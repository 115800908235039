import { JsonObject, JsonProperty } from 'json2typescript'
import { DefinedListDetail } from '../models/DefinedList'
import DateConverter from '@/converters/DateConverter'

@JsonObject('CancellationResponse')
export default class CancellationResponse {
  @JsonProperty('hasClaimedDuringPolicyPeriod', Boolean)
  hasClaimedDuringPolicyPeriod = false

  @JsonProperty('lastTakeUpMethod', DefinedListDetail)
  lastTakeUpMethod: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('daysOfCover', Number)
  daysOfCover = 0

  @JsonProperty('inceptionDate', DateConverter)
  inceptionDate: Date = new Date()

  @JsonProperty('cancellationDate', DateConverter)
  cancellationDate: Date = new Date()

  @JsonProperty('isRefund', Boolean)
  isRefund = false

  @JsonProperty('convertedToAnnual', Boolean)
  convertedToAnnual = false

  @JsonProperty('insurerReturnPremium', Number)
  insurerReturnPremium = 0

  @JsonProperty('chargeFees', Number)
  chargeFees = 0

  @JsonProperty('rebateFees', Number)
  rebateFees = 0

  @JsonProperty('totalCancellation', Number)
  totalCancellation = 0

  @JsonProperty('totalVaps', Number)
  totalVaps = 0

  @JsonProperty('outstandingFinance', Number)
  outstandingFinance = 0

  @JsonProperty('customerBalance', Number)
  customerBalance = 0
}
