import { JsonObject, JsonProperty } from 'json2typescript'
import { DefinedListDetail } from './DefinedList'

@JsonObject('TouringCaravanStorageLocation')
export default class TouringCaravanStorageLocation {
  @JsonProperty('isPrimary', Boolean, true)
  isPrimary: boolean | null = null

  @JsonProperty('storageLocationType', DefinedListDetail)
  storageLocationType: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('storageLocationDetail', DefinedListDetail)
  storageLocationDetail: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('cassoaSite', DefinedListDetail)
  cassoaSite: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('storageLocationPostcode', String, true)
  storageLocationPostcode: string | null = null

  @JsonProperty('caravansOnSite', Number, true)
  caravansOnSite: number | null = null

  @JsonProperty('monthsAtStorageLocation', Number, true)
  monthsAtStorageLocation: number | null = null

  @JsonProperty('secureFencing', Boolean, true)
  secureFencing: boolean | null = null

  @JsonProperty('securityGates', Boolean, true)
  securityGates: boolean | null = null

  @JsonProperty('fullTimeWarden', Boolean, true)
  fullTimeWarden: boolean | null = null

  @JsonProperty('hasCCTV', Boolean, true)
  hasCCTV: boolean | null = null
}
