enum PolicyStatusEnum {
  live = 'Live',
  livePending = 'Live Pending',
  cancelled = 'Cancelled',
  cancellationPending = 'Cancellation Pending',
  adjustmentLive = 'Adjustment Live',
  adjustmentPending = 'Adjustment Pending',
  lapsed = 'Lapsed',
  lapsedRenewal = 'Lapsed Renewal',
  renewalPending = 'Renewal Pending',
  renewed = 'Renewed',
  ntu = 'NTU',
}

export default PolicyStatusEnum
