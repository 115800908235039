import type QuestionEnum from '@/services/enums/QuestionEnum'

export interface IQuestionAnswer {
  validationMessage: string | null
  id: number
  name: string
  label: string
  display: string | null

  subPageAnswers: PageAnswers[]
  value: any
  visible: boolean
  hideOnSummary: boolean | null
}

export default class PageAnswers {
  title: string

  answers: IQuestionAnswer[]

  constructor(title = '', answers: IQuestionAnswer[] = []) {
    this.title = title
    this.answers = answers
  }
}

export class QuestionAnswer<T> implements IQuestionAnswer {
  id: number

  name: QuestionEnum

  label: string

  value: T | null

  visible: boolean

  display: string | null

  subPageAnswers: PageAnswers[]

  validationMessage: string | null

  hideOnSummary: boolean | null

  constructor(
    id = 0,
    name = '' as QuestionEnum,
    label = '',
    value: T | null = null,
    visible = true,
    display: string | null = null,
    subPageAnswers: PageAnswers[] = [],
    validationMessage: string | null = null,
    hideOnSummary: boolean | null = null,
  ) {
    this.id = id
    this.name = name
    this.label = label
    this.value = value
    this.visible = visible
    this.display = display
    this.subPageAnswers = subPageAnswers
    this.validationMessage = validationMessage
    this.hideOnSummary = hideOnSummary
  }
}
