import type { AxiosInstance } from 'axios'
import type { JsonConvert } from 'json2typescript'
import type IClaimService from './interfaces/IClaimService'
import { ClaimsCollectionResponse } from './values/CollectionResponse'

export default class ClaimService implements IClaimService {
  axios: AxiosInstance

  apiUrl: URL

  jsonConvert: JsonConvert

  constructor(axios: AxiosInstance, apiUrl: URL, jsonConvert: JsonConvert) {
    this.axios = axios
    this.apiUrl = apiUrl
    this.jsonConvert = jsonConvert
  }

  async getClaims(start?: number, end?: number): Promise<ClaimsCollectionResponse> {
    const result = await this.axios.get(`${this.apiUrl}/claim`, { params: { start, end } })
    return this.jsonConvert.deserializeObject(result.data, ClaimsCollectionResponse)
  }
}
