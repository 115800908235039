import { JsonObject, JsonProperty } from 'json2typescript'
import Model from './Model'

@JsonObject('CreditCard')
export default class CreditCard extends Model {
  @JsonProperty('display', String)
  display: string

  @JsonProperty('firstName', String)
  firstName: string

  @JsonProperty('lastName', String)
  lastName: string

  @JsonProperty('expiryMonth', String)
  expiryMonth: string

  @JsonProperty('expiryYear', String)
  expiryYear: string

  constructor(
    id = 0,
    display = '',
    firstName = '',
    lastName = '',
    expiryMonth = '',
    expiryYear = '',
  ) {
    super(id)
    this.display = display
    this.firstName = firstName
    this.lastName = lastName
    this.expiryMonth = expiryMonth
    this.expiryYear = expiryYear
  }
}
