import { JsonObject, JsonProperty } from 'json2typescript'

@JsonObject('AggregatorClient')
export default class AggregatorClient {
  @JsonProperty('name', String)
  name: string

  @JsonProperty('img', String)
  img: string

  constructor(
    name = '',
    img = '',
  ) {
    this.name = name
    this.img = img
  }
}
