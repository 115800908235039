export default class NetworkError implements Error {
  name: string

  message: string

  stack?: string

  constructor() {
    this.name = 'NetworkError'
    this.message = 'You appear to have no internet connection or the API is currently unavailable.'
  }
}
