import { JsonObject, JsonProperty } from 'json2typescript'
import PersonalDetails from './PersonalDetails'

@JsonObject('CaravanAdditionalPolicyHolderRequest')
export default class CaravanAdditionalPolicyHolderRequest extends PersonalDetails {
  @JsonProperty('maritalStatus', String)
    maritalStatus: string | null = null

  @JsonProperty('relationshipToPolicyHolder', String)
    relationshipToPolicyHolder: string | null = null

  @JsonProperty('employmentStatus', String)
    employmentStatus: string | null = null

  @JsonProperty('occupation', String)
    occupation: string | null = null

  @JsonProperty('businessType', String)
    businessType: string | null = null

  @JsonProperty('hasSecondaryEmployment', Boolean)
    hasSecondaryEmployment: boolean | null = true

  @JsonProperty('secondaryOccupation', String)
    secondaryOccupation: string | null = null

  @JsonProperty('secondaryBusinessType', String)
    secondaryBusinessType: string | null = null
}
