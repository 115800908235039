import { JsonObject, JsonProperty } from 'json2typescript'

@JsonObject('TouringCaravanStorageLocationValue')
export default class TouringCaravanStorageLocationRequest {
  @JsonProperty('isPrimary', Boolean, true)
  isPrimary: boolean | null = null

  @JsonProperty('storageLocationType', String, true)
  storageLocationType: string | null = null

  @JsonProperty('storageLocationDetail', String, true)
  storageLocationDetail: string | null = null

  @JsonProperty('cassoaSite', String, true)
  cassoaSite: string | null = null

  @JsonProperty('storageLocationPostcode', String, true)
  storageLocationPostcode: string | null = null

  @JsonProperty('caravansOnSite', String, true)
  caravansOnSite: string | null = null

  @JsonProperty('monthsAtStorageLocation', String, true)
  monthsAtStorageLocation: string | null = null

  @JsonProperty('secureFencing', Boolean, true)
  secureFencing: boolean | null = null

  @JsonProperty('securityGates', Boolean, true)
  securityGates: boolean | null = null

  @JsonProperty('fullTimeWarden', Boolean, true)
  fullTimeWarden: boolean | null = null

  @JsonProperty('hasCCTV', Boolean, true)
  hasCCTV: boolean | null = null
}
