import { JsonObject, JsonProperty } from 'json2typescript'
import Address from '../models/Address'

@JsonObject('CreditCardValue')
export default class CreditCard {
  @JsonProperty('name', String)
  name = ''

  @JsonProperty('type', String)
  type = ''

  @JsonProperty('cardNumber', String)
  cardNumber = ''

  @JsonProperty('expiryMonth', String)
  expiryMonth = ''

  @JsonProperty('expiryYear', String)
  expiryYear = ''

  @JsonProperty('cvv', String)
  cvv = ''

  @JsonProperty('billingAddress', Address)
  billingAddress: Address = new Address()
}
