import type ITransitionAction from '@/page-transitions/transition-actions/ITransitionAction'
import ContextLevel from '@/services/enums/ContextLevel'
import { RatingStatus } from '@/services/values/RatingResponse'
import Routes from '@/constants/Routes'
import type { PageNavigationConfig } from '@/view-models/PageNavigationConfig'
import type ProductEnum from '@/services/enums/ProductEnum'
import type IJourneyPage from '@/mixins/IJourneyPage'
import GlobalEventsEnum from '@/services/enums/GlobalEventsEnum'
import useAnswersStore from '@/stores/answers-store'
import BadRequestError from '@/services/error/BadRequestError'

export default class GetMtaQuoteAction implements ITransitionAction {
  canExecute(currentContext: ContextLevel, nextContext: ContextLevel): boolean {
    return currentContext === ContextLevel.mtaPreQuote && nextContext === ContextLevel.mtaQuote
  }

  async execute(page: IJourneyPage): Promise<PageNavigationConfig | null> {
    page.$events.emit(GlobalEventsEnum.setLoadingDisplayText, 'Getting you a price...')
    page.isLoading = true

    const answersStore = useAnswersStore()

    const currentAnswers = answersStore.answers

    try {
      const ratingResponse = await page.$services.ratingEngineService.requestMTAQuote(page.$route.params.policyNumber, currentAnswers, page.$route.params.product as ProductEnum)
      if (ratingResponse.status === RatingStatus.quoted) {
        page.isLoading = false
        return { params: { quoteReference: ratingResponse.quoteReference! } }
      }
      page.$router.push({
        name: Routes.quoteDeclined,
        query: { mta: page.$route.params.policyNumber },
      })
      page.isLoading = false
    }
    catch (e) {
      if (e instanceof BadRequestError) {
        page.$buefy.toast.open({
          duration: 5000,
          message: (Object.values(e.modelState!)[0]) ?? e.message,
          type: 'is-danger',
          position: 'is-bottom',
        })
      }
      else {
        throw e
      }
    }
    return null
  }
}
