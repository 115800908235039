import { JsonObject, JsonProperty } from 'json2typescript'
import PaymentTypeEnum from '../enums/PaymentTypeEnum'
import { DefinedListDetail } from './DefinedList'
import { ProposerDriver } from './Driver'
import QuoteDetails from './QuoteDetails'
import type { CarSchemeQuoteResult } from './SchemeQuoteResult'
import PaymentTypeEnumConverter from '@/converters/PaymentTypeEnumConverter'

@JsonObject('CarQuoteDetails')
export class CarQuoteDetails extends QuoteDetails<CarSchemeQuoteResult> {
  @JsonProperty('voluntaryExcess', DefinedListDetail)
  voluntaryExcess: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('noClaimsBonus', Number)
  noClaimsBonus = 0

  @JsonProperty('policyDuration', DefinedListDetail)
  policyDuration: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('registeredKeeper', DefinedListDetail)
  registeredKeeper: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('coverType', DefinedListDetail)
  coverType: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('mainDriver', ProposerDriver)
  mainDriver: ProposerDriver = new ProposerDriver()

  @JsonProperty('registrationNumber', String)
  registrationNumber = ''

  @JsonProperty('coverTerm', DefinedListDetail)
  coverTerm: DefinedListDetail = new DefinedListDetail()
}

@JsonObject('CarRenewalQuoteDetails')
export class CarRenewalQuoteDetails extends CarQuoteDetails {
  @JsonProperty('initialPaymentMethod', PaymentTypeEnumConverter)
  initialPaymentMethod: PaymentTypeEnum = PaymentTypeEnum.cardPayment

  @JsonProperty('previousPolicyScheme', String)
  previousPolicyScheme = ''
}

@JsonObject('CarMTAQuoteDetails')
export class CarMTAQuoteDetails extends CarQuoteDetails {
  @JsonProperty('initialPaymentMethod', PaymentTypeEnumConverter)
  initialPaymentMethod: PaymentTypeEnum = PaymentTypeEnum.cardPayment

  @JsonProperty('hasUpgradableVAP', Boolean)
  hasUpgradableVAP = true

  @JsonProperty('hasClaims', Boolean)
  hasClaims = false

  @JsonProperty('message', String)
  message = ''
}
