import { JsonObject, JsonProperty } from 'json2typescript'
import DateConverter from '../../converters/DateConverter'
import PersonalDetails from '../values/PersonalDetails'
import { DefinedListDetail } from './DefinedList'
import TouringCaravanClaim from './TouringCaravanClaim'
import TouringCaravanConviction from './TouringCaravanConviction'

@JsonObject('TouringCaravanProposer')
export default class TouringCaravanProposer extends PersonalDetails {
  @JsonProperty('maritalStatus', DefinedListDetail)
  maritalStatus: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('livedInUKSince', DateConverter, true)
  livedInUKSince: Date | null = null

  @JsonProperty('licenceHeldYears', Number, true)
  licenceHeldYears: number | null = null

  @JsonProperty('manouveringCourse', DefinedListDetail)
  manouveringCourse: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('clubMembership', DefinedListDetail)
  clubMembership: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('employmentStatus', DefinedListDetail)
  employmentStatus: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('occupation', DefinedListDetail)
  occupation: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('businessType', DefinedListDetail)
  businessType: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('secondaryEmploymentStatus', DefinedListDetail)
  secondaryEmploymentStatus: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('secondaryOccupation', DefinedListDetail)
  secondaryOccupation: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('secondaryBusinessType', DefinedListDetail)
  secondaryBusinessType: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('noClaimsBonus', Number, true)
  noClaimsBonus: number | null = null

  @JsonProperty('caravanNoClaimsBonus', Number, true)
  caravanNoClaimsBonus: number | null = null

  @JsonProperty('hasClaims', Boolean, true)
  hasClaims: boolean | null = null

  @JsonProperty('hasConvictions', Boolean, true)
  hasConvictions: boolean | null = null

  @JsonProperty('hadInsuranceRefused', Boolean, true)
  hadInsuranceRefused: boolean | null = null

  @JsonProperty('noClaimsProtection', Boolean, true)
  noClaimsProtection: boolean | null = null

  @JsonProperty('hasHadPreviousPolicyCancelledTermsApplied', Boolean, true)
  hasHadPreviousPolicyCancelledTermsApplied: boolean | null = null

  @JsonProperty('authorisedPersonsName', String, true)
  authorisedPersonsName: string | null = null

  @JsonProperty('hasCriminalConvictions', Boolean, true)
  hasCriminalConvictions: boolean | null = null

  @JsonProperty('touringCaravanClaims', [TouringCaravanClaim])
  touringCaravanClaims: TouringCaravanClaim[] = []

  @JsonProperty('touringCaravanConvictions', [TouringCaravanConviction])
  touringCaravanConvictions: TouringCaravanConviction[] = []

  @JsonProperty('hadPreviousInsurance', Boolean, true)
  hadPreviousInsurance: boolean | null = null

  @JsonProperty('previousInsurerName', Number, true)
  previousInsurerName: number | null = null

  @JsonProperty('previousPolicyNumber', String, true)
  previousPolicyNumber: string | null = null

  @JsonProperty('previousPolicyExpiration', DateConverter, true)
  previousPolicyExpiration: Date | null = null

  @JsonProperty('referralSource', DefinedListDetail, true)
  referralSource: DefinedListDetail | null = null

  @JsonProperty('europeanCoverRequired', Boolean, true)
  europeanCoverRequired: boolean | null = null

  @JsonProperty('publicLiabilityRequired', Boolean, true)
  publicLiabilityRequired: boolean | null = null

  @JsonProperty('publicLiabilityCover', DefinedListDetail, true)
  publicLiabilityCover: DefinedListDetail | null = null

  @JsonProperty('hasBeenBankrupt', Boolean, true)
  hasBeenBankrupt: boolean | null = null
}
