export default class NotAuthorisedError implements Error {
  name: string

  message: string

  stack?: string

  constructor() {
    this.name = 'NotAuthorisedError'
    this.message = 'You are not logged in or your session has expired.'
  }
}
