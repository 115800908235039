import { JsonObject, JsonProperty } from 'json2typescript'
import Address from '../Address'
import { DefinedListDetail } from '../DefinedList'
import VehicleDriver from './VehicleDriver'

@JsonObject('VehicleProposerModel')
export default class VehicleProposer extends VehicleDriver {
  @JsonProperty('isCompany', Boolean)
    isCompany: boolean

  @JsonProperty('companyName', String)
    companyName: string

  @JsonProperty('address', Address)
    address: Address

  @JsonProperty('employmentStatus', DefinedListDetail)
    employmentStatus: DefinedListDetail

  @JsonProperty('occupation', DefinedListDetail)
    occupation: DefinedListDetail

  @JsonProperty('businessType', DefinedListDetail)
    businessType: DefinedListDetail

  @JsonProperty('clubMembership', DefinedListDetail)
    clubMembership: DefinedListDetail

  @JsonProperty('isCompanyVehicle', Boolean)
    isCompanyVehicle: boolean | null = null

  @JsonProperty('hasAdditionalDrivers', Boolean)
    hasAdditionalDrivers: boolean

  @JsonProperty('hasConfirmedAssumptions', Boolean)
    hasConfirmedAssumptions: boolean

  @JsonProperty('hadInsuranceRefusedCancelledOrDeclined', Boolean)
    hadInsuranceRefusedCancelledOrDeclined: boolean | null = null

  @JsonProperty('hasUnspentNonMotoringConvictions', Boolean)
    hasUnspentNonMotoringConvictions: boolean | null = null

  @JsonProperty('hasDeclaredBankruptcy', Boolean)
    hasDeclaredBankruptcy: boolean | null = null

  @JsonProperty('hadPreviousInsurance', Boolean)
    hadPreviousInsurance: boolean | null = null

  @JsonProperty('currentInsurer', DefinedListDetail)
    currentInsurer: DefinedListDetail

  @JsonProperty('hasAccessToProposerLicence', Boolean)
    hasAccessToProposerLicence: boolean | null = null

  constructor(
      isCompany = false,
      companyName = '',
      address = new Address(),
      employmentStatus: DefinedListDetail = new DefinedListDetail(),
      businessType: DefinedListDetail = new DefinedListDetail(),
      occupation: DefinedListDetail = new DefinedListDetail(),
      clubMembership: DefinedListDetail = new DefinedListDetail(),
      isCompanyVehicle = false,

      hasAdditionalDrivers = false,
      hasConfirmedAssumptions = false,
      hadInsuranceRefusedCancelledOrDeclined = false,
      hasUnspentNonMotoringConvictions = false,
      hasDeclaredBankruptcy = false,
      hadPreviousInsurance = false,
      currentInsurer: DefinedListDetail = new DefinedListDetail(),
      hasAccessToProposerLicence = null,
  ) {
    super()
    this.isCompany = isCompany
    this.companyName = companyName
    this.address = address
    this.employmentStatus = employmentStatus
    this.businessType = businessType
    this.occupation = occupation
    this.clubMembership = clubMembership
    this.isCompanyVehicle = isCompanyVehicle
    this.hasAdditionalDrivers = hasAdditionalDrivers
    this.hasConfirmedAssumptions = hasConfirmedAssumptions
    this.hadInsuranceRefusedCancelledOrDeclined = hadInsuranceRefusedCancelledOrDeclined
    this.hasUnspentNonMotoringConvictions = hasUnspentNonMotoringConvictions
    this.hasDeclaredBankruptcy = hasDeclaredBankruptcy
    this.hadPreviousInsurance = hadPreviousInsurance
    this.currentInsurer = currentInsurer
    this.hasAccessToProposerLicence = hasAccessToProposerLicence
  }
}
