import type { JsonConvert } from 'json2typescript'
import type { AxiosInstance } from 'axios'
import type IIdentityProvider from './interfaces/IIdentityProvider'
import AccountLogin from './values/AccountLogin'
import ForgotPassword from './values/ForgotPassword'
import Identity from './values/Identity'
import ForgotUsername from './values/ForgotUsername'
import ChangePassword from './values/ChangePassword'
import type PasswordConstraints from './values/PasswordConstraints'
import QuoteAuthRequest from './values/QuoteAuthRequest'
import type IntegrationJwtFilter from './values/IntegrationJwtFilter'
import type DeepLinkTokenCheckResponse from './values/DeepLinkTokenCheckResponse'
import { getAuthServiceConfig } from '@/auth-service'

export default class IdentityProvider implements IIdentityProvider {
  axios: AxiosInstance

  apiUrl: URL

  jsonConvert: JsonConvert

  private currentIdentity: Identity | null

  constructor(axios: AxiosInstance, apiUrl: URL, jsonConvert: JsonConvert) {
    this.axios = axios
    this.apiUrl = apiUrl
    this.jsonConvert = jsonConvert
    this.currentIdentity = null
  }

  async login(username: string, password: string): Promise<void> {
    const model = new AccountLogin()
    model.username = username
    model.password = password

    await this.axios.post(`${this.apiUrl}/auth/login`, this.jsonConvert.serialize(model, AccountLogin))
    IdentityProvider.setAuthChangedBy()
  }

  async storeAuthServiceToken(token: string) {
    const authServiceConfig = getAuthServiceConfig()
    authServiceConfig.setToken(token, 'auth')
    IdentityProvider.setAuthChangedBy()
  }

  async quoteLogin(guid: string): Promise<string> {
    const model = new QuoteAuthRequest()
    model.guid = guid

    const result = await this.axios.post(`${this.apiUrl}/auth/login/quote`, this.jsonConvert.serialize(model, QuoteAuthRequest))
    IdentityProvider.setAuthChangedBy()
    return result.data as string
  }

  async aggLogin(guid: string): Promise<void> {
    const model = new QuoteAuthRequest()
    model.guid = guid
    await this.axios.post(`${this.apiUrl}/auth/login/agg`, this.jsonConvert.serialize(model, QuoteAuthRequest))
    IdentityProvider.setAuthChangedBy()
  }

  async checkDeepLinkToken(token: string): Promise<DeepLinkTokenCheckResponse> {
    const result = await this.axios.get(`${this.apiUrl}/auth/deeplink/check/${token}`)
    return result.data as DeepLinkTokenCheckResponse
  }

  async deepLinkTokenLogin(token: string): Promise<void> {
    await this.axios.post(`${this.apiUrl}/auth/deeplink/login/${token}`)
    IdentityProvider.setAuthChangedBy()
  }

  async logout(): Promise<void> {
    this.currentIdentity = null
    const authServiceConfig = getAuthServiceConfig()
    if (authServiceConfig.enabled)
      authServiceConfig.clearToken()
    else
      await this.axios.get(`${this.apiUrl}/auth/logout`)
    IdentityProvider.setAuthChangedBy()
  }

  async forgottenUsername(email: string): Promise<void> {
    const model = new ForgotUsername()
    model.emailAddress = email

    return this.axios.post(`${this.apiUrl}/account/forgot/username`, this.jsonConvert.serialize(model, ForgotUsername))
  }

  forgottenPassword(username: string, email: string, firstName: string, lastName: string): Promise<void> {
    const model = new ForgotPassword()
    model.username = username
    model.emailAddress = email
    model.firstName = firstName
    model.lastName = lastName

    return this.axios.post(`${this.apiUrl}/account/forgot/password`, this.jsonConvert.serialize(model, ForgotPassword))
  }

  async changePassword(oldPassword: string, newPassword: string): Promise<void> {
    const model = new ChangePassword()
    model.oldPassword = oldPassword
    model.newPassword = newPassword

    return this.axios.post(`${this.apiUrl}/account/password`, this.jsonConvert.serialize(model, ChangePassword))
  }

  async getCurrent(canBeUnauthorised = false): Promise<Identity> {
    if (!this.currentIdentity) {
      const result = await this.axios.get(`${this.apiUrl}/auth/identity`, {
        headers: {
          canBeUnauthorised,
        },
      })
      return this.jsonConvert.deserializeObject(result.data, Identity)
    }

    return this.currentIdentity
  }

  async getPasswordConstraints(): Promise<PasswordConstraints> {
    const result = await this.axios.get(`${this.apiUrl}/account/password-constraints`)
    return result.data as PasswordConstraints
  }

  async getIntegrationJWT(integration: string, filter: IntegrationJwtFilter | null): Promise<string> {
    const result = await this.axios.get(`${this.apiUrl}/auth/integration-jwt/${integration}`, filter ? { params: filter } : undefined)
    return result.data as string
  }

  static setAuthChangedBy() {
    localStorage.setItem('authChangedBy', window.instanceId)
  }
}
