<template>
  <b-modal class="threedsecure-modal" :active="active" has-modal-card :can-cancel="false" :on-cancel="cancel">
    <div class="modal-card">
        <header class="modal-card-head align-items">
          <p class="modal-card-title">3DSecure</p>
          <button id="threed-cancel" type="button" class="m-0" @click="cancel">Cancel</button>
        </header>
        <section class="modal-card-body ">
          <iframe
            title="ThreeDSecure"
            class="threedsecure-iframe"
            :src="iframeSrc">
          </iframe>
      </section>
    </div>
  </b-modal>
</template>

<script lang="ts">
/* eslint-disable no-useless-escape */
import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';
import ThreeDSecureDetails from '@/services/values/ThreeDSecureDetails';
import PaymentRequest from '@/services/values/PaymentRequest';
import { emit } from 'process';
import { ThreeDSecureAuthorisationResponse } from '@/services/values/ThreeDSecureAuthorisation';

@Component
export default class ThreeDSecureModal extends Vue {
  @Prop() private threeDSecureDetails!: ThreeDSecureDetails;
  @Prop() private paymentRequest!: PaymentRequest;

  active = true;

  iframeSrc = ''

  mounted() {
    console.log('3DSECURE WINDOW -- MOUNTED')
    window.removeEventListener('message', this.listener);
    window.addEventListener('message', this.listener);

    this.iframeSrc = this.threeDSecureDetails.threeDSServerCallbackUrl
  }

  beforeDestroy() {
    console.log('3DSECURE WINDOW -- DESTROYED')
    window.removeEventListener('message', this.listener);
  }

  async listener(event: MessageEvent) {
    if (event.origin !== this.$services.apiUrl.origin) {
      // eslint-disable-next-line no-console
      console.log('3DSECURE WINDOW -- INVALID EVENT');
      return;
    }

    if (event.data.event_id === '3DSMethodSkipped' || event.data.event_id === '3DSMethodFinished') {
      // eslint-disable-next-line no-console
      console.log('3DSECURE WINDOW -- BROWSER DETECTION COMPLETE PERFORMING AUTH');
      const { param } = event.data;

      let response: ThreeDSecureAuthorisationResponse | null = null

      try {
        response = await this.$services.paymentService.performThreeDSecureAuth(this.paymentRequest, this.threeDSecureDetails.authUrl, param, this.threeDSecureDetails.threeDSServerTransId)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('3DSECURE WINDOW -- PERFORM AUTH FAILED', error);
      }

      if (response?.challengeRequired) {
        this.iframeSrc = response.challengeUrl
      } else if (response?.complete) {
        window.removeEventListener('message', this.listener);
        this.active = false;
        this.$emit('complete', this.threeDSecureDetails.threeDSServerTransId)
      } else {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Something went wrong with your 3DSecure authorisation. Please try again.',
          type: 'is-danger',
          position: 'is-bottom',
        });
        this.cancel();
      }

      return;
    }

    if (event.data.event_id === 'AuthResultReady') {
      // eslint-disable-next-line no-console
      console.log('3DSECURE WINDOW -- AUTH RESULT READY');
      window.removeEventListener('message', this.listener);
      this.active = false;
      this.$emit('complete', this.threeDSecureDetails.threeDSServerTransId)
      return;
    }
  }

  cancel() {
    window.removeEventListener('message', this.listener);
    this.active = false;
    this.$emit('cancel');
  }
}
</script>
