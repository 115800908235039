import type { JsonCustomConvert } from 'json2typescript'
import { JsonConverter } from 'json2typescript'
import type QuoteStatusEnum from '@/services/enums/QuoteStatusEnum'

@JsonConverter
export default class QuoteStatusEnumConverter implements JsonCustomConvert<QuoteStatusEnum> {
  serialize(quoteStatus: QuoteStatusEnum): string {
    return quoteStatus.toString()
  }

  deserialize(quoteStatus: string): QuoteStatusEnum {
    return quoteStatus as QuoteStatusEnum
  }
}
