import type IQuestion from '../services/interfaces/IQuestion'

export default class Section {
  name: string

  header: string | null

  questions: IQuestion[]

  constructor(name: string, header: string | null, questions: IQuestion[]) {
    this.name = name
    this.header = header
    this.questions = questions
  }
}
