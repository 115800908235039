import { JsonObject, JsonProperty } from 'json2typescript'

@JsonObject('AccountLogin')
export default class AccountLogin {
  @JsonProperty('username', String)
  username = ''

  @JsonProperty('password', String)
  password = ''
}
