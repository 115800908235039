import type { JsonCustomConvert } from 'json2typescript'
import { JsonConverter } from 'json2typescript'
import type PaymentTypeEnum from '@/services/enums/PaymentTypeEnum'

@JsonConverter
export default class PaymentTypeEnumConverter implements JsonCustomConvert<PaymentTypeEnum> {
  serialize(product: PaymentTypeEnum): string {
    return product.toString()
  }

  deserialize(product: string): PaymentTypeEnum {
    return product as PaymentTypeEnum
  }
}
