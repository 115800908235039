import { JsonObject, JsonProperty } from 'json2typescript'
import Model from '@/services/models/Model'

@JsonObject('AddressSummary')
export default class AddressSummary extends Model {
  @JsonProperty('display', String)
  display: string

  @JsonProperty('lookupId', String)
  lookupId: string

  constructor(id = 0, lookupId = '', display = '') {
    super(id)
    this.lookupId = lookupId
    this.display = display
  }
}
