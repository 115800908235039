import Vue, { watch } from 'vue'
import { PiniaVuePlugin, createPinia } from 'pinia'
import Buefy from 'buefy'
import Vue2TouchEvents from 'vue2-touch-events'
import { v4 as uuidv4 } from 'uuid'

import { H } from 'highlight.run'
import { register } from '@ignite/auth-service-web-components'
import ThreeDSecurePlugin from './plugins/three-d-secure'
import PaymentGatewayPlugin from './plugins/payment-gateway'
import App from './App.vue'
import router from './router'
import type IAnalyticsConfig from './plugins/analytics/IAnalyticsConfig'
import Routes from './constants/Routes'
import WrapperUtils from './utils/WrapperUtils'
import TenantEnum from '@/services/enums/TenantEnum'
import type { IServicesOptions } from '@/plugins/services'
import ServicesPlugin from '@/plugins/services'
import type { IConfigurationOptions } from '@/plugins/configurations'
import ConfigurationPlugin from '@/plugins/configurations'
import ChatPlugin from '@/plugins/chat'
import EventBusPlugin from '@/plugins/event-bus'
import UtilsPlugin from '@/plugins/utils'
import DirectivesPlugin from '@/plugins/directives'
import PWAUtilsPlugin from '@/plugins/pwa-utils'
import type { IApplicationInsightsConfig } from '@/plugins/app-insights'
import ApplicationInsightsPlugin from '@/plugins/app-insights'
import AnalyticsPlugin from '@/plugins/analytics/analytics'
import JourneyTransitionsPlugin from '@/plugins/journey-transitions'

register()

async function main() {
  const processTenant = `${window.injectedEnv.FRONTEND_TENANT}` as TenantEnum
  if (processTenant === TenantEnum.Wrapper)
    await new WrapperUtils().handleBrandSwitching(null)

  switch (processTenant) {
    case TenantEnum.Wrapper:
    case TenantEnum.Yoga:
    case TenantEnum.BarryGrainger:
    case TenantEnum.Tractive:
    case TenantEnum.Zoomcover:
    case TenantEnum.Dash:
    case TenantEnum.Quotax:
    case TenantEnum.Ignite:
      await import(`@/styles/${processTenant}/global.scss`)
      break
    case TenantEnum.Hagerty:
    case TenantEnum.KeeyPartners:
    default:
      await import(`@/styles-legacy/${processTenant}/global.scss`)
      break
  }

  if (processTenant === TenantEnum.Ignite) {
    const { demoTenant: { colorPrimary, colorSecondary, colorAccent } } = await import('./configurations/general/ignite.json')
    const { style } = document.querySelector(':root') as HTMLElement

    style.setProperty('--col-primary', colorPrimary)
    style.setProperty('--col-secondary', colorSecondary)
    style.setProperty('--col-accent', colorAccent)
  }

  Vue.config.productionTip = false

  const appInsightsConfig: IApplicationInsightsConfig = {
    config: {
      instrumentationKey: 'd802ee99-d605-4106-b056-f7dd89ed9a40',
      appId: '94a48f07-24ce-460f-af5c-c8ad6d05a3f7',
      enableUnhandledPromiseRejectionTracking: true,
      enableAjaxErrorStatusText: true,
    },
    router,
  }

  Vue.use(ApplicationInsightsPlugin, appInsightsConfig)

  const analyticsConfig: IAnalyticsConfig = {
    gtm: window.injectedEnv.FRONTEND_GTM,
    analytics: window.injectedEnv.FRONTEND_GA,
    router,
    tenant: processTenant,
  }

  if (!window.location.hostname.includes('localhost') && !(window as any).Cypress) {
    H.init('lgx34wgm', {
      environment: window.injectedEnv.FRONTEND_NAMESPACE,
      version: window.injectedEnv.FRONTEND_APP_VERSION,
      privacySetting: 'default',
      networkRecording: {
        enabled: true,
      },
    })
  }

  Vue.use(AnalyticsPlugin, analyticsConfig)

  let apiUrl = new URL(window.injectedEnv.FRONTEND_API_URL)
  // HACK: portal is mostly deprecated now so this will die with the ignite-portal repo...
  if (window.location.hostname === 'portal.onedayinsurance.co.uk')
    apiUrl = new URL('https://api.onedayinsurance.co.uk/api')

  const servicesOptions: IServicesOptions = {
    apiUrl,
    axiosConfig: {
      withCredentials: true,
      headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
      },
    },
    tenant: processTenant,
    router,
  }

  const configurationOptions: IConfigurationOptions = {
    tenant: processTenant,
  }

  Vue.use(Buefy)
  Vue.use(ConfigurationPlugin, configurationOptions)

  Vue.use(ServicesPlugin, servicesOptions)
  Vue.use(ChatPlugin)
  Vue.use(EventBusPlugin)
  Vue.use(UtilsPlugin, servicesOptions.tenant)
  Vue.use(DirectivesPlugin)
  Vue.use(PWAUtilsPlugin)
  Vue.use(Vue2TouchEvents)
  Vue.use(JourneyTransitionsPlugin)
  Vue.use(ThreeDSecurePlugin)
  Vue.use(PaymentGatewayPlugin)

  Vue.config.ignoredElements = [
    'ignite-auth-service-login',
    'ignite-auth-service-register-button',
    'ignite-auth-service-login-buttons',
    'ignite-auth-service-forgot-button',
  ]

  Vue.use(PiniaVuePlugin)
  const pinia = createPinia()

  const piniaStateCreatedRaw = localStorage.getItem('piniaStateCreated')
  let piniaStateCreated = null

  if (piniaStateCreatedRaw)
    piniaStateCreated = Date.parse(piniaStateCreatedRaw)

  const expiryTime = 2 * 60 * 60 * 1000 // expire two hours

  let localPiniaState = null

  if (piniaStateCreated && (new Date()).getTime() - piniaStateCreated < expiryTime)
    localPiniaState = localStorage.getItem('piniaState')
  else
    localStorage.removeItem('piniaState')

  pinia.state.value = localPiniaState ? JSON.parse(localPiniaState) : {}
  localStorage.setItem('piniaStateCreated', (new Date()).toUTCString())

  watch(
    pinia.state,
    (state) => {
      // persist the whole state to the local storage whenever it changes
      localStorage.setItem('piniaState', JSON.stringify(state))
      localStorage.setItem('piniaStateCreated', (new Date()).toUTCString())
    },
    { deep: true },
  )

  // kick users to session expired if another vue instance changes logged in account
  window.instanceId = uuidv4()
  localStorage.removeItem('authChangedBy')
  setInterval(() => {
    const authChangedBy = localStorage.getItem('authChangedBy')
    if (authChangedBy && authChangedBy !== window.instanceId && router.currentRoute.name !== Routes.sessionExpired) {
      router.replace({
        name: Routes.sessionExpired,
      })
    }
  }, 1000)

  new Vue({
    router,
    pinia,
    render: h => h(App),
  }).$mount('#app')
}

main()
