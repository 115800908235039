import { JsonObject, JsonProperty } from 'json2typescript'

@JsonObject('ChangePassword')
export default class ChangePassword {
  @JsonProperty('oldPassword', String)
  oldPassword = ''

  @JsonProperty('newPassword', String)
  newPassword = ''
}
