import moment from 'moment'
import type { Dictionary } from 'vue-gtag'
import ContextLevel from '../enums/ContextLevel'
import QuestionEnum from '../enums/QuestionEnum'
import UnkownQuestionSetError from '../error/UnknownQuestionSetError'
import type IPageMappings from '../interfaces/IPageMappings'
import type IQuestionMapper from '../interfaces/IQuestionMapper'
import type IRequestMapper from '../interfaces/IRequestMapper'
import type IResponseMapper from '../interfaces/IResponseMapper'
import type Account from '../models/Account'
import { Marketing } from '../models/Account'
import type ProductPage from '../models/ProductPage'
import type Risk from '../models/Risk'
import { CarRisk } from '../models/Risk'
import AccountRegister from '../values/AccountRegister'
import type { IQuestionAnswer } from '../values/PageAnswers'
import PageAnswers, { QuestionAnswer } from '../values/PageAnswers'
import type QuoteRequest from '../values/QuoteRequest'
import type SchemeRequest from '../values/SchemeRequest'
import DLDCommonValuesEnum from '../enums/DLDCommonValuesEnum'
import type Address from '../models/Address'
import Vehicle from '../values/Vehicle'
import type { AdditionalDriver } from '../values/Driver'
import { CarProposer } from '../values/Driver'
import CarQuoteRequest, { CarMTAQuoteRequest } from '../values/CarQuoteRequest'
import Car from '../values/Car'
import useAnswersStore from '../../stores/answers-store'
import type Modification from '../values/Modification'
import BaseMapper from './BaseMapper'
import type MedicalConditionModel from '@/services/models/MedicalCondition'
import type ModificationModel from '@/services/models/Modification'
import type ConvictionModel from '@/services/models/Conviction'
import type ClaimModel from '@/services/models/Claim'
import type MedicalCondition from '@/services/values/MedicalCondition'
import type Conviction from '@/services/values/Conviction'
import type Claim from '@/services/values/Claim'
import type {
  ClaimsQuestion,
  DateQuestion,
  DrivingLicenceQuestion,
  NumberQuestion,
  SelectQuestion,
} from '@/view-models/Question'
import type Page from '@/view-models/Page'
import Routes from '@/constants/Routes'
import DynamicQuoteRequest from '@/services/values/DynamicQuoteRequest'
import DynamicRisk from '@/services/models/Dynamic/DynamicRisk'

export default class QuotaxMapper extends BaseMapper implements IRequestMapper, IResponseMapper, IQuestionMapper, IPageMappings {
  getSchemeRequest(_questions: IQuestionAnswer[]): SchemeRequest {
    throw new Error('Method not implemented. Quotax does not use Scheme Requests.')
  }

  validate(questions: IQuestionAnswer[]): boolean {
    const hasCriminalConvictions = questions.find(q => q.name === QuestionEnum.hasCriminalConvictions) as QuestionAnswer<string>
    const hasCcjs = questions.find(q => q.name === QuestionEnum.hasCcjs) as QuestionAnswer<string>
    const hasHadPolicyDeclaredVoid = questions.find(q => q.name === QuestionEnum.hasHadPolicyDeclaredVoid) as QuestionAnswer<string>
    const isVehicleRegisteredOutsideUK = questions.find(q => q.name === QuestionEnum.isVehicleRegisteredOutsideUK) as QuestionAnswer<string>
    const isVehicleModified = questions.find(q => q.name === QuestionEnum.isVehicleModified) as QuestionAnswer<string>
    const isUsedForPrivateHire = questions.find(q => q.name === QuestionEnum.isUsedForPrivateHire) as QuestionAnswer<string>
    const isVehicleRightHandDrive = questions.find(q => q.name === QuestionEnum.isVehicleRightHandDrive) as QuestionAnswer<string>
    const isMinibus = questions.find(q => q.name === QuestionEnum.insuringAMinibus) as QuestionAnswer<string>

    const base = hasCriminalConvictions?.value === DLDCommonValuesEnum.Boolean.No
      && hasCcjs?.value === DLDCommonValuesEnum.Boolean.No
      && hasHadPolicyDeclaredVoid?.value === DLDCommonValuesEnum.Boolean.No

    return isMinibus?.value === DLDCommonValuesEnum.Boolean.Yes
      ? base
      : base
      && isVehicleRegisteredOutsideUK?.value === DLDCommonValuesEnum.Boolean.No
      && isVehicleModified?.value === DLDCommonValuesEnum.Boolean.No
      && isVehicleRightHandDrive?.value === DLDCommonValuesEnum.Boolean.Yes
      && (isUsedForPrivateHire == null || isUsedForPrivateHire?.value === DLDCommonValuesEnum.Boolean.Yes)
  }

  private bindCar(questions: IQuestionAnswer[]): Car {
    const vehicleLookup = questions.find(q => q.name === QuestionEnum.vehicleLookup) as QuestionAnswer<Vehicle>
    const vehicleOvernightParkLocation = questions.find(q => q.name === QuestionEnum.vehicleOvernightParkLocation) as QuestionAnswer<string>
    const rightHandDrive = questions.filter(q => q.name === QuestionEnum.isVehicleRightHandDrive).pop() as QuestionAnswer<string>
    const vehicleSeats = questions.find(q => q.name === QuestionEnum.vehicleSeats) as QuestionAnswer<number>
    const vehicleTransmissionType = questions.find(q => q.name === QuestionEnum.vehicleTransmission) as QuestionAnswer<string>
    const vehicleFuelType = questions.find(q => q.name === QuestionEnum.fuelType) as QuestionAnswer<string>
    const alarmType = questions.find(q => q.name === QuestionEnum.alarmType) as QuestionAnswer<string>
    const trackerType = questions.find(q => q.name === QuestionEnum.trackerType) as QuestionAnswer<string>
    const motorhomeType = questions.find(q => q.name === QuestionEnum.motorhomeType) as QuestionAnswer<string>
    const unknownMotorhomeType = questions.find(q => q.name === QuestionEnum.unknownMotorhomeType) as QuestionAnswer<string>
    const hasSecondaryStorageAddress = questions.find(q => q.name === QuestionEnum.hasSecondaryStorageAddress) as QuestionAnswer<boolean>

    const car = new Car()

    car.registration = vehicleLookup.value!.registration
    car.value = vehicleLookup.value!.value
    car.overnightParkLocation = vehicleOvernightParkLocation?.value
    car.purchaseDate = vehicleLookup.value!.purchaseDate
    car.yearOfManufacture = vehicleLookup.value!.yearOfManufacture
    car.keptAtHomeAddress = !hasSecondaryStorageAddress.value
    car.registeredKeeper = DLDCommonValuesEnum.RegisteredKeeper.ProposerPolicyHolder
    car.registeredOwner = DLDCommonValuesEnum.RegisteredKeeper.ProposerPolicyHolder

    // Todo - get these question values from updated vehicle lookup
    car.cubicCapacity = vehicleLookup.value!.engineSize
    car.bodyType = vehicleLookup.value!.bodyType
    car.make = vehicleLookup.value!.make
    car.model = vehicleLookup.value!.model

    car.leftHandDrive = rightHandDrive?.value === DLDCommonValuesEnum.Boolean.No
    car.imported = false
    car.noOfSeats = vehicleSeats?.value || null
    car.transmission = vehicleTransmissionType?.value || vehicleLookup?.value?.transmissionType || null
    car.fuelType = vehicleFuelType?.value || vehicleLookup?.value?.fuelType || null
    car.alarmType = alarmType?.value || null
    car.trackerType = trackerType?.value || null
    car.motorhomeType = motorhomeType?.value || null
    car.unknownMotorhomeType = unknownMotorhomeType?.value || null

    return car
  }

  getQuoteRequest<T extends QuoteRequest>(questions: IQuestionAnswer[], Type: new () => T): T {
    const quoteRequest = new Type()

    if ((quoteRequest instanceof CarQuoteRequest || quoteRequest instanceof CarMTAQuoteRequest))
      return this.getCarQuoteRequest(questions, Type)
    else if (quoteRequest instanceof DynamicQuoteRequest)
      return this.getDynamicQuoteRequest(questions, Type)

    throw new Error(`Unsupported quote request (${typeof quoteRequest}) for Quotax.`)
  }

  getDynamicQuoteRequest<T extends QuoteRequest>(questions: IQuestionAnswer[], Type: new () => T): T {
    const vehicleLookupQuestions: QuestionAnswer<Vehicle>[] = questions.filter(q => q as QuestionAnswer<Vehicle>) as QuestionAnswer<Vehicle>[]
    const stringQuestions: QuestionAnswer<string>[] = questions.filter(q => q as QuestionAnswer<string>) as QuestionAnswer<string>[]
    const address = questions.find(q => q.name === QuestionEnum.address) as QuestionAnswer<Address>
    const effectiveDate = questions.find(q => q.name === QuestionEnum.coverEffectiveDate) as QuestionAnswer<Date>
    const quoteRequest = new Type()
    quoteRequest.address = address.value!
    if (quoteRequest instanceof DynamicQuoteRequest) {
      vehicleLookupQuestions.forEach(vehicle => quoteRequest.questionAnswers[vehicle.name] = vehicle.value)
      stringQuestions.forEach(stringQuestion => quoteRequest.questionAnswers[stringQuestion.name] = stringQuestion.value)
    }
    quoteRequest.effectiveDate = effectiveDate.value!
    return quoteRequest
  }

  getCarQuoteRequest<T extends QuoteRequest>(questions: IQuestionAnswer[], Type: new () => T): T {
    // Vehicle details
    const vehicleLookup = questions.find(q => q.name === QuestionEnum.vehicleLookup) as QuestionAnswer<Vehicle>
    const vehicleEstimatedMileage = questions.find(q => q.name === QuestionEnum.vehicleEstimatedMileage) as QuestionAnswer<number>
    const businessMileage = questions.find(q => q.name === QuestionEnum.vehicleBusinessMileage) as QuestionAnswer<number>
    const vehicleOwner = questions.find(q => q.name === QuestionEnum.vehicleOwner) as QuestionAnswer<string>
    const vehicleKeeper = questions.find(q => q.name === QuestionEnum.vehicleKeeper) as QuestionAnswer<string>
    const vehicleSeats = questions.find(q => q.name === QuestionEnum.vehicleSeats) as QuestionAnswer<number>
    const vehicleOvernightParkLocation = questions.find(q => q.name === QuestionEnum.vehicleOvernightParkLocation) as QuestionAnswer<string>
    const vehicleWeight = questions.find(q => q.name === QuestionEnum.vehicleWeight) as QuestionAnswer<number>
    const vehiclevalue = questions.find(q => q.name === QuestionEnum.vehicleValue) as QuestionAnswer<number>
    const workRadius = questions.find(q => q.name === QuestionEnum.workRadius) as QuestionAnswer<string>
    const numberOfDrops = questions.find(q => q.name === QuestionEnum.numberOfDrops) as QuestionAnswer<string>
    const vehicleFuelType = questions.find(q => q.name === QuestionEnum.vehicleFuelType) as QuestionAnswer<string>
    const vehicleTransmissionType = questions.find(q => q.name === QuestionEnum.vehicleTransmission) as QuestionAnswer<string>
    const isMinibus = questions.find(q => q.name === QuestionEnum.insuringAMinibus) as QuestionAnswer<string>

    // Personal details
    const title = questions.find(q => q.name === QuestionEnum.title) as QuestionAnswer<string>
    const firstName = questions.find(q => q.name === QuestionEnum.firstname) as QuestionAnswer<string>
    const lastName = questions.find(q => q.name === QuestionEnum.lastName) as QuestionAnswer<string>
    const dateOfBirth = questions.find(q => q.name === QuestionEnum.dateOfBirth) as QuestionAnswer<Date>
    const residentSince = questions.find(q => q.name === QuestionEnum.ukResidentSince) as QuestionAnswer<Date>
    const residentSinceBirth = questions.find(q => q.name === QuestionEnum.residentSince) as QuestionAnswer<string>
    const maritalStatus = questions.find(q => q.name === QuestionEnum.maritalStatus) as QuestionAnswer<string>
    const drivingLicence = questions.find(q => q.name === QuestionEnum.drivingLicence) as QuestionAnswer<string>
    const address = questions.find(q => q.name === QuestionEnum.address) as QuestionAnswer<Address>
    const employmentStatus = questions.find(q => q.name === QuestionEnum.employmentStatus) as QuestionAnswer<string>
    const occupation = questions.find(q => q.name === QuestionEnum.occupation) as QuestionAnswer<string>
    const secondaryOccupation = questions.find(q => q.name === QuestionEnum.secondaryOccupation) as QuestionAnswer<string>
    const businessCategory = questions.find(q => q.name === QuestionEnum.businessCategory) as QuestionAnswer<string>
    const referralSource = questions.find(q => q.name === QuestionEnum.referralSource) as QuestionAnswer<number>
    const noClaimsBonus = questions.find(q => q.name === QuestionEnum.noClaimsBonus) as QuestionAnswer<string>
    const noClaimsProtection = questions.find(q => q.name === QuestionEnum.noClaimsProtection) as QuestionAnswer<string>
    const voluntaryExcess = questions.find(q => q.name === QuestionEnum.voluntaryExcess) as QuestionAnswer<string>
    const homeOwner = questions.find(q => q.name === QuestionEnum.homeOwner) as QuestionAnswer<string>
    const hasCCJs = questions.find(q => q.name === QuestionEnum.hasCcjs) as QuestionAnswer<string>
    const licenceDate = questions.find(q => q.name === QuestionEnum.licenceDate) as QuestionAnswer<Date>
    const mainLicensingAuthority = questions.find(q => q.name === QuestionEnum.mainLicensingAuthority) as QuestionAnswer<string>
    const otherLicensingAuthorities = questions!.find(q => q.name === QuestionEnum.otherLicensingAuthorities) as QuestionAnswer<string>
    const farePayingPassengers = questions.find(q => q.name === QuestionEnum.farePayingPassengers) as QuestionAnswer<string>
    const taxiBadgeSince = questions.find(q => q.name === QuestionEnum.taxiBadgeSince) as QuestionAnswer<Date>
    const rideSharingApp = questions.find(q => q.name === QuestionEnum.rideSharingApp) as QuestionAnswer<string>
    const hasHadPolicyDeclaredVoid = questions.find(q => q.name === QuestionEnum.hasHadPolicyDeclaredVoid) as QuestionAnswer<string>
    const isDriverForUber = questions.find(q => q.name === QuestionEnum.isDriverForUber) as QuestionAnswer<string>
    const uberBranch = questions.find(q => q.name === QuestionEnum.uberBranch) as QuestionAnswer<string>
    const uberProRating = questions.find(q => q.name === QuestionEnum.uberProRating) as QuestionAnswer<string>
    const claimsFreeExperienceOnAnotherVehicle = questions.find(q => q.name === QuestionEnum.claimsFreeExperienceOnAnotherVehicle) as QuestionAnswer<number>
    const hasConvictions = questions.find(q => q.name === QuestionEnum.hasConvictions) as QuestionAnswer<string>
    const registeredOutsideUKReason = questions.find(q => q.name === QuestionEnum.registeredOutsideUKReason) as QuestionAnswer<string>
    const hasCriminalConvictions = questions.find(q => q.name === QuestionEnum.hasCriminalConvictions) as QuestionAnswer<string>
    const ukUseOnly = questions.filter(q => q.name === QuestionEnum.isVehicleRegisteredOutsideUK).pop() as QuestionAnswer<string>
    const isUsedForPrivateHire = questions.filter(q => q.name === QuestionEnum.isUsedForPrivateHire).pop() as QuestionAnswer<string>
    const hadInsuranceRefused = questions.find(q => q.name === QuestionEnum.hadInsuranceRefused) as QuestionAnswer<string>
    const primaryStorageAddress = questions.find(q => q.name === QuestionEnum.primaryStorageAddress) as QuestionAnswer<Address>
    const restrictedLicense = questions.find(q => q.name === QuestionEnum.restrictedLicense) as QuestionAnswer<string>
    const restrictedLicenseType = questions.find(q => q.name === QuestionEnum.restrictedLicenseType) as QuestionAnswer<string>
    const hasSecondaryEmployment = questions.find(q => q.name === QuestionEnum.hasSecondaryEmployment) as QuestionAnswer<string>
    const licenceType = questions.find(q => q.name === QuestionEnum.licenceType) as QuestionAnswer<string>
    const secondaryEmploymentStatus = questions.find(q => q.name === QuestionEnum.secondaryEmploymentStatus) as QuestionAnswer<string>
    const secondaryBusinessCategory = questions.find(q => q.name === QuestionEnum.secondaryBusinessCategory) as QuestionAnswer<string>
    const drivingFrequency = questions.find(q => q.name === QuestionEnum.drivingFrequency) as QuestionAnswer<string>
    const hasClaims = questions.find(q => q.name === QuestionEnum.hasClaims) as QuestionAnswer<string>
    const hasMedicalConditions = questions.find(q => q.name === QuestionEnum.hasMedicalConditions) as QuestionAnswer<string>
    const useOfAnotherVehicle = questions.find(q => q.name === QuestionEnum.useOfAnotherCar) as QuestionAnswer<string>
    const operatingInLondonArea = questions.find(q => q.name === QuestionEnum.operatingInLondonArea) as QuestionAnswer<string>
    const psvLicenceNumber = questions.find(q => q.name === QuestionEnum.psvLicenceNumber) as QuestionAnswer<string>
    const psvLicenceDate = questions.find(q => q.name === QuestionEnum.psvLicenceDate) as QuestionAnswer<Date>
    const leftHandDriveReason = questions.filter(q => q.name === QuestionEnum.leftHandDriveReason).pop() as QuestionAnswer<string>
    const selfLoad = questions.find(q => q.name === QuestionEnum.selfLoad) as QuestionAnswer<string>
    const anchoringLock = questions.find(q => q.name === QuestionEnum.anchoringLock) as QuestionAnswer<string>
    const wheelchairDescription = questions.find(q => q.name === QuestionEnum.wheelchairDescription) as QuestionAnswer<string>
    const adaptedForWheelchair = questions.find(q => q.name === QuestionEnum.adaptedForWheelchair) as QuestionAnswer<string>
    const userSpecifiedNumberOfDrivers = questions.find(q => q.name === QuestionEnum.userSpecifiedNumberOfDrivers) as QuestionAnswer<string>

    if (employmentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.Retired) {
      if (occupation)
        occupation.value = DLDCommonValuesEnum.Occupation.Retired
      if (businessCategory)
        businessCategory.value = DLDCommonValuesEnum.BusinessCategory.NonRetired
    }
    else if (employmentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.Unemployed) {
      if (occupation)
        occupation.value = DLDCommonValuesEnum.Occupation.Unemployed
      if (businessCategory)
        businessCategory.value = DLDCommonValuesEnum.BusinessCategory.NonUnemployed
    }
    else if (employmentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.FullOrPartTimeEducation) {
      if (occupation)
        occupation.value = DLDCommonValuesEnum.Occupation.Student
      if (businessCategory)
        businessCategory.value = DLDCommonValuesEnum.BusinessCategory.NoneStudent
    }
    else if (employmentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.FinanciallyAssisted) {
      if (occupation)
        occupation.value = DLDCommonValuesEnum.Occupation.NotEmployedDueToDisability
      if (businessCategory)
        businessCategory.value = DLDCommonValuesEnum.BusinessCategory.NoneNotEmployedduetoDisability
    }
    else if (employmentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.HouseholdDuties) {
      if (occupation)
        occupation.value = DLDCommonValuesEnum.Occupation.Housewife
      if (businessCategory)
        businessCategory.value = DLDCommonValuesEnum.BusinessCategory.NoneHouseholdDuties
    }
    else if (employmentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.IndependentMeans) {
      if (occupation)
        occupation.value = DLDCommonValuesEnum.Occupation.NotInEmployment
      if (businessCategory)
        businessCategory.value = DLDCommonValuesEnum.BusinessCategory.NotInEmployment
    }
    else if (employmentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.NotEmployedDueToDisability) {
      if (occupation)
        occupation.value = DLDCommonValuesEnum.Occupation.NotEmployedDueToDisability
      if (businessCategory)
        businessCategory.value = DLDCommonValuesEnum.BusinessCategory.NoneNotEmployedduetoDisability
    }

    if (secondaryEmploymentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.Retired) {
      if (secondaryOccupation)
        secondaryOccupation.value = DLDCommonValuesEnum.Occupation.Retired
      if (secondaryBusinessCategory)
        secondaryBusinessCategory.value = DLDCommonValuesEnum.BusinessCategory.NonRetired
    }
    else if (secondaryEmploymentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.Unemployed) {
      if (secondaryOccupation)
        secondaryOccupation.value = DLDCommonValuesEnum.Occupation.Unemployed
      if (secondaryBusinessCategory)
        secondaryBusinessCategory.value = DLDCommonValuesEnum.BusinessCategory.NonUnemployed
    }
    else if (secondaryEmploymentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.FullOrPartTimeEducation) {
      if (secondaryOccupation)
        secondaryOccupation.value = DLDCommonValuesEnum.Occupation.Student
      if (secondaryBusinessCategory)
        secondaryBusinessCategory.value = DLDCommonValuesEnum.BusinessCategory.NoneStudent
    }
    else if (secondaryEmploymentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.FinanciallyAssisted) {
      if (secondaryOccupation)
        secondaryOccupation.value = DLDCommonValuesEnum.Occupation.NotEmployedDueToDisability
      if (secondaryBusinessCategory)
        secondaryBusinessCategory.value = DLDCommonValuesEnum.BusinessCategory.NoneNotEmployedduetoDisability
    }
    else if (secondaryEmploymentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.HouseholdDuties) {
      if (secondaryOccupation)
        secondaryOccupation.value = DLDCommonValuesEnum.Occupation.Housewife
      if (secondaryBusinessCategory)
        secondaryBusinessCategory.value = DLDCommonValuesEnum.BusinessCategory.NoneHouseholdDuties
    }
    else if (secondaryEmploymentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.IndependentMeans) {
      if (secondaryOccupation)
        secondaryOccupation.value = DLDCommonValuesEnum.Occupation.NotInEmployment
      if (secondaryBusinessCategory)
        secondaryBusinessCategory.value = DLDCommonValuesEnum.BusinessCategory.NotInEmployment
    }
    else if (secondaryEmploymentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.NotEmployedDueToDisability) {
      if (secondaryOccupation)
        secondaryOccupation.value = DLDCommonValuesEnum.Occupation.NotEmployedDueToDisability
      if (secondaryBusinessCategory)
        secondaryBusinessCategory.value = DLDCommonValuesEnum.BusinessCategory.NoneNotEmployedduetoDisability
    }

    if ((!otherLicensingAuthorities?.visible) && otherLicensingAuthorities)
      otherLicensingAuthorities!.value = null

    // Claims, Convictions, Medicals, Modifications
    const claims = this.getClaims(questions)
    const convictions = this.getConvictions(questions)
    const medicalConditions = this.getMedicalConditions(questions)
    const modifications = this.getModifications(questions)

    // Addtional drivers
    const additionalDrivers = questions.find(q => q.name === QuestionEnum.additionalDriver) as QuestionAnswer<number>
    const adDrivers: AdditionalDriver[] = []

    if (additionalDrivers) {
      for (let i = 0; i < additionalDrivers.subPageAnswers.length; i += 1) {
        const subPage = additionalDrivers.subPageAnswers[i]
        const adTitle = subPage.answers.find(q => q.name === QuestionEnum.title) as QuestionAnswer<string>
        const adFirstName = subPage.answers.find(q => q.name === QuestionEnum.firstname) as QuestionAnswer<string>
        const adLastName = subPage.answers.find(q => q.name === QuestionEnum.lastName) as QuestionAnswer<string>
        const adDateOfBirth = subPage.answers.find(q => q.name === QuestionEnum.dateOfBirth) as QuestionAnswer<Date>
        const adDrivingLicence = subPage.answers.find(q => q.name === QuestionEnum.drivingLicence) as QuestionAnswer<string>
        const adRelationshipToPolicyholder = subPage.answers.find(q => q.name === QuestionEnum.relationshipToPolicyHolder) as QuestionAnswer<string>
        const adResidentSinceBirth = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.residentSince) as QuestionAnswer<string>
        const adEmploymentStatus = subPage.answers.find(q => q.name === QuestionEnum.employmentStatus) as QuestionAnswer<string>
        const adOccupation = subPage.answers.find(q => q.name === QuestionEnum.occupation) as QuestionAnswer<string>
        const adBusinessCategory = subPage.answers.find(q => q.name === QuestionEnum.businessCategory) as QuestionAnswer<string>
        const adSecondaryOccupation = subPage.answers.find(q => q.name === QuestionEnum.secondaryOccupation) as QuestionAnswer<string>
        const adlicenceDate = subPage.answers.find(q => q.name === QuestionEnum.licenceDate) as QuestionAnswer<Date>
        const adMainLicensingAuthority = subPage.answers.find(q => q.name === QuestionEnum.mainLicensingAuthority) as QuestionAnswer<string>
        const adtaxiBadgeSince = subPage.answers.find(q => q.name === QuestionEnum.taxiBadgeSince) as QuestionAnswer<Date>
        const adrideSharingApp = subPage.answers.find(q => q.name === QuestionEnum.rideSharingApp) as QuestionAnswer<string>
        const adMaritalStatus = subPage.answers.find(q => q.name === QuestionEnum.maritalStatus) as QuestionAnswer<string>
        const adClassOfUse = subPage.answers.find(q => q.name === QuestionEnum.classOfUse) as QuestionAnswer<string>
        const adHasConvictions = subPage.answers.find(q => q.name === QuestionEnum.hasConvictions) as QuestionAnswer<string>
        const adHasExistingMedicalConditions = subPage.answers.find(q => q.name === QuestionEnum.hasMedicalConditions) as QuestionAnswer<string>
        const adHasSecondaryEmployment = subPage.answers.find(q => q.name === QuestionEnum.hasSecondaryEmployment) as QuestionAnswer<string>
        const adSecondaryEmploymentStatus = subPage.answers.find(q => q.name === QuestionEnum.secondaryEmploymentStatus) as QuestionAnswer<string>
        const adSecondaryBusinessCategory = subPage.answers.find(q => q.name === QuestionEnum.secondaryBusinessCategory) as QuestionAnswer<string>
        const adLicenceType = subPage.answers.find(q => q.name === QuestionEnum.licenceType) as QuestionAnswer<string>
        const adDrivingFrequency = subPage.answers.find(q => q.name === QuestionEnum.drivingFrequency) as QuestionAnswer<string>
        const adHasClaims = subPage.answers.find(q => q.name === QuestionEnum.hasClaims) as QuestionAnswer<string>
        const adRestrictedLicense = subPage.answers.find(q => q.name === QuestionEnum.restrictedLicense) as QuestionAnswer<string>
        const adRestrictedLicenseType = subPage.answers.find(q => q.name === QuestionEnum.restrictedLicenseType) as QuestionAnswer<string>
        const adHasHadPolicyDeclaredVoid = subPage.answers.find(q => q.name === QuestionEnum.hasHadPolicyDeclaredVoid) as QuestionAnswer<string>
        const adVoidReason = subPage.answers.find(q => q.name === QuestionEnum.voidReason) as QuestionAnswer<string>
        const adResidentSince = subPage.answers.find(q => q.name === QuestionEnum.ukResidentSince) as QuestionAnswer<Date>
        const psvLicenceNumber = subPage.answers.find(q => q.name === QuestionEnum.psvLicenceNumber) as QuestionAnswer<string>
        const psvLicenceDate = subPage.answers.find(q => q.name === QuestionEnum.psvLicenceDate) as QuestionAnswer<Date>
        const operatingInLondonArea = subPage.answers.find(q => q.name === QuestionEnum.operatingInLondonArea) as QuestionAnswer<string>
        const adUseOfAnotherCar = subPage.answers.find(q => q.name === QuestionEnum.useOfAnotherCar) as QuestionAnswer<string>

        if (adEmploymentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.Retired) {
          if (adOccupation)
            adOccupation.value = DLDCommonValuesEnum.Occupation.Retired
          if (adBusinessCategory)
            adBusinessCategory.value = DLDCommonValuesEnum.BusinessCategory.NonRetired
        }
        else if (adEmploymentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.Unemployed) {
          if (adOccupation)
            adOccupation.value = DLDCommonValuesEnum.Occupation.Unemployed
          if (adBusinessCategory)
            adBusinessCategory.value = DLDCommonValuesEnum.BusinessCategory.NonUnemployed
        }
        else if (adEmploymentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.FullOrPartTimeEducation) {
          if (adOccupation)
            adOccupation.value = DLDCommonValuesEnum.Occupation.Student
          if (adBusinessCategory)
            adBusinessCategory.value = DLDCommonValuesEnum.BusinessCategory.NoneStudent
        }
        else if (adEmploymentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.FinanciallyAssisted) {
          if (adOccupation)
            adOccupation.value = DLDCommonValuesEnum.Occupation.NotEmployedDueToDisability
          if (adBusinessCategory)
            adBusinessCategory.value = DLDCommonValuesEnum.BusinessCategory.NoneNotEmployedduetoDisability
        }
        else if (adEmploymentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.HouseholdDuties) {
          if (adOccupation)
            adOccupation.value = DLDCommonValuesEnum.Occupation.Housewife
          if (adBusinessCategory)
            adBusinessCategory.value = DLDCommonValuesEnum.BusinessCategory.NoneHouseholdDuties
        }
        else if (adEmploymentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.IndependentMeans) {
          if (adOccupation)
            adOccupation.value = DLDCommonValuesEnum.Occupation.NotInEmployment
          if (adBusinessCategory)
            adBusinessCategory.value = DLDCommonValuesEnum.BusinessCategory.NotInEmployment
        }
        else if (adEmploymentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.NotEmployedDueToDisability) {
          if (adOccupation)
            adOccupation.value = DLDCommonValuesEnum.Occupation.NotEmployedDueToDisability
          if (adBusinessCategory)
            adBusinessCategory.value = DLDCommonValuesEnum.BusinessCategory.NoneNotEmployedduetoDisability
        }

        if (adSecondaryEmploymentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.Retired) {
          if (adSecondaryOccupation)
            adSecondaryOccupation.value = DLDCommonValuesEnum.Occupation.Retired
          if (adSecondaryBusinessCategory)
            adSecondaryBusinessCategory.value = DLDCommonValuesEnum.BusinessCategory.NonRetired
        }
        else if (adSecondaryEmploymentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.Unemployed) {
          if (adSecondaryOccupation)
            adSecondaryOccupation.value = DLDCommonValuesEnum.Occupation.Unemployed
          if (adSecondaryBusinessCategory)
            adSecondaryBusinessCategory.value = DLDCommonValuesEnum.BusinessCategory.NonUnemployed
        }
        else if (adSecondaryEmploymentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.FullOrPartTimeEducation) {
          if (adSecondaryOccupation)
            adSecondaryOccupation.value = DLDCommonValuesEnum.Occupation.Student
          if (adSecondaryBusinessCategory)
            adSecondaryBusinessCategory.value = DLDCommonValuesEnum.BusinessCategory.NoneStudent
        }
        else if (adSecondaryEmploymentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.FinanciallyAssisted) {
          if (adSecondaryOccupation)
            adSecondaryOccupation.value = DLDCommonValuesEnum.Occupation.NotEmployedDueToDisability
          if (adSecondaryBusinessCategory)
            adSecondaryBusinessCategory.value = DLDCommonValuesEnum.BusinessCategory.NoneNotEmployedduetoDisability
        }
        else if (adSecondaryEmploymentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.HouseholdDuties) {
          if (adSecondaryOccupation)
            adSecondaryOccupation.value = DLDCommonValuesEnum.Occupation.Housewife
          if (adSecondaryBusinessCategory)
            adSecondaryBusinessCategory.value = DLDCommonValuesEnum.BusinessCategory.NoneHouseholdDuties
        }
        else if (adSecondaryEmploymentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.IndependentMeans) {
          if (adSecondaryOccupation)
            adSecondaryOccupation.value = DLDCommonValuesEnum.Occupation.NotInEmployment
          if (adSecondaryBusinessCategory)
            adSecondaryBusinessCategory.value = DLDCommonValuesEnum.BusinessCategory.NotInEmployment
        }
        else if (adSecondaryEmploymentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.NotEmployedDueToDisability) {
          if (adSecondaryOccupation)
            adSecondaryOccupation.value = DLDCommonValuesEnum.Occupation.NotEmployedDueToDisability
          if (adSecondaryBusinessCategory)
            adSecondaryBusinessCategory.value = DLDCommonValuesEnum.BusinessCategory.NoneNotEmployedduetoDisability
        }

        adDrivers.push({
          title: adTitle.value!,
          firstName: adFirstName.value!,
          lastName: adLastName.value!,
          dateOfBirth: adDateOfBirth.value!,
          drivingLicence: adDrivingLicence.value!,
          licenceType: adLicenceType ? adLicenceType.value! : DLDCommonValuesEnum.CarLicenceType.FullUK,
          relationshiptoPolicyholder: adRelationshipToPolicyholder.value!,
          employmentStatus: adEmploymentStatus.value!,
          occupation: adOccupation.value!,
          secondaryOccupation: adSecondaryOccupation ? adSecondaryOccupation.value! : null,
          businessCategory: adBusinessCategory.value!,
          residentSince: adResidentSinceBirth && adResidentSinceBirth.value === DLDCommonValuesEnum.Boolean.Yes ? adDateOfBirth.value! : adResidentSince ? adResidentSince.value : null,
          hasMedicalConditions: adHasExistingMedicalConditions ? adHasExistingMedicalConditions.value === DLDCommonValuesEnum.Boolean.Yes : null,
          dvlaReportableMedicalConditions: null,
          claims: adHasClaims.value === DLDCommonValuesEnum.Boolean.Yes ? this.getClaims(additionalDrivers.subPageAnswers[i].answers) : [],
          convictions: adHasConvictions.value === DLDCommonValuesEnum.Boolean.Yes ? this.getConvictions(additionalDrivers.subPageAnswers[i].answers) : [],
          medicalConditions: adHasExistingMedicalConditions.value === DLDCommonValuesEnum.Boolean.Yes ? this.getMedicalConditions(additionalDrivers.subPageAnswers[i].answers) : [],
          prn: null,
          mainLicensingAuthority: adMainLicensingAuthority ? adMainLicensingAuthority.value! : null,
          farePayingPassengers: null,
          otherLicensingAuthorities: null,
          rideSharingApp: adrideSharingApp ? adrideSharingApp.value! === DLDCommonValuesEnum.Boolean.Yes : null,
          taxiBadgeHeldDate: adtaxiBadgeSince ? adtaxiBadgeSince.value! : null,
          licenceDate: adlicenceDate ? adlicenceDate.value! : null,
          hasHadPolicyDeclaredVoid: adHasHadPolicyDeclaredVoid ? adHasHadPolicyDeclaredVoid.value! === DLDCommonValuesEnum.Boolean.Yes : null,
          maritalStatus: adMaritalStatus ? adMaritalStatus.value! : null,
          classOfUse: adClassOfUse ? adClassOfUse.value! : null,
          otherVehiclesOwned: null,
          otherVehiclesYouDrive: null,
          useOfAnotherCar: adUseOfAnotherCar ? adUseOfAnotherCar.value! : null,
          hasConvictions: adHasConvictions ? adHasConvictions.value! === DLDCommonValuesEnum.Boolean.Yes : null,
          liveInVehicleFullTime: null,
          hadInsuranceRefused: null,
          hasCriminalConvictions: null,
          drivingFrequency: adDrivingFrequency ? adDrivingFrequency.value! : null,
          hasSecondaryEmployment: adHasSecondaryEmployment ? adHasSecondaryEmployment.value === DLDCommonValuesEnum.Boolean.Yes : null,
          secondaryEmploymentStatus: adSecondaryEmploymentStatus.value!,
          secondaryBusinessCategory: adBusinessCategory ? adBusinessCategory.value! : null,
          hasClaims: adHasClaims ? adHasClaims.value! === DLDCommonValuesEnum.Boolean.Yes : null,
          restrictedLicense: adRestrictedLicense ? adRestrictedLicense.value! === DLDCommonValuesEnum.Boolean.Yes : null,
          restrictedLicenseType: adRestrictedLicenseType.value!,
          voidReason: adVoidReason.value!,
          residentSinceBirth: adResidentSinceBirth.value! === DLDCommonValuesEnum.Boolean.Yes,
          hasCCJs: null,
          psvLicenceNumber: psvLicenceNumber ? psvLicenceNumber.value! : null,
          psvLicenceDate: psvLicenceDate ? psvLicenceDate.value! : null,
          operatingInLondonArea: operatingInLondonArea ? operatingInLondonArea.value! === DLDCommonValuesEnum.Boolean.Yes : null,
          pSVOLicenceNumber: null,
          pSVONamedProposer: null,
          hasPSVOLicence: null,
          pSVONamedProposerReason: null,
          noPSVOLicenseReason: null,
          address: null,
        })
      }
    }

    // Cover details
    const classOfUse = questions.find(q => q.name === QuestionEnum.classOfUse) as QuestionAnswer<string>
    const effectiveDateQuestion = questions.find(q => q.name === QuestionEnum.effectiveDate || q.name === QuestionEnum.coverEffectiveDate || q.name === QuestionEnum.mtaEffectiveDate) as QuestionAnswer<Date>
    const policyClass = questions.find(q => q.name === QuestionEnum.policyClass) as QuestionAnswer<string>
    const bikeClassOfUse = questions.find(q => q.name === QuestionEnum.bikeClassOfUse) as QuestionAnswer<string>
    const currentInsurer = questions.find(q => q.name === QuestionEnum.currentInsurer) as QuestionAnswer<string>
    const previousPolicyExpiration = questions.find(q => q.name === QuestionEnum.previousPolicyExpiration) as QuestionAnswer<Date>
    const claimFreeYears = questions.find(q => q.name === QuestionEnum.claimFreeYears) as QuestionAnswer<number>
    const coverTerm = questions.find(q => q.name === QuestionEnum.coverTerm) as QuestionAnswer<string>
    const hasPSVOLicence = questions.find(q => q.name === QuestionEnum.hasPSVOLicence) as QuestionAnswer<string>
    const pSVOLicenceNumber = questions.find(q => q.name === QuestionEnum.pSVOLicenceNumber) as QuestionAnswer<string>
    const pSVONamedProposer = questions.find(q => q.name === QuestionEnum.pSVNamedProposer) as QuestionAnswer<string>
    const pSVONamedProposerReason = questions.find(q => q.name === QuestionEnum.pSVONamedProposerReason) as QuestionAnswer<string>
    const noPSVOLicenseReason = questions.find(q => q.name === QuestionEnum.noPSVOLicenseReason) as QuestionAnswer<string>
    const hasTrailer = questions.find(q => q.name === QuestionEnum.hasTrailer) as QuestionAnswer<string>
    const trailerDetails = questions.find(q => q.name === QuestionEnum.trailerDetails) as QuestionAnswer<string>
    const trailerMaxCapacity = questions.find(q => q.name === QuestionEnum.trailerMaxCapacity) as QuestionAnswer<number>
    const trailerValue = questions.find(q => q.name === QuestionEnum.trailerValue) as QuestionAnswer<number>
    const trailerVIN = questions.find(q => q.name === QuestionEnum.trailerVIN) as QuestionAnswer<string>
    const carriesDangerousGoods = questions.find(q => q.name === QuestionEnum.carriesDangerousGoods) as QuestionAnswer<string>
    const storedGoodsDetails = questions.find(q => q.name === QuestionEnum.storedGoodsDetails) as QuestionAnswer<string>
    const typeOfGoods = questions.find(q => q.name === QuestionEnum.typeOfGoods) as QuestionAnswer<string>
    const vehicleUse = questions.find(q => q.name === QuestionEnum.vehicleUse) as QuestionAnswer<string>
    const seatbeltDetails = questions.find(q => q.name === QuestionEnum.seatbeltDetails) as QuestionAnswer<string>
    const seatDetails = questions.find(q => q.name === QuestionEnum.seatDetails) as QuestionAnswer<string>
    const allSeatsForwardFacing = questions.find(q => q.name === QuestionEnum.allSeatsForwardFacing) as QuestionAnswer<string>
    const allSeatsHaveSeatbelts = questions.find(q => q.name === QuestionEnum.allSeatsHaveSeatbelts) as QuestionAnswer<string>
    const percentageContract = questions.find(q => q.name === QuestionEnum.percentageContract) as QuestionAnswer<number>
    const storageLocation = questions.find(q => q.name === QuestionEnum.vehicleStorage) as QuestionAnswer<string>
    const loanWithPermits = questions.find(q => q.name === QuestionEnum.loanWithPermits) as QuestionAnswer<string>
    const roadsideProhibition = questions.find(q => q.name === QuestionEnum.roadsideProhibition) as QuestionAnswer<string>
    const sectionPermit = questions.find(q => q.name === QuestionEnum.sectionPermit) as QuestionAnswer<string>
    const numberOfTemporaryDays = questions.find(q => q.name === QuestionEnum.numberOfTemporaryDays) as QuestionAnswer<string>
    const coverType = questions.find(q => q.name === QuestionEnum.coverType) as QuestionAnswer<string>
    const hasSecondaryStorageAddress = questions.find(q => q.name === QuestionEnum.hasSecondaryStorageAddress) as QuestionAnswer<string>

    const quoteRequest = new Type()

    if (!(quoteRequest instanceof CarQuoteRequest || quoteRequest instanceof CarMTAQuoteRequest))
      throw new Error(`Unsupported quote request (${typeof quoteRequest}) for Quotax.`)

    // Proposer Details
    quoteRequest.proposer = new CarProposer()
    quoteRequest.proposer.title = title.value!
    quoteRequest.proposer.firstName = firstName.value!
    quoteRequest.proposer.lastName = lastName.value!
    quoteRequest.proposer.dateOfBirth = dateOfBirth.value!
    quoteRequest.proposer.residentSince = residentSinceBirth.value === DLDCommonValuesEnum.Boolean.Yes ? dateOfBirth.value! : residentSince.value
    quoteRequest.proposer.residentSinceBirth = residentSinceBirth.value === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.proposer.drivingLicence = drivingLicence.value!
    quoteRequest.proposer.licenceDate = licenceDate?.value
    quoteRequest.address = address.value!
    quoteRequest.proposer.address = address.value!
    quoteRequest.proposer.maritalStatus = maritalStatus?.value
    quoteRequest.proposer.prn = null
    quoteRequest.proposer.hasCCJs = hasCCJs?.value === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.proposer.homeOwner = homeOwner?.value === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.mileage = vehicleEstimatedMileage?.value
    quoteRequest.businessMileage = businessMileage?.value
    quoteRequest.proposer.occupation = occupation?.value
    quoteRequest.proposer.businessCategory = businessCategory?.value
    quoteRequest.proposer.employmentStatus = employmentStatus?.value
    quoteRequest.proposer.hasConvictions = hasConvictions?.value === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.proposer.hasCriminalConvictions = hasCriminalConvictions?.value === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.proposer.hadInsuranceRefused = hadInsuranceRefused?.value === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.proposer.restrictedLicense = restrictedLicense?.value === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.proposer.dvlaReportableMedicalConditions = restrictedLicense && restrictedLicense.value! === DLDCommonValuesEnum.Boolean.No ? null : restrictedLicenseType?.value
    quoteRequest.proposer.hasSecondaryEmployment = hasSecondaryEmployment?.value === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.proposer.licenceType = licenceType ? licenceType.value! : DLDCommonValuesEnum.CarLicenceType.FullUK
    quoteRequest.proposer.secondaryOccupation = secondaryOccupation?.visible ? secondaryOccupation.value! : null
    quoteRequest.proposer.secondaryEmploymentStatus = secondaryEmploymentStatus?.visible ? secondaryEmploymentStatus.value! : null
    quoteRequest.proposer.secondaryBusinessCategory = secondaryBusinessCategory?.visible ? secondaryBusinessCategory.value! : null
    quoteRequest.proposer.drivingFrequency = drivingFrequency?.value
    quoteRequest.proposer.hasClaims = hasClaims?.value === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.proposer.hasMedicalConditions = hasMedicalConditions?.value === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.proposer.useOfAnotherCar = useOfAnotherVehicle?.value
    quoteRequest.proposer.otherVehiclesOwned = useOfAnotherVehicle?.value === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.proposer.psvLicenceNumber = psvLicenceNumber?.visible ? psvLicenceNumber.value! : null
    quoteRequest.proposer.psvLicenceDate = psvLicenceDate?.value
    quoteRequest.proposer.operatingInLondonArea = operatingInLondonArea?.value === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.proposer.hasPSVOLicence = hasPSVOLicence?.value === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.proposer.pSVOLicenceNumber = pSVOLicenceNumber?.visible ? pSVOLicenceNumber.value! : null
    quoteRequest.proposer.pSVONamedProposer = pSVONamedProposer ? hasPSVOLicence?.value === DLDCommonValuesEnum.Boolean.Yes : null
    quoteRequest.proposer.pSVONamedProposerReason = pSVONamedProposerReason?.visible ? pSVONamedProposerReason?.value : null
    quoteRequest.proposer.noPSVOLicenseReason = noPSVOLicenseReason?.visible ? noPSVOLicenseReason?.value : null

    // Vehicle Details
    quoteRequest.car = this.bindCar(questions)
    quoteRequest.car.abiCode = vehicleLookup.value!.abiCode
    quoteRequest.car.address = primaryStorageAddress.value!
    quoteRequest.car.registration = vehicleLookup.value!.registration
    quoteRequest.car.value = vehiclevalue?.value ?? vehicleLookup.value!.value
    quoteRequest.car.cubicCapacity = vehicleLookup.value!.engineSize
    quoteRequest.car.purchaseDate = vehicleLookup.value!.purchaseDate
    quoteRequest.car.yearOfManufacture = vehicleLookup.value!.yearOfManufacture
    quoteRequest.car.keptAtHomeAddress = hasSecondaryStorageAddress.value === DLDCommonValuesEnum.Boolean.No
    quoteRequest.car.overnightParkLocation = vehicleOvernightParkLocation ? vehicleOvernightParkLocation.value! : null
    quoteRequest.car.registeredKeeper = vehicleKeeper ? vehicleKeeper.value! : DLDCommonValuesEnum.RegisteredKeeper.ProposerPolicyHolder
    quoteRequest.car.registeredOwner = vehicleOwner ? vehicleOwner.value! : DLDCommonValuesEnum.RegisteredKeeper.ProposerPolicyHolder
    quoteRequest.car.noOfSeats = vehicleSeats ? vehicleSeats.value! : null
    quoteRequest.car.fuelType = vehicleFuelType?.value || vehicleLookup?.value?.fuelType || null
    quoteRequest.car.transmission = vehicleTransmissionType?.value || vehicleLookup?.value?.transmissionType || null
    quoteRequest.car.isVehicleKeptOutsideUK = ukUseOnly ? ukUseOnly.value === DLDCommonValuesEnum.Boolean.Yes : false
    quoteRequest.car.isUsedForPrivateHire = isUsedForPrivateHire ? isUsedForPrivateHire.value === DLDCommonValuesEnum.Boolean.Yes : null
    quoteRequest.car.IsVehicleRegisteredInUK = ukUseOnly ? ukUseOnly.value === DLDCommonValuesEnum.Boolean.No : false
    quoteRequest.car.vehicleType = isMinibus && isMinibus.value === DLDCommonValuesEnum.Boolean.Yes ? DLDCommonValuesEnum.CommercialVehicleVehicleType.Minibus : null
    quoteRequest.car.hasTrailer = hasTrailer ? hasTrailer.value === DLDCommonValuesEnum.Boolean.Yes : null
    quoteRequest.car.trailerDetails = trailerDetails ? trailerDetails.value! : null
    quoteRequest.car.trailerMaxCapacity = trailerMaxCapacity ? trailerMaxCapacity.value! : null
    quoteRequest.car.trailerValue = trailerValue ? trailerValue.value! : null
    quoteRequest.car.trailerVIN = trailerVIN ? trailerVIN.value! : null
    quoteRequest.car.carriesDangerousGoods = carriesDangerousGoods ? carriesDangerousGoods.value === DLDCommonValuesEnum.Boolean.Yes : null
    quoteRequest.car.storedGoodsDetails = storedGoodsDetails ? storedGoodsDetails.value! : null
    quoteRequest.car.typeOfGoods = typeOfGoods ? typeOfGoods.value! : null
    quoteRequest.car.vehicleUse = vehicleUse ? vehicleUse.value! : null
    quoteRequest.car.allSeatsForwardFacing = allSeatsForwardFacing ? allSeatsForwardFacing.value === DLDCommonValuesEnum.Boolean.Yes : null
    quoteRequest.car.allSeatsHaveSeatbelts = allSeatsHaveSeatbelts ? allSeatsHaveSeatbelts.value === DLDCommonValuesEnum.Boolean.Yes : null
    quoteRequest.car.seatDetails = seatDetails ? seatDetails.value! : null
    quoteRequest.car.seatbeltDetails = seatbeltDetails ? seatbeltDetails.value! : null
    quoteRequest.car.leftHandDriveReason = leftHandDriveReason ? leftHandDriveReason.value! : null
    quoteRequest.car.wheelchairsAdapted = adaptedForWheelchair ? adaptedForWheelchair.value === DLDCommonValuesEnum.Boolean.Yes : null
    quoteRequest.car.selfLoadDevice = selfLoad ? selfLoad.value === DLDCommonValuesEnum.Boolean.Yes : null
    quoteRequest.car.anchoringLocking = anchoringLock ? anchoringLock.value === DLDCommonValuesEnum.Boolean.Yes : null
    quoteRequest.car.wheelchairsAdaptedDescription = wheelchairDescription ? wheelchairDescription.value! : null
    quoteRequest.car.sectionPermit = sectionPermit ? sectionPermit.value! === DLDCommonValuesEnum.Boolean.Yes : null
    quoteRequest.car.loanWithPermits = loanWithPermits ? loanWithPermits.value! === DLDCommonValuesEnum.Boolean.Yes : null
    quoteRequest.car.roadsideProhibition = roadsideProhibition ? roadsideProhibition.value! === DLDCommonValuesEnum.Boolean.Yes : null
    quoteRequest.car.percentageContract = percentageContract ? percentageContract.value! : null
    quoteRequest.car.modifications = modifications
    quoteRequest.car.vehicleStorage = storageLocation ? storageLocation.value! : null

    // Claims, Convictions, Medicals
    if (noClaimsBonus)
      quoteRequest.proposer.noClaimsYears = this.getNoClaimsYears(noClaimsBonus.value!)

    quoteRequest.noClaimsProtection = noClaimsProtection && noClaimsProtection.value!
    quoteRequest.proposer.claims = hasClaims && hasClaims.value! === DLDCommonValuesEnum.Boolean.Yes ? claims : []
    quoteRequest.proposer.medicalConditions = hasMedicalConditions && hasMedicalConditions.value! === DLDCommonValuesEnum.Boolean.Yes ? medicalConditions : []
    quoteRequest.proposer.convictions = hasConvictions && hasConvictions.value! === DLDCommonValuesEnum.Boolean.Yes ? convictions : []

    // Cover Details
    quoteRequest.classOfUse = bikeClassOfUse?.value ?? classOfUse?.value ?? DLDCommonValuesEnum.ClassOfUse.SDPOnly
    quoteRequest.effectiveDate = effectiveDateQuestion ? effectiveDateQuestion.value! : new Date()
    quoteRequest.voluntaryExcess = voluntaryExcess?.value ?? DLDCommonValuesEnum.VolXs.VolXs0
    quoteRequest.coverType = coverType.value!
    quoteRequest.coverTerm = numberOfTemporaryDays && numberOfTemporaryDays.visible ? numberOfTemporaryDays.value! : coverTerm?.value
    quoteRequest.policyClass = policyClass?.value ?? DLDCommonValuesEnum.PolicyClass.PrivateCar
    quoteRequest.currentInsurer = currentInsurer ? currentInsurer.value! : DLDCommonValuesEnum.CurrentInsurer.NotListed
    quoteRequest.policyEndDate = previousPolicyExpiration ? previousPolicyExpiration.value! : null
    quoteRequest.claimFreeYears = claimFreeYears ? claimFreeYears.value! : null
    quoteRequest.businessSourceId = referralSource?.value
    quoteRequest.userSpecifiedNumberOfDrivers = userSpecifiedNumberOfDrivers?.value

    // Taxi Only
    quoteRequest.proposer.mainLicensingAuthority = mainLicensingAuthority ? mainLicensingAuthority.value! : null
    quoteRequest.proposer.otherLicensingAuthorities = otherLicensingAuthorities ? otherLicensingAuthorities.value! : null
    quoteRequest.proposer.farePayingPassengers = farePayingPassengers ? farePayingPassengers.value! === DLDCommonValuesEnum.Boolean.Yes : null
    quoteRequest.proposer.taxiBadgeHeldDate = taxiBadgeSince ? taxiBadgeSince.value! : null
    quoteRequest.proposer.rideSharingApp = rideSharingApp ? rideSharingApp.value! === DLDCommonValuesEnum.Boolean.Yes : null
    quoteRequest.proposer.hasHadPolicyDeclaredVoid = hasHadPolicyDeclaredVoid ? hasHadPolicyDeclaredVoid.value! === DLDCommonValuesEnum.Boolean.Yes : null
    quoteRequest.proposer.isDriverForUber = isDriverForUber ? isDriverForUber.value! === DLDCommonValuesEnum.Boolean.Yes : null
    quoteRequest.proposer.uberBranch = uberBranch ? uberBranch.value! : null
    quoteRequest.proposer.uberProRating = uberProRating ? uberProRating.value! : null
    quoteRequest.car.isModified = modifications.length > 0

    // Courier Only
    quoteRequest.car.vehicleWeight = vehicleWeight ? vehicleWeight.value! : null
    quoteRequest.car.workRadius = workRadius ? workRadius.value! : null
    quoteRequest.car.numberOfDrops = numberOfDrops ? numberOfDrops.value! : null
    quoteRequest.proposer.claimsFreeExperienceOnAnotherVehicle = claimsFreeExperienceOnAnotherVehicle ? claimsFreeExperienceOnAnotherVehicle.value! : null

    quoteRequest.additionalDrivers = adDrivers
    quoteRequest.registeredOutsideUKReason = registeredOutsideUKReason ? registeredOutsideUKReason.value! : null

    return quoteRequest
  }

  private getClaims(questions: IQuestionAnswer[]): Claim[] {
    const claimsQuestion = questions.find(q => q.name === QuestionEnum.claims) as QuestionAnswer<number>
    const claims: Claim[] = []

    if (claimsQuestion) {
      for (let i = 0; i < claimsQuestion.subPageAnswers.length; i += 1) {
        const claimDate = claimsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.claimDate) as QuestionAnswer<Date>
        const incidentDate = claimsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.incidentDate) as QuestionAnswer<Date>
        const claimType = claimsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.claimType) as QuestionAnswer<string>
        const claimStatus = claimsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.claimStatus) as QuestionAnswer<string>
        const claimFault = claimsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.claimFault) as QuestionAnswer<string>
        const claimPersonsInjured = claimsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.claimPersonsInjured) as QuestionAnswer<string>
        const claimCost = claimsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.claimCost) as QuestionAnswer<number>
        const ncbLost = claimsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.ncbLost) as QuestionAnswer<string>

        claims.push({
          claimDate: claimDate.value!,
          incidentDate: incidentDate ? incidentDate.value! : null,
          claimType: claimType.value!,
          claimStatus: claimStatus.value!,
          claimFault: claimFault.value! === DLDCommonValuesEnum.Boolean.Yes,
          claimPersonsInjured: claimPersonsInjured.value! === DLDCommonValuesEnum.Boolean.Yes,
          claimCost: claimCost.value!,
          ncbLost: ncbLost.value! === DLDCommonValuesEnum.Boolean.Yes,
        })
      }
    }
    return claims
  }

  private getConvictions(questions: IQuestionAnswer[]): Conviction[] {
    const convictionsQuestion = questions.find(q => q.name === QuestionEnum.convictions) as QuestionAnswer<number>
    const convictions: Conviction[] = []

    if (convictionsQuestion) {
      for (let i = 0; i < convictionsQuestion.subPageAnswers.length; i += 1) {
        const convictionDate = convictionsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.convictionDate) as QuestionAnswer<Date>
        const convictionType = convictionsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.convictionType) as QuestionAnswer<string>
        const convictionPersonsInjured = convictionsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.convictionPersonsInjured) as QuestionAnswer<string>
        const convictionDisqualificationMonths = convictionsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.convictionDisqualificationMonths) as QuestionAnswer<number>
        const convictionFine = convictionsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.convictionFine) as QuestionAnswer<number>
        const convictionPointsReceived = convictionsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.convictionPointsReceived) as QuestionAnswer<number>

        convictions.push({
          convictionDate: convictionDate.value!,
          convictionType: convictionType.value!,
          convictionPersonsInjured: convictionPersonsInjured.value! === DLDCommonValuesEnum.Boolean.Yes,
          convictionDisqualificationMonths: convictionDisqualificationMonths.value!,
          convictionFine: convictionFine.value!,
          convictionPointsReceived: convictionPointsReceived.value!,
          convictionIsPending: null,
        })
      }
    }
    return convictions
  }

  private getModifications(questions: IQuestionAnswer[]): Modification[] {
    const modificationQuestions = questions.find(q => q.name === QuestionEnum.modifications) as QuestionAnswer<number>
    const isVehicleMod = questions.filter(q => q.name === QuestionEnum.isVehicleModified).pop() as QuestionAnswer<string>
    const modifications: Modification[] = []
    if (modificationQuestions && isVehicleMod.visible && isVehicleMod.value === DLDCommonValuesEnum.Boolean.Yes) {
      for (let i = 0; i < modificationQuestions.subPageAnswers.length; i += 1) {
        const mod = modificationQuestions.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.modification) as QuestionAnswer<string>
        modifications.push({
          carRiskVehicleModification: mod.value!,
        })
      }
    }
    return modifications
  }

  private getMedicalConditions(questions: IQuestionAnswer[]): MedicalCondition[] {
    const medicalConditionsQuestion = questions.find(q => q.name === QuestionEnum.medicalconditions) as QuestionAnswer<number>
    const medicalConditions: MedicalCondition[] = []

    if (medicalConditionsQuestion) {
      for (let i = 0; i < medicalConditionsQuestion.subPageAnswers.length; i += 1) {
        const incidentDate = medicalConditionsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.incidentDate) as QuestionAnswer<Date>
        const medicalCondition = medicalConditionsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.medicalCondition) as QuestionAnswer<string>
        const hasInformedDVLAOfMedicalConditionsAndDisabilities = medicalConditionsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.hasInformedDVLAOfMedicalConditionsAndDisabilities) as QuestionAnswer<string>
        const dVLAReportableMedicalConditions = medicalConditionsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.dvlaReportableMedicalConditions) as QuestionAnswer<string>

        medicalConditions.push({
          incidentDate: incidentDate.value!,
          medicalCondition: medicalCondition.value!,
          hasInformedDVLAOfMedicalConditionsAndDisabilities: hasInformedDVLAOfMedicalConditionsAndDisabilities.value! === DLDCommonValuesEnum.Boolean.Yes,
          dvlaReportableMedicalConditions: dVLAReportableMedicalConditions.value!,
        })
      }
    }
    return medicalConditions
  }

  getMTARequest<T extends QuoteRequest>(questions: IQuestionAnswer[], Type: new () => T): T {
    const mtaRequest = this.getQuoteRequest(questions, Type)

    if (!(mtaRequest instanceof CarMTAQuoteRequest))
      throw new Error(`Unsupported quote request (${typeof mtaRequest}) for Quotax.`)

    return mtaRequest
  }

  getAccountRegisterRequest(questions: IQuestionAnswer[], affiliateId: number | null): AccountRegister | null {
    const title = questions.find(q => q.name === QuestionEnum.title) as QuestionAnswer<string>
    const firstName = questions.find(q => q.name === QuestionEnum.firstname) as QuestionAnswer<string>
    const lastName = questions.find(q => q.name === QuestionEnum.lastName) as QuestionAnswer<string>
    const email = questions.find(q => q.name === QuestionEnum.customerEmail) as QuestionAnswer<string>
    const dob = questions.find(q => q.name === QuestionEnum.dateOfBirth) as QuestionAnswer<Date>
    const address = questions.find(q => q.name === QuestionEnum.address) as QuestionAnswer<Address>
    const phoneNumber = questions.find(q => q.name === QuestionEnum.mobilePhoneNumber) as QuestionAnswer<string>
    const autoRenew = questions.find(q => q.name === QuestionEnum.autoRenew) as QuestionAnswer<boolean>

    if (!title?.value || !firstName?.value || !lastName?.value || !email?.value || !address?.value)
      return null

    const request = new AccountRegister()
    request.title = title.value!
    request.firstName = firstName.value!
    request.lastName = lastName.value!
    request.email = email.value!
    request.dateOfBirth = dob.value!
    request.address = address.value!
    request.mobilePhone = phoneNumber.value!
    request.autoRenew = autoRenew?.value

    request.marketing = new Marketing()
    request.marketing.isEmailOptIn = false
    request.marketing.isTelephoneOptIn = false
    request.marketing.isSMSOptIn = false
    request.marketing.isPostOptIn = false

    request.affiliateId = affiliateId
    return request
  }

  updateAccountFromQuestions(account: Account, questions: IQuestionAnswer[]): Account {
    const title = questions.find(q => q.name === QuestionEnum.title) as QuestionAnswer<string>
    const firstName = questions.find(q => q.name === QuestionEnum.firstname) as QuestionAnswer<string>
    const lastName = questions.find(q => q.name === QuestionEnum.lastName) as QuestionAnswer<string>
    const email = questions.find(q => q.name === QuestionEnum.customerEmail) as QuestionAnswer<string>
    const phoneNumber = questions.find(q => q.name === QuestionEnum.mobilePhoneNumber || q.name === QuestionEnum.customerHomePhoneNumber) as QuestionAnswer<string>
    const marketing = questions.find(q => q.name === QuestionEnum.keepingYouInformed) as QuestionAnswer<string[]>
    const address = questions.find(q => q.name === QuestionEnum.address) as QuestionAnswer<Address>
    const autoRenew = questions.find(q => q.name === QuestionEnum.autoRenew) as QuestionAnswer<boolean>

    account.autoRenew = autoRenew?.value ?? account.autoRenew

    account.title = title?.value ?? account.title
    account.firstName = firstName?.value ?? account.firstName
    account.lastName = lastName?.value ?? account.lastName
    account.email = email?.value ?? account.email
    account.homePhone = phoneNumber?.value ?? account.homePhone
    account.mobilePhone = phoneNumber?.value ?? account.mobilePhone
    account.telephone = phoneNumber?.value ?? account.telephone
    account.workPhone = phoneNumber?.value ?? account.workPhone

    if (marketing?.value) {
      account.marketing.isEmailOptIn = marketing.value?.indexOf(DLDCommonValuesEnum.MarketingOption.Email) !== -1
      account.marketing.isSMSOptIn = marketing.value?.indexOf(DLDCommonValuesEnum.MarketingOption.SMS) !== -1
      account.marketing.isPostOptIn = marketing.value?.indexOf(DLDCommonValuesEnum.MarketingOption.Post) !== -1
      account.marketing.isTelephoneOptIn = marketing.value?.indexOf(DLDCommonValuesEnum.MarketingOption.Telephone) !== -1
    }
    account.address = address?.value ?? account.address

    return account
  }

  nbPageMappings: Dictionary<{ route: Routes, contextLevel: ContextLevel }> = {
    // Taxi
    Taxi_Proposer_CustomerFacing: { route: Routes.proposerDetails, contextLevel: ContextLevel.preQuote },
    Taxi_Vehicle_Details_CustomerFacing: { route: Routes.vehicleDetails, contextLevel: ContextLevel.preQuote },
    Taxi_Cover_Details_CustomerFacing: { route: Routes.coverDetails, contextLevel: ContextLevel.preQuote },
    Taxi_Quote_CustomerFacing: { route: Routes.quote, contextLevel: ContextLevel.quote },
    Taxi_Declarations_CustomerFacing: { route: Routes.preQuoteDeclarations, contextLevel: ContextLevel.preQuote },
    // Commercial
    CommercialVehicle_Declarations_CustomerFacing: { route: Routes.preQuoteDeclarations, contextLevel: ContextLevel.preQuote },
    CommercialVehicle_Proposer_CustomerFacing: { route: Routes.proposerDetails, contextLevel: ContextLevel.preQuote },
    CommercialVehicle_Vehicle_Details_CustomerFacing: { route: Routes.vehicleDetails, contextLevel: ContextLevel.preQuote },
    CommercialVehicle_Cover_Details_CustomerFacing: { route: Routes.coverDetails, contextLevel: ContextLevel.preQuote },
    CommercialVehicle_Quote_CustomerFacing: { route: Routes.quote, contextLevel: ContextLevel.quote },
    // Breakdown
    Breakdown_Details_CustomerFacing: { route: Routes.coverDetails, contextLevel: ContextLevel.preQuote },
    Breakdown_Quote_CustomerFacing: { route: Routes.quote, contextLevel: ContextLevel.quote },
  }

  mtaPageMappings: Dictionary<{ route: Routes, contextLevel: ContextLevel }> = {
    Taxi_ProposerMTA_CustomerFacing: { route: Routes.mtaProposerDetails, contextLevel: ContextLevel.mtaPreQuote },
    Taxi_Vehicle_DetailsMTA_CustomerFacing: { route: Routes.mtaVehicleDetails, contextLevel: ContextLevel.mtaPreQuote },
    Taxi_PremiumMTA_CustomerFacing: { route: Routes.mtaPremium, contextLevel: ContextLevel.mtaQuote },
    Taxi_Payment: { route: Routes.mtaPayment, contextLevel: ContextLevel.mtaQuote },
    Taxi_PaymentConfirmationMTA_CustomerFacing: { route: Routes.mtaConfirm, contextLevel: ContextLevel.mtaTakenUp },
    // Commercial
    CommercialVehicle_ProposerMta_CustomerFacing: { route: Routes.mtaProposerDetails, contextLevel: ContextLevel.mtaPreQuote },
    CommercialVehicle_Vehicle_DetailsMta_CustomerFacing: { route: Routes.mtaVehicleDetails, contextLevel: ContextLevel.mtaPreQuote },
    CommercialVehicle_Cover_DetailsMta_CustomerFacing: { route: Routes.mtaCoverDetails, contextLevel: ContextLevel.mtaPreQuote },
    CommercialVehicle_PremiumMta_CustomerFacing: { route: Routes.mtaPremium, contextLevel: ContextLevel.mtaQuote },
    CommercialVehicle_PaymentMta_CustomerFacing: { route: Routes.mtaPayment, contextLevel: ContextLevel.mtaQuote },
    CommercialVehicle_PaymentConfirmationMta_CustomerFacing: { route: Routes.mtaConfirm, contextLevel: ContextLevel.mtaTakenUp },
  }

  renewalPageMappings: Dictionary<{ route: Routes, contextLevel: ContextLevel }> = {
    Taxi_ProposerRenewal_CustomerFacing: { route: Routes.renewalProposerDetails, contextLevel: ContextLevel.renewalPreQuote },
    Taxi_Vehicle_DetailsRenewal_CustomerFacing: { route: Routes.renewalVehicleDetails, contextLevel: ContextLevel.renewalPreQuote },
    Taxi_PremiumRenewal_CustomerFacing: { route: Routes.renewalPremium, contextLevel: ContextLevel.renewalQuote },
    Taxi_PaymentRenewal_CustomerFacing: { route: Routes.renewalPayment, contextLevel: ContextLevel.renewalQuote },
    Taxi_PaymentConfirmation: { route: Routes.renewalPaymentConfirmation, contextLevel: ContextLevel.renewalTakenUp },
    // Commercial
    CommercialVehicle_ProposerRenewal_CustomerFacing: { route: Routes.proposerDetails, contextLevel: ContextLevel.renewalPreQuote },
    CommercialVehicle_Vehicle_DetailsRenewal_CustomerFacing: { route: Routes.vehicleDetails, contextLevel: ContextLevel.renewalPreQuote },
    CommercialVehicle_Cover_DetailsRenewal_CustomerFacing: { route: Routes.coverDetails, contextLevel: ContextLevel.renewalPreQuote },
    CommercialVehicle_PremiumRenewal_CustomerFacing: { route: Routes.premium, contextLevel: ContextLevel.renewalQuote },
    CommercialVehicle_PaymentRenewal_CustomerFacing: { route: Routes.renewalPayment, contextLevel: ContextLevel.renewalQuote },
    CommercialVehicle_PaymentConfirmationRenewal_CustomerFacing: { route: Routes.renewalPaymentConfirmation, contextLevel: ContextLevel.renewalTakenUp },
  }

  setupPage(setId: string, productPage: ProductPage, answers: IQuestionAnswer[]): Page {
    const mappedPage = this.mapProductPageToPage(productPage)
    return this.setupDependencies(setId, mappedPage, answers)
  }

  setupDependencies(setId: string, mappedPage: Page, _answers: IQuestionAnswer[]): Page {
    switch (setId) {
      case 'CommercialVehicle_Declarations_CustomerFacing':
        return this.setupDeclarationsCommercial(mappedPage)
      case 'Taxi_Declarations_CustomerFacing':
        return this.setupDeclarations(mappedPage)
      case 'CommercialVehicle_Cover_Details_CustomerFacing':
      case 'CommercialVehicle_Cover_DetailsMta_CustomerFacing':
      case 'CommercialVehicle_Cover_DetailsRenewal_CustomerFacing':
      case 'Taxi_Cover_Details_CustomerFacing':
        return this.setupCoverDependencies(mappedPage)
      case 'Taxi_Proposer_CustomerFacing':
      case 'Taxi_ProposerRenewal_CustomerFacing':
      case 'Taxi_ProposerMTA_CustomerFacing':
        return this.setupProposerDependencies(mappedPage)
      case 'CommercialVehicle_Proposer_CustomerFacing':
      case 'CommercialVehicle_ProposerRenewal_CustomerFacing':
      case 'CommercialVehicle_ProposerMta_CustomerFacing':
        return this.setupCommercialProposerDependencies(mappedPage)
      case 'Taxi_Proposer_CustomerFacing_AdditionalDriver':
      case 'Taxi_ProposerRenewal_CustomerFacing_AdditionalDriver':
      case 'Taxi_ProposerMTA_CustomerFacing_AdditionalDriver':
      case 'CommercialVehicle_Proposer_CustomerFacing_AdditionalDriver':
      case 'CommercialVehicle_ProposerMta_CustomerFacing_AdditionalDriver':
      case 'CommercialVehicle_ProposerRenewal_CustomerFacing_AdditionalDriver':
        return this.setupAdditionalDriverDependencies(mappedPage)
      case 'Taxi_AggregatorLanding':
      case 'Taxi_AggregatorLanding_AdditionalDriver':
        return this.setupAggregatorLandingDependencies(mappedPage)
      case 'Taxi_Vehicle_Details_CustomerFacing':
      case 'Taxi_Vehicle_DetailsRenewal_CustomerFacing':
      case 'Taxi_Vehicle_DetailsMTA_CustomerFacing':
        return this.setupVehicleDependencies(mappedPage)
      case 'Taxi_MedicalCondition':
      case 'Taxi_Proposer_CustomerFacing_AdditionalDriver_Medicals':
      case 'CommercialVehicle_MedicalConditions_CustomerFacing':
        return this.setupMedicalDependencies(mappedPage)
      case 'Taxi_Proposer_CustomerFacing_AdditionalDriver_Convictions':
      case 'CommercialVehicle_Convictions_CustomerFacing':
        return this.setupConvictions(mappedPage)
      case 'Taxi_Proposer_CustomerFacing_Claims':
      case 'Taxi_ProposerRenewal_CustomerFacing_Claims':
      case 'Taxi_Proposer_CustomerFacing_AdditionalDriver_Claims':
      case 'Taxi_ProposerMta_CustomerFacing_AdditionalDriver_Claims':
      case 'CommercialVehicle_Claims_CustomerFacing':
      case 'CommercialVehicle_ProposerMta_Claims_CustomerFacing':
      case 'CommercialVehicle_ProposerRenewal_Claims_CustomerFacing':
      case 'Taxi_ProposerMta_CustomerFacing_Claims':
      case 'Taxi_ProposerRenewal_CustomerFacing_AdditionalDriver_Claims':
        return this.setupClaims(mappedPage)
      case 'CommercialVehicle_Vehicle_Details_CustomerFacing':
      case 'CommercialVehicle_Vehicle_DetailsRenewal_CustomerFacing':
      case 'CommercialVehicle_Vehicle_DetailsMta_CustomerFacing':
        return this.setupCommercialVehicleDependencies(mappedPage)
      case 'Breakdown_Details_CustomerFacing':
        return this.setupBreakdown(mappedPage)
      case 'CommercialVehicle_Quote_CustomerFacing':
      case 'Taxi_VAPS_CustomerFacing':
      case 'Taxi_Quote_CustomerFacing':
      case 'Taxi_Premium_CustomerFacing':
      case 'Courier_Premium_CustomerFacing':
      case 'Taxi_PremiumRenewal_CustomerFacing':
      case 'Taxi_PremiumMTA_CustomerFacing':
      case 'CommercialVehicle_Premium_CustomerFacing':
      case 'CommercialVehicle_PremiumRenewal_CustomerFacing':
      case 'CommercialVehicle_PremiumMta_CustomerFacing':
      case 'Courier_Declarations_CustomerFacing':
      case 'Taxi_Payment':
      case 'Courier_Payment':
      case 'Taxi_PaymentRenewal_CustomerFacing':
      case 'Taxi_PaymentConfirmation':
      case 'Courier_PaymentConfirmation':
      case 'Taxi_PaymentConfirmationMTA_CustomerFacing':
      case 'Account':
      case 'CommercialVehicle_Payment_CustomerFacing':
      case 'CommercialVehicle_PaymentMta_CustomerFacing':
      case 'CommercialVehicle_PaymentRenewal_CustomerFacing':
      case 'CommercialVehicle_PaymentConfirmation_CustomerFacing':
      case 'CommercialVehicle_PaymentConfirmationMta_CustomerFacing':
      case 'CommercialVehicle_PaymentConfirmationRenewal_CustomerFacing':
      case 'CommercialVehicle_Proposer_CustomerFacing_Modifications':
      case 'Breakdown_Quote_CustomerFacing':
        return mappedPage
      default:
        throw new UnkownQuestionSetError(setId)
    }
  }

  private setupDeclarationsCommercial(page: Page): Page {
    const questions = page.sections.flatMap(s => s.questions)
    const insuringAMinibus = questions.find(q => q.name === QuestionEnum.insuringAMinibus) as SelectQuestion<string, string>
    const isVehicleRegisteredOutsideUK = questions.find(q => q.name === QuestionEnum.isVehicleRegisteredOutsideUK) as SelectQuestion<string, string>
    const isVehicleModified = questions.find(q => q.name === QuestionEnum.isVehicleModified) as SelectQuestion<string, string>
    const customWarning = questions.find(q => q.name === QuestionEnum.customWarning) as SelectQuestion<string, string>
    const isVehicleRightHandDrive = questions.find(q => q.name === QuestionEnum.isVehicleRightHandDrive) as SelectQuestion<string, string>
    const declaration14 = questions.find(q => q.name === QuestionEnum.declaration14) as SelectQuestion<string, string>
    const hasCcjs = questions.find(q => q.name === QuestionEnum.hasCcjs) as SelectQuestion<string, string>
    const hasHadPolicyDeclaredVoid = questions.find(q => q.name === QuestionEnum.hasHadPolicyDeclaredVoid) as SelectQuestion<string, string>
    const hasCriminalConvictions = questions.find(q => q.name === QuestionEnum.hasCriminalConvictions) as SelectQuestion<string, string>

    declaration14.isVisible
      = isVehicleRegisteredOutsideUK.isVisible
        = isVehicleModified.isVisible
          = isVehicleRightHandDrive.isVisible = () => insuringAMinibus.value === DLDCommonValuesEnum.Boolean.No

    customWarning.isVisible = () =>
      insuringAMinibus.value === DLDCommonValuesEnum.Boolean.Yes
        ? hasCriminalConvictions.value === DLDCommonValuesEnum.Boolean.Yes || hasCcjs.value === DLDCommonValuesEnum.Boolean.Yes || hasHadPolicyDeclaredVoid.value === DLDCommonValuesEnum.Boolean.Yes
        : hasCriminalConvictions.value === DLDCommonValuesEnum.Boolean.Yes || hasCcjs.value === DLDCommonValuesEnum.Boolean.Yes || hasHadPolicyDeclaredVoid.value === DLDCommonValuesEnum.Boolean.Yes
        || isVehicleRegisteredOutsideUK.value === DLDCommonValuesEnum.Boolean.Yes || isVehicleModified.value === DLDCommonValuesEnum.Boolean.Yes || isVehicleRightHandDrive.value === DLDCommonValuesEnum.Boolean.No

    return page
  }

  private setupBreakdown(page: Page): Page {
    const questions = page.sections.flatMap(s => s.questions)
    const effectiveDateQuestion = questions.find(q => q.name === QuestionEnum.coverEffectiveDate) as DateQuestion
    const dateOfBirthQuestion = questions.find(q => q.name === QuestionEnum.dateOfBirth) as DateQuestion

    if (effectiveDateQuestion) {
      effectiveDateQuestion.minDate = () => moment().toDate()
      effectiveDateQuestion.maxDate = () => moment().add(31, 'days').toDate()
    }

    if (dateOfBirthQuestion)
      dateOfBirthQuestion.maxDate = () => moment().toDate()

    return page
  }

  private setupDeclarations(page: Page): Page {
    const questions = page.sections.flatMap(s => s.questions)
    const hasCriminalConvictions = questions.find(q => q.name === QuestionEnum.hasCriminalConvictions) as SelectQuestion<string, string>
    const hasCcjs = questions.find(q => q.name === QuestionEnum.hasCcjs) as SelectQuestion<string, string>
    const hasHadPolicyDeclaredVoid = questions.find(q => q.name === QuestionEnum.hasHadPolicyDeclaredVoid) as SelectQuestion<string, string>
    const isVehicleRegisteredOutsideUK = questions.find(q => q.name === QuestionEnum.isVehicleRegisteredOutsideUK) as SelectQuestion<string, string>
    const isVehicleModified = questions.find(q => q.name === QuestionEnum.isVehicleModified) as SelectQuestion<string, string>
    const isUsedForPrivateHire = questions.find(q => q.name === QuestionEnum.isUsedForPrivateHire) as SelectQuestion<string, string>
    const isVehicleRightHandDrive = questions.find(q => q.name === QuestionEnum.isVehicleRightHandDrive) as SelectQuestion<string, string>
    const customWarning = questions.find(q => q.name === QuestionEnum.customWarning) as SelectQuestion<string, string>

    customWarning.isVisible = () => hasCriminalConvictions.value === DLDCommonValuesEnum.Boolean.Yes
    || hasCcjs.value === DLDCommonValuesEnum.Boolean.Yes
    || hasHadPolicyDeclaredVoid.value === DLDCommonValuesEnum.Boolean.Yes
    || isVehicleRegisteredOutsideUK.value === DLDCommonValuesEnum.Boolean.Yes
    || isVehicleModified.value === DLDCommonValuesEnum.Boolean.Yes
    || isUsedForPrivateHire.value === DLDCommonValuesEnum.Boolean.No
    || isVehicleRightHandDrive.value === DLDCommonValuesEnum.Boolean.No
    return page
  }

  private setupCoverDependencies(page: Page): Page {
    const questions = page.sections.flatMap(s => s.questions)

    const effectiveDateQuestion = questions.find(q => q.name === QuestionEnum.coverEffectiveDate) as DateQuestion ?? questions.find(q => q.name === QuestionEnum.mtaEffectiveDate) as DateQuestion
    const claimFreeYears = questions.find(q => q.name === QuestionEnum.claimFreeYears) as NumberQuestion
    const claimsFreeExperienceOnAnotherVehicle = questions.find(q => q.name === QuestionEnum.claimsFreeExperienceOnAnotherVehicle) as NumberQuestion
    const numberOfTemporaryDays = questions.find(q => q.name === QuestionEnum.numberOfTemporaryDays) as SelectQuestion<string, string>
    const shortTermCover = questions.find(q => q.name === QuestionEnum.shortTermCover) as SelectQuestion<string, string>

    if (shortTermCover) {
      shortTermCover.isVisible = () => this.isMinibusSet()
      numberOfTemporaryDays.isVisible = () => shortTermCover && shortTermCover.value! === DLDCommonValuesEnum.Boolean.Yes
    }

    if (claimFreeYears)
      claimFreeYears.min = 0

    if (claimsFreeExperienceOnAnotherVehicle)
      claimsFreeExperienceOnAnotherVehicle.min = 0

    if (effectiveDateQuestion) {
      effectiveDateQuestion.minDate = () => moment().toDate()
      effectiveDateQuestion.maxDate = () => moment().add(31, 'days').toDate()
    }

    return page
  }

  private setupCourierVehicleDependencies(page: Page): Page {
    const questions = page.sections.flatMap(s => s.questions)

    const effectiveDate = questions.find(q => q.name === QuestionEnum.effectiveDate || q.name === QuestionEnum.mtaEffectiveDate || q.name === QuestionEnum.coverEffectiveDate) as DateQuestion
    effectiveDate.minDate = () => moment().toDate()
    effectiveDate.maxDate = () => moment().add(31, 'days').toDate()

    return page
  }

  private setupCourierProposerDependencies(page: Page): Page {
    this.setupCommonDependencies(page)

    const questions = page.sections.flatMap(s => s.questions)

    // no claims protection
    const noClaimsBonusQuestion = questions.find(q => q.name === QuestionEnum.noClaimsBonus) as SelectQuestion<string, string>
    const noClaimsProtectionQuestion = questions.find(q => q.name === QuestionEnum.noClaimsProtection) as SelectQuestion<string, string>
    noClaimsProtectionQuestion.isVisible = () => noClaimsBonusQuestion.value != null && noClaimsBonusQuestion.value !== DLDCommonValuesEnum.NCDYears.Years0

    const hasClaims = questions.find(q => q.name === QuestionEnum.hasClaims) as SelectQuestion<string, string>
    const claims = questions.find(q => q.name === QuestionEnum.claims) as ClaimsQuestion

    claims.isVisible = () => hasClaims.value != null && hasClaims.value === DLDCommonValuesEnum.Boolean.Yes

    return page
  }

  private setupCommercialVehicleDependencies(page: Page): Page {
    const questions = page.sections.flatMap(s => s.questions)

    const hasSecondaryStorageAddress = questions.find(q => q.name === QuestionEnum.hasSecondaryStorageAddress) as SelectQuestion<string, string>
    const primaryStorageAddress = questions.find(q => q.name === QuestionEnum.primaryStorageAddress) as SelectQuestion<string, string>
    const percentage = questions.find(q => q.name === QuestionEnum.percentageContract) as NumberQuestion
    const allSeatsForwardFacing = questions.find(q => q.name === QuestionEnum.allSeatsForwardFacing) as SelectQuestion<string, string>
    const seatDetails = questions.find(q => q.name === QuestionEnum.seatDetails) as SelectQuestion<string, string>
    const allSeatsHaveSeatbelts = questions.find(q => q.name === QuestionEnum.allSeatsHaveSeatbelts) as SelectQuestion<string, string>
    const seatbeltDetails = questions.find(q => q.name === QuestionEnum.seatbeltDetails) as SelectQuestion<string, string>
    const carriesDangerousGoods = questions.find(q => q.name === QuestionEnum.carriesDangerousGoods) as SelectQuestion<string, string>
    const storedGoodsDetails = questions.find(q => q.name === QuestionEnum.storedGoodsDetails) as SelectQuestion<string, string>
    const hasTrailer = questions.find(q => q.name === QuestionEnum.hasTrailer) as SelectQuestion<string, string>
    const trailerDetails = questions.find(q => q.name === QuestionEnum.trailerDetails) as SelectQuestion<string, string>
    const trailerMaxCapacity = questions.find(q => q.name === QuestionEnum.trailerMaxCapacity) as SelectQuestion<string, string>
    const trailerVIN = questions.find(q => q.name === QuestionEnum.trailerVIN) as SelectQuestion<string, string>
    const trailerValue = questions.find(q => q.name === QuestionEnum.trailerValue) as SelectQuestion<string, string>
    const typeOfGoods = questions.find(q => q.name === QuestionEnum.typeOfGoods) as SelectQuestion<string, string>
    const isVehicleRightHandDrive = questions.find(q => q.name === QuestionEnum.isVehicleRightHandDrive) as SelectQuestion<string, string>
    const adaptedForWheelchair = questions.find(q => q.name === QuestionEnum.adaptedForWheelchair) as SelectQuestion<string, string>
    const anchoringLock = questions.find(q => q.name === QuestionEnum.anchoringLock) as SelectQuestion<string, string>
    const selfLoad = questions.find(q => q.name === QuestionEnum.selfLoad) as SelectQuestion<string, string>
    const sectionPermit = questions.find(q => q.name === QuestionEnum.sectionPermit) as SelectQuestion<string, string>
    const loanWithPermits = questions.find(q => q.name === QuestionEnum.loanWithPermits) as SelectQuestion<string, string>
    const percentageContract = questions.find(q => q.name === QuestionEnum.percentageContract) as SelectQuestion<string, string>
    const roadsideProhibition = questions.find(q => q.name === QuestionEnum.roadsideProhibition) as SelectQuestion<string, string>
    const leftHandDriveReason = questions.find(q => q.name === QuestionEnum.leftHandDriveReason) as SelectQuestion<string, string>
    const wheelchairDescription = questions.find(q => q.name === QuestionEnum.wheelchairDescription) as SelectQuestion<string, string>
    const hasPSVOLicence = questions.find(q => q.name === QuestionEnum.hasPSVOLicence) as SelectQuestion<string, string>
    const pSVOLicenceNumber = questions.find(q => q.name === QuestionEnum.pSVOLicenceNumber) as SelectQuestion<string, string>
    const pSVNamedProposer = questions.find(q => q.name === QuestionEnum.pSVNamedProposer) as SelectQuestion<string, string>
    const noPSVOLicenseReason = questions.find(q => q.name === QuestionEnum.noPSVOLicenseReason) as SelectQuestion<string, string>
    const pSVONamedProposerReason = questions.find(q => q.name === QuestionEnum.pSVONamedProposerReason) as SelectQuestion<string, string>
    const vehicleUse = questions.find(q => q.name === QuestionEnum.vehicleUse) as SelectQuestion<string, string>
    const vehicleSeats = questions.find(q => q.name === QuestionEnum.vehicleSeats) as NumberQuestion
    const registeredOutsideUK = questions.find(q => q.name === QuestionEnum.isVehicleRegisteredOutsideUK) as SelectQuestion<string, string>
    const registeredOutsideUKReason = questions.find(q => q.name === QuestionEnum.registeredOutsideUKReason) as SelectQuestion<string, string>
    const isVehicleModified = questions.find(q => q.name === QuestionEnum.isVehicleModified) as SelectQuestion<string, string>
    const modifications = questions.find(q => q.name === QuestionEnum.modifications) as SelectQuestion<string, string>
    const numberOfSeats = questions.find(q => q.name === QuestionEnum.vehicleSeats) as NumberQuestion

    const minibus = this.isMinibusSet()

    // Max

    percentage.max = 100
    numberOfSeats.max = minibus ? 17 : 72

    // Visibility

    primaryStorageAddress.isVisible = () => hasSecondaryStorageAddress.value === DLDCommonValuesEnum.Boolean.Yes

    hasTrailer.isVisible = carriesDangerousGoods.isVisible = typeOfGoods.isVisible = () => !minibus
    adaptedForWheelchair.isVisible
    = registeredOutsideUK.isVisible
    = sectionPermit.isVisible
    = loanWithPermits.isVisible
    = roadsideProhibition.isVisible
    = isVehicleModified.isVisible = () => minibus

    isVehicleRightHandDrive.visible = minibus
    modifications.isVisible = () => minibus && isVehicleModified.value === DLDCommonValuesEnum.Boolean.Yes
    anchoringLock.isVisible = selfLoad.isVisible = () => minibus && adaptedForWheelchair.value === DLDCommonValuesEnum.Boolean.Yes

    seatDetails.isVisible = () => allSeatsForwardFacing.value === DLDCommonValuesEnum.Boolean.No
    seatbeltDetails.isVisible = () => allSeatsHaveSeatbelts.value === DLDCommonValuesEnum.Boolean.No
    percentageContract.isVisible = () => this.isMinibusSet() && vehicleUse.value! === DLDCommonValuesEnum.VehicleUse.ContractHire
    hasPSVOLicence.isVisible = () => vehicleUse.value! === DLDCommonValuesEnum.VehicleUse.HireAndReward && minibus && vehicleSeats.value! > 8
    registeredOutsideUKReason.isVisible = () => this.isMinibusSet() && registeredOutsideUK.value! === DLDCommonValuesEnum.Boolean.Yes
    pSVOLicenceNumber.isVisible = pSVNamedProposer.isVisible = () => hasPSVOLicence.value === DLDCommonValuesEnum.Boolean.Yes && vehicleUse.value! === DLDCommonValuesEnum.VehicleUse.HireAndReward && minibus && vehicleSeats.value! > 8
    noPSVOLicenseReason.isVisible = () => hasPSVOLicence.value === DLDCommonValuesEnum.Boolean.No && vehicleUse.value! === DLDCommonValuesEnum.VehicleUse.HireAndReward && minibus && vehicleSeats.value! > 8
    pSVONamedProposerReason.isVisible = () => pSVNamedProposer.value === DLDCommonValuesEnum.Boolean.No && vehicleUse.value! === DLDCommonValuesEnum.VehicleUse.HireAndReward && minibus && vehicleSeats.value! > 8
    leftHandDriveReason.isVisible = () => isVehicleRightHandDrive.value === DLDCommonValuesEnum.Boolean.No && minibus
    wheelchairDescription.isVisible = () => adaptedForWheelchair.value === DLDCommonValuesEnum.Boolean.Yes && minibus
    storedGoodsDetails.isVisible = () => carriesDangerousGoods.value === DLDCommonValuesEnum.Boolean.Yes && !minibus

    trailerDetails.isVisible
    = trailerMaxCapacity.isVisible
    = trailerVIN.isVisible
    = trailerValue.isVisible = () => hasTrailer.value === DLDCommonValuesEnum.Boolean.Yes && !minibus

    this.setupNewVehicleDependencies(page)

    return page
  }

  private setupVehicleDependencies(page: Page): Page {
    const questions = page.sections.flatMap(s => s.questions)
    // hasSecondaryStorageAddress
    const hasSecondaryStorageAddress = questions.find(q => q.name === QuestionEnum.hasSecondaryStorageAddress) as SelectQuestion<string, string>
    const primaryStorageAddress = questions.find(q => q.name === QuestionEnum.primaryStorageAddress) as SelectQuestion<string, string>

    if (hasSecondaryStorageAddress)
      primaryStorageAddress.isVisible = () => hasSecondaryStorageAddress.value === DLDCommonValuesEnum.Boolean.Yes

    return page
  }

  private setupNewVehicleDependencies(page: Page): Page {
    const questions = page.sections.flatMap(s => s.questions)

    const vehicleEstimatedMileage = questions.find(q => q.name === QuestionEnum.vehicleEstimatedMileage) as NumberQuestion
    const businessMileage = questions.find(q => q.name === QuestionEnum.vehicleBusinessMileage) as NumberQuestion
    if (businessMileage) {
      businessMileage.setdependentMaxVisible = () => vehicleEstimatedMileage.value != null && businessMileage.value != null && (businessMileage.value > vehicleEstimatedMileage.value)
      businessMileage.setdependentMaxMessage = () => vehicleEstimatedMileage.value != null && businessMileage.value != null && (businessMileage.value > vehicleEstimatedMileage.value) ? 'Annual business mileage cannot be greater than total annual mileage.' : ''
    }
    return page
  }

  private setupAggregatorLandingDependencies(page: Page): Page {
    const questions = page.sections.flatMap(s => s.questions)

    const titleQuestion = questions.find(q => q.name === QuestionEnum.title)
    const firstNameQuestion = questions.find(q => q.name === QuestionEnum.firstname)
    const lastNameQuestion = questions.find(q => q.name === QuestionEnum.lastName)
    const dateOfBirthQuestion = questions.find(q => q.name === QuestionEnum.dateOfBirth)
    const drivingLicenceQuestion = questions.find(q => q.name === QuestionEnum.drivingLicence) as DrivingLicenceQuestion
    drivingLicenceQuestion.dependentQuestions = [titleQuestion!, firstNameQuestion!, lastNameQuestion!, dateOfBirthQuestion!]

    const isDriverForUberQuestion = questions.find(q => q.name === QuestionEnum.isDriverForUber) as SelectQuestion<string, string>
    const uberBranchQuestion = questions.find(q => q.name === QuestionEnum.uberBranch) as SelectQuestion<string, string>
    const uberProRatingQuestion = questions.find(q => q.name === QuestionEnum.uberProRating) as SelectQuestion<string, string>

    if (uberBranchQuestion && uberProRatingQuestion)

      uberBranchQuestion.isVisible = uberProRatingQuestion.isVisible = () => isDriverForUberQuestion.value != null && isDriverForUberQuestion.value === DLDCommonValuesEnum.Boolean.Yes

    return page
  }

  private setupClaims(page: Page): Page {
    const questions = page.sections.flatMap(s => s.questions)
    const claimCost = questions.find(q => q.name === QuestionEnum.claimCost) as NumberQuestion

    if (claimCost)
      claimCost.min = 0

    return page
  }

  private setupConvictions(page: Page): Page {
    const questions = page.sections.flatMap(s => s.questions)
    const convictionDisqualificationMonths = questions.find(q => q.name === QuestionEnum.convictionDisqualificationMonths) as NumberQuestion
    const convictionFine = questions.find(q => q.name === QuestionEnum.convictionFine) as NumberQuestion
    const convictionPointsReceived = questions.find(q => q.name === QuestionEnum.convictionPointsReceived) as NumberQuestion

    if (convictionDisqualificationMonths) {
      convictionDisqualificationMonths.min = 0
      convictionFine.min = 0
      convictionPointsReceived.min = 0
    }

    return page
  }

  private setupMedicalDependencies(page: Page): Page {
    const questions = page.sections.flatMap(s => s.questions)

    const dVLAReportableMedicalConditions = questions.find(q => q.name === QuestionEnum.dvlaReportableMedicalConditions) as SelectQuestion<string, string>
    const hasInformedDVLAOfMedicalConditionsAndDisabilities = questions.find(q => q.name === QuestionEnum.hasInformedDVLAOfMedicalConditionsAndDisabilities) as SelectQuestion<string, string>

    dVLAReportableMedicalConditions.isVisible = () => hasInformedDVLAOfMedicalConditionsAndDisabilities.value === DLDCommonValuesEnum.Boolean.Yes
    return page
  }

  private isMinibusSet(): boolean {
    const answersStore = useAnswersStore()
    const minibus = answersStore.answers.find(q => q.name === QuestionEnum.insuringAMinibus) as QuestionAnswer<string>
    return minibus && minibus.value === DLDCommonValuesEnum.Boolean.Yes
  }

  private setupAdditionalDriverDependencies(page: Page): Page {
    this.setupCommonDependencies(page)

    const questions = page.sections.flatMap(s => s.questions)
    const occupationQuestion = questions.find(q => q.name === QuestionEnum.occupation) as SelectQuestion<string, string>
    const hasSecEmploymentQuestion = questions.find(q => q.name === QuestionEnum.hasSecondaryEmployment) as SelectQuestion<string, string>
    const restrictedLicense = questions.find(q => q.name === QuestionEnum.restrictedLicense) as SelectQuestion<string, string>
    const secEmploymentStatusQuestion = questions.find(q => q.name === QuestionEnum.secondaryEmploymentStatus) as SelectQuestion<string, string>
    const secBusinessCategoryQuestion = questions.find(q => q.name === QuestionEnum.secondaryBusinessCategory) as SelectQuestion<string, string>
    const secOccupationQuestion = questions.find(q => q.name === QuestionEnum.secondaryOccupation) as SelectQuestion<string, string>
    const psvLicenceDate = questions.find(q => q.name === QuestionEnum.psvLicenceDate) as SelectQuestion<string, string>
    const psvLicenceNumber = questions.find(q => q.name === QuestionEnum.psvLicenceNumber) as SelectQuestion<string, string>
    const operatingInLondonArea = questions.find(q => q.name === QuestionEnum.operatingInLondonArea) as SelectQuestion<string, string>
    const rideSharing = questions.find(q => q.name === QuestionEnum.rideSharingApp) as SelectQuestion<string, string>
    const hasClaims = questions.find(q => q.name === QuestionEnum.hasClaims) as SelectQuestion<string, string>
    const hasMedicalConditions = questions.find(q => q.name === QuestionEnum.hasMedicalConditions) as SelectQuestion<string, string>
    const hasConvictions = questions.find(q => q.name === QuestionEnum.hasConvictions) as SelectQuestion<string, string>
    const claims = questions.find(q => q.name === QuestionEnum.claims) as SelectQuestion<string, string>
    const convictions = questions.find(q => q.name === QuestionEnum.convictions) as SelectQuestion<string, string>
    const medicalconditions = questions.find(q => q.name === QuestionEnum.medicalconditions) as SelectQuestion<string, string>

    if (hasClaims)
      claims.isVisible = () => hasClaims.value! === DLDCommonValuesEnum.Boolean.Yes

    if (hasConvictions)
      convictions.isVisible = () => hasConvictions.value! === DLDCommonValuesEnum.Boolean.Yes

    if (hasMedicalConditions)
      medicalconditions.isVisible = () => hasMedicalConditions.value! === DLDCommonValuesEnum.Boolean.Yes

    if (psvLicenceDate) {
      psvLicenceDate.isVisible
        = psvLicenceNumber.isVisible
        = rideSharing.isVisible
        = operatingInLondonArea.isVisible = () => this.isMinibusSet()
    }

    if (occupationQuestion) {
      const businessCategoryQuestion = questions.find(q => q.name === QuestionEnum.businessCategory) as SelectQuestion<string, string>
      const employmentStatusQuestion = questions.find(q => q.name === QuestionEnum.employmentStatus) as SelectQuestion<string, string>

      businessCategoryQuestion.isVisible = occupationQuestion.isVisible = () => employmentStatusQuestion.value != null
      && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.Retired
      && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.Unemployed
      && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.FullOrPartTimeEducation
      && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.HouseholdDuties
      && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.IndependentMeans
      && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.NotEmployedDueToDisability
      && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.FinanciallyAssisted
      && employmentStatusQuestion.visible
    }

    if (hasSecEmploymentQuestion)
      secEmploymentStatusQuestion.isVisible = secBusinessCategoryQuestion.isVisible = () => hasSecEmploymentQuestion.value! === DLDCommonValuesEnum.Boolean.Yes

    if (secOccupationQuestion) {
      secBusinessCategoryQuestion.isVisible = secOccupationQuestion.isVisible = () => secEmploymentStatusQuestion.value != null
      && hasSecEmploymentQuestion.value !== DLDCommonValuesEnum.Boolean.No
      && secEmploymentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.Retired
      && secEmploymentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.Unemployed
      && secEmploymentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.FullOrPartTimeEducation
      && secEmploymentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.HouseholdDuties
      && secEmploymentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.IndependentMeans
      && secEmploymentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.NotEmployedDueToDisability
      && secEmploymentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.FinanciallyAssisted
    }

    if (restrictedLicense) {
      const restrictedLicenseType = questions.find(q => q.name === QuestionEnum.restrictedLicenseType) as SelectQuestion<string, string>
      restrictedLicenseType.isVisible = () => restrictedLicense.value! === DLDCommonValuesEnum.Boolean.Yes
    }

    // Void reason
    const hasHadPolicyDeclaredVoid = questions.find(q => q.name === QuestionEnum.hasHadPolicyDeclaredVoid) as SelectQuestion<string, string>
    const voidReason = questions.find(q => q.name === QuestionEnum.voidReason) as SelectQuestion<string, string>

    if (hasHadPolicyDeclaredVoid)
      voidReason.isVisible = () => hasHadPolicyDeclaredVoid.value != null && hasHadPolicyDeclaredVoid.value === DLDCommonValuesEnum.Boolean.Yes

    return page
  }

  private setupCommercialProposerDependencies(page: Page): Page {
    this.setupCommonDependencies(page)
    const questions = page.sections.flatMap(s => s.questions)
    const psvLicenceDate = questions.find(q => q.name === QuestionEnum.psvLicenceDate) as SelectQuestion<string, string>
    const psvLicenceNumber = questions.find(q => q.name === QuestionEnum.psvLicenceNumber) as SelectQuestion<string, string>
    const operatingInLondonArea = questions.find(q => q.name === QuestionEnum.operatingInLondonArea) as SelectQuestion<string, string>
    const rideSharing = questions.find(q => q.name === QuestionEnum.rideSharingApp) as SelectQuestion<string, string>

    if (psvLicenceDate) {
      const answersStore = useAnswersStore()
      const minibus = answersStore.answers.find(q => q.name === QuestionEnum.insuringAMinibus) as QuestionAnswer<string>
      psvLicenceDate.isVisible
        = psvLicenceNumber.isVisible
        = rideSharing.isVisible
        = operatingInLondonArea.isVisible = () => minibus && minibus.value === DLDCommonValuesEnum.Boolean.Yes
    }

    // claims, convictions, medical
    const hasClaims = questions.find(q => q.name === QuestionEnum.hasClaims) as SelectQuestion<string, string>
    const hasMedicalConditions = questions.find(q => q.name === QuestionEnum.hasMedicalConditions) as SelectQuestion<string, string>
    const hasConvictions = questions.find(q => q.name === QuestionEnum.hasConvictions) as SelectQuestion<string, string>
    const medicalconditions = questions.find(q => q.name === QuestionEnum.medicalconditions) as SelectQuestion<string, string>
    const convictions = questions.find(q => q.name === QuestionEnum.convictions) as SelectQuestion<string, string>
    const claims = questions.find(q => q.name === QuestionEnum.claims) as SelectQuestion<string, string>

    claims.isVisible = () => hasClaims.value! === DLDCommonValuesEnum.Boolean.Yes
    convictions.isVisible = () => hasConvictions.value! === DLDCommonValuesEnum.Boolean.Yes
    medicalconditions.isVisible = () => hasMedicalConditions.value! === DLDCommonValuesEnum.Boolean.Yes

    return page
  }

  private setupProposerDependencies(page: Page): Page {
    this.setupCommonDependencies(page)

    const questions = page.sections.flatMap(s => s.questions)

    // Uber
    const isDriverForUberQuestion = questions.find(q => q.name === QuestionEnum.isDriverForUber) as SelectQuestion<string, string>
    const uberBranchQuestion = questions.find(q => q.name === QuestionEnum.uberBranch) as SelectQuestion<string, string>
    const uberProRatingQuestion = questions.find(q => q.name === QuestionEnum.uberProRating) as SelectQuestion<string, string>

    if (uberBranchQuestion && uberProRatingQuestion && isDriverForUberQuestion)

      uberBranchQuestion.isVisible = uberProRatingQuestion.isVisible = () => isDriverForUberQuestion.value != null && isDriverForUberQuestion.value === DLDCommonValuesEnum.Boolean.Yes

    // taxi badge date
    const lastDayOfNextYear = new Date((new Date(Date.now())).getFullYear() + 1, 11, 31)
    const taxiBadgeSince = questions.find(q => q.name === QuestionEnum.taxiBadgeSince) as DateQuestion
    taxiBadgeSince.maxDate = () => (lastDayOfNextYear)

    // claims, convictions, medical
    const hasClaims = questions.find(q => q.name === QuestionEnum.hasClaims) as SelectQuestion<string, string>
    const hasMedicalConditions = questions.find(q => q.name === QuestionEnum.hasMedicalConditions) as SelectQuestion<string, string>
    const hasConvictions = questions.find(q => q.name === QuestionEnum.hasConvictions) as SelectQuestion<string, string>
    const medicalconditions = questions.find(q => q.name === QuestionEnum.medicalconditions) as SelectQuestion<string, string>
    const convictions = questions.find(q => q.name === QuestionEnum.convictions) as SelectQuestion<string, string>
    const claims = questions.find(q => q.name === QuestionEnum.claims) as SelectQuestion<string, string>

    claims.isVisible = () => hasClaims.value! === DLDCommonValuesEnum.Boolean.Yes
    convictions.isVisible = () => hasConvictions.value! === DLDCommonValuesEnum.Boolean.Yes
    medicalconditions.isVisible = () => hasMedicalConditions.value! === DLDCommonValuesEnum.Boolean.Yes

    return page
  }

  protected setupCommonDependencies(page: Page): void {
    const questions = page.sections.flatMap(s => s.questions)

    // driving licence
    const titleQuestion = questions.find(q => q.name === QuestionEnum.title)
    const firstNameQuestion = questions.find(q => q.name === QuestionEnum.firstname)
    const lastNameQuestion = questions.find(q => q.name === QuestionEnum.lastName)
    const dateOfBirthQuestion = questions.find(q => q.name === QuestionEnum.dateOfBirth)
    const drivingLicenceQuestion = questions.find(q => q.name === QuestionEnum.drivingLicence) as DrivingLicenceQuestion
    drivingLicenceQuestion.dependentQuestions = [titleQuestion!, firstNameQuestion!, lastNameQuestion!, dateOfBirthQuestion!]

    // restricted License
    const restrictedLicense = questions.find(q => q.name === QuestionEnum.restrictedLicense) as SelectQuestion<string, string>
    if (restrictedLicense) {
      const restrictedLicenseType = questions.find(q => q.name === QuestionEnum.restrictedLicenseType) as SelectQuestion<string, string>
      restrictedLicenseType.isVisible = () => restrictedLicense.value! === DLDCommonValuesEnum.Boolean.Yes
    }

    // date of birth
    const dateOfBirth = questions.find(q => q.name === QuestionEnum.dateOfBirth) as DateQuestion
    dateOfBirth.maxDate = () => moment().add(-17, 'years').toDate()
    dateOfBirth.focusedDate = () => moment(dateOfBirth.maxDate()).subtract(1, 'years').toDate()

    // resident since
    const residentSinceQuestion = questions.find(q => q.name === QuestionEnum.residentSince) as SelectQuestion<string, string>
    const ukResidentSinceQuestion = questions.find(q => q.name === QuestionEnum.ukResidentSince) as SelectQuestion<string, string>

    if (ukResidentSinceQuestion)
      ukResidentSinceQuestion.isVisible = () => residentSinceQuestion.value != null && residentSinceQuestion.value === DLDCommonValuesEnum.Boolean.No

    // employment status
    const employmentStatusQuestion = questions.find(q => q.name === QuestionEnum.employmentStatus) as SelectQuestion<string, string>
    const occupationQuestion = questions.find(q => q.name === QuestionEnum.occupation) as SelectQuestion<string, string>
    const businessCategoryQuestion = questions.find(q => q.name === QuestionEnum.businessCategory) as SelectQuestion<string, string>

    businessCategoryQuestion.isVisible = occupationQuestion.isVisible = () => employmentStatusQuestion.value != null
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.Retired
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.Unemployed
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.FullOrPartTimeEducation
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.HouseholdDuties
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.IndependentMeans
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.NotEmployedDueToDisability
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.FinanciallyAssisted
    && employmentStatusQuestion.visible

    // secondary employment
    const hasSecEmploymentQuestion = questions.find(q => q.name === QuestionEnum.hasSecondaryEmployment) as SelectQuestion<string, string>
    const secEmploymentStatusQuestion = questions.find(q => q.name === QuestionEnum.secondaryEmploymentStatus) as SelectQuestion<string, string>
    const secBusinessCategoryQuestion = questions.find(q => q.name === QuestionEnum.secondaryBusinessCategory) as SelectQuestion<string, string>
    const secOccupationQuestion = questions.find(q => q.name === QuestionEnum.secondaryOccupation) as SelectQuestion<string, string>

    secEmploymentStatusQuestion.isVisible = secBusinessCategoryQuestion.isVisible = () => hasSecEmploymentQuestion.value! === DLDCommonValuesEnum.Boolean.Yes
    if (secOccupationQuestion) {
      secBusinessCategoryQuestion.isVisible = secOccupationQuestion.isVisible = () => secEmploymentStatusQuestion.value != null
      && hasSecEmploymentQuestion.value !== DLDCommonValuesEnum.Boolean.No
      && secEmploymentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.Retired
      && secEmploymentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.Unemployed
      && secEmploymentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.FullOrPartTimeEducation
      && secEmploymentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.HouseholdDuties
      && secEmploymentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.IndependentMeans
      && secEmploymentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.NotEmployedDueToDisability
      && secEmploymentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.FinanciallyAssisted
    }
  }

  private mapClaims(pageAnswers: Dictionary<PageAnswers>, pages: Page[], riskClaims: ClaimModel[]) {
    const claims = this.createAnswerForQuestion(pageAnswers, QuestionEnum.claims, riskClaims.length, pages)!
    claims.forEach((c) => {
      for (let i = 0; i < riskClaims.length; i += 1) {
        const claim = riskClaims[i]
        const claimDate = new QuestionAnswer<Date>(0, QuestionEnum.claimDate, '', claim.claimDate)
        const incidentDate = new QuestionAnswer<Date>(0, QuestionEnum.incidentDate, '', claim.incidentDate)
        const claimType = new QuestionAnswer<string>(0, QuestionEnum.claimType, '', claim.claimType.uniqueId)
        const claimStatus = new QuestionAnswer<string>(0, QuestionEnum.claimStatus, '', claim.claimStatus.uniqueId)
        const claimFault = new QuestionAnswer<string>(0, QuestionEnum.claimFault, '', claim.claimFault ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const claimPersonsInjured = new QuestionAnswer<string>(0, QuestionEnum.claimPersonsInjured, '', claim.claimPersonsInjured ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const claimCost = new QuestionAnswer<number>(0, QuestionEnum.claimCost, '', claim.claimCost)
        const ncbLost = new QuestionAnswer<string>(0, QuestionEnum.ncbLost, '', claim.ncbLost ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)

        const claimPage = new PageAnswers(`Claim ${i + 1}`, [claimDate, incidentDate, claimType, claimStatus, claimFault, claimPersonsInjured, claimCost, ncbLost])
        c.subPageAnswers.push(claimPage)
      }
    })
    return claims
  }

  private mapModifications(pageAnsers: Dictionary<PageAnswers>, pages: Page[], modifications: ModificationModel[]) {
    const modQuestions = this.createAnswerForQuestion(pageAnsers, QuestionEnum.modifications, modifications.length, pages)!
    modQuestions.forEach((m) => {
      for (let i = 0; i < modifications.length; i += 1) {
        const modification = modifications[i]
        const mod = new QuestionAnswer<string>(0, QuestionEnum.modification, '', modification.carRiskVehicleModification.uniqueId)
        const modPage = new PageAnswers(`Modification ${i + 1}`, [mod])
        m.subPageAnswers.push(modPage)
      }
    })
  }

  private mapConvictions(pageAnswers: Dictionary<PageAnswers>, pages: Page[], riskConvictions: ConvictionModel[]) {
    const convictions = this.createAnswerForQuestion(pageAnswers, QuestionEnum.convictions, riskConvictions.length, pages)!
    convictions.forEach((c) => {
      for (let i = 0; i < riskConvictions.length; i += 1) {
        const conviction = riskConvictions[i]
        const convictionDate = new QuestionAnswer<Date>(0, QuestionEnum.convictionDate, '', conviction.convictionDate)
        const convictionType = new QuestionAnswer<string>(0, QuestionEnum.convictionType, '', conviction.convictionType.uniqueId)
        const convictionPersonsInjured = new QuestionAnswer<string>(0, QuestionEnum.convictionPersonsInjured, '', conviction.convictionPersonsInjured ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const convictionDisqualificationMonths = new QuestionAnswer<number>(0, QuestionEnum.convictionDisqualificationMonths, '', conviction.convictionDisqualificationMonths)
        const convictionFine = new QuestionAnswer<number>(0, QuestionEnum.convictionFine, '', conviction.convictionFine)
        const convictionPointsReceived = new QuestionAnswer<number>(0, QuestionEnum.convictionPointsReceived, '', conviction.convictionPointsReceived)

        const convictionPage = new PageAnswers(`Conviction ${i + 1}`, [convictionDate, convictionType, convictionPersonsInjured, convictionDisqualificationMonths, convictionFine, convictionPointsReceived])
        c.subPageAnswers.push(convictionPage)
      }
    })
    return convictions
  }

  private mapMedicals(pageAnswers: Dictionary<PageAnswers>, pages: Page[], riskMedicals: MedicalConditionModel[]) {
    const medicals = this.createAnswerForQuestion(pageAnswers, QuestionEnum.medicalconditions, riskMedicals.length, pages)!
    medicals.forEach((c) => {
      for (let i = 0; i < riskMedicals.length; i += 1) {
        const medCondition = riskMedicals[i]
        const incidentDate = new QuestionAnswer<Date>(0, QuestionEnum.incidentDate, '', medCondition.incidentDate)
        const medicalCondition = new QuestionAnswer<string>(0, QuestionEnum.medicalCondition, '', medCondition.medicalCondition?.uniqueId)
        const hasInformedDVLAOfMedicalConditionsAndDisabilities = new QuestionAnswer<string>(0, QuestionEnum.hasInformedDVLAOfMedicalConditionsAndDisabilities, '', medCondition.hasInformedDVLAOfMedicalConditionsAndDisabilities ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const dVLAReportableMedicalConditions = new QuestionAnswer<string>(0, QuestionEnum.dvlaReportableMedicalConditions, '', medCondition.dvlaReportableMedicalConditions?.uniqueId)
        const medicalConditionPage = new PageAnswers(`Conviction ${i + 1}`, [incidentDate, medicalCondition, hasInformedDVLAOfMedicalConditionsAndDisabilities, dVLAReportableMedicalConditions])
        c.subPageAnswers.push(medicalConditionPage)
      }
    })
    return medicals
  }

  mapRiskToAnswers(risk: Risk, account: Account, pages: Page[]): Dictionary<PageAnswers> {
    switch (true) {
      case risk instanceof DynamicRisk: {
        const dynamicRisk = risk as DynamicRisk
        return this.mapDynamicRiskToAnswers(dynamicRisk, account, pages)
      }
      case risk instanceof CarRisk: {
        const carRisk = risk as CarRisk
        return this.mapCarRiskToAnswers(carRisk, account, pages)
      }
      default:
        throw new Error('Unsupported risk type for Quotax.')
    }
  }

  mapDynamicRiskToAnswers(risk: DynamicRisk, account: Account, pages: Page[]): Dictionary<PageAnswers> {
    const pageAnswers: Dictionary<PageAnswers> = {}
    this.mapAccountToAnswers(pageAnswers, account, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.autoRenew, account.autoRenew, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.coverEffectiveDate, risk.effectiveDate, pages)
    for (let i = 0; i < Object.keys(risk.questionAnswers).length; i++) {
      const key = Object.keys(risk.questionAnswers)[i]
      const value = risk.questionAnswers[key]
      const enumKey: keyof typeof QuestionEnum | null = this.getEnumKeyByEnumValue(QuestionEnum, key)
      if (enumKey) {
        if (enumKey === QuestionEnum.vehicleLookup) {
          this.createAnswerForQuestion(pageAnswers, QuestionEnum[enumKey], JSON.parse(value), pages)
          continue
        }
        this.createAnswerForQuestion(pageAnswers, QuestionEnum[enumKey], value, pages)
      }
    }
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.address, risk.address, pages)
    return pageAnswers
  }

  getEnumKeyByEnumValue<T extends { [index: string]: string }>(myEnum: T, enumValue: string): keyof T | null {
    const keys = Object.keys(myEnum).filter(x => myEnum[x].toLowerCase() === enumValue.toLowerCase())
    return keys.length > 0 ? keys[0] : null
  }

  mapCarRiskToAnswers(risk: CarRisk, account: Account, pages: Page[]): Dictionary<PageAnswers> {
    const pageAnswers: Dictionary<PageAnswers> = {}
    this.mapAccountToAnswers(pageAnswers, account, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.autoRenew, account.autoRenew, pages)

    const vehicle = new Vehicle()
    vehicle.registration = risk.vehicle.registrationNumber
    vehicle.abiCode = risk.vehicle.car.abiCode
    vehicle.make = risk.vehicle.car.makeDescription
    vehicle.model = risk.vehicle.car.modelDescription
    vehicle.value = risk.vehicle.value
    vehicle.bodyType = risk.vehicle.bodyType
    vehicle.transmissionType = risk.vehicle.vehicleTransmission?.uniqueId
    vehicle.fuelType = risk.vehicle.fuelType?.uniqueId
    vehicle.purchaseDate = risk.vehicle.purchaseDate
    vehicle.yearOfManufacture = risk.vehicle.yearOfManufacture
    vehicle.engineSize = risk.vehicle.cubicCapacity
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleLookup, vehicle, pages)

    this.createAnswerForQuestion(pageAnswers, QuestionEnum.userSpecifiedNumberOfDrivers, risk.userSpecifiedNumberOfDrivers?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.title, risk.proposer.title.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.firstname, risk.proposer.firstName, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.lastName, risk.proposer.lastName, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.dateOfBirth, risk.proposer.dateOfBirth, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.maritalStatus, risk.proposer.maritalStatus?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.address, risk.proposer.address, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.drivingLicence, risk.proposer.drivingLicence, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleEstimatedMileage, risk.vehicle.annualMileage, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleBusinessMileage, risk.vehicle.businessMileage, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleKeeper, risk.vehicle.registeredKeeper.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleOwner, risk.vehicle.registeredOwner.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleSeats, risk.vehicle.noOfSeats, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehiclePurchaseDate, risk.vehicle.purchaseDate, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleYearOfMake, risk.vehicle.yearOfManufacture, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleValue, risk.vehicle.value, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.cubicCapacity, risk.vehicle.cubicCapacity, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.employmentStatus, risk.proposer.employmentStatus?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.occupation, risk.proposer.occupation?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.businessCategory, risk.proposer.businessCategory?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.classOfUse, risk.classOfUse?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.hasCcjs, risk.proposer.hasCCJs ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.referralSource, risk.businessSource?.id, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.effectiveDate, risk.effectiveDate, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.voluntaryExcess, risk.voluntaryExcess?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.noClaimsProtection, risk.noClaimsProtection ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.noClaimsBonus, this.getNoClaimsYearsDLD(risk.proposer.noClaimsBonus), pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.residentSince, risk.proposer.residentSinceBirth ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.ukResidentSince, risk.proposer.residentSince, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.secondaryOccupation, risk.proposer.secondaryOccupation?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.secondaryEmploymentStatus, risk.proposer.secondaryEmploymentStatus?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.secondaryBusinessCategory, risk.proposer.secondaryBusinessCategory?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.homeOwner, risk.proposer.homeOwner ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.licenceDate, risk.proposer.licenceDate, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.mainLicensingAuthority, risk.proposer.mainLicensingAuthority?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.otherLicensingAuthorities, risk.proposer.otherLicensingAuthorities?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.farePayingPassengers, risk.proposer.farePayingPassengers ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.taxiBadgeSince, risk.proposer.taxiBadgeHeldDate, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.rideSharingApp, risk.proposer.rideSharingApp ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.hasHadPolicyDeclaredVoid, risk.proposer.hasHadPolicyDeclaredVoid ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.isDriverForUber, risk.proposer.isDriverForUber ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.uberBranch, risk.proposer.uberBranch?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.uberProRating, risk.proposer.uberProRating?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleFuelType, risk.vehicle.fuelType?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleTransmission, risk.vehicle.vehicleTransmission?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.coverType, risk.coverType?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.numberOfTemporaryDays, risk.coverTerm?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.coverTerm, risk.coverTerm?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.coverEffectiveDate, risk.effectiveDate, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.policyClass, risk.policyClass?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.bikeClassOfUse, risk.classOfUse?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.currentInsurer, risk.currentInsurer?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.previousPolicyExpiration, risk.policyEndDate, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.claimFreeYears, risk.claimFreeYears, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.insuringAMinibus, risk.vehicle.vehicleType?.uniqueId === DLDCommonValuesEnum.CommercialVehicleVehicleType.Minibus ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.claimsFreeExperienceOnAnotherVehicle, risk.proposer.claimsFreeExperienceOnAnotherVehicle, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.useOfAnotherCar, risk.proposer.useOfAnotherCar?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.operatingInLondonArea, risk.proposer.operatingInLondonArea ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.psvLicenceNumber, risk.proposer.psvLicenceNumber, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.psvLicenceDate, risk.proposer.psvLicenceDate, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.mtaEffectiveDate, risk.effectiveDate, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.selfLoad, risk.vehicle.selfLoadDevice ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.adaptedForWheelchair, risk.vehicle.wheelchairsAdapted ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.wheelchairDescription, risk.vehicle.wheelchairsAdaptedDescription, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.anchoringLock, risk.vehicle.anchoringLocking ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.hasPSVOLicence, risk.proposer.hasPSVOLicence ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.pSVOLicenceNumber, risk.proposer.pSVOLicenceNumber, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.pSVNamedProposer, risk.proposer.pSVONamedProposer ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.pSVONamedProposerReason, risk.proposer.pSVONamedProposerReason, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.noPSVOLicenseReason, risk.proposer.noPSVOLicenseReason, pages)

    this.createAnswerForQuestion(pageAnswers, QuestionEnum.hasTrailer, risk.vehicle.hasTrailer ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.trailerDetails, risk.vehicle.trailerDetails, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.trailerMaxCapacity, risk.vehicle.trailerMaxCapacity, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.trailerValue, risk.vehicle.trailerValue, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.trailerVIN, risk.vehicle.trailerVIN, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.carriesDangerousGoods, risk.vehicle.carriesDangerousGoods ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.storedGoodsDetails, risk.vehicle.storedGoodsDetails, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.typeOfGoods, risk.vehicle.typeOfGoods, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleUse, risk.vehicle.vehicleUse?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.seatDetails, risk.vehicle.seatDetails, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.seatbeltDetails, risk.vehicle.seatbeltDetails, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.allSeatsForwardFacing, risk.vehicle.allSeatsForwardFacing ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.allSeatsHaveSeatbelts, risk.vehicle.allSeatsHaveSeatbelts ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.isVehicleModified, risk.vehicle.modifications.length > 0 ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleStorage, risk.vehicle.vehicleStorage?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.hasClaims, risk.proposer.claims.length > 0 ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleWeight, risk.vehicle.vehicleWeight, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleOvernightParkLocation, risk.vehicle.overnightParkLocation?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.workRadius, risk.vehicle.workRadius?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.numberOfDrops, risk.vehicle.numberOfDrops?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.HasExistingMedicalConditions, risk.proposer.hasMedicalConditions ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.hasConvictions, risk.proposer.hasConvictions ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.isVehicleRegisteredOutsideUK, risk.vehicle.isVehicleKeptOutsideUK ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.hasCriminalConvictions, risk.proposer.hasCriminalConvictions ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.hasSecondaryStorageAddress, risk.vehicle.keptAtHomeAddress != null && !risk.vehicle.keptAtHomeAddress ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.primaryStorageAddress, risk.vehicle.address, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.licenceType, risk.proposer.licenceType?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.hasSecondaryEmployment, risk.proposer.secondaryEmploymentStatus !== null ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.drivingFrequency, risk.proposer.drivingFrequency?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.restrictedLicenseType, risk.proposer.dvlaReportableMedicalConditions?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.restrictedLicense, risk.proposer.dvlaReportableMedicalConditions !== null ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.isVehicleRightHandDrive, risk.vehicle.leftHandDrive ? DLDCommonValuesEnum.Boolean.No : DLDCommonValuesEnum.Boolean.Yes, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.leftHandDriveReason, risk.vehicle.leftHandDriveReason, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.percentageContract, risk.vehicle.percentageContract, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.roadsideProhibition, risk.vehicle.roadsideProhibition ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.sectionPermit, risk.vehicle.sectionPermit ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.loanWithPermits, risk.vehicle.loanWithPermits ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.registeredOutsideUKReason, risk.registeredOutsideUKReason, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.hasMedicalConditions, risk.proposer.medicalConditions.length > 0 ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.shortTermCover, risk.coverTerm ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.isUsedForPrivateHire, risk.vehicle.isUsedForPrivateHire ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)

    this.mapClaims(pageAnswers, pages, risk.proposer.claims)
    this.mapMedicals(pageAnswers, pages, risk.proposer.medicalConditions)
    this.mapConvictions(pageAnswers, pages, risk.proposer.convictions)
    this.mapModifications(pageAnswers, pages, risk.vehicle.modifications)

    const additionalDrivers = this.createAnswerForQuestion(pageAnswers, QuestionEnum.additionalDriver, risk.additionalDrivers.length, pages)!
    additionalDrivers.forEach((ada) => {
      for (let i = 0; i < risk.additionalDrivers.length; i += 1) {
        const ad = risk.additionalDrivers[i]
        const adTitle = new QuestionAnswer<string>(0, QuestionEnum.title, '', ad.title.uniqueId)
        const adFirstName = new QuestionAnswer<string>(0, QuestionEnum.firstname, '', ad.firstName)
        const adLastName = new QuestionAnswer<string>(0, QuestionEnum.lastName, '', ad.lastName)
        const adDateOfBirth = new QuestionAnswer<Date>(0, QuestionEnum.dateOfBirth, '', ad.dateOfBirth)
        const adResidentSinceBirth = new QuestionAnswer<string>(0, QuestionEnum.residentSince, '', ad.residentSinceBirth ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const adResidentSince = new QuestionAnswer<Date>(0, QuestionEnum.ukResidentSince, '', ad.residentSince)
        const adDrivingLicence = new QuestionAnswer<string>(0, QuestionEnum.drivingLicence, '', ad.drivingLicence)
        const adRelationshipToPolicyholder = new QuestionAnswer<string>(0, QuestionEnum.relationshipToPolicyHolder, '', ad.relationshipToPolicyholder.uniqueId)
        const adEmploymentStatus = new QuestionAnswer<string>(0, QuestionEnum.employmentStatus, '', ad.employmentStatus.uniqueId)
        const adOccupation = new QuestionAnswer<string>(0, QuestionEnum.occupation, '', ad.occupation.uniqueId)
        const adSecondaryOccupation = new QuestionAnswer<string>(0, QuestionEnum.secondaryOccupation, '', ad.secondaryOccupation?.uniqueId)
        const adBusinessCategory = new QuestionAnswer<string>(0, QuestionEnum.businessCategory, '', ad.businessCategory.uniqueId)
        const adHasMedical = new QuestionAnswer<string>(0, QuestionEnum.HasExistingMedicalConditions, '', ad.hasMedicalConditions ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const adHasConvictions = new QuestionAnswer<string>(0, QuestionEnum.hasConvictions, '', ad.convictions.length > 0 ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const adClaims = new QuestionAnswer<number>(0, QuestionEnum.claims, '', ad.claims.length)
        const adConvictions = new QuestionAnswer<number>(0, QuestionEnum.convictions, '', ad.convictions.length)
        const adMedicals = new QuestionAnswer<number>(0, QuestionEnum.medicalconditions, '', ad.medicalConditions.length)
        const claimsAnswers: Dictionary<PageAnswers> = {}
        const medicalsAnswers: Dictionary<PageAnswers> = {}
        const convictionsAnswers: Dictionary<PageAnswers> = {}
        const claims = this.mapClaims(claimsAnswers, pages, ad.claims)
        const convictions = this.mapConvictions(convictionsAnswers, pages, ad.convictions)
        const medicals = this.mapMedicals(medicalsAnswers, pages, ad.medicalConditions)
        adClaims.subPageAnswers = claims.length > 0 ? (claims[0] as QuestionAnswer<number>).subPageAnswers : []
        adConvictions.subPageAnswers = convictions.length > 0 ? (convictions[0] as QuestionAnswer<number>).subPageAnswers : []
        adMedicals.subPageAnswers = medicals.length > 0 ? (medicals[0] as QuestionAnswer<number>).subPageAnswers : []
        const adLicenceDate = new QuestionAnswer<Date>(0, QuestionEnum.licenceDate, '', ad.licenceDate)
        const adMainLicensingAuthority = new QuestionAnswer<string>(0, QuestionEnum.mainLicensingAuthority, '', ad.mainLicensingAuthority?.uniqueId)
        const adTaxiBadgeSince = new QuestionAnswer<Date>(0, QuestionEnum.taxiBadgeSince, '', ad.taxiBadgeHeldDate)
        const adRideSharingApp = new QuestionAnswer<string>(0, QuestionEnum.rideSharingApp, '', ad.rideSharingApp ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const adMaritalStatus = new QuestionAnswer<string>(0, QuestionEnum.maritalStatus, '', ad.maritalStatus?.uniqueId)
        const adClassOfUse = new QuestionAnswer<string>(0, QuestionEnum.classOfUse, '', ad.classOfUse?.uniqueId)
        const adPSVLicenceNumber = new QuestionAnswer<string>(0, QuestionEnum.psvLicenceNumber, '', ad.psvLicenceNumber)
        const adHasMedicalConditions = new QuestionAnswer<string>(0, QuestionEnum.hasMedicalConditions, '', ad.medicalConditions.length > 0 ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const adPSVLicenceDate = new QuestionAnswer<Date>(0, QuestionEnum.psvLicenceDate, '', ad.psvLicenceDate)
        const adOperatingInLondonArea = new QuestionAnswer<string>(0, QuestionEnum.operatingInLondonArea, '', ad.operatingInLondonArea ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const adHasSecondaryEmployment = new QuestionAnswer<string>(0, QuestionEnum.hasSecondaryEmployment, '', ad.secondaryEmploymentStatus !== null ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const adSecondaryEmploymentStatus = new QuestionAnswer<string>(0, QuestionEnum.secondaryEmploymentStatus, '', ad.secondaryEmploymentStatus?.uniqueId)
        const adSecondaryBusinessCategory = new QuestionAnswer<string>(0, QuestionEnum.secondaryBusinessCategory, '', ad.secondaryBusinessCategory?.uniqueId)
        const adLicenceType = new QuestionAnswer<string>(0, QuestionEnum.licenceType, '', ad.licenceType.uniqueId)
        const adDrivingFrequency = new QuestionAnswer<string>(0, QuestionEnum.drivingFrequency, '', ad.drivingFrequency.uniqueId)
        const adHasClaims = new QuestionAnswer<string>(0, QuestionEnum.hasClaims, '', ad.hasClaims ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const adRestrictedLicense = new QuestionAnswer<string>(0, QuestionEnum.restrictedLicense, '', ad.dvlaReportableMedicalConditions !== null ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const adRestrictedLicenseType = new QuestionAnswer<string>(0, QuestionEnum.restrictedLicenseType, '', ad.dvlaReportableMedicalConditions?.uniqueId)
        const adHasHadPolicyDeclaredVoid = new QuestionAnswer<string>(0, QuestionEnum.hasHadPolicyDeclaredVoid, '', ad.hasHadPolicyDeclaredVoid ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const voidReason = new QuestionAnswer<string>(0, QuestionEnum.voidReason, '', ad.voidReason)
        const adUseOfAnotherVehicle = new QuestionAnswer<string>(0, QuestionEnum.useOfAnotherCar, '', ad.useOfAnotherCar?.uniqueId)
        const adPage = new PageAnswers(`Additional Driver ${i + 1}`, [adTitle, adFirstName, adLastName, adDateOfBirth, adDrivingLicence, adRelationshipToPolicyholder, adEmploymentStatus, adOccupation, adBusinessCategory, adResidentSince, adResidentSinceBirth, adLicenceDate, adMainLicensingAuthority, adTaxiBadgeSince, adRideSharingApp, adSecondaryOccupation, adMaritalStatus, adClassOfUse, adClaims, adConvictions, adMedicals, adHasMedical, adHasConvictions, adHasSecondaryEmployment, adSecondaryEmploymentStatus, adSecondaryBusinessCategory, adLicenceType, adDrivingFrequency, adHasClaims, adRestrictedLicense, adRestrictedLicenseType, adHasHadPolicyDeclaredVoid, voidReason, adPSVLicenceDate, adPSVLicenceNumber, adOperatingInLondonArea, adUseOfAnotherVehicle, adHasMedicalConditions])
        ada.subPageAnswers.push(adPage)
      }
    })

    return pageAnswers
  }
}
