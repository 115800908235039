import { JsonObject, JsonProperty } from 'json2typescript'
import DateConverter from '@/converters/DateConverter'

@JsonObject('PersonalDetailsValue')
export default class PersonalDetails {
  @JsonProperty('title', String)
  title = ''

  @JsonProperty('firstName', String)
  firstName = ''

  @JsonProperty('lastName', String)
  lastName = ''

  @JsonProperty('dateOfBirth', DateConverter)
  dateOfBirth: Date = new Date()
}
