import { JsonObject, JsonProperty } from 'json2typescript'
import { DefinedListDetail } from './DefinedList'
import Driver from './Driver'
import Address from './Address'

@JsonObject('AdditionalDriver')
export default class AdditionalDriver extends Driver {
  @JsonProperty('relationshipToPolicyholder', DefinedListDetail)
  relationshipToPolicyholder: DefinedListDetail

  @JsonProperty('classOfUse', DefinedListDetail)
  classOfUse: DefinedListDetail

  @JsonProperty('address', Address)
  address: Address | null = null

  constructor(
    id = 0,
    title: DefinedListDetail = new DefinedListDetail(),
    firstName = '',
    lastName = '',
    dateofBirth: Date = new Date(),
    drivingLicence = '',
    licenceDate: Date = new Date(),
    licenceType: DefinedListDetail = new DefinedListDetail(),
    employmentStatus: DefinedListDetail = new DefinedListDetail(),
    businessCategory: DefinedListDetail = new DefinedListDetail(),
    occupation: DefinedListDetail = new DefinedListDetail(),
    relationshipToPolicyholder: DefinedListDetail = new DefinedListDetail(),
    classOfUse: DefinedListDetail = new DefinedListDetail(),
    prn: null,
    psvLicenceNumber: null,
    psvLicenceDate: Date = new Date(),
    operatingInLondonArea: null,
    otherVehiclesOwned: null,
  ) {
    super(id, title, firstName, lastName, dateofBirth, drivingLicence, licenceDate, licenceType, employmentStatus, businessCategory, occupation)
    this.relationshipToPolicyholder = relationshipToPolicyholder
    this.classOfUse = classOfUse
    this.prn = prn
    this.psvLicenceNumber = psvLicenceNumber
    this.psvLicenceDate = psvLicenceDate
    this.operatingInLondonArea = operatingInLondonArea
    this.otherVehiclesOwned = otherVehiclesOwned
  }
}
