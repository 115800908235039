enum AvailableActionsEnum {
  requestGreencard = 'RequestGreencard',
  cancelPolicy = 'CancelPolicy',
  amendPolicy = 'AmendPolicy',
  cancelAmendment = 'CancelAmendment',
  upgradePolicy = 'UpgradePolicy',
  manageRenewal = 'ManageRenewal',
  makeClaim = 'MakeClaim',
  manageDirectDebit = 'ManageDirectDebit',
}

export default AvailableActionsEnum
