import type _Vue from 'vue'
import type Account from '@/services/models/Account'

interface ChatWindow extends Window {
  BE_API: any
  __be: any
  LC_API: any
  __lc: any
}

declare const window: ChatWindow

export class Chat {
  setSession(user: Account): void {
    window.LC_API = window.LC_API || {}

    window.LC_API.on_before_load = () => {
      window.LC_API.set_custom_variables({
        email: user.email,
        name: `${user.firstName} ${user.lastName}`,
        username: user.username,
      })
    }

    window.BE_API = window.BE_API || {}
    window.BE_API.onLoad = () => {
      window.BE_API.setUserAttributes({
        email: user.email,
        name: `${user.firstName} ${user.lastName}`,
        username: user.username,
      })
    }
  }

  open(): void {
    window.BE_API.openChatWindow()
  }

  openLiveChat(): void {
    window.LC_API.open_chat_window()
  }

  hideChatIcon(): void {
    const icon = document.getElementById('chatbot-chat')
    if (icon)
      icon.style.display = 'none'
  }

  showChatIcon(): void {
    const icon = document.getElementById('chatbot-chat')
    if (icon)
      icon.style.display = 'block'
  }
}

export const ChatClient = new Chat()

export default function ChatPlugin(Vue: typeof _Vue): void {
  Vue.prototype.$chat = ChatClient
}
