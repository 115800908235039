import { defineStore } from 'pinia'
import type { IQuestionAnswer } from '@/services/values/PageAnswers'
import PageAnswers, { QuestionAnswer } from '@/services/values/PageAnswers'
import type { Dictionary } from '@/types'
import type Page from '@/view-models/Page'
import type Question from '@/view-models/Question'
import { SubPagesQuestion } from '@/view-models/Question'

function mapSubQuestionToAnswers(q: SubPagesQuestion) {
  const pageAnswers = new Array<PageAnswers>()

  q.subPageDetails.forEach((spd, i) => {
    const subQustions = spd.page.sections.flatMap(s => s.questions)

    const pageAnswer = new PageAnswers()
    pageAnswer.title = `Item ${i + 1}`

    subQustions.forEach((sq) => {
      type extractGeneric<Type> = Type extends Question<infer X> ? X : null
      type extracted = extractGeneric<typeof sq>
      const question = sq as Question<extracted>

      const answer = new QuestionAnswer<extracted>()
      answer.id = question.id
      answer.name = question.name
      answer.label = question.label
      answer.value = question.value
      answer.visible = question.visible
      answer.display = question.display()
      answer.hideOnSummary = question.hideOnSummary()

      if (question instanceof SubPagesQuestion) {
        const mappedAnswers = mapSubQuestionToAnswers(question)
        answer.subPageAnswers = mappedAnswers
      }

      pageAnswer.answers.push(answer)
    })

    pageAnswers.push(pageAnswer)
  })

  return pageAnswers
}

interface State {
  pagesStore: Dictionary<PageAnswers>
  selectedSchemeQuoteResult: string
}

const useAnswersStore = defineStore('answers', {
  state: (): State => ({
    pagesStore: {},
    selectedSchemeQuoteResult: '',
  }),
  getters: {
    answers(state): IQuestionAnswer[] {
      return Object.keys(state.pagesStore).flatMap(p => state.pagesStore[p].answers)
    },
    pages(state): PageAnswers[] {
      return Object.values(state.pagesStore)
    },
  },
  actions: {
    setAnswers(forPage: Page): void {
      const questions = forPage.sections.flatMap(s => s.questions)
      const newPS: Dictionary<PageAnswers> = {}
      newPS[forPage.id] = new PageAnswers(forPage.title, questions.map(q => q.toAnswer()))
      this.pagesStore = { ...this.$state.pagesStore, ...newPS }
    },
    updateAnswersFromPage(page: Page): void {
      const newPS: Dictionary<PageAnswers> = this.$state.pagesStore

      const questions = page.sections.flatMap(s => s.questions)
      const currentAnswers = Object.keys(newPS).flatMap(p => newPS[p].answers)

      questions.forEach((q) => {
        const matchedAnswers = currentAnswers.filter(a => a.name === q.name)
        matchedAnswers.forEach((ma) => {
          type extractGeneric<Type> = Type extends Question<infer X> ? X : null
          type extracted = extractGeneric<typeof q>
          const typedAnswer = ma as QuestionAnswer<extracted>
          const question = q as Question<extracted>
          typedAnswer.value = question.value

          if (q instanceof SubPagesQuestion) {
            const subQuestion = q as SubPagesQuestion
            const mappedAnswers = mapSubQuestionToAnswers(subQuestion)

            if (typedAnswer.subPageAnswers.length !== mappedAnswers.length) {
              typedAnswer.subPageAnswers = mappedAnswers
            }
            else {
              for (let i = 0; i < mappedAnswers.length; i += 1) {
                const newItem = mappedAnswers[i]
                const currentItem = typedAnswer.subPageAnswers[i]

                for (let x = 0; x < newItem.answers.length; x += 1) {
                  const newAnswer = newItem.answers[x]
                  const currentAnswer = currentItem.answers.find(z => z.id === newAnswer.id)!

                  const index = currentItem.answers.indexOf(currentAnswer)
                  currentItem.answers[index] = newAnswer
                }
              }
            }
          }
        })
      })

      this.pagesStore = newPS
    },
    setPageAnswers(pageAnswers: Dictionary<PageAnswers>): void {
      this.pagesStore = pageAnswers
    },
    clearAll(): void {
      this.pagesStore = {}
      this.selectedSchemeQuoteResult = ''
    },
    setSelectedSchemeQuoteResult(schemeName: string): void {
      this.selectedSchemeQuoteResult = schemeName
    },
  },
})

export default useAnswersStore
