import { JsonObject, JsonProperty } from 'json2typescript'
import CreditCard from './CreditCard'
import DirectDebit from './DirectDebit'

@JsonObject('PaymentRequest')
export default class PaymentRequest {
  @JsonProperty('singlePaymentAmount', Number)
  singlePaymentAmount = 0

  @JsonProperty('selectedSchemeQuoteResult', Number)
  selectedSchemeQuoteResult: number | null = null

  @JsonProperty('creditCard', CreditCard, true)
  creditCard: CreditCard | null = null

  @JsonProperty('directDebit', DirectDebit, true)
  directDebit: DirectDebit | null = null

  @JsonProperty('existingCard', Number, true)
  existingCard: number | null = null

  @JsonProperty('addToExistingDirectDebit', Boolean)
  addToExistingDirectDebit = false

  @JsonProperty('addToExistingGoCardless', Boolean)
  addToExistingGoCardless = false

  @JsonProperty('threeDSecureTransId', String)
  threeDSecureTransId = ''

  @JsonProperty('ignitePaymentGatewayResult', String)
  ignitePaymentGatewayResult = ''
}
