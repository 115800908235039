import { JsonObject, JsonProperty } from 'json2typescript'
import Model from './Model'
import Enclosure from './Enclosure'
import Insurer from './Insurer'
import NCDStepBackRule from './NCDStepBackRule'
import SchemeDocument from './SchemeDocument'

@JsonObject('Scheme')
export default class Scheme extends Model {
  @JsonProperty('code', String)
  code: string

  @JsonProperty('name', String)
  name: string

  @JsonProperty('isPayMonthly', Boolean)
  isPayMonthly: boolean

  @JsonProperty('enclosures', [Enclosure])
  enclosures: Enclosure[]

  @JsonProperty('schemeDocuments', [SchemeDocument])
  schemeDocuments: SchemeDocument[]

  @JsonProperty('insurer', Insurer)
  insurer: Insurer

  @JsonProperty('protectedNCDStepBackRules', [NCDStepBackRule])
  protectedNCDStepBackRules: NCDStepBackRule[]

  @JsonProperty('unprotectedNCDStepBackRules', [NCDStepBackRule])
  unprotectedNCDStepBackRules: NCDStepBackRule[]

  constructor(
    id = 0,
    code = '',
    name = '',
    isPayMonthly = false,
    enclosures: Enclosure[] = [],
    schemeDocuments: SchemeDocument[] = [],
    insurer: Insurer = new Insurer(),
    protectedNCDStepBackRules: NCDStepBackRule[] = [],
    unprotectedNCDStepBackRules: NCDStepBackRule[] = [],
  ) {
    super(id)
    this.code = code
    this.name = name
    this.isPayMonthly = isPayMonthly
    this.enclosures = enclosures
    this.schemeDocuments = schemeDocuments
    this.insurer = insurer
    this.protectedNCDStepBackRules = protectedNCDStepBackRules
    this.unprotectedNCDStepBackRules = unprotectedNCDStepBackRules
  }
}
