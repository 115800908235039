import { JsonObject, JsonProperty } from 'json2typescript'
import { Marketing } from '../models/Account'
import Address from '../models/Address'
import DateConverter from '@/converters/DateConverter'

@JsonObject('AccountRegister')
export default class AccountRegister {
  @JsonProperty('title', String)
  title = ''

  @JsonProperty('firstName', String)
  firstName = ''

  @JsonProperty('lastName', String)
  lastName = ''

  @JsonProperty('email', String)
  email = ''

  @JsonProperty('password', String, true)
  password: string | null = null

  @JsonProperty('dateOfBirth', DateConverter)
  dateOfBirth: Date = new Date()

  @JsonProperty('homePhone', String)
  homePhone = ''

  @JsonProperty('mobilePhone', String)
  mobilePhone = ''

  @JsonProperty('address', Address)
  address: Address = new Address()

  @JsonProperty('marketing', Marketing)
  marketing: Marketing = new Marketing()

  @JsonProperty('affiliateId', Number, false)
  affiliateId: number | null = 0

  @JsonProperty('autoRenew', Boolean, true)
  autoRenew: boolean | null = null
}
