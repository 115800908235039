<template>
  <div>
    <b-modal :active.sync="isActive" has-modal-card scroll="keep" :can-cancel="canCancel">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ authServiceConfig.enabled ? 'Complete Registration' : 'Change Password' }}</p>
        </header>
        <ValidationObserver v-slot="{ passes }">
          <section class="modal-card-body" v-if="authServiceConfig.enabled">
            <p>In order to access your portal account. Please register your account via one of the registration methods below.</p>

            <AuthService type="register" />
          </section>
          <section class="modal-card-body" v-else>
            <p>{{ complexityText }}</p>

            <ValidationProvider
              :rules="{
                required: true,
              }"
              v-slot="{ errors, valid }"
              v-if="account?.hasCustomerCredentials">
              <b-field
                label="Current Password"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :message="errors">
                <b-input
                  type="password"
                  v-model="currentPassword"/>
              </b-field>
            </ValidationProvider>

            <ValidationProvider
              :rules="{
                required: true,
                passwordComplexity: passwordComplexityRule,
                confirmed: 'confirmation',
              }"
              v-slot="{ errors, valid }">
              <b-field
                label="New Password"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :message="errors">
                <b-input
                  type="password"
                  v-model="newPassword"
                  data-cy="password" />
              </b-field>
            </ValidationProvider>

            <ValidationProvider
              :rules="{
                required: true,
                passwordComplexity: passwordComplexityRule,
              }"
              v-slot="{ errors, valid }"
              vid="confirmation">
              <b-field
                label="Confirm New Password"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :message="errors">
                <b-input
                  type="password"
                  v-model="confirmPassword"
                  @keydown.native.prevent.enter="passes(submit)" 
                  data-cy="password-confirm"/>
              </b-field>
            </ValidationProvider>
          </section>
          <footer class="modal-card-foot" v-if="!authServiceConfig.enabled">
            <b-button class="is-primary" @click="passes(submit)">Update</b-button>
          </footer>
        </ValidationObserver>
      </div>
    </b-modal>
    <b-loading :is-full-page="true" :active="loading" />
  </div>
</template>

<script lang="ts">
import {
  Component, Vue, PropSync, Prop,
} from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, confirmed, regex } from 'vee-validate/dist/rules';
import Account from '@/services/models/Account';
import BadRequestError from '@/services/error/BadRequestError';
import useAccountStore from '@/stores/account-store';
import { getAuthServiceConfig } from '@/auth-service';
import AuthService from './AuthService.vue';

extend('required', {
  ...required,
  message: 'This field is required.',
});

extend('confirmed', {
  ...confirmed,
  message: 'These two passwords must match.',
});

extend('regex', {
  ...regex,
});

@Component({
  components: {
    IgniteMarkdown: () => import('@/components/controls/IgniteMarkdown.vue'),
    ValidationProvider,
    ValidationObserver,
    AuthService,
  },
})
export default class PasswordChange extends Vue {
  @PropSync('active', { type: Boolean }) isActive!: boolean;

  @Prop({ default: true }) canCancel!: boolean;

  private currentPassword = '';

  private newPassword = '';

  private confirmPassword = '';

  private complexityText = '';

  private passwordRegex = '';

  private loading = false;

  async created() {
    this.loading = true;
    if (this.authServiceConfig.enabled) {
      if (!this.authServiceConfig.token) {
        this.loading = false;
        return
      }

      if (this.authServiceConfig.type === 'auth') {
        this.$emit('complete')
        this.loading = false;
        return
      }
      try {
        const { token } = await this.authServiceConfig.exchangeAuthProspectToken(this.authServiceConfig.token!)
        this.authServiceConfig.setToken(token, 'auth')
        this.$emit('complete')
      } catch (error) {
        console.warn('Error exchanging prospect token', error)
      }
    } else {
      const contraints = await this.$services.identityProvider.getPasswordConstraints();
      this.complexityText = contraints.complexityError;
      this.passwordRegex = contraints.complexityRegex;
      extend('passwordComplexity', {
        ...regex,
        params: ['regex', 'message'],
        message: 'This field is not valid.',
      });
    }
    this.loading = false;
  }

  get authServiceConfig() {
    return getAuthServiceConfig();
  }

  get passwordComplexityRule() {
    return {
      regex: new RegExp(this.passwordRegex),
    };
  }

  get newPasswordInput() {
    return this.$refs.newPassword as any;
  }

  get confirmPasswordInput() {
    return this.$refs.newPassword as any;
  }

  get account() {
    const accountStore = useAccountStore();
    return accountStore.account;
  }

  updateLocal(account: Account) {
    const accountStore = useAccountStore();
    accountStore.setAccount(account);
  }

  async submit() {
    if (this.newPassword !== this.confirmPassword) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Please correct your input.',
        position: 'is-bottom',
        type: 'is-danger',
      });
      return;
    }

    this.loading = true;
    try {
      await this.$services.identityProvider.changePassword(this.currentPassword, this.newPassword);
      const accountStore = useAccountStore();
      accountStore.clearAccount();
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Your password has been successfully changed!',
        position: 'is-bottom',
        type: 'is-success',
      });
      const account = await this.$services.accountService.getCurrent();
      this.updateLocal(account);
      this.$emit('complete');
    } catch (error) {
      if (error instanceof BadRequestError) {
        this.$buefy.toast.open({
          duration: 5000,
          message: error.modelState?.oldPassword?.[0] ?? error.modelState?.newPassword?.[0] ?? 'Something went wrong. Please try again.',
          position: 'is-bottom',
          type: 'is-danger',
        });
      } else {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Something went wrong. Please try again.',
          position: 'is-bottom',
          type: 'is-danger',
        });
        this.loading = false;
        throw error;
      }
    }
    this.loading = false;
  }
}
</script>
