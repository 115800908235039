import { JsonObject, JsonProperty } from 'json2typescript'
import DateConverter from '@/converters/DateConverter'

@JsonObject('DirectDebitQuote')
export default class DirectDebitQuote {
  @JsonProperty('depositAmount', Number)
  depositAmount: number

  @JsonProperty('firstInstallment', Number)
  firstInstallment: number

  @JsonProperty('firstInstalmentDueDate', DateConverter)
  firstInstalmentDueDate: Date | null

  @JsonProperty('subsequentInstallments', Number)
  subsequentInstallments: number

  @JsonProperty('finalInstallment', Number)
  finalInstallment: number

  @JsonProperty('payMonthlyTotal', Number)
  payMonthlyTotal: number

  @JsonProperty('apr', Number)
  apr: number

  @JsonProperty('numberOfInstallments', Number)
  numberOfInstallments: number

  @JsonProperty('loanAmount', Number)
  loanAmount: number

  @JsonProperty('totalCostOfCredit', Number)
  totalCostOfCredit: number

  @JsonProperty('creditCharge', Number)
  creditCharge: number

  @JsonProperty('grossRate', Number)
  grossRate: number

  @JsonProperty('provider', String)
  provider: string

  @JsonProperty('exampleCreditAgg', Number)
  exampleCreditAgg: number

  constructor(
    depositAmount = 0,
    firstInstallment = 0,
    firstInstalmentDueDate = null,
    subsequentInstallments = 0,
    finalInstallment = 0,
    payMonthlyTotal = 0,
    apr = 0,
    numberOfInstallments = 0,
    loanAmount = 0,
    totalCostOfCredit = 0,
    creditCharge = 0,
    grossRate = 0,
    provider = '',
    exampleCreditAgg = 0,
  ) {
    this.depositAmount = depositAmount
    this.firstInstallment = firstInstallment
    this.firstInstalmentDueDate = firstInstalmentDueDate
    this.subsequentInstallments = subsequentInstallments
    this.finalInstallment = finalInstallment
    this.payMonthlyTotal = payMonthlyTotal
    this.apr = apr
    this.numberOfInstallments = numberOfInstallments
    this.loanAmount = loanAmount
    this.totalCostOfCredit = totalCostOfCredit
    this.creditCharge = creditCharge
    this.grossRate = grossRate
    this.provider = provider
    this.exampleCreditAgg = exampleCreditAgg
  }
}
