import { JsonObject, JsonProperty } from 'json2typescript'
import UIFieldTypeEnum from '../enums/UIFieldTypeEnum'
import Model from './Model'
import DefinedList, { DefinedListDetail } from './DefinedList'
import CustomList from './CustomList'
import CoInsuranceRule from './CoInsuranceRule'
import type { Dictionary } from '@/types'

@JsonObject('UIDefault')
export class UIDefault extends Model {
  @JsonProperty('definedListDetail', DefinedListDetail)
  definedListDetail: DefinedListDetail

  constructor(
    id = 0,
    definedListDetail: DefinedListDetail = new DefinedListDetail(),
  ) {
    super(id)
    this.definedListDetail = definedListDetail
  }
}

@JsonObject('UIFieldType')
export class UIFieldType extends Model {
  @JsonProperty('name', String)
  name: UIFieldTypeEnum

  constructor(
    id = 0,
    name: UIFieldTypeEnum = UIFieldTypeEnum.text,
  ) {
    super(id)
    this.name = name
  }
}

@JsonObject('UIField')
export default class UIField extends Model {
  @JsonProperty('name', String)
  name: string

  @JsonProperty('questionLabel', String)
  questionLabel: string

  @JsonProperty('customerHelpText', String)
  customerHelpText: string | null

  @JsonProperty('definedList', DefinedList)
  definedList: DefinedList | null

  @JsonProperty('type', UIFieldType)
  type: UIFieldType

  @JsonProperty('coInsuranceRules', [CoInsuranceRule], true)
  coInsuranceRules: CoInsuranceRule[] | null

  @JsonProperty('customListDetails', [CustomList])
  customListDetails: CustomList[] | null

  @JsonProperty('config', Object)
  config: Dictionary<string>

  @JsonProperty('markdown', String)
  markdown: string | null

  @JsonProperty('regularExpression', String)
  regularExpression: string | null

  @JsonProperty('regularExpressionValidationError', String)
  regularExpressionValidationError: string | null

  @JsonProperty('minSubPages', Number)
  minSubPages: number

  @JsonProperty('maxSubPages', Number)
  maxSubPages: number

  @JsonProperty('productPageUniqueId', String)
  productPageUniqueId: string | null

  constructor(
    id = 0,
    name = '',
    questionLabel = '',
    customerHelpText = '',
    definedList: DefinedList | null = null,
    type: UIFieldType = new UIFieldType(),
    coInsuranceRules: CoInsuranceRule[] | null = null,
    customListDetails: CustomList[] | null = null,
    config: Dictionary<string> = {},
    markdown: string | null = null,
    regularExpression: string | null = null,
    regularExpressionValidationError: string | null = null,
    minSubPages = 0,
    maxSubPages = 0,
    productPageUniqueId: string | null = null,
  ) {
    super(id)
    this.name = name
    this.questionLabel = questionLabel
    this.customerHelpText = customerHelpText
    this.definedList = definedList
    this.type = type
    this.coInsuranceRules = coInsuranceRules
    this.customListDetails = customListDetails
    this.config = config
    this.markdown = markdown
    this.regularExpression = regularExpression
    this.regularExpressionValidationError = regularExpressionValidationError
    this.minSubPages = minSubPages
    this.maxSubPages = maxSubPages
    this.productPageUniqueId = productPageUniqueId
  }
}

@JsonObject('UIFieldSetField')
export class UIFieldSetField extends Model {
  @JsonProperty('questionLabel', String)
  questionLabel: string | null

  @JsonProperty('visible', Boolean)
  visible: boolean

  @JsonProperty('isMandatory', Boolean)
  isMandatory: boolean

  @JsonProperty('order', Number)
  order: number

  @JsonProperty('customerHelpText', String)
  customerHelpText: string | null

  @JsonProperty('uiField', UIField)
  uiField: UIField

  @JsonProperty('customerUIDefault', UIDefault)
  customerUIDefault: UIDefault | null

  @JsonProperty('markdown', String)
  markdown: string | null

  constructor(
    id = 0,
    questionLabel = '',
    visible = true,
    isMandatory = true,
    order = 0,
    uiField: UIField = new UIField(),
    customerUIDefault: UIDefault = new UIDefault(),
    customerHelpText = '',
    markdown = '',
  ) {
    super(id)
    this.questionLabel = questionLabel
    this.visible = visible
    this.isMandatory = isMandatory
    this.order = order
    this.uiField = uiField
    this.customerUIDefault = customerUIDefault
    this.customerHelpText = customerHelpText
    this.markdown = markdown
  }
}

@JsonObject('UIFieldSet')
export class UIFieldSet extends Model {
  @JsonProperty('name', String)
  name: string

  @JsonProperty('isHTMLFieldSet', Boolean)
  isHTMLFieldSet: boolean

  @JsonProperty('order', Number)
  order: number

  @JsonProperty('uiFieldSetFields', [UIFieldSetField])
  uiFieldSetFields: UIFieldSetField[]

  @JsonProperty('displayHeader', String)
  displayHeader: string

  constructor(
    id = 0,
    name = '',
    isHTMLFieldSet = true,
    order = 0,
    uiFieldSetFields: UIFieldSetField[] = [],
    displayHeader = '',
  ) {
    super(id)
    this.name = name
    this.isHTMLFieldSet = isHTMLFieldSet
    this.order = order
    this.uiFieldSetFields = uiFieldSetFields
    this.displayHeader = displayHeader
  }
}
