import type _Vue from 'vue'
import TenantEnum from '@/services/enums/TenantEnum'
import ConfigurationLoader from '@/configurations/ConfigurationLoader'

import BarryGraingerAggConfig from '@/configurations/aggregator/barrygrainger.json'
import BarryGraingerGeneralConfig from '@/configurations/general/barrygrainger.json'
import BarryGraingerJourneyConfig from '@/configurations/journey/barrygrainger.json'
import BarryGraingerExperimentsConfig from '@/configurations/experiments/barrygrainger.json'

import DashAggConfig from '@/configurations/aggregator/dash.json'
import DashGeneralConfig from '@/configurations/general/dash.json'
import DashJourneyConfig from '@/configurations/journey/dash.json'
import DashExperimentsConfig from '@/configurations/experiments/dash.json'

import HagertyAggConfig from '@/configurations/aggregator/hagerty.json'
import HagertyGeneralConfig from '@/configurations/general/hagerty.json'
import HagertyJourneyConfig from '@/configurations/journey/hagerty.json'
import HagertyExperimentsConfig from '@/configurations/experiments/hagerty.json'

import IgniteAggConfig from '@/configurations/aggregator/ignite.json'
import IgniteGeneralConfig from '@/configurations/general/ignite.json'
import IgniteJourneyConfig from '@/configurations/journey/ignite.json'
import IgniteExperimentsConfig from '@/configurations/experiments/ignite.json'

import KeeyPartnersAggConfig from '@/configurations/aggregator/keeypartners.json'
import KeeyPartnersGeneralConfig from '@/configurations/general/keeypartners.json'
import KeeyPartnersJourneyConfig from '@/configurations/journey/keeypartners.json'
import KeeyPartnersExperimentsConfig from '@/configurations/experiments/keeypartners.json'

import QuotaxAggConfig from '@/configurations/aggregator/quotax.json'
import QuotaxGeneralConfig from '@/configurations/general/quotax.json'
import QuotaxJourneyConfig from '@/configurations/journey/quotax.json'
import QuotaxExperimentsConfig from '@/configurations/experiments/quotax.json'

import WrapperAggConfig from '@/configurations/aggregator/wrapper.json'
import WrapperGeneralConfig from '@/configurations/general/wrapper.json'
import WrapperJourneyConfig from '@/configurations/journey/wrapper.json'
import WrapperExperimentsConfig from '@/configurations/experiments/wrapper.json'

import TractiveAggConfig from '@/configurations/aggregator/tractive.json'
import TractiveGeneralConfig from '@/configurations/general/tractive.json'
import TractiveJourneyConfig from '@/configurations/journey/tractive.json'
import TractiveExperimentsConfig from '@/configurations/experiments/tractive.json'

import YogaAggConfig from '@/configurations/aggregator/yoga.json'
import YogaGeneralConfig from '@/configurations/general/yoga.json'
import YogaJourneyConfig from '@/configurations/journey/yoga.json'
import YogaExperimentsConfig from '@/configurations/experiments/yoga.json'

import ZoomcoverAggConfig from '@/configurations/aggregator/zoomcover.json'
import ZoomcoverGeneralConfig from '@/configurations/general/zoomcover.json'
import ZoomcoverJourneyConfig from '@/configurations/journey/zoomcover.json'
import ZoomcoverExperimentsConfig from '@/configurations/experiments/zoomcover.json'

export interface IConfigurationOptions {
  tenant: TenantEnum
}

export default function ConfigurationPlugin(Vue: typeof _Vue, options: IConfigurationOptions): void {
  switch (options.tenant) {
    case TenantEnum.BarryGrainger:
      Vue.prototype.$configurations = ConfigurationLoader.createConfiguration(BarryGraingerGeneralConfig, BarryGraingerAggConfig, BarryGraingerJourneyConfig, BarryGraingerExperimentsConfig)
      break
    case TenantEnum.Dash:
      Vue.prototype.$configurations = ConfigurationLoader.createConfiguration(DashGeneralConfig, DashAggConfig, DashJourneyConfig, DashExperimentsConfig)
      break
    case TenantEnum.Hagerty:
      Vue.prototype.$configurations = ConfigurationLoader.createConfiguration(HagertyGeneralConfig, HagertyAggConfig, HagertyJourneyConfig, HagertyExperimentsConfig)
      break
    case TenantEnum.Ignite:
      Vue.prototype.$configurations = ConfigurationLoader.createConfiguration(IgniteGeneralConfig, IgniteAggConfig, IgniteJourneyConfig, IgniteExperimentsConfig)
      break
    case TenantEnum.KeeyPartners:
      Vue.prototype.$configurations = ConfigurationLoader.createConfiguration(KeeyPartnersGeneralConfig, KeeyPartnersAggConfig, KeeyPartnersJourneyConfig, KeeyPartnersExperimentsConfig)
      break
    case TenantEnum.Quotax:
      Vue.prototype.$configurations = ConfigurationLoader.createConfiguration(QuotaxGeneralConfig, QuotaxAggConfig, QuotaxJourneyConfig, QuotaxExperimentsConfig)
      break
    case TenantEnum.Tractive:
      Vue.prototype.$configurations = ConfigurationLoader.createConfiguration(TractiveGeneralConfig, TractiveAggConfig, TractiveJourneyConfig, TractiveExperimentsConfig)
      break
    case TenantEnum.Wrapper:
      Vue.prototype.$configurations = ConfigurationLoader.createConfiguration(WrapperGeneralConfig, WrapperAggConfig, WrapperJourneyConfig, WrapperExperimentsConfig)
      break
    case TenantEnum.Yoga:
      Vue.prototype.$configurations = ConfigurationLoader.createConfiguration(YogaGeneralConfig, YogaAggConfig, YogaJourneyConfig, YogaExperimentsConfig)
      break
    case TenantEnum.Zoomcover:
      Vue.prototype.$configurations = ConfigurationLoader.createConfiguration(ZoomcoverGeneralConfig, ZoomcoverAggConfig, ZoomcoverJourneyConfig, ZoomcoverExperimentsConfig)
      break
    default:
      throw new Error(`Unable to find tenant configuration for: ${options.tenant}.`)
  }
}
