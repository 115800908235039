import { JsonObject, JsonProperty } from 'json2typescript'
import PropelloCustomerStatusEnum from '../enums/PropelloCustomerStatusEnum'
import PropelloCustomerStatusEnumConverter from '@/converters/PropelloCustomerStatusEnumConverter'

@JsonObject('PropelloLoginLinkResponseModel')
export class PropelloLoginLinkResponseModel {
  @JsonProperty('propelloUserStatus', PropelloCustomerStatusEnumConverter)
  propelloUserStatus: PropelloCustomerStatusEnum = PropelloCustomerStatusEnum.notFound

  @JsonProperty('link', String)
  link: string

  constructor(
    propelloUserStatus = PropelloCustomerStatusEnum.notFound,
    link = '',
  ) {
    this.propelloUserStatus = propelloUserStatus
    this.link = link
  }
}

@JsonObject('BenefitsDetails')
export default class BenefitsDetails {
  @JsonProperty('isSuccess', Boolean)
  isSuccess: boolean

  @JsonProperty('error', String)
  error: string

  @JsonProperty('value', PropelloLoginLinkResponseModel)
  value: PropelloLoginLinkResponseModel = new PropelloLoginLinkResponseModel()

  constructor(
    isSuccess = false,
    error = '',
    value = new PropelloLoginLinkResponseModel(),
  ) {
    this.isSuccess = isSuccess
    this.error = error
    this.value = value
  }
}
