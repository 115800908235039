import { JsonObject, JsonProperty } from 'json2typescript'
import QuoteStatus from '../enums/QuoteStatusEnum'
import ProductEnum from '../enums/ProductEnum'
import SummaryItem from './SummaryItem'
import QuoteStatusEnumConverter from '@/converters/QuoteStatusEnumConverter'
import ProductEnumConverter from '@/converters/ProductEnumConverter'
import DateConverter from '@/converters/DateConverter'

@JsonObject('QuoteSummary')
export default class QuoteSummary {
  @JsonProperty('quoteReference', String)
  quoteReference = ''

  @JsonProperty('product', ProductEnumConverter)
  product: ProductEnum = ProductEnum.modernCar

  @JsonProperty('status', QuoteStatusEnumConverter)
  status: QuoteStatus = QuoteStatus.quoted

  @JsonProperty('isActive', Boolean)
  isActive = false

  @JsonProperty('quoteCreationDate', DateConverter)
  quoteCreationDate: Date = new Date()

  @JsonProperty('effectiveDate', DateConverter)
  effectiveDate: Date = new Date()

  @JsonProperty('calculatedTotalPayable', Number)
  calculatedTotalPayable = 0

  @JsonProperty('summaryItems', [SummaryItem])
  summaryItems: SummaryItem[] = []
}
