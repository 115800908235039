import { Any, JsonObject, JsonProperty } from 'json2typescript'
import Model from './Model'
import Enclosure from './Enclosure'
import type { Dictionary } from '@/types'

@JsonObject('VAPFeatureDescription')
export class VAPFeatureDescription {
  @JsonProperty('description', String)
  description: string

  @JsonProperty('footnote', String)
  footnote: string

  @JsonProperty('overrideText', String)
  overrideText: string

  constructor(description = '', footnote = '', overrideText = '') {
    this.description = description
    this.footnote = footnote
    this.overrideText = overrideText
  }
}

@JsonObject('VAPOption')
export class VAPOption extends Model {
  @JsonProperty('name', String)
  name: string

  @JsonProperty('salePrice', Number)
  salePrice: number

  @JsonProperty('monthlyEstimate', Number)
  monthlyEstimate: number | null

  @JsonProperty('features', Any)
  features: Dictionary<VAPFeatureDescription>

  @JsonProperty('enclosures', [Enclosure])
  enclosures: Enclosure[]

  @JsonProperty('selectedInQuote', Boolean)
  selectedInQuote: boolean

  @JsonProperty('selectedInPolicy', Boolean)
  selectedInPolicy: boolean

  @JsonProperty('autoInclude', Boolean)
  autoInclude: boolean

  @JsonProperty('level', Number)
  level: number | null

  @JsonProperty('subheading', String)
  subheading: string | null

  @JsonProperty('footerMarkdown', String)
  footerMarkdown: string | null

  @JsonProperty('mostPopular', Boolean)
  mostPopular: boolean

  @JsonProperty('bestValue', Boolean)
  bestValue: boolean

  @JsonProperty('ipt', Number)
  ipt: number

  @JsonProperty('displayInPaymentSummary', Boolean)
  displayInPaymentSummary: boolean

  @JsonProperty('cantBeFinanced', Boolean)
  cantBeFinanced: boolean

  constructor(
    id = 0,
    name = '',
    salePrice = 0,
    monthlyEstimate = null,
    features: Dictionary<VAPFeatureDescription> = {},
    enclosures: Enclosure[] = [],
    selectedInQuote = false,
    selectedInPolicy = false,
    autoInclude = false,
    level = null,
    subheading = null,
    footerMarkdown = null,
    mostPopular = false,
    bestValue = false,
    ipt = 0,
    displayInPaymentSummary = false,
    cantBeFinanced = false,
  ) {
    super(id)
    this.name = name
    this.salePrice = salePrice
    this.monthlyEstimate = monthlyEstimate
    this.features = features
    this.enclosures = enclosures
    this.selectedInQuote = selectedInQuote
    this.selectedInPolicy = selectedInPolicy
    this.autoInclude = autoInclude
    this.level = level
    this.subheading = subheading
    this.footerMarkdown = footerMarkdown
    this.mostPopular = mostPopular
    this.bestValue = bestValue
    this.ipt = ipt
    this.displayInPaymentSummary = displayInPaymentSummary
    this.cantBeFinanced = cantBeFinanced
  }
}

@JsonObject('VAPFeature')
export class VAPFeature extends Model {
  @JsonProperty('name', String)
  name: string

  constructor(id = 0, name = '') {
    super(id)
    this.name = name
  }
}

@JsonObject('VAP')
export default class VAP extends Model {
  @JsonProperty('name', String)
  name: string

  @JsonProperty('description', String)
  description: string

  @JsonProperty('isMandatory', Boolean)
  isMandatory: boolean

  @JsonProperty('features', [VAPFeature])
  features: VAPFeature[]

  @JsonProperty('options', [VAPOption])
  options: VAPOption[]

  constructor(
    id = 0,
    name = '',
    description = '',
    isMandatory = false,
    features: VAPFeature[] = [],
    options: VAPOption[] = [],
  ) {
    super(id)
    this.name = name
    this.description = description
    this.isMandatory = isMandatory
    this.features = features
    this.options = options
  }
}
