import { JsonObject, JsonProperty } from 'json2typescript'

@JsonObject('SchemeRequest')
export default class SchemeRequest {
  @JsonProperty('hasFullUKLicence', String)
  hasFullUKLicence = ''

  @JsonProperty('isRegisteredKeeper', String)
  isRegisteredKeeper = ''

  @JsonProperty('isInsuredAnnually', String)
  isInsuredAnnually = ''

  @JsonProperty('isBusinessUseOnly', String)
  isBusinessUseOnly = ''

  @JsonProperty('isImpounded', String)
  isImpounded = ''

  @JsonProperty('drivingOtherCarsOnOtherPolicy', String)
  drivingOtherCarsOnOtherPolicy = ''

  @JsonProperty('isVehicleVan', Boolean)
  isVehicleVan = false
}
