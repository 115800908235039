import type _Vue from 'vue'
import type ThreeDSecureDetials from '@/services/values/ThreeDSecureDetails'
import ThreeDSecureModal from '@/components/payment/ThreeDSecureModal.vue'
import type PaymentRequest from '@/services/values/PaymentRequest'

export interface IThreeDSecure {
  showPrompt: (parent: Vue, details: ThreeDSecureDetials, paymentRequest: PaymentRequest) => Promise<{ cancelled: boolean, transId: string | null }>
}

class ThreeDSecure implements IThreeDSecure {
  private vue: typeof _Vue

  constructor(Vue: typeof _Vue) {
    this.vue = Vue
  }

  showPrompt(parent: Vue, details: ThreeDSecureDetials, paymentRequest: PaymentRequest): Promise<{ cancelled: boolean, transId: string | null }> {
    const ThreeDSecureModalComponent = this.vue.extend(ThreeDSecureModal)
    const component = new ThreeDSecureModalComponent({
      el: document.createElement('div'),
      propsData: {
        threeDSecureDetails: details,
        paymentRequest,
      },
    })

    parent.$el.appendChild(component.$el)

    return new Promise<{ cancelled: boolean, transId: string | null }>((resolve) => {
      component.$off('complete')
      component.$off('cancel')

      component.$on('complete', (transId: string) => {
        resolve({ cancelled: false, transId })
        parent.$el.removeChild(component.$el)
        component.$destroy()
      })
      component.$on('cancel', () => {
        resolve({ cancelled: true, transId: null })
        parent.$el.removeChild(component.$el)
        component.$destroy()
      })
    })
  }
}

export default function ThreeDSecurePlugin(Vue: typeof _Vue): void {
  Vue.prototype.$threeDSecure = new ThreeDSecure(Vue)
}
