import { JsonObject, JsonProperty } from 'json2typescript'
import { DefinedListDetail } from './DefinedList'
import TransactionType from './TransactionType'
import Model from './Model'
import DateConverter from '@/converters/DateConverter'

@JsonObject('PolicyHistory')
export default class PolicyHistory extends Model {
  @JsonProperty('type', TransactionType)
  type: TransactionType

  @JsonProperty('transactionDate', DateConverter)
  transactionDate: Date

  @JsonProperty('paymentType', DefinedListDetail)
  paymentType: DefinedListDetail

  @JsonProperty('amount', Number)
  amount: number

  constructor(
    id: number,
    type: TransactionType,
    transactionDate: Date,
    paymentType: DefinedListDetail,
    amount: number,
  ) {
    super(id)
    this.type = type
    this.transactionDate = transactionDate
    this.paymentType = paymentType
    this.amount = amount
  }
}
