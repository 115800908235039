import { JsonObject, JsonProperty } from 'json2typescript'
import Address from './Address'
import Model from './Model'
import DateConverter from '@/converters/DateConverter'

@JsonObject('Marketing')
export class Marketing {
  @JsonProperty('isEmailOptIn', Boolean)
  isEmailOptIn = false

  @JsonProperty('isSMSOptIn', Boolean)
  isSMSOptIn = false

  @JsonProperty('isPostOptIn', Boolean)
  isPostOptIn = false

  @JsonProperty('isTelephoneOptIn', Boolean)
  isTelephoneOptIn = false
}

@JsonObject('Account')
export default class Account extends Model {
  @JsonProperty('username', String)
  username = ''

  @JsonProperty('firstName', String)
  firstName = ''

  @JsonProperty('lastName', String)
  lastName = ''

  @JsonProperty('email', String)
  email = ''

  @JsonProperty('telephone', String)
  telephone = ''

  @JsonProperty('homePhone', String)
  homePhone = ''

  @JsonProperty('workPhone', String)
  workPhone = ''

  @JsonProperty('mobilePhone', String)
  mobilePhone = ''

  @JsonProperty('marketing', Marketing)
  marketing: Marketing = new Marketing()

  @JsonProperty('needDocumentsPosted', Boolean)
  needDocumentsPosted = false

  @JsonProperty('hasCustomerCredentials', Boolean)
  hasCustomerCredentials = false

  @JsonProperty('dateOfBirth', DateConverter)
  dateOfBirth: Date = new Date()

  @JsonProperty('title', String)
  title = ''

  @JsonProperty('autoRenew', Boolean)
  autoRenew: boolean | null = null

  @JsonProperty('address', Address)
  address: Address = new Address()
}
