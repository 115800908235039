import { JsonObject, JsonProperty } from 'json2typescript'
import Address from './Address'
import Model from './Model'
import { DefinedListDetail } from './DefinedList'
import DateConverter from '@/converters/DateConverter'

@JsonObject('RiskProposer')
export default class RiskProposer extends Model {
  @JsonProperty('title', DefinedListDetail)
    title: DefinedListDetail

  @JsonProperty('firstName', String)
    firstName: string

  @JsonProperty('lastName', String)
    lastName: string

  @JsonProperty('dateOfBirth', DateConverter)
    dateOfBirth: Date

  @JsonProperty('emailAddress', String)
    emailAddress: string

  @JsonProperty('telephoneNumber', String)
    telephoneNumber: string

  @JsonProperty('employmentStatus', DefinedListDetail)
    employmentStatus: DefinedListDetail

  @JsonProperty('occupation', DefinedListDetail)
    occupation: DefinedListDetail

  @JsonProperty('businessType', DefinedListDetail)
    businessType: DefinedListDetail

  @JsonProperty('maritalStatus', DefinedListDetail)
    maritalStatus: DefinedListDetail

  @JsonProperty('studentType', DefinedListDetail)
    studentType: DefinedListDetail

  @JsonProperty('address', Address)
    address: Address

  @JsonProperty('ukResident', Boolean)
    ukResident: boolean

  @JsonProperty('isHomeOwner', Boolean)
    isHomeOwner: boolean

  @JsonProperty('hadInsuranceRefusedCancelledOrDeclined', Boolean)
    hadInsuranceRefusedCancelledOrDeclined: boolean

  @JsonProperty('hasUnspentNonMotoringConvictions', Boolean)
    hasUnspentNonMotoringConvictions: boolean

  @JsonProperty('hasDeclaredBankruptcy', Boolean)
    hasDeclaredBankruptcy: boolean

  @JsonProperty('hadPreviousInsurance', Boolean)
    hadPreviousInsurance: boolean

  @JsonProperty('voluntaryExcess', DefinedListDetail)
    voluntaryExcess: DefinedListDetail

  @JsonProperty('voluntaryExcess2', DefinedListDetail)
    voluntaryExcess2: DefinedListDetail

  @JsonProperty('isPartTime', Boolean)
    isPartTime: boolean

  @JsonProperty('hadSpecialTermsImposed', Boolean)
    hadSpecialTermsImposed: boolean

  @JsonProperty('isClubMember', Boolean)
    isClubMember: boolean

  @JsonProperty('club', DefinedListDetail)
    club: DefinedListDetail

  @JsonProperty('hasSecondaryEmployment', Boolean)
    hasSecondaryEmployment: boolean

  @JsonProperty('secondaryOccupation', DefinedListDetail)
    secondaryOccupation: DefinedListDetail

  @JsonProperty('secondaryBusinessType', DefinedListDetail)
    secondaryBusinessType: DefinedListDetail

  @JsonProperty('secondaryEmploymentStatus', DefinedListDetail)
    secondaryEmploymentStatus: DefinedListDetail

  @JsonProperty('ukResidentSinceDate', DateConverter)
    ukResidentSinceDate: Date

  @JsonProperty('hasBeenRequestedExtraPrecautions', Boolean)
    hasBeenRequestedExtraPrecautions: boolean

  @JsonProperty('hasClaims', Boolean)
  hasClaims: boolean

  @JsonProperty('authorisedPersonsName', String)
  authorisedPersonsName: string

  constructor(
    id = 0,
    title = new DefinedListDetail(),
    firstName = '',
    lastName = '',
    dateofBirth: Date = new Date(),
    emailAddress = '',
    telephoneNumber = '',
    employmentStatus = new DefinedListDetail(),
    occupation = new DefinedListDetail(),
    businessType = new DefinedListDetail(),
    maritalStatus = new DefinedListDetail(),
    studentType = new DefinedListDetail(),
    address = new Address(),
    ukResident = false,
    isHomeOwner = false,
    hadInsuranceRefusedCancelledOrDeclined = false,
    hasUnspentNonMotoringConvictions = false,
    hasDeclaredBankruptcy = false,
    hadPreviousInsurance = false,
    voluntaryExcess = new DefinedListDetail(),
    voluntaryExcess2 = new DefinedListDetail(),
    isPartTime = false,
    hadSpecialTermsImposed = false,
    isClubMember = false,
    club = new DefinedListDetail(),
    hasSecondaryEmployment = false,
    secondaryOccupation = new DefinedListDetail(),
    secondaryBusinessType = new DefinedListDetail(),
    secondaryEmploymentStatus = new DefinedListDetail(),
    ukResidentSinceDate: Date = new Date(),
    hasBeenRequestedExtraPrecautions = false,
    hasClaims = false,
    authorisedPersonsName = '',
  ) {
    super(id)
    this.title = title
    this.firstName = firstName
    this.lastName = lastName
    this.dateOfBirth = dateofBirth
    this.emailAddress = emailAddress
    this.telephoneNumber = telephoneNumber
    this.employmentStatus = employmentStatus
    this.occupation = occupation
    this.businessType = businessType
    this.maritalStatus = maritalStatus
    this.studentType = studentType
    this.address = address
    this.ukResident = ukResident
    this.isHomeOwner = isHomeOwner
    this.hadInsuranceRefusedCancelledOrDeclined = hadInsuranceRefusedCancelledOrDeclined
    this.hasUnspentNonMotoringConvictions = hasUnspentNonMotoringConvictions
    this.hasDeclaredBankruptcy = hasDeclaredBankruptcy
    this.hadPreviousInsurance = hadPreviousInsurance
    this.voluntaryExcess = voluntaryExcess
    this.voluntaryExcess2 = voluntaryExcess2
    this.isPartTime = isPartTime
    this.hadSpecialTermsImposed = hadSpecialTermsImposed
    this.isClubMember = isClubMember
    this.club = club
    this.hasSecondaryEmployment = hasSecondaryEmployment
    this.secondaryOccupation = secondaryOccupation
    this.secondaryBusinessType = secondaryBusinessType
    this.secondaryEmploymentStatus = secondaryEmploymentStatus
    this.ukResidentSinceDate = ukResidentSinceDate
    this.hasBeenRequestedExtraPrecautions = hasBeenRequestedExtraPrecautions
    this.hasClaims = hasClaims
    this.authorisedPersonsName = authorisedPersonsName
  }
}
