import { JsonObject, JsonProperty } from 'json2typescript'
import { DefinedListDetail } from '../DefinedList'
import Model from '../Model'
import DateConverter from '@/converters/DateConverter'

@JsonObject('VehicleConvictionModel')
export default class VehicleConviction extends Model {
  @JsonProperty('convictionDate', DateConverter)
    convictionDate: Date

  @JsonProperty('convictionType', DefinedListDetail)
    convictionType: DefinedListDetail

  @JsonProperty('convictionPersonsInjured', Boolean)
    convictionPersonsInjured: boolean

  @JsonProperty('hasConvictionBan', Boolean)
    hasConvictionBan: boolean

  @JsonProperty('banStartDate', DateConverter)
    banStartDate: Date | null

  @JsonProperty('banEndDate', DateConverter)
    banEndDate: Date | null

  @JsonProperty('convictionFine', Number)
    convictionFine: number

  @JsonProperty('convictionPointsReceived', Number)
    convictionPointsReceived: number

  constructor(
        id = 0,
        convictionDate = new Date(),
        convictionCode = new DefinedListDetail(),
        convictionPersonsInjured = false,
        hasConvictionBan = false,
        banStartDate = null,
        banEndDate = null,
        convictionFine = 0,
        convictionPointsReceived = 0,
  ) {
    super(id)
    this.convictionDate = convictionDate
    this.convictionType = convictionCode
    this.convictionPersonsInjured = convictionPersonsInjured
    this.hasConvictionBan = hasConvictionBan
    this.banStartDate = banStartDate
    this.banEndDate = banEndDate
    this.convictionFine = convictionFine
    this.convictionPointsReceived = convictionPointsReceived
  }
}
