import { JsonObject, JsonProperty } from 'json2typescript'
import Model from '../Model'
import Address from '../Address'
import { DefinedListDetail } from '../DefinedList'
import PropertyClaim from './PropertyClaim'
import SpecifiedItem from './SpecifiedItem'
import DateConverter from '@/converters/DateConverter'

@JsonObject('Property')
export default class Property extends Model {
  @JsonProperty('propertyClaims', [PropertyClaim])
    propertyClaims: PropertyClaim[]

  @JsonProperty('address', Address)
    address: Address

  @JsonProperty('propertyType', DefinedListDetail)
    propertyType: DefinedListDetail

  @JsonProperty('subType', DefinedListDetail)
    subType: DefinedListDetail

  @JsonProperty('yearOfBuild', Number)
   yearOfBuild: number

  @JsonProperty('numberOfBedrooms', DefinedListDetail)
    numberOfBedrooms: DefinedListDetail

  @JsonProperty('numberOfBathrooms', DefinedListDetail)
    numberOfBathrooms: DefinedListDetail

  @JsonProperty('numberOfOtherRooms', DefinedListDetail)
    numberOfOtherRooms: DefinedListDetail

  @JsonProperty('numberOfReceptionRooms', DefinedListDetail)
    numberOfReceptionRooms: DefinedListDetail

  @JsonProperty('distanceFromWater', DefinedListDetail)
    distanceFromWater: DefinedListDetail

  @JsonProperty('hasUnspecifiedItems', Boolean)
    hasUnspecifiedItems: boolean

  @JsonProperty('unspecifiedItemsValue', DefinedListDetail)
    unspecifiedItemsValue: DefinedListDetail

  @JsonProperty('costToRebuild', Number)
    costToRebuild: number

  @JsonProperty('inFloodArea', Boolean)
    inFloodArea: boolean

  @JsonProperty('typeOfLocks', DefinedListDetail)
    typeOfLocks: DefinedListDetail

  @JsonProperty('animalsInProperty', DefinedListDetail)
    animalsInProperty: DefinedListDetail

  @JsonProperty('whenIsPropertyUnoccupied', DefinedListDetail)
    whenIsPropertyUnoccupied: DefinedListDetail

  @JsonProperty('howLongIsPropertyUnoccupied', DefinedListDetail)
    howLongIsPropertyUnoccupied: DefinedListDetail

  @JsonProperty('inVerifiedNeighbourhoodWatchArea', Boolean)
    inVerifiedNeighbourhoodWatchArea: boolean

  @JsonProperty('burglerAlarmFitted', Boolean)
    burglerAlarmFitted: boolean

  @JsonProperty('alarmProfessionallyInstalledMaintained', Boolean)
    alarmProfessionallyInstalledMaintained: boolean

  @JsonProperty('costToReplaceContents', Number)
    costToReplaceContents: number

  @JsonProperty('insuredItemsCarriedOutsideProperty', Boolean)
    insuredItemsCarriedOutsideProperty: boolean

  @JsonProperty('isMainHome', DefinedListDetail)
    isMainHome: DefinedListDetail

  @JsonProperty('listedBuilding', DefinedListDetail)
    listedBuilding: DefinedListDetail

  @JsonProperty('own', DefinedListDetail)
    own: DefinedListDetail

  @JsonProperty('whenAreYouAtProperty', DefinedListDetail)
    whenAreYouAtProperty: DefinedListDetail

  @JsonProperty('howLongAtProperty', DefinedListDetail)
    howLongAtProperty: DefinedListDetail

  @JsonProperty('whoLivesAtProperty', DefinedListDetail)
    whoLivesAtProperty: DefinedListDetail

  @JsonProperty('howManyPeopleWhoArentRelated', DefinedListDetail)
    howManyPeopleWhoArentRelated: DefinedListDetail

  @JsonProperty('howManyAdults', DefinedListDetail)
    howManyAdults: DefinedListDetail

  @JsonProperty('howManyChildren', DefinedListDetail)
    howManyChildren: DefinedListDetail

  @JsonProperty('lessSecureDoorType', DefinedListDetail)
    lessSecureDoorType: DefinedListDetail

  @JsonProperty('additionalExteriorDoorType', DefinedListDetail)
    additionalExteriorDoorType: DefinedListDetail

  @JsonProperty('workingSmokeAlarmType', DefinedListDetail)
    workingSmokeAlarmType: DefinedListDetail

  @JsonProperty('anySmokers', Boolean)
    anySmokers: boolean

  @JsonProperty('anyBusinessUse', DefinedListDetail)
    anyBusinessUse: DefinedListDetail

  @JsonProperty('includeAccidentalDamageForBuildings', Boolean)
    includeAccidentalDamageForBuildings: boolean

  @JsonProperty('includeAccidentalDamageForContents', Boolean)
    includeAccidentalDamageForContents: boolean

  @JsonProperty('includeReplacementLocksAndKeys', Boolean)
    includeReplacementLocksAndKeys: boolean

  @JsonProperty('typeOfPolicy', DefinedListDetail)
    typeOfPolicy: DefinedListDetail

  @JsonProperty('voluntaryExcessValue', DefinedListDetail)
    voluntaryExcessValue: DefinedListDetail

  @JsonProperty('typeOfAlarm', DefinedListDetail)
    typeOfAlarm: DefinedListDetail

  @JsonProperty('tenancyType', DefinedListDetail)
    tenancyType: DefinedListDetail

  @JsonProperty('furnishingsCovered', Boolean)
    furnishingsCovered: boolean

  @JsonProperty('externalWallConstruction', DefinedListDetail)
    externalWallConstruction: DefinedListDetail

  @JsonProperty('hasFlatRoof', Boolean)
    hasFlatRoof: boolean

  @JsonProperty('roofConstruction', DefinedListDetail)
    roofConstruction: DefinedListDetail

  @JsonProperty('flatRoofPercentage', DefinedListDetail)
    flatRoofPercentage: DefinedListDetail

  @JsonProperty('nearTrees', Boolean)
    nearTrees: boolean

  @JsonProperty('hasBeenFlooded', Boolean)
    hasBeenFlooded: boolean

  @JsonProperty('hasExternalWallCracks', Boolean)
    hasExternalWallCracks: boolean

  @JsonProperty('hasBeenUnderpinned', Boolean)
    hasBeenUnderpinned: boolean

  @JsonProperty('hasSubsidence', Boolean)
    hasSubsidence: boolean

  @JsonProperty('inGoodStateOfRepair', Boolean)
    inGoodStateOfRepair: boolean

  @JsonProperty('anyCurrentBuildingWork', Boolean)
    anyCurrentBuildingWork: boolean

  @JsonProperty('hasKeyOperatedLocksOnAllWindows', Boolean)
    hasKeyOperatedLocksOnAllWindows: boolean

  @JsonProperty('hasHighValueItems', Boolean)
    hasHighValueItems: boolean

  @JsonProperty('hasBikesToCover', Boolean)
    hasBikesToCover: boolean

  @JsonProperty('bikeDescription', String)
    bikeDescription: string

  @JsonProperty('bikesValue', Number)
    bikesValue: number

  @JsonProperty('hasBeenExtended', Boolean)
    hasBeenExtended: boolean

  @JsonProperty('typeOfHeating', DefinedListDetail)
    typeOfHeating: DefinedListDetail

  @JsonProperty('hasFixedSafe', Boolean)
    hasFixedSafe: boolean

  @JsonProperty('totalValueOfUnspecifiedItems', Number)
    totalValueOfUnspecifiedItems: number

  @JsonProperty('purchasedDate', DateConverter)
    purchasedDate: Date

  @JsonProperty('notYetPurchased', Boolean)
    notYetPurchased: boolean

  @JsonProperty('specifiedItems', [SpecifiedItem])
    specifiedItems: SpecifiedItem[]

  constructor(
    id = 0,
    propertyClaims: PropertyClaim[] = [],
    address = new Address(),
    propertyType = new DefinedListDetail(),
    subType = new DefinedListDetail(),
    yearOfBuild = 0,
    numberOfBedrooms = new DefinedListDetail(),
    numberOfBathrooms = new DefinedListDetail(),
    numberOfOtherRooms = new DefinedListDetail(),
    numberOfReceptionRooms = new DefinedListDetail(),
    distanceFromWater = new DefinedListDetail(),
    hasUnspecifiedItems = false,
    unspecifiedItemsValue = new DefinedListDetail(),
    costToRebuild = 0,
    inFloodArea = false,
    typeOfLocks = new DefinedListDetail(),
    animalsInProperty = new DefinedListDetail(),
    whenIsPropertyUnoccupied = new DefinedListDetail(),
    howLongIsPropertyUnoccupied = new DefinedListDetail(),
    inVerifiedNeighbourhoodWatchArea = false,
    burglerAlarmFitted = false,
    alarmProfessionallyInstalledMaintained = false,
    costToReplaceContents = 0,
    insuredItemsCarriedOutsideProperty = false,
    isMainHome = new DefinedListDetail(),
    listedBuilding = new DefinedListDetail(),
    own = new DefinedListDetail(),
    whenAreYouAtProperty = new DefinedListDetail(),
    howLongAtProperty = new DefinedListDetail(),
    whoLivesAtProperty = new DefinedListDetail(),
    howManyPeopleWhoArentRelated = new DefinedListDetail(),
    howManyAdults = new DefinedListDetail(),
    howManyChildren = new DefinedListDetail(),
    lessSecureDoorType = new DefinedListDetail(),
    additionalExteriorDoorType = new DefinedListDetail(),
    workingSmokeAlarmType = new DefinedListDetail(),
    anySmokers = false,
    anyBusinessUse = new DefinedListDetail(),
    includeAccidentalDamageForBuildings = false,
    includeAccidentalDamageForContents = false,
    includeReplacementLocksAndKeys = false,
    typeOfPolicy = new DefinedListDetail(),
    voluntaryExcessValue = new DefinedListDetail(),
    typeOfAlarm = new DefinedListDetail(),
    tenancyType = new DefinedListDetail(),
    furnishingsCovered = false,
    externalWallConstruction = new DefinedListDetail(),
    hasFlatRoof = false,
    roofConstruction = new DefinedListDetail(),
    flatRoofPercentage = new DefinedListDetail(),
    nearTrees = false,
    hasBeenFlooded = false,
    hasExternalWallCracks = false,
    hasBeenUnderpinned = false,
    hasSubsidence = false,
    inGoodStateOfRepair = false,
    anyCurrentBuildingWork = false,
    hasKeyOperatedLocksOnAllWindows = false,
    hasHighValueItems = false,
    hasBikesToCover = false,
    bikeDescription = '',
    bikesValue = 0,
    hasBeenExtended = false,
    typeOfHeating = new DefinedListDetail(),
    hasFixedSafe = false,
    totalValueOfUnspecifiedItems = 0,
    purchasedDate: Date = new Date(),
    notYetPurchased = false,
    specifiedItems: SpecifiedItem[],
  ) {
    super(id)
    this.propertyClaims = propertyClaims
    this.address = address
    this.propertyType = propertyType
    this.subType = subType
    this.yearOfBuild = yearOfBuild
    this.numberOfBedrooms = numberOfBedrooms
    this.numberOfBathrooms = numberOfBathrooms
    this.numberOfOtherRooms = numberOfOtherRooms
    this.numberOfReceptionRooms = numberOfReceptionRooms
    this.distanceFromWater = distanceFromWater
    this.hasUnspecifiedItems = hasUnspecifiedItems
    this.unspecifiedItemsValue = unspecifiedItemsValue
    this.costToRebuild = costToRebuild
    this.inFloodArea = inFloodArea
    this.typeOfLocks = typeOfLocks
    this.animalsInProperty = animalsInProperty
    this.whenIsPropertyUnoccupied = whenIsPropertyUnoccupied
    this.howLongIsPropertyUnoccupied = howLongIsPropertyUnoccupied
    this.inVerifiedNeighbourhoodWatchArea = inVerifiedNeighbourhoodWatchArea
    this.burglerAlarmFitted = burglerAlarmFitted
    this.alarmProfessionallyInstalledMaintained = alarmProfessionallyInstalledMaintained
    this.costToReplaceContents = costToReplaceContents
    this.insuredItemsCarriedOutsideProperty = insuredItemsCarriedOutsideProperty
    this.isMainHome = isMainHome
    this.listedBuilding = listedBuilding
    this.own = own
    this.whenAreYouAtProperty = whenAreYouAtProperty
    this.howLongAtProperty = howLongAtProperty
    this.whoLivesAtProperty = whoLivesAtProperty
    this.howManyPeopleWhoArentRelated = howManyPeopleWhoArentRelated
    this.howManyAdults = howManyAdults
    this.howManyChildren = howManyChildren
    this.lessSecureDoorType = lessSecureDoorType
    this.additionalExteriorDoorType = additionalExteriorDoorType
    this.workingSmokeAlarmType = workingSmokeAlarmType
    this.anySmokers = anySmokers
    this.anyBusinessUse = anyBusinessUse
    this.includeAccidentalDamageForBuildings = includeAccidentalDamageForBuildings
    this.includeAccidentalDamageForContents = includeAccidentalDamageForContents
    this.includeReplacementLocksAndKeys = includeReplacementLocksAndKeys
    this.typeOfPolicy = typeOfPolicy
    this.voluntaryExcessValue = voluntaryExcessValue
    this.typeOfAlarm = typeOfAlarm
    this.tenancyType = tenancyType
    this.furnishingsCovered = furnishingsCovered
    this.externalWallConstruction = externalWallConstruction
    this.hasFlatRoof = hasFlatRoof
    this.roofConstruction = roofConstruction
    this.flatRoofPercentage = flatRoofPercentage
    this.nearTrees = nearTrees
    this.hasBeenFlooded = hasBeenFlooded
    this.hasExternalWallCracks = hasExternalWallCracks
    this.hasBeenUnderpinned = hasBeenUnderpinned
    this.hasSubsidence = hasSubsidence
    this.inGoodStateOfRepair = inGoodStateOfRepair
    this.anyCurrentBuildingWork = anyCurrentBuildingWork
    this.hasKeyOperatedLocksOnAllWindows = hasKeyOperatedLocksOnAllWindows
    this.hasHighValueItems = hasHighValueItems
    this.hasBikesToCover = hasBikesToCover
    this.bikeDescription = bikeDescription
    this.bikesValue = bikesValue
    this.hasBeenExtended = hasBeenExtended
    this.typeOfHeating = typeOfHeating
    this.hasFixedSafe = hasFixedSafe
    this.totalValueOfUnspecifiedItems = totalValueOfUnspecifiedItems
    this.purchasedDate = purchasedDate
    this.notYetPurchased = notYetPurchased
    this.specifiedItems = specifiedItems
  }
}
