import type { Route } from 'vue-router'
import Hotjar from '@hotjar/browser'
import { getActivePinia } from 'pinia'
import Analytics from './Analytics'
import type AggregatorClient from '@/services/models/AggregatorClient'
import type QuoteDetails from '@/services/models/QuoteDetails'
import type Risk from '@/services/models/Risk'
import type SchemeQuoteResult from '@/services/models/SchemeQuoteResult'
import useAccountStore from '@/stores/account-store'
import Routes from '@/constants/Routes'

export default class HagertyAnalytics extends Analytics {
  loadAnalytics(): void {
    // Adobe Analytics
    const adobeId = window.injectedEnv.FRONTEND_ADOBE
    if (adobeId) {
      const a = adobeId
      const b = document
      const c = 'script'
      const d = b.createElement(c) as HTMLScriptElement
      d.src = a
      d.type = `text/java${c}`
      d.async = true
      const e = b.getElementsByTagName(c)[0]
      e.parentNode!.insertBefore(d, e)
    }

    // Hotjar Tracking
    Hotjar.init(Number(window.injectedEnv.FRONTEND_HOTJAR), 6)
  }

  async trackRouteChange(vue: Vue, to: Route, _from: Route): Promise<void> {
    interface HagertyView {
      _tag_name?: string
      logged_in: boolean
      hashed_email?: string
    }

    const view: HagertyView = {
      logged_in: false,
    }

    if (getActivePinia()) {
      const accountStore = useAccountStore()
      if (accountStore.account) {
        view.logged_in = true

        const encoder = new TextEncoder()
        const data = encoder.encode(accountStore.account.email.toLowerCase())
        const hashBuffer = await crypto.subtle.digest('SHA-256', data)
        const hashArray = Array.from(new Uint8Array(hashBuffer))
        const hashHex = hashArray
          .map(b => b.toString(16).padStart(2, '0'))
          .join('')
        view.hashed_email = hashHex
      }
    }

    if (to.name === Routes.preQuoteAssumptions)
      view._tag_name = 'quote_start'

    else if (to.name === Routes.premium || to.name === Routes.quoteReferred)
      view._tag_name = 'quote_complete'

    else if (to.name === Routes.paymentConfirm)
      view._tag_name = 'application_complete'

    // Adobe Analytics
    this.window.utag?.view(view)
  }

  trackNBSale<T extends SchemeQuoteResult>(_vue: Vue, _policyNumber: string, _quote: QuoteDetails<T>, _selectedSchemeQuoteResult: number, _aggregatorClient: AggregatorClient | null, _risk: Risk | null): void {
    // do nothing
  }
}
