import { JsonObject, JsonProperty } from 'json2typescript'
import DateConverter from '../../converters/DateConverter'
import Address from '../models/Address'

@JsonObject('StaticCaravanRequest')
export default class StaticCaravanRequest {
  @JsonProperty('caravanTypeDLDID', String, true)
  caravanType: string | null = null

  @JsonProperty('caravanMakeDLDID', String, true)
  caravanMake: string | null = null

  @JsonProperty('caravanModelDLDID', String, true)
  caravanModel: string | null = null

  @JsonProperty('measuredInFeet', Boolean, true)
  measuredInFeet: boolean | null = null

  @JsonProperty('caravanLengthDLDID', String, true)
  caravanLength: string | null = null

  @JsonProperty('caravanWidthDLDID', String, true)
  caravanWidth: string | null = null

  @JsonProperty('noOfBedRoomsDLDID', String, true)
  noOfBedRooms: string | null = null

  @JsonProperty('yearOfMake', Number, true)
  yearOfMake: number | null = null

  @JsonProperty('purchaseDate', DateConverter, true)
  purchaseDate: Date | null = null

  @JsonProperty('notBoughtYet', Boolean, true)
  notBoughtYet: boolean | null = null

  @JsonProperty('isPermanentResidence', Boolean, true)
  isPermanentResidence: boolean | null = null

  @JsonProperty('classOfUseDLDID', String, true)
  classOfUse: string | null = null

  @JsonProperty('goodCondition', Boolean, true)
  goodCondition: boolean | null = null

  @JsonProperty('isAnchored', Boolean, true)
  isAnchored: boolean | null = null

  @JsonProperty('isFittedWithFloatationDevice', Boolean, true)
  isFittedWithFloatationDevice: boolean | null = null

  @JsonProperty('heatingSystemDLDID', String, true)
  heatingSystem: string | null = null

  @JsonProperty('alarmFitted', Boolean, true)
  alarmFitted: boolean | null = null

  @JsonProperty('alarmFittedByDLDID', String, true)
  alarmFittedBy: string | null = null

  @JsonProperty('isHavingAdditionalFeatures', Boolean, true)
  isHavingAdditionalFeatures: boolean | null = null

  @JsonProperty('isSiteRegistered', Boolean, true)
  isSiteRegistered: boolean | null = null

  @JsonProperty('sitedDLDID', String, true)
  sited: string | null = null

  @JsonProperty('plotNumber', String, true)
  plotNumber: string | null = null

  @JsonProperty('numberOfCaravanPitchesDLDID', String, true)
  numberOfCaravanPitches: string | null = null

  @JsonProperty('propertyHasBeenFlooded', Boolean, true)
  propertyHasBeenFlooded: boolean | null = null

  @JsonProperty('hasPreviousStormDamage', Boolean, true)
  hasPreviousStormDamage: boolean | null = null

  @JsonProperty('hasPerimiterFencing', Boolean, true)
  hasPerimiterFencing: boolean | null = null

  @JsonProperty('hasWarden', Boolean, true)
  hasWarden: boolean | null = null

  @JsonProperty('hasSecurityGate', Boolean, true)
  hasSecurityGate: boolean | null = null

  @JsonProperty('hasCCTV', Boolean, true)
  hasCCTV: boolean | null = null

  @JsonProperty('selectedCaravanSecurity', [String])
  selectedCaravanSecurity: string[] | null = []

  @JsonProperty('CaravanSecurityUniqueID', String)
  CaravanSecurityUniqueID: string | null = null

  @JsonProperty('address', Address)
  address: Address = new Address()
}
