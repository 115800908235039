import { JsonObject, JsonProperty } from 'json2typescript'
import DateConverter from '../../converters/DateConverter'
import Model from './Model'

@JsonObject('Document')
export default class Document extends Model {
  @JsonProperty('policyNumber', String)
  policyNumber: string

  @JsonProperty('quoteReference', String)
  quoteReference: string

  @JsonProperty('name', String)
  name: string

  @JsonProperty('correspondenceDate', DateConverter)
  correspondenceDate: Date

  @JsonProperty('path', String)
  path: string

  @JsonProperty('fileType', String)
  fileType: string

  constructor(
    id = 0,
    policyNumber = '',
    quoteReference = '',
    name = '',
    correspondenceDate: Date = new Date(),
    path = '',
    fileType = '',
  ) {
    super(id)
    this.policyNumber = policyNumber
    this.quoteReference = quoteReference
    this.name = name
    this.correspondenceDate = correspondenceDate
    this.path = path
    this.fileType = fileType
  }
}
