import type { JsonCustomConvert } from 'json2typescript'
import { JsonConverter } from 'json2typescript'
import type PropelloCustomerStatusEnum from '@/services/enums/PropelloCustomerStatusEnum'

@JsonConverter
export default class PropelloCustomerStatusEnumConverter implements JsonCustomConvert<PropelloCustomerStatusEnum> {
  serialize(propelloCustomerStatus: PropelloCustomerStatusEnum): string {
    return propelloCustomerStatus.toString()
  }

  deserialize(propelloCustomerStatus: string): PropelloCustomerStatusEnum {
    return propelloCustomerStatus as PropelloCustomerStatusEnum
  }
}
