import { JsonObject, JsonProperty } from 'json2typescript'
import Document from '../models/Document'
import PolicyHistory from '../models/PolicyHistory'
import Product from '../models/Product'
import PolicySummary from './PolicySummary'
import QuoteSummary from './QuoteSummary'
import ClaimSummary from './ClaimSummary'

@JsonObject('CollectionResponse')
export default class CollectionResponse<T> {
  @JsonProperty('total', Number)
  total = 0

  results: T[] = []
}

@JsonObject('PolicyCollectionResponse')
export class PolicyCollectionResponse extends CollectionResponse<PolicySummary> {
  @JsonProperty('results', [PolicySummary])
  results: PolicySummary[] = []
}

@JsonObject('QuotesCollectionResponse')
export class QuotesCollectionResponse extends CollectionResponse<QuoteSummary> {
  @JsonProperty('results', [QuoteSummary])
  results: QuoteSummary[] = []
}

@JsonObject('ClaimsCollectionResponse')
export class ClaimsCollectionResponse extends CollectionResponse<ClaimSummary> {
  @JsonProperty('results', [ClaimSummary])
  results: ClaimSummary[] = []
}

@JsonObject('DocumentCollectionResponse')
export class DocumentCollectionResponse extends CollectionResponse<Document> {
  @JsonProperty('results', [Document])
  results: Document[] = []
}

@JsonObject('PolicyHistoryCollectionResponse')
export class PolicyHistoryCollectionResponse extends CollectionResponse<PolicyHistory> {
  @JsonProperty('results', [PolicyHistory])
  results: PolicyHistory[] = []
}

@JsonObject('ProductCollectionResponse')
export class ProductCollectionResponse extends CollectionResponse<Product> {
  @JsonProperty('results', [Product])
  results: Product[] = []
}
