import { JsonObject, JsonProperty } from 'json2typescript'
import DateConverter from '../../converters/DateConverter'
import SummaryItem from './SummaryItem'

@JsonObject('ClaimSummary')
export default class ClaimSummary {
  @JsonProperty('policyNumber', String)
  policyNumber = ''

  @JsonProperty('status', String)
  status = ''

  @JsonProperty('claimNumber', String)
  claimNumber = ''

  @JsonProperty('claimDate', DateConverter)
  claimDate: Date = new Date()

  @JsonProperty('currentCosts', Number)
  currentCosts = 0

  @JsonProperty('summaryItems', [SummaryItem])
  summaryItems: SummaryItem[] = []
}
