<template>
  <DashboardItem :title="benefitsInfo.title" :loaded="loaded">
    <template #content>
      <div class="benefits-button">
          <b-button :disabled="!benefitsButtonEnabled" @click="onBenefitsLinkClick(benefitsButtonLink)">Access Benefits</b-button>
      </div>
      <p v-if="showDescription">{{ benefitsInfo.description }}</p>
    </template>
  </DashboardItem>
</template>
  
<script lang="ts">
import {
  Component,
  Vue,
} from 'vue-property-decorator';
import NotAuthorisedError from '@/services/error/NotAuthorisedError';
import DashboardItem from './DashboardItem.vue';
import PropelloCustomerStatusEnum from '@/services/enums/PropelloCustomerStatusEnum';

@Component({
  components: {
    DashboardItem
  }
})

export default class Benefits extends Vue {

  loaded = false;

  benefitsButtonLink = "https://hagerty-drivers-club-demo-v2.yourperx.com/"

  benefitsButtonEnabled = false

  async created() {
    try {
      const response = await this.$services.benefitsService.getBenefits()
      if(response.isSuccess) {
        if(response.value.propelloUserStatus === PropelloCustomerStatusEnum.active) {
          this.benefitsButtonLink = response.value?.link
        }
        this.benefitsButtonEnabled = response.value.propelloUserStatus !== PropelloCustomerStatusEnum.notFound
      }
      this.loaded = true;
    } catch (error) {
      if (!(error instanceof NotAuthorisedError)) {
        throw error;
      }
    }
  }

  get benefitsInfo() {
      return this.$configurations.benefitsDashboardItem;
  }

  get showDescription() {
    return this.$configurations.benefitsDashboardItem.description && !this.benefitsButtonEnabled
  }

  onBenefitsLinkClick(benefitsLink: string) {
    window.open(benefitsLink)
  }
}
</script>
  