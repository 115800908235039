enum ProductEnum {
  modernCar = 'ModernCar',
  motorCaravan = 'MotorCaravan',
  multiPet = 'MultiPet',
  taxi = 'Taxi',
  gapInsurance = 'GAPInsurance',
  touringCaravan = 'TouringCaravan',
  caravan = 'Caravan',
  courier = 'Courier',
  vehicle = 'Vehicle',
  commercialVehicle = 'CommercialVehicle',
  breakdown = 'Breakdown',
  staticCaravanResidential = 'StaticCaravanResidential',
  home = 'Home',
}

export enum ProductJourneyType {
  newBuiness = 'nb',
  midTermAdjustment = 'mta',
  renewal = 'renewal',
}

export default ProductEnum
