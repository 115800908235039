enum PaymentTypeEnum {
  cardPayment = 'PaymentType.CardPayment',
  directDebit = 'PaymentType.DirectDebit',
  instalments = 'PaymentType.Instalments',
  cheque = 'PaymentType.Cheque',
  cash = 'PaymentType.Cash',
  ManualCardPayment = 'PaymentType.ManualCardPayment',
  bacs = 'PaymentType.BACS',
  manualInstallmentPayment = 'PaymentType.ManualInstallmentPayment',
  agentAccount = 'PaymentType.AgentAccount',
  goCardless = 'PaymentType.GoCardless',
}

export default PaymentTypeEnum
