import { JsonObject, JsonProperty } from 'json2typescript'

@JsonObject('SpecifiedItemValue')
export default class SpecifiedItem {
  @JsonProperty('typeOfItem', String)
    typeOfItem: string | null = null

  @JsonProperty('insuredItemsCarriedOutsideProperty', String)
  insuredItemsCarriedOutsideProperty: string | null = null

  @JsonProperty('replacementCost', Number)
    replacementCost: number | null = null

  @JsonProperty('specifiedItemDescription', String)
    specifiedItemDescription: string | null = null
}
