import { JsonObject, JsonProperty } from 'json2typescript'
import DateConverter from '../../converters/DateConverter'

@JsonObject('TouringCaravanRequest')
export default class TouringCaravanRequest {
  @JsonProperty('caravanType', String, true)
  caravanType: string | null = null

  @JsonProperty('make', String, true)
  make: string | null = null

  @JsonProperty('model', String, true)
  model: string | null = null

  @JsonProperty('yearOfMake', Number, true)
  yearOfMake: number | null = null

  @JsonProperty('purchaseDate', DateConverter, true)
  purchaseDate: Date | null = null

  @JsonProperty('caravanUssage', String, true)
  caravanUssage: string | null = null

  @JsonProperty('isCRISRegistered', Boolean, true)
  isCRISRegistered: boolean | null = null

  @JsonProperty('crisNumber', String, true)
  crisNumber: string | null = null

  @JsonProperty('alarmType', String, true)
  alarmType: string | null = null

  @JsonProperty('towingFrequency', String, true)
  towingFrequency: string | null = null

  @JsonProperty('trackerType', String, true)
  trackerType: string | null = null

  @JsonProperty('inGoodCondition', Boolean, true)
  inGoodCondition: boolean | null = null

  @JsonProperty('servicedInLastTwoYears', Boolean, true)
  servicedInLastTwoYears: boolean | null = null

  @JsonProperty('isPermanantlySited', Boolean, true)
  isPermanantlySited: boolean | null = null

  @JsonProperty('currentValue', Number, true)
  currentValue: number | null = null

  @JsonProperty('purchasePrice', Number, true)
  purchasePrice: number | null = null

  @JsonProperty('caravanSecurity', [String])
  caravanSecurity: string[] | null = []

  @JsonProperty('caravanTagging', [String])
  caravanTagging: string[] | null = []

  @JsonProperty('caravanSafety', [String])
  caravanSafety: string[] | null = []

  @JsonProperty('ownedByInsuredOrSpouse', Boolean, true)
  ownedByInsuredOrSpouse: boolean | null = null

  @JsonProperty('previouslyWrittenOff', Boolean, true)
  previouslyWrittenOff: boolean | null = null

  @JsonProperty('hasAgreedToDeclarations', Boolean, true)
  hasAgreedToDeclarations: boolean | null = null
}
