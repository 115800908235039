import Hotjar from '@hotjar/browser'
import _Vue from 'vue'
import VueGtag from 'vue-gtag'
import type IAnalyticsConfig from '../IAnalyticsConfig'
import Analytics from './Analytics'

export default class ZoomcoverAnalytics extends Analytics {
  loadAnalytics(config: IAnalyticsConfig): void {
    // Google Tag Manager
    const gtmId = window.injectedEnv.FRONTEND_GTM
    if (gtmId) {
      (
        function loadGTM(w: any, d: any, s: any, l: any, i: any) {
          w[l] = w[l] || []
          w[l].push({ 'gtm.start': new Date().getTime(), 'event': 'gtm.js' })
          const f = d.getElementsByTagName(s)[0]
          const j = d.createElement(s)
          const dl = l !== 'dataLayer' ? `&l=${l}` : ''
          j.async = true
          j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`
          f.parentNode.insertBefore(j, f)
        }
      )(window, document, 'script', 'dataLayer', gtmId)
    }

    // Google Analytics
    _Vue.use(VueGtag, {
      config: {
        id: config.analytics,
        params: {

          send_page_view: false,
        },
      },
    }, config.router)

    // Hotjar Tracking
    Hotjar.init(Number(window.injectedEnv.FRONTEND_HOTJAR), 6)

    // Microsoft Advertising
    const microsoftAdvertising = window.injectedEnv.FRONTEND_MICROSOFT_ADVERTISING
    if (microsoftAdvertising) {
      (
        /* eslint-disable no-unused-expressions, new-cap, no-sequences, style/no-mixed-operators */
        function (w: any, d: any, t: any, r: any, u: any) {
          let f: any, n: any, i: any
          w[u] = w[u] || [], f = function () {
            const o = { ti: microsoftAdvertising } as any
            o.q = w[u], w[u] = new (o)(), w[u].push('pageLoad')
          }, n = d.createElement(t), n.src = r, n.async = 1, n.onload = n.onreadystatechange = function () {
            const s = this.readyState
            s && s !== 'loaded' && s !== 'complete' || (f(), n.onload = n.onreadystatechange = null)
          }, i = d.getElementsByTagName(t)[0], i.parentNode.insertBefore(n, i)
        })(window, document, 'script', '//bat.bing.com/bat.js', 'uetq')
      /* eslint-enable no-unused-expressions, new-cap, no-sequences, style/no-mixed-operators */
    }
  }
}
