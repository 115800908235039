import type { JsonConvert } from 'json2typescript'
import type { AxiosInstance } from 'axios'
import type CardDetails from '../view-models/CardDetails'
import type DirectDebitDetails from '../view-models/DirectDebitDetails'
import type IPaymentService from './interfaces/IPaymentService'
import PaymentRequest from './values/PaymentRequest'
import CreditCardRequest from './values/CreditCard'
import DirectDebit from './values/DirectDebit'
import CreditCard from './models/CreditCard'
import type Policy from './models/Policy'
import type SchemeQuoteResult from './models/SchemeQuoteResult'
import type QuoteDetails from './models/QuoteDetails'
import type ThreeDSecureDetails from './values/ThreeDSecureDetails'
import type { ThreeDSecureAuthorisationResponse } from './values/ThreeDSecureAuthorisation'
import { ThreeDSecureAuthorisationRequest } from './values/ThreeDSecureAuthorisation'
import type AmendDirectDebitDetailsResponse from './models/AmendDirectDebitDetailsResponse'
import type Address from '@/services/models/Address'
import type Account from '@/services/models/Account'
import type { ProductJourneyType } from '@/services/enums/ProductEnum'
import GoCardlessRedirectCompleteResponse from '@/services/models/GoCardlessRedirectCompleteResponse'
import GoCardlessRedirectFlowResponse from '@/services/models/GoCardlessRedirectFlowResponse'

export default class PaymentService implements IPaymentService {
  axios: AxiosInstance

  apiUrl: URL

  jsonConvert: JsonConvert

  constructor(axios: AxiosInstance, apiUrl: URL, jsonConvert: JsonConvert) {
    this.axios = axios
    this.apiUrl = apiUrl
    this.jsonConvert = jsonConvert
  }

  async createGoCardlessRedirectFlow(type: ProductJourneyType, quoteReference: string, referrer: string, policyNumber: string | null, schemeQuoteResultId: number): Promise<GoCardlessRedirectFlowResponse> {
    const config = await this.axios.post(`${this.apiUrl}/payment/gocardless/create-redirect-flow/${type}`, { quoteReference, referrer, policyNumber, schemeQuoteResultId })
    return this.jsonConvert.deserializeObject(config.data, GoCardlessRedirectFlowResponse)
  }

  async completeGoCardlessRedirectFlow(type: ProductJourneyType, jwt: string, redirectFlowId: string, customerPaymentId: number): Promise<GoCardlessRedirectCompleteResponse> {
    const config = await this.axios.post(`${this.apiUrl}/payment/gocardless/complete-redirect-flow/${type}`, {
      jwt,
      redirectFlowId,
      customerPaymentId,
    })
    return this.jsonConvert.deserializeObject(config.data, GoCardlessRedirectCompleteResponse)
  }

  async takeGoCardlessDepositPayment<T extends SchemeQuoteResult>(cardDetails: CardDetails, quote: QuoteDetails<T>, selectedSchemeQuoteResult: SchemeQuoteResult, threeDSecure: (details: ThreeDSecureDetails, paymentRequest: PaymentRequest) => Promise<{ cancelled: boolean, transId: string | null }>): Promise<number | null> {
    const request = PaymentService.mapPaymentRequest(selectedSchemeQuoteResult.directDebitQuote.depositAmount, selectedSchemeQuoteResult, false, cardDetails)

    const threeDSecureResult = await PaymentService.threeDSecureWorkflow(this.axios, this.jsonConvert, this.apiUrl, request, threeDSecure)

    if (threeDSecureResult.cancelled)
      return null

    request.threeDSecureTransId = threeDSecureResult.transId!

    return this.processGoCardlessDepositPayment(quote.quoteReference, request)
  }

  async takePayment<T extends SchemeQuoteResult>(cardDetails: CardDetails, quote: QuoteDetails<T>, selectedSchemeQuoteResult: SchemeQuoteResult, threeDSecure: (details: ThreeDSecureDetails, paymentRequest: PaymentRequest) => Promise<{ cancelled: boolean, transId: string | null }>): Promise<string | null> {
    const request = PaymentService.mapPaymentRequest(selectedSchemeQuoteResult.calculatedTotalPayable, selectedSchemeQuoteResult, false, cardDetails)

    const threeDSecureResult = await PaymentService.threeDSecureWorkflow(this.axios, this.jsonConvert, this.apiUrl, request, threeDSecure)

    if (threeDSecureResult.cancelled)
      return null

    request.threeDSecureTransId = threeDSecureResult.transId!

    return this.processPayment(quote.quoteReference, request)
  }

  async takeRenewalPayment<T extends SchemeQuoteResult>(cardDetails: CardDetails, quote: QuoteDetails<T>, selectedSchemeQuoteResult: SchemeQuoteResult, threeDSecure: (details: ThreeDSecureDetails, paymentRequest: PaymentRequest) => Promise<{ cancelled: boolean, transId: string | null }>): Promise<string | null> {
    const request = PaymentService.mapPaymentRequest(selectedSchemeQuoteResult.calculatedTotalPayable, selectedSchemeQuoteResult, false, cardDetails)

    const threeDSecureResult = await PaymentService.threeDSecureWorkflow(this.axios, this.jsonConvert, this.apiUrl, request, threeDSecure)

    if (threeDSecureResult.cancelled)
      return null

    request.threeDSecureTransId = threeDSecureResult.transId!

    return this.processRenewalPayment(quote.quoteReference, request)
  }

  async takeDirectDebitPayment<T extends SchemeQuoteResult>(cardDetails: CardDetails, quote: QuoteDetails<T>, selectedSchemeQuoteResult: SchemeQuoteResult, directDebit: DirectDebitDetails, threeDSecure: (details: ThreeDSecureDetails, paymentRequest: PaymentRequest) => Promise<{ cancelled: boolean, transId: string | null }>): Promise<string | null> {
    const request = PaymentService.mapPaymentRequest(selectedSchemeQuoteResult.directDebitQuote.depositAmount, selectedSchemeQuoteResult, false, cardDetails, directDebit)

    const threeDSecureResult = await PaymentService.threeDSecureWorkflow(this.axios, this.jsonConvert, this.apiUrl, request, threeDSecure)

    if (threeDSecureResult.cancelled)
      return null

    request.threeDSecureTransId = threeDSecureResult.transId!

    return this.processPayment(quote.quoteReference, request)
  }

  async takeRenewalDirectDebitPayment<T extends SchemeQuoteResult>(cardDetails: CardDetails, quote: QuoteDetails<T>, selectedSchemeQuoteResult: SchemeQuoteResult, directDebit: DirectDebitDetails, threeDSecure: (details: ThreeDSecureDetails, paymentRequest: PaymentRequest) => Promise<{ cancelled: boolean, transId: string | null }>): Promise<string | null> {
    const request = PaymentService.mapPaymentRequest(selectedSchemeQuoteResult.directDebitQuote.depositAmount, selectedSchemeQuoteResult, false, cardDetails, directDebit)

    const threeDSecureResult = await PaymentService.threeDSecureWorkflow(this.axios, this.jsonConvert, this.apiUrl, request, threeDSecure)

    if (threeDSecureResult.cancelled)
      return null

    request.threeDSecureTransId = threeDSecureResult.transId!

    return this.processRenewalPayment(quote.quoteReference, request)
  }

  async addRenewalToExistingDirectDebit<T extends SchemeQuoteResult>(cardDetails: CardDetails, quote: QuoteDetails<T>, selectedSchemeQuoteResult: SchemeQuoteResult, threeDSecure: (details: ThreeDSecureDetails, paymentRequest: PaymentRequest) => Promise<{ cancelled: boolean, transId: string | null }>): Promise<string | null> {
    const request = PaymentService.mapPaymentRequest(selectedSchemeQuoteResult.directDebitQuote.depositAmount, selectedSchemeQuoteResult, true, cardDetails)

    const threeDSecureResult = await PaymentService.threeDSecureWorkflow(this.axios, this.jsonConvert, this.apiUrl, request, threeDSecure)

    if (threeDSecureResult.cancelled)
      return null

    request.threeDSecureTransId = threeDSecureResult.transId!

    return this.processRenewalPayment(quote.quoteReference, request)
  }

  async addRenewalToExistingGoCardless<T extends SchemeQuoteResult>(quote: QuoteDetails<T>, selectedSchemeQuoteResult: SchemeQuoteResult): Promise<string> {
    const request = new PaymentRequest()
    request.singlePaymentAmount = selectedSchemeQuoteResult.calculatedTotalPayable
    request.selectedSchemeQuoteResult = selectedSchemeQuoteResult.id
    request.addToExistingGoCardless = true
    return this.processRenewalPayment(quote.quoteReference, request)
  }

  async takeMTAPayment<T extends SchemeQuoteResult>(cardDetails: CardDetails, quote: QuoteDetails<T>, reasonForChange: string, threeDSecure: (details: ThreeDSecureDetails, paymentRequest: PaymentRequest) => Promise<{ cancelled: boolean, transId: string | null }>): Promise<string | null> {
    const request = PaymentService.mapPaymentRequest(quote.results[0].calculatedTotalPayable, quote.results[0], false, cardDetails)

    const threeDSecureResult = await PaymentService.threeDSecureWorkflow(this.axios, this.jsonConvert, this.apiUrl, request, threeDSecure)

    if (threeDSecureResult.cancelled)
      return null

    request.threeDSecureTransId = threeDSecureResult.transId!

    return this.processMTAPayment(quote.quoteReference, request, reasonForChange)
  }

  async addMTAToExistingDirectDebit<T extends SchemeQuoteResult>(cardDetails: CardDetails, quote: QuoteDetails<T>, reasonForChange: string, threeDSecure: (details: ThreeDSecureDetails, paymentRequest: PaymentRequest) => Promise<{ cancelled: boolean, transId: string | null }>): Promise<string | null> {
    const request = PaymentService.mapPaymentRequest(quote.results[0].directDebitQuote.depositAmount, quote.results[0], true, cardDetails)

    const threeDSecureResult = await PaymentService.threeDSecureWorkflow(this.axios, this.jsonConvert, this.apiUrl, request, threeDSecure)

    if (threeDSecureResult.cancelled)
      return null

    request.threeDSecureTransId = threeDSecureResult.transId!

    return this.processMTAPayment(quote.quoteReference, request, reasonForChange)
  }

  async addMTAToExistingGoCardless<T extends SchemeQuoteResult>(quote: QuoteDetails<T>, reasonForChange: string): Promise<string> {
    const request = new PaymentRequest()
    request.selectedSchemeQuoteResult = quote.results[0].id
    request.addToExistingGoCardless = true
    return this.processMTAPayment(quote.quoteReference, request, reasonForChange)
  }

  async getExistingCards(): Promise<CreditCard[]> {
    const result = await this.axios.get(`${this.apiUrl}/payment/cards`)
    return this.jsonConvert.deserializeArray(result.data, CreditCard)
  }

  async cancelPendingMTA(policy: Policy, cardDetails?: CardDetails, threeDSecure?: (details: ThreeDSecureDetails, paymentRequest: PaymentRequest) => Promise<{ cancelled: boolean, transId: string | null }>): Promise<boolean> {
    const paymentDetails = cardDetails ? PaymentService.mapPaymentRequest(policy.pendingRefundAmount, null, false, cardDetails, undefined) : null

    if (paymentDetails && threeDSecure) {
      const threeDSecureResult = await PaymentService.threeDSecureWorkflow(this.axios, this.jsonConvert, this.apiUrl, paymentDetails, threeDSecure)

      if (threeDSecureResult.cancelled)
        return false

      paymentDetails.threeDSecureTransId = threeDSecureResult.transId!
    }

    return this.axios.post(`${this.apiUrl}/payment/cancel-mta/${policy.policyNumber}`, paymentDetails ? this.jsonConvert.serialize(paymentDetails, PaymentRequest) : null)
  }

  async amendDirectDebitDetails(policyNumber: string, directDebitDetails: DirectDebitDetails): Promise<AmendDirectDebitDetailsResponse> {
    const dd = new DirectDebit()
    dd.accountNumber = directDebitDetails.accountNumber
    dd.accountHolderName = directDebitDetails.accountName
    dd.bankName = directDebitDetails.bankName
    dd.sortCode = directDebitDetails.sortCode
    dd.preferredPaymentDay = directDebitDetails.preferredPaymentDay
    const result = await this.axios.post(`${this.apiUrl}/payment/${policyNumber}/amend-direct-debit-details`, dd)
    return result.data as AmendDirectDebitDetailsResponse
  }

  public static mapCreditCardToRequest(cardDetails: CardDetails) {
    const cardRequest = new CreditCardRequest()
    cardRequest.name = cardDetails.newCard!.nameOnCard
    cardRequest.type = cardDetails.newCard!.cardType
    cardRequest.cardNumber = cardDetails.newCard!.cardNumber
    cardRequest.expiryMonth = cardDetails.newCard!.cardExpiryMonth
    cardRequest.expiryYear = cardDetails.newCard!.cardExpiryYear
    cardRequest.cvv = cardDetails.newCard!.cardCode

    cardRequest.billingAddress = cardDetails.billingAddress!

    return cardRequest
  }

  private static mapPaymentRequest(amount: number, selectedSchemeQuoteResult: SchemeQuoteResult | null, addToExistingDD: boolean, cardDetails: CardDetails, directDebit?: DirectDebitDetails): PaymentRequest {
    const payment = new PaymentRequest()
    payment.singlePaymentAmount = amount
    payment.selectedSchemeQuoteResult = selectedSchemeQuoteResult?.id ?? null

    if (cardDetails.newCard) {
      const card = this.mapCreditCardToRequest(cardDetails)
      payment.creditCard = card
    }

    if (directDebit) {
      const dd = new DirectDebit()
      dd.accountNumber = directDebit.accountNumber
      dd.accountHolderName = directDebit.accountName
      dd.bankName = directDebit.bankName
      dd.sortCode = directDebit.sortCode
      dd.preferredPaymentDay = directDebit.preferredPaymentDay

      payment.directDebit = dd
    }

    if (cardDetails.paymentGatewayResult)
      payment.ignitePaymentGatewayResult = cardDetails.paymentGatewayResult

    payment.existingCard = cardDetails.existingCard
    payment.addToExistingDirectDebit = addToExistingDD

    return payment
  }

  private async processPayment(quoteReference: string, request: PaymentRequest): Promise<string> {
    const model = this.jsonConvert.serialize(request, PaymentRequest)
    const result = await this.axios.post(`${this.apiUrl}/payment/take-up/${encodeURIComponent(quoteReference)}`, model)
    return result.data as string
  }

  private async processGoCardlessDepositPayment(quoteReference: string, request: PaymentRequest): Promise<number> {
    const result = await this.axios.post(`${this.apiUrl}/payment/deposit/${encodeURIComponent(quoteReference)}`, this.jsonConvert.serialize(request, PaymentRequest))
    return result.data as number
  }

  private async processMTAPayment(quoteReference: string, request: PaymentRequest, reasonForChange: string): Promise<string> {
    const result = await this.axios.post(`${this.apiUrl}/payment/take-up/mta/${encodeURIComponent(quoteReference)}`, this.jsonConvert.serialize(request, PaymentRequest), { params: { reasonForChange } })
    return result.data as string
  }

  private async processRenewalPayment(quoteReference: string, request: PaymentRequest): Promise<string> {
    const result = await this.axios.post(`${this.apiUrl}/payment/take-up/renewal/${encodeURIComponent(quoteReference)}`, this.jsonConvert.serialize(request, PaymentRequest))
    return result.data as string
  }

  static async threeDSecureWorkflow(axios: AxiosInstance, jsonConvert: JsonConvert, apiUrl: URL, paymentDetails: PaymentRequest, threeDSecure: (details: ThreeDSecureDetails, paymentRequest: PaymentRequest) => Promise<{ cancelled: boolean, transId: string | null }>): Promise<{ cancelled: boolean, transId: string | null }> {
    if (!paymentDetails.creditCard || paymentDetails.existingCard)
      return { cancelled: false, transId: null }

    const result = await axios.post(`${apiUrl}/payment/3dsecure`, jsonConvert.serialize(paymentDetails, PaymentRequest))

    const threeDSecureDetails = result.data as ThreeDSecureDetails

    let threeDSecureTransId: string | null = null
    if (threeDSecureDetails?.required) {
      const threeDSecurePrompt = await threeDSecure(threeDSecureDetails, paymentDetails)

      if (threeDSecurePrompt.cancelled)
        return { cancelled: true, transId: null }

      threeDSecureTransId = threeDSecurePrompt.transId!
    }

    return { cancelled: false, transId: threeDSecureTransId }
  }

  async startPaymentProcess(amount: number, account: Account, billingAddress: Address | null, presentationMethod: number): Promise<string> {
    const jsonRequest = {
      Amount: amount.toFixed(2),
      UserName: account.username,
      BillingAddress: billingAddress,
      RedirectUri: location.origin + location.pathname,
      presentationMethod, // todo maybe use enum here
    }

    const result = await this.axios.post(`${this.apiUrl}/payment-gateway/load-payment-options`, jsonRequest)
    return result.data
  }

  async performThreeDSecureAuth(paymentRequest: PaymentRequest, authUrl: string, param: string, transId: string) {
    const threeDSecureAuth: ThreeDSecureAuthorisationRequest = {
      authUrl,
      transId,
      browserInfo: param,
      paymentRequest,
    }
    const result = await this.axios.post(`${this.apiUrl}/payment/3dsecure-auth`, this.jsonConvert.serialize(threeDSecureAuth, ThreeDSecureAuthorisationRequest))

    return result.data as ThreeDSecureAuthorisationResponse
  }
}
