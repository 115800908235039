import { JsonObject, JsonProperty } from 'json2typescript'
import QuoteDetails from '../QuoteDetails'
import type { VehicleSchemeQuoteResult } from '../SchemeQuoteResult'
import VehicleDriver from './VehicleDriver'
import VehicleProposer from './VehicleProposer'
import VehicleRiskVehicle from './VehicleRiskVehicle'
import PaymentTypeEnum from '@/services/enums/PaymentTypeEnum'
import PaymentTypeEnumConverter from '@/converters/PaymentTypeEnumConverter'

@JsonObject('VehicleQuoteDetails')
export default class VehicleQuoteDetails extends QuoteDetails<VehicleSchemeQuoteResult> {
  @JsonProperty('vehicleProposer', VehicleProposer)
    vehicleProposer: VehicleProposer = new VehicleProposer()

  @JsonProperty('vehicles', [VehicleRiskVehicle])
    vehicles: VehicleRiskVehicle[] = []

  @JsonProperty('vehicleDrivers', [VehicleDriver])
    vehicleDrivers: VehicleDriver[] = []
}

@JsonObject('VehicleRenewalQuoteDetails')
export class VehicleRenewalQuoteDetails extends VehicleQuoteDetails {
  @JsonProperty('initialPaymentMethod', PaymentTypeEnumConverter)
  initialPaymentMethod: PaymentTypeEnum = PaymentTypeEnum.cardPayment

  @JsonProperty('previousPolicyScheme', String)
  previousPolicyScheme = ''
}

@JsonObject('VehicleMTAQuoteDetails')
export class VehicleMTAQuoteDetails extends VehicleQuoteDetails {
  @JsonProperty('initialPaymentMethod', PaymentTypeEnumConverter)
  initialPaymentMethod: PaymentTypeEnum = PaymentTypeEnum.cardPayment

  @JsonProperty('hasUpgradableVAP', Boolean)
  hasUpgradableVAP = true

  @JsonProperty('hasClaims', Boolean)
  hasClaims = false

  @JsonProperty('message', String)
  message = ''
}
