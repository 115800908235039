import { JsonObject, JsonProperty } from 'json2typescript'
import PaymentTypeEnum from '../enums/PaymentTypeEnum'
import PetProposer from './PetProposer'
import PetRiskPet from './PetRiskPet'
import QuoteDetails from './QuoteDetails'
import type { PetSchemeQuoteResult } from './SchemeQuoteResult'
import PaymentTypeEnumConverter from '@/converters/PaymentTypeEnumConverter'

@JsonObject('PetQuoteDetails')
export default class PetQuoteDetails extends QuoteDetails<PetSchemeQuoteResult> {
  @JsonProperty('proposer', PetProposer)
  proposer: PetProposer = new PetProposer()

  @JsonProperty('pets', [PetRiskPet])
  pets: PetRiskPet[] = []
}

@JsonObject('PetMTAQuoteDetails')
export class PetMTAQuoteDetails extends PetQuoteDetails {
  @JsonProperty('initialPaymentMethod', PaymentTypeEnumConverter)
  initialPaymentMethod: PaymentTypeEnum = PaymentTypeEnum.cardPayment
}

@JsonObject('PetRenewalQuoteDetails')
export class PetRenewalQuoteDetails extends PetQuoteDetails {
  @JsonProperty('initialPaymentMethod', PaymentTypeEnumConverter)
  initialPaymentMethod: PaymentTypeEnum = PaymentTypeEnum.cardPayment

  @JsonProperty('previousPolicyScheme', String)
  previousPolicyScheme = ''
}
