<template>
  <article class="dash-item">
    <h4 class="title">{{ title }}</h4>
    <div class="content">
      <slot v-if="loaded" name="content" />
    </div>
    <b-loading :is-full-page="false" :active="!loaded" />
  </article>
</template>

<script lang="ts">
import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';

@Component
export default class DashboardItem extends Vue {
  @Prop() title!: string;

  @Prop() loaded!: boolean;
}
</script>
