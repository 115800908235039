import { JsonObject, JsonProperty } from 'json2typescript'
import DateConverter from '../../converters/DateConverter'

@JsonObject('CaravanClaimValue')
export default class CaravanClaimRequests {
  @JsonProperty('claimType', String, true)
  claimType: string | null = null

  @JsonProperty('claimDate', DateConverter, true)
  claimDate: Date | null = null

  @JsonProperty('claimCost', Number, true)
  claimCost: number | null = null

  @JsonProperty('claimStatus', String, true)
  claimStatus: string | null = null
}
