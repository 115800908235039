import { JsonObject, JsonProperty } from 'json2typescript'
import PersonalDetails from './PersonalDetails'

@JsonObject('AdditionalPolicyholderValue')
export default class AdditionalPolicyholder extends PersonalDetails {
  @JsonProperty('maritalStatus', String)
    maritalStatus: string | null = null

  @JsonProperty('relationshiptoPolicyholder', String)
    relationshiptoPolicyholder: string | null = null

  @JsonProperty('employmentStatus', String)
    employmentStatus: string | null = null

  @JsonProperty('occupation', String)
    occupation: string | null = null

  @JsonProperty('businessType', String)
    businessType: string | null = null

  @JsonProperty('studentType', String)
    studentType: string | null = null

  @JsonProperty('hasSecondaryEmployment', Boolean)
    hasSecondaryEmployment: boolean | null = null

  @JsonProperty('secondaryOccupation', String)
    secondaryOccupation: string | null = null

  @JsonProperty('secondaryBusinessType', String)
    secondaryBusinessType: string | null = null
}
