import moment from 'moment'
import type IQuestionMapper from '../interfaces/IQuestionMapper'
import type IRequestMapper from '../interfaces/IRequestMapper'
import type IResponseMapper from '../interfaces/IResponseMapper'
import type ProductPage from '../models/ProductPage'
import type SchemeRequest from '../values/SchemeRequest'
import type QuoteRequest from '../values/QuoteRequest'
import AccountRegister from '../values/AccountRegister'
import type Account from '../models/Account'
import { Marketing } from '../models/Account'
import type { PetRisk } from '../models/Risk'
import type IPageMappings from '../interfaces/IPageMappings'
import type { IQuestionAnswer } from '../values/PageAnswers'
import PageAnswers, { QuestionAnswer } from '../values/PageAnswers'
import type Address from '../models/Address'
import QuestionEnum from '../enums/QuestionEnum'
import PetQuoteRequest, { PetMTAQuoteRequest } from '../values/PetQuoteRequest'
import DLDCommonValuesEnum from '../enums/DLDCommonValuesEnum'
import UnkownQuestionSetError from '../error/UnknownQuestionSetError'
import type IQuestion from '../interfaces/IQuestion'
import BaseMapper from './BaseMapper'
import KeeyPartnersExperimentsConfig from '@/configurations/experiments/keeypartners.json'
import KeeyPartnersJourneyConfig from '@/configurations/journey/keeypartners.json'
import KeeyPartnersGeneralConfig from '@/configurations/general/keeypartners.json'
import KeeyPartnersAggConfig from '@/configurations/aggregator/keeypartners.json'
import ConfigurationLoader from '@/configurations/ConfigurationLoader'
import type IConfiguration from '@/configurations/IConfiguration'
import {
  MarkdownQuestion,
} from '@/view-models/Question'
import type {
  DateQuestion,
  NumberQuestion,
  PetsQuestion,
  SelectQuestion,
  SubPagesQuestion,
  TextQuestion,
  VeriskQuestion,
} from '@/view-models/Question'
import ContextLevel from '@/services/enums/ContextLevel'
import Routes from '@/constants/Routes'
import type { Dictionary } from '@/types'
import type Page from '@/view-models/Page'

export default class KeeyPartnersMapper extends BaseMapper implements IQuestionMapper, IRequestMapper, IResponseMapper, IPageMappings {
  nbPageMappings: Dictionary<{ route: Routes, contextLevel: ContextLevel }> = {
    MultiPet_Pet_Details_CustomerFacing: { route: Routes.petDetails, contextLevel: ContextLevel.preQuote },
    MultiPet_Proposer_Details_CustomerFacing: { route: Routes.proposerDetails, contextLevel: ContextLevel.preQuote },
    MultiPet_Premium_CustomerFacing: { route: Routes.premium, contextLevel: ContextLevel.quote },
    MultiPet_Declarations_CustomerFacing: { route: Routes.declarations, contextLevel: ContextLevel.quote },
    MultiPet_Payment_CustomerFacing: { route: Routes.payment, contextLevel: ContextLevel.quote },
    MultiPet_PaymentConfirmation: { route: Routes.paymentConfirm, contextLevel: ContextLevel.policy },
  }

  mtaPageMappings: Dictionary<{ route: Routes, contextLevel: ContextLevel }> = {
    MultiPet_PetMta_Details_CustomerFacing: { route: Routes.mtaPetDetails, contextLevel: ContextLevel.mtaPreQuote },
    MultiPet_PremiumMta_CustomerFacing: { route: Routes.mtaPremium, contextLevel: ContextLevel.mtaQuote },
    MultiPet_Declarations_CustomerFacing: { route: Routes.mtaDeclarations, contextLevel: ContextLevel.mtaQuote },
    MultiPet_Payment_CustomerFacing: { route: Routes.mtaPayment, contextLevel: ContextLevel.mtaQuote },
    MultiPet_PaymentConfirmation: { route: Routes.mtaConfirm, contextLevel: ContextLevel.mtaTakenUp },
  }

  renewalPageMappings: Dictionary<{ route: Routes, contextLevel: ContextLevel }> = {
    MultiPet_PetRenewal_Details_CustomerFacing: { route: Routes.renewalPetDetails, contextLevel: ContextLevel.renewalPreQuote },
    MultiPet_ProposerRenewal_Details_CustomerFacing: { route: Routes.renewalProposerDetails, contextLevel: ContextLevel.renewalPreQuote },
    MultiPet_PremiumRenewal_CustomerFacing: { route: Routes.renewalPremium, contextLevel: ContextLevel.renewalQuote },
    MultiPet_DeclarationsRenewal_CustomerFacing: { route: Routes.renewalDeclarations, contextLevel: ContextLevel.renewalQuote },
    MultiPet_PaymentRenewal_CustomerFacing: { route: Routes.renewalPayment, contextLevel: ContextLevel.renewalQuote },
    MultiPet_PaymentConfirmation: { route: Routes.renewalPaymentConfirmation, contextLevel: ContextLevel.renewalTakenUp },
  }

  setupPage(setId: string, productPage: ProductPage, answers: IQuestionAnswer[], affiliateId?: number, parent?: SubPagesQuestion): Page {
    const mappedPage = this.mapProductPageToPage(productPage)
    return this.setupDependencies(setId, mappedPage, affiliateId, parent)
  }

  setupDependencies(setId: string, mappedPage: Page, affiliateId?: number, parent?: SubPagesQuestion): Page {
    const config = ConfigurationLoader.createConfiguration(KeeyPartnersGeneralConfig, KeeyPartnersAggConfig, KeeyPartnersJourneyConfig, KeeyPartnersExperimentsConfig)
    switch (setId) {
      case 'MultiPet_Pet_Details_CustomerFacing_PetComponent':
        return this.setUpPetComponentDependencies(mappedPage, config, true, affiliateId, parent)
      case 'MultiPet_PetRenewal_Details_CustomerFacing_PetComponent':
      case 'MultiPet_PetMta_Details_CustomerFacing_PetComponent':
        return this.setUpPetComponentDependencies(mappedPage, config, false, affiliateId, parent)
      case 'MultiPet_Pet_Details_CustomerFacing':
        return this.setUpPetDetailsDependencies(mappedPage, config, true)
      case 'MultiPet_PetRenewal_Details_CustomerFacing':
      case 'MultiPet_PetMta_Details_CustomerFacing':
        return this.setUpPetDetailsDependencies(mappedPage, config, false)
      case 'MultiPet_Premium_CustomerFacing':
      case 'MultiPet_PremiumRenewal_CustomerFacing':
      case 'MultiPet_PremiumMta_CustomerFacing':
      case 'MultiPet_Declarations_CustomerFacing':
      case 'MultiPet_DeclarationsRenewal_CustomerFacing':
      case 'MultiPet_Proposer_Details_CustomerFacing':
      case 'MultiPet_ProposerRenewal_Details_CustomerFacing':
      case 'MultiPet_Payment_CustomerFacing':
      case 'MultiPet_PaymentRenewal_CustomerFacing':
      case 'MultiPet_AggregatorLanding':
      case 'MultiPet_PaymentConfirmation':
      case 'Account':
        return mappedPage
      default:
        throw new UnkownQuestionSetError(setId)
    }
  }

  private setUpPetComponentDependencies(page: Page, config: IConfiguration, isNB: boolean, affiliateId?: number, parent?: SubPagesQuestion): Page {
    const questions = page.sections.flatMap(s => s.questions)
    const petType = questions.find(q => q.name === QuestionEnum.petType) as SelectQuestion<string, string>
    const petName = questions.find(q => q.name === QuestionEnum.name) as TextQuestion
    const gender = questions.find(q => q.name === QuestionEnum.gender)
    const dateOfBirth = questions.find(q => q.name === QuestionEnum.dateOfBirth) as DateQuestion
    dateOfBirth.maxDate = () => moment().add(-4, 'days').toDate()
    const catType = questions.find(q => q.name === QuestionEnum.catType) as SelectQuestion<string, string>
    const dogType = questions.find(q => q.name === QuestionEnum.dogType) as SelectQuestion<string, string>
    const sizeOfPet = questions.find(q => q.name === QuestionEnum.sizeOfPet)
    const costOfPet = questions.find(q => q.name === QuestionEnum.costOfPet) as NumberQuestion
    const catBreed = questions.find(q => q.name === QuestionEnum.catBreed) as SelectQuestion<string, string>
    catBreed.options = catBreed.options.filter(d => d.value !== DLDCommonValuesEnum.CatBreeds.Moggie).sort((a, b) => ((a.name > b.name) ? 1 : -1))
    const dogBreed = questions.find(q => q.name === QuestionEnum.dogBreed) as SelectQuestion<string, string>
    dogBreed.options = dogBreed.options.filter(d => d.value !== DLDCommonValuesEnum.DogBreeds.Mongrel && d.value !== DLDCommonValuesEnum.DogBreeds.Crossbreed && d.value !== DLDCommonValuesEnum.DogBreeds.CrossbreedSmall && d.value !== DLDCommonValuesEnum.DogBreeds.CrossbreedMedium && d.value !== DLDCommonValuesEnum.DogBreeds.CrossbreedLarge).sort((a, b) => ((a.name > b.name) ? 1 : -1))
    const hasBeenNeutered = questions.find(q => q.name === QuestionEnum.hasBeenNeutered)
    const hasBeenChipped = questions.find(q => q.name === QuestionEnum.hasBeenChipped)
    const hasUptoDateVaccinations = questions.find(q => q.name === QuestionEnum.hasUptoDateVaccinations)
    const hasAttacked = questions.find(q => q.name === QuestionEnum.hasAttacked) as SelectQuestion<string, string>
    const proposerOwnsPet = questions.find(q => q.name === QuestionEnum.proposerOwnsPet)
    const everSeenVetForIllness = questions.find(q => q.name === QuestionEnum.everSeenVetForIllness) as SelectQuestion<string, string>
    const isAwaitingReview = questions.find(q => q.name === QuestionEnum.isAwaitingReview) as SelectQuestion<string, string>
    const coverPreExistingConditions = questions.find(q => q.name === QuestionEnum.coverPreExistingConditions) as SelectQuestion<string, string>
    const dogSize = questions.find(q => q.name === QuestionEnum.sizeOfPet) as SelectQuestion<string, string>
    const manyPetsText = questions.find(q => q.name === QuestionEnum.manyPetsText) as MarkdownQuestion
    const preExistingText = questions.find(q => q.name === QuestionEnum.preExistingText) as MarkdownQuestion
    const veriskQuestion = questions.find(q => q.name === QuestionEnum.verisk) as VeriskQuestion

    petName.maxlength = 30

    catType.isVisible = (): boolean => petType.value === DLDCommonValuesEnum.PetType.Cat
    catBreed.isVisible = (): boolean => petType.value === DLDCommonValuesEnum.PetType.Cat && catType.value === DLDCommonValuesEnum.CatType.Pedigree

    dogType.isVisible = (): boolean => petType.value === DLDCommonValuesEnum.PetType.Dog
    dogBreed.isVisible = (): boolean => petType.value === DLDCommonValuesEnum.PetType.Dog && dogType.value === DLDCommonValuesEnum.DogType.Pedigree
    dogSize.isVisible = (): boolean => petType.value === DLDCommonValuesEnum.PetType.Dog && (dogType.value === DLDCommonValuesEnum.DogType.Crossbreed || dogType.value === DLDCommonValuesEnum.DogType.Mongrel)
    hasAttacked.isVisible = (): boolean => petType.value === DLDCommonValuesEnum.PetType.Dog

    everSeenVetForIllness.isVisible = (): boolean => parent!.subPageDetails.length < 2 && everSeenVetForIllness.visible
    if (config.veriskEnabled) {
      isAwaitingReview.isVisible = (): boolean => parent!.subPageDetails.length < 2 && everSeenVetForIllness.value === DLDCommonValuesEnum.Boolean.Yes && everSeenVetForIllness.isVisible() && isAwaitingReview.visible
      preExistingText.isVisible = coverPreExistingConditions.isVisible = (): boolean => parent!.subPageDetails.length < 2 && everSeenVetForIllness.value === DLDCommonValuesEnum.Boolean.Yes && everSeenVetForIllness.isVisible() && isAwaitingReview.value === DLDCommonValuesEnum.Boolean.No && isAwaitingReview.isVisible() && coverPreExistingConditions.visible

      // BIT HACKY THIS BUT BEST I CAN DO AT THE MOMENT
      if (manyPetsText) {
        const maxPets = parent!.maxSubPages
        manyPetsText.isVisible = (): boolean => {
          const result = parent!.subPageDetails.length > 1 || ((everSeenVetForIllness.value === DLDCommonValuesEnum.Boolean.No) || (everSeenVetForIllness.value === DLDCommonValuesEnum.Boolean.Yes && isAwaitingReview.value === DLDCommonValuesEnum.Boolean.Yes) || (everSeenVetForIllness.value === DLDCommonValuesEnum.Boolean.Yes && isAwaitingReview.value === DLDCommonValuesEnum.Boolean.No && coverPreExistingConditions.value === DLDCommonValuesEnum.Boolean.No))
          if (result)
            parent!.maxSubPages = maxPets
          else
            parent!.maxSubPages = 1

          if (!everSeenVetForIllness.isVisible() && parent!.subPageDetails.length === 1)
            return false

          return result
        }
      }

      veriskQuestion.isVisible = (): boolean => everSeenVetForIllness.value === DLDCommonValuesEnum.Boolean.Yes && isAwaitingReview.value === DLDCommonValuesEnum.Boolean.No && coverPreExistingConditions.value === DLDCommonValuesEnum.Boolean.Yes && everSeenVetForIllness.isVisible() && isAwaitingReview.isVisible() && coverPreExistingConditions.isVisible() && veriskQuestion.visible
    }
    else {
      isAwaitingReview.isVisible = preExistingText.isVisible = coverPreExistingConditions.isVisible = veriskQuestion.isVisible = (): boolean => false

      if (manyPetsText) {
        const maxPets = parent!.maxSubPages
        manyPetsText.isVisible = (): boolean => {
          const result = parent!.subPageDetails.length > 1 || (isNB && (everSeenVetForIllness.value === DLDCommonValuesEnum.Boolean.Yes || everSeenVetForIllness.value === DLDCommonValuesEnum.Boolean.No))
          if (result)
            parent!.maxSubPages = maxPets
          else
            parent!.maxSubPages = 1

          if (!everSeenVetForIllness.isVisible() && parent!.subPageDetails.length === 1)
            return false

          return result
        }
        manyPetsText.markdown = 'Any illness, disease or injury that your pet has shown clinical signs for and/or you have sought veterinary advice or treatment will be considered as a pre-existing condition. Pre-existing conditions are excluded from cover.'
      }
    }

    costOfPet.min = 0

    petName!.dependentQuestions = [petType!]
    gender!.dependentQuestions = [petName!]
    dateOfBirth!.dependentQuestions = [petName!]
    catType!.dependentQuestions = [petType!, petName!]
    dogType!.dependentQuestions = [petType!, petName!]
    sizeOfPet!.dependentQuestions = [petName!]
    costOfPet!.dependentQuestions = [petName!]
    hasBeenNeutered!.dependentQuestions = [petName!]
    hasBeenChipped!.dependentQuestions = [petName!]
    hasUptoDateVaccinations!.dependentQuestions = [petName!]
    hasAttacked!.dependentQuestions = [petName!]
    proposerOwnsPet!.dependentQuestions = [petName!]
    everSeenVetForIllness!.dependentQuestions = [petName!]
    isAwaitingReview!.dependentQuestions = [petName!]
    coverPreExistingConditions!.dependentQuestions = [petName!]
    veriskQuestion!.dependentQuestions = [petType!]

    if (affiliateId) {
      let affiliateLink = ''
      let affiliateName = ''
      switch (affiliateId) {
        case 19:
          affiliateLink = 'https://www.comparecover.com/xml/affiliate.cfm?affiliate=comparecover&affiliateNumber=220&type=pet&source=scratchandpatch'
          affiliateName = 'CompareCover.com'
          break
        case 16:
          affiliateLink = 'https://pet.confused.com/xml/affiliate.cfm?affiliate=confused_travel&affiliateNumber=199&type=pet&source=scratchandpatch'
          affiliateName = 'Confused.com'
          if (everSeenVetForIllness.isVisible() && !everSeenVetForIllness.value)
            everSeenVetForIllness.value = DLDCommonValuesEnum.Boolean.Yes

          break
        case 20:
          affiliateLink = 'https://www.quote-link.net/xml/affiliate.cfm?affiliate=payingtoomuch&affiliateNumber=208&type=pet&source=scratchandpatch'
          affiliateName = 'Paying Too Much'
          break
        default:
          return page
      }

      const affiliateMarkdown = new MarkdownQuestion(
        0,
        'Please return to our partner site for cover.',
        QuestionEnum.affiliateMarkdown,
        false,
        null,
        null,
        `By selecting ‘No’ to this question we can only show you prices for policies which exclude pre-existing conditions. To compare standard pet insurance from the whole market you may wish to go back to <a href="${affiliateLink}" target="_blank">${affiliateName}</a>.`,
      )

      affiliateMarkdown.isVisible = () => everSeenVetForIllness.value === DLDCommonValuesEnum.Boolean.No

      const section = page.sections.find(s => s.questions.find(q => q.name === QuestionEnum.everSeenVetForIllness))!
      section.questions.splice(section.questions.findIndex(q => q.name === QuestionEnum.everSeenVetForIllness) + 1, 0, affiliateMarkdown)
    }

    return page
  }

  private setUpPetDetailsDependencies(page: Page, config: IConfiguration, isNB: boolean): Page {
    const questions = page.sections.flatMap(s => s.questions)
    const effectiveDate = questions.find(q => q.name === QuestionEnum.effectiveDate || q.name === QuestionEnum.mtaEffectiveDate) as DateQuestion
    effectiveDate.minDate = () => moment().toDate()
    effectiveDate.maxDate = () => moment().add(31, 'days').toDate()
    const petComponentQuestion = questions.find(q => q.name === QuestionEnum.pets) as PetsQuestion
    const petCoverTypeQuestion = questions.find(q => q.name === QuestionEnum.petCoverType) as SelectQuestion<string, string>
    if (config.veriskEnabled) {
      petCoverTypeQuestion.isVisible = (): boolean => !!petComponentQuestion.subPageDetails.find((spd) => {
        const spdQuestions = spd.page.sections.flatMap(s => s.questions)
        const spdIllness = spdQuestions.find(q => q.name === QuestionEnum.everSeenVetForIllness) as SelectQuestion<string, string>
        const spdReview = spdQuestions.find(q => q.name === QuestionEnum.isAwaitingReview) as SelectQuestion<string, string>
        const spdPreExisting = spdQuestions.find(q => q.name === QuestionEnum.coverPreExistingConditions) as SelectQuestion<string, string>

        return (spdIllness.value === DLDCommonValuesEnum.Boolean.No || spdReview.value === DLDCommonValuesEnum.Boolean.Yes || spdPreExisting.value === DLDCommonValuesEnum.Boolean.No) && petCoverTypeQuestion.visible
      })
    }
    else {
      petCoverTypeQuestion.isVisible = (): boolean => isNB
    }

    this.setupPetCoverTypeDependencies(questions)
    this.setUpPetProposerDependencies(page)
    return page
  }

  private setUpPetProposerDependencies(page: Page): Page {
    const questions = page.sections.flatMap(s => s.questions)
    const dateOfBirth = questions.find(q => q.name === QuestionEnum.dateOfBirth) as DateQuestion
    dateOfBirth.maxDate = () => moment().add(-18, 'years').toDate()
    return page
  }

  private setupPetCoverTypeDependencies(questions: IQuestion[]) {
    const petCoverTypeQuestion = questions.find(q => q.name === QuestionEnum.petCoverType) as SelectQuestion<string, string>
    const petSubCoverTypeQuestion = questions.find(q => q.name === QuestionEnum.petSubCoverType) as SelectQuestion<string, string>
    if (petCoverTypeQuestion && petSubCoverTypeQuestion)
      petSubCoverTypeQuestion.isVisible = (): boolean => petCoverTypeQuestion.isVisible() && petCoverTypeQuestion.value === DLDCommonValuesEnum.PetCoverType.AccidentAndIllness
  }

  getSchemeRequest(_questions: IQuestionAnswer[]): SchemeRequest {
    throw new Error('Method not implemented.')
  }

  validate(_questions: IQuestionAnswer[]): boolean {
    throw new Error('Method not implemented. KeeyPartners does not use custom validation.')
  }

  getQuoteRequest<T extends QuoteRequest>(questions: IQuestionAnswer[], Type: new () => T): T {
    const title = questions.find(q => q.name === QuestionEnum.title) as QuestionAnswer<string>
    const firstName = questions.find(q => q.name === QuestionEnum.firstname) as QuestionAnswer<string>
    const lastName = questions.find(q => q.name === QuestionEnum.lastName) as QuestionAnswer<string>
    const dateOfBirth = questions.find(q => q.name === QuestionEnum.dateOfBirth) as QuestionAnswer<Date>
    const address = questions.find(q => q.name === QuestionEnum.proposerAddress) as QuestionAnswer<Address>
    const coverLevelOrType = questions.find(q => q.name === QuestionEnum.coverLevelOrType) as QuestionAnswer<string>

    const petsKeptAtProposerAddress = questions.find(q => q.name === QuestionEnum.petsKeptAtProposerAddress) as QuestionAnswer<string>
    const ukResident = questions.find(q => q.name === QuestionEnum.ukResident) as QuestionAnswer<string>
    const usedForBreedingOrConnectedToBusiness = questions.find(q => q.name === QuestionEnum.usedForBreedingOrConnectedToBusiness) as QuestionAnswer<string>

    const coInsuranceQuestion = questions.find(q => q.name === QuestionEnum.coinsuranceVoluntaryDiscount) as QuestionAnswer<number>
    const effectiveDateQuestion = questions.find(q => q.name === QuestionEnum.effectiveDate || q.name === QuestionEnum.mtaEffectiveDate) as QuestionAnswer<Date>
    const petCoverType = questions.find(q => q.name === QuestionEnum.petCoverType) as QuestionAnswer<string>
    const petCoverSubType = questions.find(q => q.name === QuestionEnum.petSubCoverType) as QuestionAnswer<string>
    const quoteRequest = new Type()

    if (!(quoteRequest instanceof PetQuoteRequest || quoteRequest instanceof PetMTAQuoteRequest))
      throw new Error(`Unsupported quote request (${typeof quoteRequest}) for KeeyPartners.`)

    quoteRequest.proposer.title = title.value!
    quoteRequest.proposer.firstName = firstName.value!
    quoteRequest.proposer.lastName = lastName.value!
    quoteRequest.proposer.dateOfBirth = dateOfBirth.value!
    quoteRequest.proposer.address = address.value!
    quoteRequest.proposer.petsKeptAtProposerAddress = petsKeptAtProposerAddress.value === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.proposer.ukResident = ukResident.value === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.proposer.usedForBreedingOrConnectedToBusiness = usedForBreedingOrConnectedToBusiness.value === null ? null : usedForBreedingOrConnectedToBusiness.value === DLDCommonValuesEnum.Boolean.Yes

    quoteRequest.businessSourceId = DLDCommonValuesEnum.BusinessSourceEnum.AggregatorID
    quoteRequest.address = address.value!
    quoteRequest.coInsuranceRuleId = coInsuranceQuestion.value!
    quoteRequest.effectiveDate = effectiveDateQuestion.value!
    quoteRequest.coverLevelOrType = coverLevelOrType?.value ?? null

    // Pets
    const pets = questions.find(q => q.name === QuestionEnum.pets) as QuestionAnswer<number>

    for (let i = 0; i < pets.subPageAnswers.length; i += 1) {
      const subPage = pets.subPageAnswers[i]
      const petType = subPage.answers.find(q => q.name === QuestionEnum.petType) as QuestionAnswer<string>
      const name = subPage.answers.find(q => q.name === QuestionEnum.name) as QuestionAnswer<string>
      const petDateOfBirth = subPage.answers.find(q => q.name === QuestionEnum.dateOfBirth) as QuestionAnswer<Date>
      const petGender = subPage.answers.find(q => q.name === QuestionEnum.gender) as QuestionAnswer<string>
      const costOfPet = subPage.answers.find(q => q.name === QuestionEnum.costOfPet) as QuestionAnswer<number>
      const sizeOfPet = subPage.answers.find(q => q.name === QuestionEnum.sizeOfPet) as QuestionAnswer<string>
      const catBreed = subPage.answers.find(q => q.name === QuestionEnum.catBreed) as QuestionAnswer<string>
      const dogBreed = subPage.answers.find(q => q.name === QuestionEnum.dogBreed) as QuestionAnswer<string>
      const dogType = subPage.answers.find(q => q.name === QuestionEnum.dogType) as QuestionAnswer<string>
      const catType = subPage.answers.find(q => q.name === QuestionEnum.catType) as QuestionAnswer<string>
      if (petType.value === DLDCommonValuesEnum.PetType.Cat && catType.value === DLDCommonValuesEnum.CatType.Moggie)
        catBreed.value = DLDCommonValuesEnum.CatBreeds.Moggie
      else
        if (petType.value === DLDCommonValuesEnum.PetType.Dog && dogType.value === DLDCommonValuesEnum.DogType.Crossbreed)
          dogBreed.value = DLDCommonValuesEnum.DogBreeds.Crossbreed
        else
          if (petType.value === DLDCommonValuesEnum.PetType.Dog && dogType.value === DLDCommonValuesEnum.DogType.Mongrel)
            dogBreed.value = DLDCommonValuesEnum.DogBreeds.Mongrel

      const hasBeenNeutered = subPage.answers.find(q => q.name === QuestionEnum.hasBeenNeutered) as QuestionAnswer<string>
      const hasBeenChipped = subPage.answers.find(q => q.name === QuestionEnum.hasBeenChipped) as QuestionAnswer<string>
      const hasUptoDateVaccinations = subPage.answers.find(q => q.name === QuestionEnum.hasUptoDateVaccinations) as QuestionAnswer<string>
      const proposerOwnsPet = subPage.answers.find(q => q.name === QuestionEnum.proposerOwnsPet) as QuestionAnswer<string>
      const hasAttacked = subPage.answers.find(q => q.name === QuestionEnum.hasAttacked) as QuestionAnswer<string>
      const everSeenVetForIllness = subPage.answers.find(q => q.name === QuestionEnum.everSeenVetForIllness) as QuestionAnswer<string>
      const isAwaitingReview = subPage.answers.find(q => q.name === QuestionEnum.isAwaitingReview) as QuestionAnswer<string>
      const coverPreExistingConditions = subPage.answers.find(q => q.name === QuestionEnum.coverPreExistingConditions) as QuestionAnswer<string>
      const veriskQuestion = subPage.answers.find(q => q.name === QuestionEnum.verisk) as QuestionAnswer<string>
      const dominantBreedSizeOfPet = subPage.answers.find(q => q.name === QuestionEnum.dominantBreedSizeOfPet) as QuestionAnswer<string>
      const dominantCatBreed = subPage.answers.find(q => q.name === QuestionEnum.dominantCatBreed) as QuestionAnswer<string>
      const dominantDogBreed = subPage.answers.find(q => q.name === QuestionEnum.dominantDogBreed) as QuestionAnswer<string>
      const prn = subPage.answers.find(q => q.name === QuestionEnum.PRN) as QuestionAnswer<number>
      const isDeceased = subPage.answers.find(q => q.name === QuestionEnum.isPetDeceased) as QuestionAnswer<string>
      const removedDate = subPage.answers.find(q => q.name === QuestionEnum.petDateRemoved) as QuestionAnswer<Date>

      quoteRequest.pets.push({
        petType: petType.value!,
        name: name.value!,
        gender: petGender.value!,
        dateOfBirth: petDateOfBirth.value!,
        petSubType: petType.value! === DLDCommonValuesEnum.PetType.Cat ? catType.value! : dogType.value!,
        breed: petType.value! === DLDCommonValuesEnum.PetType.Cat ? catBreed.value! : dogBreed.value!,
        size: sizeOfPet.value!,
        costOfPet: costOfPet.value!,
        hasBeenNeutered: hasBeenNeutered.value! === DLDCommonValuesEnum.Boolean.Yes,
        hasBeenChipped: hasBeenChipped.value! === DLDCommonValuesEnum.Boolean.Yes,
        hasUpToDateVaccinations: hasUptoDateVaccinations.value! === DLDCommonValuesEnum.Boolean.Yes,
        proposerOwnsPet: proposerOwnsPet.value! === DLDCommonValuesEnum.Boolean.Yes,
        hasAttacked: petType.value! === DLDCommonValuesEnum.PetType.Dog && hasAttacked.value! === DLDCommonValuesEnum.Boolean.Yes,
        everSeenVetForIllness: everSeenVetForIllness.value! === DLDCommonValuesEnum.Boolean.Yes,
        isAwaitingReview: everSeenVetForIllness.value! === DLDCommonValuesEnum.Boolean.Yes && isAwaitingReview.value! === DLDCommonValuesEnum.Boolean.Yes,
        coverPreExistingConditions: everSeenVetForIllness.value! === DLDCommonValuesEnum.Boolean.Yes && isAwaitingReview.value! === DLDCommonValuesEnum.Boolean.No && coverPreExistingConditions.value! === DLDCommonValuesEnum.Boolean.Yes,
        veriskResponse: (everSeenVetForIllness.value! === DLDCommonValuesEnum.Boolean.No || isAwaitingReview.value! === DLDCommonValuesEnum.Boolean.Yes || coverPreExistingConditions.value! === DLDCommonValuesEnum.Boolean.No) ? null : veriskQuestion.value!,
        dominantBreed: petType.value! === DLDCommonValuesEnum.PetType.Cat ? dominantCatBreed.value! : dominantDogBreed.value!,
        dominantBreedSize: dominantBreedSizeOfPet.value!,
        hasCausedLegalIncident: false,
        trackerUniqueId: null,
        prn: prn?.value,
        isDeceased: isDeceased.value! === DLDCommonValuesEnum.Boolean.Yes,
        dateRemoved: removedDate?.value,
      })
    }

    quoteRequest.proposer.petCoverType = quoteRequest.pets.find(p => p.veriskResponse) ? null : petCoverType.value!
    quoteRequest.proposer.petCoverSubType = quoteRequest.pets.find(p => p.veriskResponse) || petCoverType.value! === DLDCommonValuesEnum.PetCoverType.AccidentOnly ? null : petCoverSubType.value!

    if (quoteRequest.pets.find(p => p.veriskResponse) && quoteRequest.coverLevelOrType != null
      && quoteRequest.coverLevelOrType !== 'PetCoverLevelHDIMedicallyUnderwritten.Champ25'
      && quoteRequest.coverLevelOrType !== 'PetCoverLevelHDIMedicallyUnderwritten.Champ40'
      && quoteRequest.coverLevelOrType !== 'PetCoverLevelHDIMedicallyUnderwritten.Champ80')
      quoteRequest.coverLevelOrType = null

    return quoteRequest
  }

  getMTARequest<T extends QuoteRequest>(_questions: IQuestionAnswer[], _Type: new () => T): T {
    const mtaRequest = this.getQuoteRequest(_questions, _Type)

    if (!(mtaRequest instanceof PetMTAQuoteRequest))
      throw new Error(`Unsupported quote request (${typeof mtaRequest}) for KeeyPartners`)

    const reasonForChange = _questions.find(q => q.name === QuestionEnum.reasonForChange) as QuestionAnswer<string>
    mtaRequest.reasonForChange = reasonForChange.value!
    return mtaRequest
  }

  getAccountRegisterRequest(questions: IQuestionAnswer[], affiliateId: number | null): AccountRegister | null {
    const title = questions.find(q => q.name === QuestionEnum.title) as QuestionAnswer<string>
    const firstName = questions.find(q => q.name === QuestionEnum.firstname) as QuestionAnswer<string>
    const lastName = questions.find(q => q.name === QuestionEnum.lastName) as QuestionAnswer<string>
    const email = questions.find(q => q.name === QuestionEnum.customerEmail) as QuestionAnswer<string>
    const dob = questions.find(q => q.name === QuestionEnum.dateOfBirth) as QuestionAnswer<Date>
    const phoneNumber = questions.find(q => q.name === QuestionEnum.mobilePhoneNumber || q.name === QuestionEnum.customerHomePhoneNumber || q.name === QuestionEnum.customerHomePhoneNumber) as QuestionAnswer<string>
    const marketing = questions.find(q => q.name === QuestionEnum.marketing) as QuestionAnswer<string[]>
    const address = questions.find(q => q.name === QuestionEnum.address || q.name === QuestionEnum.proposerAddress) as QuestionAnswer<Address>

    if (!title?.value || !firstName?.value || !lastName?.value || !email?.value || !address?.value)
      return null

    const request = new AccountRegister()
    request.title = title.value!
    request.firstName = firstName.value!
    request.lastName = lastName.value!
    request.email = email.value!
    request.dateOfBirth = dob.value!
    request.address = address.value!
    request.homePhone = phoneNumber.value!
    request.mobilePhone = phoneNumber.value!

    request.marketing = new Marketing()
    if (marketing) {
      request.marketing.isEmailOptIn = marketing?.value?.indexOf(DLDCommonValuesEnum.MarketingOption.Email) !== -1
      request.marketing.isTelephoneOptIn = marketing?.value?.indexOf(DLDCommonValuesEnum.MarketingOption.Telephone) !== -1
      request.marketing.isSMSOptIn = marketing?.value?.indexOf(DLDCommonValuesEnum.MarketingOption.SMS) !== -1
      request.marketing.isPostOptIn = marketing?.value?.indexOf(DLDCommonValuesEnum.MarketingOption.Post) !== -1
    }

    request.affiliateId = affiliateId

    return request
  }

  updateAccountFromQuestions(account: Account, questions: IQuestionAnswer[]): Account {
    const title = questions.find(q => q.name === QuestionEnum.title) as QuestionAnswer<string>
    const firstName = questions.find(q => q.name === QuestionEnum.firstname) as QuestionAnswer<string>
    const lastName = questions.find(q => q.name === QuestionEnum.lastName) as QuestionAnswer<string>
    const email = questions.find(q => q.name === QuestionEnum.customerEmail || q.name === QuestionEnum.email) as QuestionAnswer<string>
    const phoneNumber = questions.find(q => q.name === QuestionEnum.mobilePhoneNumber || q.name === QuestionEnum.customerHomePhoneNumber || q.name === QuestionEnum.customerHomePhoneNumber) as QuestionAnswer<string>
    const marketing = questions.find(q => q.name === QuestionEnum.marketing) as QuestionAnswer<string[]>
    const address = questions.find(q => q.name === QuestionEnum.address) as QuestionAnswer<Address>

    account.title = title?.value ?? account.title
    account.firstName = firstName?.value ?? account.firstName
    account.lastName = lastName?.value ?? account.lastName
    account.email = email?.value ?? account.email
    account.homePhone = phoneNumber?.value ?? account.homePhone
    account.mobilePhone = phoneNumber?.value ?? account.mobilePhone
    account.telephone = phoneNumber?.value ?? account.telephone
    account.workPhone = phoneNumber?.value ?? account.workPhone
    if (marketing?.value) {
      account.marketing.isEmailOptIn = marketing.value?.indexOf(DLDCommonValuesEnum.MarketingOption.Email) !== -1
      account.marketing.isSMSOptIn = marketing.value?.indexOf(DLDCommonValuesEnum.MarketingOption.SMS) !== -1
      account.marketing.isPostOptIn = marketing.value?.indexOf(DLDCommonValuesEnum.MarketingOption.Post) !== -1
      account.marketing.isTelephoneOptIn = marketing.value?.indexOf(DLDCommonValuesEnum.MarketingOption.Telephone) !== -1
    }
    account.address = address?.value ?? account.address

    return account
  }

  mapRiskToAnswers(risk: PetRisk, account: Account, pages: Page[]): Dictionary<PageAnswers> {
    const pageAnswers: Dictionary<PageAnswers> = {}

    this.mapAccountToAnswers(pageAnswers, account, pages)

    this.createAnswerForQuestion(pageAnswers, QuestionEnum.title, risk.proposer.title.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.firstname, risk.proposer.firstName, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.lastName, risk.proposer.lastName, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.dateOfBirth, risk.proposer.dateOfBirth, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.address, risk.proposer.address, pages)

    this.createAnswerForQuestion(pageAnswers, QuestionEnum.petsKeptAtProposerAddress, risk.proposer.petsKeptAtProposerAddress ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.ukResident, risk.proposer.ukResident ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.usedForBreedingOrConnectedToBusiness, this.extractNullableValue(risk.proposer.usedForBreedingOrConnectedToBusiness, null, risk.proposer.usedForBreedingOrConnectedToBusiness ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No), pages)

    this.createAnswerForQuestion(pageAnswers, QuestionEnum.coinsuranceVoluntaryDiscount, risk.coInsuranceRule?.id, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.effectiveDate, risk.effectiveDate, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.proposerAddress, risk.address, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.coverLevelOrType, risk.coverLevelOrType?.uniqueId, pages)

    if (risk.proposer.petCoverType?.uniqueId) {
      this.createAnswerForQuestion(pageAnswers, QuestionEnum.petCoverType, risk.proposer.petCoverType?.uniqueId, pages)
      this.createAnswerForQuestion(pageAnswers, QuestionEnum.petSubCoverType, risk.proposer.petCoverSubType?.uniqueId, pages)
    }
    else {
      switch (risk.coverLevelOrType?.uniqueId) {
        case (DLDCommonValuesEnum.PetCoverLevel.Bronze):
          this.createAnswerForQuestion(pageAnswers, QuestionEnum.petCoverType, DLDCommonValuesEnum.PetCoverType.AccidentOnly, pages)
          break
        case (DLDCommonValuesEnum.PetCoverLevel.Silver):
          this.createAnswerForQuestion(pageAnswers, QuestionEnum.petCoverType, DLDCommonValuesEnum.PetCoverType.AccidentAndIllness, pages)
          this.createAnswerForQuestion(pageAnswers, QuestionEnum.petSubCoverType, DLDCommonValuesEnum.PetSubCoverType.TimeLimited, pages)
          break
        case (DLDCommonValuesEnum.PetCoverLevel.Gold):
          this.createAnswerForQuestion(pageAnswers, QuestionEnum.petCoverType, DLDCommonValuesEnum.PetCoverType.AccidentAndIllness, pages)
          this.createAnswerForQuestion(pageAnswers, QuestionEnum.petSubCoverType, DLDCommonValuesEnum.PetSubCoverType.MaximumBenefit, pages)
          break
        case (DLDCommonValuesEnum.PetCoverLevel.Premier):
        case (DLDCommonValuesEnum.PetCoverLevel.PremierPlus):
        case (DLDCommonValuesEnum.PetCoverLevel.Prime):
          this.createAnswerForQuestion(pageAnswers, QuestionEnum.petCoverType, DLDCommonValuesEnum.PetCoverType.AccidentAndIllness, pages)
          this.createAnswerForQuestion(pageAnswers, QuestionEnum.petSubCoverType, DLDCommonValuesEnum.PetSubCoverType.Lifetime, pages)
          break
        default:
          this.createAnswerForQuestion(pageAnswers, QuestionEnum.petCoverType, risk.proposer.petCoverType?.uniqueId, pages)
          this.createAnswerForQuestion(pageAnswers, QuestionEnum.petSubCoverType, risk.proposer.petCoverSubType?.uniqueId, pages)
          break
      }
    }

    const pets = this.createAnswerForQuestion(pageAnswers, QuestionEnum.pets, risk.pets.length, pages)!
    pets.forEach((pap) => {
      for (let i = 0; i < risk.pets.length; i += 1) {
        const pet = risk.pets[i]
        const petType = new QuestionAnswer<string>(0, QuestionEnum.petType, '', pet.petType.uniqueId)
        const name = new QuestionAnswer<string>(0, QuestionEnum.name, '', pet.name)
        const petDateOfBirth = new QuestionAnswer<Date>(0, QuestionEnum.dateOfBirth, '', pet.dateOfBirth)
        const petGender = new QuestionAnswer<string>(0, QuestionEnum.gender, '', pet.gender.uniqueId)
        const costOfPet = new QuestionAnswer<number>(0, QuestionEnum.costOfPet, '', pet.costOfPet)
        const sizeOfPet = new QuestionAnswer<string>(0, QuestionEnum.sizeOfPet, '', pet.size?.uniqueId)
        const subType = new QuestionAnswer<string>(0, pet.petType.uniqueId === DLDCommonValuesEnum.PetType.Dog ? QuestionEnum.dogType : QuestionEnum.catType, '', pet.petSubType.uniqueId)
        const breed = new QuestionAnswer<string>(0, pet.petType.uniqueId === DLDCommonValuesEnum.PetType.Dog ? QuestionEnum.dogBreed : QuestionEnum.catBreed, '', pet.breed.uniqueId)
        const hasBeenNeutered = new QuestionAnswer<string>(0, QuestionEnum.hasBeenNeutered, '', pet.hasBeenNeutered ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const hasBeenChipped = new QuestionAnswer<string>(0, QuestionEnum.hasBeenChipped, '', pet.hasBeenChipped ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const hasUptoDateVaccinations = new QuestionAnswer<string>(0, QuestionEnum.hasUptoDateVaccinations, '', pet.hasUpToDateVaccinations ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const proposerOwnsPet = new QuestionAnswer<string>(0, QuestionEnum.proposerOwnsPet, '', pet.proposerOwnsPet ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const hasAttacked = new QuestionAnswer<string>(0, QuestionEnum.hasAttacked, '', pet.hasAttacked ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const everSeenVetForIllness = new QuestionAnswer<string>(0, QuestionEnum.everSeenVetForIllness, '', pet.everSeenVetForIllness ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const isAwaitingReview = new QuestionAnswer<string>(0, QuestionEnum.isAwaitingReview, '', pet.isAwaitingReview ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const coverPreExistingConditions = new QuestionAnswer<string>(0, QuestionEnum.coverPreExistingConditions, '', pet.coverPreExistingConditions ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const verisk = new QuestionAnswer<string>(0, QuestionEnum.verisk, '', pet.veriskDetail?.veriskData)
        const dominantBreedSize = new QuestionAnswer<string>(0, QuestionEnum.dominantBreedSizeOfPet, '', pet.dominantBreedSize?.uniqueId)
        const dominantBreed = new QuestionAnswer<string>(0, pet.petType.uniqueId === DLDCommonValuesEnum.PetType.Dog ? QuestionEnum.dominantDogBreed : QuestionEnum.dominantCatBreed, '', pet.dominantBreed?.uniqueId)
        const prn = new QuestionAnswer<number>(0, QuestionEnum.PRN, '', pet.prn, false)
        const isDeceased = new QuestionAnswer<string>(0, QuestionEnum.isPetDeceased, '', pet.isDeceased === true ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, false)
        const removedDate = new QuestionAnswer<Date>(0, QuestionEnum.petDateRemoved, '', pet.dateRemoved, false)
        const petPage = new PageAnswers(`Pet ${i + 1}`, [petType, name, petDateOfBirth, petGender, costOfPet, sizeOfPet, subType, breed, hasBeenNeutered, hasBeenChipped, hasUptoDateVaccinations, proposerOwnsPet, hasAttacked, everSeenVetForIllness, coverPreExistingConditions, isAwaitingReview, verisk, dominantBreedSize, dominantBreed, prn, isDeceased, removedDate])
        pap.subPageAnswers.push(petPage)
      }
    })

    return pageAnswers
  }
}
