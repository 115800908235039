import { JsonObject, JsonProperty } from 'json2typescript'
import DateConverter from '../../converters/DateConverter'
import ProductEnum from '../enums/ProductEnum'
import PolicyStatusEnum from '../enums/PolicyStatusEnum'
import type AvailableActionsEnum from '../enums/AvalableActionsEnum'
import RenewalStatusEnum from '../enums/RenewalStatusEnum'
import SummaryItem from './SummaryItem'
import AvalableActionsEnumArrayConverter from '@/converters/AvailableActionsEnumArrayConverter'
import PolicyStatusEnumConverter from '@/converters/PolicyStatusEnumConverter'
import ProductEnumConverter from '@/converters/ProductEnumConverter'

@JsonObject('PolicySummary')
export default class PolicySummary {
  @JsonProperty('policyNumber', String)
  policyNumber = ''

  @JsonProperty('policyStatus', PolicyStatusEnumConverter)
  policyStatus: PolicyStatusEnum = PolicyStatusEnum.live

  @JsonProperty('renewalStatus', PolicyStatusEnumConverter)
  renewalStatus: RenewalStatusEnum = RenewalStatusEnum.notSet

  @JsonProperty('product', ProductEnumConverter)
  product: ProductEnum = ProductEnum.modernCar

  @JsonProperty('effectiveDate', DateConverter)
  effectiveDate: Date = new Date()

  @JsonProperty('renewalDate', DateConverter)
  renewalDate: Date = new Date()

  @JsonProperty('summaryItems', [SummaryItem])
  summaryItems: SummaryItem[] = []

  @JsonProperty('availableActions', AvalableActionsEnumArrayConverter)
  availableActions: AvailableActionsEnum[] = []

  @JsonProperty('renewalQuoteReference', String)
  renewalQuoteReference: string | null = null

  @JsonProperty('vaps', [String])
  vaps: string[] = []

  @JsonProperty('schemeName', String)
  schemeName: string | null = null
}
