import moment from 'moment'
import type IRequestMapper from '../interfaces/IRequestMapper'
import type IResponseMapper from '../interfaces/IResponseMapper'
import type IQuestionMapper from '../interfaces/IQuestionMapper'
import type SchemeRequest from '../values/SchemeRequest'
import type QuoteRequest from '../values/QuoteRequest'
import type Page from '../../view-models/Page'
import type ProductPage from '../models/ProductPage'
import QuestionEnum from '../enums/QuestionEnum'
import DLDCommonValuesEnum from '../enums/DLDCommonValuesEnum'
import { CarProposer } from '../values/Driver'
import UnkownQuestionSetError from '../error/UnknownQuestionSetError'
import Vehicle from '../values/Vehicle'
import AccountRegister from '../values/AccountRegister'
import type { CarRisk } from '../models/Risk'
import type Account from '../models/Account'
import { Marketing } from '../models/Account'
import Car from '../values/Car'
import type IPageMappings from '../interfaces/IPageMappings'
import type { IQuestionAnswer } from '../values/PageAnswers'
import PageAnswers, { QuestionAnswer } from '../values/PageAnswers'
import type Address from '../models/Address'
import CarQuoteRequest, { CarMTAQuoteRequest } from '../values/CarQuoteRequest'
import type IQuestion from '../interfaces/IQuestion'
import type Conviction from '../values/Conviction'
import BaseMapper from './BaseMapper'
import type { ConvictionsQuestion, DateQuestion, DrivingLicenceQuestion, SelectQuestion, SubPagesQuestion } from '@/view-models/Question'
import ContextLevel from '@/services/enums/ContextLevel'
import type { Dictionary } from '@/types'
import Routes from '@/constants/Routes'

export default class YogaMapper extends BaseMapper implements IQuestionMapper, IRequestMapper, IResponseMapper, IPageMappings {
  nbPageMappings: Dictionary<{ route: Routes, contextLevel: ContextLevel }> = {
    ModernCar_Proposer_CustomerFacing: { route: Routes.scheme, contextLevel: ContextLevel.preQuote },
    ModernCar_Premium_CustomerFacing: { route: Routes.premium, contextLevel: ContextLevel.quote },
    ModernCar_VAPs_CustomerFacing: { route: Routes.additionalProducts, contextLevel: ContextLevel.quote },
    ModernCar_Payment: { route: Routes.payment, contextLevel: ContextLevel.quote },
    ModernCar_PostTakeUpVAPs_CustomerFacing: { route: Routes.upgrade, contextLevel: ContextLevel.policy },
    ModernCar_VAPs_Payment_CustomerFacing: { route: Routes.upgradePayment, contextLevel: ContextLevel.policy },
    ModernCar_PaymentConfirmation: { route: Routes.paymentConfirm, contextLevel: ContextLevel.policy },
    CommercialVehicle_Proposer_CustomerFacing: { route: Routes.scheme, contextLevel: ContextLevel.preQuote },
    CommercialVehicle_Premium_CustomerFacing: { route: Routes.premium, contextLevel: ContextLevel.quote },
    CommercialVehicle_VAPs_CustomerFacing: { route: Routes.additionalProducts, contextLevel: ContextLevel.quote },
    CommercialVehicle_Payment: { route: Routes.payment, contextLevel: ContextLevel.quote },
    CommercialVehicle_PostTakeUpVAPs_CustomerFacing: { route: Routes.upgrade, contextLevel: ContextLevel.policy },
    CommercialVehicle_VAPs_Payment_CustomerFacing: { route: Routes.upgradePayment, contextLevel: ContextLevel.policy },
    CommercialVehicle_PaymentConfirmation: { route: Routes.paymentConfirm, contextLevel: ContextLevel.policy },
  }

  mtaPageMappings: Dictionary<{ route: Routes, contextLevel: ContextLevel }> = {
    ModernCar_ProposerMta_CustomerFacing: { route: Routes.mtaScheme, contextLevel: ContextLevel.mtaPreQuote },
    ModernCar_PremiumMta: { route: Routes.mtaPremium, contextLevel: ContextLevel.mtaQuote },
    ModernCar_Payment: { route: Routes.mtaPayment, contextLevel: ContextLevel.mtaQuote },
    ModernCar_PaymentConfirmationMta: { route: Routes.mtaConfirm, contextLevel: ContextLevel.mtaTakenUp },
    CommercialVehicle_ProposerMta_CustomerFacing: { route: Routes.mtaScheme, contextLevel: ContextLevel.mtaPreQuote },
    CommercialVehicle_PremiumMta: { route: Routes.mtaPremium, contextLevel: ContextLevel.mtaQuote },
    CommercialVehicle_Payment: { route: Routes.mtaPayment, contextLevel: ContextLevel.mtaQuote },
    CommercialVehicle_PaymentConfirmationMta: { route: Routes.mtaConfirm, contextLevel: ContextLevel.mtaTakenUp },
  }

  renewalPageMappings: Dictionary<{ route: Routes, contextLevel: ContextLevel }> = {
    ModernCar_ProposerRenewal_CustomerFacing: { route: Routes.renewalScheme, contextLevel: ContextLevel.renewalPreQuote },
    ModernCar_PremiumRenewal: { route: Routes.renewalPremium, contextLevel: ContextLevel.renewalQuote },
    ModernCar_PaymentRenewal: { route: Routes.renewalPayment, contextLevel: ContextLevel.renewalQuote },
    ModernCar_PostTakeUpVAPs_CustomerFacing: { route: Routes.renewalUpgrade, contextLevel: ContextLevel.renewalTakenUp },
    ModernCar_VAPs_Payment_CustomerFacing: { route: Routes.renewalUpgradePayment, contextLevel: ContextLevel.renewalTakenUp },
    ModernCar_PaymentConfirmation: { route: Routes.renewalPaymentConfirmation, contextLevel: ContextLevel.renewalTakenUp },
    CommercialVehicle_ProposerRenewal_CustomerFacing: { route: Routes.renewalScheme, contextLevel: ContextLevel.renewalPreQuote },
    CommercialVehicle_PremiumRenewal: { route: Routes.renewalPremium, contextLevel: ContextLevel.renewalQuote },
    CommercialVehicle_PaymentRenewal: { route: Routes.renewalPayment, contextLevel: ContextLevel.renewalQuote },
    CommercialVehicle_PostTakeUpVAPs_CustomerFacing: { route: Routes.upgrade, contextLevel: ContextLevel.renewalTakenUp },
    CommercialVehicle_VAPs_Payment_CustomerFacing: { route: Routes.upgradePayment, contextLevel: ContextLevel.renewalTakenUp },
    CommercialVehicle_PaymentConfirmation: { route: Routes.renewalPaymentConfirmation, contextLevel: ContextLevel.renewalTakenUp },
  }

  setupPage(setId: string, productPage: ProductPage, answers: IQuestionAnswer[], affiliateId?: number, parent?: SubPagesQuestion): Page {
    const mappedPage = this.mapProductPageToPage(productPage)
    return this.setupDependencies(setId, mappedPage, parent)
  }

  setupDependencies(setId: string, mappedPage: Page, parent?: SubPagesQuestion): Page {
    switch (setId) {
      case 'ModernCar_Proposer_CustomerFacing':
      case 'ModernCar_ProposerMta_CustomerFacing':
      case 'ModernCar_ProposerRenewal_CustomerFacing':
        return this.setupProposerDependencies(mappedPage)
      case 'CommercialVehicle_Proposer_CustomerFacing':
      case 'CommercialVehicle_ProposerMta_CustomerFacing':
      case 'CommercialVehicle_ProposerRenewal_CustomerFacing':
        return this.setupProposerVanDependencies(mappedPage)
      case 'ModernCar_Proposer_CustomerFacing_AdditionalDriver':
      case 'ModernCar_ProposerMta_CustomerFacing_AdditionalDriver':
      case 'ModernCar_ProposerRenewal_CustomerFacing_AdditionalDriver':
      case 'CommercialVehicle_Proposer_CustomerFacing_AdditionalDriver':
      case 'CommercialVehicle_ProposerMta_CustomerFacing_AdditionalDriver':
      case 'CommercialVehicle_ProposerRenewal_CustomerFacing_AdditionalDriver':
        return this.setupAdditionalDriverDependencies(mappedPage)
      case 'ModernCar_AggregatorLanding':
      case 'ModernCar_AggregatorLanding_AdditionalDriver':
      case 'CommercialVehicle_AggregatorLanding':
      case 'CommercialVehicle_AggregatorLanding_AdditionalDriver':
        return this.setupAggregatorLandingDependencies(mappedPage)
      case 'ModernCar_Proposer_CustomerFacing_Convictions':
        return this.setupConvictionsDependencies(mappedPage, parent!)
      case 'ModernCar_VAPs_CustomerFacing':
      case 'ModernCar_PostTakeUpVAPs_CustomerFacing':
      case 'ModernCar_VAPs_Payment_CustomerFacing':
      case 'ModernCar_Premium_CustomerFacing':
      case 'ModernCar_PremiumMta':
      case 'ModernCar_Payment':
      case 'ModernCar_PaymentConfirmation':
      case 'ModernCar_PaymentConfirmationMta':
      case 'ModernCar_PremiumRenewal':
      case 'ModernCar_PaymentRenewal':
      case 'CommercialVehicle_VAPs_CustomerFacing':
      case 'CommercialVehicle_PostTakeUpVAPs_CustomerFacing':
      case 'CommercialVehicle_VAPs_Payment_CustomerFacing':
      case 'CommercialVehicle_Premium_CustomerFacing':
      case 'CommercialVehicle_PremiumMta':
      case 'CommercialVehicle_Payment':
      case 'CommercialVehicle_PaymentConfirmation':
      case 'CommercialVehicle_PaymentConfirmationMta':
      case 'CommercialVehicle_PremiumRenewal':
      case 'CommercialVehicle_PaymentRenewal':
      case 'Account':
        return mappedPage
      default:
        throw new UnkownQuestionSetError(setId)
    }
  }

  private setupAggregatorLandingDependencies(page: Page): Page {
    const questions = page.sections.flatMap(s => s.questions)

    const titleQuestion = questions.find(q => q.name === QuestionEnum.title)
    const firstNameQuestion = questions.find(q => q.name === QuestionEnum.firstname)
    const lastNameQuestion = questions.find(q => q.name === QuestionEnum.lastName)
    const dateOfBirthQuestion = questions.find(q => q.name === QuestionEnum.dateOfBirth)
    const drivingLicenceQuestion = questions.find(q => q.name === QuestionEnum.drivingLicence) as DrivingLicenceQuestion
    drivingLicenceQuestion.dependentQuestions = [titleQuestion!, firstNameQuestion!, lastNameQuestion!, dateOfBirthQuestion!]

    return page
  }

  private setupAdditionalDriverDependencies(page: Page): Page {
    this.setupAddDriverDependencies(page)

    const questions = page.sections.flatMap(s => s.questions)
    const titleQuestion = questions.find(q => q.name === QuestionEnum.title)
    const firstNameQuestion = questions.find(q => q.name === QuestionEnum.firstname)
    const lastNameQuestion = questions.find(q => q.name === QuestionEnum.lastName)
    const dateOfBirthQuestion = questions.find(q => q.name === QuestionEnum.dateOfBirth)
    const relationshipToPolicyHolder = questions.find(q => q.name === QuestionEnum.relationshipToPolicyHolder)
    const drivingLicenceQuestion = questions.find(q => q.name === QuestionEnum.drivingLicence) as DrivingLicenceQuestion
    drivingLicenceQuestion.dependentQuestions = [titleQuestion!, firstNameQuestion!, lastNameQuestion!, dateOfBirthQuestion!]
    dateOfBirthQuestion!.dependentQuestions = [firstNameQuestion!, lastNameQuestion!]
    relationshipToPolicyHolder!.dependentQuestions = [firstNameQuestion!, lastNameQuestion!]

    const employmentStatusQuestion = questions.find(q => q.name === QuestionEnum.employmentStatus) as SelectQuestion<string, string>
    const occupationQuestion = questions.find(q => q.name === QuestionEnum.occupation) as SelectQuestion<string, string>
    const businessCategoryQuestion = questions.find(q => q.name === QuestionEnum.businessCategory) as SelectQuestion<string, string>
    employmentStatusQuestion!.dependentQuestions = [firstNameQuestion!, lastNameQuestion!]
    occupationQuestion!.dependentQuestions = [firstNameQuestion!, lastNameQuestion!]
    businessCategoryQuestion!.dependentQuestions = [firstNameQuestion!, lastNameQuestion!]
    const residentSinceBirthQuestion = questions.find(q => q.name === QuestionEnum.residentSinceBirth) as SelectQuestion<string, string>
    const residentSinceQuestion = questions.find(q => q.name === QuestionEnum.residentSince) as DateQuestion
    if (residentSinceQuestion)
      residentSinceQuestion.isVisible = () => residentSinceBirthQuestion.value! === DLDCommonValuesEnum.Boolean.No

    occupationQuestion.isVisible = businessCategoryQuestion.isVisible = () => employmentStatusQuestion.value != null && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.Retired && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.Unemployed

    const maritalStatus = questions.find(q => q.name === QuestionEnum.maritalStatus) as SelectQuestion<string, string>
    const driveOtherVehicles = questions.find(q => q.name === QuestionEnum.doYouDriveAnyVehicleBesidesThisOne) as SelectQuestion<string, string>
    const otherVehiclesYouDrive = questions.find(q => q.name === QuestionEnum.otherVehiclesYouDrive) as SelectQuestion<string, string>

    if (maritalStatus)
      maritalStatus!.dependentQuestions = [firstNameQuestion!, lastNameQuestion!]

    if (driveOtherVehicles)
      driveOtherVehicles!.dependentQuestions = [firstNameQuestion!, lastNameQuestion!]

    if (otherVehiclesYouDrive) {
      otherVehiclesYouDrive!.dependentQuestions = [firstNameQuestion!, lastNameQuestion!]
      otherVehiclesYouDrive.isVisible = () => driveOtherVehicles.value !== null && driveOtherVehicles.value === DLDCommonValuesEnum.Boolean.Yes
    }

    return page
  }

  private setupConvictionsDependencies(page: Page, parent: SubPagesQuestion): Page {
    const questions = page.sections.flatMap(s => s.questions)
    const convictionDate = questions.find(q => q.name === QuestionEnum.convictionDate)! as DateQuestion
    const isPendingQuestion = questions.find(q => q.name === QuestionEnum.convictionIsPending)!

    convictionDate.minDate = () => moment().add(-5, 'years').toDate()
    convictionDate.maxDate = () => new Date()

    isPendingQuestion.isVisible = () => {
      const licenceType = parent.dependentQuestions.find(q => q.name === QuestionEnum.licenceType) as SelectQuestion<string, string>

      return licenceType
        && (licenceType.value === DLDCommonValuesEnum.CarLicenceType.FullEULicence
        || licenceType.value === DLDCommonValuesEnum.CarLicenceType.FullInternationalLicence
        || licenceType.value === DLDCommonValuesEnum.CarLicenceType.Other)
    }

    return page
  }

  protected setupAddDriverDependencies(page: Page): void {
    this.setupCommonDependencies(page)
    const questions = page.sections.flatMap(s => s.questions)
    const residentSinceQuestion = questions.find(q => q.name === QuestionEnum.residentSince) as DateQuestion
    const residentSinceBirthQuestion = questions.find(q => q.name === QuestionEnum.residentSinceBirth) as SelectQuestion<string, string>
    if (residentSinceQuestion) {
      residentSinceQuestion.minDate = () => this.setupDOB(questions).value
      residentSinceQuestion.maxDate = () => moment().toDate()
      residentSinceQuestion.isVisible = () => residentSinceBirthQuestion.value! === DLDCommonValuesEnum.Boolean.No
    }
  }

  private setupProposerVanDependencies(page: Page): Page {
    const questions = page.sections.flatMap(s => s.questions)
    const residentSinceQuestion = questions.find(q => q.name === QuestionEnum.residentSince) as DateQuestion
    const residentSinceBirthQuestion = questions.find(q => q.name === QuestionEnum.residentSinceBirth) as SelectQuestion<string, string>

    residentSinceQuestion.minDate = () => this.setupDOB(questions).value
    residentSinceQuestion.maxDate = () => moment().toDate()
    residentSinceQuestion.isVisible = () => residentSinceBirthQuestion.value! === DLDCommonValuesEnum.Boolean.No
    return this.setupProposerDependencies(page)
  }

  private setupProposerDependencies(page: Page): Page {
    this.setupCommonDependencies(page)

    const questions = page.sections.flatMap(s => s.questions)

    const trackerType = questions.find(q => q.name === QuestionEnum.trackerType) as SelectQuestion<string, string>
    const carTrackerModel = questions.find(q => q.name === QuestionEnum.trackerModel) as SelectQuestion<string, string>

    if (carTrackerModel)
      carTrackerModel.isVisible = () => trackerType.value !== null && trackerType.value !== 'CarTrackerType.None'

    const alarmType = questions.find(q => q.name === QuestionEnum.modernCarAlarmType) as SelectQuestion<string, string>
    const alarmModel = questions.find(q => q.name === QuestionEnum.alarmModel) as SelectQuestion<string, string>
    const alarmFittedBy = questions.find(q => q.name === QuestionEnum.alarmFittedBy) as SelectQuestion<string, string>

    if (alarmModel && alarmFittedBy)
      alarmModel.isVisible = alarmFittedBy.isVisible = () => alarmType.value !== null && alarmType.value !== 'ModernCarAlarmType.None'

    const effectiveDateQuestion = questions.find(q => q.name === QuestionEnum.effectiveDate) as DateQuestion ?? questions.find(q => q.name === QuestionEnum.mtaEffectiveDate) as DateQuestion

    const employmentStatusQuestion = questions.find(q => q.name === QuestionEnum.employmentStatus) as SelectQuestion<string, string>
    const occupationQuestion = questions.find(q => q.name === QuestionEnum.occupation) as SelectQuestion<string, string>
    occupationQuestion.options = occupationQuestion.options.filter(o => ![
      DLDCommonValuesEnum.Occupation.Unemployed,
      DLDCommonValuesEnum.Occupation.NotInEmployment,
      DLDCommonValuesEnum.Occupation.NotEmployedDueToDisability,
    ].find(x => x === o.value))
    const businessCategoryQuestion = questions.find(q => q.name === QuestionEnum.businessCategory) as SelectQuestion<string, string>
    businessCategoryQuestion.options = businessCategoryQuestion.options.filter(b => ![
      DLDCommonValuesEnum.BusinessCategory.NotInEmployment,
      DLDCommonValuesEnum.BusinessCategory.NonUnemployed,
      DLDCommonValuesEnum.BusinessCategory.NoneNotEmployedduetoDisability,
    ].find(x => x === b.value))

    occupationQuestion.isVisible = businessCategoryQuestion.isVisible = () => employmentStatusQuestion.value != null && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.Retired && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.Unemployed

    const hasSecondaryEmployment = questions.find(q => q.name === QuestionEnum.hasSecondaryEmployment) as SelectQuestion<string, string>
    const secondaryOccupation = questions.find(q => q.name === QuestionEnum.secondaryOccupation) as SelectQuestion<string, string>
    const secondaryBusinessCategory = questions.find(q => q.name === QuestionEnum.secondaryBusinessCategory) as SelectQuestion<string, string>

    if (secondaryOccupation && secondaryBusinessCategory)
      secondaryOccupation.isVisible = secondaryBusinessCategory.isVisible = () => hasSecondaryEmployment.value != null && hasSecondaryEmployment.value === DLDCommonValuesEnum.Boolean.Yes

    const doYouDriveAnyOtherVehicles = questions.find(q => q.name === QuestionEnum.doYouDriveAnyVehicleBesidesThisOne) as SelectQuestion<string, string>
    const selectOtherVehiclesDriven = questions.find(q => q.name === QuestionEnum.otherVehiclesYouDrive) as SelectQuestion<string, string>

    if (selectOtherVehiclesDriven)
      selectOtherVehiclesDriven.isVisible = () => doYouDriveAnyOtherVehicles.value !== null && doYouDriveAnyOtherVehicles.value === DLDCommonValuesEnum.Boolean.Yes

    const residentSinceBirth = questions.find(q => q.name === QuestionEnum.residentSinceBirth) as SelectQuestion<string, string>
    const residentSince = questions.find(q => q.name === QuestionEnum.residentSince) as DateQuestion

    residentSince.isVisible = () => residentSinceBirth.value !== null && residentSinceBirth.value === DLDCommonValuesEnum.Boolean.No

    effectiveDateQuestion.maxDate = () => new Date()

    effectiveDateQuestion.minDate = () => moment().toDate()
    effectiveDateQuestion.maxDate = () => moment().add(31, 'days').toDate()

    const titleQuestion = questions.find(q => q.name === QuestionEnum.title)
    const firstNameQuestion = questions.find(q => q.name === QuestionEnum.firstname)
    const lastNameQuestion = questions.find(q => q.name === QuestionEnum.lastName)
    const dateOfBirthQuestion = questions.find(q => q.name === QuestionEnum.dateOfBirth)

    const licenceType = questions.find(q => q.name === QuestionEnum.licenceType) as SelectQuestion<string, string>

    const drivingLicenceQuestion = questions.find(q => q.name === QuestionEnum.drivingLicence) as DrivingLicenceQuestion

    drivingLicenceQuestion.isVisible = () => this.isUkDrivingLicence(licenceType.value)

    drivingLicenceQuestion.dependentQuestions = [titleQuestion!, firstNameQuestion!, lastNameQuestion!, dateOfBirthQuestion!]

    const licenceDate = questions.find(q => q.name === QuestionEnum.licenceDate) as DateQuestion
    licenceDate.isVisible = () => !this.isUkDrivingLicence(licenceType.value)

    licenceDate.minDate = () => this.setupDOB(questions).value
    licenceDate.maxDate = () => moment().toDate()

    const convictionQuestion = questions.find(q => q.name === QuestionEnum.convictions) as ConvictionsQuestion

    convictionQuestion.dependentQuestions = [licenceType]

    return page
  }

  isUkDrivingLicence(licenceType: string | null): boolean {
    return licenceType === DLDCommonValuesEnum.CarLicenceType.FullUK
      || licenceType === DLDCommonValuesEnum.CarLicenceType.ProvisionalUK
      || licenceType === DLDCommonValuesEnum.CarLicenceType.MedicalRestrictedFull3YearsOrMoreCar
      || licenceType === DLDCommonValuesEnum.CarLicenceType.MedicalRestrictedFullLessThan3YearsCar
      || licenceType === DLDCommonValuesEnum.CarLicenceType.MedicalRestrictedProvisional3YearsOrMoreCar
      || licenceType === DLDCommonValuesEnum.CarLicenceType.MedicalRestrictedProvisionalLessThen3YearsCar
  }

  protected setupDOB(questions: IQuestion[]): DateQuestion {
    const dateOfBirth = questions.find(q => q.name === QuestionEnum.dateOfBirth) as DateQuestion
    dateOfBirth.maxDate = () => moment().add(-17, 'years').toDate()
    dateOfBirth.focusedDate = () => moment(dateOfBirth.maxDate()).subtract(1, 'years').toDate()
    return dateOfBirth
  }

  getSchemeRequest(_questions: IQuestionAnswer[]): SchemeRequest {
    throw new Error('Method not implemented. Yoga does not use Scheme Requests.')
  }

  validate(_questions: IQuestionAnswer[]): boolean {
    throw new Error('Method not implemented. Wrapper does not use custom validation.')
  }

  getQuoteRequest<T extends QuoteRequest>(questions: IQuestionAnswer[], Type: new() => T): T {
    // Vehicle details
    const vehicleLookup = questions.find(q => q.name === QuestionEnum.vehicleLookup) as QuestionAnswer<Vehicle>
    const vehicleSeats = questions.find(q => q.name === QuestionEnum.vehicleSeats) as QuestionAnswer<number>
    const vehicleEstimatedMileage = questions.find(q => q.name === QuestionEnum.vehicleEstimatedMileage) as QuestionAnswer<number>
    const businessMileage = questions.find(q => q.name === QuestionEnum.vehicleBusinessMileage) as QuestionAnswer<number>
    const vehicleOvernightParkLocation = questions.find(q => q.name === QuestionEnum.vehicleOvernightParkLocation) as QuestionAnswer<string>
    const vehicleOwner = questions.find(q => q.name === QuestionEnum.vehicleOwner) as QuestionAnswer<string>
    const vehicleKeeper = questions.find(q => q.name === QuestionEnum.vehicleKeeper) as QuestionAnswer<string>
    const commercialVehicleBodyType = questions.find(q => q.name === QuestionEnum.commercialVehicleBodyType) as QuestionAnswer<string>
    const vehicleFuelType = questions.find(q => q.name === QuestionEnum.fuelType) as QuestionAnswer<string>
    const vehicleTransmissionType = questions.find(q => q.name === QuestionEnum.vehicleTransmission) as QuestionAnswer<string>
    const trackerType = questions.find(q => q.name === QuestionEnum.trackerType) as QuestionAnswer<string>
    const trackerModel = questions.find(q => q.name === QuestionEnum.trackerModel) as QuestionAnswer<string>
    const alarmType = questions.find(q => q.name === QuestionEnum.modernCarAlarmType) as QuestionAnswer<string>
    const alarmModel = questions.find(q => q.name === QuestionEnum.alarmModel) as QuestionAnswer<string>
    const alarmFittedBy = questions.find(q => q.name === QuestionEnum.alarmFittedBy) as QuestionAnswer<string>
    const vehicleIsModified = questions.find(q => q.name === QuestionEnum.vehicleIsModified) as QuestionAnswer<string>
    const vehicleIsImpounded = questions.find(q => q.name === QuestionEnum.isVehicleImpounded) as QuestionAnswer<string>
    const vehicleIsImported = questions.find(q => q.name === QuestionEnum.vehicleImported) as QuestionAnswer<string>
    const vehicleIsRightHandDrive = questions.find(q => q.name === QuestionEnum.isVehicleRightHandDrive) as QuestionAnswer<string>

    // Personal details
    const title = questions.find(q => q.name === QuestionEnum.title) as QuestionAnswer<string>
    const firstName = questions.find(q => q.name === QuestionEnum.firstname) as QuestionAnswer<string>
    const lastName = questions.find(q => q.name === QuestionEnum.lastName) as QuestionAnswer<string>
    const dateOfBirth = questions.find(q => q.name === QuestionEnum.dateOfBirth) as QuestionAnswer<Date>
    const licenceType = questions.find(q => q.name === QuestionEnum.licenceType) as QuestionAnswer<string>
    const licenceDate = questions.find(q => q.name === QuestionEnum.licenceDate) as QuestionAnswer<Date>
    const drivingLicence = questions.find(q => q.name === QuestionEnum.drivingLicence) as QuestionAnswer<string>
    const address = questions.find(q => q.name === QuestionEnum.address) as QuestionAnswer<Address>
    const employmentStatus = questions.find(q => q.name === QuestionEnum.employmentStatus) as QuestionAnswer<string>
    const occupation = questions.find(q => q.name === QuestionEnum.occupation) as QuestionAnswer<string>
    const businessCategory = questions.find(q => q.name === QuestionEnum.businessCategory) as QuestionAnswer<string>
    const referralSource = questions.find(q => q.name === QuestionEnum.referralSource) as QuestionAnswer<number>
    const noClaimsProtection = questions.find(q => q.name === QuestionEnum.noClaimsProtection) as QuestionAnswer<string>
    const voluntaryExcess = questions.find(q => q.name === QuestionEnum.voluntaryExcess) as QuestionAnswer<string>
    const residentSince = questions.find(q => q.name === QuestionEnum.residentSince) as QuestionAnswer<Date>
    const residentSinceBirth = questions.find(q => q.name === QuestionEnum.residentSinceBirth) as QuestionAnswer<string>
    const hasSecondaryEmployment = questions.find(q => q.name === QuestionEnum.hasSecondaryEmployment) as QuestionAnswer<string>
    const secondaryOccupation = questions.find(q => q.name === QuestionEnum.secondaryOccupation) as QuestionAnswer<string>
    const secondaryBusinessCategory = questions.find(q => q.name === QuestionEnum.secondaryBusinessCategory) as QuestionAnswer<string>
    const maritalStatus = questions.find(q => q.name === QuestionEnum.maritalStatus) as QuestionAnswer<string>
    const homeOwner = questions.find(q => q.name === QuestionEnum.homeOwner) as QuestionAnswer<string>
    const numberOfOtherVehiclesInHousehold = questions.find(q => q.name === QuestionEnum.numberOfOtherVehiclesAvailable) as QuestionAnswer<string>
    const driveOtherVehicles = questions.find(q => q.name === QuestionEnum.doYouDriveAnyVehicleBesidesThisOne) as QuestionAnswer<string>
    const otherVehicles = questions.find(q => q.name === QuestionEnum.otherVehiclesYouDrive) as QuestionAnswer<string>
    const hasCriminalConvictions = questions.find(q => q.name === QuestionEnum.hasCriminalConvictions) as QuestionAnswer<string>
    const hasHadPreviousPolicyCancelled = questions.find(q => q.name === QuestionEnum.hasHadPreviousPolicyCancelled) as QuestionAnswer<string>
    const hasMedicalConditions = questions.find(q => q.name === QuestionEnum.hasMedicalConditions) as QuestionAnswer<string>

    if (employmentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.Retired) {
      if (occupation)
        occupation.value = DLDCommonValuesEnum.Occupation.Retired
      if (businessCategory)
        businessCategory.value = DLDCommonValuesEnum.BusinessCategory.NonRetired
    }
    else if (employmentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.Unemployed) {
      if (occupation)
        occupation.value = DLDCommonValuesEnum.Occupation.Unemployed
      if (businessCategory)
        businessCategory.value = DLDCommonValuesEnum.BusinessCategory.NonUnemployed
    }
    else if (employmentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.FinanciallyAssisted) {
      if (occupation)
        occupation.value = DLDCommonValuesEnum.Occupation.NotInEmployment
      if (businessCategory)
        businessCategory.value = DLDCommonValuesEnum.BusinessCategory.NotInEmployment
    }

    // Cover details
    const classOfUse = questions.find(q => q.name === QuestionEnum.classOfUse || q.name === QuestionEnum.commercialVehicleClassOfUse) as QuestionAnswer<string>
    const effectiveDateQuestion = questions.find(q => q.name === QuestionEnum.effectiveDate || q.name === QuestionEnum.mtaEffectiveDate) as QuestionAnswer<Date>

    const quoteRequest = new Type()

    if (!(quoteRequest instanceof CarQuoteRequest || quoteRequest instanceof CarMTAQuoteRequest))
      throw new Error(`Unsupported quote request (${typeof quoteRequest}) for Yoga.`)

    quoteRequest.car = new Car()
    quoteRequest.car.abiCode = vehicleLookup.value!.abiCode
    quoteRequest.car.registration = vehicleLookup.value!.registration
    quoteRequest.car.value = vehicleLookup.value!.value
    quoteRequest.car.noOfSeats = vehicleSeats.value
    quoteRequest.car.registeredKeeper = vehicleKeeper.value!
    quoteRequest.car.registeredOwner = vehicleOwner.value!
    quoteRequest.car.overnightParkLocation = vehicleOvernightParkLocation.value!
    quoteRequest.car.purchaseDate = vehicleLookup.value!.purchaseDate
    quoteRequest.car.yearOfManufacture = vehicleLookup.value!.yearOfManufacture
    quoteRequest.car.keptAtHomeAddress = true
    quoteRequest.car.make = vehicleLookup.value!.make
    quoteRequest.car.model = vehicleLookup.value!.model
    quoteRequest.car.purchaseDate = vehicleLookup.value!.purchaseDate
    quoteRequest.car.bodyType = commercialVehicleBodyType?.value || vehicleLookup.value!.bodyType || null
    quoteRequest.car.transmission = vehicleTransmissionType?.value || vehicleLookup.value?.transmissionType || null
    quoteRequest.car.cubicCapacity = vehicleLookup.value!.engineSize
    quoteRequest.car.fuelType = vehicleFuelType?.value || vehicleLookup.value?.fuelType || null
    quoteRequest.car.trackerType = trackerType?.value
    quoteRequest.car.trackerModel = !trackerType || trackerType?.value === 'CarTrackerType.None' ? null : trackerModel.value
    quoteRequest.car.alarmType = alarmType?.value
    quoteRequest.car.alarmModel = !alarmType || alarmType?.value === 'ModernCarAlarmType.None' ? null : alarmModel.value
    quoteRequest.car.alarmFittedBy = !alarmType || alarmType?.value === 'ModernCarAlarmType.None' ? null : alarmFittedBy.value
    quoteRequest.car.isModified = vehicleIsModified.value! === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.car.imported = vehicleIsImported.value! === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.car.leftHandDrive = vehicleIsRightHandDrive!.value === DLDCommonValuesEnum.Boolean.No

    quoteRequest.mileage = vehicleEstimatedMileage.value!
    quoteRequest.businessMileage = businessMileage?.value ?? 0

    quoteRequest.proposer = new CarProposer()
    quoteRequest.proposer.title = title.value!
    quoteRequest.proposer.firstName = firstName.value!
    quoteRequest.proposer.lastName = lastName.value!
    quoteRequest.proposer.dateOfBirth = dateOfBirth.value!
    quoteRequest.proposer.drivingLicence = drivingLicence!.value!
    quoteRequest.proposer.licenceType = licenceType.value!
    quoteRequest.proposer.licenceDate = licenceDate.value!
    quoteRequest.proposer.employmentStatus = employmentStatus.value!
    quoteRequest.proposer.occupation = occupation.value!
    quoteRequest.proposer.businessCategory = businessCategory.value!
    quoteRequest.proposer.address = address.value!
    quoteRequest.proposer.prn = null
    quoteRequest.proposer.hasSecondaryEmployment = hasSecondaryEmployment?.value === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.proposer.secondaryOccupation = quoteRequest.proposer.hasSecondaryEmployment ? secondaryOccupation.value : null
    quoteRequest.proposer.secondaryBusinessCategory = quoteRequest.proposer.hasSecondaryEmployment ? secondaryBusinessCategory.value : null
    quoteRequest.proposer.maritalStatus = maritalStatus?.value
    quoteRequest.proposer.homeOwner = homeOwner?.value === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.proposer.numberOfOtherVehiclesAvailable = numberOfOtherVehiclesInHousehold?.value
    quoteRequest.proposer.otherVehiclesYouDrive = driveOtherVehicles?.value === DLDCommonValuesEnum.Boolean.Yes ? otherVehicles.value : null
    quoteRequest.proposer.convictions = this.getConvictions(questions)
    quoteRequest.proposer.hasCriminalConvictions = hasCriminalConvictions.value! === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.proposer.hasHadPreviousPolicyCancelled = hasHadPreviousPolicyCancelled.value! === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.proposer.hasMedicalConditions = hasMedicalConditions.value! === DLDCommonValuesEnum.Boolean.Yes

    if (residentSinceBirth)
      quoteRequest.proposer.residentSince = residentSinceBirth.value === DLDCommonValuesEnum.Boolean.Yes ? dateOfBirth.value! : residentSince.value

    quoteRequest.address = address.value!
    quoteRequest.classOfUse = classOfUse.value!
    quoteRequest.effectiveDate = effectiveDateQuestion.value!
    quoteRequest.voluntaryExcess = voluntaryExcess && voluntaryExcess.value ? voluntaryExcess.value : DLDCommonValuesEnum.VolXs.VolXs0
    quoteRequest.coverType = DLDCommonValuesEnum.CoverType.ThirdParty
    quoteRequest.businessSourceId = referralSource?.value
    quoteRequest.noClaimsProtection = noClaimsProtection && noClaimsProtection.value!

    quoteRequest.isImpounded = vehicleIsImpounded.value

    quoteRequest.policyDuration = DLDCommonValuesEnum.PolicyDuration.Days30

    return quoteRequest
  }

  private getConvictions(questions: IQuestionAnswer[]): Conviction[] {
    const convictionsQuestion = questions.find(q => q.name === QuestionEnum.convictions) as QuestionAnswer<number>
    const convictions: Conviction[] = []

    if (convictionsQuestion) {
      for (let i = 0; i < convictionsQuestion.subPageAnswers.length; i += 1) {
        const convictionDate = convictionsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.convictionDate) as QuestionAnswer<Date>
        const convictionType = convictionsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.convictionType) as QuestionAnswer<string>
        const convictionIsPending = convictionsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.convictionIsPending) as QuestionAnswer<string>

        convictions.push({
          convictionDate: convictionDate.value!,
          convictionType: convictionType.value!,
          convictionIsPending: convictionIsPending.visible ? convictionIsPending.value! === DLDCommonValuesEnum.Boolean.Yes : true,
          convictionFine: null,
          convictionPersonsInjured: null,
          convictionDisqualificationMonths: null,
          convictionPointsReceived: null,
        })
      }
    }
    return convictions
  }

  getMTARequest<T extends QuoteRequest>(questions: IQuestionAnswer[], Type: new() => T): T {
    const mtaRequest = this.getQuoteRequest(questions, Type)

    if (!(mtaRequest instanceof CarMTAQuoteRequest))
      throw new Error(`Unsupported quote request (${typeof mtaRequest}) for Yoga.`)

    const reasonForChange = questions.find(q => q.name === QuestionEnum.reasonForChange) as QuestionAnswer<string>
    mtaRequest.reasonForChange = reasonForChange.value!
    return mtaRequest
  }

  mapRiskToAnswers(risk: CarRisk, account: Account, pages: Page[]): Dictionary<PageAnswers> {
    const pageAnswers: Dictionary<PageAnswers> = {}

    this.mapAccountToAnswers(pageAnswers, account, pages)

    const vehicle = new Vehicle()
    vehicle.registration = risk.vehicle.registrationNumber
    vehicle.abiCode = risk.vehicle.car.abiCode ?? risk.vehicle.unknownABICode
    vehicle.make = risk.vehicle.car.makeDescription
    vehicle.model = risk.vehicle.car.modelDescription
    vehicle.bodyType = risk.vehicle.bodyType
    vehicle.transmissionType = risk.vehicle.vehicleTransmission?.uniqueId
    vehicle.fuelType = risk.vehicle.fuelType?.uniqueId
    vehicle.value = risk.vehicle.value
    vehicle.purchaseDate = risk.vehicle.purchaseDate
    vehicle.yearOfManufacture = risk.vehicle.yearOfManufacture

    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleLookup, vehicle, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.customerVehicleValue, risk.vehicle.value, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.dateOfPurchase, risk.vehicle.purchaseDate, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleSeats, risk.vehicle.noOfSeats, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.isVehicleRightHandDrive, risk.vehicle.leftHandDrive ? DLDCommonValuesEnum.Boolean.No : DLDCommonValuesEnum.Boolean.Yes, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleImported, risk.vehicle.imported ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.isVehicleImpounded, risk.isImpounded ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)

    this.createAnswerForQuestion(pageAnswers, QuestionEnum.licenceType, risk.proposer.licenceType.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.licenceDate, risk.proposer.licenceDate, pages)

    this.createAnswerForQuestion(pageAnswers, QuestionEnum.title, risk.proposer.title.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.firstname, risk.proposer.firstName, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.lastName, risk.proposer.lastName, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.dateOfBirth, risk.proposer.dateOfBirth, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.address, risk.address, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.drivingLicence, risk.proposer.drivingLicence, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleEstimatedMileage, risk.vehicle.annualMileage, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleBusinessMileage, risk.vehicle.businessMileage === 0 ? null : risk.vehicle.businessMileage, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleKeeper, risk.vehicle.registeredKeeper.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleOwner, risk.vehicle.registeredOwner.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleOvernightParkLocation, risk.vehicle.overnightParkLocation.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.employmentStatus, risk.proposer.employmentStatus.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.occupation, risk.proposer.occupation.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.businessCategory, risk.proposer.businessCategory.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.classOfUse, risk.classOfUse.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.commercialVehicleClassOfUse, risk.classOfUse.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.referralSource, risk.businessSource?.id, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.effectiveDate, risk.effectiveDate, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.voluntaryExcess, risk.voluntaryExcess.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.noClaimsProtection, risk.noClaimsProtection ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.commercialVehicleBodyType, risk.vehicle.commercialVehicleBodyType?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.residentSinceBirth, risk.proposer.residentSinceBirth ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.residentSince, risk.proposer.residentSince, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.fuelType, risk.vehicle.fuelType?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleTransmission, risk.vehicle.vehicleTransmission?.uniqueId, pages)

    this.createAnswerForQuestion(pageAnswers, QuestionEnum.trackerType, risk.vehicle.trackerType?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.trackerModel, risk.vehicle.trackerModel?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.modernCarAlarmType, risk.vehicle.alarmType?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.alarmModel, risk.vehicle.alarmModel?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.alarmFittedBy, risk.vehicle.alarmFittedBy?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.hasSecondaryEmployment, risk.proposer.hasSecondaryEmployment ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.secondaryOccupation, risk.proposer.secondaryOccupation?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.secondaryBusinessCategory, risk.proposer.secondaryBusinessCategory?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.maritalStatus, risk.proposer.maritalStatus?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.homeOwner, risk.proposer.homeOwner ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.numberOfOtherVehiclesAvailable, risk.proposer.numberOfOtherVehiclesAvailable?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.doYouDriveAnyVehicleBesidesThisOne, risk.proposer.otherVehiclesYouDrive != null ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.otherVehiclesYouDrive, risk.proposer.otherVehiclesYouDrive?.uniqueId, pages)

    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleIsModified, risk.vehicle.isModified ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.hasCriminalConvictions, risk.proposer.hasCriminalConvictions ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.hasHadPreviousPolicyCancelled, risk.proposer.hasHadPreviousPolicyCancelled ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.hasMedicalConditions, risk.proposer.hasMedicalConditions ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)

    const additionalDrivers = this.createAnswerForQuestion(pageAnswers, QuestionEnum.additionalDriver, risk.additionalDrivers.length, pages)!
    additionalDrivers.forEach((ada) => {
      for (let i = 0; i < risk.additionalDrivers.length; i += 1) {
        const ad = risk.additionalDrivers[i]
        const adTitle = new QuestionAnswer<string>(0, QuestionEnum.title, '', ad.title.uniqueId)
        const adFirstName = new QuestionAnswer<string>(0, QuestionEnum.firstname, '', ad.firstName)
        const adLastName = new QuestionAnswer<string>(0, QuestionEnum.lastName, '', ad.lastName)
        const adDateOfBirth = new QuestionAnswer<Date>(0, QuestionEnum.dateOfBirth, '', ad.dateOfBirth)
        const adResidentSince = new QuestionAnswer<Date>(0, QuestionEnum.residentSince, '', ad.residentSince)
        const adResidentSinceBirth = new QuestionAnswer<string>(0, QuestionEnum.residentSinceBirth, '', ad.residentSinceBirth ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const adDrivingLicence = new QuestionAnswer<string>(0, QuestionEnum.drivingLicence, '', ad.drivingLicence)
        const adRelationshipToPolicyholder = new QuestionAnswer<string>(0, QuestionEnum.relationshipToPolicyHolder, '', ad.relationshipToPolicyholder.uniqueId)
        const adEmploymentStatus = new QuestionAnswer<string>(0, QuestionEnum.employmentStatus, '', ad.employmentStatus.uniqueId)
        const adOccupation = new QuestionAnswer<string>(0, QuestionEnum.occupation, '', ad.occupation.uniqueId)
        const adBusinessCategory = new QuestionAnswer<string>(0, QuestionEnum.businessCategory, '', ad.businessCategory.uniqueId)
        const adPrn = new QuestionAnswer<number>(0, QuestionEnum.PRN, '', ad.prn, false)

        const adresidentSince = new QuestionAnswer<Date>(0, QuestionEnum.residentSince, '', ad.residentSince)
        const adresidentSinceBirth = new QuestionAnswer<string>(0, QuestionEnum.residentSinceBirth, '', ad.residentSinceBirth ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const adMaritalStatus = new QuestionAnswer<string>(0, QuestionEnum.maritalStatus, '', ad.maritalStatus?.uniqueId)
        const adDriveOtherVehicles = new QuestionAnswer<string>(0, QuestionEnum.doYouDriveAnyVehicleBesidesThisOne, '', ad.useOfAnotherCar != null ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const adOtherVehicles = new QuestionAnswer<string>(0, QuestionEnum.otherVehiclesYouDrive, '', ad.useOfAnotherCar?.uniqueId)

        const adPage = new PageAnswers(`Additional Driver ${i + 1}`, [adTitle, adFirstName, adLastName, adDateOfBirth, adDrivingLicence, adRelationshipToPolicyholder, adEmploymentStatus, adOccupation, adBusinessCategory, adPrn, adResidentSince, adResidentSinceBirth, adresidentSince, adresidentSinceBirth, adMaritalStatus, adDriveOtherVehicles, adOtherVehicles])

        ada.subPageAnswers.push(adPage)
      }
    })

    const convictions = this.createAnswerForQuestion(pageAnswers, QuestionEnum.convictions, risk.proposer.convictions.length, pages)!
    convictions.forEach((conva) => {
      for (let i = 0; i < risk.proposer.convictions.length; i += 1) {
        const conv = risk.proposer.convictions[i]
        const convDate = new QuestionAnswer<Date>(0, QuestionEnum.convictionDate, '', conv.convictionDate)
        const convConvictionType = new QuestionAnswer<string>(0, QuestionEnum.convictionType, '', conv.convictionType.uniqueId)
        const convConvictionIsPending = new QuestionAnswer<string>(0, QuestionEnum.convictionIsPending, '', conv.convictionIsPending ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)

        const convPage = new PageAnswers(`Conviction ${i + 1}`, [convDate, convConvictionType, convConvictionIsPending])

        conva.subPageAnswers.push(convPage)
      }
    })

    return pageAnswers
  }

  getAccountRegisterRequest(questions: IQuestionAnswer[], affiliateId: number | null): AccountRegister {
    const title = questions.find(q => q.name === QuestionEnum.title) as QuestionAnswer<string>
    const firstName = questions.find(q => q.name === QuestionEnum.firstname) as QuestionAnswer<string>
    const lastName = questions.find(q => q.name === QuestionEnum.lastName) as QuestionAnswer<string>
    const email = questions.find(q => q.name === QuestionEnum.customerEmail) as QuestionAnswer<string>
    const dob = questions.find(q => q.name === QuestionEnum.dateOfBirth) as QuestionAnswer<Date>
    const address = questions.find(q => q.name === QuestionEnum.address) as QuestionAnswer<Address>
    const phoneNumber = questions.find(q => q.name === QuestionEnum.mobilePhoneNumber) as QuestionAnswer<string>
    const marketing = questions.find(q => q.name === QuestionEnum.marketing) as QuestionAnswer<string[]>

    const request = new AccountRegister()
    request.title = title.value!
    request.firstName = firstName.value!
    request.lastName = lastName.value!
    request.email = email.value!
    request.dateOfBirth = dob.value!
    request.address = address.value!
    request.mobilePhone = phoneNumber.value!

    request.marketing = new Marketing()
    if (marketing?.value) {
      request.marketing.isEmailOptIn = marketing.value?.indexOf(DLDCommonValuesEnum.MarketingOption.Email) !== -1
      request.marketing.isSMSOptIn = marketing.value?.indexOf(DLDCommonValuesEnum.MarketingOption.SMS) !== -1
      request.marketing.isPostOptIn = marketing.value?.indexOf(DLDCommonValuesEnum.MarketingOption.Post) !== -1
      request.marketing.isTelephoneOptIn = marketing.value?.indexOf(DLDCommonValuesEnum.MarketingOption.Telephone) !== -1
    }

    request.affiliateId = affiliateId

    return request
  }

  updateAccountFromQuestions(account: Account, questions: IQuestionAnswer[]): Account {
    const title = questions.find(q => q.name === QuestionEnum.title) as QuestionAnswer<string>
    const firstName = questions.find(q => q.name === QuestionEnum.firstname) as QuestionAnswer<string>
    const lastName = questions.find(q => q.name === QuestionEnum.lastName) as QuestionAnswer<string>
    const dob = questions.find(q => q.name === QuestionEnum.dateOfBirth) as QuestionAnswer<Date>
    const email = questions.find(q => q.name === QuestionEnum.customerEmail) as QuestionAnswer<string>
    const phoneNumber = questions.find(q => q.name === QuestionEnum.mobilePhoneNumber || q.name === QuestionEnum.customerHomePhoneNumber) as QuestionAnswer<string>
    const marketing = questions.find(q => q.name === QuestionEnum.marketing) as QuestionAnswer<string[]>
    const address = questions.find(q => q.name === QuestionEnum.address) as QuestionAnswer<Address>
    const autoRenew = questions.find(q => q.name === QuestionEnum.autoRenewalOptOut) as QuestionAnswer<string>

    account.title = title?.value ?? account.title
    account.firstName = firstName?.value ?? account.firstName
    account.lastName = lastName?.value ?? account.lastName
    account.dateOfBirth = dob?.value ?? account.dateOfBirth
    account.email = email?.value ?? account.email
    account.homePhone = phoneNumber?.value ?? account.homePhone
    account.mobilePhone = phoneNumber?.value ?? account.mobilePhone
    account.telephone = phoneNumber?.value ?? account.telephone
    account.workPhone = phoneNumber?.value ?? account.workPhone

    if (marketing?.value) {
      account.marketing.isEmailOptIn = marketing.value?.indexOf(DLDCommonValuesEnum.MarketingOption.Email) !== -1
      account.marketing.isSMSOptIn = marketing.value?.indexOf(DLDCommonValuesEnum.MarketingOption.SMS) !== -1
      account.marketing.isPostOptIn = marketing.value?.indexOf(DLDCommonValuesEnum.MarketingOption.Post) !== -1
      account.marketing.isTelephoneOptIn = marketing.value?.indexOf(DLDCommonValuesEnum.MarketingOption.Telephone) !== -1
    }
    account.address = address?.value ?? account.address
    if (autoRenew?.value)
      account.autoRenew = autoRenew.value?.indexOf('true') !== -1 ? false : true ?? null

    return account
  }
}
