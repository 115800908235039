import type { Route } from 'vue-router'
import type VueRouter from 'vue-router'
import Routes from '@/constants/Routes'
import PolicyStatusEnum from '@/services/enums/PolicyStatusEnum'
import type PolicySummary from '@/services/values/PolicySummary'

export function getPolicyNumber(route: Route): string {
  return route.params.policyNumber
};

export function getPolicyStatusClass(status: PolicyStatusEnum): string {
  switch (status) {
    case PolicyStatusEnum.live:
    case PolicyStatusEnum.cancellationPending:
    case PolicyStatusEnum.adjustmentLive:
    case PolicyStatusEnum.adjustmentPending:
    case PolicyStatusEnum.renewed:
      return 'is-live'
    case PolicyStatusEnum.renewalPending:
    case PolicyStatusEnum.livePending:
      return 'is-pending'
    case PolicyStatusEnum.ntu:
    case PolicyStatusEnum.cancelled:
    case PolicyStatusEnum.lapsed:
    case PolicyStatusEnum.lapsedRenewal:
      return 'is-cancelled'
    default:
      return ''
  }
};

export function goToPolicy(router: VueRouter, policy: PolicySummary): void {
  router.push({
    name: Routes.policy,
    params: {
      code: policy.schemeName!,
      product: policy.product,
      policyNumber: policy.policyNumber,
    },
  })
};

export function isActionAvailable(policy: PolicySummary, action: string) {
  return policy.availableActions.findIndex(availableAction => availableAction === action) >= 0
};
