enum QuoteStatusEnum {
  incomplete = 'Incomplete',
  complete = 'Complete',
  quoted = 'Quoted',
  renewalInvalidated = 'RenewalInvalidated',
  rejected = 'rejected',
  lapsed = 'Lapsed',
}

export default QuoteStatusEnum
