import moment from 'moment'
import Utils from './Utils'
import type QuoteDetails from '@/services/models/QuoteDetails'
import type SchemeQuoteResult from '@/services/models/SchemeQuoteResult'

export default class KeeyPartnersUtils extends Utils {
  coverType<T extends SchemeQuoteResult>(quote: QuoteDetails<T>, selectedSchemeQuoteResult: number): string {
    return quote.results.find(r => r.id === selectedSchemeQuoteResult)!.schemeName
  }

  getRenewalDate<T extends SchemeQuoteResult>(quote: QuoteDetails<T>): Date {
    return moment(quote.effectiveDate)
      .add(1, 'year')
      .hours(23)
      .minutes(59)
      .seconds(59)
      .toDate()
  }
}
