import { JsonObject, JsonProperty } from 'json2typescript'
import Model from './Model'

@JsonObject('CoInsuranceRule')
export default class CoInsuranceRule extends Model {
  @JsonProperty('percentage', String)
  percentage: string

  constructor(
    id = 0,
    percentage = '',
  ) {
    super(id)
    this.percentage = percentage
  }
}
