import { JsonObject, JsonProperty } from 'json2typescript'

@JsonObject('DirectDebit')
export default class DirectDebit {
  @JsonProperty('accountNumber', String)
  accountNumber = ''

  @JsonProperty('accountHolderName', String)
  accountHolderName = ''

  @JsonProperty('bankName', String)
  bankName = ''

  @JsonProperty('sortCode', String)
  sortCode = ''

  @JsonProperty('preferredPaymentDay', Number)
  preferredPaymentDay = 1

  @JsonProperty('directDebitProvider', String)
  directDebitProvider = ''
}
