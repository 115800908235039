import type { JsonConvert } from 'json2typescript'
import type { AxiosInstance } from 'axios'
import type IAccountService from './interfaces/IAccountService'
import Account from './models/Account'
import type IResponseMapper from './interfaces/IResponseMapper'
import type IRequestMapper from './interfaces/IRequestMapper'
import WebPushSubscription, { WebPushSubscriptionKey } from './models/WebPushSubscription'
import type WebPlatformEnum from './enums/WebPlatformEnum'
import AccountRegister from './values/AccountRegister'
import type { IQuestionAnswer } from './values/PageAnswers'
import type IQuestion from './interfaces/IQuestion'
import IdentityProvider from './IdentityProvider'
import useAccountStore from '@/stores/account-store'
import PrepopulatableValues from '@/services/models/PrepopulatableValues'
import { getAuthServiceConfig } from '@/auth-service'

export default class AccountService implements IAccountService {
  axios: AxiosInstance

  apiUrl: URL

  jsonConvert: JsonConvert

  responseMapper: IResponseMapper

  requestMapper: IRequestMapper

  constructor(axios: AxiosInstance, apiUrl: URL, jsonConvert: JsonConvert, responseMapper: IResponseMapper, requestMapper: IRequestMapper) {
    this.axios = axios
    this.apiUrl = apiUrl
    this.jsonConvert = jsonConvert
    this.responseMapper = responseMapper
    this.requestMapper = requestMapper
  }

  async register(answers: IQuestionAnswer[], affiliateId: number) {
    const request = this.requestMapper.getAccountRegisterRequest(answers, affiliateId)
    if (!request)
      return false
    const authServiceConfig = getAuthServiceConfig()
    if (authServiceConfig.enabled) {
      const { token } = await authServiceConfig.getAuthProspectToken()
      authServiceConfig.setToken(token, 'auth-prospect')
    }
    const result = await this.axios.post(`${this.apiUrl}/account/register`, this.jsonConvert.serialize(request, AccountRegister))
    IdentityProvider.setAuthChangedBy()
    return result.status === 200
  }

  async getCurrent(bypasscache = false): Promise<Account> {
    let account
    const accountStore = useAccountStore()
    if (!bypasscache)
      account = accountStore.account

    if (!account) {
      const result = await this.axios.get(`${this.apiUrl}/account`, { headers: { canBeUnauthorised: true } })
      account = this.jsonConvert.deserializeObject(result.data, Account)
      accountStore.setAccount(account)
    }
    return account
  }

  async getPrepopulatableValues(product: string): Promise<PrepopulatableValues> {
    const result = await this.axios.get(`${this.apiUrl}/account/${product}/prepopulate-values`, { headers: { canBeUnauthorised: true } })
    return this.jsonConvert.deserializeObject(result.data, PrepopulatableValues)
  }

  async update(account: Account): Promise<void> {
    return this.axios.put(`${this.apiUrl}/account`, this.jsonConvert.serialize(account, Account))
  }

  async updateAccount(answers: IQuestionAnswer[], manuallyUpdatedAccount?: Account): Promise<void> {
    const account = manuallyUpdatedAccount ?? await this.getCurrent(true)
    const request = this.requestMapper.updateAccountFromQuestions(account, answers)
    return this.update(request)
  }

  async mapPrepopulateValuesToQuestions(questions: IQuestion[], product: string): Promise<void> {
    this.responseMapper.mapPrepopulateValuesToQuestions(await this.getPrepopulatableValues(product), questions)
  }

  async mapAccountToQuestions(questions: IQuestion[]): Promise<void> {
    this.responseMapper.mapAccountToQuestions(await this.getCurrent(), questions)
  }

  private addWebPushSubscription(pushNotification: WebPushSubscription) {
    return this.axios.post(`${this.apiUrl}/account/web-push-subscription`, this.jsonConvert.serialize<WebPushSubscription>(pushNotification))
  }

  async requestPermission(publicKey: string, webPlatform: WebPlatformEnum): Promise<void> {
    const swReg = await navigator.serviceWorker.ready
    const sub = await swReg.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: this.base64UrlToUint8Array(publicKey),
    })
    const keys = new WebPushSubscriptionKey(this.arrayBufferToKey(sub.getKey('auth')!), this.arrayBufferToKey(sub.getKey('p256dh')!))
    const pushNotification = new WebPushSubscription(sub.endpoint, webPlatform, keys)
    this.addWebPushSubscription(pushNotification)
  }

  arrayBufferToKey(arrayBuffer: ArrayBuffer) {
    const intArray = new Uint8Array(arrayBuffer)
    return btoa(String.fromCharCode.apply(null, Array.from(intArray)))
  }

  base64UrlToUint8Array(base64UrlData: string) {
    const padding = '='.repeat((4 - base64UrlData.length % 4) % 4)
    const base64 = (base64UrlData + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/')

    const rawData = atob(base64)
    const buffer = new Uint8Array(rawData.length)

    for (let i = 0; i < rawData.length; ++i)
      buffer[i] = rawData.charCodeAt(i)

    return buffer
  }
}
