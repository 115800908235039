import { JsonObject, JsonProperty } from 'json2typescript'
import Model from './Model'
import StaticFile from './StaticFile'

@JsonObject('Enclosure')
export default class Enclosure extends Model {
  @JsonProperty('staticFile', StaticFile)
  staticFile: StaticFile

  constructor(id = 0, staticFile: StaticFile = new StaticFile()) {
    super(id)
    this.staticFile = staticFile
  }
}
