import { JsonObject, JsonProperty } from 'json2typescript'
import Model from './Model'
import DateConverter from '@/converters/DateConverter'

@JsonObject('Car')
export default class Car extends Model {
  @JsonProperty('abiCode', String)
  abiCode: string

  @JsonProperty('vin', String)
  vin: string

  @JsonProperty('makeDescription', String)
  makeDescription: string

  @JsonProperty('modelDescription', String)
  modelDescription: string

  @JsonProperty('manufacturedFrom', Number)
  manufacturedFrom: number

  @JsonProperty('manufacturedTo', Number)
  manufacturedTo: number

  @JsonProperty('engineSize', Number)
  engineSize: number

  @JsonProperty('bodyDesc', String, true)
  bodyDesc: string

  @JsonProperty('doors', Number)
  doors: number

  @JsonProperty('fuelType', String)
  fuelType: string

  @JsonProperty('transmission', String)
  transmission: string

  @JsonProperty('dateFirstRegistered', DateConverter)
  dateFirstRegistered: Date

  constructor(
    id = 0,
    abiCode = '',
    vin = '',
    makeDescription = '',
    modelDescription = '',
    manufacturedFrom = 0,
    manufacturedTo = 0,
    engineSize = 0,
    bodyDesc = '',
    doors = 0,
    fuelType = '',
    transmission = '',
    dateFirstRegistered = new Date(),
  ) {
    super(id)
    this.abiCode = abiCode
    this.vin = vin
    this.makeDescription = makeDescription
    this.modelDescription = modelDescription
    this.manufacturedFrom = manufacturedFrom
    this.manufacturedTo = manufacturedTo
    this.engineSize = engineSize
    this.bodyDesc = bodyDesc
    this.doors = doors
    this.fuelType = fuelType
    this.transmission = transmission
    this.dateFirstRegistered = dateFirstRegistered
  }
}
