import { JsonObject, JsonProperty } from 'json2typescript'
import Risk from '../Risk'
import Address from '../Address'
import CustomList from '../CustomList'
import CoInsuranceRule from '../CoInsuranceRule'
import VehicleProposer from './VehicleProposer'
import VehicleRiskVehicle from './VehicleRiskVehicle'
import VehicleDriver from './VehicleDriver'

@JsonObject('VehicleRisk')
export default class VehicleRisk extends Risk {
  @JsonProperty('proposer', VehicleProposer)
    proposer: VehicleProposer

  @JsonProperty('vehicles', [VehicleRiskVehicle])
    vehicles: VehicleRiskVehicle[]

  @JsonProperty('vehicleDrivers', [VehicleDriver])
    vehicleDrivers: VehicleDriver[]

  constructor(
      id = 0,
      effectiveDate = new Date(),
      address = new Address(),
      businessSource = new CustomList(),
      coInsuranceRule = new CoInsuranceRule(),
      proposer = new VehicleProposer(),
      vehicles: VehicleRiskVehicle[] = [],
      vehicleDrivers: VehicleDriver[] = [],
  ) {
    super(id, effectiveDate, address, businessSource, coInsuranceRule)
    this.proposer = proposer
    this.vehicles = vehicles
    this.vehicleDrivers = vehicleDrivers
  }
}
