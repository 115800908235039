import { JsonObject, JsonProperty } from 'json2typescript'

@JsonObject('GoCardlessRedirectFlowResponse')
export default class GoCardlessRedirectFlowResponse {
  @JsonProperty('redirectUrl', String)
  redirectUrl: string

  sessionToken: string

  constructor(redirectUrl = '', sessionToken = '') {
    this.redirectUrl = redirectUrl
    this.sessionToken = sessionToken
  }
}
