import { JsonObject, JsonProperty } from 'json2typescript'
import Enclosure from './Enclosure'
import Model from './Model'

@JsonObject('Insurer')
export default class Insurer extends Model {
  @JsonProperty('name', String)
  name: string

  @JsonProperty('enclosures', [Enclosure])
  enclosures: Enclosure[]

  @JsonProperty('typicalNCDDiscountTable', String)
  typicalNCDDiscountTable: string

  constructor(
    id = 0,
    name = '',
    enclosures: Enclosure[] = [],
    typicalNCDDiscountTable = '',
  ) {
    super(id)
    this.name = name
    this.enclosures = enclosures
    this.typicalNCDDiscountTable = typicalNCDDiscountTable
  }
}
