import { JsonObject, JsonProperty } from 'json2typescript'
import { DefinedListDetail } from './DefinedList'
import QuoteDetails from './QuoteDetails'
import type SchemeQuoteResult from './SchemeQuoteResult'
import PaymentTypeEnumConverter from '@/converters/PaymentTypeEnumConverter'
import PaymentTypeEnum from '@/services/enums/PaymentTypeEnum'

@JsonObject('GapQuoteDetails')
export default class GapQuoteDetails extends QuoteDetails<SchemeQuoteResult> {
  @JsonProperty('coverTerm', DefinedListDetail)
  coverTerm: DefinedListDetail = new DefinedListDetail()
}

@JsonObject('GapMTAQuoteDetails')
export class GapMTAQuoteDetails extends GapQuoteDetails {
  @JsonProperty('initialPaymentMethod', PaymentTypeEnumConverter)
  initialPaymentMethod: PaymentTypeEnum = PaymentTypeEnum.cardPayment

  @JsonProperty('hasUpgradableVAP', Boolean)
  hasUpgradableVAP = true

  @JsonProperty('hasClaims', Boolean)
  hasClaims = false

  @JsonProperty('message', String)
  message = ''
}
