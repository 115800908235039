import type { Dictionary } from '@/types'

export default class BadRequestError implements Error {
  name: string

  message: string

  stack?: string

  modelState?: Dictionary<string>

  constructor(modelState?: Dictionary<string>) {
    this.name = 'BadRequestError'
    this.message = 'The request you have made did not validate. Validations have been applied to the original questions where possible.'
    this.modelState = modelState
  }
}
