import type _Vue from 'vue'
import FormatCardNumber from './CreditCard'
import FormatSortCode from './DirectDebit'

export default function DirectivesPlugin(Vue: typeof _Vue): void {
  Vue.directive('uppercase', {
    inserted(el, _, vnode) {
      el.addEventListener('input', (e) => {
        if (vnode && vnode.componentInstance && e && e.target && e.target instanceof HTMLInputElement) {
          e.target.value = e.target.value.toUpperCase()
          vnode.componentInstance.$emit('input', e.target.value)
        }
      })
    },
  })

  Vue.directive('trimwhitespace', {
    inserted(el, _, vnode) {
      el.addEventListener('input', (e) => {
        if (vnode && vnode.componentInstance && e && e.target && e.target instanceof HTMLInputElement) {
          e.target.value = e.target.value.replace(/\s+/g, '')
          vnode.componentInstance.$emit('input', e.target.value)
        }
      })
    },
  })

  Vue.directive('lettersnumbersonly', {
    inserted(el, _, vnode) {
      el.addEventListener('input', (e) => {
        if (vnode && vnode.componentInstance && e && e.target && e.target instanceof HTMLInputElement) {
          e.target.value = e.target.value.replace(/\W+/g, '')
          vnode.componentInstance.$emit('input', e.target.value)
        }
      })
    },
  })

  Vue.directive('credit-card', {
    bind(el: any, binding: any) {
      el.addEventListener('input', () => {
        Vue.nextTick(() => {
          FormatCardNumber(el.__vue__, binding)
        })
      })
    },
  })

  Vue.directive('direct-debit', {
    bind(el: any) {
      el.addEventListener('input', () => {
        Vue.nextTick(() => {
          FormatSortCode(el.__vue__)
        })
      })
    },
  })
}
