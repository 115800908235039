import Hotjar from '@hotjar/browser'
import _Vue from 'vue'
import VueGtag from 'vue-gtag'
import type IAnalyticsConfig from '../IAnalyticsConfig'
import Analytics from './Analytics'
import type AggregatorClient from '@/services/models/AggregatorClient'
import type QuoteDetails from '@/services/models/QuoteDetails'
import type Risk from '@/services/models/Risk'
import { PetRisk } from '@/services/models/Risk'
import type SchemeQuoteResult from '@/services/models/SchemeQuoteResult'

export default class TractiveAnaltics extends Analytics {
  loadAnalytics(config: IAnalyticsConfig): void {
    // Google Tag Manager
    const gtmId = window.injectedEnv.FRONTEND_GTM
    if (gtmId) {
      (function loadGTM(w: any, d: any, s: any, l: any, i: any) {
        w[l] = w[l] || []
        w[l].push({ 'gtm.start': new Date().getTime(), 'event': 'gtm.js' })
        const f = d.getElementsByTagName(s)[0]
        const j = d.createElement(s)
        const dl = l !== 'dataLayer' ? `&l=${l}` : ''
        j.async = true
        j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`
        f.parentNode.insertBefore(j, f)
      })(window, document, 'script', 'dataLayer', gtmId)
    }

    // Google Analytics
    _Vue.use(VueGtag, {
      config: {
        id: config.analytics,
        params: {

          send_page_view: false,
        },
      },
    }, config.router)

    const hostJar = window.injectedEnv.FRONTEND_HOTJAR
    if (hostJar) {
      // Hotjar Tracking
      Hotjar.init(Number(hostJar), 6)
    }
  }

  trackNBSale<T extends SchemeQuoteResult>(vue: Vue, policyNumber: string, quote: QuoteDetails<T>, selectedSchemeQuoteResult: number, aggregatorClient: AggregatorClient | null, risk: Risk | null): void {
    const result = quote.results.find(r => r.id === selectedSchemeQuoteResult)!

    // Google Tag Manager
    this.window.dataLayer?.push({
      policyNumber,
      schemeName: result.schemeName,
      premium: result.calculatedTotalPayable.toString(10),
      additional: result.additional.toString(10),
      aggregator: aggregatorClient?.name ?? '',
      event: 'nb-sale',
      policyType: risk instanceof PetRisk ? (risk?.pets.length > 1 ? 'multipet' : 'single') : '',
    })

    // Direct to Google Analytics sale tracking
    vue.$gtag?.purchase({

      transaction_id: policyNumber,
      affiliation: 'Ignite CF',
      value: result.calculatedTotalPayable,
      items: [
        {
          id: result.scheme.code,
          name: result.schemeName,
        },
      ],
    })
  }
}
