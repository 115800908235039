import { JsonObject, JsonProperty } from 'json2typescript'
import DateConverter from '../../converters/DateConverter'

@JsonObject('TouringCaravanClaimValue')
export default class TouringCaravanClaimRequests {
  @JsonProperty('claimType', String, true)
  claimType: string | null = null

  @JsonProperty('claimDate', DateConverter, true)
  claimDate: Date | null = null

  @JsonProperty('claimCost', Number, true)
  claimCost: number | null = null
}
