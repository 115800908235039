import type _Vue from 'vue'
import TenantEnum from '@/services/enums/TenantEnum'
import ZoomcoverUtils from '@/utils/ZoomcoverUtils'
import Utils from '@/utils/Utils'
import KeeyPartnersUtils from '@/utils/KeeyPartnersUtils'
import WrapperUtils from '@/utils/WrapperUtils'
import BarryGraingerUtils from '@/utils/BarryGraingerUtils'
import DashUtils from '@/utils/DashUtils'
import YogaUtils from '@/utils/YogaUtils'

export default function UtilsPlugin(Vue: typeof _Vue, tenant: TenantEnum): void {
  switch (tenant) {
    case TenantEnum.Zoomcover:
      Vue.prototype.$utils = new ZoomcoverUtils()
      break
    case TenantEnum.Yoga:
      Vue.prototype.$utils = new YogaUtils()
      break
    case TenantEnum.KeeyPartners:
      Vue.prototype.$utils = new KeeyPartnersUtils()
      break
    case TenantEnum.Wrapper:
      Vue.prototype.$utils = new WrapperUtils()
      break
    case TenantEnum.BarryGrainger:
      Vue.prototype.$utils = new BarryGraingerUtils()
      break
    case TenantEnum.Dash:
      Vue.prototype.$utils = new DashUtils()
      break
    default:

      Vue.prototype.$utils = new Utils()
  }
}
