import { JsonObject, JsonProperty } from 'json2typescript'
import DateConverter from '@/converters/DateConverter'

@JsonObject('VehicleConvictionValue')
export default class VehicleConviction {
  @JsonProperty('convictionDate', DateConverter)
    convictionDate: Date = new Date()

  @JsonProperty('convictionType', String)
    convictionType = ''

  @JsonProperty('convictionPersonsInjured', Boolean)
    convictionPersonsInjured = false

  @JsonProperty('hasConvictionBan', Boolean)
    hasConvictionBan = false

  @JsonProperty('banStartDate', DateConverter, true)
    banStartDate: Date | null = null

  @JsonProperty('banEndDate', DateConverter, true)
    banEndDate: Date | null = null

  @JsonProperty('convictionFine', Number)
    convictionFine = 0

  @JsonProperty('convictionPointsReceived', Number)
    convictionPointsReceived = 0
}
