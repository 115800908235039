import moment from 'moment'
import type IRequestMapper from '../interfaces/IRequestMapper'
import type IResponseMapper from '../interfaces/IResponseMapper'
import type IQuestionMapper from '../interfaces/IQuestionMapper'
import type SchemeRequest from '../values/SchemeRequest'
import type QuoteRequest from '../values/QuoteRequest'
import type Page from '../../view-models/Page'
import type ProductPage from '../models/ProductPage'
import QuestionEnum from '../enums/QuestionEnum'
import DLDCommonValuesEnum from '../enums/DLDCommonValuesEnum'
import GapVehiclePaymentTypeEnum from '../enums/GapVehiclePaymentTypeEnum'
import type { AdditionalDriver } from '../values/Driver'
import { CarProposer } from '../values/Driver'
import UnkownQuestionSetError from '../error/UnknownQuestionSetError'
import Vehicle from '../values/Vehicle'
import AccountRegister from '../values/AccountRegister'
import type Risk from '../models/Risk'
import { CarRisk, GapRisk } from '../models/Risk'
import type Account from '../models/Account'
import { Marketing } from '../models/Account'
import Car from '../values/Car'
import type IPageMappings from '../interfaces/IPageMappings'
import type { IQuestionAnswer } from '../values/PageAnswers'
import PageAnswers, { QuestionAnswer } from '../values/PageAnswers'
import type Address from '../models/Address'
import CarQuoteRequest, { CarMTAQuoteRequest } from '../values/CarQuoteRequest'
import GapQuoteRequest, { GapMTAQuoteRequest } from '../values/GapQuoteRequest'
import type IQuestion from '../interfaces/IQuestion'
import BaseMapper from './BaseMapper'
import type ClaimModel from '@/services/models/Claim'
import type Claim from '@/services/values/Claim'
import type {
  DateQuestion,
  DrivingLicenceQuestion,
  MarkdownQuestion,
  SelectQuestion,
  TextQuestion,
} from '@/view-models/Question'
import ContextLevel from '@/services/enums/ContextLevel'
import type { Dictionary } from '@/types'
import Routes from '@/constants/Routes'

export default class BarryGraingerMapper extends BaseMapper implements IQuestionMapper, IRequestMapper, IResponseMapper, IPageMappings {
  nbPageMappings: Dictionary<{ route: Routes, contextLevel: ContextLevel }> = {
    MotorCaravan_Vehicle_Details_CustomerFacing: { route: Routes.vehicleDetails, contextLevel: ContextLevel.preQuote },
    MotorCaravan_Proposer_CustomerFacing: { route: Routes.proposerDetails, contextLevel: ContextLevel.preQuote },
    MotorCaravan_Cover_Details_CustomerFacing: { route: Routes.coverDetails, contextLevel: ContextLevel.preQuote },
    MotorCaravan_Premium_CustomerFacing: { route: Routes.premium, contextLevel: ContextLevel.quote },
    MotorCaravan_VAPs_CustomerFacing: { route: Routes.additionalProducts, contextLevel: ContextLevel.quote },
    MotorCaravan_Payment: { route: Routes.payment, contextLevel: ContextLevel.quote },
    MotorCaravan_PostTakeUpVAPs_CustomerFacing: { route: Routes.upgrade, contextLevel: ContextLevel.policy },
    MotorCaravan_VAPs_Payment_CustomerFacing: { route: Routes.upgradePayment, contextLevel: ContextLevel.policy },
    MotorCaravan_PaymentConfirmation: { route: Routes.paymentConfirm, contextLevel: ContextLevel.policy },
    GAPInsurance_Vehicle_Details_CustomerFacing: { route: Routes.vehicleDetails, contextLevel: ContextLevel.preQuote },
    GAPInsurance_Owning_CustomerFacing: { route: Routes.owningAndUsing, contextLevel: ContextLevel.preQuote },
    GAPInsurance_Cover_Details_CustomerFacing: { route: Routes.coverDetails, contextLevel: ContextLevel.preQuote },
    GAPInsurance_Proposer_Details_CustomerFacing: { route: Routes.proposerDetails, contextLevel: ContextLevel.preQuote },
    GAPInsurance_Premium_CustomerFacing: { route: Routes.premium, contextLevel: ContextLevel.quote },
    GAPInsurance_Payment_CustomerFacing: { route: Routes.payment, contextLevel: ContextLevel.quote },
    GAPInsurance_PaymentConfirmation_CustomerFacing: { route: Routes.paymentConfirm, contextLevel: ContextLevel.policy },
    GAPInsurance_VAPs_CustomerFacing: { route: Routes.additionalProducts, contextLevel: ContextLevel.quote },
    GAPInsurance_PostTakeUpVAPs_CustomerFacing: { route: Routes.upgrade, contextLevel: ContextLevel.policy },
    GAPInsurance_VAPs_Payment_CustomerFacing: { route: Routes.upgradePayment, contextLevel: ContextLevel.policy },
  }

  mtaPageMappings: Dictionary<{ route: Routes, contextLevel: ContextLevel }> = {
    MotorCaravan_Vehicle_DetailsMta_CustomerFacing: { route: Routes.mtaVehicleDetails, contextLevel: ContextLevel.mtaPreQuote },
    MotorCaravan_ProposerMta_CustomerFacing: { route: Routes.mtaProposerDetails, contextLevel: ContextLevel.mtaPreQuote },
    MotorCaravan_Cover_DetailsMta_CustomerFacing: { route: Routes.mtaCoverDetails, contextLevel: ContextLevel.mtaPreQuote },
    MotorCaravan_PremiumMta_CustomerFacing: { route: Routes.mtaPremium, contextLevel: ContextLevel.mtaQuote },
    MotorCaravan_Payment: { route: Routes.mtaPayment, contextLevel: ContextLevel.mtaQuote },
    MotorCaravan_PaymentConfirmationMta: { route: Routes.mtaConfirm, contextLevel: ContextLevel.mtaTakenUp },
    GAPInsurance_Vehicle_Details_MTA_CustomerFacing: { route: Routes.mtaVehicleDetails, contextLevel: ContextLevel.mtaPreQuote },
    GAPInsurance_Owning_MTA_CustomerFacing: { route: Routes.mtaOwningAndUsing, contextLevel: ContextLevel.mtaPreQuote },
    GAPInsurance_Cover_Details_MTA_CustomerFacing: { route: Routes.mtaCoverDetails, contextLevel: ContextLevel.mtaPreQuote },
    GAPInsurance_Proposer_Details_MTA_CustomerFacing: { route: Routes.mtaProposerDetails, contextLevel: ContextLevel.mtaPreQuote },
    GAPInsurance_Premium_MTA_CustomerFacing: { route: Routes.mtaPremium, contextLevel: ContextLevel.mtaQuote },
    GAPInsurance_Payment_MTA_CustomerFacing: { route: Routes.mtaPayment, contextLevel: ContextLevel.mtaQuote },
    GAPInsurance_PaymentConfirmation_MTA_CustomerFacing: { route: Routes.mtaConfirm, contextLevel: ContextLevel.mtaTakenUp },
  }

  renewalPageMappings: Dictionary<{ route: Routes, contextLevel: ContextLevel }> = {
    MotorCaravan_Vehicle_DetailsRenewal_CustomerFacing: { route: Routes.renewalVehicleDetails, contextLevel: ContextLevel.renewalPreQuote },
    MotorCaravan_ProposerRenewal_CustomerFacing: { route: Routes.renewalProposerDetails, contextLevel: ContextLevel.renewalPreQuote },
    MotorCaravan_Cover_DetailsRenewal_CustomerFacing: { route: Routes.renewalCoverDetails, contextLevel: ContextLevel.renewalPreQuote },
    MotorCaravan_PremiumRenewal_CustomerFacing: { route: Routes.renewalPremium, contextLevel: ContextLevel.renewalQuote },
    MotorCaravan_PaymentRenewal: { route: Routes.renewalPayment, contextLevel: ContextLevel.renewalQuote },
    MotorCaravan_PostTakeUpVAPs_CustomerFacing: { route: Routes.upgrade, contextLevel: ContextLevel.renewalTakenUp },
    MotorCaravan_VAPs_Payment_CustomerFacing: { route: Routes.upgradePayment, contextLevel: ContextLevel.renewalTakenUp },
    MotorCaravan_PaymentConfirmation: { route: Routes.renewalPaymentConfirmation, contextLevel: ContextLevel.renewalTakenUp },
  }

  setupQuestions(setId: string, productPage: ProductPage): Page {
    const mappedPage = this.mapProductPageToPage(productPage)
    return this.setupDependencies(setId, mappedPage)
  }

  setupPage(setId: string, productPage: ProductPage, _answeredQuestions: IQuestionAnswer[]): Page {
    const page = this.mapProductPageToPage(productPage)
    return this.setupDependencies(setId, page)
  }

  setupDependencies(setId: string, mappedPage: Page): Page {
    switch (setId) {
      case 'MotorCaravan_Proposer_CustomerFacing':
      case 'MotorCaravan_ProposerMta_CustomerFacing':
      case 'MotorCaravan_ProposerRenewal_CustomerFacing':
        return this.setupProposerDependencies(mappedPage)
      case 'MotorCaravan_Proposer_CustomerFacing_AdditionalDriver':
      case 'MotorCaravan_ProposerMta_CustomerFacing_AdditionalDriver':
      case 'MotorCaravan_ProposerRenewal_CustomerFacing_AdditionalDriver':
        return this.setupAdditionalDriverDependencies(mappedPage)
      case 'MotorCaravan_Cover_Details_CustomerFacing':
      case 'MotorCaravan_Cover_DetailsMta_CustomerFacing':
      case 'GAPInsurance_Cover_Details_CustomerFacing':
      case 'GAPInsurance_Cover_Details_MTA_CustomerFacing':
        return this.setupCoverDetailsDependencies(mappedPage)
      case 'MotorCaravan_AggregatorLanding':
      case 'MotorCaravan_AggregatorLanding_AdditionalDriver':
        return this.setupAggregatorLandingDependencies(mappedPage)
      case 'MotorCaravan_Proposer_CustomerFacing_Claims':
      case 'MotorCaravan_ProposerMta_CustomerFacing_Claims':
      case 'MotorCaravan_ProposerRenewal_CustomerFacing_Claims':
      case 'MotorCaravan_Proposer_CustomerFacing_AdditionalDriver_Claims':
      case 'MotorCaravan_ProposerMta_CustomerFacing_AdditionalDriver_Claims':
      case 'MotorCaravan_ProposerRenewal_CustomerFacing_AdditionalDriver_Claims':
      case 'MotorCaravan_Cover_DetailsRenewal_CustomerFacing':
      case 'MotorCaravan_VAPs_CustomerFacing':
      case 'MotorCaravan_PostTakeUpVAPs_CustomerFacing':
      case 'MotorCaravan_VAPs_Payment_CustomerFacing':
      case 'MotorCaravan_Premium_CustomerFacing':
      case 'MotorCaravan_PremiumMta_CustomerFacing':
      case 'MotorCaravan_PremiumMta':
      case 'MotorCaravan_PremiumRenewal_CustomerFacing':
      case 'MotorCaravan_Payment':
      case 'MotorCaravan_PaymentConfirmation':
      case 'MotorCaravan_PaymentConfirmationMta':
      case 'MotorCaravan_PremiumRenewal':
      case 'MotorCaravan_PaymentRenewal':
      case 'Account':
      case 'GAPInsurance_Vehicle_Details_CustomerFacing':
      case 'GAPInsurance_Premium_CustomerFacing':
      case 'GAPInsurance_Payment_CustomerFacing':
      case 'GAPInsurance_PaymentConfirmation_CustomerFacing':
      case 'GAPInsurance_Vehicle_Details_MTA_CustomerFacing':
      case 'GAPInsurance_Premium_MTA_CustomerFacing':
      case 'GAPInsurance_Payment_MTA_CustomerFacing':
      case 'GAPInsurance_PaymentConfirmation_MTA_CustomerFacing':
      case 'GAPInsurance_VAPs_CustomerFacing':
      case 'GAPInsurance_PostTakeUpVAPs_CustomerFacing':
      case 'GAPInsurance_VAPs_Payment_CustomerFacing':
        return mappedPage
      case 'MotorCaravan_Vehicle_Details_CustomerFacing':
      case 'MotorCaravan_Vehicle_DetailsMta_CustomerFacing':
      case 'MotorCaravan_Vehicle_DetailsRenewal_CustomerFacing':
        return this.setupVehicleDetailsDependencies(mappedPage)
      case 'GAPInsurance_Proposer_Details_CustomerFacing':
      case 'GAPInsurance_Proposer_Details_MTA_CustomerFacing':
        return this.setupGapProposerDependencies(mappedPage)
      case 'GAPInsurance_Owning_CustomerFacing':
      case 'GAPInsurance_Owning_MTA_CustomerFacing':
        return this.setupOwningDependencies(mappedPage)
      default:
        throw new UnkownQuestionSetError(setId)
    }
  }

  private setupOwningDependencies(page: Page): Page {
    const questions = page.sections.flatMap(s => s.questions)
    const repairQuestion = questions.find(q => q.name === QuestionEnum.gapDamagedBeyondRepair) as MarkdownQuestion
    const paymentQuestion = questions.find(q => q.name === QuestionEnum.gapVehiclePaymentType) as SelectQuestion<string, string>
    const vehicleDeliveryTaken = questions.find(q => q.name === QuestionEnum.vehicleDeliveryTaken) as MarkdownQuestion
    const vehicleCollectionDate = questions.find(q => q.name === QuestionEnum.vehicleCollectionDate) as DateQuestion
    repairQuestion.isVisible = () => paymentQuestion.value! === GapVehiclePaymentTypeEnum.pcpPurchase || paymentQuestion.value! === GapVehiclePaymentTypeEnum.hirePurchase

    if (vehicleDeliveryTaken)
      vehicleCollectionDate.isVisible = () => vehicleDeliveryTaken.value! === DLDCommonValuesEnum.Boolean.Yes

    return page
  }

  private setupVehicleDetailsDependencies(page: Page) {
    const questions = page.sections.flatMap(s => s.questions)
    const motorhomeTypeQuestion = questions.find(q => q.name === QuestionEnum.motorhomeType) as SelectQuestion<string, string>
    const unknownMotorhomeTypeQuestion = questions.find(q => q.name === QuestionEnum.unknownMotorhomeType) as TextQuestion
    const numberOfHouseholdVehicles = questions.find(q => q.name === QuestionEnum.numberOfHouseholdVehicles) as SelectQuestion<string, string>
    const hasAccessToAnotherVehicle = questions.find(q => q.name === QuestionEnum.hasAccessToAnotherVehicle) as SelectQuestion<string, string>

    if (hasAccessToAnotherVehicle)
      numberOfHouseholdVehicles.isVisible = () => hasAccessToAnotherVehicle.value === DLDCommonValuesEnum.Boolean.Yes

    unknownMotorhomeTypeQuestion.isVisible = () => motorhomeTypeQuestion.value === DLDCommonValuesEnum.MotorhomeTypes.Other
    unknownMotorhomeTypeQuestion.maxlength = 50

    return page
  }

  private setupAggregatorLandingDependencies(page: Page): Page {
    const questions = page.sections.flatMap(s => s.questions)

    const titleQuestion = questions.find(q => q.name === QuestionEnum.title)
    const firstNameQuestion = questions.find(q => q.name === QuestionEnum.firstName)
    const lastNameQuestion = questions.find(q => q.name === QuestionEnum.lastName)
    const dateOfBirthQuestion = questions.find(q => q.name === QuestionEnum.dateOfBirth)
    const drivingLicenceQuestion = questions.find(q => q.name === QuestionEnum.drivingLicence) as DrivingLicenceQuestion
    drivingLicenceQuestion.dependentQuestions = [titleQuestion!, firstNameQuestion!, lastNameQuestion!, dateOfBirthQuestion!]

    return page
  }

  private setupAdditionalDriverDependencies(page: Page): Page {
    this.setupCommonDependencies(page)
    const questions = page.sections.flatMap(s => s.questions)
    const titleQuestion = questions.find(q => q.name === QuestionEnum.title)
    const firstNameQuestion = questions.find(q => q.name === QuestionEnum.firstName)
    const lastNameQuestion = questions.find(q => q.name === QuestionEnum.lastName)
    const dateOfBirthQuestion = questions.find(q => q.name === QuestionEnum.dateOfBirth)
    const drivingLicenceQuestion = questions.find(q => q.name === QuestionEnum.drivingLicence) as DrivingLicenceQuestion
    drivingLicenceQuestion.dependentQuestions = [titleQuestion!, firstNameQuestion!, lastNameQuestion!, dateOfBirthQuestion!]

    const employmentStatusQuestion = questions.find(q => q.name === QuestionEnum.employmentStatus) as SelectQuestion<string, string>
    const occupationQuestion = questions.find(q => q.name === QuestionEnum.occupation) as SelectQuestion<string, string>
    const businessCategoryQuestion = questions.find(q => q.name === QuestionEnum.businessCategory) as SelectQuestion<string, string>

    const hasMedicalConditionsQuestion = questions.find(q => q.name === QuestionEnum.hasMedicalConditions) as SelectQuestion<string, string>
    const dvlaReportableMedicalConditionsQuestion = questions.find(q => q.name === QuestionEnum.dvlaReportableMedicalConditions) as SelectQuestion<string, string>
    dvlaReportableMedicalConditionsQuestion.isVisible = () => hasMedicalConditionsQuestion.value !== null && hasMedicalConditionsQuestion.value === DLDCommonValuesEnum.Boolean.Yes

    const residentSinceBirthQuestion = questions.find(q => q.name === QuestionEnum.residentSinceBirth) as SelectQuestion<string, string>
    const residentSinceQuestion = questions.find(q => q.name === QuestionEnum.residentSince) as DateQuestion

    residentSinceQuestion.isVisible = () => residentSinceBirthQuestion.value! === DLDCommonValuesEnum.Boolean.No

    occupationQuestion.isVisible = businessCategoryQuestion.isVisible = () => employmentStatusQuestion.value != null && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.Retired && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.Unemployed

    return page
  }

  private setupCoverDetailsDependencies(page: Page): Page {
    const questions = page.sections.flatMap(s => s.questions)

    const gapAdditionalDriver = questions.find(q => q.name === QuestionEnum.additionalDrivers) as SelectQuestion<string, string>
    const gapNeedCoverForAdditionalDriver = questions.find(q => q.name === QuestionEnum.needCoverForAdditionalDriver) as SelectQuestion<string, string>

    if (gapNeedCoverForAdditionalDriver)
      gapNeedCoverForAdditionalDriver.isVisible = () => gapAdditionalDriver.value !== null && gapAdditionalDriver.value === DLDCommonValuesEnum.Boolean.Yes

    const effectiveDateQuestion = questions.find(q => q.name === QuestionEnum.coverEffectiveDate) as DateQuestion ?? questions.find(q => q.name === QuestionEnum.mtaEffectiveDate) as DateQuestion
    effectiveDateQuestion.minDate = () => moment().toDate()
    effectiveDateQuestion.maxDate = () => moment().add(31, 'days').toDate()

    return page
  }

  protected setupCommonDependencies(page: Page): void {
    const questions = page.sections.flatMap(s => s.questions)
    const residentSinceQuestion = questions.find(q => q.name === QuestionEnum.residentSince) as DateQuestion

    residentSinceQuestion.minDate = () => this.setupDOB(questions).value
    residentSinceQuestion.maxDate = () => moment().toDate()
  }

  protected setupDOB(questions: IQuestion[]): DateQuestion {
    const dateOfBirth = questions.find(q => q.name === QuestionEnum.dateOfBirth) as DateQuestion
    dateOfBirth.maxDate = () => moment().add(-17, 'years').toDate()
    dateOfBirth.focusedDate = () => moment(dateOfBirth.maxDate()).subtract(1, 'years').toDate()
    return dateOfBirth
  }

  private setupGapProposerDependencies(page: Page): Page {
    const questions = page.sections.flatMap(s => s.questions)
    this.setupDOB(questions)
    return page
  }

  private setupProposerDependencies(page: Page): Page {
    this.setupCommonDependencies(page)
    const questions = page.sections.flatMap(s => s.questions)

    const hasMedicalConditionsQuestion = questions.find(q => q.name === QuestionEnum.hasMedicalConditions) as SelectQuestion<string, string>
    const dvlaReportableMedicalConditionsQuestion = questions.find(q => q.name === QuestionEnum.dvlaReportableMedicalConditions) as SelectQuestion<string, string>

    dvlaReportableMedicalConditionsQuestion.isVisible = () => hasMedicalConditionsQuestion.value !== null && hasMedicalConditionsQuestion.value === DLDCommonValuesEnum.Boolean.Yes

    const employmentStatusQuestion = questions.find(q => q.name === QuestionEnum.employmentStatus) as SelectQuestion<string, string>
    const occupationQuestion = questions.find(q => q.name === QuestionEnum.occupation) as SelectQuestion<string, string>
    const businessCategoryQuestion = questions.find(q => q.name === QuestionEnum.businessCategory) as SelectQuestion<string, string>

    occupationQuestion.isVisible = businessCategoryQuestion.isVisible = () => employmentStatusQuestion.value != null
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.Retired
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.Unemployed
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.FullOrPartTimeEducation
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.HouseholdDuties
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.IndependentMeans
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.NotEmployedDueToDisability
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.FinanciallyAssisted

    const titleQuestion = questions.find(q => q.name === QuestionEnum.title)
    const firstNameQuestion = questions.find(q => q.name === QuestionEnum.firstName)
    const lastNameQuestion = questions.find(q => q.name === QuestionEnum.lastName)
    const dateOfBirthQuestion = questions.find(q => q.name === QuestionEnum.dateOfBirth)
    const drivingLicenceQuestion = questions.find(q => q.name === QuestionEnum.drivingLicence) as DrivingLicenceQuestion
    drivingLicenceQuestion.dependentQuestions = [titleQuestion!, firstNameQuestion!, lastNameQuestion!, dateOfBirthQuestion!]

    const hasAdvancedLicenceQuestion = questions.find(q => q.name === QuestionEnum.hasAdvancedDrivingLicence) as SelectQuestion<string, string>
    const advancedDrivingLicenceTypeQuestion = questions.find(q => q.name === QuestionEnum.advancedDrivingLicenceType) as SelectQuestion<string, string>

    advancedDrivingLicenceTypeQuestion.isVisible = () => hasAdvancedLicenceQuestion.value != null && hasAdvancedLicenceQuestion.value === DLDCommonValuesEnum.Boolean.Yes

    const isClubMemberQuestion = questions.find(q => q.name === QuestionEnum.isClubMember) as SelectQuestion<string, string>
    const clubNameQuestion = questions.find(q => q.name === QuestionEnum.clubName) as SelectQuestion<string, string>

    clubNameQuestion.isVisible = () => isClubMemberQuestion.value != null && isClubMemberQuestion.value === DLDCommonValuesEnum.Boolean.Yes

    const residentSinceBirthQuestion = questions.find(q => q.name === QuestionEnum.residentSinceBirth) as SelectQuestion<string, string>
    const residentSinceQuestion = questions.find(q => q.name === QuestionEnum.residentSince) as DateQuestion

    residentSinceQuestion.isVisible = () => residentSinceBirthQuestion.value! === DLDCommonValuesEnum.Boolean.No

    return page
  }

  getSchemeRequest(_questions: IQuestionAnswer[]): SchemeRequest {
    throw new Error('Method not implemented. Wrapper does not use Scheme Requests.')
  }

  validate(_questions: IQuestionAnswer[]): boolean {
    throw new Error('Method not implemented. Wrapper does not use custom validation.')
  }

  buildGapRequest(quoteRequest: GapMTAQuoteRequest | GapQuoteRequest, questions: IQuestionAnswer[]): void {
    // Personal details
    const title = questions.find(q => q.name === QuestionEnum.title) as QuestionAnswer<string>
    const firstName = questions.find(q => q.name === QuestionEnum.firstName) as QuestionAnswer<string>
    const lastName = questions.find(q => q.name === QuestionEnum.lastName) as QuestionAnswer<string>
    const dateOfBirth = questions.find(q => q.name === QuestionEnum.dateOfBirth) as QuestionAnswer<Date>
    const address = questions.find(q => q.name === QuestionEnum.address) as QuestionAnswer<Address>
    const effectiveDateQuestion = questions.find(q => q.name === QuestionEnum.coverEffectiveDate || q.name === QuestionEnum.mtaEffectiveDate) as QuestionAnswer<Date>
    // Vehicle details
    const currentMileage = questions.find(q => q.name === QuestionEnum.currentMileage) as QuestionAnswer<number>
    const invoiceValue = questions.find(q => q.name === QuestionEnum.purchasePrice) as QuestionAnswer<number>
    const vehicleType = questions.find(q => q.name === QuestionEnum.gapVehicleType) as QuestionAnswer<string>
    const gapDamagedBeyondRepair = questions.find(q => q.name === QuestionEnum.gapDamagedBeyondRepair) as QuestionAnswer<string>
    const paymentQuestion = questions.find(q => q.name === QuestionEnum.gapVehiclePaymentType) as QuestionAnswer<string>
    const vehiclePurchaseFrom = questions.find(q => q.name === QuestionEnum.gapVehiclePurchaseType) as QuestionAnswer<string>
    const vehicleCollectionDate = questions.find(q => q.name === QuestionEnum.vehicleCollectionDate) as QuestionAnswer<Date>
    const confirmCommercialType = questions.find(q => q.name === QuestionEnum.confirmVehicleUse) as QuestionAnswer<string>
    const confirmRegisteredKeeper = questions.find(q => q.name === QuestionEnum.confirmRegisteredKeeper) as QuestionAnswer<string>
    // About your cover
    const confirmFullyComp = questions.find(q => q.name === QuestionEnum.confirmFullyComp) as QuestionAnswer<string>
    const noClaimsBonus = questions.find(q => q.name === QuestionEnum.noClaimsBonus) as QuestionAnswer<string>
    const coverTerm = questions.find(q => q.name === QuestionEnum.coverTerm) as QuestionAnswer<string>
    const needCoverForAdditionalDriver = questions.find(q => q.name === QuestionEnum.needCoverForAdditionalDriver) as QuestionAnswer<string>
    const includeCoverForVehicleStolen = questions.find(q => q.name === QuestionEnum.includeCoverForVehicleStolen) as QuestionAnswer<string>
    const additionalDrivers = questions.find(q => q.name === QuestionEnum.additionalDrivers) as QuestionAnswer<string>
    const includeCoverForAddedExtra = questions.find(q => q.name === QuestionEnum.includeCoverForAddedExtra) as QuestionAnswer<string>

    quoteRequest.address = address.value!
    quoteRequest.car = this.bindCar(questions)
    quoteRequest.proposer.title = title.value!
    quoteRequest.proposer.firstName = firstName.value!
    quoteRequest.proposer.lastName = lastName.value!
    quoteRequest.proposer.dateOfBirth = dateOfBirth.value!
    quoteRequest.effectiveDate = effectiveDateQuestion.value!
    quoteRequest.currentMileage = currentMileage.value!
    quoteRequest.invoiceValue = invoiceValue.value!
    quoteRequest.gapVehicleType = vehicleType.value!
    quoteRequest.vehicleCollectionDate = vehicleCollectionDate?.visible ? vehicleCollectionDate.value! : new Date()
    quoteRequest.vehiclePaymentType = paymentQuestion.value!
    quoteRequest.vehiclePurchaseFrom = vehiclePurchaseFrom.value!
    quoteRequest.noClaimsBonus = this.getNoClaimsYears(noClaimsBonus.value!)
    quoteRequest.coverTerm = coverTerm.value!
    quoteRequest.gapDamagedBeyondRepair = gapDamagedBeyondRepair.value! === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.confirmCommercialType = confirmCommercialType.value!
    quoteRequest.confirmRegisteredKeeper = confirmRegisteredKeeper.value!
    quoteRequest.includeCoverForAddedExtra = includeCoverForAddedExtra.value! === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.additionalDrivers = additionalDrivers.value! === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.needCoverForAdditionalDriver = additionalDrivers.value! === DLDCommonValuesEnum.Boolean.Yes ? (needCoverForAdditionalDriver.value! === DLDCommonValuesEnum.Boolean.Yes) : null
    quoteRequest.confirmFullyComp = confirmFullyComp.value!
    quoteRequest.includeCoverForVehicleStolen = includeCoverForVehicleStolen.value! === DLDCommonValuesEnum.Boolean.Yes
  }

  buildCarRequest(quoteRequest: CarMTAQuoteRequest | CarQuoteRequest, questions: IQuestionAnswer[]): void {
    // Vehicle details
    const vehicleEstimatedMileage = questions.find(q => q.name === QuestionEnum.vehicleEstimatedMileage) as QuestionAnswer<number>

    // Personal details
    const title = questions.find(q => q.name === QuestionEnum.title) as QuestionAnswer<string>
    const firstName = questions.find(q => q.name === QuestionEnum.firstName) as QuestionAnswer<string>
    const lastName = questions.find(q => q.name === QuestionEnum.lastName) as QuestionAnswer<string>
    const dateOfBirth = questions.find(q => q.name === QuestionEnum.dateOfBirth) as QuestionAnswer<Date>
    const residentSince = questions.find(q => q.name === QuestionEnum.residentSince) as QuestionAnswer<Date>
    const residentSinceBirth = questions.find(q => q.name === QuestionEnum.residentSinceBirth) as QuestionAnswer<string>
    const drivingLicence = questions.find(q => q.name === QuestionEnum.drivingLicence) as QuestionAnswer<string>
    const address = questions.find(q => q.name === QuestionEnum.address) as QuestionAnswer<Address>
    const employmentStatus = questions.find(q => q.name === QuestionEnum.employmentStatus) as QuestionAnswer<string>
    const occupation = questions.find(q => q.name === QuestionEnum.occupation) as QuestionAnswer<string>
    const businessCategory = questions.find(q => q.name === QuestionEnum.businessCategory) as QuestionAnswer<string>
    const noClaimsProtection = questions.find(q => q.name === QuestionEnum.noClaimsProtection) as QuestionAnswer<string>
    const hasAdvancedDrivingLicence = questions.find(q => q.name === QuestionEnum.hasAdvancedDrivingLicence) as QuestionAnswer<string>
    const advancedDrivingLicenceType = questions.find(q => q.name === QuestionEnum.advancedDrivingLicenceType) as QuestionAnswer<string>
    const noClaimsBonus = questions.find(q => q.name === QuestionEnum.noClaimsBonus) as QuestionAnswer<string>
    const isOwnersClubMemeber = questions.find(q => q.name === QuestionEnum.isClubMember) as QuestionAnswer<string>
    const club = questions.find(q => q.name === QuestionEnum.clubName) as QuestionAnswer<string>
    const maritalStatus = questions.find(q => q.name === QuestionEnum.maritalStatus) as QuestionAnswer<string>
    const hasMedicalConditionsQuestion = questions.find(q => q.name === QuestionEnum.hasMedicalConditions) as QuestionAnswer<string>
    const dvlaReportableMedicalConditionsQuestion = questions.find(q => q.name === QuestionEnum.dvlaReportableMedicalConditions) as QuestionAnswer<string>
    const numberOfOtherVehiclesAvailable = questions.find(q => q.name === QuestionEnum.numberOfOtherVehiclesAvailable) as QuestionAnswer<string>
    const drivingOtherCarsOnOtherPolicy = questions.find(q => q.name === QuestionEnum.hasAccessToAnotherVehicle) as QuestionAnswer<string>
    const numberOfHouseholdVehicles = questions.find(q => q.name === QuestionEnum.numberOfHouseholdVehicles) as QuestionAnswer<number>
    const dvlaMotorhome = questions.find(q => q.name === QuestionEnum.dvlaMotorhome) as QuestionAnswer<string>
    const conversionCompleted = questions.find(q => q.name === QuestionEnum.conversionCompleted) as QuestionAnswer<string>
    const modifiedEngineDriveTrain = questions.find(q => q.name === QuestionEnum.modifiedEngineDriveTrain) as QuestionAnswer<string>
    const registeredKeeper = questions.find(q => q.name === QuestionEnum.registeredKeeper) as QuestionAnswer<string>
    const registeredOwner = questions.find(q => q.name === QuestionEnum.vehicleOwner) as QuestionAnswer<string>
    const beenRequestedExtraPrecautions = questions.find(q => q.name === QuestionEnum.beenRequestedExtraPrecautions) as QuestionAnswer<string>
    const mainUser = questions.find(q => q.name === QuestionEnum.mainUser) as QuestionAnswer<string>
    const primaryTransport = questions.find(q => q.name === QuestionEnum.primaryTransport) as QuestionAnswer<string>
    const liveInVehicleFullTime = questions.find(q => q.name === QuestionEnum.liveInVehicleFullTime) as QuestionAnswer<string>
    const hadInsuranceRefused = questions.find(q => q.name === QuestionEnum.hadInsuranceRefused) as QuestionAnswer<string>
    const hasConvictions = questions.find(q => q.name === QuestionEnum.hasClaims) as QuestionAnswer<string>
    const voluntaryExcess = questions.find(q => q.name === QuestionEnum.voluntaryExcess) as QuestionAnswer<string>

    if (employmentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.Retired) {
      if (occupation)
        occupation.value = DLDCommonValuesEnum.Occupation.Retired
      if (businessCategory)
        businessCategory.value = DLDCommonValuesEnum.BusinessCategory.NonRetired
    }
    else if (employmentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.Unemployed) {
      if (occupation)
        occupation.value = DLDCommonValuesEnum.Occupation.Unemployed
      if (businessCategory)
        businessCategory.value = DLDCommonValuesEnum.BusinessCategory.NonUnemployed
    }
    else if (employmentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.FullOrPartTimeEducation) {
      if (occupation)
        occupation.value = DLDCommonValuesEnum.Occupation.Student
      if (businessCategory)
        businessCategory.value = DLDCommonValuesEnum.BusinessCategory.NoneStudent
    }
    else if (employmentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.FinanciallyAssisted) {
      if (occupation)
        occupation.value = DLDCommonValuesEnum.Occupation.NotEmployedDueToDisability
      if (businessCategory)
        businessCategory.value = DLDCommonValuesEnum.BusinessCategory.NoneNotEmployedduetoDisability
    }
    else if (employmentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.HouseholdDuties) {
      if (occupation)
        occupation.value = DLDCommonValuesEnum.Occupation.Housewife
      if (businessCategory)
        businessCategory.value = DLDCommonValuesEnum.BusinessCategory.NoneHouseholdDuties
    }
    else if (employmentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.IndependentMeans) {
      if (occupation)
        occupation.value = DLDCommonValuesEnum.Occupation.NotInEmployment
      if (businessCategory)
        businessCategory.value = DLDCommonValuesEnum.BusinessCategory.NotInEmployment
    }
    else if (employmentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.NotEmployedDueToDisability) {
      if (occupation)
        occupation.value = DLDCommonValuesEnum.Occupation.NotEmployedDueToDisability
      if (businessCategory)
        businessCategory.value = DLDCommonValuesEnum.BusinessCategory.NoneNotEmployedduetoDisability
    }

    // Claims
    const claims = this.getClaims(questions)

    // Addtional drivers
    const additionalDrivers = questions.find(q => q.name === QuestionEnum.additionalDriver) as QuestionAnswer<number>
    const adDrivers: AdditionalDriver[] = []

    if (additionalDrivers) {
      for (let i = 0; i < additionalDrivers.subPageAnswers.length; i += 1) {
        const adTitle = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.title) as QuestionAnswer<string>
        const adFirstName = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.firstName) as QuestionAnswer<string>
        const adLastName = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.lastName) as QuestionAnswer<string>
        const adDateOfBirth = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.dateOfBirth) as QuestionAnswer<Date>
        const adDrivingLicence = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.drivingLicence) as QuestionAnswer<string>
        const adRelationshipToPolicyholder = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.relationshipToPolicyHolder) as QuestionAnswer<string>
        const adEmploymentStatus = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.employmentStatus) as QuestionAnswer<string>
        const adOccupation = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.occupation) as QuestionAnswer<string>
        const adBusinessCategory = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.businessCategory) as QuestionAnswer<string>
        const adClassOfUse = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.classOfUse) as QuestionAnswer<string>
        const adResidentSince = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.residentSince) as QuestionAnswer<Date>
        const adResidentSinceBirth = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.residentSinceBirth) as QuestionAnswer<string>
        const adMaritalStatus = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.maritalStatus) as QuestionAnswer<string>
        const adHasMedicalConditionsQuestion = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.hasMedicalConditions) as QuestionAnswer<string>
        const adDvlaReportableMedicalConditionsQuestion = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.dvlaReportableMedicalConditions) as QuestionAnswer<string>
        const adPrn = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.PRN) as QuestionAnswer<number>

        if (adEmploymentStatus.value === DLDCommonValuesEnum.EmploymentStatus.Retired) {
          adOccupation.value = DLDCommonValuesEnum.Occupation.Retired
          adBusinessCategory.value = DLDCommonValuesEnum.BusinessCategory.NonRetired
        }
        else if (adEmploymentStatus.value === DLDCommonValuesEnum.EmploymentStatus.Unemployed) {
          adOccupation.value = DLDCommonValuesEnum.Occupation.Unemployed
          adBusinessCategory.value = DLDCommonValuesEnum.BusinessCategory.NonUnemployed
        }

        adDrivers.push({
          title: adTitle.value!,
          firstName: adFirstName.value!,
          lastName: adLastName.value!,
          dateOfBirth: adDateOfBirth.value!,
          drivingLicence: adDrivingLicence.value!,
          licenceType: DLDCommonValuesEnum.CarLicenceType.FullUK,
          relationshiptoPolicyholder: adRelationshipToPolicyholder.value!,
          employmentStatus: adEmploymentStatus.value!,
          occupation: adOccupation.value!,
          businessCategory: adBusinessCategory.value!,
          residentSince: adResidentSinceBirth.value! === DLDCommonValuesEnum.Boolean.Yes ? adDateOfBirth.value! : adResidentSince.value!,
          classOfUse: adClassOfUse.value!,
          maritalStatus: adMaritalStatus.value!,
          hasMedicalConditions: adHasMedicalConditionsQuestion.value! === DLDCommonValuesEnum.Boolean.Yes,
          dvlaReportableMedicalConditions: adDvlaReportableMedicalConditionsQuestion.value,
          claims: this.getClaims(additionalDrivers.subPageAnswers[i].answers),
          prn: adPrn?.value ?? null,
          farePayingPassengers: null,
          licenceDate: null,
          otherLicensingAuthorities: '',
          mainLicensingAuthority: '',
          rideSharingApp: null,
          secondaryOccupation: null,
          taxiBadgeHeldDate: null,
          hasHadPolicyDeclaredVoid: null,
          otherVehiclesOwned: true,
          hasConvictions: null,
          liveInVehicleFullTime: null,
          hadInsuranceRefused: null,
          drivingFrequency: null,
          hasCriminalConvictions: null,
          hasSecondaryEmployment: null,
          secondaryEmploymentStatus: null,
          secondaryBusinessCategory: null,
          hasHadPreviousPolicyCancelled: null,
          hasClaims: null,
          convictions: [],
          medicalConditions: [],
          voidReason: null,
          residentSinceBirth: null,
          restrictedLicense: null,
          hasCCJs: null,
          restrictedLicenseType: null,
          psvLicenceNumber: null,
          psvLicenceDate: null,
          operatingInLondonArea: null,
          otherVehiclesYouDrive: null,
          useOfAnotherCar: null,
          pSVOLicenceNumber: null,
          pSVONamedProposer: null,
          hasPSVOLicence: null,
          pSVONamedProposerReason: null,
          noPSVOLicenseReason: null,
          address: null,
        })
      }
    }

    // Cover details
    const classOfUse = questions.find(q => q.name === QuestionEnum.classOfUse) as QuestionAnswer<string>
    const coverType = questions.find(q => q.name === QuestionEnum.coverType) as QuestionAnswer<string>
    const effectiveDateQuestion = questions.find(q => q.name === QuestionEnum.coverEffectiveDate || q.name === QuestionEnum.mtaEffectiveDate) as QuestionAnswer<Date>

    quoteRequest.car = this.bindCar(questions)
    quoteRequest.car.registeredKeeper = registeredKeeper.value!
    quoteRequest.car.registeredOwner = registeredOwner.value!
    quoteRequest.mileage = vehicleEstimatedMileage.value!

    quoteRequest.proposer = new CarProposer()
    quoteRequest.proposer.title = title.value!
    quoteRequest.proposer.firstName = firstName.value!
    quoteRequest.proposer.lastName = lastName.value!
    quoteRequest.proposer.dateOfBirth = dateOfBirth.value!
    quoteRequest.proposer.drivingLicence = drivingLicence!.value!
    quoteRequest.proposer.licenceType = DLDCommonValuesEnum.CarLicenceType.FullUK
    quoteRequest.proposer.employmentStatus = employmentStatus.value!
    quoteRequest.proposer.occupation = occupation.value!
    quoteRequest.proposer.businessCategory = businessCategory.value!
    quoteRequest.proposer.address = address.value!
    quoteRequest.proposer.claims = claims

    quoteRequest.proposer.advancedDrivingLicence = hasAdvancedDrivingLicence.value! === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.proposer.advancedDrivingLicenceType = hasAdvancedDrivingLicence.value! === DLDCommonValuesEnum.Boolean.Yes ? advancedDrivingLicenceType.value! : null
    quoteRequest.proposer.isOwnersClubMember = isOwnersClubMemeber.value! === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.proposer.ownersClub = club.value!
    quoteRequest.proposer.maritalStatus = maritalStatus.value!
    quoteRequest.proposer.noClaimsYears = this.getNoClaimsYears(noClaimsBonus.value!)
    quoteRequest.proposer.hasMedicalConditions = hasMedicalConditionsQuestion.value! === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.proposer.otherVehiclesOwned = true
    quoteRequest.proposer.dvlaReportableMedicalConditions = hasMedicalConditionsQuestion.value! === DLDCommonValuesEnum.Boolean.Yes ? dvlaReportableMedicalConditionsQuestion.value! : null
    quoteRequest.proposer.numberOfOtherVehiclesAvailable = numberOfOtherVehiclesAvailable.value!
    quoteRequest.proposer.numberOfHouseholdVehicles = numberOfHouseholdVehicles.value!
    quoteRequest.proposer.beenRequestedExtraPrecautions = beenRequestedExtraPrecautions.value! === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.proposer.drivingFrequency = mainUser.value! === DLDCommonValuesEnum.Boolean.Yes ? DLDCommonValuesEnum.DrivingFrequency.Main : DLDCommonValuesEnum.DrivingFrequency.NotAvailable
    quoteRequest.proposer.primaryTransport = primaryTransport.value! === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.proposer.liveInVehicleFullTime = liveInVehicleFullTime.value! === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.proposer.hadInsuranceRefused = hadInsuranceRefused.value! === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.proposer.hasConvictions = hasConvictions.value! === DLDCommonValuesEnum.Boolean.Yes

    quoteRequest.registeredDVLAMotorhome = dvlaMotorhome.value! === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.conversionCompleted = conversionCompleted.value! === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.modifiedEngineOrDrive = modifiedEngineDriveTrain.value! === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.drivingOtherCarsOnOtherPolicy = drivingOtherCarsOnOtherPolicy.value!
    quoteRequest.address = address.value!
    quoteRequest.additionalDrivers = adDrivers
    quoteRequest.classOfUse = classOfUse.value!
    quoteRequest.effectiveDate = effectiveDateQuestion.value!

    quoteRequest.voluntaryExcess = voluntaryExcess?.value ?? DLDCommonValuesEnum.VolXs.VolXs0
    quoteRequest.coverType = coverType.value!
    quoteRequest.noClaimsProtection = noClaimsProtection && noClaimsProtection.value!
    quoteRequest.proposer.residentSince = residentSinceBirth.value === DLDCommonValuesEnum.Boolean.Yes ? dateOfBirth.value! : residentSince.value
  }

  getQuoteRequest<T extends QuoteRequest>(questions: IQuestionAnswer[], Type: new () => T): T {
    const quoteRequest = new Type()
    if (quoteRequest instanceof CarQuoteRequest || quoteRequest instanceof CarMTAQuoteRequest)
      this.buildCarRequest(quoteRequest, questions)
    else if (quoteRequest instanceof GapQuoteRequest || quoteRequest instanceof GapMTAQuoteRequest)
      this.buildGapRequest(quoteRequest, questions)
    else
      throw new Error(`Unsupported quote request (${typeof Type}) for Wrapper.`)

    return quoteRequest
  }

  private bindCar(questions: IQuestionAnswer[]): Car {
    const vehicleLookup = questions.find(q => q.name === QuestionEnum.vehicleLookup) as QuestionAnswer<Vehicle>
    const vehicleOvernightParkLocation = questions.find(q => q.name === QuestionEnum.vehicleOvernightParkLocation) as QuestionAnswer<string>
    const rightHandDrive = questions.find(q => q.name === QuestionEnum.isVehicleRightHandDrive) as QuestionAnswer<string>
    const vehicleSeats = questions.find(q => q.name === QuestionEnum.vehicleSeats) as QuestionAnswer<number>
    const vehicleTransmission = questions.find(q => q.name === QuestionEnum.vehicleTransmission) as QuestionAnswer<string>
    const alarmType = questions.find(q => q.name === QuestionEnum.alarmType) as QuestionAnswer<string>
    const trackerType = questions.find(q => q.name === QuestionEnum.trackerType) as QuestionAnswer<string>
    const motorhomeType = questions.find(q => q.name === QuestionEnum.motorhomeType) as QuestionAnswer<string>
    const unknownMotorhomeType = questions.find(q => q.name === QuestionEnum.unknownMotorhomeType) as QuestionAnswer<string>
    const presentValue = questions.find(q => q.name === QuestionEnum.presentValue) as QuestionAnswer<number>
    const vehicleFuelType = questions.find(q => q.name === QuestionEnum.fuelType) as QuestionAnswer<string>

    const car = new Car()

    car.registration = vehicleLookup.value!.registration
    car.value = presentValue?.visible ? presentValue.value! : vehicleLookup.value!.value < 1 ? 1 : vehicleLookup.value!.value
    car.value = vehicleLookup.value!.value
    car.overnightParkLocation = vehicleOvernightParkLocation?.value
    car.purchaseDate = vehicleLookup.value!.purchaseDate
    car.yearOfManufacture = vehicleLookup.value!.yearOfManufacture
    car.keptAtHomeAddress = true
    car.registeredKeeper = DLDCommonValuesEnum.RegisteredKeeper.ProposerPolicyHolder
    car.registeredOwner = DLDCommonValuesEnum.RegisteredKeeper.ProposerPolicyHolder
    car.fuelType = vehicleFuelType?.value || vehicleLookup.value!.fuelType || null
    car.transmission = vehicleTransmission?.value || vehicleLookup.value?.transmissionType || null

    // Todo - get these question values from updated vehicle lookup
    car.cubicCapacity = vehicleLookup.value!.engineSize
    car.bodyType = vehicleLookup.value!.bodyType
    car.make = vehicleLookup.value!.make
    car.model = vehicleLookup.value!.model

    car.leftHandDrive = rightHandDrive?.value === DLDCommonValuesEnum.Boolean.No
    car.imported = false
    car.noOfSeats = vehicleSeats?.value || null
    car.alarmType = alarmType?.value || null
    car.trackerType = trackerType?.value || null
    car.motorhomeType = motorhomeType?.value || null
    car.unknownMotorhomeType = unknownMotorhomeType?.value || null

    return car
  }

  private getClaims(questions: IQuestionAnswer[]): Claim[] {
    const claimsQuestion = questions.find(q => q.name === QuestionEnum.claims) as QuestionAnswer<number>
    const claims: Claim[] = []

    if (claimsQuestion) {
      for (let i = 0; i < claimsQuestion.subPageAnswers.length; i += 1) {
        const claimDate = claimsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.claimDate) as QuestionAnswer<Date>
        const claimType = claimsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.claimType) as QuestionAnswer<string>
        const claimStatus = claimsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.claimStatus) as QuestionAnswer<string>
        const claimFault = claimsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.claimFault) as QuestionAnswer<string>
        const claimPersonsInjured = claimsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.claimPersonsInjured) as QuestionAnswer<string>
        const claimCost = claimsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.claimCost) as QuestionAnswer<number>
        const ncbLost = claimsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.ncbLost) as QuestionAnswer<string>

        claims.push({
          claimDate: claimDate.value!,
          incidentDate: null,
          claimType: claimType.value!,
          claimStatus: claimStatus.value!,
          claimFault: claimFault.value! === DLDCommonValuesEnum.Boolean.Yes,
          claimPersonsInjured: claimPersonsInjured.value! === DLDCommonValuesEnum.Boolean.Yes,
          claimCost: claimCost.value!,
          ncbLost: ncbLost.value! === DLDCommonValuesEnum.Boolean.Yes,
        })
      }
    }
    return claims
  }

  getMTARequest<T extends QuoteRequest>(questions: IQuestionAnswer[], Type: new () => T): T {
    const mtaRequest = this.getQuoteRequest(questions, Type)
    if (!(mtaRequest instanceof CarMTAQuoteRequest) && !(mtaRequest instanceof GapMTAQuoteRequest))
      throw new Error(`Unsupported quote request (${typeof mtaRequest}) for Wrapper.`)

    return mtaRequest
  }

  mapRiskToAnswers(risk: Risk, account: Account, pages: Page[]): Dictionary<PageAnswers> {
    switch (true) {
      case risk instanceof GapRisk: {
        const gapRisk = risk as GapRisk
        return this.mapGapRiskToAnswers(gapRisk, account, pages)
      }
      case risk instanceof CarRisk: {
        const carRisk = risk as CarRisk
        return this.mapCarRiskToAnswers(carRisk, account, pages)
      }
      default:
        throw new Error('Unsupported risk type for Wrapper.')
    }
  }

  mapCarRiskToAnswers(risk: CarRisk, account: Account, pages: Page[]): Dictionary<PageAnswers> {
    const pageAnswers: Dictionary<PageAnswers> = {}

    this.mapAccountToAnswers(pageAnswers, account, pages)

    const vehicle = new Vehicle()
    vehicle.registration = risk.vehicle.registrationNumber
    vehicle.abiCode = risk.vehicle.car?.abiCode
    vehicle.make = risk.vehicle.car?.makeDescription
    vehicle.model = risk.vehicle.car?.modelDescription
    vehicle.value = risk.vehicle.value
    vehicle.purchaseDate = risk.vehicle.purchaseDate
    vehicle.yearOfManufacture = risk.vehicle.yearOfManufacture
    vehicle.bodyType = risk.vehicle.bodyType
    vehicle.transmissionType = risk.vehicle.vehicleTransmission?.uniqueId
    vehicle.fuelType = risk.vehicle.fuelType?.uniqueId
    vehicle.engineSize = risk.vehicle.cubicCapacity

    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleLookup, vehicle, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.title, risk.proposer.title.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.firstName, risk.proposer.firstName, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.lastName, risk.proposer.lastName, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.dateOfBirth, risk.proposer.dateOfBirth, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.address, risk.proposer.address, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.drivingLicence, risk.proposer.drivingLicence, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleEstimatedMileage, risk.vehicle.annualMileage, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleBusinessMileage, risk.vehicle.businessMileage, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.registeredKeeper, risk.vehicle.registeredKeeper.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleOwner, risk.vehicle.registeredOwner.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleOvernightParkLocation, risk.vehicle.overnightParkLocation.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.employmentStatus, risk.proposer.employmentStatus.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.occupation, risk.proposer.occupation.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.businessCategory, risk.proposer.businessCategory.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.classOfUse, risk.classOfUse.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.coverType, risk.coverType.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.referralSource, risk.businessSource?.id, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.coverEffectiveDate, risk.effectiveDate, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.voluntaryExcess, risk.voluntaryExcess.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.noClaimsProtection, risk.noClaimsProtection ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.numberOfOtherVehiclesAvailable, risk.proposer.numberOfOtherVehiclesAvailable?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.conversionCompleted, risk.vehicle.conversionCompleted ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.modifiedEngineDriveTrain, risk.vehicle.modifiedEngineOrDrive ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.dvlaMotorhome, risk.vehicle.registeredDVLAMotorhome ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)

    this.createAnswerForQuestion(pageAnswers, QuestionEnum.hasClaims, risk.proposer.hasConvictions ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.liveInVehicleFullTime, risk.proposer.liveInVehicleFullTime ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.hadInsuranceRefused, risk.proposer.hadInsuranceRefused ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.mainUser, risk.proposer.drivingFrequency.uniqueId === DLDCommonValuesEnum.DrivingFrequency.Main ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.primaryTransport, risk.proposer.primaryTransport ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.beenRequestedExtraPrecautions, risk.proposer.beenRequestedExtraPrecautions ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)

    this.createAnswerForQuestion(pageAnswers, QuestionEnum.isVehicleRightHandDrive, risk.vehicle.leftHandDrive ? DLDCommonValuesEnum.Boolean.No : DLDCommonValuesEnum.Boolean.Yes, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.cubicCapacity, risk.vehicle.cubicCapacity, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.motorhomeType, risk.vehicle.motorhomeType, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.unknownMotorhomeType, risk.vehicle.unknownMotorhomeType, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleImported, risk.vehicle.imported ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.hasAdvancedDrivingLicence, risk.proposer.hasAdvancedDrivingLicence ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.advancedDrivingLicenceType, risk.proposer.advancedDrivingLicenceType?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.noClaimsBonus, this.getNoClaimsYearsDLD(risk.proposer.noClaimsBonus), pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.isClubMember, risk.proposer.isOwnersClubMember ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.clubName, risk.proposer.ownersClub?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.residentSinceBirth, risk.proposer.residentSinceBirth ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.residentSince, risk.proposer.residentSince, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.maritalStatus, risk.proposer.maritalStatus.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.hasMedicalConditions, risk.proposer.hasMedicalConditions ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.dvlaReportableMedicalConditions, risk.proposer.dvlaReportableMedicalConditions?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleSeats, risk.vehicle.noOfSeats, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleTransmission, risk.vehicle.vehicleTransmission?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.alarmType, risk.vehicle.alarmType?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.trackerType, risk.vehicle.trackerType?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.hasAccessToAnotherVehicle, risk.drivingOtherCarsOnOtherPolicy ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.numberOfHouseholdVehicles, risk.proposer.numberOfHouseholdVehicles, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.presentValue, vehicle.value, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.fuelType, risk.vehicle.fuelType?.uniqueId, pages)

    this.mapClaims(pageAnswers, pages, risk.proposer.claims)

    const additionalDrivers = this.createAnswerForQuestion(pageAnswers, QuestionEnum.additionalDriver, risk.additionalDrivers.length, pages)!
    additionalDrivers.forEach((ada) => {
      for (let i = 0; i < risk.additionalDrivers.length; i += 1) {
        const ad = risk.additionalDrivers[i]
        const adTitle = new QuestionAnswer<string>(0, QuestionEnum.title, '', ad.title.uniqueId)
        const adFirstName = new QuestionAnswer<string>(0, QuestionEnum.firstName, '', ad.firstName)
        const adLastName = new QuestionAnswer<string>(0, QuestionEnum.lastName, '', ad.lastName)
        const adDateOfBirth = new QuestionAnswer<Date>(0, QuestionEnum.dateOfBirth, '', ad.dateOfBirth)
        const adDrivingLicence = new QuestionAnswer<string>(0, QuestionEnum.drivingLicence, '', ad.drivingLicence)
        const adRelationshipToPolicyholder = new QuestionAnswer<string>(0, QuestionEnum.relationshipToPolicyHolder, '', ad.relationshipToPolicyholder.uniqueId)
        const adEmploymentStatus = new QuestionAnswer<string>(0, QuestionEnum.employmentStatus, '', ad.employmentStatus.uniqueId)
        const adMaritalStatus = new QuestionAnswer<string>(0, QuestionEnum.maritalStatus, '', ad.maritalStatus.uniqueId)
        const adOccupation = new QuestionAnswer<string>(0, QuestionEnum.occupation, '', ad.occupation.uniqueId)
        const adBusinessCategory = new QuestionAnswer<string>(0, QuestionEnum.businessCategory, '', ad.businessCategory.uniqueId)
        const adResidentSinceBirth = new QuestionAnswer<string>(0, QuestionEnum.residentSinceBirth, '', ad.residentSinceBirth ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const adResidentSince = new QuestionAnswer<Date>(0, QuestionEnum.residentSince, '', ad.residentSince)
        const adClassOfUse = new QuestionAnswer<string>(0, QuestionEnum.classOfUse, '', ad.classOfUse.uniqueId)
        const adHasMedicalConditions = new QuestionAnswer<string>(0, QuestionEnum.hasMedicalConditions, '', ad.hasMedicalConditions ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const adDVLAReportableMedicalConditions = new QuestionAnswer<string>(0, QuestionEnum.dvlaReportableMedicalConditions, '', ad.dvlaReportableMedicalConditions?.uniqueId)
        const adPrn = new QuestionAnswer<number>(0, QuestionEnum.PRN, '', ad.prn, false)

        const adClaims = new QuestionAnswer<number>(0, QuestionEnum.claims, '', ad.claims.length)
        const claimsAnswers: Dictionary<PageAnswers> = {}
        const claims = this.mapClaims(claimsAnswers, pages, ad.claims)
        adClaims.subPageAnswers = (claims[0] as QuestionAnswer<number>).subPageAnswers
        const adPage = new PageAnswers(`Additional Driver ${i + 1}`, [adTitle, adFirstName, adLastName, adDateOfBirth, adDrivingLicence, adRelationshipToPolicyholder, adEmploymentStatus, adOccupation, adBusinessCategory, adResidentSinceBirth, adResidentSince, adClassOfUse, adMaritalStatus, adHasMedicalConditions, adDVLAReportableMedicalConditions, adClaims, adPrn])
        ada.subPageAnswers.push(adPage)
      }
    })

    return pageAnswers
  }

  mapGapRiskToAnswers(risk: GapRisk, account: Account, pages: Page[]): Dictionary<PageAnswers> {
    const pageAnswers: Dictionary<PageAnswers> = {}
    this.mapAccountToAnswers(pageAnswers, account, pages)

    const vehicle = new Vehicle()
    vehicle.registration = risk.vehicle.registrationNumber
    vehicle.abiCode = risk.vehicle.car?.abiCode
    vehicle.make = risk.vehicle.car?.makeDescription
    vehicle.model = risk.vehicle.car?.modelDescription
    vehicle.value = risk.vehicle.invoiceValue
    vehicle.purchaseDate = risk.vehicle.vehiclePurchaseDate
    vehicle.yearOfManufacture = risk.vehicle.modelYear
    vehicle.bodyType = risk.vehicle.model
    vehicle.engineSize = risk.vehicle.engineSize
    vehicle.fuelType = risk.vehicle.fuelType?.uniqueId
    vehicle.transmissionType = risk.vehicle.transmission?.uniqueId

    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleLookup, vehicle, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.title, risk.proposer.title.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.firstName, risk.proposer.firstName, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.lastName, risk.proposer.lastName, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.dateOfBirth, risk.proposer.dateOfBirth, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.address, risk.proposer.address, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.gapVehicleType, risk.vehicle.vehicleType?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.purchasePrice, risk.vehicle.invoiceValue, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.currentMileage, risk.vehicle.currentMileage, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehiclePurchaseDate, risk.vehicle.vehiclePurchaseDate, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.gapVehiclePurchaseType, risk.vehicle.vehiclePurchaseFrom?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.gapVehiclePaymentType, risk.vehicle.vehiclePaymentType?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.confirmVehicleUse, risk.demands?.confirmCommercialType?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.confirmRegisteredKeeper, risk.demands?.confirmRegisteredKeeper?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.coverEffectiveDate, risk.effectiveDate, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.confirmFullyComp, risk.demands?.confirmFullyComp?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.noClaimsBonus, this.getNoClaimsYearsDLD(risk.proposer.noClaimDiscount!), pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.coverTerm, risk.coverTerm?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.additionalDrivers, risk.proposer.hasAdditionalApplicant ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleCollectionDate, risk.vehicle.vehicleCollectionDate, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.needCoverForAdditionalDriver, risk.proposer.needCoverForAdditionalDriver ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.includeCoverForVehicleStolen, risk.demands?.includeCoverForVehicleStolen ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.includeCoverForAddedExtra, risk.demands?.includeCoverForAddedExtra ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleDeliveryTaken, DLDCommonValuesEnum.Boolean.Yes, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.gapDamagedBeyondRepair, risk.demands.needOutstandingFinance ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)

    return pageAnswers
  }

  private mapClaims(pageAnswers: Dictionary<PageAnswers>, pages: Page[], riskClaims: ClaimModel[]) {
    const claims = this.createAnswerForQuestion(pageAnswers, QuestionEnum.claims, riskClaims.length, pages)!
    claims.forEach((c) => {
      for (let i = 0; i < riskClaims.length; i += 1) {
        const claim = riskClaims[i]
        const claimDate = new QuestionAnswer<Date>(0, QuestionEnum.claimDate, '', claim.claimDate)
        const claimType = new QuestionAnswer<string>(0, QuestionEnum.claimType, '', claim.claimType.uniqueId)
        const claimStatus = new QuestionAnswer<string>(0, QuestionEnum.claimStatus, '', claim.claimStatus.uniqueId)
        const claimFault = new QuestionAnswer<string>(0, QuestionEnum.claimFault, '', claim.claimFault ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const claimPersonsInjured = new QuestionAnswer<string>(0, QuestionEnum.claimPersonsInjured, '', claim.claimPersonsInjured ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const claimCost = new QuestionAnswer<number>(0, QuestionEnum.claimCost, '', claim.claimCost)
        const ncbLost = new QuestionAnswer<string>(0, QuestionEnum.ncbLost, '', claim.ncbLost ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)

        const claimPage = new PageAnswers(`Claim ${i + 1}`, [claimDate, claimType, claimStatus, claimFault, claimPersonsInjured, claimCost, ncbLost])
        c.subPageAnswers.push(claimPage)
      }
    })
    return claims
  }

  getAccountRegisterRequest(questions: IQuestionAnswer[], affiliateId: number | null): AccountRegister | null {
    const title = questions.find(q => q.name === QuestionEnum.title) as QuestionAnswer<string>
    const firstName = questions.find(q => q.name === QuestionEnum.firstName) as QuestionAnswer<string>
    const lastName = questions.find(q => q.name === QuestionEnum.lastName) as QuestionAnswer<string>
    const email = questions.find(q => q.name === QuestionEnum.customerEmail) as QuestionAnswer<string>
    const dob = questions.find(q => q.name === QuestionEnum.dateOfBirth) as QuestionAnswer<Date>
    const address = questions.find(q => q.name === QuestionEnum.address) as QuestionAnswer<Address>
    const phoneNumber = questions.find(q => q.name === QuestionEnum.mobilePhoneNumber || q.name === QuestionEnum.customerHomePhoneNumber) as QuestionAnswer<string>

    if (!title?.value || !firstName?.value || !lastName?.value || !email?.value)
      return null

    const request = new AccountRegister()
    request.title = title.value!
    request.firstName = firstName.value!
    request.lastName = lastName.value!
    request.dateOfBirth = dob.value!
    request.email = email.value!
    request.address = address.value!
    request.mobilePhone = phoneNumber.value!

    request.marketing = new Marketing()
    request.marketing.isEmailOptIn = false
    request.marketing.isTelephoneOptIn = false
    request.marketing.isSMSOptIn = false
    request.marketing.isPostOptIn = false

    request.affiliateId = affiliateId

    return request
  }

  updateAccountFromQuestions(account: Account, questions: IQuestionAnswer[]): Account {
    const title = questions.find(q => q.name === QuestionEnum.title) as QuestionAnswer<string>
    const firstName = questions.find(q => q.name === QuestionEnum.firstName) as QuestionAnswer<string>
    const lastName = questions.find(q => q.name === QuestionEnum.lastName) as QuestionAnswer<string>
    const email = questions.find(q => q.name === QuestionEnum.customerEmail) as QuestionAnswer<string>
    const phoneNumber = questions.find(q => q.name === QuestionEnum.mobilePhoneNumber || q.name === QuestionEnum.customerHomePhoneNumber) as QuestionAnswer<string>
    const marketing = questions.find(q => q.name === QuestionEnum.keepingYouInformed) as QuestionAnswer<string[]>
    const address = questions.find(q => q.name === QuestionEnum.address) as QuestionAnswer<Address>
    const dateOfBirth = questions.find(q => q.name === QuestionEnum.dateOfBirth) as QuestionAnswer<Date>

    account.title = title?.value ?? account.title
    account.firstName = firstName?.value ?? account.firstName
    account.lastName = lastName?.value ?? account.lastName
    account.email = email?.value ?? account.email
    account.homePhone = phoneNumber?.value ?? account.homePhone
    account.mobilePhone = phoneNumber?.value ?? account.mobilePhone
    account.telephone = phoneNumber?.value ?? account.telephone
    account.workPhone = phoneNumber?.value ?? account.workPhone
    account.dateOfBirth = dateOfBirth?.value ?? account.dateOfBirth

    if (marketing?.value) {
      account.marketing.isEmailOptIn = marketing.value?.indexOf(DLDCommonValuesEnum.MarketingOption.Email) !== -1
      account.marketing.isSMSOptIn = marketing.value?.indexOf(DLDCommonValuesEnum.MarketingOption.SMS) !== -1
      account.marketing.isPostOptIn = marketing.value?.indexOf(DLDCommonValuesEnum.MarketingOption.Post) !== -1
      account.marketing.isTelephoneOptIn = marketing.value?.indexOf(DLDCommonValuesEnum.MarketingOption.Telephone) !== -1
    }
    account.address = address?.value ?? account.address

    return account
  }
}
