<template>
  <DashboardItem title="Documents" :loaded="loaded">
    <template #content>
      <div v-if="total > 0">
        <table>
          <tr v-for="(d) in results" :key="d.id">
            <td>
              <span class="summary-title">Policy Number</span>
              {{ d.policyNumber }}
            </td>
            <td>
              <span class="summary-title">Description</span>
              {{ d.name }}
            </td>
            <td>
              <a :href="getDocumentUrl(d.path)" target="_blank" aria-label="download"><b-icon icon="download" /></a>
            </td>
          </tr>
        </table>
        <p>
          <b>To view more documents, please select a policy on the policies page.</b>
        </p>
      </div>
      <p v-else>You currently have no documents</p>
    </template>
  </DashboardItem>
</template>

<script lang="ts">
import {
  Component,
  Vue,
} from 'vue-property-decorator';
import Document from '@/services/models/Document';
import NotAuthorisedError from '@/services/error/NotAuthorisedError';
import DashboardItem from './DashboardItem.vue';

@Component({
  components: {
    DashboardItem,
  },
})
export default class Documents extends Vue {
  results: Document[] = [];

  total = 0;

  loaded = false;

  getDocumentUrl(path: string) {
    return this.$services.documentService.generateURL(path);
  }

  async created() {
    try {
      const response = await this.$services.documentService.getForCustomer(0, 2);
      this.results = response.results;
      this.total = response.total;
      this.loaded = true;
    } catch (error) {
      if (!(error instanceof NotAuthorisedError)) {
        throw error;
      }
    }
  }
}
</script>
