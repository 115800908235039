import { JsonObject, JsonProperty } from 'json2typescript'
import Address from '../models/Address'
import PersonalDetails from './PersonalDetails'
import DateConverter from '@/converters/DateConverter'
import Claim from '@/services/values/Claim'
import Conviction from '@/services/values/Conviction'
import MedicalCondition from '@/services/values/MedicalCondition'

@JsonObject('DriverValue')
export default class Driver extends PersonalDetails {
  @JsonProperty('drivingLicence', String)
  drivingLicence = ''

  @JsonProperty('licenceType', String)
  licenceType = ''

  @JsonProperty('licenceDate', DateConverter)
  licenceDate: Date | null = null

  @JsonProperty('employmentStatus', String, true)
  employmentStatus: string | null = null

  @JsonProperty('secondaryEmploymentStatus', String, true)
  secondaryEmploymentStatus: string | null = null

  @JsonProperty('otherVehiclesYouDrive', String, true)
  otherVehiclesYouDrive: string | null = null

  @JsonProperty('useOfAnotherCar', String, true)
  useOfAnotherCar: string | null = null

  @JsonProperty('occupation', String, true)
  occupation: string | null = null

  @JsonProperty('secondaryOccupation', String)
  secondaryOccupation: string | null = null

  @JsonProperty('businessCategory', String, true)
  businessCategory: string | null = null

  @JsonProperty('secondaryBusinessCategory', String, true)
  secondaryBusinessCategory: string | null = null

  @JsonProperty('residentSince', DateConverter)
  residentSince: Date | null = null

  @JsonProperty('residentSinceBirth', Boolean)
  residentSinceBirth: boolean | null = null

  @JsonProperty('classOfUse', String, true)
  classOfUse: string | null = null

  @JsonProperty('maritalStatus', String, true)
  maritalStatus: string | null = null

  @JsonProperty('hasMedicalConditions', Boolean, true)
  hasMedicalConditions: boolean | null = null

  @JsonProperty('hasSecondaryEmployment', Boolean, true)
  hasSecondaryEmployment: boolean | null = null

  @JsonProperty('dvlaReportableMedicalConditions', String, true)
  dvlaReportableMedicalConditions: string | null = null

  @JsonProperty('claims', [Claim])
  claims: Claim[] = []

  @JsonProperty('prn', Number)
  prn: number | null = null

  @JsonProperty('mainLicensingAuthority', String, true)
  mainLicensingAuthority: string | null = null

  @JsonProperty('otherLicensingAuthorities', String, true)
  otherLicensingAuthorities: string | null = null

  @JsonProperty('farePayingPassengers', Boolean, true)
  farePayingPassengers: boolean | null = null

  @JsonProperty('taxiBadgeHeldDate', DateConverter, true)
  taxiBadgeHeldDate: Date | null = null

  @JsonProperty('rideSharingApp', Boolean)
  rideSharingApp: boolean | null = null

  @JsonProperty('hasCCJs', Boolean)
  hasCCJs: boolean | null = null

  @JsonProperty('psvLicenceNumber', String)
  psvLicenceNumber: string | null = null

  @JsonProperty('hasHadPolicyDeclaredVoid', Boolean)
  hasHadPolicyDeclaredVoid: boolean | null = null

  @JsonProperty('psvLicenceDate', DateConverter, true)
  psvLicenceDate: Date | null = null

  @JsonProperty('operatingInLondonArea', Boolean)
  operatingInLondonArea: boolean | null = null

  @JsonProperty('otherVehiclesOwned', Boolean)
  otherVehiclesOwned: boolean | null = null

  @JsonProperty('hasPSVOLicence', Boolean)
  hasPSVOLicence: boolean | null = null

  @JsonProperty('pSVONamedProposer', Boolean)
  pSVONamedProposer: boolean | null = null

  @JsonProperty('pSVOLicenceNumber', String)
  pSVOLicenceNumber: string | null = null

  @JsonProperty('pSVONamedProposerReason', String)
  pSVONamedProposerReason: string | null = null

  @JsonProperty('noPSVOLicenseReason', String)
  noPSVOLicenseReason: string | null = null

  @JsonProperty('hasConvictions', Boolean)
  hasConvictions: boolean | null = null

  @JsonProperty('hasCriminalConvictions', Boolean)
  hasCriminalConvictions: boolean | null = null

  @JsonProperty('hasClaims', Boolean)
  hasClaims: boolean | null = null

  @JsonProperty('liveInVehicleFullTime', Boolean)
  liveInVehicleFullTime: boolean | null = null

  @JsonProperty('hadInsuranceRefused', Boolean)
  hadInsuranceRefused: boolean | null = null

  @JsonProperty('hasHadPreviousPolicyCancelled', Boolean, true)
  hasHadPreviousPolicyCancelled: boolean | null = null

  @JsonProperty('restrictedLicense', Boolean)
  restrictedLicense: boolean | null = null

  @JsonProperty('restrictedLicenseType', String, true)
  restrictedLicenseType: string | null = null

  @JsonProperty('drivingFrequency', String)
  drivingFrequency: string | null = null

  @JsonProperty('convictions', [Conviction])
  convictions: Conviction[] = []

  @JsonProperty('medicalConditions', [MedicalCondition])
  medicalConditions: MedicalCondition[] = []

  @JsonProperty('voidReason', String, true)
  voidReason: string | null = null
}

@JsonObject('GapProposer')
export class GapProposer extends PersonalDetails {
  @JsonProperty('address', Address)
  address: Address = new Address()

  @JsonProperty('telephone', String, true)
  telephone: string | null = null

  @JsonProperty('emailAddress', String, true)
  emailAddress: string | null = null

  @JsonProperty('hasAdditionalApplicant', String, true)
  hasAdditionalApplicant: string | null = null

  @JsonProperty('coverTerm', String, true)
  coverTerm: string | null = null

  @JsonProperty('noClaimDiscount', String, true)
  noClaimDiscount: string | null = null

  @JsonProperty('needCoverForAdditionalDriver', String, true)
  needCoverForAdditionalDriver: string | null = null

  @JsonProperty('residentSinceBirth', Boolean)
  residentSinceBirth: boolean | null = null

  @JsonProperty('ukResident', DateConverter, true)
  ukResident: Date | null = null
}

@JsonObject('CarProposer')
export class CarProposer extends Driver {
  @JsonProperty('address', Address)
  address: Address = new Address()

  @JsonProperty('advancedDrivingLicence', Boolean)
  advancedDrivingLicence: boolean | null = null

  @JsonProperty('advancedDrivingLicenceType', String, true)
  advancedDrivingLicenceType: string | null = null

  @JsonProperty('numberOfHouseholdVehicles', Number)
  numberOfHouseholdVehicles: number | null = null

  @JsonProperty('ownersClub', String, true)
  ownersClub: string | null = null

  @JsonProperty('isOwnersClubMember', Boolean)
  isOwnersClubMember: boolean | null = null

  @JsonProperty('noClaimsYears', Number)
  noClaimsYears: number | null = null

  @JsonProperty('homeOwner', Boolean, true)
  homeOwner: boolean | null = null

  @JsonProperty('isDriverForUber', Boolean)
  isDriverForUber: boolean | null = null

  @JsonProperty('uberBranch', String, true)
  uberBranch: string | null = null

  @JsonProperty('uberProRating', String, true)
  uberProRating: string | null = null

  @JsonProperty('claimsFreeExperienceOnAnotherVehicle', Number)
  claimsFreeExperienceOnAnotherVehicle: number | null = null

  @JsonProperty('numberOfOtherVehiclesAvailable', String, true)
  numberOfOtherVehiclesAvailable: string | null = null

  @JsonProperty('beenRequestedExtraPrecautions', Boolean)
  beenRequestedExtraPrecautions: boolean | null = null

  @JsonProperty('primaryTransport', Boolean)
  primaryTransport: boolean | null = null
}

@JsonObject('AdditionalDriverValue')
export class AdditionalDriver extends Driver {
  @JsonProperty('relationshiptoPolicyholder', String)
  relationshiptoPolicyholder = ''

  @JsonProperty('address', Address)
  address: Address | null = null
}
