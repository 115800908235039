import type { JsonConvert } from 'json2typescript'
import type { AxiosInstance } from 'axios'
import type IPolicyService from './interfaces/IPolicyService'
import { PolicyCollectionResponse, PolicyHistoryCollectionResponse } from './values/CollectionResponse'
import Policy from './models/Policy'
import CancellationRequest from './values/CancellationRequest'
import CancellationResponse from './values/CancellationResponse'
import ConfirmCancellationRequest from './values/ConfirmCancellationRequest'
import PaymentRequest from './values/PaymentRequest'
import type ThreeDSecureDetails from './values/ThreeDSecureDetails'
import PaymentService from './PaymentService'
import type CardDetails from '@/view-models/CardDetails'

export default class PolicyService implements IPolicyService {
  axios: AxiosInstance

  apiUrl: URL

  jsonConvert: JsonConvert

  constructor(axios: AxiosInstance, apiUrl: URL, jsonConvert: JsonConvert) {
    this.axios = axios
    this.apiUrl = apiUrl
    this.jsonConvert = jsonConvert
  }

  async cancelPendingRenewal(policyNumber: string, reason: string): Promise<void> {
    return this.axios.get(`${this.apiUrl}/policy/${encodeURIComponent(policyNumber)}/cancel-pending-renewal`, { params: { reason } })
  }

  async getPolicy(policyNumber: string): Promise<Policy> {
    const result = await this.axios.get(`${this.apiUrl}/policy/${encodeURIComponent(policyNumber)}`)
    return this.jsonConvert.deserializeObject(result.data, Policy)
  }

  async getPolicies(active: boolean, start?: number, end?: number): Promise<PolicyCollectionResponse> {
    const result = await this.axios.get(`${this.apiUrl}/policy`, { params: { active, start, end } })
    return this.jsonConvert.deserializeObject(result.data, PolicyCollectionResponse)
  }

  async getRenewals(start?: number, end?: number): Promise<PolicyCollectionResponse> {
    const result = await this.axios.get(`${this.apiUrl}/policy/renewals`, { params: { start, end } })
    return this.jsonConvert.deserializeObject(result.data, PolicyCollectionResponse)
  }

  async getPolicyHistories(policyNumber: string, start?: number, end?: number): Promise<PolicyHistoryCollectionResponse> {
    const result = await this.axios.get(`${this.apiUrl}/policy/${encodeURIComponent(policyNumber)}/history`, { params: { start, end } })
    return this.jsonConvert.deserializeObject(result.data, PolicyHistoryCollectionResponse)
  }

  async requestCancellation(policyNumber: string, effectiveDate: Date, currentMileage?: number): Promise<CancellationResponse> {
    const request = new CancellationRequest()
    request.effectiveDate = effectiveDate
    request.currentMileage = currentMileage ?? null

    const result = await this.axios.post(`${this.apiUrl}/policy/${encodeURIComponent(policyNumber)}/cancellation`, this.jsonConvert.serialize(request, CancellationRequest))
    return this.jsonConvert.deserializeObject(result.data, CancellationResponse)
  }

  async confirmCancellation(policyNumber: string, effectiveDate: Date, cancellationReason: number, cantAfford: boolean, paymentDueDate?: Date, currentMileage?: number): Promise<void> {
    const request = new ConfirmCancellationRequest()
    request.effectiveDate = effectiveDate
    request.cancellationReason = cancellationReason
    request.currentMileage = currentMileage ?? null
    request.cantAfford = cantAfford
    request.paymentDueDate = paymentDueDate ?? null

    return this.axios.post(`${this.apiUrl}/policy/${encodeURIComponent(policyNumber)}/cancellation/confirm`, this.jsonConvert.serialize(request, ConfirmCancellationRequest))
  }

  async confirmCancellationTakePayment(policyNumber: string, effectiveDate: Date, cancellationReason: number, paymentAmount: number, cardDetails: CardDetails, threeDSecure?: (details: ThreeDSecureDetails, paymentRequest: PaymentRequest) => Promise<{ cancelled: boolean, transId: string | null }>, currentMileage?: number): Promise<boolean> {
    const payment = new PaymentRequest()
    payment.singlePaymentAmount = paymentAmount

    if (cardDetails.newCard) {
      const card = PaymentService.mapCreditCardToRequest(cardDetails)
      payment.creditCard = card
    }

    if (cardDetails.paymentGatewayResult)
      payment.ignitePaymentGatewayResult = cardDetails.paymentGatewayResult

    payment.existingCard = cardDetails.existingCard

    const request = new ConfirmCancellationRequest()
    request.effectiveDate = effectiveDate
    request.cancellationReason = cancellationReason
    request.currentMileage = currentMileage ?? null
    request.payment = payment

    if (threeDSecure) {
      const result = await PaymentService.threeDSecureWorkflow(this.axios, this.jsonConvert, this.apiUrl, payment, threeDSecure)

      if (result.cancelled)
        return false

      payment.threeDSecureTransId = result.transId!
    }

    return this.axios.post(`${this.apiUrl}/policy/${encodeURIComponent(policyNumber)}/cancellation/confirm`, this.jsonConvert.serialize(request, ConfirmCancellationRequest))
  }

  async cancelPendingMTA(policyNumber: string): Promise<void> {
    return this.axios.get(`${this.apiUrl}/policy/${encodeURIComponent(policyNumber)}/cancel-pending-mta`)
  }
}
