import { JsonObject, JsonProperty } from 'json2typescript'
import Model from './Model'

@JsonObject('ActionMessage')
export default class ActionMessage extends Model {
  @JsonProperty('message', String)
  message: string

  @JsonProperty('level', Number)
  level: number

  constructor(
    id = 0,
    message = '',
    level = 0,
  ) {
    super(id)
    this.message = message
    this.level = level
  }
}
