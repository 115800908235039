enum PaymentScheduleStatusEnum {
  notYetDue = 'NotYetDue',
  sent = 'Sent',
  inLoop = 'InLoop',
  paid = 'Paid',
  failed = 'Failed',
  paymentFailed = 'PaymentFailed',
  cardPayment = 'CardPayment',
  indemnityClaim = 'IndemnityClaim',
  contactProvider = 'ContactProvider',
}

export default PaymentScheduleStatusEnum
