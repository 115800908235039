import type { JsonConvert } from 'json2typescript'
import type { AxiosInstance } from 'axios'
import type IVehicleService from './interfaces/IVehicleService'
import DVLALookup from './models/DVLALookup'
import Car from './models/Car'

export default class VehicleService implements IVehicleService {
  axios: AxiosInstance

  apiUrl: URL

  jsonConvert: JsonConvert

  constructor(axios: AxiosInstance, apiUrl: URL, jsonConvert: JsonConvert) {
    this.axios = axios
    this.apiUrl = apiUrl
    this.jsonConvert = jsonConvert
  }

  async getByRegistration(productUniqueId: string, registration: string, dvlaOnly: boolean): Promise<DVLALookup> {
    const result = await this.axios.get(`${this.apiUrl}/vehicle/reg/${encodeURIComponent(productUniqueId)}/${encodeURIComponent(registration)}`, { params: { dvlaOnly } })
    return this.jsonConvert.deserializeObject(result.data, DVLALookup)
  }

  async getByAbiCode(productUniqueId: string, abiCode: string): Promise<Car> {
    const result = await this.axios.get(`${this.apiUrl}/vehicle/abi/${encodeURIComponent(productUniqueId)}/${encodeURIComponent(abiCode)}`)
    return this.jsonConvert.deserializeObject(result.data, Car)
  }

  async getCarMakes(productUniqueId: string, make: string): Promise<string[]> {
    const result = await this.axios.get(`${this.apiUrl}/vehicle/${encodeURIComponent(productUniqueId)}/${encodeURIComponent(make)}?count=10`)
    return result.data as string[]
  }

  async getCarModels(productUniqueId: string, make: string, model: string): Promise<Car[]> {
    const result = await this.axios.get(`${this.apiUrl}/vehicle/${encodeURIComponent(productUniqueId)}/${encodeURIComponent(make)}/${encodeURIComponent(model.replaceAll('+', '').replaceAll('&', '').replaceAll(':', '').replaceAll('/', '').replaceAll('.', ''))}?count=20`)
    return this.jsonConvert.deserializeArray(result.data, Car)
  }
}
