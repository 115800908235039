import type { JsonConvert } from 'json2typescript'
import type { AxiosInstance } from 'axios'
import type IAffiliateService from './interfaces/IAffiliateService'
import Affiliate from './models/Affiliate'

export default class AffiliateService implements IAffiliateService {
  axios: AxiosInstance

  apiUrl: URL

  jsonConvert: JsonConvert

  constructor(axios: AxiosInstance, apiUrl: URL, jsonConvert: JsonConvert) {
    this.axios = axios
    this.apiUrl = apiUrl
    this.jsonConvert = jsonConvert
  }

  async getAffiliate(affiliateId: number): Promise<Affiliate> {
    const result = await this.axios.get(`${this.apiUrl}/affiliate/${encodeURIComponent(affiliateId)}`)
    return this.jsonConvert.deserializeObject(result.data, Affiliate)
  }
}
