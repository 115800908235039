import type Section from './Section'
import type { Dictionary } from '@/types'

export default class Page {
  id: string

  name: string

  title: string

  subtitle: string | null

  sections: Section[]

  configs: Dictionary<string>

  placeholderAnswers: object | undefined

  reloadFunction!: () => void

  reload(): void {
    if (this.reloadFunction)
      this.reloadFunction()
  }

  constructor(id: string, name: string, title: string, subtitle: string | null, sections: Section[], configs: Dictionary<string>, placeholderAnswers: object | undefined) {
    this.id = id
    this.name = name
    this.title = title
    this.subtitle = subtitle
    this.sections = sections
    this.configs = configs
    this.placeholderAnswers = placeholderAnswers
  }
}
