import { JsonObject, JsonProperty } from 'json2typescript'
import DateConverter from '../../converters/DateConverter'
import VAPVehicle from '../models/VAPVehicle'
import QuoteRequest from './QuoteRequest'
import TouringCaravanRequest from './TouringCaravanRequest'
import TouringCaravanClaimRequest from './TouringCaravanClaimRequest'
import TouringCaravanConvictionRequest from './TouringCaravanConvictionRequest'
import TouringCaravanProposerRequest from './TouringCaravanProposerRequest'
import TouringCaravanStorageLocationRequest from './TouringCaravanStorageLocationRequest'

@JsonObject('TouringCaravanQuoteRequest')
export default class TouringCaravanQuoteRequest extends QuoteRequest {
  @JsonProperty('awningsValue', Number, true)
  awningsValue: number | null = null

  @JsonProperty('basisOfCover', String, true)
  basisOfCover: string | null = null

  @JsonProperty('coverEffectiveDate', DateConverter, true)
  coverEffectiveDate: Date | null = null

  @JsonProperty('equipmentValue', Number, true)
  equipmentValue: number | null = null

  @JsonProperty('contentsValue', Number, true)
  contentsValue: number | null = null

  @JsonProperty('personalEffectsValue', Number, true)
  personalEffectsValue: number | null = null

  @JsonProperty('voluntaryExcess', String, true)
  voluntaryExcess: string | null = null

  @JsonProperty('touringCaravan', TouringCaravanRequest)
  touringCaravan: TouringCaravanRequest = new TouringCaravanRequest()

  @JsonProperty('touringCaravanClaims', [TouringCaravanClaimRequest])
  touringCaravanClaims: TouringCaravanClaimRequest[] = []

  @JsonProperty('touringCaravanConvictions', [TouringCaravanConvictionRequest])
  touringCaravanConvictions: TouringCaravanConvictionRequest[] = []

  @JsonProperty('touringCaravanProposer', TouringCaravanProposerRequest)
  TouringCaravanProposer = new TouringCaravanProposerRequest()

  @JsonProperty('touringCaravanStorageLocations', [TouringCaravanStorageLocationRequest])
  touringCaravanStorageLocations: TouringCaravanStorageLocationRequest[] = []

  @JsonProperty('breakDownCoverRequired', Boolean, true)
  breakDownCoverRequired: boolean | null = null

  @JsonProperty('vAPVehicle', VAPVehicle)
  vAPVehicle = new VAPVehicle()
}

@JsonObject('TouringCaravanMTAQuoteRequest')
export class TouringCaravanMTAQuoteRequest extends TouringCaravanQuoteRequest {
  @JsonProperty('reasonForChange', String)
  reasonForChange = ''
}
