import { JsonObject, JsonProperty } from 'json2typescript'
import PaymentRequest from './PaymentRequest'

@JsonObject('ThreeDSecureAuthorisationRequest')
export class ThreeDSecureAuthorisationRequest {
  @JsonProperty('authUrl', String)
  authUrl = ''

  @JsonProperty('transId', String)
  transId = ''

  @JsonProperty('browserInfo', String)
  browserInfo = ''

  @JsonProperty('paymentRequest', PaymentRequest)
  paymentRequest: PaymentRequest | null = null
}

@JsonObject('ThreeDSecureAuthorisationResponse')
export class ThreeDSecureAuthorisationResponse {
  @JsonProperty('complete', Boolean)
  complete = false

  @JsonProperty('failed', Boolean)
  failed = false

  @JsonProperty('challengeRequired', Boolean)
  challengeRequired = false

  @JsonProperty('challengeUrl', String)
  challengeUrl = ''

  @JsonProperty('challengeUrl', String)
  paymentRequest = ''
}
