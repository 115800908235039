import { JsonObject, JsonProperty } from 'json2typescript'

@JsonObject('ForgotPassword')
export default class ForgotPassword {
  @JsonProperty('username', String)
  username = ''

  @JsonProperty('emailAddress', String)
  emailAddress = ''

  @JsonProperty('firstName', String)
  firstName = ''

  @JsonProperty('lastName', String)
  lastName = ''
}
