import { JsonObject, JsonProperty } from 'json2typescript'
import DateConverter from '@/converters/DateConverter'

@JsonObject('SubscriptionResult')
export default class SubscriptionResult {
  @JsonProperty('newSubscriptionCalculatedTotalPayable', Number)
  newSubscriptionCalculatedTotalPayable = 0

  @JsonProperty('newSubscriptionIPT', Number)
  newSubscriptionIPT = 0

  @JsonProperty('newSubscriptionPriceEffectiveDate', DateConverter)
  newSubscriptionPriceEffectiveDate: Date = new Date()
}
