<template>
  <DashboardItem title="Policies" :loaded="loaded">
    <template #content>
      <div v-if="policiesStore.total > 0">
        <p>You have {{ policiesStore.total }} active policies:</p>
        <table>
          <tr v-for="(policy) in policiesStore.policies" :key="policy.policyNumber">
            <td>
              <b-icon :pack=getProductIcon(policy.product).pack :icon="getProductIcon(policy.product).icon" size="is-large"/>
            </td>
            <td>
              <span class="summary-title">{{ policy.summaryItems[0].name }}</span>
              {{ policy.summaryItems[0].value }}
            </td>
            <td>
              <div class="flex-buttons">
                <b-button @click="goToPolicy(router, policy)">Manage</b-button>
                <LinkConfigButton v-if="actions(policy).length > 0 && !!actions(policy)[0].name" :config="actions(policy)[0]" />
              </div>
            </td>
          </tr>
        </table>
        <router-link
          :to="routes.policies">
          All policies
        </router-link>
      </div>
      <p v-else>You have no policies</p>
    </template>
  </DashboardItem>
</template>

<script lang="ts">
import {
  Component,
  Vue,
} from 'vue-property-decorator';
import usePoliciesStore from '@/stores/policies-store'
import DashboardItem from './DashboardItem.vue';
import Routes from '@/constants/Routes';
import { getProductIcon } from '@/composables/product-utils'
import { actions } from '@/composables/actions-utils'
import { goToPolicy } from '@/composables/policies-utils'
import LinkConfigButton from '../LinkConfigButton.vue';
@Component({
  components: {
    DashboardItem,
    LinkConfigButton,
  },
})
export default class Policies extends Vue {

  goToPolicy = goToPolicy;

  actions = actions;

  getProductIcon = getProductIcon;

  routes = Routes;

  loaded = false;

  get policiesStore(){
    return usePoliciesStore();
  }

  get router(){
    return this.$router;
  }

  async created() {
    this.loaded = await usePoliciesStore().retrievePolicies(this.$services, true);
  }
}
</script>
