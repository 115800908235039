import { defineStore } from 'pinia'
import NotAuthorisedError from '@/services/error/NotAuthorisedError'
import type { IServices } from '@/plugins/services'
import type PolicySummary from '@/services/values/PolicySummary'

interface State {
  policies?: PolicySummary[]
  total:
  number
}

const usePoliciesStore = defineStore('policies', {

  state: (): State => ({
    policies: undefined,
    total: 0,
  }),
  actions: {
    async retrievePolicies(services: IServices, active: boolean, start?: number, end?: number) {
      try {
        const response = await services.policyService.getPolicies(active, start, end)
        this.policies = response.results
        this.total = response.total
        return true
      }
      catch (error) {
        if (!(error instanceof NotAuthorisedError))
          throw error
      }
      return false
    },
  },
})

export default usePoliciesStore
