import type { JsonConvert } from 'json2typescript'
import type { AxiosInstance } from 'axios'
import type IRatingEngineService from './interfaces/IRatingEngineService'
import RatingResponse from './values/RatingResponse'
import type IRequestMapper from './interfaces/IRequestMapper'
import type IResponseMapper from './interfaces/IResponseMapper'
import type { IQuestionAnswer } from './values/PageAnswers'
import CarQuoteRequest, { CarMTAQuoteRequest } from './values/CarQuoteRequest'
import PetQuoteRequest, { PetMTAQuoteRequest } from './values/PetQuoteRequest'
import GapQuoteRequest, { GapMTAQuoteRequest } from './values/GapQuoteRequest'
import TouringCaravanQuoteRequest, { TouringCaravanMTAQuoteRequest } from './values/TouringCaravanQuoteRequest'
import CaravanQuoteRequest, { CaravanMTAQuoteRequest } from './values/CaravanQuoteRequest'
import VehicleQuoteRequest, { VehicleMTAQuoteRequest } from './values/Vehicle/VehicleQuoteRequest'
import PropertyQuoteRequest, { PropertyMTAQuoteRequest } from './values/Property/PropertyQuoteRequest'
import type { Dictionary } from '@/types'
import ProductEnum from '@/services/enums/ProductEnum'
import DynamicQuoteRequest from '@/services/values/DynamicQuoteRequest'

export default class RatingEngineService implements IRatingEngineService {
  axios: AxiosInstance

  apiUrl: URL

  jsonConvert: JsonConvert

  requestMapper: IRequestMapper

  responseMapper: IResponseMapper

  constructor(axios: AxiosInstance, apiUrl: URL, jsonConvert: JsonConvert, requestMapper: IRequestMapper, responseMapper: IResponseMapper) {
    this.axios = axios
    this.apiUrl = apiUrl
    this.jsonConvert = jsonConvert
    this.requestMapper = requestMapper
    this.responseMapper = responseMapper
  }

  async getQuote(questions: IQuestionAnswer[], aggQuoteReference: string | null, product: ProductEnum, metaData: Dictionary<string | (string | null)[]>): Promise<RatingResponse> {
    const dictNoDupes = Object.keys(metaData).reduce((d, x) => ({ ...d, [x]: Array.isArray(metaData[x]) ? metaData[x][0] : metaData[x] }), {})
    switch (product) {
      case ProductEnum.modernCar:
      case ProductEnum.motorCaravan:
      case ProductEnum.taxi:
      case ProductEnum.courier:
      case ProductEnum.commercialVehicle:
        return this.getCarQuote(questions, aggQuoteReference, product, dictNoDupes)
      case ProductEnum.multiPet:
        return this.getPetQuote(questions, aggQuoteReference, dictNoDupes)
      case ProductEnum.touringCaravan:
        return this.getTouringCaravanQuote(questions, aggQuoteReference, dictNoDupes)
      case ProductEnum.staticCaravanResidential:
        return this.getCaravanQuote(questions, aggQuoteReference, dictNoDupes)
      case ProductEnum.caravan:
        return this.getCaravanQuote(questions, aggQuoteReference, dictNoDupes)
      case ProductEnum.gapInsurance:
        return this.getGapQuote(questions, aggQuoteReference, dictNoDupes)
      case ProductEnum.vehicle:
        return this.getVehicleQuote(questions, aggQuoteReference, dictNoDupes)
      case ProductEnum.breakdown:
        return this.getDynamicQuote(questions, aggQuoteReference, dictNoDupes, product)
      case ProductEnum.home:
        return this.getPropertyQuote(questions, aggQuoteReference, dictNoDupes)
      default:
        throw new Error(`Unknown product (${product}).`)
    }
  }

  async updateQuote(quoteReference: string, questions: IQuestionAnswer[], product: ProductEnum): Promise<RatingResponse> {
    switch (product) {
      case ProductEnum.modernCar:
      case ProductEnum.motorCaravan:
      case ProductEnum.taxi:
      case ProductEnum.courier:
      case ProductEnum.commercialVehicle:
        return this.updateCarQuote(quoteReference, questions, product)
      case ProductEnum.multiPet:
        return this.updatePetQuote(quoteReference, questions)
      case ProductEnum.gapInsurance:
        return this.updateGapQuote(quoteReference, questions)
      case ProductEnum.touringCaravan:
        return this.updateTouringCaravanQote(quoteReference, questions)
      case ProductEnum.staticCaravanResidential:
        return this.updateCaravanQote(quoteReference, questions)
      case ProductEnum.caravan:
        return this.updateCaravanQote(quoteReference, questions)
      case ProductEnum.vehicle:
        return this.updateVehicleQuote(quoteReference, questions)
      case ProductEnum.breakdown:
        return this.updateDynamicQuote(quoteReference, questions, product)
      case ProductEnum.home:
        return this.updatePropertyQuote(quoteReference, questions, product)
      default:
        throw new Error(`Unknown product (${product}).`)
    }
  }

  async updateRenewalQuote(policyNumber: string, quoteReference: string, questions: IQuestionAnswer[], product: ProductEnum): Promise<RatingResponse> {
    switch (product) {
      case ProductEnum.modernCar:
      case ProductEnum.motorCaravan:
      case ProductEnum.taxi:
      case ProductEnum.courier:
      case ProductEnum.commercialVehicle:
        return this.updateCarRenewalQuote(policyNumber, quoteReference, questions, product)
      case ProductEnum.multiPet:
        return this.updatePetRenewalQuote(policyNumber, quoteReference, questions)
      case ProductEnum.touringCaravan:
        return this.updateTouringCaravanRenewalQuote(policyNumber, quoteReference, questions)
      case ProductEnum.caravan:
        return this.updateCaravanRenewalQuote(policyNumber, quoteReference, questions)
      case ProductEnum.staticCaravanResidential:
        return this.updateCaravanRenewalQuote(policyNumber, quoteReference, questions)
      case ProductEnum.vehicle:
        return this.updateVehicleRenewalQuote(policyNumber, quoteReference, questions)
      case ProductEnum.home:
        return this.updatePropertyRenewalQuote(policyNumber, quoteReference, questions)
      default:
        throw new Error(`Unknown product (${product}).`)
    }
  }

  async updateRenewalDiscount(policyNumber: string, quoteReference: string, product: string): Promise<RatingResponse> {
    const result = await this.axios.post(`${this.apiUrl}/rating/${product}/update-renewal-quote-discount/${encodeURIComponent(policyNumber)}/${encodeURIComponent(quoteReference)}`)
    return this.jsonConvert.deserializeObject(result.data, RatingResponse)
  }

  async requestMTAQuote(policyNumber: string, questions: IQuestionAnswer[], product: ProductEnum): Promise<RatingResponse> {
    switch (product) {
      case ProductEnum.modernCar:
      case ProductEnum.motorCaravan:
      case ProductEnum.taxi:
      case ProductEnum.courier:
      case ProductEnum.commercialVehicle:
        return this.requestCarMTAQuote(policyNumber, questions, product)
      case ProductEnum.gapInsurance:
        return this.requestGapMTAQuote(policyNumber, questions)
      case ProductEnum.multiPet:
        return this.requestPetMTAQuote(policyNumber, questions)
      case ProductEnum.touringCaravan:
        return this.requestTouringCaravanMTAQuote(policyNumber, questions)
      case ProductEnum.staticCaravanResidential:
        return this.requestCaravanMTAQuote(policyNumber, questions)
      case ProductEnum.caravan:
        return this.requestCaravanMTAQuote(policyNumber, questions)
      case ProductEnum.vehicle:
        return this.requestVehicleMTAQuote(policyNumber, questions)
      case ProductEnum.home:
        return this.requestPropertyMTAQuote(policyNumber, questions)
      default:
        throw new Error(`Unknown product (${product}).`)
    }
  }

  async getTouringCaravanQuote(questions: IQuestionAnswer[], aggQuoteReference: string | null, metaData: Dictionary<string>): Promise<RatingResponse> {
    const request = this.requestMapper.getQuoteRequest(questions, TouringCaravanQuoteRequest)
    request.aggregatorQuoteReference = aggQuoteReference
    request.metaData = metaData
    const result = await this.axios.post(`${this.apiUrl}/rating/Touring/rate`, this.jsonConvert.serialize(request, TouringCaravanQuoteRequest))
    return this.jsonConvert.deserializeObject(result.data, RatingResponse)
  }

  async getCaravanQuote(questions: IQuestionAnswer[], aggQuoteReference: string | null, metaData: Dictionary<string>): Promise<RatingResponse> {
    const request = this.requestMapper.getQuoteRequest(questions, CaravanQuoteRequest)
    request.aggregatorQuoteReference = aggQuoteReference
    request.metaData = metaData
    const result = await this.axios.post(`${this.apiUrl}/rating/Caravan/rate`, this.jsonConvert.serialize(request, CaravanQuoteRequest))
    return this.jsonConvert.deserializeObject(result.data, RatingResponse)
  }

  async getPropertyQuote(questions: IQuestionAnswer[], aggQuoteReference: string | null, metaData: Dictionary<string>): Promise<RatingResponse> {
    const request = this.requestMapper.getQuoteRequest(questions, PropertyQuoteRequest)
    request.aggregatorQuoteReference = aggQuoteReference
    request.metaData = metaData
    const result = await this.axios.post(`${this.apiUrl}/rating/Property/rate`, this.jsonConvert.serialize(request, PropertyQuoteRequest))
    return this.jsonConvert.deserializeObject(result.data, RatingResponse)
  }

  async getGapQuote(questions: IQuestionAnswer[], aggQuoteReference: string | null, metaData: Dictionary<string>): Promise<RatingResponse> {
    const request = this.requestMapper.getQuoteRequest(questions, GapQuoteRequest)
    request.aggregatorQuoteReference = aggQuoteReference
    request.metaData = metaData
    const result = await this.axios.post(`${this.apiUrl}/rating/Gap/rate`, this.jsonConvert.serialize(request, GapQuoteRequest))
    return this.jsonConvert.deserializeObject(result.data, RatingResponse)
  }

  async getCarQuote(questions: IQuestionAnswer[], aggQuoteReference: string | null, product: string, metaData: Dictionary<string>): Promise<RatingResponse> {
    const request = this.requestMapper.getQuoteRequest(questions, CarQuoteRequest)
    request.aggregatorQuoteReference = aggQuoteReference
    request.metaData = metaData
    const result = await this.axios.post(`${this.apiUrl}/rating/${product}/rate`, this.jsonConvert.serialize(request, CarQuoteRequest))
    return this.jsonConvert.deserializeObject(result.data, RatingResponse)
  }

  async updateCarQuote(quoteReference: string, questions: IQuestionAnswer[], product: string): Promise<RatingResponse> {
    const request = this.requestMapper.getQuoteRequest(questions, CarQuoteRequest)
    const result = await this.axios.post(`${this.apiUrl}/rating/${product}/update-quote/${encodeURIComponent(quoteReference)}`, this.jsonConvert.serialize(request, CarQuoteRequest))
    return this.jsonConvert.deserializeObject(result.data, RatingResponse)
  }

  async updateTouringCaravanQote(quoteReference: string, questions: IQuestionAnswer[]): Promise<RatingResponse> {
    const request = this.requestMapper.getQuoteRequest(questions, TouringCaravanQuoteRequest)
    const result = await this.axios.post(`${this.apiUrl}/rating/Touring/update-quote/${encodeURIComponent(quoteReference)}`, this.jsonConvert.serialize(request, TouringCaravanQuoteRequest))
    return this.jsonConvert.deserializeObject(result.data, RatingResponse)
  }

  async updateCaravanQote(quoteReference: string, questions: IQuestionAnswer[]): Promise<RatingResponse> {
    const request = this.requestMapper.getQuoteRequest(questions, CaravanQuoteRequest)
    const result = await this.axios.post(`${this.apiUrl}/rating/Caravan/update-quote/${encodeURIComponent(quoteReference)}`, this.jsonConvert.serialize(request, CaravanQuoteRequest))
    return this.jsonConvert.deserializeObject(result.data, RatingResponse)
  }

  async updateGapQuote(quoteReference: string, questions: IQuestionAnswer[]): Promise<RatingResponse> {
    const request = this.requestMapper.getQuoteRequest(questions, GapQuoteRequest)
    const result = await this.axios.post(`${this.apiUrl}/rating/Gap/update-quote/${encodeURIComponent(quoteReference)}`, this.jsonConvert.serialize(request, GapQuoteRequest))
    return this.jsonConvert.deserializeObject(result.data, RatingResponse)
  }

  async updateCarRenewalQuote(policyNumber: string, quoteReference: string, questions: IQuestionAnswer[], product: string): Promise<RatingResponse> {
    const request = this.requestMapper.getQuoteRequest(questions, CarQuoteRequest)
    const result = await this.axios.post(`${this.apiUrl}/rating/${product}/update-renewal-quote/${encodeURIComponent(policyNumber)}/${encodeURIComponent(quoteReference)}`, this.jsonConvert.serialize(request, CarQuoteRequest))
    return this.jsonConvert.deserializeObject(result.data, RatingResponse)
  }

  async requestCarMTAQuote(policyNumber: string, questions: IQuestionAnswer[], product: string): Promise<RatingResponse> {
    const request = this.requestMapper.getMTARequest(questions, CarMTAQuoteRequest)
    const result = await this.axios.post(`${this.apiUrl}/rating/${product}/${encodeURIComponent(policyNumber)}/change`, this.jsonConvert.serialize(request, CarMTAQuoteRequest))
    return this.jsonConvert.deserializeObject(result.data, RatingResponse)
  }

  async requestGapMTAQuote(policyNumber: string, questions: IQuestionAnswer[]): Promise<RatingResponse> {
    const request = this.requestMapper.getMTARequest(questions, GapMTAQuoteRequest)
    const result = await this.axios.post(`${this.apiUrl}/rating/Gap/${encodeURIComponent(policyNumber)}/change`, this.jsonConvert.serialize(request, GapMTAQuoteRequest))
    return this.jsonConvert.deserializeObject(result.data, RatingResponse)
  }

  async requestVehicleMTAQuote(policyNumber: string, questions: IQuestionAnswer[]): Promise<RatingResponse> {
    const request = this.requestMapper.getMTARequest(questions, VehicleMTAQuoteRequest)
    const result = await this.axios.post(`${this.apiUrl}/rating/Vehicle/${encodeURIComponent(policyNumber)}/change`, this.jsonConvert.serialize(request, VehicleMTAQuoteRequest))
    return this.jsonConvert.deserializeObject(result.data, RatingResponse)
  }

  async requestPropertyMTAQuote(policyNumber: string, questions: IQuestionAnswer[]): Promise<RatingResponse> {
    const request = this.requestMapper.getMTARequest(questions, PropertyMTAQuoteRequest)
    const result = await this.axios.post(`${this.apiUrl}/rating/Property/${encodeURIComponent(policyNumber)}/change`, this.jsonConvert.serialize(request, PropertyMTAQuoteRequest))
    return this.jsonConvert.deserializeObject(result.data, RatingResponse)
  }

  async getPetQuote(questions: IQuestionAnswer[], aggQuoteReference: string | null, metaData: Dictionary<string>): Promise<RatingResponse> {
    const request = this.requestMapper.getQuoteRequest(questions, PetQuoteRequest)
    request.aggregatorQuoteReference = aggQuoteReference
    request.metaData = metaData
    const result = await this.axios.post(`${this.apiUrl}/rating/Pet/rate`, this.jsonConvert.serialize(request, PetQuoteRequest))
    return this.jsonConvert.deserializeObject(result.data, RatingResponse)
  }

  async updatePetQuote(quoteReference: string, questions: IQuestionAnswer[]): Promise<RatingResponse> {
    const request = this.requestMapper.getQuoteRequest(questions, PetQuoteRequest)
    const result = await this.axios.post(`${this.apiUrl}/rating/Pet/update-quote/${encodeURIComponent(quoteReference)}`, this.jsonConvert.serialize(request, PetQuoteRequest))
    return this.jsonConvert.deserializeObject(result.data, RatingResponse)
  }

  async updatePetRenewalQuote(policyNumber: string, quoteReference: string, questions: IQuestionAnswer[]): Promise<RatingResponse> {
    const request = this.requestMapper.getQuoteRequest(questions, PetQuoteRequest)
    const result = await this.axios.post(`${this.apiUrl}/rating/Pet/update-renewal-quote/${encodeURIComponent(policyNumber)}/${encodeURIComponent(quoteReference)}`, this.jsonConvert.serialize(request, PetQuoteRequest))
    return this.jsonConvert.deserializeObject(result.data, RatingResponse)
  }

  async updateTouringCaravanRenewalQuote(policyNumber: string, quoteReference: string, questions: IQuestionAnswer[]): Promise<RatingResponse> {
    const request = this.requestMapper.getQuoteRequest(questions, TouringCaravanQuoteRequest)
    const result = await this.axios.post(`${this.apiUrl}/rating/Touring/update-renewal-quote/${encodeURIComponent(policyNumber)}/${encodeURIComponent(quoteReference)}`, this.jsonConvert.serialize(request, TouringCaravanQuoteRequest))
    return this.jsonConvert.deserializeObject(result.data, RatingResponse)
  }

  async updateCaravanRenewalQuote(policyNumber: string, quoteReference: string, questions: IQuestionAnswer[]): Promise<RatingResponse> {
    const request = this.requestMapper.getQuoteRequest(questions, CaravanQuoteRequest)
    const result = await this.axios.post(`${this.apiUrl}/rating/Caravan/update-renewal-quote/${encodeURIComponent(policyNumber)}/${encodeURIComponent(quoteReference)}`, this.jsonConvert.serialize(request, CaravanQuoteRequest))
    return this.jsonConvert.deserializeObject(result.data, RatingResponse)
  }

  async requestPetMTAQuote(policyNumber: string, questions: IQuestionAnswer[]): Promise<RatingResponse> {
    const request = this.requestMapper.getMTARequest(questions, PetMTAQuoteRequest)
    const result = await this.axios.post(`${this.apiUrl}/rating/Pet/${encodeURIComponent(policyNumber)}/change`, this.jsonConvert.serialize(request, PetMTAQuoteRequest))
    return this.jsonConvert.deserializeObject(result.data, RatingResponse)
  }

  async requestTouringCaravanMTAQuote(policyNumber: string, questions: IQuestionAnswer[]): Promise<RatingResponse> {
    const request = this.requestMapper.getMTARequest(questions, TouringCaravanMTAQuoteRequest)
    const result = await this.axios.post(`${this.apiUrl}/rating/Touring/${encodeURIComponent(policyNumber)}/change`, this.jsonConvert.serialize(request, TouringCaravanMTAQuoteRequest))
    return this.jsonConvert.deserializeObject(result.data, RatingResponse)
  }

  async requestCaravanMTAQuote(policyNumber: string, questions: IQuestionAnswer[]): Promise<RatingResponse> {
    const request = this.requestMapper.getMTARequest(questions, CaravanMTAQuoteRequest)
    const result = await this.axios.post(`${this.apiUrl}/rating/Caravan/${encodeURIComponent(policyNumber)}/change`, this.jsonConvert.serialize(request, CaravanMTAQuoteRequest))
    return this.jsonConvert.deserializeObject(result.data, RatingResponse)
  }

  async getMTAQuote(policyNumber: string): Promise<RatingResponse> {
    const result = await this.axios.get(`${this.apiUrl}/rating/${encodeURIComponent(policyNumber)}/change`)
    return this.jsonConvert.deserializeObject(result.data, RatingResponse)
  }

  async getVehicleQuote(questions: IQuestionAnswer[], aggQuoteReference: string | null, metaData: Dictionary<string>): Promise<RatingResponse> {
    const request = this.requestMapper.getQuoteRequest(questions, VehicleQuoteRequest)
    request.aggregatorQuoteReference = aggQuoteReference
    request.metaData = metaData
    const result = await this.axios.post(`${this.apiUrl}/rating/Vehicle/rate`, this.jsonConvert.serialize(request, VehicleQuoteRequest))
    return this.jsonConvert.deserializeObject(result.data, RatingResponse)
  }

  async getDynamicQuote(questions: IQuestionAnswer[], aggQuoteReference: string | null, metaData: Dictionary<string>, product: string): Promise<RatingResponse> {
    const request = this.requestMapper.getQuoteRequest(questions, DynamicQuoteRequest)
    request.aggregatorQuoteReference = aggQuoteReference
    request.metaData = metaData
    const result = await this.axios.post(`${this.apiUrl}/rating/dynamic/${product}/rate`, this.jsonConvert.serialize(request, DynamicQuoteRequest))
    return this.jsonConvert.deserializeObject(result.data, RatingResponse)
  }

  async updateVehicleQuote(quoteReference: string, questions: IQuestionAnswer[]): Promise<RatingResponse> {
    const request = this.requestMapper.getQuoteRequest(questions, VehicleQuoteRequest)
    const result = await this.axios.post(`${this.apiUrl}/rating/Vehicle/update-quote/${encodeURIComponent(quoteReference)}`, this.jsonConvert.serialize(request, VehicleQuoteRequest))
    return this.jsonConvert.deserializeObject(result.data, RatingResponse)
  }

  async updateDynamicQuote(quoteReference: string, questions: IQuestionAnswer[], product: string): Promise<RatingResponse> {
    const request = this.requestMapper.getQuoteRequest(questions, DynamicQuoteRequest)
    const result = await this.axios.post(`${this.apiUrl}/rating/dynamic/${product}/update-quote/${encodeURIComponent(quoteReference)}`, this.jsonConvert.serialize(request, DynamicQuoteRequest))
    return this.jsonConvert.deserializeObject(result.data, RatingResponse)
  }

  async updatePropertyQuote(quoteReference: string, questions: IQuestionAnswer[]): Promise<RatingResponse> {
    const request = this.requestMapper.getQuoteRequest(questions, PropertyQuoteRequest)
    const result = await this.axios.post(`${this.apiUrl}/rating/property/update-quote/${encodeURIComponent(quoteReference)}`, this.jsonConvert.serialize(request, PropertyQuoteRequest))
    return this.jsonConvert.deserializeObject(result.data, RatingResponse)
  }

  async updateVehicleRenewalQuote(policyNumber: string, quoteReference: string, questions: IQuestionAnswer[]): Promise<RatingResponse> {
    const request = this.requestMapper.getQuoteRequest(questions, VehicleQuoteRequest)
    const result = await this.axios.post(`${this.apiUrl}/rating/Vehicle/update-renewal-quote/${encodeURIComponent(policyNumber)}/${encodeURIComponent(quoteReference)}`, this.jsonConvert.serialize(request, VehicleQuoteRequest))
    return this.jsonConvert.deserializeObject(result.data, RatingResponse)
  }

  async updatePropertyRenewalQuote(policyNumber: string, quoteReference: string, questions: IQuestionAnswer[]): Promise<RatingResponse> {
    const request = this.requestMapper.getQuoteRequest(questions, PropertyQuoteRequest)
    const result = await this.axios.post(`${this.apiUrl}/rating/Property/update-renewal-quote/${encodeURIComponent(policyNumber)}/${encodeURIComponent(quoteReference)}`, this.jsonConvert.serialize(request, PropertyQuoteRequest))
    return this.jsonConvert.deserializeObject(result.data, RatingResponse)
  }
}
