import { JsonObject, JsonProperty } from 'json2typescript'
import DateConverter from '../../converters/DateConverter'
import { DefinedListDetail } from './DefinedList'
import Model from './Model'
import Address from '@/services/models/Address'
import Claim from '@/services/models/Claim'
import Conviction from '@/services/models/Conviction'
import MedicalCondidion from '@/services/models/MedicalCondition'

@JsonObject('Driver')
export default class Driver extends Model {
  @JsonProperty('title', DefinedListDetail)
  title: DefinedListDetail

  @JsonProperty('firstName', String)
  firstName: string

  @JsonProperty('lastName', String)
  lastName: string

  @JsonProperty('dateOfBirth', DateConverter)
  dateOfBirth: Date

  @JsonProperty('drivingLicence', String)
  drivingLicence: string

  @JsonProperty('licenceDate', DateConverter)
  licenceDate: Date

  @JsonProperty('licenceType', DefinedListDetail)
  licenceType: DefinedListDetail

  @JsonProperty('employmentStatus', DefinedListDetail)
  employmentStatus: DefinedListDetail

  @JsonProperty('businessCategory', DefinedListDetail)
  businessCategory: DefinedListDetail

  @JsonProperty('occupation', DefinedListDetail)
  occupation: DefinedListDetail

  @JsonProperty('secondaryOccupation', DefinedListDetail)
  secondaryOccupation: DefinedListDetail

  @JsonProperty('hasAdvancedDrivingLicence', Boolean)
  hasAdvancedDrivingLicence: boolean

  @JsonProperty('advancedDrivingLicenceType', DefinedListDetail)
  advancedDrivingLicenceType: DefinedListDetail

  @JsonProperty('isOwnersClubMember', Boolean)
  isOwnersClubMember: boolean

  @JsonProperty('ownersClub', DefinedListDetail)
  ownersClub: DefinedListDetail

  @JsonProperty('residentSinceBirth', Boolean)
  residentSinceBirth: boolean

  @JsonProperty('residentSince', DateConverter)
  residentSince: Date

  @JsonProperty('noClaimsBonus', Number)
  noClaimsBonus: number

  @JsonProperty('maritalStatus', DefinedListDetail)
  maritalStatus: DefinedListDetail

  @JsonProperty('hasMedicalConditions', Boolean)
  hasMedicalConditions: boolean

  @JsonProperty('dvlaReportableMedicalConditions', DefinedListDetail)
  dvlaReportableMedicalConditions: DefinedListDetail

  @JsonProperty('claims', [Claim])
  claims: Claim[]

  @JsonProperty('prn', Number)
  prn: number | null

  @JsonProperty('psvLicenceNumber', String)
  psvLicenceNumber: string | null

  @JsonProperty('psvLicenceDate', DateConverter)
  psvLicenceDate: Date | null

  @JsonProperty('operatingInLondonArea', Boolean)
  operatingInLondonArea: boolean | null

  @JsonProperty('mainLicensingAuthority', DefinedListDetail)
  mainLicensingAuthority: DefinedListDetail

  @JsonProperty('otherLicensingAuthorities', DefinedListDetail)
  otherLicensingAuthorities: DefinedListDetail

  @JsonProperty('farePayingPassengers', Boolean)
  farePayingPassengers: boolean | null

  @JsonProperty('taxiBadgeHeldDate', DateConverter)
  taxiBadgeHeldDate: Date

  @JsonProperty('rideSharingApp', Boolean)
  rideSharingApp: boolean | null

  @JsonProperty('hasHadPolicyDeclaredVoid', Boolean)
  hasHadPolicyDeclaredVoid: boolean | null

  @JsonProperty('claimsFreeExperienceOnAnotherVehicle', Number)
  claimsFreeExperienceOnAnotherVehicle: number | null

  @JsonProperty('isDriverForUber', Boolean)
  isDriverForUber: boolean | null

  @JsonProperty('uberBranch', DefinedListDetail)
  uberBranch: DefinedListDetail

  @JsonProperty('uberProRating', DefinedListDetail)
  uberProRating: DefinedListDetail

  @JsonProperty('numberOfOtherVehiclesAvailable', DefinedListDetail)
  numberOfOtherVehiclesAvailable: DefinedListDetail

  @JsonProperty('otherVehiclesYouDrive', DefinedListDetail)
  otherVehiclesYouDrive: DefinedListDetail

  @JsonProperty('useOfAnotherCar', DefinedListDetail)
  useOfAnotherCar: DefinedListDetail

  @JsonProperty('otherVehiclesOwned', Boolean)
  otherVehiclesOwned: boolean | null

  @JsonProperty('numberOfHouseholdVehicles', Number)
  numberOfHouseholdVehicles: number | null

  @JsonProperty('hasConvictions', Boolean)
  hasConvictions: boolean | null = null

  @JsonProperty('hasCriminalConvictions', Boolean)
  hasCriminalConvictions: boolean | null = null

  @JsonProperty('hasHadPreviousPolicyCancelled', Boolean)
  hasHadPreviousPolicyCancelled: boolean | null = null

  @JsonProperty('liveInVehicleFullTime', Boolean)
  liveInVehicleFullTime: boolean | null = null

  @JsonProperty('hadInsuranceRefused', Boolean)
  hadInsuranceRefused: boolean | null = null

  @JsonProperty('drivingFrequency', DefinedListDetail)
  drivingFrequency: DefinedListDetail

  @JsonProperty('primaryTransport', Boolean)
  primaryTransport: boolean | null = null

  @JsonProperty('beenRequestedExtraPrecautions', Boolean)
  beenRequestedExtraPrecautions: boolean | null = null

  @JsonProperty('hasSecondaryEmployment', Boolean)
  hasSecondaryEmployment: boolean | null = null

  @JsonProperty('secondaryEmploymentStatus', DefinedListDetail)
  secondaryEmploymentStatus: DefinedListDetail

  @JsonProperty('secondaryBusinessCategory', DefinedListDetail)
  secondaryBusinessCategory: DefinedListDetail

  @JsonProperty('hasClaims', Boolean)
  hasClaims: boolean | null = null

  @JsonProperty('restrictedLicense', Boolean)
  restrictedLicense: boolean | null = null

  @JsonProperty('convictions', [Conviction])
  convictions: Conviction[]

  @JsonProperty('medicalConditions', [MedicalCondidion])
  medicalConditions: MedicalCondidion[]

  @JsonProperty('voidReason', String)
  voidReason: string

  @JsonProperty('hasCCJs', Boolean)
  hasCCJs: boolean | null = null

  @JsonProperty('hasPSVOLicence', Boolean)
  hasPSVOLicence: boolean | null = null

  @JsonProperty('psvoNamedProposer', Boolean)
  pSVONamedProposer: boolean | null = null

  @JsonProperty('psvoLicenceNumber', String)
  pSVOLicenceNumber: string | null = null

  @JsonProperty('psvoNamedProposerReason', String)
  pSVONamedProposerReason: string | null = null

  @JsonProperty('noPSVOLicenseReason', String)
  noPSVOLicenseReason: string | null = null

  constructor(
    id = 0,
    title: DefinedListDetail = new DefinedListDetail(),
    firstName = '',
    lastName = '',
    dateofBirth: Date = new Date(),
    drivingLicence = '',
    licenceDate = new Date(),
    licenceType: DefinedListDetail = new DefinedListDetail(),
    employmentStatus: DefinedListDetail = new DefinedListDetail(),
    businessCategory: DefinedListDetail = new DefinedListDetail(),
    occupation: DefinedListDetail = new DefinedListDetail(),
    secondaryOccupation: DefinedListDetail = new DefinedListDetail(),
    hasAdvancedDrivingLicence = false,
    advancedDrivingLicenceType: DefinedListDetail = new DefinedListDetail(),
    isOwnersClubMember = false,
    ownersClub: DefinedListDetail = new DefinedListDetail(),
    residentSinceBirth = false,
    residentSince = new Date(),
    noClaimsBonus = 0,
    maritalStatus = new DefinedListDetail(),
    hasMedicalConditions = false,
    dvlaReportableMedicalConditions = new DefinedListDetail(),
    claims: Claim[] = [],
    prn = null,
    mainLicensingAuthority: DefinedListDetail = new DefinedListDetail(),
    otherLicensingAuthorities: DefinedListDetail = new DefinedListDetail(),
    farePayingPassengers = false,
    taxiBadgeHeldDate = new Date(),
    rideSharingApp = false,
    hasHadPolicyDeclaredVoid = false,
    isDriverForUber = false,
    uberBranch: DefinedListDetail = new DefinedListDetail(),
    uberProRating: DefinedListDetail = new DefinedListDetail(),
    numberOfOtherVehiclesAvailable: DefinedListDetail = new DefinedListDetail(),
    otherVehiclesYouDrive = new DefinedListDetail(),
    useOfAnotherCar: DefinedListDetail = new DefinedListDetail(),
    numberOfHouseholdVehicles = null,
    beenRequestedExtraPrecautions = null,
    hasConvictions = null,
    liveInVehicleFullTime = null,
    drivingFrequency = new DefinedListDetail(),
    hadInsuranceRefused = null,
    primaryTransport = null,
    otherVehiclesOwned = false,
    hasCriminalConvictions = false,
    hasSecondaryEmployment = false,
    secondaryEmploymentStatus: DefinedListDetail = new DefinedListDetail(),
    secondaryBusinessCategory: DefinedListDetail = new DefinedListDetail(),
    hasClaims = false,
    restrictedLicense = false,
    convictions: Conviction[] = [],
    medicalConditions: MedicalCondidion[] = [],
    voidReason = '',
    hasCCJs = null,
    operatingInLondonArea = null,
    psvLicenceNumber = null,
    psvLicenceDate = null,
    claimsFreeExperienceOnAnotherVehicle = null,
    hasPSVOLicence = null,
    pSVONamedProposer = null,
    pSVOLicenceNumber = '',
    pSVONamedProposerReason = '',
    noPSVOLicenseReason = '',
  ) {
    super(id)
    this.title = title
    this.firstName = firstName
    this.lastName = lastName
    this.dateOfBirth = dateofBirth
    this.drivingLicence = drivingLicence
    this.licenceDate = licenceDate
    this.licenceType = licenceType
    this.employmentStatus = employmentStatus
    this.businessCategory = businessCategory
    this.occupation = occupation
    this.secondaryOccupation = secondaryOccupation
    this.hasAdvancedDrivingLicence = hasAdvancedDrivingLicence
    this.advancedDrivingLicenceType = advancedDrivingLicenceType
    this.isOwnersClubMember = isOwnersClubMember
    this.ownersClub = ownersClub
    this.residentSinceBirth = residentSinceBirth
    this.residentSince = residentSince
    this.noClaimsBonus = noClaimsBonus
    this.maritalStatus = maritalStatus
    this.hasMedicalConditions = hasMedicalConditions
    this.dvlaReportableMedicalConditions = dvlaReportableMedicalConditions
    this.claims = claims
    this.prn = prn
    this.mainLicensingAuthority = mainLicensingAuthority
    this.otherLicensingAuthorities = otherLicensingAuthorities
    this.farePayingPassengers = farePayingPassengers
    this.taxiBadgeHeldDate = taxiBadgeHeldDate
    this.rideSharingApp = rideSharingApp
    this.hasHadPolicyDeclaredVoid = hasHadPolicyDeclaredVoid
    this.isDriverForUber = isDriverForUber
    this.claimsFreeExperienceOnAnotherVehicle = claimsFreeExperienceOnAnotherVehicle
    this.uberBranch = uberBranch
    this.uberProRating = uberProRating
    this.numberOfOtherVehiclesAvailable = numberOfOtherVehiclesAvailable
    this.otherVehiclesYouDrive = otherVehiclesYouDrive
    this.useOfAnotherCar = useOfAnotherCar
    this.otherVehiclesOwned = otherVehiclesOwned
    this.numberOfHouseholdVehicles = numberOfHouseholdVehicles
    this.liveInVehicleFullTime = liveInVehicleFullTime
    this.drivingFrequency = drivingFrequency
    this.primaryTransport = primaryTransport
    this.beenRequestedExtraPrecautions = beenRequestedExtraPrecautions
    this.hasConvictions = hasConvictions
    this.hadInsuranceRefused = hadInsuranceRefused
    this.hasCriminalConvictions = hasCriminalConvictions
    this.hasSecondaryEmployment = hasSecondaryEmployment
    this.secondaryEmploymentStatus = secondaryEmploymentStatus
    this.secondaryBusinessCategory = secondaryBusinessCategory
    this.hasClaims = hasClaims
    this.restrictedLicense = restrictedLicense
    this.convictions = convictions
    this.medicalConditions = medicalConditions
    this.voidReason = voidReason
    this.hasCCJs = hasCCJs
    this.psvLicenceDate = psvLicenceDate
    this.psvLicenceNumber = psvLicenceNumber
    this.operatingInLondonArea = operatingInLondonArea
    this.hasPSVOLicence = hasPSVOLicence
    this.pSVONamedProposer = pSVONamedProposer
    this.pSVOLicenceNumber = pSVOLicenceNumber
    this.pSVONamedProposerReason = pSVONamedProposerReason
    this.noPSVOLicenseReason = noPSVOLicenseReason
  }
}

@JsonObject('ProposerDriver')
export class ProposerDriver extends Driver {
  @JsonProperty('address', Address)
  address: Address

  @JsonProperty('homeOwner', Boolean, true)
  homeOwner: boolean | null = null

  constructor(
    address = new Address(),
    homeOwner = false,
  ) {
    super()
    this.address = address
    this.homeOwner = homeOwner
  }
}
