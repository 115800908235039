import { JsonObject, JsonProperty } from 'json2typescript'
import Model from './Model'

@JsonObject('VeriskDetail')
export default class VeriskDetail extends Model {
  @JsonProperty('veriskData', String)
  veriskData: string

  constructor(
    id = 0,
    veriskData = '',
  ) {
    super(id)
    this.veriskData = veriskData
  }
}
