import { JsonObject, JsonProperty } from 'json2typescript'
import Model from './Model'

@JsonObject('TransactionType')
export default class TransactionType extends Model {
  @JsonProperty('name', String)
  name: string

  constructor(
    id = 0,
    name = '',
  ) {
    super(id)
    this.name = name
  }
}
