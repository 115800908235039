import { JsonObject, JsonProperty } from 'json2typescript'
import type WebPlatformEnum from '../enums/WebPlatformEnum'

@JsonObject('WebPushSubscriptionKey')
export class WebPushSubscriptionKey {
  @JsonProperty('auth', String)
  auth: string

  @JsonProperty('p256dh', String)
  p256dh: string

  constructor(
    auth = '',
    p246dh = '',
  ) {
    this.auth = auth
    this.p256dh = p246dh
  }
}

@JsonObject('WebPushSubscription')
export default class WebPushSubscription {
  @JsonProperty('endpoint', String)
  endpoint: string

  @JsonProperty('webPlatform', String)
  webPlatform: WebPlatformEnum

  @JsonProperty('keys', WebPushSubscriptionKey)
  keys: WebPushSubscriptionKey

  constructor(
    endpoint: string,
    webPlatform: WebPlatformEnum,
    keys: WebPushSubscriptionKey,
  ) {
    this.endpoint = endpoint
    this.webPlatform = webPlatform
    this.keys = keys
  }
}
