import { JsonObject, JsonProperty } from 'json2typescript'
import SpecifiedItem from './SpecifiedItem'
import PropertyClaim from './PropertyClaim'
import DateConverter from '@/converters/DateConverter'
import Address from '@/services/models/Address'

@JsonObject('PropertyValue')
export default class Property {
  @JsonProperty('propertyClaims', [PropertyClaim])
    propertyClaims: PropertyClaim[] = []

  @JsonProperty('address', Address)
    address: Address = new Address()

  @JsonProperty('propertyType', String)
    propertyType: string | null = null

  @JsonProperty('subType', String)
    subType: string | null = null

  @JsonProperty('yearOfBuild', Number)
   yearOfBuild = 0

  @JsonProperty('numberOfBedrooms', String)
    numberOfBedrooms: string | null = null

  @JsonProperty('numberOfBathrooms', String)
    numberOfBathrooms: string | null = null

  @JsonProperty('numberOfOtherRooms', String)
    numberOfOtherRooms: string | null = null

  @JsonProperty('numberOfReceptionRooms', String)
    numberOfReceptionRooms: string | null = null

  @JsonProperty('distanceFromWater', String)
    distanceFromWater: string | null = null

  @JsonProperty('unspecifiedItemsValue', String)
    unspecifiedItemsValue: string | null = null

  @JsonProperty('costToRebuild', Number)
    costToRebuild: number | null = null

  @JsonProperty('inFloodArea', Boolean)
    inFloodArea: boolean | null = null

  @JsonProperty('typeOfLocks', String)
    typeOfLocks: string | null = null

  @JsonProperty('animalsInProperty', String)
    animalsInProperty: string | null = null

  @JsonProperty('whenIsPropertyUnoccupied', String)
    whenIsPropertyUnoccupied: string | null = null

  @JsonProperty('howLongIsPropertyUnoccupied', String)
    howLongIsPropertyUnoccupied: string | null = null

  @JsonProperty('inVerifiedNeighbourhoodWatchArea', Boolean)
    inVerifiedNeighbourhoodWatchArea: boolean | null = null

  @JsonProperty('burglerAlarmFitted', Boolean)
    burglerAlarmFitted: boolean | null = null

  @JsonProperty('alarmProfessionallyInstalledMaintained', Boolean)
    alarmProfessionallyInstalledMaintained: boolean | null = null

  @JsonProperty('costToReplaceContents', Number)
    costToReplaceContents: number | null = null

  @JsonProperty('insuredItemsCarriedOutsideProperty', Boolean)
    insuredItemsCarriedOutsideProperty: boolean | null = null

  @JsonProperty('isMainHome', String)
    isMainHome: string | null = null

  @JsonProperty('listedBuilding', String)
    listedBuilding: string | null = null

  @JsonProperty('own', String)
    own: string | null = null

  @JsonProperty('whenAreYouAtProperty', String)
    whenAreYouAtProperty: string | null = null

  @JsonProperty('howLongAtProperty', String)
    howLongAtProperty: string | null = null

  @JsonProperty('whoLivesAtProperty', String)
    whoLivesAtProperty: string | null = null

  @JsonProperty('howManyPeopleWhoArentRelated', String)
    howManyPeopleWhoArentRelated: string | null = null

  @JsonProperty('howManyAdults', String)
    howManyAdults: string | null = null

  @JsonProperty('howManyChildren', String)
    howManyChildren: string | null = null

  @JsonProperty('lessSecureDoorType', String)
    lessSecureDoorType: string | null = null

  @JsonProperty('additionalExteriorDoorType', String)
    additionalExteriorDoorType: string | null = null

  @JsonProperty('workingSmokeAlarmType', String)
    workingSmokeAlarmType: string | null = null

  @JsonProperty('anySmokers', Boolean)
    anySmokers: boolean | null = null

  @JsonProperty('anyBusinessUse', String)
    anyBusinessUse: string | null = null

  @JsonProperty('includeReplacementLocksAndKeys', Boolean)
    includeReplacementLocksAndKeys: boolean | null = null

  @JsonProperty('typeOfPolicy', String)
    typeOfPolicy: string | null = null

  @JsonProperty('voluntaryExcessValue', String)
    voluntaryExcessValue: string | null = null

  @JsonProperty('typeOfAlarm', String)
    typeOfAlarm: string | null = null

  @JsonProperty('tenancyType', String)
    tenancyType: string | null = null

  @JsonProperty('furnishingsCovered', Boolean)
    furnishingsCovered: boolean | null = null

  @JsonProperty('externalWallConstruction', String)
    externalWallConstruction: string | null = null

  @JsonProperty('hasFlatRoof', Boolean)
    hasFlatRoof: boolean | null = null

  @JsonProperty('roofConstruction', String)
    roofConstruction: string | null = null

  @JsonProperty('flatRoofPercentage', String)
    flatRoofPercentage: string | null = null

  @JsonProperty('nearTrees', Boolean)
    nearTrees: boolean | null = null

  @JsonProperty('hasBeenFlooded', Boolean)
    hasBeenFlooded: boolean | null = null

  @JsonProperty('hasExternalWallCracks', Boolean)
    hasExternalWallCracks: boolean | null = null

  @JsonProperty('hasBeenUnderpinned', Boolean)
    hasBeenUnderpinned: boolean | null = null

  @JsonProperty('hasSubsidence', Boolean)
    hasSubsidence: boolean | null = null

  @JsonProperty('inGoodStateOfRepair', Boolean)
    inGoodStateOfRepair: boolean | null = null

  @JsonProperty('anyCurrentBuildingWork', Boolean)
    anyCurrentBuildingWork: boolean | null = null

  @JsonProperty('hasKeyOperatedLocksOnAllWindows', Boolean)
    hasKeyOperatedLocksOnAllWindows: boolean | null = null

  @JsonProperty('hasHighValueItems', Boolean)
    hasHighValueItems: boolean | null = null

  @JsonProperty('includeAccidentalDamageForBuildings', Boolean)
    includeAccidentalDamageForBuildings: boolean | null = null

  @JsonProperty('includeAccidentalDamageForContents', Boolean)
    includeAccidentalDamageForContents: boolean | null = null

  @JsonProperty('hasBikesToCover', Boolean)
    hasBikesToCover: boolean | null = null

  @JsonProperty('bikeDescription', String)
    bikeDescription = ''

  @JsonProperty('bikesValue', Number)
    bikesValue = 0

  @JsonProperty('hasBeenExtended', Boolean)
    hasBeenExtended: boolean | null = null

  @JsonProperty('typeOfHeating', String)
    typeOfHeating: string | null = null

  @JsonProperty('hasFixedSafe', Boolean)
    hasFixedSafe: boolean | null = null

  @JsonProperty('hasUnspecifiedItems', Boolean)
    hasUnspecifiedItems: boolean | null = null

  @JsonProperty('totalValueOfUnspecifiedItems', Number)
    totalValueOfUnspecifiedItems = 0

  @JsonProperty('purchasedDate', DateConverter)
    purchasedDate: Date | null = new Date()

  @JsonProperty('notYetPurchased', Boolean)
    notYetPurchased: boolean | null = null

  @JsonProperty('specifiedItems', [SpecifiedItem])
    specifiedItems: SpecifiedItem[] = []
}
