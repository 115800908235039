import { JsonObject, JsonProperty } from 'json2typescript'
import { DefinedListDetail } from './DefinedList'
import Model from './Model'
import StaticFile from './StaticFile'

@JsonObject('SchemeDocument')
export default class SchemeDocument extends Model {
  @JsonProperty('staticFile', StaticFile)
  staticFile: StaticFile

  @JsonProperty('customerFacingName', String)
  customerFacingName: string

  @JsonProperty('customDefinedListDetail', DefinedListDetail)
  customDefinedListDetail: DefinedListDetail

  constructor(
    id = 0,
    staticFile: StaticFile = new StaticFile(),
    customerFacingName = '',
    customDefinedListDetail = new DefinedListDetail(),
  ) {
    super(id)
    this.staticFile = staticFile
    this.customerFacingName = customerFacingName
    this.customDefinedListDetail = customDefinedListDetail
  }
}
