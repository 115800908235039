import moment from 'moment'
import Utils from './Utils'
import ProductEnum from '@/services/enums/ProductEnum'
import UnknownProductError from '@/services/error/UnknownProductError'
import GapQuoteDetails from '@/services/models/GapQuoteDetails'
import type QuoteDetails from '@/services/models/QuoteDetails'
import type SchemeQuoteResult from '@/services/models/SchemeQuoteResult'
import Icon from '@/view-models/Icon'

export default class BarryGraingerUtils extends Utils {
  getRenewalDate<T extends SchemeQuoteResult>(quote: QuoteDetails<T>): Date {
    if (!(quote instanceof GapQuoteDetails))
      return moment(quote.effectiveDate).add(1, 'year').add(-1, 'day').toDate()
    const durationString = (quote as GapQuoteDetails).coverTerm.uniqueId.split('.')[1]

    const components = durationString.split(/([0-9]+)/).filter(x => x.length > 0)

    return moment(quote.effectiveDate).add(Number.parseInt(components[0], 10), components[1] as moment.unitOfTime.Base).toDate()
  }

  getProductIcon(product: ProductEnum): Icon {
    switch (product) {
      case ProductEnum.motorCaravan:
        return new Icon('rv-truck')
      case ProductEnum.gapInsurance:
        return new Icon('icon-gap-car', 'wrapper')
      case ProductEnum.touringCaravan:
        return new Icon('icon-touring-caravan', 'wrapper')
      default:
        throw new UnknownProductError(product)
    }
  }
}
