import { DialogProgrammatic, LoadingProgrammatic, ModalProgrammatic } from 'buefy'
import type Vue from 'vue'
import { LinkConfig } from './IConfiguration'
import type {
  ILinkConfigActionSpecification,
  ILinkConfigSpecification,
} from '@/configurations/config-objects/LinkConfigSpecification'
import {
  LinkConfigActionTypes,
} from '@/configurations/config-objects/LinkConfigSpecification'
import router from '@/router'
import type PolicySummary from '@/services/values/PolicySummary'
import type Policy from '@/services/models/Policy'
import { ChatClient } from '@/plugins/chat'
import LinkConfigModal from '@/components/LinkConfigModal.vue'
import type ClaimSummary from '@/services/values/ClaimSummary'

export default class LinkConfigFactory {
  static create(config: ILinkConfigSpecification, policy: PolicySummary | Policy | null = null, claim: ClaimSummary | null = null): LinkConfig {
    const action = LinkConfigFactory.createAction(config.action, policy, claim)

    if (config.action.modalConfig) {
      const actionModal = (vue: Vue) => {
        ModalProgrammatic.open({
          parent: vue,
          component: LinkConfigModal,
          hasModalCard: true,
          canCancel: ['escape', 'outside'],
          props: { title: config.action.modalConfig!.title, message: config.action.modalConfig!.message, buttons: config.action.modalConfig!.buttons.map(b => this.create(b, policy)), isRenewalOptOutModal: config.action.modalConfig!.isRenewalOptOutModal, policy },
        })
      }
      return new LinkConfig(config.name, actionModal)
    }

    return new LinkConfig(config.name, action)
  }

  private static createAction(actionConfig: ILinkConfigActionSpecification, policy: PolicySummary | Policy | null = null, claim: ClaimSummary | null = null): (vue: Vue) => void {
    let action = (_vue: Vue) => {
    }
    if (actionConfig.type === LinkConfigActionTypes.redirect) {
      action = () => {
        window.location.href = actionConfig.url!
      }
    }
    else if (actionConfig.type === LinkConfigActionTypes.windowOpen) {
      action = () => {
        window.open(actionConfig.url!)
      }
    }
    else if (actionConfig.type === LinkConfigActionTypes.routerPush && policy) {
      action = () => {
        router.push({
          name: actionConfig.url!,
          params: { product: policy.product, policyNumber: policy.policyNumber, quoteReference: policy.renewalQuoteReference!, code: (policy as Policy)?.scheme?.code ?? (policy as PolicySummary)?.schemeName },
          query: actionConfig.queryParams!,
        })
      }
    }
    else if (actionConfig.type === LinkConfigActionTypes.chat) {
      action = () => {
        ChatClient.open()
      }
    }
    else if (actionConfig.type === LinkConfigActionTypes.alert) {
      action = () => {
        DialogProgrammatic.alert(actionConfig.dialogConfig!)
      }
    }
    else if (actionConfig.type === LinkConfigActionTypes.emit) {
      action = (vue: Vue) => {
        vue.$events.emit(actionConfig.url!)
      }
    }
    else if (actionConfig.type === LinkConfigActionTypes.integrationJWT) {
      action = async (vue: Vue) => {
        const loading = LoadingProgrammatic.open({ isFullPage: true, canCancel: false })
        const jwt = await vue.$services.identityProvider.getIntegrationJWT(actionConfig.integrationId!, policy != null ? { policyNumber: policy.policyNumber } : null)
        loading.close()

        const url = new URL(actionConfig.url!)
        url.searchParams.set('token', jwt)

        if (claim)
          url.searchParams.set('claimNumber', claim.claimNumber)

        if (this.isSafariBrowser())
          window.location.href = url.href

        else
          window.open(url.href)
      }
    }
    return action
  }

  static isSafariBrowser(): boolean {
    const userAgentString = window.navigator.userAgent
    if (userAgentString.toLowerCase().includes('safari')
      && !userAgentString.toLowerCase().includes('chrome')
      && !userAgentString.toLowerCase().includes('crios'))
      return true

    return false
  }
}
