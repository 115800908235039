import { JsonObject, JsonProperty } from 'json2typescript'
import VehicleDriver from './VehicleDriver'
import Address from '@/services/models/Address'

@JsonObject('VehicleProposerValue')
export default class VehicleProposer extends VehicleDriver {
  @JsonProperty('isCompany', Boolean)
    isCompany = false

  @JsonProperty('companyName', String)
    companyName: string | null = null

  @JsonProperty('address', Address)
    address: Address = new Address()

  @JsonProperty('employmentStatus', String, true)
    employmentStatus: string | null = null

  @JsonProperty('occupation', String, true)
    occupation: string | null = null

  @JsonProperty('businessCategory', String, true)
    businessCategory: string | null = null

  @JsonProperty('clubMembership', String, true)
    clubMembership: string | null = null

  @JsonProperty('hadInsuranceRefusedCancelledOrDeclined', Boolean)
    hadInsuranceRefusedCancelledOrDeclined: boolean | null = null

  @JsonProperty('hasUnspentNonMotoringConvictions', Boolean)
    hasUnspentNonMotoringConvictions: boolean | null = null

  @JsonProperty('hasDeclaredBankruptcy', Boolean)
    hasDeclaredBankruptcy: boolean | null = null

  @JsonProperty('isCompanyVehicle', Boolean)
    isCompanyVehicle: boolean | null = null

  @JsonProperty('hasAdditionalDrivers', Boolean)
    hasAdditionalDrivers = false

  @JsonProperty('hasConfirmedAssumptions', Boolean)
    hasConfirmedAssumptions = false

  @JsonProperty('hadPreviousInsurance', Boolean)
    hadPreviousInsurance = false

  @JsonProperty('currentInsurer', String, true)
    currentInsurer: string | null = null

  @JsonProperty('hasAccessToProposerLicence', Boolean)
    hasAccessToProposerLicence: boolean | null = null
}
