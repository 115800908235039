import { defineStore } from 'pinia'

interface State {
  token: string | null
  type: 'auth' | 'auth-prospect' | null
}

const useAuthServiceStore = defineStore('auth-service', {
  state: (): State => ({
    token: null,
    type: null,
  }),
  actions: {
    setToken(token: string, type: 'auth' | 'auth-prospect'): void {
      this.token = token
      this.type = type
    },
    clearToken(): void {
      this.token = null
      this.type = null
    },
  },
})

export default useAuthServiceStore
