import type { JsonCustomConvert } from 'json2typescript'
import { JsonConverter } from 'json2typescript'
import type ProductEnum from '@/services/enums/ProductEnum'

@JsonConverter
export default class ProductEnumConverter implements JsonCustomConvert<ProductEnum> {
  serialize(product: ProductEnum): string {
    return product.toString()
  }

  deserialize(product: string): ProductEnum {
    return product as ProductEnum
  }
}
