import { JsonObject, JsonProperty } from 'json2typescript'
import Address from '../models/Address'
import PersonalDetails from './PersonalDetails'
import DateConverter from '@/converters/DateConverter'

@JsonObject('RiskProposerValue')
export default class RiskProposer extends PersonalDetails {
  @JsonProperty('emailAddress', String)
    emailAddress = ''

  @JsonProperty('telephoneNumber', String)
    telephoneNumber = ''

  @JsonProperty('employmentStatus', String)
    employmentStatus: string | null = null

  @JsonProperty('occupation', String)
    occupation: string | null = null

  @JsonProperty('businessType', String)
    businessType: string | null = null

  @JsonProperty('maritalStatus', String)
    maritalStatus: string | null = null

  @JsonProperty('studentType', String)
    studentType: string | null = null

  @JsonProperty('address', Address)
    address = new Address()

  @JsonProperty('ukResident', Boolean)
    ukResident: boolean | null = true

  @JsonProperty('isHomeOwner', Boolean)
    isHomeOwner: boolean | null = null

  @JsonProperty('hadInsuranceRefusedCancelledOrDeclined', Boolean)
    hadInsuranceRefusedCancelledOrDeclined: boolean | null = null

  @JsonProperty('hasUnspentNonMotoringConvictions', Boolean)
    hasUnspentNonMotoringConvictions: boolean | null = null

  @JsonProperty('hasDeclaredBankruptcy', Boolean)
    hasDeclaredBankruptcy: boolean | null = null

  @JsonProperty('hadPreviousInsurance', Boolean)
    hadPreviousInsurance: boolean | null = null

  @JsonProperty('voluntaryExcess', String)
    voluntaryExcess: string | null = null

  @JsonProperty('voluntaryExcess2', String)
    voluntaryExcess2: string | null = null

  @JsonProperty('isPartTime', Boolean)
    isPartTime: boolean | null = null

  @JsonProperty('hadSpecialTermsImposed', Boolean)
    hadSpecialTermsImposed: boolean | null = null

  @JsonProperty('isClubMember', Boolean)
    isClubMember: boolean | null = null

  @JsonProperty('club', String)
    club: string | null = null

  @JsonProperty('hasSecondaryEmployment', Boolean)
    hasSecondaryEmployment: boolean | null = null

  @JsonProperty('secondaryOccupation', String)
    secondaryOccupation: string | null = null

  @JsonProperty('secondaryBusinessType', String)
    secondaryBusinessType: string | null = null

  @JsonProperty('secondaryEmploymentStatus', String)
    secondaryEmploymentStatus: string | null = null

  @JsonProperty('ukResidentSinceDate', DateConverter)
    ukResidentSinceDate: Date = new Date()

  @JsonProperty('hasBeenRequestedExtraPrecautions', Boolean)
    hasBeenRequestedExtraPrecautions: boolean | null = null

  @JsonProperty('hasClaims', Boolean)
    hasClaims: boolean | null = null

  @JsonProperty('authorisedPersonsName', String)
    authorisedPersonsName = ''
}
