import { JsonObject, JsonProperty } from 'json2typescript'
import DateConverter from '../../converters/DateConverter'
import { DefinedListDetail } from './DefinedList'
import Model from './Model'

@JsonObject('MedicalCondition')
export default class MedicalCondition extends Model {
  @JsonProperty('incidentDate', DateConverter)
  incidentDate: Date

  @JsonProperty('medicalCondition', DefinedListDetail)
  medicalCondition: DefinedListDetail

  @JsonProperty('hasInformedDVLAOfMedicalConditionsAndDisabilities', Boolean)
  hasInformedDVLAOfMedicalConditionsAndDisabilities: boolean

  @JsonProperty('dvlaReportableMedicalConditions', DefinedListDetail)
  dvlaReportableMedicalConditions: DefinedListDetail

  constructor(
    id = 0,
    incidentDate = new Date(),
    medicalCondition = new DefinedListDetail(),
    hasInformedDVLAOfMedicalConditionsAndDisabilities = false,
    dvlaReportableMedicalConditions = new DefinedListDetail(),
  ) {
    super(id)
    this.incidentDate = incidentDate
    this.medicalCondition = medicalCondition
    this.hasInformedDVLAOfMedicalConditionsAndDisabilities = hasInformedDVLAOfMedicalConditionsAndDisabilities
    this.dvlaReportableMedicalConditions = dvlaReportableMedicalConditions
  }
}
