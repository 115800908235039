import moment from 'moment'
import Utils from './Utils'
import GapQuoteDetails from '@/services/models/GapQuoteDetails'
import type QuoteDetails from '@/services/models/QuoteDetails'
import type SchemeQuoteResult from '@/services/models/SchemeQuoteResult'
import { CarQuoteDetails } from '@/services/models/CarQuoteDetails'
import type IRiskService from '@/services/interfaces/IRiskService'
import ProductEnum from '@/services/enums/ProductEnum'

export default class WrapperUtils extends Utils {
  getRenewalDate<T extends SchemeQuoteResult>(quote: QuoteDetails<T>): Date {
    if ((!(quote instanceof GapQuoteDetails) && !(quote instanceof CarQuoteDetails)) || !quote.coverTerm)
      return moment(quote.effectiveDate).add(1, 'year').add(-1, 'day').toDate()
    const durationString = (quote as GapQuoteDetails | CarQuoteDetails).coverTerm.uniqueId.split('.')[1]

    const components = durationString.split(/([0-9]+)/).filter(x => x.length > 0)

    return moment(quote.effectiveDate).add(Number.parseInt(components[0], 10), components[1] as moment.unitOfTime.Base).toDate()
  }

  async getProductFromQuoteReference(quoteReference: string, riskService: IRiskService): Promise<ProductEnum | null> {
    const risk = await riskService.getByQuoteReference(quoteReference, ProductEnum.modernCar)
    if (risk !== null && risk.productUniqueId === ProductEnum.modernCar)
      return ProductEnum.modernCar
    return null
  }

  async handleBrandSwitching(product: ProductEnum | null): Promise<void> {
    await import(`@/styles/wrapper-oneday/global.scss`)
    await import(`@/styles/wrapper/global.scss`)

    if (this.useAlternateBranding(product))
      this.applyOneDayBranding()
    else
      this.applyWrapperBranding()
  }

  useAlternateBranding(product: ProductEnum | null): boolean {
    return window.location.pathname.includes('/ModernCar') || product === ProductEnum.modernCar
  }

  private applyOneDayBranding() {
    document.documentElement.classList.add('branding-one-day')
    document.documentElement.classList.remove('branding-wrapper')
    this.setFavIcon('/assets/img/wrapper-oneday/favicon-32x32.png')
  }

  private applyWrapperBranding() {
    document.documentElement.classList.add('branding-wrapper')
    document.documentElement.classList.remove('branding-one-day')
    this.setFavIcon('/assets/img/wrapper/favicon-32x32.png')
  }

  private setFavIcon(path: string) {
    const link = document.querySelectorAll('[type="image/x-icon"]')[0]
    if (link)
      link.setAttribute('href', path)
  }

  getPaymentIcon(paymentIcon: string): string {
    return `/assets/img/wrapper/${paymentIcon}.jpg`
  }
}
