import { JsonObject, JsonProperty } from 'json2typescript'
import VehicleModification from './VehicleModification'
import DateConverter from '@/converters/DateConverter'
import Address from '@/services/models/Address'

@JsonObject('VehicleRiskVehicleValue')
export default class VehicleRiskVehicle {
  @JsonProperty('vehicleType', String)
    vehicleType = ''

  @JsonProperty('abiCode', String)
    abiCode = ''

  @JsonProperty('vin', String)
    vin = ''

  @JsonProperty('registration', String)
    registration = ''

  @JsonProperty('make', String)
    make = ''

  @JsonProperty('model', String)
    model = ''

  @JsonProperty('cubicCapacity', Number)
    cubicCapacity = 0

  @JsonProperty('value', Number)
    value = 0

  @JsonProperty('yearOfMake', Number)
    yearOfMake = 0

  @JsonProperty('riderWillCarryPillion', Boolean)
    riderWillCarryPillion: boolean | null = null

  @JsonProperty('annualMileage', String)
    annualMileage = ''

  @JsonProperty('isModified', Boolean)
    isModified = false

  @JsonProperty('modifications', [VehicleModification])
    modifications: VehicleModification[] = []

  @JsonProperty('isOwnedByProposer', String)
    isOwnedByProposer = ''

  @JsonProperty('lengthOfOwnership', String)
    lengthOfOwnership = ''

  @JsonProperty('classOfUse', String)
    classOfUse = ''

  @JsonProperty('isPrivatelyStored', Boolean)
    isPrivatelyStored = false

  @JsonProperty('vehicleStorage', String, true)
    vehicleStorage: string | null = null

  @JsonProperty('purchaseDate', DateConverter)
    purchaseDate: Date | null = new Date()

  @JsonProperty('voluntaryExcess', String)
    voluntaryExcess = ''

  @JsonProperty('securityDevice', String)
    securityDevice = ''

  @JsonProperty('europeanCover', String)
    europeanCover = ''

  @JsonProperty('address', Address)
    address: Address = new Address()

  @JsonProperty('vehicleCondition', String)
    vehicleCondition = ''
}
