import { JsonObject, JsonProperty } from 'json2typescript'

@JsonObject('SummaryItem')
export default class SummaryItem {
  @JsonProperty('name', String)
  name = ''

  @JsonProperty('value', String)
  value = ''
}
