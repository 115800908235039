import { JsonObject, JsonProperty } from 'json2typescript'
import PaymentRequest from './PaymentRequest'
import DateConverter from '@/converters/DateConverter'

@JsonObject('ConfirmCancellationRequest')
export default class ConfirmCancellationRequest {
  @JsonProperty('effectiveDate', DateConverter)
  effectiveDate: Date = new Date()

  @JsonProperty('cancellationReason', Number)
  cancellationReason = 0

  @JsonProperty('currentMileage', Number, true)
  currentMileage: number | null = null

  @JsonProperty('payment', PaymentRequest, true)
  payment: PaymentRequest | null = null

  @JsonProperty('cantAfford', Boolean, true)
  cantAfford: boolean | null = null

  @JsonProperty('paymentDueDate', DateConverter, true)
  paymentDueDate: Date | null = null
}
