import { JsonObject, JsonProperty } from 'json2typescript'

@JsonObject('NCDStepBackRule')
export default class NCDStepBackRule {
  @JsonProperty('newNCD', Number)
  newNCD: number

  @JsonProperty('numberOfClaims', Number)
  numberOfClaims: number

  @JsonProperty('numberOfYears', Number)
  numberOfYears: number

  constructor(
    newNCD = 0,
    numberOfClaims = 0,
    numberOfYears = 0,
  ) {
    this.newNCD = newNCD
    this.numberOfClaims = numberOfClaims
    this.numberOfYears = numberOfYears
  }
}
