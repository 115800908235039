import { JsonObject, JsonProperty } from 'json2typescript'
import DateConverter from '../../converters/DateConverter'
import Car from './Car'
import { GapProposer } from './Driver'
import QuoteRequest from './QuoteRequest'

@JsonObject('GapQuoteRequest')
export default class GapQuoteRequest extends QuoteRequest {
  @JsonProperty('car', Car)
  car: Car = new Car()

  @JsonProperty('proposer', GapProposer)
  proposer: GapProposer = new GapProposer()

  @JsonProperty('gapVehicleType', String, true)
  gapVehicleType: string | null = null

  @JsonProperty('vehiclePurchaseFrom', String, true)
  vehiclePurchaseFrom: string | null = null

  @JsonProperty('vehiclePaymentType', String, true)
  vehiclePaymentType: string | null = null

  @JsonProperty('currentMileage', Number, true)
  currentMileage: number | null = null

  @JsonProperty('invoiceValue', Number, true)
  invoiceValue: number | null = null

  @JsonProperty('deposit', Number, true)
  deposit: number | null = null

  @JsonProperty('totalFinanced', Number, true)
  totalFinanced: number | null = null

  @JsonProperty('finalPayment', Number, true)
  finalPayment: number | null = null

  @JsonProperty('vehicleCollectionDate', DateConverter)
  vehicleCollectionDate: Date | null = null

  @JsonProperty('vehiclePurchaseDate', DateConverter)
  vehiclePurchaseDate: Date | null = null

  @JsonProperty('gapDamagedBeyondRepair', Boolean, true)
  gapDamagedBeyondRepair: boolean | null = null

  @JsonProperty('confirmGreyImport', String, true)
  confirmGreyImport: string | null = null

  @JsonProperty('confirmAnyModifications', String, true)
  confirmAnyModifications: string | null = null

  @JsonProperty('confirmVehicleUse', String, true)
  confirmVehicleUse: string | null = null

  @JsonProperty('confirmFullyComp', String, true)
  confirmFullyComp: string | null = null

  @JsonProperty('confirmCommercialType', String, true)
  confirmCommercialType: string | null = null

  @JsonProperty('purchaseVATRegistered', Boolean, true)
  purchaseVATRegistered: boolean | null = null

  @JsonProperty('noClaimsBonus', Number, true)
  noClaimsBonus: number | null = null

  @JsonProperty('coverTerm', String, true)
  coverTerm: string | null = null

  @JsonProperty('needCoverForAdditionalDriver', Boolean, true)
  needCoverForAdditionalDriver: boolean | null = null

  @JsonProperty('includeCoverForVehicleStolen', Boolean, true)
  includeCoverForVehicleStolen: boolean | null = null

  @JsonProperty('additionalDrivers', Boolean, true)
  additionalDrivers: boolean | null = null

  @JsonProperty('includeCoverForAddedExtra', Boolean, true)
  includeCoverForAddedExtra: boolean | null = null

  @JsonProperty('confirmRegisteredKeeper', String, true)
  confirmRegisteredKeeper: string | null = null

  @JsonProperty('insuringInNameOfCompany', Boolean, true)
  insuringInNameOfCompany: boolean | null = null

  @JsonProperty('companyName', String, true)
  companyName: string | null = null
}

@JsonObject('GapMTAQuoteRequest')
export class GapMTAQuoteRequest extends GapQuoteRequest {
  @JsonProperty('reasonForChange', String)
  reasonForChange = ''
}
