import type _Vue from 'vue'
import type { Snippet } from '@microsoft/applicationinsights-web'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import type { Route } from 'vue-router'
import type VueRouter from 'vue-router'

export interface IApplicationInsightsConfig extends Snippet {
  router: VueRouter
}

export class VueApplicationInsights extends ApplicationInsights {
  constructor(config: Snippet, router: VueRouter) {
    super(config)
    this.loadAppInsights()

    router.beforeEach((to, from, next) => {
      this.startTrackPage(this.getName(to))
      next()
    })

    router.afterEach((to, from) => {
      if (from.name) {
        const url = `${location.protocol}//${location.host}${from.fullPath}`
        this.stopTrackPage(this.getName(from), url)
      }
    })
  }

  private getName(route: Route) {
    return `VUE/${route.name}`
  }
}

export default function ApplicationInsightsPlugin(Vue: typeof _Vue, config: IApplicationInsightsConfig): void {
  const appInsights = new VueApplicationInsights(config, config.router)

  Vue.prototype.$appInsights = appInsights
}
