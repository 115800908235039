import { JsonObject, JsonProperty } from 'json2typescript'
import Model from './Model'
import CancellationReason from './CancellationReason'

@JsonObject('ProductNavigation')
export class ProductNavigation {
  @JsonProperty('name', String)
  name: string

  @JsonProperty('pageId', String)
  pageId: string

  constructor(
    name = '',
    pageId = '',
  ) {
    this.name = name
    this.pageId = pageId
  }
}

@JsonObject('Product')
export default class Product extends Model {
  @JsonProperty('name', String)
  name: string

  @JsonProperty('displayName', String)
  displayName: string

  @JsonProperty('productCode', String)
  productCode: string

  @JsonProperty('uniqueId', String)
  uniqueId: string

  @JsonProperty('active', Boolean)
  active: boolean

  @JsonProperty('navigationJourneyPortal', [ProductNavigation])
  navigationJourneyPortal: ProductNavigation[]

  @JsonProperty('policyChangeJourneyPortal', [ProductNavigation])
  policyChangeJourneyPortal: ProductNavigation[]

  @JsonProperty('renewalJourneyPortal', [ProductNavigation])
  renewalJourneyPortal: ProductNavigation[]

  @JsonProperty('cancellationReasons', [CancellationReason])
  cancellationReasons: CancellationReason[]

  constructor(
    id = 0,
    name = '',
    displayName = '',
    productCode = '',
    uniqueId = '',
    active = true,
    navigationJourneyPortal: ProductNavigation[] = [],
    policyChangeJourneyPortal: ProductNavigation[] = [],
    renewalJourneyPortal: ProductNavigation[] = [],
    cancellationReasons: CancellationReason[] = [],
  ) {
    super(id)
    this.name = name
    this.displayName = displayName
    this.productCode = productCode
    this.uniqueId = uniqueId
    this.active = active
    this.navigationJourneyPortal = navigationJourneyPortal
    this.policyChangeJourneyPortal = policyChangeJourneyPortal
    this.renewalJourneyPortal = renewalJourneyPortal
    this.cancellationReasons = cancellationReasons
  }
}
