import { JsonObject, JsonProperty } from 'json2typescript'
import Model from '../Model'

@JsonObject('VehicleClaimModel')
export default class VehicleClaim extends Model {
  @JsonProperty('faultClaim', Boolean)
    faultClaim: boolean

  constructor(
        id = 0,
        faultClaim = false,
  ) {
    super(id)
    this.faultClaim = faultClaim
  }
}
