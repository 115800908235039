import { JsonObject, JsonProperty } from 'json2typescript'
import Model from './Model'

@JsonObject('DefinedListDetail')
export class DefinedListDetail extends Model {
  @JsonProperty('uniqueId', String)
  uniqueId: string

  @JsonProperty('name', String)
  name: string

  @JsonProperty('instepCode', String, true)
  instepCode: string

  @JsonProperty('uiVisible', Boolean, true)
  uiVisible: boolean

  @JsonProperty('tooltipText', String, true)
  tooltipText: string

  @JsonProperty('relatedUniqueId', String, true)
  relatedUniqueId: string

  @JsonProperty('optionAsBtn', Boolean)
  optionAsBtn: boolean

  constructor(
    id = 0,
    uniqueId = '',
    name = '',
    instepCode = '',
    uiVisible = false,
    tooltipText = '',
    relatedUniqueId = '',
    optionAsBtn = true,
  ) {
    super(id)
    this.uniqueId = uniqueId
    this.name = name
    this.instepCode = instepCode
    this.uiVisible = uiVisible
    this.tooltipText = tooltipText
    this.relatedUniqueId = relatedUniqueId
    this.optionAsBtn = optionAsBtn
  }

  static createMock(uniqueId: string): DefinedListDetail {
    return new DefinedListDetail(0, uniqueId, uniqueId, '', true, '')
  }
}

@JsonObject('DefinedList')
export default class DefinedList extends Model {
  @JsonProperty('definedListDetails', [DefinedListDetail])
  definedListDetails: DefinedListDetail[]

  constructor(
    id = 0,
    definedListDetails: DefinedListDetail[] = [],
  ) {
    super(id)
    this.definedListDetails = definedListDetails
  }
}
