import moment from 'moment'
import Utils from './Utils'
import type QuoteDetails from '@/services/models/QuoteDetails'
import type SchemeQuoteResult from '@/services/models/SchemeQuoteResult'

export default class YogaUtils extends Utils {
  getRenewalDate<T extends SchemeQuoteResult>(quote: QuoteDetails<T>, selectedSchemeQuoteResult: SchemeQuoteResult | null): Date {
    if (selectedSchemeQuoteResult!.scheme.isPayMonthly) {
      return moment(quote.effectiveDate)
        .add(1, 'month')
        .add(-1, 'day')
        .hours(23)
        .minutes(59)
        .seconds(59)
        .toDate()
    }
    else {
      return moment(quote.effectiveDate)
        .add(1, 'year')
        .add(-1, 'day')
        .hours(23)
        .minutes(59)
        .seconds(59)
        .toDate()
    }
  }
}
