import type { JsonConvert } from 'json2typescript'
import type { AxiosInstance } from 'axios'
import type ISchemeService from './interfaces/ISchemeService'
import Scheme from './models/Scheme'
import type IRequestMapper from './interfaces/IRequestMapper'
import type { IQuestionAnswer } from './values/PageAnswers'

export default class SchemeService implements ISchemeService {
  axios: AxiosInstance

  apiUrl: URL

  jsonConvert: JsonConvert

  requestMapper: IRequestMapper

  constructor(axios: AxiosInstance, apiUrl: URL, jsonConvert: JsonConvert, requestMapper: IRequestMapper) {
    this.axios = axios
    this.apiUrl = apiUrl
    this.jsonConvert = jsonConvert
    this.requestMapper = requestMapper
  }

  async getScheme(answers: IQuestionAnswer[]): Promise<Scheme> {
    const request = this.requestMapper.getSchemeRequest(answers)
    const result = await this.axios.post(`${this.apiUrl}/scheme`, request)
    return this.jsonConvert.deserializeObject(result.data, Scheme)
  }
}
