import { JsonObject, JsonProperty } from 'json2typescript'
import Address from '../models/Address'
import DateConverter from '@/converters/DateConverter'
import Modification from '@/services/values/Modification'

@JsonObject('RegisteredOwnerKeeperDetails')
export class RegisteredOwnerKeeperDetails {
  @JsonProperty('firstName', String)
  firstName = ''

  @JsonProperty('lastName', String)
  lastName = ''

  @JsonProperty('contactTelephone', String)
  contactTelephone = ''

  @JsonProperty('address', Address)
  address: Address = new Address()
}

@JsonObject('CarValue')
export default class Car {
  @JsonProperty('abiCode', String)
  abiCode = ''

  @JsonProperty('registration', String)
  registration = ''

  @JsonProperty('value', Number)
  value = 0

  @JsonProperty('noOfSeats', Number)
  noOfSeats: number | null = 0

  @JsonProperty('registeredKeeper', String)
  registeredKeeper = ''

  @JsonProperty('registeredOwner', String)
  registeredOwner = ''

  @JsonProperty('overnightParkLocation', String, true)
  overnightParkLocation: string | null = null

  @JsonProperty('purchaseDate', DateConverter)
  purchaseDate: Date | null = new Date()

  @JsonProperty('yearOfManufacture', Number)
  yearOfManufacture: number | null = 0

  @JsonProperty('keptAtHomeAddress', Boolean)
  keptAtHomeAddress = false

  @JsonProperty('dateFirstRegistered', DateConverter)
  dateFirstRegistered: Date | null = new Date()

  @JsonProperty('registeredKeeperDetails', RegisteredOwnerKeeperDetails, true)
  registeredKeeperDetails: RegisteredOwnerKeeperDetails | null = null

  @JsonProperty('regsiteredOwnerDetails', RegisteredOwnerKeeperDetails, true)
  regsiteredOwnerDetails: RegisteredOwnerKeeperDetails | null = null

  @JsonProperty('cubicCapacity', Number)
  cubicCapacity: number | null = null

  @JsonProperty('bodyType', String)
  bodyType: string | null = null

  @JsonProperty('imported', Boolean)
  imported: boolean | null = null

  @JsonProperty('leftHandDrive', Boolean)
  leftHandDrive: boolean | null = null

  @JsonProperty('make', String)
  make: string | null = null

  @JsonProperty('model', String)
  model: string | null = null

  @JsonProperty('transmission', String)
  transmission: string | null = null

  @JsonProperty('alarmType', String, true)
  alarmType: string | null = null

  @JsonProperty('alarmModel', String, true)
  alarmModel: string | null = null

  @JsonProperty('alarmFittedBy', String, true)
  alarmFittedBy: string | null = null

  @JsonProperty('trackerType', String, true)
  trackerType: string | null = null

  @JsonProperty('trackerModel', String, true)
  trackerModel: string | null = null

  @JsonProperty('motorhomeType', String)
  motorhomeType: string | null = null

  @JsonProperty('unknownMotorhomeType', String)
  unknownMotorhomeType: string | null = null

  @JsonProperty('vehicleWeight', Number)
  vehicleWeight: number | null = null

  @JsonProperty('workRadius', String)
  workRadius: string | null = null

  @JsonProperty('numberOfDrops', String)
  numberOfDrops: string | null = null

  @JsonProperty('isVehicleKeptOutsideUK', Boolean)
  isVehicleKeptOutsideUK: boolean | null = null

  @JsonProperty('vehicleType', String)
  vehicleType: string | null = null

  @JsonProperty('VehicleStorage', String)
  vehicleStorage: string | null = null

  @JsonProperty('modifications', [Modification])
  modifications: Modification[] = []

  @JsonProperty('vehicleUse', String)
  vehicleUse: string | null = null

  @JsonProperty('hasTrailer', Boolean)
  hasTrailer: boolean | null = null

  @JsonProperty('trailerDetails', String)
  trailerDetails: string | null = null

  @JsonProperty('trailerMaxCapacity', Number)
  trailerMaxCapacity: number | null = null

  @JsonProperty('trailerValue', Number)
  trailerValue: number | null = null

  @JsonProperty('trailerVIN', String)
  trailerVIN: string | null = null

  @JsonProperty('carriesDangerousGoods', Boolean)
  carriesDangerousGoods: boolean | null = null

  @JsonProperty('storedGoodsDetails', String)
  storedGoodsDetails: string | null = null

  @JsonProperty('typeOfGoods', String)
  typeOfGoods: string | null = null

  @JsonProperty('allSeatsForwardFacing', Boolean)
  allSeatsForwardFacing: boolean | null = null

  @JsonProperty('allSeatsHaveSeatbelts', Boolean)
  allSeatsHaveSeatbelts: boolean | null = null

  @JsonProperty('seatbeltDetails', String)
  seatbeltDetails: string | null = null

  @JsonProperty('leftHandDriveReason', String)
  leftHandDriveReason: string | null = null

  @JsonProperty('seatDetails', String)
  seatDetails: string | null = null

  @JsonProperty('wheelchairsAdapted', Boolean)
  wheelchairsAdapted: boolean | null = null

  @JsonProperty('wheelchairsAdaptedDescription', String)
  wheelchairsAdaptedDescription: string | null = null

  @JsonProperty('anchoringLocking', Boolean)
  anchoringLocking: boolean | null = null

  @JsonProperty('selfLoadDevice', Boolean)
  selfLoadDevice: boolean | null = null

  @JsonProperty('sectionPermit', Boolean)
  sectionPermit: boolean | null = null

  @JsonProperty('loanWithPermits', Boolean)
  loanWithPermits: boolean | null = null

  @JsonProperty('roadsideProhibition', Boolean)
  roadsideProhibition: boolean | null = null

  @JsonProperty('percentageContract', Number)
  percentageContract: number | null = null

  @JsonProperty('isModified', Boolean)
  isModified: boolean | null = null

  @JsonProperty('isUsedForPrivateHire', Boolean)
  isUsedForPrivateHire: boolean | null = null

  @JsonProperty('IsVehicleRegisteredInUK', Boolean)
  IsVehicleRegisteredInUK: boolean | null = null

  @JsonProperty('fuelType', String)
  fuelType: string | null = null

  @JsonProperty('secondaryStorageAddress', Address)
  secondaryStorageAddress: Address | null = null

  @JsonProperty('address', Address)
  address: Address | null = null
}
