import { JsonObject, JsonProperty } from 'json2typescript'
import Model from '../Model'
import { DefinedListDetail } from '../DefinedList'

@JsonObject('VehicleModificationModel')
export default class VehicleModification extends Model {
  @JsonProperty('modificationSubCategory', DefinedListDetail)
    modificationSubCategory: DefinedListDetail

  @JsonProperty('modificationDetail', DefinedListDetail)
    modificationDetail: DefinedListDetail

  constructor(
        id = 0,
        modificationSubCategory = new DefinedListDetail(),
        modificationDetail = new DefinedListDetail(),
  ) {
    super(id)
    this.modificationSubCategory = modificationSubCategory
    this.modificationDetail = modificationDetail
  }
}
