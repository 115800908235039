import Hotjar from '@hotjar/browser'
import SmartlookClient from 'smartlook-client'
import Analytics from './Analytics'

export default class YogaAnalytics extends Analytics {
  loadAnalytics(): void {
    // Google Tag Manager
    const gtmId = window.injectedEnv.FRONTEND_GTM
    if (gtmId) {
      (
        function loadGTM(w: any, d: any, s: any, l: any, i: any) {
          w[l] = w[l] || []
          w[l].push({ 'gtm.start': new Date().getTime(), 'event': 'gtm.js' })
          const f = d.getElementsByTagName(s)[0]
          const j = d.createElement(s)
          const dl = l !== 'dataLayer' ? `&l=${l}` : ''
          j.async = true
          j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`
          f.parentNode.insertBefore(j, f)
        }
      )(window, document, 'script', 'dataLayer', gtmId)
    }

    // SmartLook
    SmartlookClient.init(window.injectedEnv.FRONTEND_SMARTLOOK)

    // Hotjar Tracking
    Hotjar.init(Number(window.injectedEnv.FRONTEND_HOTJAR), 6)
  }
}
