import { JsonObject, JsonProperty } from 'json2typescript'
import Model from './Model'

@JsonObject('Fee')
export default class Fee extends Model {
  @JsonProperty('amount', Number)
  amount: number

  constructor(
    id = 0,
    amount = 0,
  ) {
    super(id)
    this.amount = amount
  }
}
