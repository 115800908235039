import { JsonObject, JsonProperty } from 'json2typescript'
import DateConverter from '../../converters/DateConverter'
import PersonalDetails from '../values/PersonalDetails'
import { DefinedListDetail } from './DefinedList'
import CaravanClaim from './CaravanClaim'

@JsonObject('CaravanProposer')
export default class CaravanProposer extends PersonalDetails {
  @JsonProperty('title', String)
  titleDLDID: string | null = null

  @JsonProperty('emailAddress', String, true)
  emailAddress: string | null = null

  @JsonProperty('telephoneNumber', String, true)
  telephoneNumber: string | null = null

  @JsonProperty('maritalStatus', DefinedListDetail, true)
  maritalStatus: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('ukResident', Boolean, true)
  uKResident: boolean | null = null

  @JsonProperty('ukResidentSince', DateConverter, true)
  uKResidentSince: Date | null = null

  @JsonProperty('isPartTime', Boolean, true)
  isPartTime: boolean | null = null

  @JsonProperty('isHomeOwner', Boolean, true)
  isHomeOwner: boolean | null = null

  @JsonProperty('hadInsuranceRefusedCancelledOrDeclined', Boolean, true)
  hadInsuranceRefusedCancelledOrDeclined: boolean | null = null

  @JsonProperty('hasUnspentNonMotoringConvictions', Boolean, true)
  hasUnspentNonMotoringConvictions: boolean | null = null

  @JsonProperty('hasBeenRequestedExtraPrecautions', Boolean, true)
  hasBeenRequestedExtraPrecautions: boolean | null = null

  @JsonProperty('hasDeclaredBankruptcy', Boolean, true)
  hasDeclaredBankruptcy: boolean | null = null

  @JsonProperty('hasClaims', Boolean, true)
  hasClaims: boolean | null = null

  @JsonProperty('addressLine1', String, true)
  addressLine1: string | null = null

  @JsonProperty('addressLine2', String, true)
  addressLine2: string | null = null

  @JsonProperty('addressLine3', String, true)
  addressLine3: string | null = null

  @JsonProperty('addressLine4', String, true)
  addressLine4: string | null = null

  @JsonProperty('postCode', String, true)
  postCode: string | null = null

  @JsonProperty('region', String, true)
  region: string | null = null

  @JsonProperty('city', String, true)
  city: string | null = null

  @JsonProperty('country', String, true)
  country: string | null = null

  @JsonProperty('employmentStatus', DefinedListDetail, true)
  employmentStatus: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('occupation', DefinedListDetail, true)
  occupation: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('businessType', DefinedListDetail, true)
  businessType: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('hasSecondaryEmployment', Boolean, true)
  hasSecondaryEmployment: boolean | null = null

  @JsonProperty('secondaryOccupation', DefinedListDetail, true)
  secondaryOccupation: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('secondaryBusinessType', DefinedListDetail, true)
  secondaryBusinessType: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('isEmailOptIn', Boolean, true)
  isEmailOptIn: boolean | null = null

  @JsonProperty('isPostOptIn', Boolean, true)
  isPostOptIn: boolean | null = null

  @JsonProperty('isSmsOptIn', Boolean, true)
  isSmsOptIn: boolean | null = null

  @JsonProperty('isTelephoneOptIn', Boolean, true)
  isTelephoneOptIn: boolean | null = null

  @JsonProperty('isClubMember', Boolean, true)
  isClubMember: boolean | null = null

  @JsonProperty('club', DefinedListDetail, true)
  club: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('hadPreviousInsurance', Boolean, true)
  hadPreviousInsurance: boolean | null = null

  @JsonProperty('referralSource', DefinedListDetail, true)
  referralSource: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('authorisedPersonsName', String, true)
  authorisedPersonsName: string | null = null

  @JsonProperty('voluntaryXS', DefinedListDetail, true)
  voluntaryXS: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('caravanClaims', [CaravanClaim])
  caravanClaims: CaravanClaim[] = []
}
