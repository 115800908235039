import { JsonObject, JsonProperty } from 'json2typescript'
import DateConverter from '../../converters/DateConverter'

@JsonObject('DVLALookup')
export default class DVLALookup {
  @JsonProperty('carRegistration', String)
  carRegistration: string

  @JsonProperty('abiCode', String)
  abiCode: string

  @JsonProperty('vin', String)
  vin: string

  @JsonProperty('make', String)
  make: string

  @JsonProperty('model', String)
  model: string

  @JsonProperty('bodyDesc', String)
  bodyDesc: string

  @JsonProperty('colour', String)
  colour: string

  @JsonProperty('fuelType', String)
  fuelType: string

  @JsonProperty('transmission', String)
  transmission: string

  @JsonProperty('engineSize', String)
  engineSize: string

  @JsonProperty('dateOfManufacture', DateConverter)
  dateOfManufacture: Date

  @JsonProperty('dateFirstRegistered', DateConverter)
  dateFirstRegistered: Date

  @JsonProperty('keeperStartDate', DateConverter)
  keeperStartDate: Date

  @JsonProperty('forecourtSale', Number)
  forecourtSale: number

  @JsonProperty('avgMileage', Number)
  avgMileage: number

  @JsonProperty('valuationMileage', String)
  valuationMileage: string

  constructor(
    carRegistration = '',
    abiCode = '',
    vin = '',
    make = '',
    model = '',
    bodyDesc = '',
    colour = '',
    fuelType = '',
    transmission = '',
    engineSize = '',
    dateOfManufacture: Date = new Date(),
    dateFirstRegistered: Date = new Date(),
    keeperStartDate: Date = new Date(),
    forecourtSale = 0,
    avgMileage = 0,
    valuationMileage = '',
  ) {
    this.carRegistration = carRegistration
    this.abiCode = abiCode
    this.vin = vin
    this.make = make
    this.model = model
    this.bodyDesc = bodyDesc
    this.colour = colour
    this.fuelType = fuelType
    this.transmission = transmission
    this.engineSize = engineSize
    this.dateOfManufacture = dateOfManufacture
    this.dateFirstRegistered = dateFirstRegistered
    this.keeperStartDate = keeperStartDate
    this.forecourtSale = forecourtSale
    this.avgMileage = avgMileage
    this.valuationMileage = valuationMileage
  }
}
