import { JsonObject, JsonProperty } from 'json2typescript'
import Model from './Model'
import { DefinedListDetail } from './DefinedList'
import DateConverter from '@/converters/DateConverter'

@JsonObject('PersonalDetailsModel')
export default class PersonalDetails extends Model {
  @JsonProperty('title', DefinedListDetail)
  title: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('firstName', String)
  firstName = ''

  @JsonProperty('lastName', String)
  lastName = ''

  @JsonProperty('dateOfBirth', DateConverter)
  dateOfBirth: Date = new Date()
}
