<template>
    <b-button v-if="config && !isAnchor" :disabled="config.isDisabled" @click="action" >{{ config.name }}</b-button>
    <a v-else-if="config && isAnchor" :disabled="config.isDisabled" @click="action" >{{ config.name }}</a>
</template>

<script lang="ts">
import { LinkConfig } from '@/configurations/IConfiguration';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class LinkConfigButton extends Vue {
  @Prop() private config!: LinkConfig;
  @Prop({ default: false }) private isAnchor!: boolean;

  action(): void {
    this.config.click(this);
    this.$emit('click');
  }

}
</script>
