<template>
  <main class="dashboard navigable">
    <article v-if="account && (canInstallPWA || canInstallIOS)" class="dash-item app-banner">
      <div class="title level">
        <div class="level-left">{{$configurations.installAppText}}</div>
        <div class="level-right"><b-button class="level-item" type="is-primary" @click="installApp">Install</b-button></div>
      </div>
    </article>
    <article v-else-if="account && (!isSubscribed && (browserNotification || canMacOSNotification))" class="dash-item app-banner">
      <div class="title level">
        <div class="level-left">{{ notificationBannerText }}</div>
        <div class="level-right"><b-button class="level-item" type="is-primary" @click="requestNotification">Yes</b-button></div>
      </div>
    </article>
    <article class="dash-item" id="account">
      <h4 class="title">Welcome back, {{ account ? account.firstName : '' }}</h4>
      <div class="content">
        <p>Need to insure something else?</p>
          <template v-if="alternateAddNewProductMarkup">
            <IgniteMarkdown>{{ alternateAddNewProductMarkup }}</IgniteMarkdown>
          </template>
          <template v-else>
            <b-button 
              v-for="(product) in products" :key="product.uniqueId"
              :icon-left="getButtonIcon(product).icon"
              :icon-pack="getButtonIcon(product).pack"
              tag="router-link"
              :to="getProductRoute(product)"
              type="is-link"
              :label="getNavLink(product)">
            </b-button>
          </template>
        </div>
      </div>
      <b-loading :is-full-page="false" :active="products.length < 1" />
    </article>
    <Renewals id="renewals" />
    <Policies id="policies" />
    <Quotes id="quotes" />
    <Benefits v-if="hasBenefits" id="benefits"/>
    <Claims id="claims" />
    <Documents id="documents" />
    <IOSPWAPrompt :active.sync="iosPromptActive" />
    <PasswordChange :active.sync="passwordChangeShown" @complete="passwordChangeShown = false" :can-cancel="false" />
  </main>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import Policies from '@/components/dashboard/Policies.vue';
import Renewals from '@/components/dashboard/Renewals.vue';
import Quotes from '@/components/dashboard/Quotes.vue';
import Claims from '@/components/dashboard/Claims.vue';
import Documents from '@/components/dashboard/Documents.vue';
import Benefits from '@/components/dashboard/Benefits.vue';
import IOSPWAPrompt from '@/components/dashboard/IOSPWAPrompt.vue';
import PasswordChange from '@/components/login/PasswordChange.vue';
import Account from '@/services/models/Account';
import useAccountStore from '@/stores/account-store';
import Product from '../services/models/Product';
import NotAuthorisedError from '../services/error/NotAuthorisedError';
import WebPlatformEnum from '../services/enums/WebPlatformEnum';
import ProductEnum from '../services/enums/ProductEnum';
import usePoliciesStore from '@/stores/policies-store';
import { getProductRoute } from '@/composables/product-utils';
import { getProductIcon } from '@/composables/product-utils'
import Icon from "@/view-models/Icon";
import { getAuthServiceConfig } from '@/auth-service';
import IgniteMarkdown from '@/components/controls/IgniteMarkdown.vue';

@Component({
  components: {
    Policies,
    Renewals,
    Quotes,
    Claims,
    Documents,
    IOSPWAPrompt,
    PasswordChange,
    Benefits
  },
})
export default class Home extends Vue {

  getProductRoute = getProductRoute;

  getProductIcon = getProductIcon;

  products: Product[] = [];

  iosPromptActive = false;

  subscribeClicked = false;

  passwordChangeShown = false;

  async mounted(): Promise<void> {
    const accountStore = useAccountStore();
    const response = await this.$services.productService.getAll();
    this.products = response.results;

    try {
      const account = await this.$services.accountService.getCurrent();
      accountStore.setAccount(account);

      const authServiceConfig = getAuthServiceConfig();

      if (authServiceConfig.enabled) {
        if (authServiceConfig.type === 'auth-prospect')
          this.passwordChangeShown = true;
      }
      else if (!account.hasCustomerCredentials) {
        this.passwordChangeShown = true;
      }
    } catch (error) {
      if (!(error instanceof NotAuthorisedError)) {
        throw error;
      }
    }
    await usePoliciesStore().retrievePolicies(this.$services, true);
  }

  get tenant(): string {
    return this.$configurations.tenantFriendlyName;
  }

  get isSubscribed(): boolean {
    return this.$pwa.isSubscribed || this.subscribeClicked;
  }

  get hasBenefits(): boolean {
    return this.$configurations.benefitsDashboardItem.visible ?? false;
  }

  get alternateAddNewProductMarkup(): string {
    return this.$configurations.alternateAddNewProductMarkup
  }

  // eslint-disable-next-line class-methods-use-this
  get account(): Account | null {
    const accountStore = useAccountStore();
    return accountStore.account;
  }

  installApp(): void {
    if (this.canInstallIOS || this.iosPromptActive) {
      this.iosPromptActive = true;
    } else if (this.canInstallPWA) {
      this.$pwa.deferredPrompt.prompt();
    }
  }

  async requestNotification(): Promise<void> {
    this.subscribeClicked = true;
    // eslint-disable-next-line no-nested-ternary
    await this.$services.accountService.requestPermission(this.$configurations.notification.publicKey, this.isiOS ? WebPlatformEnum.ios : this.isiPadOS ? WebPlatformEnum.iPadOS : WebPlatformEnum.GenericBrowser);
  }

  get notificationBannerText(): string {
    return this.$configurations.notificationBannerText;
  }

  get canInstallPWA(): boolean {
    return this.$pwa.canInstall && this.$configurations.features.app;
  }

  get browserNotification(): boolean {
    return this.$pwa.canBrowserSupport && this.$configurations.features.pushNotification;
  }

  get canMacOSNotification(): boolean {
    return false && this.$configurations.features.pushNotification;
  }

  getNavLink(product: Product): string {
    if (usePoliciesStore().policies?.find(r => r.product == product.uniqueId)) {
      return this.$configurations.navLinkForAdditionalProduct(product.uniqueId as ProductEnum) ?? `Insure additional ${product.displayName}`;
    }
    return this.$configurations.navLinkForProduct(product.uniqueId as ProductEnum) ?? `Insure a ${product.displayName}`;
  }

  getButtonIcon(product: Product): Icon {
    return this.getProductIcon(product.uniqueId as ProductEnum)
  }

  // eslint-disable-next-line class-methods-use-this
  get isiOS(): boolean {
    return /iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase());
  }

  // eslint-disable-next-line class-methods-use-this
  get isiPadOS(): boolean {
    return navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1;
  }

  get canInstallIOS(): boolean {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const isStandalone = 'standalone' in window.navigator && (window.navigator as any).standalone;

    return (this.isiOS || this.isiPadOS) && !isStandalone && this.$configurations.features.app;
  }
}
</script>
