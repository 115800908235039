enum ContextLevel {
  preQuote,
  quote,
  policy,
  mtaPreQuote,
  mtaQuote,
  mtaTakenUp,
  renewalPreQuote,
  renewalQuote,
  renewalTakenUp,
}

export default ContextLevel
