import { JsonObject, JsonProperty } from 'json2typescript'
import { DefinedListDetail } from './DefinedList'

@JsonObject('Modification')
export default class Modification {
  @JsonProperty('carRiskVehicleModification', DefinedListDetail)
  carRiskVehicleModification: DefinedListDetail

  constructor(
    carRiskVehicleModification = new DefinedListDetail(),
  ) {
    this.carRiskVehicleModification = carRiskVehicleModification
  }
}
