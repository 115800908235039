import { JsonObject, JsonProperty } from 'json2typescript'
import Address from '../models/Address'
import VAPVehicle from '../models/VAPVehicle'
import QuoteRequest from './QuoteRequest'
import CaravanRequest from './CaravanRequest'
import CaravanClaimRequest from './CaravanClaimRequest'
import CaravanProposerRequest from './CaravanProposerRequest'
import CaravanCoverRequest from './CaravanCoverRequest'
import CaravanAdditionalPolicyHolderRequest from './CaravanAdditionalPolicyHolderRequest'

@JsonObject('CaravanQuoteRequest')
export default class CaravanQuoteRequest extends QuoteRequest {
  @JsonProperty('address', Address)
  address: Address = new Address()

  @JsonProperty('caravan', CaravanRequest)
  caravan: CaravanRequest = new CaravanRequest()

  @JsonProperty('caravanClaims', [CaravanClaimRequest])
  caravanClaims: CaravanClaimRequest[] = []

  @JsonProperty('caravanProposer', CaravanProposerRequest)
  caravanProposer = new CaravanProposerRequest()

  @JsonProperty('caravanAdditionalPolicyHolders', [CaravanAdditionalPolicyHolderRequest])
  caravanAdditionalPolicyHolderRequest: CaravanAdditionalPolicyHolderRequest[] = []

  @JsonProperty('caravanCover', CaravanCoverRequest)
  caravanCoverRequest: CaravanCoverRequest = new CaravanCoverRequest()

  @JsonProperty('breakDownCoverRequired', Boolean, true)
  breakDownCoverRequired: boolean | null = null

  @JsonProperty('vapVehicle', VAPVehicle)
  vapVehicle = new VAPVehicle()
}

@JsonObject('CaravanMTAQuoteRequest')
export class CaravanMTAQuoteRequest extends CaravanQuoteRequest {
  @JsonProperty('reasonForChange', String)
  reasonForChange = ''
}
