import { JsonObject, JsonProperty } from 'json2typescript'
import Model from './Model'

@JsonObject('CancellationReason')
export default class CancellationReason extends Model {
  @JsonProperty('name', String)
  name: string

  @JsonProperty('uniqueId', String)
  uniqueId: string

  constructor(
    id = 0,
    name = '',
    uniqueId = '',
  ) {
    super(id)
    this.name = name
    this.uniqueId = uniqueId
  }
}
