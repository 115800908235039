import { JsonObject, JsonProperty } from 'json2typescript'

@JsonObject('GoCardlessRedirectCompleteResponse')
export default class GoCardlessRedirectCompleteResponse {
  @JsonProperty('policyNumber', String)
  policyNumber = ''

  @JsonProperty('quoteReference', String)
  quoteReference = ''

  @JsonProperty('schemeQuoteResultID', Number, true)
  schemeQuoteResultId: number | null = null
}
