<template>
  <b-loading is-full-page :active="isActive">
    <div v-if="isDefault">
      <div class="loading-icon"></div>
      <p v-if="loadingText" class="loading-text">{{ loadingText }}</p>
    </div>
    <div v-else-if="isImg">
      <img class="loading-icon" :src="loadingSpinner.asset" />
      <p v-if="loadingText" class="loading-text">{{ loadingText }}</p>
    </div>
    <div class="loading-lottie" v-else>
      <LottieAnimation class="lottie" :path="loadingSpinner.asset" :height="height" :width="width" />
      <p v-if="loadingText" class="loading-text">{{ loadingText }}</p>
    </div>
  </b-loading>
</template>

<script lang="ts">
import ProductEnum from '@/services/enums/ProductEnum';
import TenantEnum from '@/services/enums/TenantEnum';
import WrapperUtils from '@/utils/WrapperUtils';
import {
  Component, Prop, PropSync, Vue,
} from 'vue-property-decorator';

enum LoadingAssetType {
  default,
  lottie,
  img
}

@Component({
  components: {
    LottieAnimation: () => import('./LottieAnimation.vue'),
  },
})
export default class Loading extends Vue {
  @PropSync('active', { type: Boolean }) private isActive!: boolean;

  @Prop({ default: '' }) private loadingText!: string;

  height = 300;

  width = 300;

  get isDefault() {
    return this.loadingSpinner.type === LoadingAssetType.default
  }

  get isImg() {
    return this.loadingSpinner.type === LoadingAssetType.img
  }

  get loadingSpinner() {
    switch (this.$services.tenant) {
      case TenantEnum.KeeyPartners:
        this.height = 500;
        this.width = 500;
        const asset = Math.random() >= 0.5 ? '/assets/img/keeypartners/lottie/scratch-loading.json' : '/assets/img/keeypartners/lottie/patch-loading.json';
        return {
          type: LoadingAssetType.lottie,
          asset
        }
      case TenantEnum.Wrapper:
        return {
          type: LoadingAssetType.lottie,
          asset: this.$utils.useAlternateBranding(this.$route.params.product as ProductEnum | null)
            ? '/assets/img/wrapper-oneday/lottie/wrapper-oneday-loading.json'
            : '/assets/img/wrapper/lottie/wrapper-loading.json'
        }
      case TenantEnum.Hagerty:
        return {
          type: LoadingAssetType.img,
          asset: '/assets/img/hagerty/animated-svg/hagerty-loading.svg'
        }
      case TenantEnum.Tractive:
        return {
          type: LoadingAssetType.lottie,
          asset: '/assets/img/tractive/lottie/tractive-loading.json'
        }
      default:
        return {
          type: LoadingAssetType.default
        }
    }
  }
}
</script>
