export default class Icon {
  icon: string

  pack: string | undefined

  constructor(icon: string, pack?: string) {
    this.icon = icon
    this.pack = pack
  }
}
