<template>
  <DashboardItem title="Quotes" :loaded="loaded">
    <template #content>
      <div v-if="total > 0">
        <p>You have {{ total }} active quotes:</p>
        <table>
          <tr v-for="(quote) in results" :key="quote.quoteReference">
            <td>
              <b-icon :pack="getProductIcon(quote.product).pack" :icon="getProductIcon(quote.product).icon"
                size="is-large" />
            </td>
            <td>
              <span class="summary-title">{{ quote.summaryItems[0].name }}</span>
              {{ quote.summaryItems[0].value }}
            </td>
            <td>
              <span class="summary-title">Price</span>
              &pound;{{ quote.calculatedTotalPayable.toFixed(2) }}
            </td>
            <td>
              <b-button type="is-primary" @click="goToQuote(quote)">Buy</b-button>
            </td>
          </tr>
        </table>
        <router-link :to="quotesRoute">
          All quotes
        </router-link>
      </div>
      <p v-else>You have no active quotes.</p>
    </template>
  </DashboardItem>
</template>

<script lang="ts">
import {
  Component,
  Vue,
} from 'vue-property-decorator';
import QuoteSummary from '@/services/values/QuoteSummary';

import Routes from '@/constants/Routes';
import NotAuthorisedError from '@/services/error/NotAuthorisedError';
import DashboardItem from './DashboardItem.vue';
import { getProductIcon } from '@/composables/product-utils';

@Component({
  components: {
    DashboardItem,
  },
})
export default class Quotes extends Vue {
  getProductIcon = getProductIcon;

  results: QuoteSummary[] = [];

  total = 0;

  loaded = false;

  get quotesRoute() {
    return {
      name: Routes.quotes,
    };
  }

  async created() {
    try {
      const response = await this.$services.quoteService.getQuotes(false);
      this.results = response.results;
      this.total = response.total;
      this.loaded = true;
    } catch (error) {
      if (!(error instanceof NotAuthorisedError)) {
        throw error;
      }
    }
  }

  goToQuote(quote: QuoteSummary) {
    this.$router.push({
      name: Routes.premium,
      params: {
        product: quote.product,
        quoteReference: quote.quoteReference,
      },
    });
  }
}
</script>
