import { JsonObject, JsonProperty } from 'json2typescript'

@JsonObject('Model')
export default abstract class Model {
  @JsonProperty('id', Number, true)
  id: number

  constructor(id = 0) {
    this.id = id
  }
}
