<template>
  <DashboardItem title="Claims" :loaded="loaded">
    <template #content>
      <table v-if="total > 0">
        <tr>
          <td>
            You have made {{ total }} {{ total === 1 ? 'claim' : 'claims' }}
          </td>
          <td>
            <b-button @click="goToClaims()">Details</b-button>
          </td>
        </tr>
      </table>
      <p v-else>You have no current claims</p>
      <div class="claim-buttons">
      <b-button v-for="(claimsLink, c) in dashboardClaimsLinks" :key="c" @click="onClaimsLinkClick(claimsLink)">
        {{ claimsLink.name }}
      </b-button>
      </div>
    </template>
  </DashboardItem>
</template>

<script lang="ts">
import {
  Component,
  Vue,
} from 'vue-property-decorator';
import Routes from '@/constants/Routes';
import ClaimSummary from '@/services/values/ClaimSummary';
import NotAuthorisedError from '@/services/error/NotAuthorisedError';
import { LinkConfig } from '@/configurations/IConfiguration';
import DashboardItem from './DashboardItem.vue';

@Component({
  components: {
    DashboardItem,
  },
})
export default class Claims extends Vue {
  results: ClaimSummary[] = [];

  total = 0;

  loaded = false;

  brokenDown = false;

  get claimsRoute() {
    return {
      name: Routes.claims,
    };
  }

  async created() {
    try {
      const response = await this.$services.claimService.getClaims();
      this.results = response.results;
      this.total = response.total;
      this.loaded = true;
    } catch (error) {
      if (!(error instanceof NotAuthorisedError)) {
        throw error;
      }
    }
  }

  goToClaims() {
    this.$router.push(this.claimsRoute);
  }

  onClaimsLinkClick(claimsLink: LinkConfig) {
    claimsLink.click(this);
  }

  get dashboardClaimsLinks() {
    return this.$configurations.dashboardClaimsLinks;
  }
}
</script>
