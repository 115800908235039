import _Vue from 'vue'
import Hotjar from '@hotjar/browser'
import VueGtag from 'vue-gtag'
import type IAnalyticsConfig from '../IAnalyticsConfig'
import Analytics from './Analytics'

export default class DashAnalytics extends Analytics {
  loadAnalytics(config: IAnalyticsConfig): void {
    // Google Tag Manager
    const gtmId = window.injectedEnv.FRONTEND_GTM
    if (gtmId) {
      (
        function loadGTM(w: any, d: any, s: any, l: any, i: any) {
          w[l] = w[l] || []
          w[l].push({ 'gtm.start': new Date().getTime(), 'event': 'gtm.js' })
          const f = d.getElementsByTagName(s)[0]
          const j = d.createElement(s)
          const dl = l !== 'dataLayer' ? `&l=${l}` : ''
          j.async = true
          j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`
          f.parentNode.insertBefore(j, f)
        }
      )(window, document, 'script', 'dataLayer', gtmId)
    }

    // Google Analytics
    _Vue.use(VueGtag, {
      config: {
        id: config.analytics,
        params: {

          send_page_view: false,
        },
      },
    }, config.router)

    // Hotjar Tracking
    Hotjar.init(Number(window.injectedEnv.FRONTEND_HOTJAR), 6)

    // Hotjar Tracking 2
    Hotjar.init(Number(window.injectedEnv.FRONTEND_HOTJAR_2), 6)
  }
}
