import TenantEnum from '../enums/TenantEnum'
import BarryGraingerMapper from './BarryGraingerMapper'
import DashMapper from './DashMapper'
import HagertyMapper from './HagertyMapper'
import KeeyPartnersMapper from './KeeyPartnersMapper'
import QuotaxMapper from './QuotaxMapper'
import TractiveMapper from './TractiveMapper'
import WrapperMapper from './WrapperMapper'
import YogaMapper from './YogaMapper'
import ZoomcoverMapper from './ZoomcoverMapper'

export default function MapperFactory(tenant: TenantEnum) {
  switch (tenant) {
    case TenantEnum.BarryGrainger:
      return new BarryGraingerMapper()
    case TenantEnum.Dash:
      return new DashMapper()
    case TenantEnum.Hagerty:
      return new HagertyMapper()
    case TenantEnum.Ignite:
      return new YogaMapper() // currently configured like yoga
    case TenantEnum.KeeyPartners:
      return new KeeyPartnersMapper()
    case TenantEnum.Quotax:
      return new QuotaxMapper()
    case TenantEnum.Tractive:
      return new TractiveMapper()
    case TenantEnum.Yoga:
      return new YogaMapper()
    case TenantEnum.Zoomcover:
      return new ZoomcoverMapper()
    case TenantEnum.Wrapper:
      return new WrapperMapper()
    default:
      throw new Error(`MAPPER: ${tenant} is not a valid tenant.`)
  }
}
