export default class UnkownQuestionSetError implements Error {
  name: string

  message: string

  stack?: string

  constructor(questionSet: string) {
    this.name = 'UnknownQuestionSetError'
    this.message = `The question set (${questionSet}) is not recognised by the current tenant mapper`
  }
}
