import { JsonObject, JsonProperty } from 'json2typescript'
import DateConverter from '../../converters/DateConverter'
import Address from '../models/Address'
import { DefinedListDetail } from './DefinedList'

@JsonObject('Caravan')
export default class Caravan {
  @JsonProperty('caravanType', DefinedListDetail, true)
  caravanType: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('caravanMake', DefinedListDetail, true)
  caravanMake: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('caravanModel', DefinedListDetail, true)
  caravanModel: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('measuredInFeet', Boolean, true)
  measuredInFeet: boolean | null = null

  @JsonProperty('caravanLength', DefinedListDetail, true)
  caravanLength: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('caravanWidth', DefinedListDetail, true)
  caravanWidth: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('noOfBedRooms', DefinedListDetail, true)
  noOfBedRooms: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('yearOfMake', Number, true)
  yearOfMake: number | null = null

  @JsonProperty('purchaseDate', DateConverter, true)
  purchaseDate: Date | null = null

  @JsonProperty('notBoughtYet', Boolean, true)
  notBoughtYet: boolean | null = null

  @JsonProperty('isPermanentResidence', Boolean, true)
  isPermanentResidence: boolean | null = null

  @JsonProperty('classOfUse', DefinedListDetail, true)
  classOfUse: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('goodCondition', Boolean, true)
  goodCondition: boolean | null = null

  @JsonProperty('isAnchored', Boolean, true)
  isAnchored: boolean | null = null

  @JsonProperty('isFittedWithFloatationDevice', Boolean, true)
  isFittedWithFloatationDevice: boolean | null = null

  @JsonProperty('heatingSystem', DefinedListDetail, true)
  heatingSystem: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('alarmFitted', Boolean, true)
  alarmFitted: boolean | null = null

  @JsonProperty('alarmFittedBy', DefinedListDetail, true)
  alarmFittedBy: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('isHavingAdditionalFeatures', Boolean, true)
  isHavingAdditionalFeatures: boolean | null = null

  @JsonProperty('isSiteRegistered', Boolean, true)
  isSiteRegistered: boolean | null = null

  @JsonProperty('sited', DefinedListDetail, true)
  sited: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('plotNumber', String, true)
  plotNumber: string | null = null

  @JsonProperty('numberOfCaravanPitches', DefinedListDetail, true)
  numberOfCaravanPitches: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('propertyHasBeenFlooded', Boolean, true)
  propertyHasBeenFlooded: boolean | null = null

  @JsonProperty('hasPreviousStormDamage', Boolean, true)
  hasPreviousStormDamage: boolean | null = null

  @JsonProperty('hasPerimiterFencing', Boolean, true)
  hasPerimiterFencing: boolean | null = null

  @JsonProperty('hasWarden', Boolean, true)
  hasWarden: boolean | null = null

  @JsonProperty('hasSecurityGate', Boolean, true)
  hasSecurityGate: boolean | null = null

  @JsonProperty('hasCCTV', Boolean, true)
  hasCCTV: boolean | null = null

  @JsonProperty('selectedCaravanSecurity', [String])
  selectedCaravanSecurity: string[] | null = []

  @JsonProperty('marketPrice', Number, true)
  marketPrice: number | null = null

  @JsonProperty('replacementValue', Number, true)
  replacementValue: number | null = null

  @JsonProperty('address', Address)
  address: Address | null = null
}
