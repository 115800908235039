import { JsonObject, JsonProperty } from 'json2typescript'
import Risk from '../Risk'
import Address from '../Address'
import CustomList from '../CustomList'
import CoInsuranceRule from '../CoInsuranceRule'
import type { Dictionary } from '@/types'

@JsonObject('DynamicProposer')
export class DynamicProposer {
  @JsonProperty('firstName', String)
  firstName: string

  @JsonProperty('lastName', String)
  lastName: string

  constructor(firstName = '', lastName = '') {
    this.firstName = firstName
    this.lastName = lastName
  }
}

@JsonObject('DynamicRisk')
export default class DynamicRisk extends Risk {
  @JsonProperty('proposer', DynamicProposer)
  proposer: DynamicProposer

  @JsonProperty()
  questionAnswers: Dictionary<string>

  constructor(
      id = 0,
      effectiveDate = new Date(),
      address = new Address(),
      businessSource = new CustomList(),
      coInsuranceRule = new CoInsuranceRule(),
      proposer = new DynamicProposer(),
      questionAnswers = {},
  ) {
    super(id, effectiveDate, address, businessSource, coInsuranceRule)
    this.proposer = proposer
    this.questionAnswers = questionAnswers
  }
}
