import { JsonObject, JsonProperty } from 'json2typescript'
import DateConverter from '../../converters/DateConverter'

@JsonObject('TouringCaravanConvictionValue')
export default class TouringCaravanConvictionRequest {
  @JsonProperty('convictionCode', String, true)
  convictionCode: string | null = null

  @JsonProperty('convictionDate', DateConverter, true)
  convictionDate: Date | null = null

  @JsonProperty('pointsReceived', Number, true)
  pointsReceived: number | null = null

  @JsonProperty('fine', Number, true)
  fine: number | null = null

  @JsonProperty('disqualificationMonths', Number, true)
  disqualificationMonths: number | null = null
}
