import type ITransitionAction from '@/page-transitions/transition-actions/ITransitionAction'
import ContextLevel from '@/services/enums/ContextLevel'
import type JourneyPage from '@/mixins/JourneyPage.vue'
import type { PageNavigationConfig } from '@/view-models/PageNavigationConfig'

export default class NoActionAction implements ITransitionAction {
  canExecute(currentContext: ContextLevel, nextContext: ContextLevel): boolean {
    return (currentContext === ContextLevel.preQuote && nextContext === ContextLevel.preQuote)
      || (currentContext === ContextLevel.mtaPreQuote && nextContext === ContextLevel.mtaPreQuote)
      || (currentContext === ContextLevel.mtaQuote && nextContext === ContextLevel.mtaQuote)
      || (currentContext === ContextLevel.renewalPreQuote && nextContext === ContextLevel.renewalPreQuote)
  }

  async execute(this: JourneyPage): Promise<PageNavigationConfig | null> {
    return {}
  }
}
