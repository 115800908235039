import { JsonObject, JsonProperty } from 'json2typescript'
import DateConverter from '../../converters/DateConverter'
import PersonalDetails from './PersonalDetails'

@JsonObject('TouringCaravanProposerRequest')
export default class TouringCaravanProposerRequest extends PersonalDetails {
  @JsonProperty('maritalStatus', String, true)
  maritalStatus: string | null = null

  @JsonProperty('livedInUKSince', DateConverter, true)
  livedInUKSince: Date | null = null

  @JsonProperty('licenceHeldYears', String, true)
  licenceHeldYears: string | null = null

  @JsonProperty('manouveringCourse', String, true)
  manouveringCourse: string | null = null

  @JsonProperty('clubMembership', String, true)
  clubMembership: string | null = null

  @JsonProperty('employmentStatus', String, true)
  employmentStatus: string | null = null

  @JsonProperty('occupation', String, true)
  occupation: string | null = null

  @JsonProperty('businessType', String, true)
  businessType: string | null = null

  @JsonProperty('secondaryEmploymentStatus', String, true)
  secondaryEmploymentStatus: string | null = null

  @JsonProperty('secondaryOccupation', String, true)
  secondaryOccupation: string | null = null

  @JsonProperty('secondaryBusinessType', String, true)
  secondaryBusinessType: string | null = null

  @JsonProperty('noClaimsBonus', Number, true)
  noClaimsBonus: number | null = null

  @JsonProperty('caravanNoClaimsBonus', Number, true)
  caravanNoClaimsBonus: number | null = null

  @JsonProperty('hasClaims', Boolean, true)
  hasClaims: boolean | null = null

  @JsonProperty('hasConvictions', Boolean, true)
  hasConvictions: boolean | null = null

  @JsonProperty('hadInsuranceRefused', Boolean, true)
  hadInsuranceRefused: boolean | null = null

  @JsonProperty('hasHadPreviousPolicyCancelledTermsApplied', Boolean, true)
  hasHadPreviousPolicyCancelledTermsApplied: boolean | null = null

  @JsonProperty('hasCriminalConvictions', Boolean, true)
  hasCriminalConvictions: boolean | null = null

  @JsonProperty('noClaimsProtection', Boolean, true)
  noClaimsProtection: boolean | null = null

  @JsonProperty('hadPreviousInsurance', Boolean, true)
  hadPreviousInsurance: boolean | null = null

  @JsonProperty('previousInsurerName', Number, true)
  previousInsurerName: number | null = null

  @JsonProperty('previousPolicyNumber', String, true)
  previousPolicyNumber: string | null = null

  @JsonProperty('previousPolicyExpiration', DateConverter, true)
  previousPolicyExpiration: Date | null = null

  @JsonProperty('referralSource', String, true)
  referralSource: string | null = null

  @JsonProperty('europeanCoverRequired', Boolean, true)
  europeanCoverRequired: boolean | null = null

  @JsonProperty('publicLiabilityRequired', Boolean, true)
  publicLiabilityRequired: boolean | null = null

  @JsonProperty('publicLiabilityCover', String, true)
  publicLiabilityCover: string | null = null

  @JsonProperty('hasBeenBankrupt', Boolean, true)
  hasBeenBankrupt: boolean | null = null

  @JsonProperty('authorisedPersonsName', String, true)
  authorisedPersonsName: string | null = null
}
