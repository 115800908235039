import { defineStore } from 'pinia'
import { H } from 'highlight.run'
import type Account from '@/services/models/Account'

interface State {
  account: Account | null
}

const useAccountStore = defineStore('account', {
  state: (): State => ({
    account: null,
  }),
  actions: {
    setAccount(account: Account): void {
      H.identify(account.username)
      this.account = account
    },
    clearAccount(): void {
      this.account = null
    },
  },
})

export default useAccountStore
