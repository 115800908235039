import { defineStore } from 'pinia'
import type Affiliate from '@/services/models/Affiliate'
import type AggregatorClient from '@/services/models/AggregatorClient'
import type { Dictionary } from '@/types'

interface State {
  aggregatorQuoteReference: string | null

  client: AggregatorClient | null

  affiliate: Affiliate | null

  queryString: Dictionary<string | (string | null)[]>
}

const useAggregatorStore = defineStore('aggregator', {
  state: (): State => ({
    aggregatorQuoteReference: null,
    client: null,
    affiliate: null,
    queryString: {},
  }),
  actions: {
    setAggregatorQuoteReference(aggregatorQuoteReference: string): void {
      this.aggregatorQuoteReference = aggregatorQuoteReference
    },
    setAggregatorClient(client: AggregatorClient): void {
      this.client = client
    },
    setAffiliate(affiliate: Affiliate): void {
      this.affiliate = affiliate
    },
    clearAggregatorQuoteReference(): void {
      this.aggregatorQuoteReference = null
    },
    clearAggregatorClient(): void {
      this.client = null
      this.affiliate = null
      this.queryString = {}
    },
    setQueryString(queryString: Dictionary<string | (string | null)[]>): void {
      this.queryString = queryString
    },
  },
})

export default useAggregatorStore
