import { JsonObject, JsonProperty } from 'json2typescript'
import { DefinedListDetail } from '../DefinedList'
import Model from '../Model'
import Address from '../Address'
import VehicleModification from './VehicleModification'
import DateConverter from '@/converters/DateConverter'

@JsonObject('VehicleRiskVehicle')
export default class VehicleRiskVehicle extends Model {
  @JsonProperty('vehicleType', DefinedListDetail)
  vehicleType: DefinedListDetail

  @JsonProperty('abiCode', String)
  abiCode: string

  @JsonProperty('vin', String)
  vin: string

  @JsonProperty('registration', String)
  registration: string

  @JsonProperty('make', String)
  make: string

  @JsonProperty('model', String)
  model: string

  @JsonProperty('cubicCapacity', Number)
  cubicCapacity: number

  @JsonProperty('value', Number)
  value: number

  @JsonProperty('yearOfMake', Number)
  yearOfMake: number

  @JsonProperty('riderWillCarryPillion', Boolean)
  riderWillCarryPillion: boolean | null = null

  @JsonProperty('annualMileage', DefinedListDetail)
  annualMileage: DefinedListDetail

  @JsonProperty('isModified', Boolean)
  isModified: boolean

  @JsonProperty('modifications', [VehicleModification])
  modifications: VehicleModification[]

  @JsonProperty('isOwnedByProposer', DefinedListDetail)
  isOwnedByProposer: DefinedListDetail

  @JsonProperty('lengthOfOwnership', DefinedListDetail)
  lengthOfOwnership: DefinedListDetail

  @JsonProperty('classOfUse', DefinedListDetail)
  classOfUse: DefinedListDetail

  @JsonProperty('isPrivatelyStored', Boolean)
  isPrivatelyStored: boolean

  @JsonProperty('vehicleStorage', DefinedListDetail)
  vehicleStorage: DefinedListDetail

  @JsonProperty('purchaseDate', DateConverter)
  purchaseDate: Date | null = null

  @JsonProperty('voluntaryExcess', DefinedListDetail)
  voluntaryExcess: DefinedListDetail

  @JsonProperty('securityDevice', DefinedListDetail)
  securityDevice: DefinedListDetail

  @JsonProperty('europeanCover', DefinedListDetail)
  europeanCover: DefinedListDetail

  @JsonProperty('address', Address)
  address: Address

  @JsonProperty('vehicleCondition', DefinedListDetail)
  vehicleCondition: DefinedListDetail

  constructor(
    id = 0,
    vehicleType = new DefinedListDetail(),
    abiCode = '',
    vin = '',
    registration = '',
    make = '',
    model = '',
    cubicCapacity = 0,
    value = 0,
    yearOfMake = 0,
    riderWillCarryPillion = false,
    annualMileage = new DefinedListDetail(),
    isModified = false,
    modifications: VehicleModification[] = [],
    isOwnedByProposer = new DefinedListDetail(),
    lengthOfOwnership = new DefinedListDetail(),
    classOfUse = new DefinedListDetail(),
    isPrivatelyStored = false,
    vehicleStorage = new DefinedListDetail(),
    purchaseDate = new Date(),
    voluntaryExcess = new DefinedListDetail(),
    securityDevice = new DefinedListDetail(),
    europeanCover = new DefinedListDetail(),
    address = new Address(),
    vehicleCondition = new DefinedListDetail(),
  ) {
    super(id)
    this.vehicleType = vehicleType
    this.abiCode = abiCode
    this.vin = vin
    this.registration = registration
    this.make = make
    this.model = model
    this.cubicCapacity = cubicCapacity
    this.value = value
    this.yearOfMake = yearOfMake
    this.riderWillCarryPillion = riderWillCarryPillion
    this.isModified = isModified
    this.modifications = modifications
    this.annualMileage = annualMileage
    this.isOwnedByProposer = isOwnedByProposer
    this.lengthOfOwnership = lengthOfOwnership
    this.classOfUse = classOfUse
    this.isPrivatelyStored = isPrivatelyStored
    this.vehicleStorage = vehicleStorage
    this.purchaseDate = purchaseDate
    this.voluntaryExcess = voluntaryExcess
    this.securityDevice = securityDevice
    this.europeanCover = europeanCover
    this.address = address
    this.vehicleCondition = vehicleCondition
  }
}
