import type _Vue from 'vue'
import type ITransitionAction from '@/page-transitions/transition-actions/ITransitionAction'
import type ContextLevel from '@/services/enums/ContextLevel'
import type JourneyPage from '@/mixins/JourneyPage.vue'
import GetQuoteAction from '@/page-transitions/transition-actions/GetQuoteAction'
import type { PageNavigationConfig } from '@/view-models/PageNavigationConfig'
import NoActionAction from '@/page-transitions/transition-actions/NoActionAction'
import GetMtaQuoteAction from '@/page-transitions/transition-actions/GetMtaQuoteAction'
import GetRenewalQuoteAction from '@/page-transitions/transition-actions/GetRenewalQuoteAction'
import type IJourneyPage from '@/mixins/IJourneyPage'

export class JourneyTransitionProcessor {
  private transitionActions: ITransitionAction[]

  constructor() {
    this.transitionActions = [new NoActionAction(), new GetQuoteAction(), new GetMtaQuoteAction(), new GetRenewalQuoteAction()]
  }

  async process(currentContext: ContextLevel, nextContext: ContextLevel, page: JourneyPage): Promise<PageNavigationConfig | null> {
    let params: PageNavigationConfig | null = null

    for (let i = 0; i < this.transitionActions.length; i++) {
      const transactionAction = this.transitionActions[i]
      if (transactionAction.canExecute(currentContext, nextContext)) {
        const actionParams = await transactionAction.execute(page as unknown as IJourneyPage)
        if (actionParams) {
          if (!params)
            params = {}

          params = { ...params, ...actionParams }
        }
      }
    }
    return params
  }
}

export default function JourneyTransitionsPlugin(Vue: typeof _Vue): void {
  Vue.prototype.$journeyTransitions = new JourneyTransitionProcessor()
}
