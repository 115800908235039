import type ITransitionAction from '@/page-transitions/transition-actions/ITransitionAction'
import ContextLevel from '@/services/enums/ContextLevel'
import { RatingStatus } from '@/services/values/RatingResponse'
import Routes from '@/constants/Routes'
import type { PageNavigationConfig } from '@/view-models/PageNavigationConfig'
import type ProductEnum from '@/services/enums/ProductEnum'
import type IJourneyPage from '@/mixins/IJourneyPage'
import GlobalEventsEnum from '@/services/enums/GlobalEventsEnum'
import useAnswersStore from '@/stores/answers-store'
import BadRequestError from '@/services/error/BadRequestError'

export default class GetRenewalQuoteAction implements ITransitionAction {
  canExecute(currentContext: ContextLevel, nextContext: ContextLevel): boolean {
    return currentContext === ContextLevel.renewalPreQuote && nextContext === ContextLevel.renewalQuote
  }

  async execute(page: IJourneyPage): Promise<PageNavigationConfig | null> {
    page.$events.emit(GlobalEventsEnum.setLoadingDisplayText, 'Getting you a price...')
    page.isLoading = true
    const answersStore = useAnswersStore()
    const currentAnswers = answersStore.answers
    try {
      const ratingResponse = await page.$services.ratingEngineService.updateRenewalQuote(page.$route.params.policyNumber, page.$route.params.quoteReference, currentAnswers, page.$route.params.product as ProductEnum)
      if (ratingResponse.status === RatingStatus.quoted) {
        page.isLoading = false
        return { params: { quoteReference: ratingResponse.quoteReference! } }
      }
      else if (ratingResponse.status === RatingStatus.refer) {
        page.$router.replace({
          name: Routes.quoteReferred,
          params: { policyNumber: page.$route.params.policyNumber, quoteReference: ratingResponse.quoteReference!, transactionType: 'renewal' },
        })
      }
      else {
        page.$router.replace({
          name: Routes.quoteDeclined,
          query: { renewal: page.$route.params.policyNumber },
        })
      }

      page.isLoading = false
    }
    catch (e) {
      if (e instanceof BadRequestError) {
        page.$buefy.toast.open({
          duration: 5000,
          message: (Object.values(e.modelState!)[0]) ?? e.message,
          type: 'is-danger',
          position: 'is-bottom',
        })
      }
      else {
        throw e
      }
    }
    return null
  }
}
