import { JsonObject, JsonProperty } from 'json2typescript'
import Model from './Model'

@JsonObject('CustomListDetail')
export default class CustomListDetail extends Model {
  @JsonProperty('name', String)
  name: string

  @JsonProperty('dependencyId', Number)
  dependencyId: number | null

  constructor(
    id = 0,
    name = '',
    dependencyId = 0,
  ) {
    super(id)
    this.name = name
    this.dependencyId = dependencyId
  }
}
