import { JsonObject, JsonProperty } from 'json2typescript'

export enum RatingStatus {
  quoted = 0,
  refer = 1,
  decline = 2,
}

@JsonObject('RatingResponse')
export default class RatingResponse {
  @JsonProperty('quoteReference', String)
  quoteReference: string | null = null

  @JsonProperty('status', Number)
  status: RatingStatus = RatingStatus.quoted

  @JsonProperty('price', Number)
  price: number | null = null

  @JsonProperty('quoteId', Number)
  quoteId: number | null = null
}
