import type { RawLocation } from 'vue-router'
import Routes from '@/constants/Routes'
import ProductEnum from '@/services/enums/ProductEnum'
import UnknownProductError from '@/services/error/UnknownProductError'
import type Product from '@/services/models/Product'
import Icon from '@/view-models/Icon'

export function getProductRoute(product: Product): RawLocation {
  return {
    name: Routes.productJourney,
    params: {
      product: product.uniqueId,
    },
  }
};

export function getProductIcon(product: ProductEnum): Icon {
  switch (product) {
    case ProductEnum.motorCaravan:
      return new Icon('icon-motor-caravan', 'wrapper')
    case ProductEnum.modernCar:
    case ProductEnum.vehicle:
      return new Icon('car')
    case ProductEnum.multiPet:
      return new Icon('paw')
    case ProductEnum.taxi:
      return new Icon('taxi')
    case ProductEnum.courier:
    case ProductEnum.commercialVehicle:
      return new Icon('van-utility')
    case ProductEnum.gapInsurance:
      return new Icon('icon-gap-car', 'wrapper')
    case ProductEnum.staticCaravanResidential:
      return new Icon('icon-caravan')
    case ProductEnum.touringCaravan:
    case ProductEnum.caravan:
      return new Icon('icon-touring-caravan', 'wrapper')
    case ProductEnum.home:
      return new Icon('home')
    default:
      throw new UnknownProductError(product)
  }
}
