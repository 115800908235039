import { Any, JsonObject, JsonProperty } from 'json2typescript'
import { UIFieldSet } from './UIField'
import Model from './Model'
import type { Dictionary } from '@/types'

@JsonObject('ProductPage')
export default class ProductPage extends Model {
  @JsonProperty('name', String)
  name: string

  @JsonProperty('uniqueId', String)
  uniqueId: string

  @JsonProperty('title', String)
  title: string

  @JsonProperty('subTitle', String)
  subTitle: string

  @JsonProperty('uiFieldSets', [UIFieldSet])
  uiFieldSets: UIFieldSet[]

  @JsonProperty('configs', Any)
  configs: Dictionary<string>

  constructor(
    id = 0,
    name = '',
    uniqueId = '',
    title = '',
    subTitle = '',
    uiFieldSets: UIFieldSet[] = [],
    configs: Dictionary<string> = {},
  ) {
    super(id)
    this.name = name
    this.uniqueId = uniqueId
    this.title = title
    this.subTitle = subTitle
    this.uiFieldSets = uiFieldSets
    this.configs = configs
  }
}
