import { JsonObject, JsonProperty } from 'json2typescript'
import QuoteRequest from '../QuoteRequest'
import VehicleDriver from './VehicleDriver'
import VehicleProposer from './VehicleProposer'
import VehicleRiskVehicle from './VehicleRiskVehicle'

@JsonObject('VehicleQuoteRequest')
export default class VehicleQuoteRequest extends QuoteRequest {
  @JsonProperty('vehicleProposer', VehicleProposer)
    vehicleProposer: VehicleProposer = new VehicleProposer()

  @JsonProperty('vehicles', [VehicleRiskVehicle])
    vehicles: VehicleRiskVehicle[] = []

  @JsonProperty('vehicleDrivers', [VehicleDriver])
    vehicleDrivers: VehicleDriver[] = []

  @JsonProperty('voluntaryExcess', String, true)
    voluntaryExcess: string | null = null

  @JsonProperty('coverType', String, true)
    coverType: string | null = null
}

@JsonObject('VehicleMTAQuoteRequest')
export class VehicleMTAQuoteRequest extends VehicleQuoteRequest {
  @JsonProperty('reasonForChange', String)
    reasonForChange = ''
}
