import type { JsonConvert } from 'json2typescript'
import type { AxiosInstance } from 'axios'
import type Page from '../view-models/Page'
import type Product from './models/Product'
import ProductPage from './models/ProductPage'
import type IQuestionMapper from './interfaces/IQuestionMapper'
import type IProductService from './interfaces/IProductService'
import { ProductCollectionResponse } from './values/CollectionResponse'
import type { ProductJourneyType } from './enums/ProductEnum'
import type ProductEnum from './enums/ProductEnum'
import type { IQuestionAnswer } from './values/PageAnswers'
import type { SubPagesQuestion } from '@/view-models/Question'
import type ContextLevel from '@/services/enums/ContextLevel'
import type { Dictionary } from '@/types'
import type Routes from '@/constants/Routes'

export default class ProductService implements IProductService {
  axios: AxiosInstance

  apiUrl: URL

  questionMapper: IQuestionMapper

  nbPageMappings: Dictionary<{ route: Routes, contextLevel: ContextLevel }>

  mtaPageMappings: Dictionary<{ route: Routes, contextLevel: ContextLevel }>

  renewalPageMappings: Dictionary<{ route: Routes, contextLevel: ContextLevel }>

  jsonConvert: JsonConvert

  constructor(axios: AxiosInstance, apiUrl: URL, questionMapper: IQuestionMapper, nbPageMappings: Dictionary<{ route: Routes, contextLevel: ContextLevel }>, mtaPageMappings: Dictionary<{ route: Routes, contextLevel: ContextLevel }>, renewalPageMappings: Dictionary<{ route: Routes, contextLevel: ContextLevel }>, jsonConvert: JsonConvert) {
    this.axios = axios
    this.apiUrl = apiUrl
    this.questionMapper = questionMapper
    this.nbPageMappings = nbPageMappings
    this.mtaPageMappings = mtaPageMappings
    this.renewalPageMappings = renewalPageMappings
    this.jsonConvert = jsonConvert
  }

  async getByUniqueId(id: ProductEnum): Promise<Product> {
    const result = await this.axios.get(`${this.apiUrl}/product/${id}`)
    return result.data as Product
  }

  async getPage(id: ProductEnum, setId: string, schemeCode: string | null, coverLevel: number | null, answers: IQuestionAnswer[], affiliateId?: number, parent?: SubPagesQuestion): Promise<Page> {
    const result = await this.axios.get(`${this.apiUrl}/product/${encodeURIComponent(id)}/page/${encodeURIComponent(setId)}`, { params: { schemeCode, coverLevel } })
    const productPage = this.jsonConvert.deserializeObject(result.data, ProductPage)
    return this.questionMapper.setupPage(setId, productPage, answers, affiliateId, parent)
  }

  async getAll(): Promise<ProductCollectionResponse> {
    const result = await this.axios.get(`${this.apiUrl}/product/all`)
    return this.jsonConvert.deserializeObject(result.data, ProductCollectionResponse)
  }

  async getAllPages(id: ProductEnum, type: ProductJourneyType, schemeCode: string | null, coverLevel: number | null, answers: IQuestionAnswer[]): Promise<Page[]> {
    const result = await this.axios.get(`${this.apiUrl}/product/${encodeURIComponent(id)}/pages/${encodeURIComponent(type)}`, { params: { schemeCode, coverLevel } })
    const productPages = this.jsonConvert.deserializeArray(result.data, ProductPage)
    return productPages.map(p => this.questionMapper.setupPage(p.uniqueId, p, answers))
  }
}
