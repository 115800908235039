import { JsonObject, JsonProperty } from 'json2typescript'
import DateConverter from '../../converters/DateConverter'
import PersonalDetails from './PersonalDetails'

@JsonObject('CaravanProposerRequest')
export default class CaravanProposerRequest extends PersonalDetails {
  @JsonProperty('titleDLDID', String)
  titleDLDID: string = ''

  @JsonProperty('emailAddress', String, true)
  emailAddress: string | null = null

  @JsonProperty('telephoneNumber', String, true)
  telephoneNumber: string | null = null

  @JsonProperty('maritalStatusDLDID', String, true)
  maritalStatus: string | null = null

  @JsonProperty('uKResident', Boolean, true)
  uKResident: boolean | null = null

  @JsonProperty('uKResidentSince', DateConverter, true)
  uKResidentSince: Date | null = null

  @JsonProperty('isPartTime', Boolean, true)
  isPartTime: boolean | null = null

  @JsonProperty('isHomeOwner', Boolean, true)
  isHomeOwner: boolean | null = null

  @JsonProperty('hadInsuranceRefusedCancelledOrDeclined', Boolean, true)
  hadInsuranceRefusedCancelledOrDeclined: boolean | null = null

  @JsonProperty('hasUnspentNonMotoringConvictions', Boolean, true)
  hasUnspentNonMotoringConvictions: boolean | null = null

  @JsonProperty('hasBeenRequestedExtraPrecautions', Boolean, true)
  hasBeenRequestedExtraPrecautions: boolean | null = null

  @JsonProperty('hasDeclaredBankruptcy', Boolean, true)
  hasDeclaredBankruptcy: boolean | null = null

  @JsonProperty('hasClaims', Boolean, true)
  hasClaims: boolean | null = null

  @JsonProperty('addressLine1', String, true)
  addressLine1: string | null = null

  @JsonProperty('addressLine2', String, true)
  addressLine2: string | null = null

  @JsonProperty('addressLine3', String, true)
  addressLine3: string | null = null

  @JsonProperty('addressLine4', String, true)
  addressLine4: string | null = null

  @JsonProperty('postCode', String, true)
  postCode: string | null = null

  @JsonProperty('region', String, true)
  region: string | null = null

  @JsonProperty('city', String, true)
  city: string | null = null

  @JsonProperty('country', String, true)
  country: string | null = null

  @JsonProperty('employmentStatusDLDID', String, true)
  employmentStatus: string | null = null

  @JsonProperty('occupationDLDID', String, true)
  occupation: string | null = null

  @JsonProperty('businessTypeDLDID', String, true)
  businessType: string | null = null

  @JsonProperty('hasSecondaryEmployment', Boolean, true)
  hasSecondaryEmployment: boolean | null = null

  @JsonProperty('secondaryOccupationDLDID', String, true)
  secondaryOccupation: string | null = null

  @JsonProperty('secondaryBusinessTypeDLDID', String, true)
  secondaryBusinessType: string | null = null

  @JsonProperty('isEmailOptIn', Boolean, true)
  isEmailOptIn: boolean | null = null

  @JsonProperty('isPostOptIn', Boolean, true)
  isPostOptIn: boolean | null = null

  @JsonProperty('isSmsOptIn', Boolean, true)
  isSmsOptIn: boolean | null = null

  @JsonProperty('isTelephoneOptIn', Boolean, true)
  isTelephoneOptIn: boolean | null = null

  @JsonProperty('isClubMember', Boolean, true)
  isClubMember: boolean | null = null

  @JsonProperty('clubDLDID', String, true)
  club: string | null = null

  @JsonProperty('hadPreviousInsurance', Boolean, true)
  hadPreviousInsurance: boolean | null = null

  @JsonProperty('referralSourceDLDID', String, true)
  referralSource: string | null = null

  @JsonProperty('authorisedPersonsName', String, true)
  authorisedPersonsName: string | null = null

  @JsonProperty('voluntaryXSDLDID', String, true)
  voluntaryXS: string | null = null
}
