<template>
  <DashboardItem title="Renewals" :loaded="loaded">
    <template #content>
      <div v-if="total > 0">
        <p v-if="total === 1">Your renewal date is:</p>
        <p v-else>Here is your next policy renewal:</p>
         <table>
          <tr v-for="(policy) in results" :key="policy.policyNumber">
            <td>
              <span class="summary-title">Renewal Date</span>
              {{ dateFormatter(policy.renewalDate) }}
            </td>
            <td>
              <b-button @click="goToPolicy(policy)">Details</b-button>
            </td>
          </tr>
        </table>
      </div>
      <p v-else>You have no policies due for renewal</p>
    </template>
  </DashboardItem>
</template>

<script lang="ts">
import {
  Component,
  Vue,
} from 'vue-property-decorator';
import moment from 'moment';
import PolicySummary from '@/services/values/PolicySummary';
import Routes from '@/constants/Routes';
import DashboardItem from './DashboardItem.vue';
import NotAuthorisedError from '../../services/error/NotAuthorisedError';

@Component({
  components: {
    DashboardItem,
  },
})
export default class Renewals extends Vue {
  results: PolicySummary[] = [];

  total = 0;

  loaded = false;

  get policiesRoute() {
    return {
      name: Routes.policies,
    };
  }

  dateFormatter(date: Date) {
    return moment(date).format('Do MMMM YYYY');
  }

  async created() {
    try {
      const response = await this.$services.policyService.getRenewals(0, 0);
      this.results = response.results;
      this.total = response.total;
      this.loaded = true;
    } catch (error) {
      if (!(error instanceof NotAuthorisedError)) {
        throw error;
      }
    }
  }

  goToPolicy(policy: PolicySummary) {
    this.$router.push({
      name: Routes.policy,
      params: {
        product: policy.product,
        policyNumber: policy.policyNumber,
      },
    });
  }
}
</script>
