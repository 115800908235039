import { JsonObject, JsonProperty } from 'json2typescript'
import ProductEnum from '../enums/ProductEnum'
import ProductEnumConverter from '@/converters/ProductEnumConverter'

@JsonObject('AggregatorQuoteReference')
export default class AggregatorQuoteReference {
  @JsonProperty('reference', String)
  reference: string

  @JsonProperty('premium', Number)
  premium: number

  @JsonProperty('product', ProductEnumConverter)
  product: ProductEnum

  @JsonProperty('schemeCode', String)
  schemeCode: string

  @JsonProperty('quoteReference', String)
  quoteReference: string

  constructor(
    reference = '',
    premium = 0,
    product = ProductEnum.modernCar,
    schemeCode = '',
    quoteReference = '',
  ) {
    this.reference = reference
    this.premium = premium
    this.product = product
    this.schemeCode = schemeCode
    this.quoteReference = quoteReference
  }
}
