import { JsonObject, JsonProperty } from 'json2typescript'
import DateConverter from '../../converters/DateConverter'
import QuoteStatusEnum from '../enums/QuoteStatusEnum'
import Model from './Model'
import VAP from './VAP'
import type SchemeQuoteResult from './SchemeQuoteResult'
import AggregatorClient from './AggregatorClient'
import TransactionType from '@/services/models/TransactionType'
import SchemeQuoteResultsConverter from '@/converters/SchemeQuoteResultsConverter'
import QuoteStatusEnumConverter from '@/converters/QuoteStatusEnumConverter'

@JsonObject('QuoteDetails')
export default abstract class QuoteDetails<T extends SchemeQuoteResult> extends Model {
  @JsonProperty('quoteReference', String)
  quoteReference = ''

  @JsonProperty('aggregatorQuoteReference', String)
  aggregatorQuoteReference = ''

  @JsonProperty('aggregatorClient', AggregatorClient)
  aggregatorClient = new AggregatorClient()

  @JsonProperty('effectiveDate', DateConverter)
  effectiveDate: Date = new Date()

  @JsonProperty('status', QuoteStatusEnumConverter)
  status: QuoteStatusEnum = QuoteStatusEnum.quoted

  @JsonProperty('isActive', Boolean)
  isActive = true

  @JsonProperty('activePolicyNumber', String)
  activePolicyNumber = ''

  @JsonProperty('isFraud', Boolean)
  isFraud = false

  @JsonProperty('riskId', Number)
  riskId = 0

  @JsonProperty('vaps', [VAP])
  vaps: VAP[] = []

  @JsonProperty('quoteCreationDate', DateConverter)
  quoteCreationDate: Date = new Date()

  @JsonProperty('results', SchemeQuoteResultsConverter)
  results: T[] = []

  @JsonProperty('transactionType', TransactionType)
  transactionType: TransactionType | null = null
}
