import { JsonObject, JsonProperty } from 'json2typescript'
import DateConverter from '../../converters/DateConverter'
import { DefinedListDetail } from './DefinedList'
import TouringCaravanStorageLocation from './TouringCaravanStorageLocation'

@JsonObject('TouringCaravan')
export default class TouringCaravan {
  @JsonProperty('caravanType', DefinedListDetail)
  caravanType: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('make', DefinedListDetail)
  make: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('model', DefinedListDetail)
  model: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('towingFrequency', DefinedListDetail)
  towingFrequency: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('yearOfMake', Number, true)
  yearOfMake: number | null = null

  @JsonProperty('purchaseDate', DateConverter, true)
  purchaseDate: Date | null = null

  @JsonProperty('coverType', DefinedListDetail)
  coverType: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('isCRISRegistered', Boolean, true)
  isCRISRegistered: boolean | null = null

  @JsonProperty('crisNumber', String, true)
  crisNumber: string | null = null

  @JsonProperty('alarmType', DefinedListDetail)
  alarmType: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('trackerType', DefinedListDetail)
  trackerType: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('inGoodCondition', Boolean, true)
  inGoodCondition: boolean | null = null

  @JsonProperty('servicedInLastTwoYears', Boolean, true)
  servicedInLastTwoYears: boolean | null = null

  @JsonProperty('isPermanantlySited', Boolean, true)
  isPermanantlySited: boolean | null = null

  @JsonProperty('currentValue', Number, true)
  currentValue: number | null = null

  @JsonProperty('purchasePrice', Number, true)
  purchasePrice: number | null = null

  @JsonProperty('touringCaravanStorageLocations', [TouringCaravanStorageLocation])
  touringCaravanStorageLocations: TouringCaravanStorageLocation[] = []

  @JsonProperty('caravanSafety', [String])
  caravanSafety: string[] = []

  @JsonProperty('caravanTagging', [String])
  caravanTagging: string[] = []

  @JsonProperty('caravanSecurity', [String])
  caravanSecurity: string[] = []

  @JsonProperty('hasAgreedToDeclarations', Boolean, true)
  hasAgreedToDeclarations: boolean | null = null
}
