import { JsonObject, JsonProperty } from 'json2typescript'
import DateConverter from '../../converters/DateConverter'
import { DefinedListDetail } from './DefinedList'

@JsonObject('CaravanCover')
export default class CaravanCover {
  @JsonProperty('effectiveDate', DateConverter, true)
  effectiveDate: Date | null = null

  @JsonProperty('effectiveDateBufferMinutes', Number, true)
  effectiveDateBufferMinutes: number | null = null

  @JsonProperty('billingPeriod', String, true)
  billingPeriod: string | null = null

  @JsonProperty('voluntaryExcess', DefinedListDetail, true)
  voluntaryExcess: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('basisOfCover', DefinedListDetail, true)
  basisOfCover: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('coverForAwnings', Boolean, true)
  coverForAwnings: boolean | null = null

  @JsonProperty('caravanAwningValue', Number, true)
  caravanAwningValue: number | null = null

  @JsonProperty('requireLeisureEquipmentInsurance', Boolean, true)
  requireLeisureEquipmentInsurance: boolean | null = null

  @JsonProperty('leisureEquipmentValue', Number, true)
  leisureEquipmentValue: number | null = null

  @JsonProperty('coverForContents', Boolean, true)
  coverForContents: boolean | null = null

  @JsonProperty('caravanContentsValue', Number, true)
  caravanContentsValue: number | null = null

  @JsonProperty('coverForPersonalEffects', Boolean, true)
  coverForPersonalEffects: boolean | null = null

  @JsonProperty('caravanPersonalEffectsValue', Number, true)
  caravanPersonalEffectsValue: number | null = null

  @JsonProperty('requireHighRiskItemsInsurance', Boolean, true)
  requireHighRiskItemsInsurance: boolean | null = null

  @JsonProperty('highRiskItemsValue', Number, true)
  highRiskItemsValue: number | null = null

  @JsonProperty('requirePublicLiabilityInsurance', Boolean, true)
  requirePublicLiabilityInsurance: boolean | null = null

  @JsonProperty('publicLiability', DefinedListDetail, true)
  publicLiability: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('europeanCoverRequired', Boolean, true)
  europeanCoverRequired: boolean | null = null

  @JsonProperty('hasHotTub', Boolean, true)
  hasHotTub: boolean | null = null

  @JsonProperty('caravanNoClaimsBonus', DefinedListDetail, true)
  caravanNoClaimsBonus: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('householdNoClaimsBonus', DefinedListDetail, true)
  householdNoClaimsBonus: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('hadPreviousInsurance', Boolean, true)
  hadPreviousInsurance: boolean | null = null

  @JsonProperty('previousInsurerListID', Number, true)
  previousInsurerListID: number | null = null

  @JsonProperty('previousPolicyNumber', String, true)
  previousPolicyNumber: string | null = null

  @JsonProperty('previousPolicyExpiration', DateConverter, true)
  previousPolicyExpiration: Date | null = null
}
