enum CarLicenceTypeEnum {
  FullUK = 'CarLicenceTypes.FullUKCarLicence',
  ProvisionalUK = 'CarLicenceTypes.ProvisionalUKCarLicence',
  MedicalRestrictedFull3YearsOrMoreCar = 'CarLicenceTypes.MedicalRestrictedFull3YearsOrMoreCar',
  MedicalRestrictedFullLessThan3YearsCar = 'CarLicenceTypes.MedicalRestrictedFullLessThan3YearsCar',
  MedicalRestrictedProvisionalLessThen3YearsCar = 'CarLicenceTypes.MedicalRestrictedProvisionalLessThen3YearsCar',
  MedicalRestrictedProvisional3YearsOrMoreCar = 'CarLicenceTypes.MedicalRestrictedProvisional3YearsOrMoreCar',
  FullEULicence = 'CarLicenceTypes.FullEULicence',
  FullInternationalLicence = 'CarLicenceTypes.FullInternationalLicence',
  Other = 'CarLicenceTypes.Other',
}

enum OccupationEnum {
  AbattoirWorker = 'Occupation.AbattoirWorker',
  AccommodationOfficer = 'Occupation.AccommodationOfficer',
  Retired = 'Occupation.Retired',
  Unemployed = 'Occupation.Unemployed',
  Student = 'Occupation.Student',
  NotEmployedDueToDisability = 'Occupation.NotEmployedDuetoDisability',
  Househusband = 'Occupation.Househusband',
  Housewife = 'Occupation.Housewife',
  NotInEmployment = 'Occupation.NotInEmployment',
}

enum StaticCaravanDimensionMeasureEnum {
  Metres = 'StaticCaravanDimensionMeasure.Metres',
  Feet = 'StaticCaravanDimensionMeasure.Feet',
}

enum EmploymentStatusEnum {
  Employed = 'EmploymentStatus.Employed',
  Unemployed = 'EmploymentStatus.Unemployed',
  Retired = 'EmploymentStatus.Retired',
  FullOrPartTimeEducation = 'EmploymentStatus.InFullOrPartTimeEducation',
  HouseholdDuties = 'EmploymentStatus.HouseholdDuties',
  IndependentMeans = 'EmploymentStatus.IndependentMeans',
  NotEmployedDueToDisability = 'EmploymentStatus.NotEmployedDueToDisability',
  FinanciallyAssisted = 'EmploymentStatus.FinanciallyAssisted',
}

enum BusinessCategoryEnum {
  NonRetired = 'BusinessCategory.NoneRetired',
  NonUnemployed = 'BusinessCategory.NoneUnemployed',
  NoneStudent = 'BusinessCategory.NoneStudent',
  NoneNotEmployedduetoDisability = 'BusinessCategory.NoneNotEmployedduetoDisability',
  NoneHouseholdDuties = 'BusinessCategory.NoneHouseholdDuties',
  NotInEmployment = 'BusinessCategory.NotInEmployment',
}

enum MarketingOptionEnum {
  Telephone = 'MarketingOptions.Telephone',
  Email = 'MarketingOptions.Email',
  Post = 'MarketingOptions.Post',
  SMS = 'MarketingOptions.SMS',
  NoThanks = 'MarketingOptions.NoThanks',
}

enum DrivingFrequencyEnum {
  NotAvailable = 'DrivingFrequency.NotAvailable',
  Main = 'DrivingFrequency.Main',
}

enum TowingFrequencyEnum {
  PermanentlySited = 'TowingFrequency.PermanentlySited',
}

enum VehicleUseEnum {
  ContractHire = 'VehicleUse.ContractHire',
  HireAndReward = 'VehicleUse.HireAndReward',
}
enum VehicleMileageEnum {
  Zero = 'VehicleAnnualMileage.0',
}

enum CommercialVehicleVehicleTypeEnum {
  Minibus = 'CommercialVehicleVehicleType.Minibus',
}

enum BooleanEnum {
  No = 'BooleanAnswer.NO',
  Yes = 'BooleanAnswer.Yes',
}

export enum LicenseHeldYearsEnum {
  ZeroYears = 'VehicleLicenceLengthHeld.0Years',
  OneYear = 'VehicleLicenceLengthHeld.1Year',
  TwoYear = 'VehicleLicenceLengthHeld.2Years',
  ThreeYear = 'VehicleLicenceLengthHeld.3Years',
  FourYear = 'VehicleLicenceLengthHeld.4Years',
  FivePlusYear = 'VehicleLicenceLengthHeld.5PlusYears',
}

export enum MonthsStoredAtLocationEnum {
  LessThan1 = 'MonthsStoredAtLocation.LessThan1',
  LessThan2 = 'MonthsStoredAtLocation.LessThan2',
  LessThan3 = 'MonthsStoredAtLocation.LessThan3',
  LessThan4 = 'MonthsStoredAtLocation.LessThan4',
  LessThan5 = 'MonthsStoredAtLocation.LessThan5',
  LessThan6 = 'MonthsStoredAtLocation.LessThan6',
  LessThan7 = 'MonthsStoredAtLocation.LessThan7',
  LessThan8 = 'MonthsStoredAtLocation.LessThan8',
  LessThan9 = 'MonthsStoredAtLocation.LessThan9',
  NineOrMore = 'MonthsStoredAtLocation.9OrMore',
}

enum CaravanLocationKeptAtEnum {
  Home = 'CaravanLocationKeptAt.AtHome',
  Farm = 'CaravanLocationKeptAt.Farm',
  CaravanPark = 'CaravanLocationKeptAt.CaravanPark',
  Other = 'CaravanLocationKeptAt.Other',
  Storage = 'CaravanLocationKeptAt.StorageFacility',
  PermanentlySited = 'CaravanLocationKeptAt.PermanentlySited',
}

enum CaravanStorageEnum {
  CaSSOA = 'CaravanStorage.CaSSOA',
  NonCaSSOA = 'CaravanStorage.NonCaSSOA',
  Other = 'CaravanStorage.Other',
  OtherResidentialAddress = 'CaravanStorage.OtherResidentialAddress',
  RelativeFriendsHome = 'CaravanStorage.RelativeFriendsHome',
}

enum RegisteredKeeperEnum {
  ProposerPolicyHolder = 'RegisteredKeeper.ProposerPolicyholder',
  Other = 'RegisteredKeeper.Other',
}

enum CoverTypeEnum {
  ThirdParty = 'CoverType.ThirdParty',
  AccidentalDamageFireAndTheft = 'CoverType.AccidentalDamageFireAndTheft',
  Comprehensive = 'CoverType.Comprehensive',
}

enum VolXsEnum {
  VolXs0 = 'VolXs.0',
  VolXs250 = 'VolXs.250',
}

enum PolicyClass {
  CommercialVehicle = 'PolicyClass.CommercialVehicle',
  PrivateCar = 'PolicyClass.PrivateCar',
  PublicHire = 'PolicyClass.PublicHire',
  PrivateHire = 'PolicyClass.PrivateHire',
}

enum CommercialClassOfUseEnum {
  SocialDomesticPleasureAndCommuting = 'CommercialVehicleClassOfUse.SocialDomesticPleasureAndCommuting',
  SocialDomesticPleasureOnly = 'CommercialVehicleClassOfUse.SocialDomesticPleasureOnly',
}

enum ClassOfUseEnum {
  SDPOnly = 'CarClassOfUse.SocialDomesticAndPleasure',
  SDPCommuting = 'CarClassOfUse.Commuting',
  BusinessClass1 = 'CarClassOfUse.ProposersBusiness',
  SpouseBusiness = 'CarClassOfUse.SpousesBusiness',
  BusinessClass3 = 'CarClassOfUse.NamedDriversBusiness',
}

enum TitleEnum {
  Mr = 'Title.Mr',
  Mrs = 'Title.Mrs',
  Miss = 'Title.Miss',
  Ms = 'Title.Ms',
  Mx = 'Title.Mx',
  DoctorFemale = 'Title.DoctorFemale',
  DoctorMale = 'Title.DoctorMale',
}

enum PaymentTypeEnum {
  CreditCard = 'PaymentType.CardPayment',
  DirectDebit = 'PaymentType.DirectDebit',
  GoCardless = 'PaymentType.GoCardless',
}

enum ReasonForChangeEnum {
  ChangeVehicle = 'ReasonForChange.ChangeVehicle',
  ChangeRegistration = 'ReasonForChange.ChangeRegistration',
  AddDriver = 'ReasonForChange.AddDriver',
  RemoveDriver = 'ReasonForChange.RemoveDriver',
  ChangeAddress = 'ReasonForChange.ChangeAddress',
  AddBusinessUse = 'ReasonForChange.AddBusinessUse',
  ChangeClassOfUse = 'ReasonForChange.ChangeClassOfUse',
  ChangeMileage = 'ReasonForChange.ChangeMileage',
  MultipleChanges = 'ReasonForChange.MultipleChanges',
  VAPs = 'ReasonForChange.VAPs',
}

enum ReasonForChangeHomeEnum {
  AddJointProposer = 'ReasonForChangeHome.AddJointProposer',
  AddSpecifiedItem = 'ReasonForChangeHome.AddSpecifiedItem',
  ChangeAddress = 'ReasonForChangeHome.ChangeOfAddress',
  ChangeSumsInsured = 'ReasonForChangeHome.ChangeSumsInsured',
  MultipleChanges = 'ReasonForChangeHome.MultipleChanges',
  RemoveJointProposer = 'ReasonForChangeHome.RemoveJointProposer',
  RemovingContents = 'ReasonForChangeHome.RemovingContents',
  VAPs = 'ReasonForChangeHome.VAPs',
}

enum BusinessSourceEnum {
  AggregatorID = 1,
  ComparisonSiteID = 55,
}

enum PetTypeEnum {
  Cat = 'PetType.Cat',
  Dog = 'PetType.Dog',
}

enum CatTypeEnum {
  Moggie = 'CatType.Moggie',
  Pedigree = 'CatType.Pedigree',
}

enum DogTypeEnum {
  Crossbreed = 'DogType.Crossbreed',
  Mongrel = 'DogType.Mongrel',
  Pedigree = 'DogType.Pedigree',
}

enum PetCoverTypeEnum {
  AccidentOnly = 'PetCoverType.AccidentOnly',
  AccidentAndIllness = 'PetCoverType.AccidentAndIllness',
}

enum DogBreedsEnum {
  Mongrel = 'DogBreeds.Mongrel',
  Crossbreed = 'DogBreeds.Crossbreed',
  CrossbreedLarge = 'DogBreeds.CrossbreedLarge',
  CrossbreedMedium = 'DogBreeds.CrossbreedMedium',
  CrossbreedSmall = 'DogBreeds.CrossbreedSmall',
}
enum CatBreedsEnum {
  Moggie = 'CatBreeds.Moggie',
}

export enum NumberOfCaravansOnSiteEnum {
  Oneto10 = 'NumberOfCaravansOnSite.1to10',
  Elevento20 = 'NumberOfCaravansOnSite.11to20',
  TwentyOneto30 = 'NumberOfCaravansOnSite.21to30',
  ThirtyOneto40 = 'NumberOfCaravansOnSite.31to40',
  FortyOneto50 = 'NumberOfCaravansOnSite.41to50',
  fiftyOneto60 = 'NumberOfCaravansOnSite.51to60',
  SixtyOneto70 = 'NumberOfCaravansOnSite.61to70',
  SeventyOneto80 = 'NumberOfCaravansOnSite.71to80',
  EightyOneto90 = 'NumberOfCaravansOnSite.81to90',
  NinetyPlus = 'NumberOfCaravansOnSite.90+',
}

export enum NCDYearsEnum {
  Years0 = 'NCDYears.0',
  Years1 = 'NCDYears.1',
  Years2 = 'NCDYears.2',
  Years3 = 'NCDYears.3',
  Years4 = 'NCDYears.4',
  Years5 = 'NCDYears.5',
  Years6 = 'NCDYears.6',
  Years7 = 'NCDYears.7',
  Years8 = 'NCDYears.8',
  Years9 = 'NCDYears.9',
  Years10Plus = 'NCDYears.10+',
}

enum CoverTerm {
  Years1 = 'CoverTerm.1',
  Years2 = 'CoverTerm.2',
  Years3 = 'CoverTerm.3',
  Years4 = 'CoverTerm.4',
}

enum BreedTypeEnum {
  Dog = '2',
  Cat = '3',
}

enum DogShowHideClassesEnum {
  ShowClasses = '6',
  HideClasses = '7',
}

enum CatShowHideClassesEnum {
  ShowClasses = '7',
  HideClasses = '6',
}

enum MotorhomeTypesEnum {
  Other = 'MotorHomeType.Other',
}

enum PetSubCoverTypeEnum {
  TimeLimited = 'PetSubCoverType.PerConditionUpTo12Months',
  MaximumBenefit = 'PetSubCoverType.PerCondition',
  Lifetime = 'PetSubCoverType.ReinstatedAnnuallyWithThePolicy',
}
enum CurrentInsurer {
  NotListed = 'Insurers.NotListed',
}

enum PreviousInsurerName {
  NotListed = 'Insurers.NotListed',
}
enum PetCoverLevelEnum {
  Bronze = 'PetCoverLevel.AccidentOnly',
  Silver = 'PetCoverLevel.TimeLimited',
  Gold = 'PetCoverLevel.PerCondition',
  Premier = 'PetCoverLevel.Lifetime',
  PremierPlus = 'PetCoverLevel.LifetimePlus',
  Prime = 'PetCoverLevel.Prime',
}

enum BasisOfCoverEnum {
  MarketValue = 'BasisOfCover.Market Value',
  NewForOld = 'BasisOfCover.New For Old',
}

enum CaravanUsageEnum {
  PersonalHolidayUse = 'CaravanUsage.PersonalHolidayUse',
  Other = 'CaravanUsage.Other',
  BusinessUse = 'CaravanUsage.BusinessUse',
  CommercialLetting = 'CaravanUsage.CommercialLetting',
  HireAndReward = 'CaravanUsage.HireAndReward',
  PermanentResidence = 'CaravanUsage.PermanentResidence',
  PersonalAndFamilyUseAndHireAndReward = 'CaravanUsage.PersonalAndFamilyUseAndHireAndReward',
  PersonalAndFamilyUse = 'CaravanUsage.PersonalAndFamilyUse',
}

enum ClaimIncidentTypeEnum {
  Accident = 'ClaimIncidentType.Accident',
  Theft = 'ClaimIncidentType.Theft',
  Other = 'ClaimIncidentType.Other',
}

enum PolicyholderTypeEnum {
  Company = 'PolicyholderTypes.Company',
  Private = 'PolicyholderTypes.Private',
}

enum TypeOfVehicleEnum {
  Car = 'VehicleType.Car',
  Motorbike = 'VehicleType.Motorbike',
  Tractor = 'VehicleType.Tractor',
  Military = 'VehicleType.Military',
  Commercial = 'VehicleType.Commercial',
}

enum IsVehicleCarOrVan {
  Car = 'IsVehicleCarOrVan.Car',
  Van = 'IsVehicleCarOrVan.Van',
}

enum ModernCarVehicleType {
  Van = 'ModernCarVehicleType.Van',
}

enum EuropeanCoverDays {
  Days90 = 'EuropeanCoverDays.90',
  Days180 = 'EuropeanCoverDays.180',
}

enum PolicyDuration {
  Days30 = 'PolicyDuration.30Days',
}

enum RelatedRadioListEnum {
  OtherValue = 'OtherValue',
}

enum StaticCaravanSited {
  BuildingConstructionSite = 'StaticCaravanLocationKeptAt.BuildingConstructionSite',
  BusinessPremises = 'StaticCaravanLocationKeptAt.BusinessPremises',
  CaravanParkRegistered = 'StaticCaravanLocationKeptAt.CaravanParkRegistered',
  CaravanParkUnregistered = 'StaticCaravanLocationKeptAt.CaravanParkUnregistered',
  Farm = 'StaticCaravanLocationKeptAt.Farm',
  OtherLocation = 'StaticCaravanLocationKeptAt.OtherLocation',
  PrivateResidence = 'StaticCaravanLocationKeptAt.PrivateResidence',
}

enum RelationshipToPolicyHolder {
  NotApplicable = 'RelationshipToPolicyHolder.NotApplicable',
}

enum PropertyInsuranceOwnership {
  OwnedOutright = 'PropertyInsuranceOwnership.OwnedOutright',
  Mortgaged = 'PropertyInsuranceOwnership.Mortgaged',
  RentingCouncilFurnished = 'PropertyInsuranceOwnership.RentingCouncilFurnished',
  RentingCouncilUnfurnished = 'PropertyInsuranceOwnership.RentingCouncilUnfurnished',
  RentingPrivateFurnished = 'PropertyInsuranceOwnership.RentingPrivateFurnished',
  RentingPrivateUnfurnished = 'PropertyInsuranceOwnership.RentingPrivateUnfurnished',
  HousingAssociationFurnished = 'PropertyInsuranceOwnership.HousingAssociationFurnished',
  HousingAssociationUnfurnished = 'PropertyInsuranceOwnership.HousingAssociationUnfurnished',
}

enum PropertyInsuranceTypeOfPolicy {
  BuildingsAndContents = 'PropertyInsuranceTypeOfPolicy.BuildingsAndContents',
  Buildings = 'PropertyInsuranceTypeOfPolicy.Buildings',
  Contents = 'PropertyInsuranceTypeOfPolicy.Contents',
}

enum PropertyInsuranceWhoLivesAtProperty {
  PolicyholderOnly = 'PropertyInsuranceWhoLivesAtProperty.PolicyholderOnly',
  PolicyholderAndImmediateFamily = 'PropertyInsuranceWhoLivesAtProperty.PolicyholderandImmediateFamily',
  PolicyholderAndImmediateFamilyAndOrPartnersFamily = 'PropertyInsuranceWhoLivesAtProperty.PolicyholderandImmediateFamilyand/orPartner\'sFamily',
  Policyholderandstudents = 'PropertyInsuranceWhoLivesAtProperty.Policyholderandstudents',
  Policyholderandlodgers = 'PropertyInsuranceWhoLivesAtProperty.Policyholderandlodgers',
}

enum PropertyInsuranceTypeOfAlarm {
  NoAlarm = 'PropertyInsuranceTypeOfAlarm.NoAlarm',
}

export default class DLDCommonValuesEnum {
  static CarLicenceType = CarLicenceTypeEnum

  static ClaimIncidentType = ClaimIncidentTypeEnum

  static CoverTerm = CoverTerm

  static BasisOfCover = BasisOfCoverEnum

  static CaravanUsage = CaravanUsageEnum

  static MarketingOption = MarketingOptionEnum

  static DrivingFrequency = DrivingFrequencyEnum

  static TowingFrequency = TowingFrequencyEnum

  static Boolean = BooleanEnum

  static VehicleUse = VehicleUseEnum
  static VehicleMileage = VehicleMileageEnum

  static CommercialVehicleVehicleType = CommercialVehicleVehicleTypeEnum

  static CaravanLocationKeptAt = CaravanLocationKeptAtEnum

  static MonthsStoredAtLocation = MonthsStoredAtLocationEnum

  static LicenseHeldYears = LicenseHeldYearsEnum

  static CaravanStorage = CaravanStorageEnum

  static RegisteredKeeper = RegisteredKeeperEnum

  static CoverType = CoverTypeEnum

  static ClassOfUse = ClassOfUseEnum

  static CommercialClassOfUse = CommercialClassOfUseEnum

  static VolXs = VolXsEnum

  static StaticCaravanDimensionMeasureEnum = StaticCaravanDimensionMeasureEnum

  static Occupation = OccupationEnum

  static EmploymentStatus = EmploymentStatusEnum

  static BusinessCategory = BusinessCategoryEnum

  static Title = TitleEnum

  static PaymentType = PaymentTypeEnum

  static ReasonForChange = ReasonForChangeEnum

  static ReasonForChangeHome = ReasonForChangeHomeEnum

  static BusinessSourceEnum = BusinessSourceEnum

  static PetType = PetTypeEnum

  static CatType = CatTypeEnum

  static DogType = DogTypeEnum

  static PetCoverType = PetCoverTypeEnum

  static DogBreeds = DogBreedsEnum

  static CatBreeds = CatBreedsEnum

  static NCDYears = NCDYearsEnum

  static NumberOfCaravansOnSite = NumberOfCaravansOnSiteEnum

  static BreedType = BreedTypeEnum

  static DogShowHideClasses = DogShowHideClassesEnum

  static CatShowHideClasses = CatShowHideClassesEnum

  static MotorhomeTypes = MotorhomeTypesEnum

  static PetSubCoverType = PetSubCoverTypeEnum

  static PetCoverLevel = PetCoverLevelEnum

  static PolicyholderType = PolicyholderTypeEnum

  static TypeOfVehicle = TypeOfVehicleEnum

  static PolicyClass = PolicyClass

  static CurrentInsurer = CurrentInsurer

  static PreviousInsurerName = PreviousInsurerName

  static IsVehicleCarOrVan = IsVehicleCarOrVan

  static ModernCarVehicleType = ModernCarVehicleType

  static EuropeanCoverDays = EuropeanCoverDays

  static PolicyDuration = PolicyDuration

  static RelatedRadioListEnum = RelatedRadioListEnum

  static StaticCaravanSited = StaticCaravanSited

  static RelationshipToPolicyHolder = RelationshipToPolicyHolder

  static PropertyInsuranceOwnerhip = PropertyInsuranceOwnership

  static PropertyInsuranceTypeOfPolicy = PropertyInsuranceTypeOfPolicy

  static PropertyInsuranceWhoLivesAtProperty = PropertyInsuranceWhoLivesAtProperty

  static PropertyInsuranceTypeOfAlarm = PropertyInsuranceTypeOfAlarm
}
