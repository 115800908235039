import { JsonObject, JsonProperty } from 'json2typescript'
import { DefinedListDetail } from './DefinedList'
import Model from './Model'
import VeriskDetail from './VeriskDetail'
import DateConverter from '@/converters/DateConverter'

@JsonObject('PetRiskPet')
export default class PetRiskPet extends Model {
  @JsonProperty('petType', DefinedListDetail)
  petType: DefinedListDetail

  @JsonProperty('name', String)
  name: string

  @JsonProperty('gender', DefinedListDetail)
  gender: DefinedListDetail

  @JsonProperty('dateOfBirth', DateConverter)
  dateOfBirth: Date

  @JsonProperty('petSubType', DefinedListDetail)
  petSubType: DefinedListDetail

  @JsonProperty('breed', DefinedListDetail)
  breed: DefinedListDetail

  @JsonProperty('size', DefinedListDetail)
  size: DefinedListDetail

  @JsonProperty('costOfPet', Number)
  costOfPet: number

  @JsonProperty('hasBeenNeutered', Boolean)
  hasBeenNeutered: boolean

  @JsonProperty('hasBeenChipped', Boolean)
  hasBeenChipped: boolean

  @JsonProperty('hasUpToDateVaccinations', Boolean)
  hasUpToDateVaccinations: boolean

  @JsonProperty('proposerOwnsPet', Boolean)
  proposerOwnsPet: boolean

  @JsonProperty('hasAttacked', Boolean)
  hasAttacked: boolean

  @JsonProperty('everSeenVetForIllness', Boolean)
  everSeenVetForIllness: boolean

  @JsonProperty('coverPreExistingConditions', Boolean)
  coverPreExistingConditions: boolean

  @JsonProperty('isAwaitingReview', Boolean)
  isAwaitingReview: boolean | null

  @JsonProperty('veriskDetail', VeriskDetail)
  veriskDetail: VeriskDetail

  @JsonProperty('dominantBreed', DefinedListDetail)
  dominantBreed: DefinedListDetail

  @JsonProperty('dominantBreedSize', DefinedListDetail)
  dominantBreedSize: DefinedListDetail

  @JsonProperty('hasCausedLegalIncident', Boolean)
  hasCausedLegalIncident: boolean

  @JsonProperty('trackerUniqueId', String, true)
  trackerUniqueId: string | null = null

  @JsonProperty('prn', Number)
  prn: number | null

  @JsonProperty('isDeceased', Boolean)
  isDeceased: boolean | null

  @JsonProperty('dateRemoved', DateConverter)
  dateRemoved: Date | null

  constructor(
    id = 0,
    petType = new DefinedListDetail(),
    name = '',
    gender = new DefinedListDetail(),
    dateOfBirth = new Date(),
    petSubType = new DefinedListDetail(),
    breed = new DefinedListDetail(),
    size = new DefinedListDetail(),
    costOfPet = 0,
    hasBeenNeutered = true,
    hasBeenChipped = true,
    hasUpToDateVaccinations = true,
    proposerOwnsPet = true,
    hasAttacked = false,
    everSeenVetForIllness = true,
    coverPreExistingConditions = true,
    isAwaitingReview = true,
    veriskDetail = new VeriskDetail(),
    dominantBreed = new DefinedListDetail(),
    dominantBreedSize = new DefinedListDetail(),
    hasCausedLegalIncident = false,
    trackerUniqueId = '',
    prn = null,
    isDeceased = false,
    dateRemoved = new Date(),
  ) {
    super(id)
    this.petType = petType
    this.name = name
    this.gender = gender
    this.dateOfBirth = dateOfBirth
    this.petSubType = petSubType
    this.breed = breed
    this.size = size
    this.costOfPet = costOfPet
    this.hasBeenNeutered = hasBeenNeutered
    this.hasBeenChipped = hasBeenChipped
    this.hasUpToDateVaccinations = hasUpToDateVaccinations
    this.proposerOwnsPet = proposerOwnsPet
    this.hasAttacked = hasAttacked
    this.everSeenVetForIllness = everSeenVetForIllness
    this.coverPreExistingConditions = coverPreExistingConditions
    this.isAwaitingReview = isAwaitingReview
    this.veriskDetail = veriskDetail!
    this.dominantBreed = dominantBreed
    this.dominantBreedSize = dominantBreedSize
    this.hasCausedLegalIncident = hasCausedLegalIncident
    this.trackerUniqueId = trackerUniqueId
    this.prn = prn
    this.isDeceased = isDeceased
    this.dateRemoved = dateRemoved
  }
}
