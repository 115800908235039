import { JsonObject, JsonProperty } from 'json2typescript'
import DateConverter from '@/converters/DateConverter'

@JsonObject('ClaimValue')
export default class Claim {
  @JsonProperty('claimDate', DateConverter)
  claimDate: Date = new Date()

  @JsonProperty('incidentDate', DateConverter)
  incidentDate: Date | null = new Date()

  @JsonProperty('claimType', String)
  claimType = ''

  @JsonProperty('claimStatus', String)
  claimStatus = ''

  @JsonProperty('claimFault', Boolean)
  claimFault = false

  @JsonProperty('claimPersonsInjured', Boolean)
  claimPersonsInjured = false

  @JsonProperty('claimCost', Number)
  claimCost = 0

  @JsonProperty('ncbLost', Boolean)
  ncbLost = false
}
