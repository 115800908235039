import type _Vue from 'vue'

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: Array<string>

  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed'
    platform: string
  }>

  prompt: () => Promise<void>
}

export class PWAUtils {
  private installPrompt?: BeforeInstallPromptEvent

  constructor() {
    window.addEventListener('beforeinstallprompt', (e) => {
      this.installPrompt = e as BeforeInstallPromptEvent
    })
  }

  get canBrowserSupport(): boolean {
    return 'Notification' in window
  }

  get isSubscribed(): boolean {
    return this.canBrowserSupport && Notification.permission === 'granted'
  }

  get canInstall(): boolean {
    return !!this.installPrompt
  }

  get deferredPrompt(): BeforeInstallPromptEvent {
    if (!this.canInstall)
      throw new Error('PWA cannot be installed or is already installed.')
    return this.installPrompt!
  }

  static isMobile(): boolean {
    return /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  }
}

export default function PWAUtilsPlugin(Vue: typeof _Vue): void {
  Vue.prototype.$pwa = new PWAUtils()
}
