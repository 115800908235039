import { JsonObject, JsonProperty } from 'json2typescript'
import Address from '../models/Address'
import PersonalDetails from './PersonalDetails'

@JsonObject('PetProposerValue')
export default class PetProposer extends PersonalDetails {
  @JsonProperty('petCoverType', String)
  petCoverType: string | null = null

  @JsonProperty('petCoverSubType', String)
  petCoverSubType: string | null = null

  @JsonProperty('address', Address)
  address = new Address()

  @JsonProperty('ukResident', Boolean)
  ukResident: boolean | null = true

  @JsonProperty('petsKeptAtProposerAddress', Boolean)
  petsKeptAtProposerAddress = true

  @JsonProperty('liveInPremisesSellingAlcohol', Boolean)
  liveInPremisesSellingAlcohol: boolean | null = null

  @JsonProperty('anyLegalActionOrComplaints', Boolean)
  anyLegalActionOrComplaints = false

  @JsonProperty('usedForBreedingOrConnectedToBusiness', Boolean)
  usedForBreedingOrConnectedToBusiness: boolean | null = null
}
