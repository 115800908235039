import { JsonObject, JsonProperty } from 'json2typescript'

export enum UserRole {
  Customer,
  Administrator,
}

@JsonObject('Identity')
export default class Identity {
  @JsonProperty('username', String)
  username = ''

  @JsonProperty('role', Number)
  role: UserRole = UserRole.Administrator
}
