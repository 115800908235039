import { JsonObject, JsonProperty } from 'json2typescript'
import QuoteDetails from '../QuoteDetails'
import type { PropertySchemeQuoteResult } from '../SchemeQuoteResult'
import RiskProposer from '../RiskProposer'
import Property from './Property'
import PaymentTypeEnum from '@/services/enums/PaymentTypeEnum'
import PaymentTypeEnumConverter from '@/converters/PaymentTypeEnumConverter'

@JsonObject('PropertyQuoteDetails')
export default class PropertyQuoteDetails extends QuoteDetails<PropertySchemeQuoteResult> {
  @JsonProperty('riskProposer', RiskProposer)
    propertyProposer: RiskProposer = new RiskProposer()

  @JsonProperty('properties', [Property])
    properties: Property[] = []
}

@JsonObject('PropertyRenewalQuoteDetails')
export class PropertyRenewalQuoteDetails extends PropertyQuoteDetails {
  @JsonProperty('initialPaymentMethod', PaymentTypeEnumConverter)
  initialPaymentMethod: PaymentTypeEnum = PaymentTypeEnum.cardPayment

  @JsonProperty('previousPolicyScheme', String)
  previousPolicyScheme = ''
}

@JsonObject('PropertyMTAQuoteDetails')
export class PropertyMTAQuoteDetails extends PropertyQuoteDetails {
  @JsonProperty('initialPaymentMethod', PaymentTypeEnumConverter)
  initialPaymentMethod: PaymentTypeEnum = PaymentTypeEnum.cardPayment

  @JsonProperty('hasUpgradableVAP', Boolean)
  hasUpgradableVAP = true

  @JsonProperty('hasClaims', Boolean)
  hasClaims = false

  @JsonProperty('message', String)
  message = ''
}
