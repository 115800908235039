import { JsonObject, JsonProperty } from 'json2typescript'
import Model from './Model'

@JsonObject('PrepopulatableValues')
export default class Account extends Model {
  @JsonProperty('licenceNumber', String)
  licenceNumber: string

  @JsonProperty('employmentStatus', String)
  employmentStatus: string

  @JsonProperty('occupation', String)
  occupation: string

  @JsonProperty('businessCategory', String)
  businessCategory: string

  constructor(
    id = 0,
    licenceNumber = '',
    employmentStatus = '',
    occupation = '',
    businessCategory = '',
  ) {
    super(id)
    this.licenceNumber = licenceNumber
    this.employmentStatus = employmentStatus
    this.occupation = occupation
    this.businessCategory = businessCategory
  }
}
