import { JsonObject, JsonProperty } from 'json2typescript'
import QuoteDetails from '../QuoteDetails'
import type SchemeQuoteResult from '../SchemeQuoteResult'
import PaymentTypeEnum from '@/services/enums/PaymentTypeEnum'
import PaymentTypeEnumConverter from '@/converters/PaymentTypeEnumConverter'

@JsonObject('DynamicQuoteDetails')
export default class DynamicQuoteDetails extends QuoteDetails<SchemeQuoteResult> {
}

@JsonObject('DynamicRenewalQuoteDetails')
export class DynamicRenewalQuoteDetails extends DynamicQuoteDetails {
  @JsonProperty('initialPaymentMethod', PaymentTypeEnumConverter)
  initialPaymentMethod: PaymentTypeEnum = PaymentTypeEnum.cardPayment

  @JsonProperty('previousPolicyScheme', String)
  previousPolicyScheme = ''
}
