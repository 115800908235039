import moment from 'moment'
import type IRequestMapper from '../interfaces/IRequestMapper'
import SchemeRequest from '../values/SchemeRequest'
import type QuoteRequest from '../values/QuoteRequest'
import QuestionEnum from '../enums/QuestionEnum'
import type Page from '../../view-models/Page'
import type ProductPage from '../models/ProductPage'
import type IQuestionMapper from '../interfaces/IQuestionMapper'
import DLDCommonValuesEnum from '../enums/DLDCommonValuesEnum'
import type IResponseMapper from '../interfaces/IResponseMapper'
import UnkownQuestionSetError from '../error/UnknownQuestionSetError'
import type { CarRisk } from '../models/Risk'
import Vehicle from '../values/Vehicle'
import Car, { RegisteredOwnerKeeperDetails } from '../values/Car'
import AccountRegister from '../values/AccountRegister'
import { CarProposer } from '../values/Driver'
import type Account from '../models/Account'
import { Marketing } from '../models/Account'
import type IPageMappings from '../interfaces/IPageMappings'
import type { IQuestionAnswer, QuestionAnswer } from '../values/PageAnswers'
import type PageAnswers from '../values/PageAnswers'
import type Address from '../models/Address'
import CarQuoteRequest from '../values/CarQuoteRequest'
import BaseMapper from './BaseMapper'
import type { DateQuestion, DrivingLicenceQuestion, SelectQuestion } from '@/view-models/Question'
import ContextLevel from '@/services/enums/ContextLevel'
import type { Dictionary } from '@/types'
import Routes from '@/constants/Routes'

export default class ZoomcoverMapper extends BaseMapper implements IRequestMapper, IResponseMapper, IQuestionMapper, IPageMappings {
  nbPageMappings: Dictionary<{ route: Routes, contextLevel: ContextLevel }> = {
    ModernCar_Demands_CustomerFacing: { route: Routes.demands, contextLevel: ContextLevel.preQuote },
    ModernCar_Proposer_CustomerFacing: { route: Routes.scheme, contextLevel: ContextLevel.preQuote },
    ModernCar_Premium_CustomerFacing: { route: Routes.premium, contextLevel: ContextLevel.quote },
    ModernCar_Payment: { route: Routes.payment, contextLevel: ContextLevel.quote },
    ModernCar_Declarations: { route: Routes.declarations, contextLevel: ContextLevel.quote },
    ModernCar_PaymentConfirmation: { route: Routes.paymentConfirm, contextLevel: ContextLevel.policy },
  }

  mtaPageMappings: Dictionary<{ route: Routes, contextLevel: ContextLevel }> = {
    ModernCar_ProposerMta_CustomerFacing: { route: Routes.mtaScheme, contextLevel: ContextLevel.mtaPreQuote },
    ModernCar_PremiumMta: { route: Routes.mtaPremium, contextLevel: ContextLevel.mtaQuote },
    ModernCar_Payment: { route: Routes.mtaPayment, contextLevel: ContextLevel.mtaQuote },
    ModernCar_PaymentConfirmation: { route: Routes.mtaConfirm, contextLevel: ContextLevel.mtaTakenUp },
  }

  renewalPageMappings: Dictionary<{ route: Routes, contextLevel: ContextLevel }> = {
    ModernCar_PremiumRenewal: { route: Routes.renewalPremium, contextLevel: ContextLevel.renewalQuote },
  }

  setupPage(setId: string, productPage: ProductPage, answers: IQuestionAnswer[]): Page {
    const mappedPage = this.mapProductPageToPage(productPage)
    return this.setupDependencies(setId, mappedPage, answers)
  }

  setupDependencies(setId: string, mappedPage: Page, answers: IQuestionAnswer[]): Page {
    switch (setId) {
      case 'ModernCar_Demands_CustomerFacing':
        return this.setupDemandsDependencies(mappedPage)
      case 'ModernCar_Proposer_CustomerFacing':
        return this.setupSchemeDependencies(mappedPage)
      case 'ModernCar_Premium_CustomerFacing':
        return this.setupPremiumDependencies(mappedPage, answers)
      case 'ModernCar_Declarations':
        return this.setupDeclarationsDependencies(mappedPage, answers)
      case 'ModernCar_Payment':
      case 'ModernCar_PaymentConfirmation':
        return mappedPage
      default:
        throw new UnkownQuestionSetError(setId)
    }
  }

  private setupDemandsDependencies(page: Page): Page {
    const questions = page.sections.flatMap(s => s.questions)

    const fullUkLicenceQuestion = questions.find(q => q.name === QuestionEnum.licenceType) as SelectQuestion<string, string>
    const isRegisteredKeeperQuestion = questions.find(q => q.name === QuestionEnum.isRegisteredKeeper) as SelectQuestion<string, string>
    const isVehicleCarOrVanQuestion = questions.find(q => q.name === QuestionEnum.isVehicleCarOrVan) as SelectQuestion<string, string>
    const isInsuredAnnuallyQuestion = questions.find(q => q.name === QuestionEnum.isInsuredAnnually) as SelectQuestion<string, string>

    const businessUseOnlyQuestion = questions.find(q => q.name === QuestionEnum.isBusinessUseOnly) as SelectQuestion<string, string>
    const isVehicleImpoundedQuestion = questions.find(q => q.name === QuestionEnum.isImpounded) as SelectQuestion<string, string>
    const drivingOtherCarsQuestion = questions.find(q => q.name === QuestionEnum.drivingOtherCarsOnOtherPolicy) as SelectQuestion<string, string>

    businessUseOnlyQuestion.isVisible = (): boolean => fullUkLicenceQuestion.value === DLDCommonValuesEnum.CarLicenceType.FullUK && isRegisteredKeeperQuestion.value === DLDCommonValuesEnum.Boolean.Yes && isInsuredAnnuallyQuestion.value === DLDCommonValuesEnum.Boolean.Yes && isVehicleCarOrVanQuestion.value === DLDCommonValuesEnum.IsVehicleCarOrVan.Car
    isVehicleImpoundedQuestion.isVisible = (): boolean => fullUkLicenceQuestion.value === DLDCommonValuesEnum.CarLicenceType.FullUK && isRegisteredKeeperQuestion.value === DLDCommonValuesEnum.Boolean.Yes && isInsuredAnnuallyQuestion.value === DLDCommonValuesEnum.Boolean.No && isVehicleCarOrVanQuestion.value === DLDCommonValuesEnum.IsVehicleCarOrVan.Car
    drivingOtherCarsQuestion.isVisible = (): boolean => fullUkLicenceQuestion.value === DLDCommonValuesEnum.CarLicenceType.FullUK && isRegisteredKeeperQuestion.value === DLDCommonValuesEnum.Boolean.No && isInsuredAnnuallyQuestion.value === DLDCommonValuesEnum.Boolean.Yes && isVehicleCarOrVanQuestion.value === DLDCommonValuesEnum.IsVehicleCarOrVan.Car

    return page
  }

  private setupSchemeDependencies(page: Page) {
    this.setupCommonDependencies(page)

    const questions = page.sections.flatMap(s => s.questions)

    const titleQuestion = questions.find(q => q.name === QuestionEnum.title)
    const firstNameQuestion = questions.find(q => q.name === QuestionEnum.firstname)
    const lastNameQuestion = questions.find(q => q.name === QuestionEnum.lastName)
    const dateOfBirthQuestion = questions.find(q => q.name === QuestionEnum.dateOfBirth) as DateQuestion
    const drivingLicenceQuestion = questions.find(q => q.name === QuestionEnum.drivingLicence) as DrivingLicenceQuestion
    drivingLicenceQuestion.dependentQuestions = [titleQuestion!, firstNameQuestion!, lastNameQuestion!, dateOfBirthQuestion!]

    return page
  }

  private setupPremiumDependencies(page: Page, answers: IQuestionAnswer[]) {
    const isRegisteredKeeper = answers.find(q => q.name === QuestionEnum.isRegisteredKeeper) as QuestionAnswer<string>

    if (isRegisteredKeeper?.value === DLDCommonValuesEnum.Boolean.Yes) {
      const registeredKeeperSection = page.sections.find(x => x.name === 'Premium Registered Keeper')

      registeredKeeperSection?.questions.forEach(q => q.isVisible = () => isRegisteredKeeper.value === DLDCommonValuesEnum.Boolean.No)
    }

    const questions = page.sections.flatMap(s => s.questions)
    const effectiveDateQuestion = questions.find(q => q.name === QuestionEnum.effectiveDate) as DateQuestion

    effectiveDateQuestion.minDate = () => moment().add(20, 'minute').toDate()
    effectiveDateQuestion.maxDate = () => moment().add(31, 'days').toDate()

    return page
  }

  private setupDeclarationsDependencies(page: Page, answers: IQuestionAnswer[]) {
    const questions = page.sections.flatMap(s => s.questions)
    const vehicleLookup = answers.find(a => a.name === QuestionEnum.vehicleLookup) as QuestionAnswer<Vehicle>
    let registration = 'the insured vehicle'
    if (vehicleLookup && vehicleLookup.value?.registration)
      registration = vehicleLookup.value.registration

    const registeredKeeperQuestion = questions.find(q => q.name === QuestionEnum.isRegisteredKeeperOrHasPermission || q.name === QuestionEnum.isRegisteredKeeperDeclaration || q.name === QuestionEnum.hasRegisteredKeepersPermission)
    if (registeredKeeperQuestion)
      registeredKeeperQuestion.label = registeredKeeperQuestion.label.replace('{0}', registration)

    return page
  }

  mapRiskToAnswers(risk: CarRisk, account: Account, pages: Page[]): Dictionary<PageAnswers> {
    const pageAnswers: Dictionary<PageAnswers> = {}

    this.mapAccountToAnswers(pageAnswers, account, pages)

    const vehicle = new Vehicle()
    vehicle.registration = risk.vehicle.registrationNumber
    vehicle.abiCode = risk.vehicle.car.abiCode
    vehicle.make = risk.vehicle.car.makeDescription
    vehicle.model = risk.vehicle.car.modelDescription
    vehicle.value = risk.vehicle.value
    vehicle.purchaseDate = risk.vehicle.purchaseDate
    vehicle.yearOfManufacture = risk.vehicle.yearOfManufacture
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleLookup, vehicle, pages)

    this.createAnswerForQuestion(pageAnswers, QuestionEnum.title, risk.proposer.title.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.firstname, risk.proposer.firstName, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.lastName, risk.proposer.lastName, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.dateOfBirth, risk.proposer.dateOfBirth, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.address, risk.proposer.address, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.drivingLicence, risk.proposer.drivingLicence, pages)

    this.createAnswerForQuestion(pageAnswers, QuestionEnum.policyDurationZLOCI, risk.policyDuration.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.policyDurationZBUOI, risk.policyDuration.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.policyDurationZTI, risk.policyDuration.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.policyDurationZIVI, risk.policyDuration.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.policyDurationZTUC, risk.policyDuration.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.policyDurationZCSI, risk.policyDuration.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.policyDurationZLBCI, risk.policyDuration.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.policyDurationZVI, risk.policyDuration.uniqueId, pages)

    this.createAnswerForQuestion(pageAnswers, QuestionEnum.licenceType, risk.proposer.licenceType.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.isRegisteredKeeper, risk.vehicle.registeredKeeper.uniqueId === DLDCommonValuesEnum.RegisteredKeeper.ProposerPolicyHolder ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.isInsuredAnnually, risk.isInsuredAnnually ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.isBusinessUseOnly, risk.classOfUse.uniqueId === DLDCommonValuesEnum.ClassOfUse.BusinessClass1 ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.isImpounded, risk.isImpounded ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.drivingOtherCarsOnOtherPolicy, risk.drivingOtherCarsOnOtherPolicy ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.isVehicleCarOrVan, risk.vehicle.vehicleType?.uniqueId === DLDCommonValuesEnum.ModernCarVehicleType.Van ? DLDCommonValuesEnum.IsVehicleCarOrVan.Van : DLDCommonValuesEnum.IsVehicleCarOrVan.Car, pages)

    return pageAnswers
  }

  getSchemeRequest(questions: IQuestionAnswer[]): SchemeRequest {
    const licenceType = questions.find(q => q.name === QuestionEnum.licenceType) as QuestionAnswer<string>
    const isRegisteredKeeper = questions.find(q => q.name === QuestionEnum.isRegisteredKeeper) as QuestionAnswer<string>
    const isInsuredAnnually = questions.find(q => q.name === QuestionEnum.isInsuredAnnually) as QuestionAnswer<string>
    const isBusinessUseOnly = questions.find(q => q.name === QuestionEnum.isBusinessUseOnly) as QuestionAnswer<string>
    const isImpounded = questions.find(q => q.name === QuestionEnum.isImpounded) as QuestionAnswer<string>
    const drivingOtherCarsOnOtherPolicy = questions.find(q => q.name === QuestionEnum.drivingOtherCarsOnOtherPolicy) as QuestionAnswer<string>
    const isVehicleCarOrVan = questions.find(q => q.name === QuestionEnum.isVehicleCarOrVan) as QuestionAnswer<string>

    const result = new SchemeRequest()
    result.hasFullUKLicence = licenceType.value!
    result.isRegisteredKeeper = isRegisteredKeeper.value!
    result.isInsuredAnnually = isInsuredAnnually.value!
    result.isBusinessUseOnly = isBusinessUseOnly.value!
    result.isImpounded = isImpounded.value!
    result.drivingOtherCarsOnOtherPolicy = drivingOtherCarsOnOtherPolicy.value!
    result.isVehicleVan = isVehicleCarOrVan.value! === DLDCommonValuesEnum.IsVehicleCarOrVan.Van

    return result
  }

  validate(_questions: IQuestionAnswer[]): boolean {
    throw new Error('Method not implemented. Wrapper does not use custom validation.')
  }

  getQuoteRequest<T extends QuoteRequest>(questions: IQuestionAnswer[], Type: new() => T): T {
    const vehicleLookup = questions.find(q => q.name === QuestionEnum.vehicleLookup) as QuestionAnswer<Vehicle>
    const title = questions.find(q => q.name === QuestionEnum.title) as QuestionAnswer<string>
    const firstName = questions.find(q => q.name === QuestionEnum.firstname) as QuestionAnswer<string>
    const lastName = questions.find(q => q.name === QuestionEnum.lastName) as QuestionAnswer<string>
    const dateOfBirth = questions.find(q => q.name === QuestionEnum.dateOfBirth) as QuestionAnswer<Date>
    const address = questions.find(q => q.name === QuestionEnum.address) as QuestionAnswer<Address>
    const drivingLicence = questions.find(q => q.name === QuestionEnum.drivingLicence) as QuestionAnswer<string>

    const policyDuration = questions.find(q => q.name === QuestionEnum.policyDurationZLOCI || q.name === QuestionEnum.policyDurationZBUOI || q.name === QuestionEnum.policyDurationZTI || q.name === QuestionEnum.policyDurationZIVI || q.name === QuestionEnum.policyDurationZTUC || q.name === QuestionEnum.policyDurationZCSI || q.name === QuestionEnum.policyDurationZLBCI || q.name === QuestionEnum.policyDurationZVI) as QuestionAnswer<string>
    const isRegisteredKeeper = questions.find(q => q.name === QuestionEnum.isRegisteredKeeper) as QuestionAnswer<string>
    const licenceType = questions.find(q => q.name === QuestionEnum.licenceType) as QuestionAnswer<string>
    const isInsuredAnnually = questions.find(q => q.name === QuestionEnum.isInsuredAnnually) as QuestionAnswer<string>
    const isBusinessUseOnly = questions.find(q => q.name === QuestionEnum.isBusinessUseOnly) as QuestionAnswer<string>
    const isImpounded = questions.find(q => q.name === QuestionEnum.isImpounded) as QuestionAnswer<string>
    const drivingOtherCarsOnOtherPolicy = questions.find(q => q.name === QuestionEnum.drivingOtherCarsOnOtherPolicy) as QuestionAnswer<string>
    const effectiveDate = questions.find(q => q.name === QuestionEnum.effectiveDate) as QuestionAnswer<Date>
    const registeredKeeperFirstName = questions.find(q => q.name === QuestionEnum.registeredKeeperFirstName) as QuestionAnswer<string>
    const registeredKeeperLastName = questions.find(q => q.name === QuestionEnum.registeredKeeperLastName) as QuestionAnswer<string>
    const registeredKeeperAddress = questions.find(q => q.name === QuestionEnum.registeredKeeperAddress) as QuestionAnswer<Address>
    const registeredKeeperTelephoneNumber = questions.find(q => q.name === QuestionEnum.registeredKeeperTelephoneNumber) as QuestionAnswer<string>
    const noClaimsProtection = questions.find(q => q.name === QuestionEnum.noClaimsProtection) as QuestionAnswer<string>
    const isVehicleCarOrVan = questions.find(q => q.name === QuestionEnum.isVehicleCarOrVan) as QuestionAnswer<string>

    let registeredKeeperDetails = null
    if (registeredKeeperFirstName?.value && registeredKeeperLastName?.value && registeredKeeperAddress?.value && registeredKeeperTelephoneNumber?.value) {
      registeredKeeperDetails = new RegisteredOwnerKeeperDetails()
      registeredKeeperDetails.firstName = registeredKeeperFirstName.value!
      registeredKeeperDetails.lastName = registeredKeeperLastName.value!
      registeredKeeperDetails.contactTelephone = registeredKeeperTelephoneNumber.value!
      registeredKeeperDetails.address = registeredKeeperAddress.value!
    }

    const quoteRequest = new Type()

    if (!(quoteRequest instanceof CarQuoteRequest))
      throw new Error(`Unsupported quote request (${typeof quoteRequest}) for Zoomcover.`)

    quoteRequest.car = new Car()
    quoteRequest.car.abiCode = vehicleLookup.value!.abiCode
    quoteRequest.car.registration = vehicleLookup.value!.registration
    // Prevents vehicle value from invalidating but will bounce on the schemes and show the decline message
    quoteRequest.car.value = vehicleLookup.value!.value < 1 ? 1 : vehicleLookup.value!.value
    quoteRequest.car.registeredKeeper = isRegisteredKeeper.value! === DLDCommonValuesEnum.Boolean.Yes ? DLDCommonValuesEnum.RegisteredKeeper.ProposerPolicyHolder : DLDCommonValuesEnum.RegisteredKeeper.Other
    quoteRequest.car.registeredOwner = isRegisteredKeeper.value! === DLDCommonValuesEnum.Boolean.Yes ? DLDCommonValuesEnum.RegisteredKeeper.ProposerPolicyHolder : DLDCommonValuesEnum.RegisteredKeeper.Other
    quoteRequest.car.purchaseDate = vehicleLookup.value!.purchaseDate
    quoteRequest.car.yearOfManufacture = vehicleLookup.value!.yearOfManufacture
    quoteRequest.car.registeredKeeperDetails = registeredKeeperDetails
    quoteRequest.car.vehicleType = isVehicleCarOrVan.value! === DLDCommonValuesEnum.IsVehicleCarOrVan.Van ? DLDCommonValuesEnum.ModernCarVehicleType.Van : null

    quoteRequest.mileage = 1

    quoteRequest.proposer = new CarProposer()
    quoteRequest.proposer.title = title.value!
    quoteRequest.proposer.firstName = firstName.value!
    quoteRequest.proposer.lastName = lastName.value!
    quoteRequest.proposer.dateOfBirth = dateOfBirth.value!
    quoteRequest.proposer.drivingLicence = drivingLicence!.value!
    quoteRequest.proposer.licenceType = licenceType.value!
    quoteRequest.proposer.address = address!.value!

    quoteRequest.address = address.value!

    quoteRequest.additionalDrivers = []
    quoteRequest.coverType = policyDuration.value!
    quoteRequest.classOfUse = isBusinessUseOnly.value! === DLDCommonValuesEnum.Boolean.Yes ? DLDCommonValuesEnum.ClassOfUse.BusinessClass1 : DLDCommonValuesEnum.ClassOfUse.SDPCommuting
    quoteRequest.effectiveDate = effectiveDate && effectiveDate.value ? effectiveDate.value! : moment().toDate()
    quoteRequest.policyDuration = policyDuration.value!
    quoteRequest.isInsuredAnnually = isInsuredAnnually.value!
    quoteRequest.isImpounded = isImpounded.value!
    quoteRequest.drivingOtherCarsOnOtherPolicy = drivingOtherCarsOnOtherPolicy.value!
    quoteRequest.noClaimsProtection = noClaimsProtection && noClaimsProtection.value!
    quoteRequest.businessSourceId = null
    quoteRequest.voluntaryExcess = DLDCommonValuesEnum.VolXs.VolXs0

    return quoteRequest
  }

  getMTARequest<T extends QuoteRequest>(_questions: IQuestionAnswer[], _Type: new() => T): T {
    throw new Error('Method not implemented. Zoomcover does not use MTAs.')
  }

  getAccountRegisterRequest(questions: IQuestionAnswer[]): AccountRegister | null {
    const title = questions.find(q => q.name === QuestionEnum.title) as QuestionAnswer<string>
    const firstName = questions.find(q => q.name === QuestionEnum.firstname) as QuestionAnswer<string>
    const lastName = questions.find(q => q.name === QuestionEnum.lastName) as QuestionAnswer<string>
    const email = questions.find(q => q.name === QuestionEnum.customerEmail) as QuestionAnswer<string>
    const dob = questions.find(q => q.name === QuestionEnum.dateOfBirth) as QuestionAnswer<Date>
    const marketing = questions.find(q => q.name === QuestionEnum.marketing) as QuestionAnswer<string[]>
    const address = questions.find(q => q.name === QuestionEnum.address) as QuestionAnswer<Address>

    if (!title?.value || !firstName?.value || !lastName?.value || !email?.value)
      return null

    const request = new AccountRegister()

    request.title = title.value
    request.firstName = firstName.value
    request.lastName = lastName.value
    request.email = email.value
    request.dateOfBirth = dob.value!
    request.address = address.value!

    request.marketing = new Marketing()
    request.marketing.isEmailOptIn = marketing?.value?.indexOf(DLDCommonValuesEnum.MarketingOption.Email) !== -1
    request.marketing.isTelephoneOptIn = marketing?.value?.indexOf(DLDCommonValuesEnum.MarketingOption.Telephone) !== -1
    request.marketing.isSMSOptIn = marketing?.value?.indexOf(DLDCommonValuesEnum.MarketingOption.SMS) !== -1
    request.marketing.isPostOptIn = marketing?.value?.indexOf(DLDCommonValuesEnum.MarketingOption.Post) !== -1

    return request
  }

  updateAccountFromQuestions(account: Account, questions: IQuestionAnswer[]): Account {
    const firstName = questions.find(q => q.name === QuestionEnum.firstname) as QuestionAnswer<string>
    const lastName = questions.find(q => q.name === QuestionEnum.lastName) as QuestionAnswer<string>
    const email = questions.find(q => q.name === QuestionEnum.customerEmail) as QuestionAnswer<string>
    const phoneNumber = questions.find(q => q.name === QuestionEnum.mobilePhoneNumber || q.name === QuestionEnum.customerHomePhoneNumber) as QuestionAnswer<string>
    const marketing = questions.find(q => q.name === QuestionEnum.marketing) as QuestionAnswer<string[]>
    const address = questions.find(q => q.name === QuestionEnum.address) as QuestionAnswer<Address>

    account.firstName = firstName?.value ?? account.firstName
    account.lastName = lastName?.value ?? account.lastName
    account.email = email?.value ?? account.email
    account.homePhone = phoneNumber?.value ?? account.homePhone
    account.mobilePhone = phoneNumber?.value ?? account.mobilePhone
    account.telephone = phoneNumber?.value ?? account.telephone
    account.workPhone = phoneNumber?.value ?? account.workPhone

    if (marketing?.value) {
      account.marketing.isEmailOptIn = marketing.value!.includes(DLDCommonValuesEnum.MarketingOption.Email)
      account.marketing.isSMSOptIn = marketing.value!.includes(DLDCommonValuesEnum.MarketingOption.SMS)
      account.marketing.isPostOptIn = marketing.value!.includes(DLDCommonValuesEnum.MarketingOption.Post)
      account.marketing.isTelephoneOptIn = marketing.value!.includes(DLDCommonValuesEnum.MarketingOption.Telephone)
    }

    account.address = address?.value ?? account.address

    return account
  }
}
