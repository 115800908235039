import { JsonObject, JsonProperty } from 'json2typescript'

@JsonObject('VeriskConfig')
export default class VeriskConfig {
  @JsonProperty('VeriskEnabled', Boolean)
  veriskEnabled: boolean

  @JsonProperty('RootUrl', String)
  rootUrl: string

  @JsonProperty('ApiVersion', String)
  apiVersion: string

  @JsonProperty('UserName', String)
  userName: string

  @JsonProperty('GrantType', String)
  grantType: string

  @JsonProperty('Token', String)
  token: string

  constructor(
    veriskEnabled = false,
    rootUrl = '',
    apiVersion = '',
    userName = '',
    grantType = '',
    token = '',
  ) {
    this.veriskEnabled = veriskEnabled
    this.rootUrl = rootUrl
    this.apiVersion = apiVersion
    this.userName = userName
    this.grantType = grantType
    this.token = token
  }
}
