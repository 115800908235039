import { JsonObject, JsonProperty } from 'json2typescript'
import PolicyStatusEnum from '../enums/PolicyStatusEnum'
import type AvailableActionsEnum from '../enums/AvalableActionsEnum'
import ProductEnum from '../enums/ProductEnum'
import DirectDebit from '../values/DirectDebit'
import PaymentSchedule from '../values/PaymentSchedule'
import PaymentTypeEnumConverter from '../../converters/PaymentTypeEnumConverter'
import type PaymentTypeEnum from '../enums/PaymentTypeEnum'
import RenewalStatusEnum from '../enums/RenewalStatusEnum'
import ActionMessage from './ActionMessage'
import VAP from './VAP'
import Scheme from './Scheme'
import Model from './Model'
import ProductEnumConverter from '@/converters/ProductEnumConverter'
import AvalableActionsEnumArrayConverter from '@/converters/AvailableActionsEnumArrayConverter'
import PolicyStatusEnumConverter from '@/converters/PolicyStatusEnumConverter'
import DateConverter from '@/converters/DateConverter'

@JsonObject('Policy')
export default class Policy extends Model {
  @JsonProperty('policyNumber', String)
  policyNumber: string

  @JsonProperty('product', ProductEnumConverter)
  product: ProductEnum

  @JsonProperty('policyStatus', PolicyStatusEnumConverter)
  policyStatus: PolicyStatusEnum | null

  @JsonProperty('renewalStatus', PolicyStatusEnumConverter)
  renewalStatus: RenewalStatusEnum

  @JsonProperty('effectiveDate', DateConverter)
  effectiveDate: Date

  @JsonProperty('renewalDate', DateConverter)
  renewalDate: Date

  @JsonProperty('renewalQuoteReference', String)
  renewalQuoteReference: string | null

  @JsonProperty('willAutoRenew', Boolean)
  willAutoRenew: boolean

  @JsonProperty('vaps', [VAP])
  vaps: VAP[]

  @JsonProperty('isRefundedPendingChange', Boolean)
  isRefundedPendingChange: boolean

  @JsonProperty('paymentType', PaymentTypeEnumConverter)
  paymentType: PaymentTypeEnum

  @JsonProperty('isPendingAmendment', Boolean)
  isPendingAmendment: boolean

  @JsonProperty('pendingRefundAmount', Number)
  pendingRefundAmount: number

  @JsonProperty('scheme', Scheme)
  scheme: Scheme

  @JsonProperty('availableActions', AvalableActionsEnumArrayConverter)
  availableActions: AvailableActionsEnum[]

  @JsonProperty('actionMessages', [ActionMessage])
  actionMessages: ActionMessage[]

  @JsonProperty('directDebitDetails', DirectDebit)
  directDebitDetails: DirectDebit | null

  @JsonProperty('paymentSchedule', PaymentSchedule)
  paymentSchedule: PaymentSchedule | null

  constructor(
    id = 0,
    policyNumber = '',
    product: ProductEnum = ProductEnum.modernCar,
    policyStatus: PolicyStatusEnum = PolicyStatusEnum.live,
    renewalStatus: RenewalStatusEnum = RenewalStatusEnum.notSet,
    effectiveDate: Date = new Date(),
    renewalDate: Date = new Date(),
    willAutoRenew = false,
    vaps: VAP[] = [],
    isRefundedPendingChange = false,
    paymentType: PaymentTypeEnum,
    isPendingAmendment = false,
    pendingRefundAmount = 0,
    scheme: Scheme = new Scheme(),
    availableActions: AvailableActionsEnum[] = [],
    actionMessages: ActionMessage[] = [],
    renewalQuoteReference = null,
    directDebitDetails: DirectDebit | null = null,
    paymentSchedule: PaymentSchedule | null = null,
  ) {
    super(id)
    this.policyNumber = policyNumber
    this.product = product
    this.policyStatus = policyStatus
    this.renewalStatus = renewalStatus
    this.effectiveDate = effectiveDate
    this.renewalDate = renewalDate
    this.willAutoRenew = willAutoRenew
    this.vaps = vaps
    this.isRefundedPendingChange = isRefundedPendingChange
    this.paymentType = paymentType
    this.isPendingAmendment = isPendingAmendment
    this.pendingRefundAmount = pendingRefundAmount
    this.scheme = scheme
    this.availableActions = availableActions
    this.actionMessages = actionMessages
    this.renewalQuoteReference = renewalQuoteReference
    this.directDebitDetails = directDebitDetails
    this.paymentSchedule = paymentSchedule
  }
}
