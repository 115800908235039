import { JsonObject, JsonProperty } from 'json2typescript'
import PaymentTypeEnumConverter from '../../converters/PaymentTypeEnumConverter'
import PaymentTypeEnum from '../enums/PaymentTypeEnum'
import { DefinedListDetail } from './DefinedList'
import QuoteDetails from './QuoteDetails'
import type SchemeQuoteResult from './SchemeQuoteResult'

@JsonObject('CaravanQuoteDetails')
export default class CaravanQuoteDetails extends QuoteDetails<SchemeQuoteResult> {
  @JsonProperty('voluntaryExcess', DefinedListDetail)
  voluntaryExcess: DefinedListDetail = new DefinedListDetail()
}

@JsonObject('CaravanMTAQuoteDetails')
export class CaravanMTAQuoteDetails extends CaravanQuoteDetails {
  @JsonProperty('initialPaymentMethod', PaymentTypeEnumConverter)
  initialPaymentMethod: PaymentTypeEnum = PaymentTypeEnum.cardPayment

  @JsonProperty('hasUpgradableVAP', Boolean)
  hasUpgradableVAP = false
}

@JsonObject('CaravanRenewalQuoteDetails')
export class CaravanRenewalQuoteDetails extends CaravanQuoteDetails {
  @JsonProperty('initialPaymentMethod', PaymentTypeEnumConverter)
  initialPaymentMethod: PaymentTypeEnum = PaymentTypeEnum.cardPayment

  @JsonProperty('previousPolicyScheme', String)
  previousPolicyScheme = ''
}
