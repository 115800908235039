import { defineStore } from 'pinia'

interface State {
  consented: boolean
}

const useCookieStore = defineStore('cookie', {
  state: (): State => ({
    consented: false,
  }),
  actions: {
    consent(): void {
      this.consented = true
    },
  },
})

export default useCookieStore
