import type { JsonConvert } from 'json2typescript'
import type { AxiosInstance } from 'axios'
import type IQuoteService from './interfaces/IQuoteService'
import { QuotesCollectionResponse } from './values/CollectionResponse'
import { CarMTAQuoteDetails, CarQuoteDetails, CarRenewalQuoteDetails } from './models/CarQuoteDetails'
import PetQuoteDetails, { PetMTAQuoteDetails, PetRenewalQuoteDetails } from './models/PetQuoteDetails'
import type QuoteDetails from './models/QuoteDetails'
import type SchemeQuoteResult from './models/SchemeQuoteResult'
import ProductEnum from './enums/ProductEnum'
import GapQuoteDetails, { GapMTAQuoteDetails } from './models/GapQuoteDetails'
import TouringCaravanQuoteDetails, { TouringCaravanMTAQuoteDetails, TouringCaravanRenewalQuoteDetails } from './models/TouringCaravanQuoteDetails'
import CaravanQuoteDetails, { CaravanMTAQuoteDetails, CaravanRenewalQuoteDetails } from './models/CaravanQuoteDetails'
import VehicleQuoteDetails, { VehicleMTAQuoteDetails, VehicleRenewalQuoteDetails } from './models/Vehicle/VehicleQuoteDetails'
import DynamicQuoteDetails from './models/Dynamic/DynamicQuoteDetails'
import PropertyQuoteDetails, { PropertyMTAQuoteDetails } from './models/Property/PropertyQuoteDetails'

export default class QuoteService implements IQuoteService {
  axios: AxiosInstance

  apiUrl: URL

  jsonConvert: JsonConvert

  constructor(axios: AxiosInstance, apiUrl: URL, jsonConvert: JsonConvert) {
    this.axios = axios
    this.apiUrl = apiUrl
    this.jsonConvert = jsonConvert
  }

  async getMTAQuote(reference: string, product: string): Promise<CarMTAQuoteDetails | GapMTAQuoteDetails | TouringCaravanMTAQuoteDetails | CaravanMTAQuoteDetails | VehicleMTAQuoteDetails | PetMTAQuoteDetails | PropertyMTAQuoteDetails> {
    switch (product) {
      case ProductEnum.motorCaravan:
      case ProductEnum.modernCar:
      case ProductEnum.taxi:
      case ProductEnum.courier:
      case ProductEnum.commercialVehicle:
        return this.getCarMTAQuote(reference)
      case ProductEnum.gapInsurance:
        return this.getGapMTAQuote(reference)
      case ProductEnum.touringCaravan:
        return this.getTouringCaravanMTAQuote(reference)
      case ProductEnum.staticCaravanResidential:
        return this.getCaravanMTAQuote(reference)
      case ProductEnum.caravan:
        return this.getCaravanMTAQuote(reference)
      case ProductEnum.vehicle:
        return this.getVehicleMTAQuote(reference)
      case ProductEnum.multiPet:
        return this.getPetMTAQuote(reference)
      case ProductEnum.home:
        return this.getPropertyMTAQuote(reference)
      default:
        throw new Error(`Unknown product (${product}).`)
    }
  }

  async getCarQuote(reference: string): Promise<CarQuoteDetails> {
    const result = await this.axios.get(`${this.apiUrl}/quote/${encodeURIComponent(reference)}`)
    return this.jsonConvert.deserializeObject(result.data, CarQuoteDetails)
  }

  async getCarMTAQuote(reference: string): Promise<CarMTAQuoteDetails> {
    const result = await this.axios.get(`${this.apiUrl}/quote/mta/${encodeURIComponent(reference)}`)
    return this.jsonConvert.deserializeObject(result.data, CarMTAQuoteDetails)
  }

  async getCarRenewalQuote(policyNumber: string, quoteReference: string | null): Promise<CarRenewalQuoteDetails> {
    const result = await this.axios.get(`${this.apiUrl}/quote/renewal/${policyNumber}${quoteReference ? `/${quoteReference}` : ''}`)
    return this.jsonConvert.deserializeObject(result.data, CarRenewalQuoteDetails)
  }

  async getGapQuote(reference: string): Promise<GapQuoteDetails> {
    const result = await this.axios.get(`${this.apiUrl}/quote/${encodeURIComponent(reference)}`)
    return this.jsonConvert.deserializeObject(result.data, GapQuoteDetails)
  }

  async getTouringCaravanQuote(reference: string): Promise<TouringCaravanQuoteDetails> {
    const result = await this.axios.get(`${this.apiUrl}/quote/${encodeURIComponent(reference)}`)
    return this.jsonConvert.deserializeObject(result.data, TouringCaravanQuoteDetails)
  }

  async getTouringCaravanMTAQuote(reference: string): Promise<TouringCaravanMTAQuoteDetails> {
    const result = await this.axios.get(`${this.apiUrl}/quote/mta/${encodeURIComponent(reference)}`)
    return this.jsonConvert.deserializeObject(result.data, TouringCaravanMTAQuoteDetails)
  }

  async getTouringMTACaravanQuote(reference: string): Promise<TouringCaravanMTAQuoteDetails> {
    const result = await this.axios.get(`${this.apiUrl}/quote/mta/${encodeURIComponent(reference)}`)
    return this.jsonConvert.deserializeObject(result.data, TouringCaravanMTAQuoteDetails)
  }

  async getCaravanQuote(reference: string): Promise<CaravanQuoteDetails> {
    const result = await this.axios.get(`${this.apiUrl}/quote/${encodeURIComponent(reference)}`)
    return this.jsonConvert.deserializeObject(result.data, CaravanQuoteDetails)
  }

  async getCaravanMTAQuote(reference: string): Promise<CaravanMTAQuoteDetails> {
    const result = await this.axios.get(`${this.apiUrl}/quote/mta/${encodeURIComponent(reference)}`)
    return this.jsonConvert.deserializeObject(result.data, CaravanMTAQuoteDetails)
  }

  async getGapMTAQuote(reference: string): Promise<GapMTAQuoteDetails> {
    const result = await this.axios.get(`${this.apiUrl}/quote/mta/${encodeURIComponent(reference)}`)
    return this.jsonConvert.deserializeObject(result.data, GapMTAQuoteDetails)
  }

  async getPetQuote(reference: string): Promise<PetQuoteDetails> {
    const result = await this.axios.get(`${this.apiUrl}/quote/${encodeURIComponent(reference)}`)
    return this.jsonConvert.deserializeObject(result.data, PetQuoteDetails)
  }

  async getPetMTAQuote(reference: string): Promise<PetMTAQuoteDetails> {
    const result = await this.axios.get(`${this.apiUrl}/quote/mta/${encodeURIComponent(reference)}`)
    return this.jsonConvert.deserializeObject(result.data, PetMTAQuoteDetails)
  }

  async getPropertyMTAQuote(reference: string): Promise<PropertyMTAQuoteDetails> {
    const result = await this.axios.get(`${this.apiUrl}/quote/mta/${encodeURIComponent(reference)}`)
    return this.jsonConvert.deserializeObject(result.data, PropertyMTAQuoteDetails)
  }

  async getPetRenewalQuote(policyNumber: string, quoteReference: string | null): Promise<PetRenewalQuoteDetails> {
    const result = await this.axios.get(`${this.apiUrl}/quote/renewal/${policyNumber}${quoteReference ? `/${quoteReference}` : ''}`)
    return this.jsonConvert.deserializeObject(result.data, PetRenewalQuoteDetails)
  }

  async getTouringCaravanRenewalQuote(policyNumber: string, quoteReference: string | null): Promise<TouringCaravanRenewalQuoteDetails> {
    const result = await this.axios.get(`${this.apiUrl}/quote/renewal/${policyNumber}${quoteReference ? `/${quoteReference}` : ''}`)
    return this.jsonConvert.deserializeObject(result.data, TouringCaravanRenewalQuoteDetails)
  }

  async getCaravanRenewalQuote(policyNumber: string, quoteReference: string | null): Promise<CaravanRenewalQuoteDetails> {
    const result = await this.axios.get(`${this.apiUrl}/quote/renewal/${policyNumber}${quoteReference ? `/${quoteReference}` : ''}`)
    return this.jsonConvert.deserializeObject(result.data, CaravanRenewalQuoteDetails)
  }

  async getVehicleQuote(reference: string): Promise<VehicleQuoteDetails> {
    const result = await this.axios.get(`${this.apiUrl}/quote/${encodeURIComponent(reference)}`)
    return this.jsonConvert.deserializeObject(result.data, VehicleQuoteDetails)
  }

  async getPropertyQuote(reference: string): Promise<PropertyQuoteDetails> {
    const result = await this.axios.get(`${this.apiUrl}/quote/${encodeURIComponent(reference)}`)
    return this.jsonConvert.deserializeObject(result.data, PropertyQuoteDetails)
  }

  async getPropertyRenewalQuote(policyNumber: string, quoteReference: string | null): Promise<PropertyQuoteDetails> {
    const result = await this.axios.get(`${this.apiUrl}/quote/renewal/${policyNumber}${quoteReference ? `/${quoteReference}` : ''}`)
    return this.jsonConvert.deserializeObject(result.data, PropertyQuoteDetails)
  }

  async getVehicleRenewalQuote(policyNumber: string, quoteReference: string | null): Promise<VehicleRenewalQuoteDetails> {
    const result = await this.axios.get(`${this.apiUrl}/quote/renewal/${policyNumber}${quoteReference ? `/${quoteReference}` : ''}`)
    return this.jsonConvert.deserializeObject(result.data, VehicleRenewalQuoteDetails)
  }

  async getVehicleMTAQuote(reference: string): Promise<VehicleMTAQuoteDetails> {
    const result = await this.axios.get(`${this.apiUrl}/quote/mta/${encodeURIComponent(reference)}`)
    return this.jsonConvert.deserializeObject(result.data, VehicleMTAQuoteDetails)
  }

  async getDynamicQuote(reference: string): Promise<DynamicQuoteDetails> {
    const result = await this.axios.get(`${this.apiUrl}/quote/${encodeURIComponent(reference)}`)
    return this.jsonConvert.deserializeObject(result.data, DynamicQuoteDetails)
  }

  async getQuotes(lapsed: boolean, start?: number, end?: number): Promise<QuotesCollectionResponse> {
    const result = await this.axios.get(`${this.apiUrl}/quote/customer`, {
      params: {
        start,
        end,
        lapsed,
      },
    })
    return this.jsonConvert.deserializeObject(result.data, QuotesCollectionResponse)
  }

  async setVAPsOnQuote(reference: string, vaps: number[]): Promise<void> {
    await this.axios.put(`${this.apiUrl}/quote/${encodeURIComponent(reference)}/vaps`, vaps)
  }

  async getRenewalQuote(policyNumber: string, quoteReference: string | null, product: ProductEnum): Promise<QuoteDetails<SchemeQuoteResult>> {
    let quote
    switch (product) {
      case ProductEnum.motorCaravan:
      case ProductEnum.modernCar:
      case ProductEnum.taxi:
      case ProductEnum.courier:
      case ProductEnum.commercialVehicle:
        quote = await this.getCarRenewalQuote(policyNumber, quoteReference)
        break
      case ProductEnum.multiPet:
        quote = await this.getPetRenewalQuote(policyNumber, quoteReference)
        break
      case ProductEnum.touringCaravan:
        quote = await this.getTouringCaravanRenewalQuote(policyNumber, quoteReference)
        break
      case ProductEnum.caravan:
        quote = await this.getCaravanRenewalQuote(policyNumber, quoteReference)
        break
      case ProductEnum.staticCaravanResidential:
        quote = await this.getCaravanRenewalQuote(policyNumber, quoteReference)
        break
      case ProductEnum.vehicle:
        quote = await this.getVehicleRenewalQuote(policyNumber, quoteReference)
        break
      case ProductEnum.home:
        quote = await this.getPropertyRenewalQuote(policyNumber, quoteReference)
        break
      default:
        throw new Error(`Unknown product (${product}).`)
    }
    return quote
  }
}
