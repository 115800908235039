import { JsonObject, JsonProperty } from 'json2typescript'
import DateConverter from '../../converters/DateConverter'
import { DefinedListDetail } from './DefinedList'

@JsonObject('TouringCaravanConviction')
export default class TouringCaravanConviction {
  @JsonProperty('convictionCode', DefinedListDetail)
  convictionCode: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('convictionDate', DateConverter, true)
  convictionDate: Date | null = null

  @JsonProperty('pointsReceived', Number, true)
  pointsReceived: number | null = null

  @JsonProperty('fine', Number, true)
  fine: number | null = null

  @JsonProperty('disqualificationMonths', Number, true)
  disqualificationMonths: number | null = null
}
