export default class UnknownQuestionTypeError implements Error {
  name: string

  message: string

  stack?: string

  constructor(type: string) {
    this.name = 'UnknownQuestionTypeError'
    this.message = `The requested question type '${type}' could not be found.`
  }
}
