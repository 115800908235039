import { JsonObject, JsonProperty } from 'json2typescript'
import DateConverter from '@/converters/DateConverter'

@JsonObject('MedicalConditionValue')
export default class MedicalCondition {
  @JsonProperty('incidentDate', DateConverter)
  incidentDate: Date = new Date()

  @JsonProperty('medicalCondition', String)
  medicalCondition = ''

  @JsonProperty('hasInformedDVLAOfMedicalConditionsAndDisabilities', Boolean)
  hasInformedDVLAOfMedicalConditionsAndDisabilities = false

  @JsonProperty('dvlaReportableMedicalConditions', String)
  dvlaReportableMedicalConditions = ''
}
