import { JsonObject, JsonProperty } from 'json2typescript'

@JsonObject('VehicleModificationValue')
export default class VehicleModification {
  @JsonProperty('modificationSubCategory', String, true)
    modificationSubCategory: string | null = null

  @JsonProperty('modificationDetail', String)
    modificationDetail = ''
}
