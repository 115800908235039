import { JsonObject, JsonProperty } from 'json2typescript'
import PaymentScheduleStatusEnum from '../enums/PaymentScheduleStatusEnum'
import PaymentScheduleStatusEnumConerter from '@/converters/PaymentScheduleStatusEnumConverter'
import DateConverter from '@/converters/DateConverter'

@JsonObject('PaymentScheduleItem')
export class PaymentScheduleItem {
  @JsonProperty('date', DateConverter)
  date: Date = new Date()

  @JsonProperty('amount', Number)
  amount = 0

  @JsonProperty('status', PaymentScheduleStatusEnumConerter)
  status: PaymentScheduleStatusEnum = PaymentScheduleStatusEnum.contactProvider
}

@JsonObject('PaymentSchedule')
export default class PaymentSchedule {
  @JsonProperty('schedule', [PaymentScheduleItem])
  schedule: PaymentScheduleItem[] = []
}
