import { JsonObject, JsonProperty } from 'json2typescript'
import Model from './Model'

@JsonObject('Endorsement')
export default class Endorsement extends Model {
  @JsonProperty('referenceCode', String)
  referenceCode: string

  @JsonProperty('text', String)
  text: string

  @JsonProperty('highlighted', Boolean)
  highlighted: boolean

  constructor(
    id = 0,
    referenceCode = '',
    text = '',
    highlighted = false,
  ) {
    super(id)
    this.referenceCode = referenceCode
    this.text = text
    this.highlighted = highlighted
  }
}
