import { JsonObject, JsonProperty } from 'json2typescript'
import DateConverter from '@/converters/DateConverter'

@JsonObject('VAPVehicle')
export default class VAPVehicle {
  @JsonProperty('gapEngineSize', Number)
  gapEngineSize: number | null = null

  @JsonProperty('vehicleMake', String, true)
  vehicleMake: string | null = null

  @JsonProperty('vehicleModel', String, true)
  vehicleModel: string | null = null

  @JsonProperty('vehicleRegistration', String, true)
  vehicleRegistration: string | null = null

  @JsonProperty('dateOfReg', DateConverter)
  dateOfReg: Date | null = new Date()

  constructor(
    vehicleMake = '',
    vehicleModel = '',
    gapEngineSize = 0,
    dateOfReg: Date = new Date(),
    vehicleRegistration = '',
  ) {
    this.vehicleMake = vehicleMake
    this.vehicleModel = vehicleModel
    this.gapEngineSize = gapEngineSize
    this.dateOfReg = dateOfReg
    this.vehicleRegistration = vehicleRegistration
  }
}
