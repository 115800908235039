export default class NotAcceptableError implements Error {
  name: string

  message: string

  constructor() {
    this.name = 'NotAcceptableError'
    this.message = 'The requested resource could not be accepted.'
  }
}
