import type _Vue from 'vue'
import VueRouter from 'vue-router'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'
import Axios from 'axios'
import { JsonConvert, ValueCheckingMode } from 'json2typescript'

import type IIdentityProvider from '@/services/interfaces/IIdentityProvider'
import type IAggregatorService from '@/services/interfaces/IAggregatorService'
import type IClaimService from '@/services/interfaces/IClaimService'
import type IAccountService from '@/services/interfaces/IAccountService'
import type IRatingEngineService from '@/services/interfaces/IRatingEngineService'
import type IVehicleService from '@/services/interfaces/IVehicleService'
import type IAddressService from '@/services/interfaces/IAddressService'
import type IQuoteService from '@/services/interfaces/IQuoteService'
import type ISchemeService from '@/services/interfaces/ISchemeService'
import type IPolicyService from '@/services/interfaces/IPolicyService'
import type IPaymentService from '@/services/interfaces/IPaymentService'
import type IProductService from '@/services/interfaces/IProductService'
import type IRiskService from '@/services/interfaces/IRiskService'
import type IDocumentService from '@/services/interfaces/IDocumentService'
import type IVeriskService from '@/services/interfaces/IVeriskService'
import type IAffiliateService from '@/services/interfaces/IAffiliateService'
import type IValidationService from '@/services/interfaces/IValidationService'

import type IResponseMapper from '@/services/interfaces/IResponseMapper'
import type IRequestMapper from '@/services/interfaces/IRequestMapper'
import type IQuestionMapper from '@/services/interfaces/IQuestionMapper'

import type { Dictionary } from '@/types'
import Routes from '@/constants/Routes'

import type TenantEnum from '@/services/enums/TenantEnum'

import AggregatorService from '@/services/AggregatorService'
import AccountService from '@/services/AccountService'
import ClaimService from '@/services/ClaimService'
import IdentityProvider from '@/services/IdentityProvider'
import RatingEngineService from '@/services/RatingEngineService'
import VehicleService from '@/services/VehicleService'
import AddressService from '@/services/AddressService'
import QuoteService from '@/services/QuoteService'
import PolicyService from '@/services/PolicyService'
import PaymentService from '@/services/PaymentService'
import ProductService from '@/services/ProductService'
import SchemeService from '@/services/SchemeService'
import RiskService from '@/services/RiskService'
import DocumentService from '@/services/DocumentService'
import VeriskService from '@/services/VeriskService'
import AffiliateService from '@/services/AffiliateService'
import ValidationService from '@/services/ValidationService'

import MapperFactory from '@/services/mappers/MapperFactory'
import NetworkError from '@/services/error/NetworkError'
import NotFoundError from '@/services/error/NotFoundError'
import AccountLocked from '@/services/error/AccountLocked'
import BadRequestError from '@/services/error/BadRequestError'
import NotAuthorisedError from '@/services/error/NotAuthorisedError'
import NotAcceptableError from '@/services/error/NotAcceptableError'
import type ContextLevel from '@/services/enums/ContextLevel'
import useAccountStore from '@/stores/account-store'
import { getAuthServiceConfig } from '@/auth-service'
import type IBenefitsService from '@/services/interfaces/IBenefitsService'
import BenefitsService from '@/services/BenefitsService'

const { isNavigationFailure, NavigationFailureType } = VueRouter

export interface IServices {
  tenant: TenantEnum
  apiUrl: URL

  identityProvider: IIdentityProvider
  aggregatorService: IAggregatorService
  accountService: IAccountService
  claimService: IClaimService
  productService: IProductService
  schemeService: ISchemeService
  vehicleService: IVehicleService
  addressService: IAddressService
  ratingEngineService: IRatingEngineService
  paymentService: IPaymentService
  quoteService: IQuoteService
  policyService: IPolicyService
  riskService: IRiskService
  documentService: IDocumentService
  veriskService: IVeriskService
  affiliateService: IAffiliateService
  validationService: IValidationService
  benefitsService: IBenefitsService
}

export class Services implements IServices {
  axios: AxiosInstance

  tenant: TenantEnum

  apiUrl: URL

  identityProvider: IIdentityProvider

  aggregatorService: IAggregatorService

  accountService: IAccountService

  claimService: IClaimService

  productService: IProductService

  schemeService: ISchemeService

  vehicleService: IVehicleService

  addressService: IAddressService

  ratingEngineService: IRatingEngineService

  quoteService: IQuoteService

  paymentService: IPaymentService

  policyService: IPolicyService

  riskService: IRiskService

  documentService: IDocumentService

  veriskService: IVeriskService

  affiliateService: IAffiliateService

  validationService: IValidationService

  benefitsService: IBenefitsService

  constructor(axios: AxiosInstance, tenant: TenantEnum, apiUrl: URL, requestMapper: IRequestMapper, responseMapper: IResponseMapper, questionMapper: IQuestionMapper, pageMappings: Dictionary<{ route: Routes, contextLevel: ContextLevel }>, mtaPageMappings: Dictionary<{ route: Routes, contextLevel: ContextLevel }>, renewalPageMappings: Dictionary<{ route: Routes, contextLevel: ContextLevel }>) {
    this.axios = axios

    this.tenant = tenant
    this.apiUrl = apiUrl

    const jsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL

    this.identityProvider = new IdentityProvider(axios, apiUrl, jsonConvert)
    this.aggregatorService = new AggregatorService(axios, apiUrl, jsonConvert, responseMapper)
    this.accountService = new AccountService(axios, apiUrl, jsonConvert, responseMapper, requestMapper)
    this.claimService = new ClaimService(axios, apiUrl, jsonConvert)
    this.productService = new ProductService(axios, apiUrl, questionMapper, pageMappings, mtaPageMappings, renewalPageMappings, jsonConvert)
    this.schemeService = new SchemeService(axios, apiUrl, jsonConvert, requestMapper)
    this.vehicleService = new VehicleService(axios, apiUrl, jsonConvert)
    this.addressService = new AddressService(axios, apiUrl, jsonConvert)
    this.ratingEngineService = new RatingEngineService(axios, apiUrl, jsonConvert, requestMapper, responseMapper)
    this.quoteService = new QuoteService(axios, apiUrl, jsonConvert)
    this.policyService = new PolicyService(axios, apiUrl, jsonConvert)
    this.paymentService = new PaymentService(axios, apiUrl, jsonConvert)
    this.riskService = new RiskService(axios, apiUrl, jsonConvert, responseMapper)
    this.documentService = new DocumentService(axios, apiUrl, jsonConvert)
    this.veriskService = new VeriskService(axios, apiUrl, jsonConvert)
    this.affiliateService = new AffiliateService(axios, apiUrl, jsonConvert)
    this.validationService = new ValidationService(requestMapper)
    this.benefitsService = new BenefitsService(axios, apiUrl, jsonConvert)
  }
}

export interface IServicesOptions {
  apiUrl: URL
  axiosConfig: AxiosRequestConfig
  tenant: TenantEnum
  router: VueRouter
}

export default function ServicesPlugin(Vue: typeof _Vue, options: IServicesOptions): void {
  Axios.defaults = Object.assign(Axios.defaults, options.axiosConfig)

  if (!options)
    throw new Error('SERVICES: You must supply a configuration.')

  Axios.interceptors.request.use((req) => {
    const authServiceConfig = getAuthServiceConfig()
    if (authServiceConfig.enabled)
      req.headers.Authorization = authServiceConfig.authHeader
    return req
  })

  Axios.interceptors.response.use(undefined, async (error: any) => {
    if (error.message === 'Network Error'
      || error.response.status === 408
      || error.response.status === 500) {
      if (error.config.headers.can500Error)
        throw new NetworkError()

      if (options.router.currentRoute?.name !== Routes.serverError || options.router.currentRoute?.params?.errorId !== error?.response?.data?.id)
        options.router.push({ name: Routes.serverError, params: { errorId: error?.response?.data?.id } })

      throw new NetworkError()
    }
    else if (error.response.status === 423) {
      throw new AccountLocked(error.response.data.modelState)
    }
    else if (error.response.status === 404) {
      throw new NotFoundError()
    }
    else if (error.response.status === 400) {
      throw new BadRequestError(error.response.data.modelState)
    }
    else if (error.response.status === 403) {
      if (!error.config.headers.canBeUnauthorised) {
        const accountStore = useAccountStore()
        if (accountStore.account?.hasCustomerCredentials === false) {
          if (options.router.currentRoute?.name !== Routes.sessionExpired)
            options.router.push({ name: Routes.sessionExpired })
        }
        else if (options.router.currentRoute?.name !== Routes.login && options.router.currentRoute?.name !== Routes.sessionExpired) {
          try {
            await options.router.replace({ name: Routes.login })
          }
          catch (failure) {
            if (!isNavigationFailure(failure, NavigationFailureType.cancelled))
              throw failure
          }
        }
        accountStore.clearAccount()
      }
      throw new NotAuthorisedError()
    }
    else if (error.response.status === 406) {
      throw new NotAcceptableError()
    }
    throw error
  })

  const mapper = MapperFactory(options.tenant) // CHANGE TO LAZY LOAD
  const requestMapper = mapper
  const responseMapper = mapper
  const questionMapper = mapper
  const pageMappings = mapper.nbPageMappings
  const { mtaPageMappings } = mapper
  const { renewalPageMappings } = mapper

  Vue.prototype.$services = new Services(Axios, options.tenant, options.apiUrl, requestMapper, responseMapper, questionMapper, pageMappings, mtaPageMappings, renewalPageMappings)
}
