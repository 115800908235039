import type { JsonConvert } from 'json2typescript'
import type { AxiosInstance } from 'axios'
import type IVeriskService from './interfaces/IVeriskService'
import VeriskConfig from './models/VeriskConfig'

export default class VeriskService implements IVeriskService {
  axios: AxiosInstance

  apiUrl: URL

  jsonConvert: JsonConvert

  constructor(axios: AxiosInstance, apiUrl: URL, jsonConvert: JsonConvert) {
    this.axios = axios
    this.apiUrl = apiUrl
    this.jsonConvert = jsonConvert
  }

  async getVeriskDetails(): Promise<VeriskConfig> {
    let result = null
    result = await this.axios.get(`${this.apiUrl}/verisk/config`)
    return this.jsonConvert.deserializeObject(result.data, VeriskConfig)
  }

  getVeriskAuthEndpoint(): string {
    return `${this.apiUrl}/verisk/auth`
  }
}
