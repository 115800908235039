import { JsonObject, JsonProperty } from 'json2typescript'
import Address from '../models/Address'
import RiskProposer from './RiskProposer'
import DateConverter from '@/converters/DateConverter'
import type { Dictionary } from '@/types'

@JsonObject('QuoteRequest')
export default class QuoteRequest {
  @JsonProperty('address', Address)
  address: Address = new Address()

  @JsonProperty('effectiveDate', DateConverter)
  effectiveDate: Date = new Date()

  @JsonProperty('businessSourceId', Number, true)
  businessSourceId: number | null = null

  @JsonProperty('aggregatorQuoteReference', String, true)
  aggregatorQuoteReference: string | null = null

  @JsonProperty('metaData', Object)
  metaData: Dictionary<string> = {}

  @JsonProperty('coInsuranceRuleId', Number, true)
  coInsuranceRuleId: number | null = null

  @JsonProperty('riskProposer', RiskProposer)
  riskProposer: RiskProposer | null = null
}
