import moment from 'moment'
import type IRequestMapper from '../interfaces/IRequestMapper'
import type IResponseMapper from '../interfaces/IResponseMapper'
import type IQuestionMapper from '../interfaces/IQuestionMapper'
import type SchemeRequest from '../values/SchemeRequest'
import type QuoteRequest from '../values/QuoteRequest'
import type Page from '../../view-models/Page'
import type ProductPage from '../models/ProductPage'
import QuestionEnum from '../enums/QuestionEnum'
import DLDCommonValuesEnum from '../enums/DLDCommonValuesEnum'
import GapVehiclePaymentTypeEnum from '../enums/GapVehiclePaymentTypeEnum'
import type { AdditionalDriver } from '../values/Driver'
import { CarProposer } from '../values/Driver'
import UnkownQuestionSetError from '../error/UnknownQuestionSetError'
import Vehicle from '../values/Vehicle'
import AccountRegister from '../values/AccountRegister'
import type Risk from '../models/Risk'
import { CarRisk, GapRisk, TouringCaravanRisk } from '../models/Risk'
import type Account from '../models/Account'
import { Marketing } from '../models/Account'
import Car from '../values/Car'
import type IPageMappings from '../interfaces/IPageMappings'
import type { IQuestionAnswer } from '../values/PageAnswers'
import PageAnswers, { QuestionAnswer } from '../values/PageAnswers'
import type Address from '../models/Address'
import CarQuoteRequest, { CarMTAQuoteRequest } from '../values/CarQuoteRequest'
import GapQuoteRequest, { GapMTAQuoteRequest } from '../values/GapQuoteRequest'
import TouringCaravanQuoteRequest, { TouringCaravanMTAQuoteRequest } from '../values/TouringCaravanQuoteRequest'
import type IQuestion from '../interfaces/IQuestion'
import type TouringCaravanClaimRequest from '../values/TouringCaravanClaimRequest'
import TouringCaravanRequest from '../values/TouringCaravanRequest'
import type TouringCaravanConvictionRequest from '../values/TouringCaravanConvictionRequest'
import TouringCaravanProposerRequest from '../values/TouringCaravanProposerRequest'
import type TouringCaravanStorageLocationRequest from '../values/TouringCaravanStorageLocationRequest'
import BaseMapper from './BaseMapper'
import type ClaimModel from '@/services/models/Claim'
import type { AddressQuestion, DateQuestion, DrivingLicenceQuestion, MarkdownQuestion, NumberQuestion, SelectQuestion, TextQuestion } from '@/view-models/Question'
import ContextLevel from '@/services/enums/ContextLevel'
import type { Dictionary } from '@/types'
import Routes from '@/constants/Routes'
import type Claim from '@/services/values/Claim'

export default class WrapperMapper extends BaseMapper implements IQuestionMapper, IRequestMapper, IResponseMapper, IPageMappings {
  nbPageMappings: Dictionary<{ route: Routes, contextLevel: ContextLevel }> = {
    MotorCaravan_Vehicle_Details_CustomerFacing: { route: Routes.vehicleDetails, contextLevel: ContextLevel.preQuote },
    MotorCaravan_Proposer_CustomerFacing: { route: Routes.proposerDetails, contextLevel: ContextLevel.preQuote },
    MotorCaravan_Cover_Details_CustomerFacing: { route: Routes.coverDetails, contextLevel: ContextLevel.preQuote },
    MotorCaravan_Premium_CustomerFacing: { route: Routes.premium, contextLevel: ContextLevel.quote },
    MotorCaravan_VAPs_CustomerFacing: { route: Routes.additionalProducts, contextLevel: ContextLevel.quote },
    MotorCaravan_Payment: { route: Routes.payment, contextLevel: ContextLevel.quote },
    MotorCaravan_PostTakeUpVAPs_CustomerFacing: { route: Routes.upgrade, contextLevel: ContextLevel.policy },
    MotorCaravan_VAPs_Payment_CustomerFacing: { route: Routes.upgradePayment, contextLevel: ContextLevel.policy },
    MotorCaravan_PaymentConfirmation: { route: Routes.paymentConfirm, contextLevel: ContextLevel.policy },
    GAPInsurance_Vehicle_Details_CustomerFacing: { route: Routes.vehicleDetails, contextLevel: ContextLevel.preQuote },
    GAPInsurance_Owning_CustomerFacing: { route: Routes.owningAndUsing, contextLevel: ContextLevel.preQuote },
    GAPInsurance_Cover_Details_CustomerFacing: { route: Routes.coverDetails, contextLevel: ContextLevel.preQuote },
    GAPInsurance_Proposer_Details_CustomerFacing: { route: Routes.proposerDetails, contextLevel: ContextLevel.preQuote },
    GAPInsurance_Premium_CustomerFacing: { route: Routes.premium, contextLevel: ContextLevel.quote },
    GAPInsurance_Payment_CustomerFacing: { route: Routes.payment, contextLevel: ContextLevel.quote },
    GAPInsurance_PaymentConfirmation_CustomerFacing: { route: Routes.paymentConfirm, contextLevel: ContextLevel.policy },
    GAPInsurance_VAPs_CustomerFacing: { route: Routes.additionalProducts, contextLevel: ContextLevel.quote },
    GAPInsurance_PostTakeUpVAPs_CustomerFacing: { route: Routes.upgrade, contextLevel: ContextLevel.policy },
    GAPInsurance_VAPs_Payment_CustomerFacing: { route: Routes.upgradePayment, contextLevel: ContextLevel.policy },
    TouringCaravan_Vehicle_Details_CustomerFacing: { route: Routes.vehicleDetails, contextLevel: ContextLevel.preQuote },
    TouringCaravan_Proposer_CustomerFacing: { route: Routes.proposerDetails, contextLevel: ContextLevel.preQuote },
    TouringCaravan_Cover_Details_CustomerFacing: { route: Routes.coverDetails, contextLevel: ContextLevel.preQuote },
    TouringCaravan_Premium_CustomerFacing: { route: Routes.premium, contextLevel: ContextLevel.quote },
    TouringCaravan_VAPs_CustomerFacing: { route: Routes.additionalProducts, contextLevel: ContextLevel.quote },
    TouringCaravan_Payment_CustomerFacing: { route: Routes.payment, contextLevel: ContextLevel.quote },
    TouringCaravan_PaymentConfirmation_CustomerFacing: { route: Routes.paymentConfirm, contextLevel: ContextLevel.policy },
    ModernCar_Vehicle_Details_CustomerFacing: { route: Routes.vehicleDetails, contextLevel: ContextLevel.preQuote },
    ModernCar_VehicleToInsure_CustomerFacing: { route: Routes.vehicleToInsure, contextLevel: ContextLevel.preQuote },
    ModernCar_Proposer_CustomerFacing: { route: Routes.proposerDetails, contextLevel: ContextLevel.preQuote },
    ModernCar_ProposerLicenceInfo_CustomerFacing: { route: Routes.proposerLicenceInfo, contextLevel: ContextLevel.preQuote },
    ModernCar_Drivers_CustomerFacing: { route: Routes.additionalDriverDetails, contextLevel: ContextLevel.preQuote },
    ModernCar_Premium_CustomerFacing: { route: Routes.premium, contextLevel: ContextLevel.quote },
    ModernCar_Declarations_CustomerFacing: { route: Routes.declarations, contextLevel: ContextLevel.quote },
    ModernCar_Payment_CustomerFacing: { route: Routes.payment, contextLevel: ContextLevel.quote },
    ModernCar_PaymentConfirmation_CustomerFacing: { route: Routes.paymentConfirm, contextLevel: ContextLevel.policy },
  }

  mtaPageMappings: Dictionary<{ route: Routes, contextLevel: ContextLevel }> = {
    MotorCaravan_Vehicle_DetailsMta_CustomerFacing: { route: Routes.mtaVehicleDetails, contextLevel: ContextLevel.mtaPreQuote },
    MotorCaravan_ProposerMta_CustomerFacing: { route: Routes.mtaProposerDetails, contextLevel: ContextLevel.mtaPreQuote },
    MotorCaravan_Cover_DetailsMta_CustomerFacing: { route: Routes.mtaCoverDetails, contextLevel: ContextLevel.mtaPreQuote },
    MotorCaravan_PremiumMta_CustomerFacing: { route: Routes.mtaPremium, contextLevel: ContextLevel.mtaQuote },
    MotorCaravan_Payment: { route: Routes.mtaPayment, contextLevel: ContextLevel.mtaQuote },
    MotorCaravan_PaymentConfirmationMta: { route: Routes.mtaConfirm, contextLevel: ContextLevel.mtaTakenUp },
    GAPInsurance_Vehicle_Details_MTA_CustomerFacing: { route: Routes.mtaVehicleDetails, contextLevel: ContextLevel.mtaPreQuote },
    GAPInsurance_Owning_MTA_CustomerFacing: { route: Routes.mtaOwningAndUsing, contextLevel: ContextLevel.mtaPreQuote },
    GAPInsurance_Cover_Details_MTA_CustomerFacing: { route: Routes.mtaCoverDetails, contextLevel: ContextLevel.mtaPreQuote },
    GAPInsurance_Proposer_Details_MTA_CustomerFacing: { route: Routes.mtaProposerDetails, contextLevel: ContextLevel.mtaPreQuote },
    GAPInsurance_Premium_MTA_CustomerFacing: { route: Routes.mtaPremium, contextLevel: ContextLevel.mtaQuote },
    GAPInsurance_Payment_MTA_CustomerFacing: { route: Routes.mtaPayment, contextLevel: ContextLevel.mtaQuote },
    GAPInsurance_PaymentConfirmation_MTA_CustomerFacing: { route: Routes.mtaConfirm, contextLevel: ContextLevel.mtaTakenUp },
    TouringCaravan_Vehicle_DetailsMta_CustomerFacing: { route: Routes.mtaVehicleDetails, contextLevel: ContextLevel.mtaPreQuote },
    TouringCaravan_ProposerMta_CustomerFacing: { route: Routes.mtaProposerDetails, contextLevel: ContextLevel.mtaPreQuote },
    TouringCaravan_Cover_DetailsMta_CustomerFacing: { route: Routes.mtaCoverDetails, contextLevel: ContextLevel.mtaPreQuote },
    TouringCaravan_Premium_MTA_CustomerFacing: { route: Routes.mtaPremium, contextLevel: ContextLevel.mtaQuote },
    TouringCaravan_Payment_MTA_CustomerFacing: { route: Routes.mtaPayment, contextLevel: ContextLevel.mtaQuote },
    TouringCaravan_PaymentConfirmation_MTA_CustomerFacing: { route: Routes.mtaConfirm, contextLevel: ContextLevel.mtaTakenUp },
  }

  renewalPageMappings: Dictionary<{ route: Routes, contextLevel: ContextLevel }> = {
    MotorCaravan_Vehicle_DetailsRenewal_CustomerFacing: { route: Routes.renewalVehicleDetails, contextLevel: ContextLevel.renewalPreQuote },
    MotorCaravan_ProposerRenewal_CustomerFacing: { route: Routes.renewalProposerDetails, contextLevel: ContextLevel.renewalPreQuote },
    MotorCaravan_Cover_DetailsRenewal_CustomerFacing: { route: Routes.renewalCoverDetails, contextLevel: ContextLevel.renewalPreQuote },
    MotorCaravan_PremiumRenewal_CustomerFacing: { route: Routes.renewalPremium, contextLevel: ContextLevel.renewalQuote },
    MotorCaravan_PaymentRenewal: { route: Routes.renewalPayment, contextLevel: ContextLevel.renewalQuote },
    MotorCaravan_PostTakeUpVAPs_CustomerFacing: { route: Routes.upgrade, contextLevel: ContextLevel.renewalTakenUp },
    MotorCaravan_VAPs_Payment_CustomerFacing: { route: Routes.upgradePayment, contextLevel: ContextLevel.renewalTakenUp },
    MotorCaravan_PaymentConfirmation: { route: Routes.renewalPaymentConfirmation, contextLevel: ContextLevel.renewalTakenUp },
    TouringCaravan_Vehicle_DetailsRenewal_CustomerFacing: { route: Routes.renewalVehicleDetails, contextLevel: ContextLevel.renewalPreQuote },
    TouringCaravan_ProposerRenewal_CustomerFacing: { route: Routes.renewalProposerDetails, contextLevel: ContextLevel.renewalPreQuote },
    TouringCaravan_Cover_DetailsRenewal_CustomerFacing: { route: Routes.renewalCoverDetails, contextLevel: ContextLevel.renewalPreQuote },
    TouringCaravan_Premium_Renewal_CustomerFacing: { route: Routes.renewalPremium, contextLevel: ContextLevel.renewalQuote },
    TouringCaravan_Payment_Renewal_CustomerFacing: { route: Routes.renewalPayment, contextLevel: ContextLevel.renewalQuote },
    TouringCaravan_PaymentConfirmation_Renewal_CustomerFacing: { route: Routes.renewalPaymentConfirmation, contextLevel: ContextLevel.renewalTakenUp },
  }

  setupQuestions(setId: string, productPage: ProductPage): Page {
    const mappedPage = this.mapProductPageToPage(productPage)
    return this.setupDependencies(setId, mappedPage)
  }

  setupPage(setId: string, productPage: ProductPage, answeredQuestions: IQuestionAnswer[]): Page {
    const page = this.mapProductPageToPage(productPage)
    return this.setupDependencies(setId, page, answeredQuestions)
  }

  setupDependencies(setId: string, mappedPage: Page, _answeredQuestions?: IQuestionAnswer[]): Page {
    switch (setId) {
      case 'MotorCaravan_Proposer_CustomerFacing':
      case 'MotorCaravan_ProposerMta_CustomerFacing':
      case 'MotorCaravan_ProposerRenewal_CustomerFacing':
        return this.setupProposerDependencies(mappedPage)
      case 'MotorCaravan_Proposer_CustomerFacing_AdditionalDriver':
      case 'MotorCaravan_ProposerMta_CustomerFacing_AdditionalDriver':
      case 'MotorCaravan_ProposerRenewal_CustomerFacing_AdditionalDriver':
        return this.setupAdditionalDriverDependencies(mappedPage)
      case 'MotorCaravan_Cover_Details_CustomerFacing':
      case 'MotorCaravan_Cover_DetailsMta_CustomerFacing':
      case 'GAPInsurance_Cover_Details_CustomerFacing':
      case 'GAPInsurance_Cover_Details_MTA_CustomerFacing':
        return this.setupCoverDetailsDependencies(mappedPage)
      case 'MotorCaravan_AggregatorLanding':
      case 'MotorCaravan_AggregatorLanding_AdditionalDriver':
        return this.setupMotorhomeAggregatorLandingDependencies(mappedPage)
      case 'GapInsurance_AggregatorLanding':
      case 'MotorCaravan_Proposer_CustomerFacing_Claims':
      case 'MotorCaravan_ProposerMta_CustomerFacing_Claims':
      case 'MotorCaravan_ProposerRenewal_CustomerFacing_Claims':
      case 'MotorCaravan_Proposer_CustomerFacing_AdditionalDriver_Claims':
      case 'MotorCaravan_ProposerMta_CustomerFacing_AdditionalDriver_Claims':
      case 'MotorCaravan_ProposerRenewal_CustomerFacing_AdditionalDriver_Claims':
      case 'MotorCaravan_Cover_DetailsRenewal_CustomerFacing':
      case 'MotorCaravan_VAPs_CustomerFacing':
      case 'MotorCaravan_PostTakeUpVAPs_CustomerFacing':
      case 'MotorCaravan_VAPs_Payment_CustomerFacing':
      case 'MotorCaravan_Premium_CustomerFacing':
      case 'MotorCaravan_PremiumMta_CustomerFacing':
      case 'MotorCaravan_PremiumMta':
      case 'MotorCaravan_PremiumRenewal_CustomerFacing':
      case 'MotorCaravan_Payment':
      case 'MotorCaravan_PaymentConfirmation':
      case 'MotorCaravan_PaymentConfirmationMta':
      case 'MotorCaravan_PremiumRenewal':
      case 'MotorCaravan_PaymentRenewal':
      case 'Account':
      case 'GAPInsurance_Vehicle_Details_CustomerFacing':
      case 'GAPInsurance_Premium_CustomerFacing':
      case 'GAPInsurance_Payment_CustomerFacing':
      case 'GAPInsurance_PaymentConfirmation_CustomerFacing':
      case 'GAPInsurance_Vehicle_Details_MTA_CustomerFacing':
      case 'GAPInsurance_Premium_MTA_CustomerFacing':
      case 'GAPInsurance_Payment_MTA_CustomerFacing':
      case 'GAPInsurance_PaymentConfirmation_MTA_CustomerFacing':
      case 'GAPInsurance_VAPs_CustomerFacing':
      case 'GAPInsurance_PostTakeUpVAPs_CustomerFacing':
      case 'GAPInsurance_VAPs_Payment_CustomerFacing':
      case 'TouringCaravan_Premium_CustomerFacing':
      case 'TouringCaravan_Payment_CustomerFacing':
      case 'TouringCaravan_PaymentConfirmation_CustomerFacing':
      case 'TouringCaravan_Premium_MTA_CustomerFacing':
      case 'TouringCaravan_Payment_MTA_CustomerFacing':
      case 'TouringCaravan_PaymentConfirmation_MTA_CustomerFacing':
      case 'TouringCaravan_Premium_Renewal_CustomerFacing':
      case 'TouringCaravan_Payment_Renewal_CustomerFacing':
      case 'TouringCaravan_PaymentConfirmation_Renewal_CustomerFacing':
      case 'TouringCaravan_VAPs_CustomerFacing':
      case 'ModernCar_Vehicle_Details_CustomerFacing':
      case 'ModernCar_Drivers_CustomerFacing':
      case 'ModernCar_ProposerLicenceInfo_CustomerFacing':
      case 'ModernCar_Declarations_CustomerFacing':
      case 'ModernCar_Payment_CustomerFacing':
      case 'ModernCar_PaymentConfirmation_CustomerFacing':
        return mappedPage
      case 'TouringCaravan_Cover_Details_CustomerFacing':
      case 'TouringCaravan_Cover_DetailsMta_CustomerFacing':
      case 'TouringCaravan_Cover_DetailsRenewal_CustomerFacing':
        return this.setupTouringCaravanCoverDependencies(mappedPage)
      case 'TouringCaravan_Vehicle_Details_CustomerFacing':
      case 'TouringCaravan_Vehicle_DetailsMta_CustomerFacing':
      case 'TouringCaravan_Vehicle_DetailsRenewal_CustomerFacing':
        return this.setupTouringCaravanVehicleDetailsDependencies(mappedPage)
      case 'TouringCaravan_Proposer_CustomerFacing':
      case 'TouringCaravan_ProposerMta_CustomerFacing':
      case 'TouringCaravan_ProposerRenewal_CustomerFacing':
        return this.setupTouringCaravanProposerDependencies(mappedPage)
      case 'TouringCaravan_Vehicle_Details_StorageLocationComponent':
      case 'TouringCaravan_Vehicle_DetailsMTA_StorageLocationComponent':
      case 'TouringCaravan_Vehicle_DetailsRenewal_StorageLocationComponent':
        return this.setupStorageLocationComponentDependencies(mappedPage)
      case 'TouringCaravan_Cover_Details_Claims':
      case 'TouringCaravan_Cover_DetailsMta_Claims':
      case 'TouringCaravan_Cover_DetailsRenewal_Claims':
        return this.setupClaimsDependencies(mappedPage)
      case 'TouringCaravan_Cover_Details_Convictions':
      case 'TouringCaravan_Cover_DetailsMta_Convictions':
      case 'TouringCaravan_Cover_DetailsRenewal_Convictions':
        return this.setupConvictionsDependencies(mappedPage)
      case 'MotorCaravan_Vehicle_Details_CustomerFacing':
      case 'MotorCaravan_Vehicle_DetailsMta_CustomerFacing':
      case 'MotorCaravan_Vehicle_DetailsRenewal_CustomerFacing':
        return this.setupVehicleDetailsDependencies(mappedPage)
      case 'GAPInsurance_Proposer_Details_CustomerFacing':
      case 'GAPInsurance_Proposer_Details_MTA_CustomerFacing':
        return this.setupGapProposerDependencies(mappedPage)
      case 'GAPInsurance_Owning_CustomerFacing':
      case 'GAPInsurance_Owning_MTA_CustomerFacing':
        return this.setupOwningDependencies(mappedPage)
      case 'ModernCar_VehicleToInsure_CustomerFacing':
        return this.setupModernCarVehicleDependencies(mappedPage)
      case 'ModernCar_Proposer_CustomerFacing':
        return this.setupModernCarProposerDependencies(mappedPage)
      case 'ModernCar_Drivers_CustomerFacing_AdditionalDriver':
        return this.setupModernCarAdditionalDriverDependencies(mappedPage)
      case 'ModernCar_Premium_CustomerFacing':
        return this.setupModernCarPremiumDependencies(mappedPage)
      default:
        throw new UnkownQuestionSetError(setId)
    }
  }

  private setupOwningDependencies(page: Page): Page {
    const questions = page.sections.flatMap(s => s.questions)
    const repairQuestion = questions.find(q => q.name === QuestionEnum.gapDamagedBeyondRepair) as MarkdownQuestion
    const paymentQuestion = questions.find(q => q.name === QuestionEnum.gapVehiclePaymentType) as SelectQuestion<string, string>
    const vehicleDeliveryTaken = questions.find(q => q.name === QuestionEnum.vehicleDeliveryTaken) as MarkdownQuestion
    const vehicleCollectionDate = questions.find(q => q.name === QuestionEnum.vehicleCollectionDate) as DateQuestion
    repairQuestion.isVisible = () => repairQuestion.visible && (paymentQuestion.value === GapVehiclePaymentTypeEnum.pcpPurchase || paymentQuestion.value === GapVehiclePaymentTypeEnum.hirePurchase)

    if (vehicleDeliveryTaken)
      vehicleCollectionDate.isVisible = () => vehicleDeliveryTaken.value! === DLDCommonValuesEnum.Boolean.Yes

    return page
  }

  private setupTouringCaravanProposerDependencies(page: Page): Page {
    const questions = page.sections.flatMap(s => s.questions)
    const ukResident = questions.find(q => q.name === QuestionEnum.ukResident) as SelectQuestion<string, string>
    const residentSinceBirth = questions.find(q => q.name === QuestionEnum.residentSinceBirth) as SelectQuestion<string, string>
    const drivingTestPassed = questions.find(q => q.name === QuestionEnum.drivingTestPassed) as SelectQuestion<string, string>
    const manoeuvringCourse = questions.find(q => q.name === QuestionEnum.manoeuvringCourse) as SelectQuestion<string, string>
    const isClubMember = questions.find(q => q.name === QuestionEnum.isClubMember) as SelectQuestion<string, string>
    const clubName = questions.find(q => q.name === QuestionEnum.touringClubName) as SelectQuestion<string, string>
    const employmentStatusQuestion = questions.find(q => q.name === QuestionEnum.employmentStatus) as SelectQuestion<string, string>
    const occupationQuestion = questions.find(q => q.name === QuestionEnum.occupation) as SelectQuestion<string, string>
    const businessCategoryQuestion = questions.find(q => q.name === QuestionEnum.businessCategory) as SelectQuestion<string, string>
    const hasSecondaryEmployment = questions.find(q => q.name === QuestionEnum.hasSecondaryEmployment) as SelectQuestion<string, string>
    const secondaryOccupation = questions.find(q => q.name === QuestionEnum.secondaryOccupation) as SelectQuestion<string, string>
    const secondaryBusinessCategory = questions.find(q => q.name === QuestionEnum.secondaryBusinessCategory) as SelectQuestion<string, string>

    ukResident.isVisible = () => residentSinceBirth.value! === DLDCommonValuesEnum.Boolean.No
    manoeuvringCourse.isVisible = () => drivingTestPassed.value! === DLDCommonValuesEnum.Boolean.Yes
    clubName.isVisible = () => isClubMember.value! === DLDCommonValuesEnum.Boolean.Yes
    this.setupDOB(questions)

    occupationQuestion.isVisible = businessCategoryQuestion.isVisible = () => employmentStatusQuestion.value != null && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.Retired && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.Unemployed
    secondaryOccupation.isVisible = secondaryBusinessCategory.isVisible = () => hasSecondaryEmployment.value! === DLDCommonValuesEnum.Boolean.Yes

    return page
  }

  private setupTouringCaravanCoverDependencies(page: Page): Page {
    const questions = page.sections.flatMap(s => s.questions)
    const basisOfCover = questions.find(q => q.name === QuestionEnum.basisOfCover) as SelectQuestion<string, string>
    const purchasePrice = questions.find(q => q.name === QuestionEnum.purchasePrice) as SelectQuestion<string, string>
    const marketPrice = questions.find(q => q.name === QuestionEnum.marketPrice) as SelectQuestion<string, string>
    const coverForAwnings = questions.find(q => q.name === QuestionEnum.coverForAwnings) as SelectQuestion<string, string>
    const awningsPrice = questions.find(q => q.name === QuestionEnum.awningsPrice) as SelectQuestion<string, string>
    const coverForequipment = questions.find(q => q.name === QuestionEnum.coverForequipment) as SelectQuestion<string, string>
    const equipmentPrice = questions.find(q => q.name === QuestionEnum.equipmentPrice) as SelectQuestion<string, string>
    const coverForPersonalEffects = questions.find(q => q.name === QuestionEnum.coverForPersonalEffects) as SelectQuestion<string, string>
    const personalEffectsPrice = questions.find(q => q.name === QuestionEnum.personalEffectsPrice) as SelectQuestion<string, string>
    const coverForContents = questions.find(q => q.name === QuestionEnum.coverForContents) as SelectQuestion<string, string>
    const contentsPrice = questions.find(q => q.name === QuestionEnum.contentsPrice) as SelectQuestion<string, string>
    const noClaimsProtection = questions.find(q => q.name === QuestionEnum.noClaimsProtection) as SelectQuestion<string, string>
    const otherNoClaimsBonus = questions.find(q => q.name === QuestionEnum.otherNoClaimsBonus) as SelectQuestion<string, string>
    const effectiveDateQuestion = questions.find(q => q.name === QuestionEnum.coverEffectiveDate) as DateQuestion ?? questions.find(q => q.name === QuestionEnum.mtaEffectiveDate) as DateQuestion
    const yearOfMake = questions.find(q => q.name === QuestionEnum.yearOfMake) as NumberQuestion

    effectiveDateQuestion.minDate = () => moment().toDate()
    effectiveDateQuestion.maxDate = () => moment().add(31, 'days').toDate()

    basisOfCover.isVisible = () => (new Date().getFullYear() - (yearOfMake?.value ?? 0)) <= 5
    purchasePrice.isVisible = () => basisOfCover.value === DLDCommonValuesEnum.BasisOfCover.NewForOld && ((new Date().getFullYear() - (yearOfMake?.value ?? 0)) <= 5)
    marketPrice.isVisible = () => basisOfCover.value === DLDCommonValuesEnum.BasisOfCover.MarketValue || ((new Date().getFullYear() - (yearOfMake?.value ?? 0)) > 5)

    contentsPrice.isVisible = () => coverForContents.value === DLDCommonValuesEnum.Boolean.Yes
    personalEffectsPrice.isVisible = () => coverForPersonalEffects.value === DLDCommonValuesEnum.Boolean.Yes
    equipmentPrice.isVisible = () => coverForequipment.value === DLDCommonValuesEnum.Boolean.Yes
    awningsPrice.isVisible = () => coverForAwnings.value === DLDCommonValuesEnum.Boolean.Yes
    noClaimsProtection.isVisible = () => otherNoClaimsBonus.value !== null && this.getNoClaimsYears(otherNoClaimsBonus.value) > 3

    return page
  }

  private setupConvictionsDependencies(page: Page): Page {
    const questions = page.sections.flatMap(s => s.questions)
    const penaltyPointsGiven = questions.find(q => q.name === QuestionEnum.penaltyPointsGiven) as SelectQuestion<string, string>
    const convictionPointsReceived = questions.find(q => q.name === QuestionEnum.convictionPointsReceived) as SelectQuestion<string, string>
    const fineGiven = questions.find(q => q.name === QuestionEnum.fineGiven) as SelectQuestion<string, string>
    const fineAmount = questions.find(q => q.name === QuestionEnum.fineAmount) as SelectQuestion<string, string>
    const disqualifiedFromDriving = questions.find(q => q.name === QuestionEnum.disqualifiedFromDriving) as SelectQuestion<string, string>
    const drivingBanLength = questions.find(q => q.name === QuestionEnum.drivingBanLength) as NumberQuestion

    convictionPointsReceived.isVisible = () => penaltyPointsGiven.value === DLDCommonValuesEnum.Boolean.Yes
    fineAmount.isVisible = () => fineGiven.value === DLDCommonValuesEnum.Boolean.Yes
    drivingBanLength.isVisible = () => disqualifiedFromDriving.value === DLDCommonValuesEnum.Boolean.Yes
    drivingBanLength.max = 120
    return page
  }

  private setupClaimsDependencies(page: Page): Page {
    const questions = page.sections.flatMap(s => s.questions)
    const claimIncidentType = questions.find(q => q.name === QuestionEnum.claimIncidentType) as SelectQuestion<string, string>
    const accidentClaimType = questions.find(q => q.name === QuestionEnum.accidentClaimType) as SelectQuestion<string, string>
    const theftClaimType = questions.find(q => q.name === QuestionEnum.theftClaimType) as SelectQuestion<string, string>
    const otherClaimType = questions.find(q => q.name === QuestionEnum.otherClaimType) as SelectQuestion<string, string>

    accidentClaimType.isVisible = () => claimIncidentType.value === DLDCommonValuesEnum.ClaimIncidentType.Accident
    theftClaimType.isVisible = () => claimIncidentType.value === DLDCommonValuesEnum.ClaimIncidentType.Theft
    otherClaimType.isVisible = () => claimIncidentType.value === DLDCommonValuesEnum.ClaimIncidentType.Other

    return page
  }

  private setupTouringCaravanVehicleDetailsDependencies(page: Page): Page {
    const questions = page.sections.flatMap(s => s.questions)
    const workingAlarm = questions.find(q => q.name === QuestionEnum.workingAlarm) as SelectQuestion<string, string>
    const vehicleAlarmType = questions.find(q => q.name === QuestionEnum.vehicleAlarmType) as SelectQuestion<string, string>
    const hasTracker = questions.find(q => q.name === QuestionEnum.hasTracker) as SelectQuestion<string, string>
    const caravansecurityTracker = questions.find(q => q.name === QuestionEnum.caravansecurityTracker) as SelectQuestion<string, string>
    const caravanModels = questions.find(q => q.name === QuestionEnum.caravanModels) as SelectQuestion<string, string>
    const caravanMakes = questions.find(q => q.name === QuestionEnum.caravanMakes) as SelectQuestion<string, string>
    const vehiclePurchaseDate = questions.find(q => q.name === QuestionEnum.vehiclePurchaseDate) as DateQuestion
    const vehicleDeliveryTaken = questions.find(q => q.name === QuestionEnum.vehicleDeliveryTaken) as SelectQuestion<string, string>
    const hasTagging = questions.find(q => q.name === QuestionEnum.hasTagging) as SelectQuestion<string, string>
    const caravanTaggingIdentification = questions.find(q => q.name === QuestionEnum.caravanTaggingIdentification) as SelectQuestion<string, string>
    const hasCaravanSafetyDevices = questions.find(q => q.name === QuestionEnum.hasCaravanSafetyDevices) as SelectQuestion<string, string>
    const caravanSafetyDevices = questions.find(q => q.name === QuestionEnum.caravanSafetyDevices) as SelectQuestion<string, string>
    const yearOfMake = questions.find(q => q.name === QuestionEnum.yearOfMake) as NumberQuestion

    vehicleAlarmType.isVisible = () => workingAlarm.value! === DLDCommonValuesEnum.Boolean.Yes
    caravansecurityTracker.isVisible = () => hasTracker.value! === DLDCommonValuesEnum.Boolean.Yes
    caravanModels.isVisible = () => caravanMakes.value! !== null
    vehiclePurchaseDate.isVisible = () => vehicleDeliveryTaken.value! === DLDCommonValuesEnum.Boolean.Yes
    caravanTaggingIdentification.isVisible = () => hasTagging.value! === DLDCommonValuesEnum.Boolean.Yes
    caravanSafetyDevices.isVisible = () => hasCaravanSafetyDevices.value! === DLDCommonValuesEnum.Boolean.Yes
    vehiclePurchaseDate.maxDate = () => moment().toDate()
    yearOfMake.max = moment().toDate().getFullYear()
    return page
  }

  private setupVehicleDetailsDependencies(page: Page): Page {
    const questions = page.sections.flatMap(s => s.questions)
    const motorhomeTypeQuestion = questions.find(q => q.name === QuestionEnum.motorhomeType) as SelectQuestion<string, string>
    const unknownMotorhomeTypeQuestion = questions.find(q => q.name === QuestionEnum.unknownMotorhomeType) as TextQuestion
    const numberOfHouseholdVehicles = questions.find(q => q.name === QuestionEnum.numberOfHouseholdVehicles) as SelectQuestion<string, string>
    const hasAccessToAnotherVehicle = questions.find(q => q.name === QuestionEnum.hasAccessToAnotherVehicle) as SelectQuestion<string, string>
    const vehicleKeptAtHome = questions.find(q => q.name === QuestionEnum.vehicleKeptAtHome) as SelectQuestion<string, string>
    const vehicleKeptAtAddress = questions.find(q => q.name === QuestionEnum.vehicleAddress) as AddressQuestion

    if (vehicleKeptAtHome)
      vehicleKeptAtAddress.isVisible = () => vehicleKeptAtHome.value! === DLDCommonValuesEnum.Boolean.No

    if (hasAccessToAnotherVehicle)
      numberOfHouseholdVehicles.isVisible = () => hasAccessToAnotherVehicle.value === DLDCommonValuesEnum.Boolean.Yes

    unknownMotorhomeTypeQuestion.isVisible = () => motorhomeTypeQuestion.value === DLDCommonValuesEnum.MotorhomeTypes.Other
    unknownMotorhomeTypeQuestion.maxlength = 50

    return page
  }

  private setupMotorhomeAggregatorLandingDependencies(page: Page): Page {
    const questions = page.sections.flatMap(s => s.questions)

    const titleQuestion = questions.find(q => q.name === QuestionEnum.title)
    const firstNameQuestion = questions.find(q => q.name === QuestionEnum.firstname)
    const lastNameQuestion = questions.find(q => q.name === QuestionEnum.lastName)
    const dateOfBirthQuestion = questions.find(q => q.name === QuestionEnum.dateOfBirth)
    const drivingLicenceQuestion = questions.find(q => q.name === QuestionEnum.drivingLicence) as DrivingLicenceQuestion
    drivingLicenceQuestion.dependentQuestions = [titleQuestion!, firstNameQuestion!, lastNameQuestion!, dateOfBirthQuestion!]

    const vehicleKeptAtHome = questions.find(q => q.name === QuestionEnum.vehicleKeptAtHome) as SelectQuestion<string, string>
    const vehicleKeptAtAddress = questions.find(q => q.name === QuestionEnum.vehicleAddress) as AddressQuestion

    if (vehicleKeptAtHome)
      vehicleKeptAtAddress.isVisible = () => vehicleKeptAtHome.value! === DLDCommonValuesEnum.Boolean.No

    return page
  }

  private setupAdditionalDriverDependencies(page: Page): Page {
    this.setupCommonDependencies(page)
    const questions = page.sections.flatMap(s => s.questions)
    const titleQuestion = questions.find(q => q.name === QuestionEnum.title)
    const firstNameQuestion = questions.find(q => q.name === QuestionEnum.firstname)
    const lastNameQuestion = questions.find(q => q.name === QuestionEnum.lastName)
    const dateOfBirthQuestion = questions.find(q => q.name === QuestionEnum.dateOfBirth)
    const drivingLicenceQuestion = questions.find(q => q.name === QuestionEnum.drivingLicence) as DrivingLicenceQuestion
    drivingLicenceQuestion.dependentQuestions = [titleQuestion!, firstNameQuestion!, lastNameQuestion!, dateOfBirthQuestion!]

    const employmentStatusQuestion = questions.find(q => q.name === QuestionEnum.employmentStatus) as SelectQuestion<string, string>
    const occupationQuestion = questions.find(q => q.name === QuestionEnum.occupation) as SelectQuestion<string, string>
    const businessCategoryQuestion = questions.find(q => q.name === QuestionEnum.businessCategory) as SelectQuestion<string, string>

    const hasMedicalConditionsQuestion = questions.find(q => q.name === QuestionEnum.hasMedicalConditions) as SelectQuestion<string, string>
    const dvlaReportableMedicalConditionsQuestion = questions.find(q => q.name === QuestionEnum.dvlaReportableMedicalConditions) as SelectQuestion<string, string>
    dvlaReportableMedicalConditionsQuestion.isVisible = () => hasMedicalConditionsQuestion.value !== null && hasMedicalConditionsQuestion.value === DLDCommonValuesEnum.Boolean.Yes

    const residentSinceBirthQuestion = questions.find(q => q.name === QuestionEnum.residentSinceBirth) as SelectQuestion<string, string>
    const residentSinceQuestion = questions.find(q => q.name === QuestionEnum.residentSince) as DateQuestion

    residentSinceQuestion.isVisible = () => residentSinceBirthQuestion.value! === DLDCommonValuesEnum.Boolean.No

    occupationQuestion.isVisible = businessCategoryQuestion.isVisible = () => employmentStatusQuestion.value != null && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.Retired && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.Unemployed

    return page
  }

  private setupCoverDetailsDependencies(page: Page): Page {
    const questions = page.sections.flatMap(s => s.questions)

    const gapAdditionalDriver = questions.find(q => q.name === QuestionEnum.additionalDrivers) as SelectQuestion<string, string>
    const gapNeedCoverForAdditionalDriver = questions.find(q => q.name === QuestionEnum.needCoverForAdditionalDriver) as SelectQuestion<string, string>

    if (gapNeedCoverForAdditionalDriver)
      gapNeedCoverForAdditionalDriver.isVisible = () => gapNeedCoverForAdditionalDriver.visible && gapAdditionalDriver.value !== null && gapAdditionalDriver.value === DLDCommonValuesEnum.Boolean.Yes

    const effectiveDateQuestion = questions.find(q => q.name === QuestionEnum.coverEffectiveDate) as DateQuestion ?? questions.find(q => q.name === QuestionEnum.mtaEffectiveDate) as DateQuestion
    effectiveDateQuestion.minDate = () => moment().toDate()
    effectiveDateQuestion.maxDate = () => moment().add(31, 'days').toDate()

    return page
  }

  protected setupCommonDependencies(page: Page): void {
    const questions = page.sections.flatMap(s => s.questions)
    const residentSinceQuestion = questions.find(q => q.name === QuestionEnum.residentSince) as DateQuestion

    residentSinceQuestion.minDate = () => this.setupDOB(questions).value
    residentSinceQuestion.maxDate = () => moment().toDate()
  }

  protected setupDOB(questions: IQuestion[]): DateQuestion {
    const dateOfBirth = questions.find(q => q.name === QuestionEnum.dateOfBirth) as DateQuestion
    dateOfBirth.maxDate = () => moment().add(-17, 'years').toDate()
    dateOfBirth.focusedDate = () => moment(dateOfBirth.maxDate()).subtract(1, 'years').toDate()
    return dateOfBirth
  }

  protected setupDrivingLicence(questions: IQuestion[]): DrivingLicenceQuestion {
    const titleQuestion = questions.find(q => q.name === QuestionEnum.title)
    const firstNameQuestion = questions.find(q => q.name === QuestionEnum.firstname)
    const lastNameQuestion = questions.find(q => q.name === QuestionEnum.lastName)
    const dateOfBirthQuestion = questions.find(q => q.name === QuestionEnum.dateOfBirth)
    const drivingLicenceQuestion = questions.find(q => q.name === QuestionEnum.drivingLicence) as DrivingLicenceQuestion
    drivingLicenceQuestion.dependentQuestions = [titleQuestion!, firstNameQuestion!, lastNameQuestion!, dateOfBirthQuestion!]
    return drivingLicenceQuestion
  }

  private setupGapProposerDependencies(page: Page): Page {
    const questions = page.sections.flatMap(s => s.questions)
    this.setupDOB(questions)
    return page
  }

  private setupStorageLocationComponentDependencies(page: Page): Page {
    const questions = page.sections.flatMap(s => s.questions)
    const storageLocation = questions.find(q => q.name === QuestionEnum.storageLocation) as SelectQuestion<string, string>
    const homeStorageLocation = questions.find(q => q.name === QuestionEnum.homeStorageLocation) as SelectQuestion<string, string>
    const typeOfFarm = questions.find(q => q.name === QuestionEnum.typeOfFarm) as SelectQuestion<string, string>
    const typeOfCaravanPark = questions.find(q => q.name === QuestionEnum.typeOfCaravanPark) as SelectQuestion<string, string>
    const typeOfCompound = questions.find(q => q.name === QuestionEnum.typeOfCompound) as SelectQuestion<string, string>
    const otherLocation = questions.find(q => q.name === QuestionEnum.otherLocation) as SelectQuestion<string, string>
    const vehicleKeptAtPostCode = questions.find(q => q.name === QuestionEnum.vehicleKeptAtPostCode) as SelectQuestion<string, string>
    const whichCaSSOASite = questions.find(q => q.name === QuestionEnum.whichCaSSOASite) as SelectQuestion<string, string>
    const numberOfCaravans = questions.find(q => q.name === QuestionEnum.numberOfCaravans) as SelectQuestion<string, string>
    const numberOfMonthsStored = questions.find(q => q.name === QuestionEnum.numberOfMonthsStored) as SelectQuestion<string, string>
    const hasPerimiterFencing = questions.find(q => q.name === QuestionEnum.hasPerimiterFencing) as SelectQuestion<string, string>
    const hasSecurityGate = questions.find(q => q.name === QuestionEnum.hasSecurityGate) as SelectQuestion<string, string>
    const hasWarden = questions.find(q => q.name === QuestionEnum.hasWarden) as SelectQuestion<string, string>
    const hasCCTV = questions.find(q => q.name === QuestionEnum.hasCCTV) as SelectQuestion<string, string>

    homeStorageLocation.isVisible = () => storageLocation.value! === DLDCommonValuesEnum.CaravanLocationKeptAt.Home
    typeOfFarm.isVisible = () => storageLocation.value! === DLDCommonValuesEnum.CaravanLocationKeptAt.Farm
    typeOfCaravanPark.isVisible = () => storageLocation.value! === DLDCommonValuesEnum.CaravanLocationKeptAt.CaravanPark
    typeOfCompound.isVisible = () => storageLocation.value! === DLDCommonValuesEnum.CaravanLocationKeptAt.Storage
    otherLocation.isVisible = () => storageLocation.value! === DLDCommonValuesEnum.CaravanLocationKeptAt.Other
    whichCaSSOASite.isVisible = () => typeOfCompound.value! === DLDCommonValuesEnum.CaravanStorage.CaSSOA && storageLocation.visible
    vehicleKeptAtPostCode.isVisible = () => storageLocation.value! !== null && storageLocation.value! !== DLDCommonValuesEnum.CaravanLocationKeptAt.Home && typeOfCompound.value! !== DLDCommonValuesEnum.CaravanStorage.CaSSOA
    hasSecurityGate.isVisible = () => storageLocation.value! !== null
    hasCCTV.isVisible = () => storageLocation.value! !== null

    numberOfCaravans.isVisible = numberOfMonthsStored.isVisible = hasPerimiterFencing.isVisible = hasWarden.isVisible = () => storageLocation.value! !== null && storageLocation.value! !== DLDCommonValuesEnum.CaravanLocationKeptAt.Home
    // eslint-enable-next-line no-multi-assign

    return page
  }

  private setupProposerDependencies(page: Page): Page {
    this.setupCommonDependencies(page)
    const questions = page.sections.flatMap(s => s.questions)

    const hasMedicalConditionsQuestion = questions.find(q => q.name === QuestionEnum.hasMedicalConditions) as SelectQuestion<string, string>
    const dvlaReportableMedicalConditionsQuestion = questions.find(q => q.name === QuestionEnum.dvlaReportableMedicalConditions) as SelectQuestion<string, string>

    dvlaReportableMedicalConditionsQuestion.isVisible = () => hasMedicalConditionsQuestion.value !== null && hasMedicalConditionsQuestion.value === DLDCommonValuesEnum.Boolean.Yes

    const employmentStatusQuestion = questions.find(q => q.name === QuestionEnum.employmentStatus) as SelectQuestion<string, string>
    const occupationQuestion = questions.find(q => q.name === QuestionEnum.occupation) as SelectQuestion<string, string>
    const businessCategoryQuestion = questions.find(q => q.name === QuestionEnum.businessCategory) as SelectQuestion<string, string>

    occupationQuestion.isVisible = businessCategoryQuestion.isVisible = () => employmentStatusQuestion.value != null
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.Retired
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.Unemployed
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.FullOrPartTimeEducation
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.HouseholdDuties
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.IndependentMeans
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.NotEmployedDueToDisability
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.FinanciallyAssisted

    const titleQuestion = questions.find(q => q.name === QuestionEnum.title)
    const firstNameQuestion = questions.find(q => q.name === QuestionEnum.firstname)
    const lastNameQuestion = questions.find(q => q.name === QuestionEnum.lastName)
    const dateOfBirthQuestion = questions.find(q => q.name === QuestionEnum.dateOfBirth)
    const drivingLicenceQuestion = questions.find(q => q.name === QuestionEnum.drivingLicence) as DrivingLicenceQuestion
    drivingLicenceQuestion.dependentQuestions = [titleQuestion!, firstNameQuestion!, lastNameQuestion!, dateOfBirthQuestion!]

    const hasAdvancedLicenceQuestion = questions.find(q => q.name === QuestionEnum.hasAdvancedDrivingLicence) as SelectQuestion<string, string>
    const advancedDrivingLicenceTypeQuestion = questions.find(q => q.name === QuestionEnum.advancedDrivingLicenceType) as SelectQuestion<string, string>

    advancedDrivingLicenceTypeQuestion.isVisible = () => hasAdvancedLicenceQuestion.value != null && hasAdvancedLicenceQuestion.value === DLDCommonValuesEnum.Boolean.Yes

    const isClubMemberQuestion = questions.find(q => q.name === QuestionEnum.isClubMember) as SelectQuestion<string, string>
    const clubNameQuestion = questions.find(q => q.name === QuestionEnum.clubName) as SelectQuestion<string, string>

    clubNameQuestion.isVisible = () => isClubMemberQuestion.value != null && isClubMemberQuestion.value === DLDCommonValuesEnum.Boolean.Yes

    const residentSinceBirthQuestion = questions.find(q => q.name === QuestionEnum.residentSinceBirth) as SelectQuestion<string, string>
    const residentSinceQuestion = questions.find(q => q.name === QuestionEnum.residentSince) as DateQuestion

    residentSinceQuestion.isVisible = () => residentSinceBirthQuestion.value! === DLDCommonValuesEnum.Boolean.No

    return page
  }

  private setupModernCarVehicleDependencies(page: Page): Page {
    const questions = page.sections.flatMap(s => s.questions)

    const effectiveDateQuestion = questions.find(q => q.name === QuestionEnum.coverEffectiveDate) as DateQuestion
    effectiveDateQuestion.minDate = () => moment().add(20, 'minute').toDate()
    effectiveDateQuestion.maxDate = () => moment().add(31, 'days').toDate()
    effectiveDateQuestion.isDateTime = () => true

    const coverDurationType = questions.find(q => q.name === QuestionEnum.coverDurationType) as SelectQuestion<string, string>
    const relatedCoverDuration = questions.find(q => q.name === QuestionEnum.relatedCoverDuration) as SelectQuestion<string, string>
    const coverDuration = questions.find(q => q.name === QuestionEnum.coverDuration) as SelectQuestion<string, string>

    relatedCoverDuration.isVisible = () => coverDurationType.value !== null
    relatedCoverDuration.dependentQuestions = [coverDuration]

    coverDuration.isVisible = () => relatedCoverDuration.value === DLDCommonValuesEnum.RelatedRadioListEnum.OtherValue

    return page
  }

  private setupModernCarPremiumDependencies(page: Page): Page {
    const questions = page.sections.flatMap(s => s.questions)

    const effectiveDateQuestion = questions.find(q => q.name === QuestionEnum.coverEffectiveDate) as DateQuestion
    effectiveDateQuestion.minDate = () => moment().add(20, 'minute').toDate()
    effectiveDateQuestion.maxDate = () => moment().add(31, 'days').toDate()
    effectiveDateQuestion.isDateTime = () => true
    return page
  }

  private setupModernCarProposerDependencies(page: Page): Page {
    const questions = page.sections.flatMap(s => s.questions)
    this.setupDOB(questions)
    this.setupDrivingLicence(questions)
    return page
  }

  private setupModernCarAdditionalDriverDependencies(page: Page): Page {
    const questions = page.sections.flatMap(s => s.questions)
    this.setupDOB(questions)
    this.setupDrivingLicence(questions)
    return page
  }

  getSchemeRequest(_questions: IQuestionAnswer[]): SchemeRequest {
    throw new Error('Method not implemented. Wrapper does not use Scheme Requests.')
  }

  validate(_questions: IQuestionAnswer[]): boolean {
    throw new Error('Method not implemented. Wrapper does not use custom validation.')
  }

  buildGapRequest(quoteRequest: GapMTAQuoteRequest | GapQuoteRequest, questions: IQuestionAnswer[]): void {
    // Personal details
    const title = questions.find(q => q.name === QuestionEnum.title) as QuestionAnswer<string>
    const firstName = questions.find(q => q.name === QuestionEnum.firstname) as QuestionAnswer<string>
    const lastName = questions.find(q => q.name === QuestionEnum.lastName) as QuestionAnswer<string>
    const dateOfBirth = questions.find(q => q.name === QuestionEnum.dateOfBirth) as QuestionAnswer<Date>
    const address = questions.find(q => q.name === QuestionEnum.address) as QuestionAnswer<Address>
    const effectiveDateQuestion = questions.find(q => q.name === QuestionEnum.coverEffectiveDate || q.name === QuestionEnum.mtaEffectiveDate) as QuestionAnswer<Date>
    // Vehicle details
    const currentMileage = questions.find(q => q.name === QuestionEnum.currentMileage) as QuestionAnswer<number>
    const invoiceValue = questions.find(q => q.name === QuestionEnum.purchasePrice) as QuestionAnswer<number>
    const vehicleType = questions.find(q => q.name === QuestionEnum.gapVehicleType) as QuestionAnswer<string>
    const gapDamagedBeyondRepair = questions.find(q => q.name === QuestionEnum.gapDamagedBeyondRepair) as QuestionAnswer<string>
    const paymentQuestion = questions.find(q => q.name === QuestionEnum.gapVehiclePaymentType) as QuestionAnswer<string>
    const vehiclePurchaseFrom = questions.find(q => q.name === QuestionEnum.gapVehiclePurchaseType) as QuestionAnswer<string>
    const vehicleCollectionDate = questions.find(q => q.name === QuestionEnum.vehicleCollectionDate) as QuestionAnswer<Date>
    const vehicleDeliveryTaken = questions.find(q => q.name === QuestionEnum.vehicleDeliveryTaken) as QuestionAnswer<string>
    const confirmRegisteredKeeper = questions.find(q => q.name === QuestionEnum.confirmRegisteredKeeper) as QuestionAnswer<string>
    const confirmCommercialType = questions.find(q => q.name === QuestionEnum.confirmCommercialType) as QuestionAnswer<string>
    // About your cover
    const confirmFullyComp = questions.find(q => q.name === QuestionEnum.confirmFullyComp) as QuestionAnswer<string>
    const noClaimsBonus = questions.find(q => q.name === QuestionEnum.noClaimsBonus) as QuestionAnswer<string>
    const coverTerm = questions.find(q => q.name === QuestionEnum.coverTerm) as QuestionAnswer<string>
    const needCoverForAdditionalDriver = questions.find(q => q.name === QuestionEnum.needCoverForAdditionalDriver) as QuestionAnswer<string>
    const includeCoverForVehicleStolen = questions.find(q => q.name === QuestionEnum.includeCoverForVehicleStolen) as QuestionAnswer<string>
    const additionalDrivers = questions.find(q => q.name === QuestionEnum.additionalDrivers) as QuestionAnswer<string>
    const includeCoverForAddedExtra = questions.find(q => q.name === QuestionEnum.includeCoverForAddedExtra) as QuestionAnswer<string>

    quoteRequest.address = address.value!
    quoteRequest.car = this.bindCar(questions, false)
    quoteRequest.proposer.title = title.value!
    quoteRequest.proposer.firstName = firstName.value!
    quoteRequest.proposer.lastName = lastName.value!
    quoteRequest.proposer.dateOfBirth = dateOfBirth.value!
    quoteRequest.effectiveDate = effectiveDateQuestion.value!
    quoteRequest.currentMileage = currentMileage.value!
    quoteRequest.invoiceValue = invoiceValue.value!
    quoteRequest.gapVehicleType = vehicleType.value!
    quoteRequest.vehicleCollectionDate = vehicleDeliveryTaken.value! === DLDCommonValuesEnum.Boolean.Yes ? vehicleCollectionDate.value! : new Date()
    quoteRequest.vehiclePurchaseDate = vehicleCollectionDate.value!
    quoteRequest.vehiclePaymentType = paymentQuestion.value!
    quoteRequest.vehiclePurchaseFrom = vehiclePurchaseFrom.value!
    quoteRequest.noClaimsBonus = noClaimsBonus.value != null ? this.getNoClaimsYears(noClaimsBonus.value!) : null
    quoteRequest.coverTerm = coverTerm.value!
    quoteRequest.gapDamagedBeyondRepair = gapDamagedBeyondRepair.value! === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.confirmCommercialType = confirmCommercialType.value!
    quoteRequest.confirmRegisteredKeeper = confirmRegisteredKeeper.value!
    quoteRequest.includeCoverForAddedExtra = includeCoverForAddedExtra.value! === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.additionalDrivers = additionalDrivers.value! === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.needCoverForAdditionalDriver = additionalDrivers.value! === DLDCommonValuesEnum.Boolean.Yes ? (needCoverForAdditionalDriver.value! === DLDCommonValuesEnum.Boolean.Yes) : null
    quoteRequest.confirmFullyComp = confirmFullyComp.value!
    quoteRequest.includeCoverForVehicleStolen = includeCoverForVehicleStolen.value! === DLDCommonValuesEnum.Boolean.Yes
  }

  buildTouringCaravanRequest(quoteRequest: TouringCaravanQuoteRequest | TouringCaravanMTAQuoteRequest, questions: IQuestionAnswer[]): void {
    const touringCaravan = new TouringCaravanRequest()
    const touringCaravanClaims: TouringCaravanClaimRequest[] = []
    const touringCaravanConvictions: TouringCaravanConvictionRequest[] = []
    const touringCaravanProposer = new TouringCaravanProposerRequest()
    const touringCaravanStorageLocations: TouringCaravanStorageLocationRequest[] = []

    const awningsValue = questions.find(q => q.name === QuestionEnum.awningsPrice) as QuestionAnswer<number>
    const equipmentValue = questions.find(q => q.name === QuestionEnum.equipmentPrice) as QuestionAnswer<number>
    const personalEffectsValue = questions.find(q => q.name === QuestionEnum.personalEffectsPrice) as QuestionAnswer<number>
    const contentsValue = questions.find(q => q.name === QuestionEnum.contentsPrice) as QuestionAnswer<number>
    const caravanType = questions.find(q => q.name === QuestionEnum.caravanType) as QuestionAnswer<string>
    const alarmType = questions.find(q => q.name === QuestionEnum.vehicleAlarmType) as QuestionAnswer<string>
    const make = questions.find(q => q.name === QuestionEnum.caravanMakes) as QuestionAnswer<string>
    const model = questions.find(q => q.name === QuestionEnum.caravanModels) as QuestionAnswer<string>
    const yearOfMake = questions.find(q => q.name === QuestionEnum.yearOfMake) as QuestionAnswer<number>
    const purchaseDate = questions.find(q => q.name === QuestionEnum.vehiclePurchaseDate) as QuestionAnswer<Date>
    const caravanUssage = questions.find(q => q.name === QuestionEnum.caravanUsage) as QuestionAnswer<string>
    const isCRISRegistered = questions.find(q => q.name === QuestionEnum.registeredWithCRiS) as QuestionAnswer<string>
    const inGoodCondition = questions.find(q => q.name === QuestionEnum.goodStateOfRepair) as QuestionAnswer<string>
    const servicedInLastTwoYears = questions.find(q => q.name === QuestionEnum.servicedEveryTwoYears) as QuestionAnswer<string>
    const trackerType = questions.find(q => q.name === QuestionEnum.caravansecurityTracker) as QuestionAnswer<string>
    const purchasePrice = questions.find(q => q.name === QuestionEnum.purchasePrice) as QuestionAnswer<number>
    const marketPrice = questions.find(q => q.name === QuestionEnum.marketPrice) as QuestionAnswer<number>
    const towingFrequency = questions.find(q => q.name === QuestionEnum.towingFrequency) as QuestionAnswer<string>
    const basisOfCover = questions.find(q => q.name === QuestionEnum.basisOfCover) as QuestionAnswer<string>
    const effectiveDateQuestion = questions.find(q => q.name === QuestionEnum.coverEffectiveDate || q.name === QuestionEnum.mtaEffectiveDate) as QuestionAnswer<Date>
    const title = questions.find(q => q.name === QuestionEnum.title) as QuestionAnswer<string>
    const firstName = questions.find(q => q.name === QuestionEnum.firstname) as QuestionAnswer<string>
    const lastName = questions.find(q => q.name === QuestionEnum.lastName) as QuestionAnswer<string>
    const dateOfBirth = questions.find(q => q.name === QuestionEnum.dateOfBirth) as QuestionAnswer<Date>
    const residentSince = questions.find(q => q.name === QuestionEnum.ukResident) as QuestionAnswer<Date>
    const address = questions.find(q => q.name === QuestionEnum.address) as QuestionAnswer<Address>
    const noClaimsBonus = questions.find(q => q.name === QuestionEnum.noClaimsBonus) as QuestionAnswer<string>
    const noClaimsProtection = questions.find(q => q.name === QuestionEnum.noClaimsProtection) as QuestionAnswer<string>
    const otherNoClaimsBonus = questions.find(q => q.name === QuestionEnum.otherNoClaimsBonus) as QuestionAnswer<string>
    const martialStatus = questions.find(q => q.name === QuestionEnum.maritalStatus) as QuestionAnswer<string>
    const licenceHeldYears = questions.find(q => q.name === QuestionEnum.licenceHeldYears) as QuestionAnswer<string>
    const manouveringCourse = questions.find(q => q.name === QuestionEnum.manoeuvringCourse) as QuestionAnswer<string>
    const clubName = questions.find(q => q.name === QuestionEnum.touringClubName) as QuestionAnswer<string>
    const employmentStatus = questions.find(q => q.name === QuestionEnum.employmentStatus) as QuestionAnswer<string>
    const occupation = questions.find(q => q.name === QuestionEnum.occupation) as QuestionAnswer<string>
    const businessType = questions.find(q => q.name === QuestionEnum.businessCategory) as QuestionAnswer<string>
    const hasSecondaryEmployment = questions.find(q => q.name === QuestionEnum.hasSecondaryEmployment) as QuestionAnswer<string>
    const secondaryOccupation = questions.find(q => q.name === QuestionEnum.secondaryOccupation) as QuestionAnswer<string>
    const secondaryBusinessType = questions.find(q => q.name === QuestionEnum.secondaryBusinessCategory) as QuestionAnswer<string>
    const hadInsuranceRefused = questions.find(q => q.name === QuestionEnum.hadInsuranceRefused) as QuestionAnswer<string>
    const hasHadPreviousPolicyCancelledTermsApplied = questions.find(q => q.name === QuestionEnum.beenRequestedExtraPrecautions) as QuestionAnswer<string>
    const unspentConvictions = questions.find(q => q.name === QuestionEnum.unspentConvictions) as QuestionAnswer<string>
    const storageLocations = questions.find(q => q.name === QuestionEnum.storageLocations) as QuestionAnswer<number>
    const residentSinceBirth = questions.find(q => q.name === QuestionEnum.residentSinceBirth) as QuestionAnswer<string>
    const voluntaryExcess = questions.find(q => q.name === QuestionEnum.voluntaryExcess) as QuestionAnswer<string>
    const caravanSecurity = questions.find(q => q.name === QuestionEnum.caravanSecurity) as QuestionAnswer<string[]>
    const caravanTagging = questions.find(q => q.name === QuestionEnum.caravanTaggingIdentification) as QuestionAnswer<string[]>
    const caravanSafetyDevices = questions.find(q => q.name === QuestionEnum.caravanSafetyDevices) as QuestionAnswer<string[]>
    const claims = questions.find(q => q.name === QuestionEnum.claims) as QuestionAnswer<number>
    const convictions = questions.find(q => q.name === QuestionEnum.convictions) as QuestionAnswer<number>

    if (employmentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.Retired) {
      if (occupation)
        occupation.value = DLDCommonValuesEnum.Occupation.Retired
      if (businessType)
        businessType.value = DLDCommonValuesEnum.BusinessCategory.NonRetired
    }
    else if (employmentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.Unemployed) {
      if (occupation)
        occupation.value = DLDCommonValuesEnum.Occupation.Unemployed
      if (businessType)
        businessType.value = DLDCommonValuesEnum.BusinessCategory.NonUnemployed
    }
    else if (employmentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.FullOrPartTimeEducation) {
      if (occupation)
        occupation.value = DLDCommonValuesEnum.Occupation.Student
      if (businessType)
        businessType.value = DLDCommonValuesEnum.BusinessCategory.NoneStudent
    }
    else if (employmentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.FinanciallyAssisted) {
      if (occupation)
        occupation.value = DLDCommonValuesEnum.Occupation.NotEmployedDueToDisability
      if (businessType)
        businessType.value = DLDCommonValuesEnum.BusinessCategory.NoneNotEmployedduetoDisability
    }
    else if (employmentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.HouseholdDuties) {
      if (occupation)
        occupation.value = DLDCommonValuesEnum.Occupation.Housewife
      if (businessType)
        businessType.value = DLDCommonValuesEnum.BusinessCategory.NoneHouseholdDuties
    }
    else if (employmentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.IndependentMeans) {
      if (occupation)
        occupation.value = DLDCommonValuesEnum.Occupation.NotInEmployment
      if (businessType)
        businessType.value = DLDCommonValuesEnum.BusinessCategory.NotInEmployment
    }
    else if (employmentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.NotEmployedDueToDisability) {
      if (occupation)
        occupation.value = DLDCommonValuesEnum.Occupation.NotEmployedDueToDisability
      if (businessType)
        businessType.value = DLDCommonValuesEnum.BusinessCategory.NoneNotEmployedduetoDisability
    }

    //  Risk base

    quoteRequest.awningsValue = awningsValue.value!
    quoteRequest.equipmentValue = equipmentValue.value!
    quoteRequest.personalEffectsValue = personalEffectsValue.value!
    quoteRequest.contentsValue = contentsValue.value!
    quoteRequest.basisOfCover = !basisOfCover.visible ? DLDCommonValuesEnum.BasisOfCover.MarketValue : basisOfCover.value!
    quoteRequest.effectiveDate = effectiveDateQuestion.value!
    quoteRequest.voluntaryExcess = voluntaryExcess.value!
    quoteRequest.address = address.value!

    // Personal details
    touringCaravanProposer.title = title.value!
    touringCaravanProposer.firstName = firstName.value!
    touringCaravanProposer.lastName = lastName.value!
    touringCaravanProposer.dateOfBirth = dateOfBirth.value!
    touringCaravanProposer.livedInUKSince = residentSinceBirth.value === DLDCommonValuesEnum.Boolean.Yes ? dateOfBirth.value! : residentSince.value
    touringCaravanProposer.noClaimsProtection = noClaimsProtection.value! === DLDCommonValuesEnum.Boolean.Yes
    touringCaravanProposer.noClaimsBonus = this.getNoClaimsYears(noClaimsBonus.value!)
    touringCaravanProposer.caravanNoClaimsBonus = this.getNoClaimsYears(otherNoClaimsBonus.value!)
    touringCaravanProposer.maritalStatus = martialStatus.value!
    touringCaravanProposer.licenceHeldYears = licenceHeldYears.value!
    touringCaravanProposer.manouveringCourse = manouveringCourse.value!
    touringCaravanProposer.clubMembership = clubName.value!
    touringCaravanProposer.employmentStatus = employmentStatus.value!
    touringCaravanProposer.occupation = occupation.value!
    touringCaravanProposer.businessType = businessType.value!
    touringCaravanProposer.secondaryEmploymentStatus = hasSecondaryEmployment.value!
    touringCaravanProposer.secondaryOccupation = secondaryOccupation.value!
    touringCaravanProposer.secondaryBusinessType = secondaryBusinessType.value!
    touringCaravanProposer.hasClaims = claims && claims.subPageAnswers.length > 0
    touringCaravanProposer.hasConvictions = convictions && convictions.subPageAnswers.length > 0
    touringCaravanProposer.hadInsuranceRefused = hadInsuranceRefused.value === DLDCommonValuesEnum.Boolean.Yes
    touringCaravanProposer.hasHadPreviousPolicyCancelledTermsApplied = hasHadPreviousPolicyCancelledTermsApplied.value! === DLDCommonValuesEnum.Boolean.Yes
    touringCaravanProposer.hasCriminalConvictions = unspentConvictions.value! === DLDCommonValuesEnum.Boolean.Yes

    //  touring caravan
    touringCaravan.caravanType = caravanType.value
    touringCaravan.make = make.value
    touringCaravan.model = model.value
    touringCaravan.yearOfMake = yearOfMake.value
    touringCaravan.purchaseDate = purchaseDate.value
    touringCaravan.caravanUssage = caravanUssage.value
    touringCaravan.isCRISRegistered = isCRISRegistered.value === DLDCommonValuesEnum.Boolean.Yes
    touringCaravan.alarmType = alarmType.value
    touringCaravan.trackerType = trackerType.value
    touringCaravan.caravanSecurity = caravanSecurity.value
    touringCaravan.caravanTagging = caravanTagging.value
    touringCaravan.caravanSafety = caravanSafetyDevices.value
    touringCaravan.inGoodCondition = inGoodCondition.value === DLDCommonValuesEnum.Boolean.Yes
    touringCaravan.servicedInLastTwoYears = servicedInLastTwoYears.value === DLDCommonValuesEnum.Boolean.Yes
    touringCaravan.isPermanantlySited = false
    touringCaravan.purchasePrice = purchasePrice.value
    touringCaravan.currentValue = marketPrice.value
    touringCaravan.towingFrequency = towingFrequency.value

    if (claims) {
      for (let i = 0; i < claims.subPageAnswers.length; i += 1) {
        const accidentClaimType = claims.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.accidentClaimType) as QuestionAnswer<string>
        const theftClaimType = claims.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.theftClaimType) as QuestionAnswer<string>
        const otherClaimType = claims.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.otherClaimType) as QuestionAnswer<string>
        const claimDate = claims.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.claimDate) as QuestionAnswer<Date>
        const claimCost = claims.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.claimCost) as QuestionAnswer<number>

        touringCaravanClaims.push({
          claimType: accidentClaimType.value || theftClaimType.value || otherClaimType.value || null,
          claimDate: claimDate.value,
          claimCost: claimCost.value,
        })
      }
    }

    // Conviction
    if (convictions) {
      for (let i = 0; i < convictions.subPageAnswers.length; i += 1) {
        const convictionType = convictions.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.convictionType) as QuestionAnswer<string>
        const convictionDate = convictions.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.convictionDate) as QuestionAnswer<Date>
        const drivingBanLength = convictions.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.drivingBanLength) as QuestionAnswer<number>
        const fineAmount = convictions.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.fineAmount) as QuestionAnswer<number>
        const pointsReceived = convictions.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.convictionPointsReceived) as QuestionAnswer<number>

        touringCaravanConvictions.push({
          convictionCode: convictionType.value,
          convictionDate: convictionDate.value,
          disqualificationMonths: drivingBanLength.value,
          fine: fineAmount.value,
          pointsReceived: pointsReceived.value,
        })
      }
    }

    // Storage locations
    if (storageLocations) {
      for (let i = 0; i < storageLocations.subPageAnswers.length; i += 1) {
        const storageLocation = storageLocations.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.storageLocation) as QuestionAnswer<string>
        const homeStorageLocation = storageLocations.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.homeStorageLocation) as QuestionAnswer<string>
        const typeOfFarm = storageLocations.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.typeOfFarm) as QuestionAnswer<string>
        const typeOfCaravanPark = storageLocations.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.typeOfCaravanPark) as QuestionAnswer<string>
        const typeOfCompound = storageLocations.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.typeOfCompound) as QuestionAnswer<string>
        const otherLocation = storageLocations.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.otherLocation) as QuestionAnswer<string>
        const vehicleKeptAtPostCode = storageLocations.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.vehicleKeptAtPostCode) as QuestionAnswer<string>
        const whichCaSSOASite = storageLocations.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.whichCaSSOASite) as QuestionAnswer<string>
        const numberOfCaravans = storageLocations.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.numberOfCaravans) as QuestionAnswer<string>
        const monthsAtStorageLocation = storageLocations.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.numberOfMonthsStored) as QuestionAnswer<string>
        const secureFencing = storageLocations.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.hasPerimiterFencing) as QuestionAnswer<string>
        const securityGates = storageLocations.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.hasSecurityGate) as QuestionAnswer<string>
        const fullTimeWarden = storageLocations.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.hasWarden) as QuestionAnswer<string>
        const hasCCTV = storageLocations.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.hasCCTV) as QuestionAnswer<string>

        touringCaravanStorageLocations.push({
          isPrimary: i === 0,
          storageLocationType: storageLocation.value!,

          storageLocationDetail: storageLocation.value! === DLDCommonValuesEnum.CaravanLocationKeptAt.Home
            ? homeStorageLocation.value!
            : storageLocation.value! === DLDCommonValuesEnum.CaravanLocationKeptAt.Farm
              ? typeOfFarm.value!
              : storageLocation.value! === DLDCommonValuesEnum.CaravanLocationKeptAt.CaravanPark
                ? typeOfCaravanPark.value!
                : storageLocation.value! === DLDCommonValuesEnum.CaravanLocationKeptAt.Storage
                  ? typeOfCompound.value!
                  : storageLocation.value! === DLDCommonValuesEnum.CaravanLocationKeptAt.Other ? otherLocation.value! : DLDCommonValuesEnum.CaravanStorage.Other,

          storageLocationPostcode: vehicleKeptAtPostCode.value!,
          cassoaSite: typeOfCompound.value! === DLDCommonValuesEnum.CaravanStorage.CaSSOA ? whichCaSSOASite.value! : null,
          caravansOnSite: storageLocation.value! === DLDCommonValuesEnum.CaravanLocationKeptAt.Home ? null : numberOfCaravans.value!,
          monthsAtStorageLocation: storageLocation.value! === DLDCommonValuesEnum.CaravanLocationKeptAt.Home ? null : monthsAtStorageLocation.value!,
          secureFencing: secureFencing.value! === DLDCommonValuesEnum.Boolean.Yes,
          securityGates: securityGates.value! === DLDCommonValuesEnum.Boolean.Yes,
          fullTimeWarden: fullTimeWarden.value! === DLDCommonValuesEnum.Boolean.Yes,
          hasCCTV: hasCCTV.value! === DLDCommonValuesEnum.Boolean.Yes,
        })
      }
    }

    quoteRequest.touringCaravan = touringCaravan
    quoteRequest.touringCaravanClaims = touringCaravanClaims
    quoteRequest.touringCaravanConvictions = touringCaravanConvictions
    quoteRequest.TouringCaravanProposer = touringCaravanProposer
    quoteRequest.touringCaravanStorageLocations = touringCaravanStorageLocations
  }

  buildCarRequest(quoteRequest: CarMTAQuoteRequest | CarQuoteRequest, questions: IQuestionAnswer[]): void {
    // Vehicle details
    const vehicleEstimatedMileage = questions.find(q => q.name === QuestionEnum.vehicleEstimatedMileage) as QuestionAnswer<number>
    const vehicleBusinessMileage = questions.find(q => q.name === QuestionEnum.vehicleBusinessMileage) as QuestionAnswer<number>

    // Personal details
    const title = questions.find(q => q.name === QuestionEnum.title) as QuestionAnswer<string>
    const firstName = questions.find(q => q.name === QuestionEnum.firstname) as QuestionAnswer<string>
    const lastName = questions.find(q => q.name === QuestionEnum.lastName) as QuestionAnswer<string>
    const dateOfBirth = questions.find(q => q.name === QuestionEnum.dateOfBirth) as QuestionAnswer<Date>
    const residentSince = questions.find(q => q.name === QuestionEnum.residentSince) as QuestionAnswer<Date>
    const residentSinceBirth = questions.find(q => q.name === QuestionEnum.residentSinceBirth) as QuestionAnswer<string>
    const drivingLicence = questions.find(q => q.name === QuestionEnum.drivingLicence) as QuestionAnswer<string>
    const address = questions.find(q => q.name === QuestionEnum.address) as QuestionAnswer<Address>
    const employmentStatus = questions.find(q => q.name === QuestionEnum.employmentStatus) as QuestionAnswer<string>
    const occupation = questions.find(q => q.name === QuestionEnum.occupation) as QuestionAnswer<string>
    const businessCategory = questions.find(q => q.name === QuestionEnum.businessCategory) as QuestionAnswer<string>
    const noClaimsProtection = questions.find(q => q.name === QuestionEnum.noClaimsProtection) as QuestionAnswer<string>
    const hasAdvancedDrivingLicence = questions.find(q => q.name === QuestionEnum.hasAdvancedDrivingLicence) as QuestionAnswer<string>
    const advancedDrivingLicenceType = questions.find(q => q.name === QuestionEnum.advancedDrivingLicenceType) as QuestionAnswer<string>
    const noClaimsBonus = questions.find(q => q.name === QuestionEnum.noClaimsBonus) as QuestionAnswer<string>
    const isOwnersClubMemeber = questions.find(q => q.name === QuestionEnum.isClubMember) as QuestionAnswer<string>
    const club = questions.find(q => q.name === QuestionEnum.clubName) as QuestionAnswer<string>
    const maritalStatus = questions.find(q => q.name === QuestionEnum.maritalStatus) as QuestionAnswer<string>
    const hasMedicalConditionsQuestion = questions.find(q => q.name === QuestionEnum.hasMedicalConditions) as QuestionAnswer<string>
    const dvlaReportableMedicalConditionsQuestion = questions.find(q => q.name === QuestionEnum.dvlaReportableMedicalConditions) as QuestionAnswer<string>
    const numberOfOtherVehiclesAvailable = questions.find(q => q.name === QuestionEnum.numberOfOtherVehiclesAvailable) as QuestionAnswer<string>
    const drivingOtherCarsOnOtherPolicy = questions.find(q => q.name === QuestionEnum.hasAccessToAnotherVehicle) as QuestionAnswer<string>
    const numberOfHouseholdVehicles = questions.find(q => q.name === QuestionEnum.numberOfHouseholdVehicles) as QuestionAnswer<number>
    const dvlaMotorhome = questions.find(q => q.name === QuestionEnum.dvlaMotorhome) as QuestionAnswer<string>
    const conversionCompleted = questions.find(q => q.name === QuestionEnum.conversionCompleted) as QuestionAnswer<string>
    const modifiedEngineDriveTrain = questions.find(q => q.name === QuestionEnum.modifiedEngineDriveTrain) as QuestionAnswer<string>
    const beenRequestedExtraPrecautions = questions.find(q => q.name === QuestionEnum.beenRequestedExtraPrecautions) as QuestionAnswer<string>
    const mainUser = questions.find(q => q.name === QuestionEnum.mainUser) as QuestionAnswer<string>
    const primaryTransport = questions.find(q => q.name === QuestionEnum.primaryTransport) as QuestionAnswer<string>
    const liveInVehicleFullTime = questions.find(q => q.name === QuestionEnum.liveInVehicleFullTime) as QuestionAnswer<string>
    const hadInsuranceRefused = questions.find(q => q.name === QuestionEnum.hadInsuranceRefused) as QuestionAnswer<string>
    const hasConvictions = questions.find(q => q.name === QuestionEnum.hasClaims) as QuestionAnswer<string>
    const voluntaryExcess = questions.find(q => q.name === QuestionEnum.voluntaryExcess) as QuestionAnswer<string>
    const reasonForCover = questions.find(q => q.name === QuestionEnum.reasonForCover) as QuestionAnswer<string>
    const coverDuration = questions.find(q => q.name === QuestionEnum.coverDuration) as QuestionAnswer<string>
    const relatedCoverDuration = questions.find(q => q.name === QuestionEnum.relatedCoverDuration) as QuestionAnswer<string>
    const licenceType = questions.find(q => q.name === QuestionEnum.licenceType) as QuestionAnswer<string>

    if (employmentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.Retired) {
      if (occupation)
        occupation.value = DLDCommonValuesEnum.Occupation.Retired
      if (businessCategory)
        businessCategory.value = DLDCommonValuesEnum.BusinessCategory.NonRetired
    }
    else if (employmentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.Unemployed) {
      if (occupation)
        occupation.value = DLDCommonValuesEnum.Occupation.Unemployed
      if (businessCategory)
        businessCategory.value = DLDCommonValuesEnum.BusinessCategory.NonUnemployed
    }
    else if (employmentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.FullOrPartTimeEducation) {
      if (occupation)
        occupation.value = DLDCommonValuesEnum.Occupation.Student
      if (businessCategory)
        businessCategory.value = DLDCommonValuesEnum.BusinessCategory.NoneStudent
    }
    else if (employmentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.FinanciallyAssisted) {
      if (occupation)
        occupation.value = DLDCommonValuesEnum.Occupation.NotEmployedDueToDisability
      if (businessCategory)
        businessCategory.value = DLDCommonValuesEnum.BusinessCategory.NoneNotEmployedduetoDisability
    }
    else if (employmentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.HouseholdDuties) {
      if (occupation)
        occupation.value = DLDCommonValuesEnum.Occupation.Housewife
      if (businessCategory)
        businessCategory.value = DLDCommonValuesEnum.BusinessCategory.NoneHouseholdDuties
    }
    else if (employmentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.IndependentMeans) {
      if (occupation)
        occupation.value = DLDCommonValuesEnum.Occupation.NotInEmployment
      if (businessCategory)
        businessCategory.value = DLDCommonValuesEnum.BusinessCategory.NotInEmployment
    }
    else if (employmentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.NotEmployedDueToDisability) {
      if (occupation)
        occupation.value = DLDCommonValuesEnum.Occupation.NotEmployedDueToDisability
      if (businessCategory)
        businessCategory.value = DLDCommonValuesEnum.BusinessCategory.NoneNotEmployedduetoDisability
    }

    // Claims
    const claims = this.getClaims(questions)

    // Addtional drivers
    const additionalDrivers = questions.find(q => q.name === QuestionEnum.additionalDriver) as QuestionAnswer<number>
    const adDrivers: AdditionalDriver[] = []

    if (additionalDrivers) {
      for (let i = 0; i < additionalDrivers.subPageAnswers.length; i += 1) {
        const adTitle = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.title) as QuestionAnswer<string>
        const adFirstName = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.firstname) as QuestionAnswer<string>
        const adLastName = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.lastName) as QuestionAnswer<string>
        const adDateOfBirth = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.dateOfBirth) as QuestionAnswer<Date>
        const adDrivingLicence = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.drivingLicence) as QuestionAnswer<string>
        const adRelationshipToPolicyholder = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.relationshipToPolicyHolder) as QuestionAnswer<string>
        const adEmploymentStatus = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.employmentStatus) as QuestionAnswer<string>
        const adOccupation = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.occupation) as QuestionAnswer<string>
        const adBusinessCategory = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.businessCategory) as QuestionAnswer<string>
        const adClassOfUse = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.classOfUse) as QuestionAnswer<string>
        const adResidentSince = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.residentSince) as QuestionAnswer<Date>
        const adResidentSinceBirth = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.residentSinceBirth) as QuestionAnswer<string>
        const adMaritalStatus = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.maritalStatus) as QuestionAnswer<string>
        const adHasMedicalConditionsQuestion = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.hasMedicalConditions) as QuestionAnswer<string>
        const adDvlaReportableMedicalConditionsQuestion = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.dvlaReportableMedicalConditions) as QuestionAnswer<string>
        const adPrn = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.PRN) as QuestionAnswer<number>
        const adLicenceType = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.licenceType) as QuestionAnswer<string>
        const adAddress = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.address) as QuestionAnswer<Address>

        if (adEmploymentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.Retired) {
          adOccupation.value = DLDCommonValuesEnum.Occupation.Retired
          adBusinessCategory.value = DLDCommonValuesEnum.BusinessCategory.NonRetired
        }
        else if (adEmploymentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.Unemployed) {
          adOccupation.value = DLDCommonValuesEnum.Occupation.Unemployed
          adBusinessCategory.value = DLDCommonValuesEnum.BusinessCategory.NonUnemployed
        }

        adDrivers.push({
          title: adTitle.value!,
          firstName: adFirstName.value!,
          lastName: adLastName.value!,
          dateOfBirth: adDateOfBirth.value!,
          drivingLicence: adDrivingLicence.value!,
          licenceType: adLicenceType?.value ?? DLDCommonValuesEnum.CarLicenceType.FullUK,
          relationshiptoPolicyholder: adRelationshipToPolicyholder?.value ?? DLDCommonValuesEnum.RelationshipToPolicyHolder.NotApplicable,
          employmentStatus: adEmploymentStatus?.value ?? null,
          occupation: adOccupation.value!,
          businessCategory: adBusinessCategory?.value ?? null,
          residentSince: adResidentSinceBirth?.value === DLDCommonValuesEnum.Boolean.Yes ? adDateOfBirth.value! : adResidentSince?.value,
          classOfUse: adClassOfUse?.value ?? DLDCommonValuesEnum.ClassOfUse.BusinessClass1,
          maritalStatus: adMaritalStatus?.value ?? null,
          hasMedicalConditions: adHasMedicalConditionsQuestion?.value === DLDCommonValuesEnum.Boolean.Yes,
          dvlaReportableMedicalConditions: adHasMedicalConditionsQuestion?.value === DLDCommonValuesEnum.Boolean.Yes ? adDvlaReportableMedicalConditionsQuestion?.value : null,
          claims: this.getClaims(additionalDrivers.subPageAnswers[i].answers),
          prn: adPrn?.value ?? null,
          farePayingPassengers: null,
          licenceDate: null,
          otherLicensingAuthorities: '',
          mainLicensingAuthority: '',
          rideSharingApp: null,
          secondaryOccupation: null,
          taxiBadgeHeldDate: null,
          hasHadPolicyDeclaredVoid: null,
          otherVehiclesOwned: true,
          hasConvictions: null,
          liveInVehicleFullTime: null,
          hadInsuranceRefused: null,
          drivingFrequency: null,
          hasCriminalConvictions: null,
          hasSecondaryEmployment: null,
          hasHadPreviousPolicyCancelled: null,
          secondaryEmploymentStatus: null,
          secondaryBusinessCategory: null,
          hasClaims: null,
          convictions: [],
          medicalConditions: [],
          voidReason: null,
          residentSinceBirth: null,
          restrictedLicense: null,
          hasCCJs: null,
          restrictedLicenseType: null,
          psvLicenceNumber: null,
          psvLicenceDate: null,
          operatingInLondonArea: null,
          otherVehiclesYouDrive: null,
          useOfAnotherCar: null,
          pSVOLicenceNumber: null,
          pSVONamedProposer: null,
          hasPSVOLicence: null,
          pSVONamedProposerReason: null,
          noPSVOLicenseReason: null,
          address: adAddress?.value ?? null,
        })
      }
    }
    // Cover details
    const classOfUse = questions.find(q => q.name === QuestionEnum.classOfUse) as QuestionAnswer<string>
    const effectiveDateQuestion = questions.find(q => q.name === QuestionEnum.coverEffectiveDate || q.name === QuestionEnum.mtaEffectiveDate || q.name === QuestionEnum.effectiveDate) as QuestionAnswer<Date>

    quoteRequest.car = this.bindCar(questions, true)
    quoteRequest.mileage = vehicleEstimatedMileage?.value ?? null

    if (vehicleBusinessMileage)
      quoteRequest.businessMileage = vehicleBusinessMileage?.value ?? 100000

    quoteRequest.proposer = new CarProposer()
    quoteRequest.proposer.title = title.value!
    quoteRequest.proposer.firstName = firstName.value!
    quoteRequest.proposer.lastName = lastName.value!
    quoteRequest.proposer.dateOfBirth = dateOfBirth.value!
    quoteRequest.proposer.drivingLicence = drivingLicence!.value!
    quoteRequest.proposer.licenceType = licenceType?.value ?? DLDCommonValuesEnum.CarLicenceType.FullUK
    quoteRequest.proposer.employmentStatus = employmentStatus?.value ?? null
    quoteRequest.proposer.occupation = occupation.value!
    quoteRequest.proposer.businessCategory = businessCategory?.value ?? null
    quoteRequest.proposer.address = address.value!
    quoteRequest.proposer.claims = claims

    quoteRequest.proposer.advancedDrivingLicence = hasAdvancedDrivingLicence?.value === DLDCommonValuesEnum.Boolean.Yes ?? null
    quoteRequest.proposer.advancedDrivingLicenceType = hasAdvancedDrivingLicence?.value === DLDCommonValuesEnum.Boolean.Yes ? advancedDrivingLicenceType?.value : null
    quoteRequest.proposer.isOwnersClubMember = isOwnersClubMemeber?.value === DLDCommonValuesEnum.Boolean.Yes ?? null
    quoteRequest.proposer.ownersClub = club?.value ?? null
    quoteRequest.proposer.maritalStatus = maritalStatus?.value ?? null
    quoteRequest.proposer.noClaimsYears = noClaimsBonus?.value ? this.getNoClaimsYears(noClaimsBonus.value!) : null
    quoteRequest.proposer.hasMedicalConditions = hasMedicalConditionsQuestion?.value === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.proposer.otherVehiclesOwned = true
    quoteRequest.proposer.dvlaReportableMedicalConditions = hasMedicalConditionsQuestion?.value === DLDCommonValuesEnum.Boolean.Yes ? dvlaReportableMedicalConditionsQuestion?.value : null
    quoteRequest.proposer.numberOfOtherVehiclesAvailable = numberOfOtherVehiclesAvailable?.value ?? null
    quoteRequest.proposer.numberOfHouseholdVehicles = numberOfHouseholdVehicles?.value ?? null
    quoteRequest.proposer.beenRequestedExtraPrecautions = beenRequestedExtraPrecautions?.value === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.proposer.drivingFrequency = mainUser?.value === DLDCommonValuesEnum.Boolean.Yes ? DLDCommonValuesEnum.DrivingFrequency.Main : DLDCommonValuesEnum.DrivingFrequency.NotAvailable
    quoteRequest.proposer.primaryTransport = primaryTransport?.value === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.proposer.liveInVehicleFullTime = liveInVehicleFullTime?.value === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.proposer.hadInsuranceRefused = hadInsuranceRefused?.value === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.proposer.hasConvictions = hasConvictions?.value === DLDCommonValuesEnum.Boolean.Yes

    quoteRequest.registeredDVLAMotorhome = dvlaMotorhome?.value === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.conversionCompleted = conversionCompleted?.value === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.modifiedEngineOrDrive = modifiedEngineDriveTrain?.value === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.drivingOtherCarsOnOtherPolicy = drivingOtherCarsOnOtherPolicy?.value
    quoteRequest.address = address.value!
    quoteRequest.additionalDrivers = adDrivers
    quoteRequest.classOfUse = classOfUse?.value ?? DLDCommonValuesEnum.ClassOfUse.BusinessClass1
    quoteRequest.effectiveDate = effectiveDateQuestion.value!

    quoteRequest.voluntaryExcess = voluntaryExcess?.value ?? DLDCommonValuesEnum.VolXs.VolXs0
    quoteRequest.coverType = DLDCommonValuesEnum.CoverType.Comprehensive
    quoteRequest.noClaimsProtection = noClaimsProtection && noClaimsProtection?.value
    quoteRequest.proposer.residentSince = residentSinceBirth?.value === DLDCommonValuesEnum.Boolean.Yes ? dateOfBirth.value! : residentSince?.value

    quoteRequest.reasonForCover = reasonForCover?.value ?? null

    let coverTerm

    if (relatedCoverDuration?.value && relatedCoverDuration?.value !== DLDCommonValuesEnum.RelatedRadioListEnum.OtherValue)
      coverTerm = relatedCoverDuration!.value

    else if (coverDuration?.value)
      coverTerm = coverDuration?.value

    else
      coverTerm = null

    quoteRequest.coverTerm = coverTerm
  }

  getQuoteRequest<T extends QuoteRequest>(questions: IQuestionAnswer[], Type: new () => T): T {
    const quoteRequest = new Type()
    if (quoteRequest instanceof CarQuoteRequest || quoteRequest instanceof CarMTAQuoteRequest)
      this.buildCarRequest(quoteRequest, questions)
    else if (quoteRequest instanceof GapQuoteRequest || quoteRequest instanceof GapMTAQuoteRequest)
      this.buildGapRequest(quoteRequest, questions)
    else if (quoteRequest instanceof TouringCaravanQuoteRequest || quoteRequest instanceof TouringCaravanMTAQuoteRequest)
      this.buildTouringCaravanRequest(quoteRequest, questions)
    else
      throw new Error(`Unsupported quote request (${typeof Type}) for Wrapper.`)

    return quoteRequest
  }

  private bindCar(questions: IQuestionAnswer[], includeAbiCode: boolean): Car {
    const vehicleLookup = questions.find(q => q.name === QuestionEnum.vehicleLookup) as QuestionAnswer<Vehicle>
    const vehicleOvernightParkLocation = questions.find(q => q.name === QuestionEnum.vehicleOvernightParkLocation) as QuestionAnswer<string>
    const rightHandDrive = questions.find(q => q.name === QuestionEnum.isVehicleRightHandDrive) as QuestionAnswer<string>
    const vehicleSeats = questions.find(q => q.name === QuestionEnum.vehicleSeats) as QuestionAnswer<number>
    const vehicleTransmission = questions.find(q => q.name === QuestionEnum.vehicleTransmission) as QuestionAnswer<string>
    const alarmType = questions.find(q => q.name === QuestionEnum.alarmType) as QuestionAnswer<string>
    const trackerType = questions.find(q => q.name === QuestionEnum.trackerType) as QuestionAnswer<string>
    const motorhomeType = questions.find(q => q.name === QuestionEnum.motorhomeType) as QuestionAnswer<string>
    const unknownMotorhomeType = questions.find(q => q.name === QuestionEnum.unknownMotorhomeType) as QuestionAnswer<string>
    const registeredKeeper = questions.find(q => q.name === QuestionEnum.registeredKeeper) as QuestionAnswer<string>
    const isRegisteredKeeper = questions.find(q => q.name === QuestionEnum.confirmRegisteredKeeper) as QuestionAnswer<string>
    const registeredOwner = questions.find(q => q.name === QuestionEnum.vehicleOwner) as QuestionAnswer<string>
    const presentValue = questions.find(q => q.name === QuestionEnum.presentValue) as QuestionAnswer<number>
    const vehicleFuelType = questions.find(q => q.name === QuestionEnum.fuelType) as QuestionAnswer<string>
    const vehicleAddress = questions.find(q => q.name === QuestionEnum.vehicleAddress) as QuestionAnswer<Address>
    const vehicleKeptAtHome = questions.find(q => q.name === QuestionEnum.vehicleKeptAtHome) as QuestionAnswer<string>
    const car = new Car()

    if (includeAbiCode)
      car.abiCode = vehicleLookup.value!.abiCode
    car.registration = vehicleLookup.value!.registration
    car.value = presentValue?.visible ? presentValue.value! : vehicleLookup.value!.value < 1 ? 1 : vehicleLookup.value!.value
    car.overnightParkLocation = vehicleOvernightParkLocation?.value
    car.purchaseDate = vehicleLookup.value?.purchaseDate ?? new Date()
    car.yearOfManufacture = vehicleLookup.value!.yearOfManufacture
    car.keptAtHomeAddress = (vehicleKeptAtHome?.value || DLDCommonValuesEnum.Boolean.Yes) === DLDCommonValuesEnum.Boolean.Yes
    car.registeredKeeper = registeredKeeper?.value ?? isRegisteredKeeper?.value === DLDCommonValuesEnum.Boolean.Yes ? DLDCommonValuesEnum.RegisteredKeeper.ProposerPolicyHolder : DLDCommonValuesEnum.RegisteredKeeper.Other
    car.registeredOwner = registeredOwner?.value ?? DLDCommonValuesEnum.RegisteredKeeper.ProposerPolicyHolder
    car.fuelType = vehicleFuelType?.value || vehicleLookup?.value?.fuelType || null
    car.transmission = vehicleTransmission?.value || vehicleLookup?.value?.transmissionType || null
    // Todo - get these question values from updated vehicle lookup
    car.cubicCapacity = vehicleLookup.value!.engineSize
    car.bodyType = vehicleLookup.value!.bodyType
    car.make = vehicleLookup.value!.make
    car.model = vehicleLookup.value!.model
    car.dateFirstRegistered = vehicleLookup.value!.dateFirstRegistered
    car.leftHandDrive = rightHandDrive?.value === DLDCommonValuesEnum.Boolean.No
    car.imported = false
    car.noOfSeats = vehicleSeats?.value || null
    car.alarmType = alarmType?.value || null
    car.trackerType = trackerType?.value || null
    car.motorhomeType = motorhomeType?.value || null
    car.unknownMotorhomeType = unknownMotorhomeType?.value || null
    car.address = vehicleKeptAtHome?.value === DLDCommonValuesEnum.Boolean.No ? vehicleAddress!.value : null

    return car
  }

  private getClaims(questions: IQuestionAnswer[]): Claim[] {
    const claimsQuestion = questions.find(q => q.name === QuestionEnum.claims) as QuestionAnswer<number>
    const claims: Claim[] = []

    if (claimsQuestion) {
      for (let i = 0; i < claimsQuestion.subPageAnswers.length; i += 1) {
        const claimDate = claimsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.claimDate) as QuestionAnswer<Date>
        const claimType = claimsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.claimType) as QuestionAnswer<string>
        const claimStatus = claimsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.claimStatus) as QuestionAnswer<string>
        const claimFault = claimsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.claimFault) as QuestionAnswer<string>
        const claimPersonsInjured = claimsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.claimPersonsInjured) as QuestionAnswer<string>
        const claimCost = claimsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.claimCost) as QuestionAnswer<number>
        const ncbLost = claimsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.ncbLost) as QuestionAnswer<string>

        claims.push({
          claimDate: claimDate.value!,
          incidentDate: null,
          claimType: claimType.value!,
          claimStatus: claimStatus.value!,
          claimFault: claimFault.value! === DLDCommonValuesEnum.Boolean.Yes,
          claimPersonsInjured: claimPersonsInjured.value! === DLDCommonValuesEnum.Boolean.Yes,
          claimCost: claimCost.value!,
          ncbLost: ncbLost.value! === DLDCommonValuesEnum.Boolean.Yes,
        })
      }
    }
    return claims
  }

  getMTARequest<T extends QuoteRequest>(questions: IQuestionAnswer[], Type: new () => T): T {
    const mtaRequest = this.getQuoteRequest(questions, Type)
    if (!(mtaRequest instanceof CarMTAQuoteRequest) && !(mtaRequest instanceof TouringCaravanQuoteRequest) && !(mtaRequest instanceof GapMTAQuoteRequest))
      throw new Error(`Unsupported quote request (${typeof mtaRequest}) for Wrapper.`)

    return mtaRequest
  }

  mapRiskToAnswers(risk: Risk, account: Account, pages: Page[]): Dictionary<PageAnswers> {
    switch (true) {
      case risk instanceof GapRisk: {
        const gapRisk = risk as GapRisk
        return this.mapGapRiskToAnswers(gapRisk, account, pages)
      }
      case risk instanceof CarRisk: {
        const carRisk = risk as CarRisk
        return this.mapCarRiskToAnswers(carRisk, account, pages)
      }
      case risk instanceof TouringCaravanRisk: {
        const tourningCaravanRisk = risk as TouringCaravanRisk
        return this.mapTouringCaravanRisk(tourningCaravanRisk, account, pages)
      }
      default:
        throw new Error('Unsupported risk type for Wrapper.')
    }
  }

  mapTouringCaravanRisk(risk: TouringCaravanRisk, account: Account, pages: Page[]): Dictionary<PageAnswers> {
    const pageAnswers: Dictionary<PageAnswers> = { }
    const caravan = risk.touringCaravans[0]
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.yearOfMake, caravan.yearOfMake, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.basisOfCover, risk.coverType.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.coverEffectiveDate, risk.effectiveDate, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.purchasePrice, caravan.purchasePrice, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.marketPrice, caravan.currentValue, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.coverForAwnings, risk.awningsValue === 0 ? DLDCommonValuesEnum.Boolean.No : DLDCommonValuesEnum.Boolean.Yes, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.coverForequipment, risk.equipmentValue === 0 ? DLDCommonValuesEnum.Boolean.No : DLDCommonValuesEnum.Boolean.Yes, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.coverForContents, risk.contentsValue === 0 ? DLDCommonValuesEnum.Boolean.No : DLDCommonValuesEnum.Boolean.Yes, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.coverForPersonalEffects, risk.personalEffectsValue === 0 ? DLDCommonValuesEnum.Boolean.No : DLDCommonValuesEnum.Boolean.Yes, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.awningsPrice, risk.awningsValue, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.equipmentPrice, risk.equipmentValue, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.personalEffectsPrice, risk.personalEffectsValue, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.contentsPrice, risk.contentsValue, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.voluntaryExcess, risk.voluntaryExcess.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.title, risk.proposer.title, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.firstname, risk.proposer.firstName, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.towingFrequency, caravan.towingFrequency?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.lastName, risk.proposer.lastName, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.noClaimsProtection, risk.proposer.noClaimsProtection ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.dateOfBirth, risk.proposer.dateOfBirth, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.address, risk.address, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.caravanType, caravan.caravanType.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.caravanMakes, caravan.make.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.caravanModels, caravan.model.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleDeliveryTaken, caravan.purchaseDate == null ? DLDCommonValuesEnum.Boolean.No : DLDCommonValuesEnum.Boolean.Yes, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehiclePurchaseDate, caravan.purchaseDate, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.caravanUsage, caravan.coverType?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.registeredWithCRiS, caravan.isCRISRegistered ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.workingAlarm, caravan.alarmType !== null ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.hasTracker, caravan.trackerType !== null ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleAlarmType, caravan.alarmType?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.caravansecurityTracker, caravan.trackerType?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.hasTagging, DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.hasCaravanSafetyDevices, DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.goodStateOfRepair, caravan.inGoodCondition ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.servicedEveryTwoYears, caravan.servicedInLastTwoYears ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.maritalStatus, risk.proposer.maritalStatus.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.residentSinceBirth, risk.proposer.dateOfBirth === risk.proposer.livedInUKSince ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.ukResident, risk.proposer.livedInUKSince, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.licenceHeldYears, this.getLicenseYears(risk.proposer.licenceHeldYears!), pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.drivingTestPassed, risk.proposer.manouveringCourse !== null ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.manoeuvringCourse, risk.proposer.manouveringCourse?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.isClubMember, risk.proposer.clubMembership !== null ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.touringClubName, risk.proposer.clubMembership?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.employmentStatus, risk.proposer.employmentStatus?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.occupation, risk.proposer.occupation?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.businessCategory, risk.proposer.businessType?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.hasSecondaryEmployment, risk.proposer.secondaryEmploymentStatus?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.secondaryOccupation, risk.proposer.secondaryOccupation?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.secondaryBusinessCategory, risk.proposer.secondaryBusinessType?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.noClaimsBonus, this.getNoClaimsYearsDLD(risk.proposer.noClaimsBonus!), pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.otherNoClaimsBonus, this.getNoClaimsYearsDLD(risk.proposer.caravanNoClaimsBonus!), pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.hasClaims, risk.proposer.hasClaims ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.hasConvictions, risk.proposer.hasConvictions ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.hadInsuranceRefused, risk.proposer.hadInsuranceRefused ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.unspentConvictions, risk.proposer.hasCriminalConvictions ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.beenRequestedExtraPrecautions, risk.proposer.hasHadPreviousPolicyCancelledTermsApplied ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.caravanSecurity, risk.touringCaravans[0].caravanSecurity, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.caravanTaggingIdentification, risk.touringCaravans[0].caravanTagging, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.caravanSafetyDevices, risk.touringCaravans[0].caravanSafety, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.hasTagging, risk.touringCaravans[0].caravanTagging.length > 0 ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.hasCaravanSafetyDevices, risk.touringCaravans[0].caravanSafety.length > 0 ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)

    if (risk.proposer.hasClaims) {
      const claims = this.createAnswerForQuestion(pageAnswers, QuestionEnum.claims, risk.proposer.touringCaravanClaims.length, pages)!

      for (let i = 0; i < risk.proposer.touringCaravanClaims.length; i += 1) {
        claims.forEach((cls) => {
          const cl = risk.proposer.touringCaravanClaims[i]

          const claimIncidentType = new QuestionAnswer<string>(0, QuestionEnum.claimIncidentType, '', cl.claimType.relatedUniqueId)
          const accidentClaimType = new QuestionAnswer<string>(0, QuestionEnum.accidentClaimType, '', cl.claimType.uniqueId)
          const theftClaimType = new QuestionAnswer<string>(0, QuestionEnum.theftClaimType, '', cl.claimType.uniqueId)
          const otherClaimType = new QuestionAnswer<string>(0, QuestionEnum.otherClaimType, '', cl.claimType.uniqueId)
          const claimDate = new QuestionAnswer<Date>(0, QuestionEnum.claimDate, '', cl.claimDate)
          const claimCost = new QuestionAnswer<number>(0, QuestionEnum.claimCost, '', cl.claimCost)
          const claimPage = new PageAnswers(`Claims ${i + 1}`, [claimIncidentType, accidentClaimType, theftClaimType, otherClaimType, claimDate, claimCost])
          cls.subPageAnswers.push(claimPage)
        })
      }
    }

    if (risk.proposer.hasConvictions) {
      const convictions = this.createAnswerForQuestion(pageAnswers, QuestionEnum.convictions, risk.proposer.touringCaravanConvictions.length, pages)!

      for (let i = 0; i < risk.proposer.touringCaravanConvictions.length; i += 1) {
        convictions.forEach((cls) => {
          const cl = risk.proposer.touringCaravanConvictions[i]
          const convictionType = new QuestionAnswer<string>(0, QuestionEnum.convictionType, '', cl.convictionCode.uniqueId)
          const convictionDate = new QuestionAnswer<Date>(0, QuestionEnum.convictionDate, '', cl.convictionDate)
          const penaltyPointsGiven = new QuestionAnswer<string>(0, QuestionEnum.penaltyPointsGiven, '', cl.pointsReceived === 0 ? DLDCommonValuesEnum.Boolean.No : DLDCommonValuesEnum.Boolean.Yes)
          const fineGiven = new QuestionAnswer<string>(0, QuestionEnum.fineGiven, '', cl.fine === 0 ? DLDCommonValuesEnum.Boolean.No : DLDCommonValuesEnum.Boolean.Yes)
          const disqualifiedFromDriving = new QuestionAnswer<string>(0, QuestionEnum.disqualifiedFromDriving, '', cl.disqualificationMonths === 0 ? DLDCommonValuesEnum.Boolean.No : DLDCommonValuesEnum.Boolean.Yes)
          const convictionPointsReceived = new QuestionAnswer<number>(0, QuestionEnum.convictionPointsReceived, '', cl.pointsReceived)
          const fineAmount = new QuestionAnswer<number>(0, QuestionEnum.fineAmount, '', cl.fine)
          const drivingBanLength = new QuestionAnswer<number>(0, QuestionEnum.drivingBanLength, '', cl.disqualificationMonths)
          const convictionPage = new PageAnswers(`Convictions ${i + 1}`, [convictionType, convictionDate, penaltyPointsGiven, fineGiven, disqualifiedFromDriving, convictionPointsReceived, fineAmount, drivingBanLength])
          cls.subPageAnswers.push(convictionPage)
        })
      }
    }

    const storageLocations = this.createAnswerForQuestion(pageAnswers, QuestionEnum.storageLocations, caravan.touringCaravanStorageLocations.length, pages)!
    storageLocations.forEach((sls) => {
      for (let i = 0; i < caravan.touringCaravanStorageLocations.length; i += 1) {
        const sl = caravan.touringCaravanStorageLocations[i]
        const storageLocation = new QuestionAnswer<string>(0, QuestionEnum.storageLocation, '', sl.storageLocationType.uniqueId)
        const homeStorageLocation = new QuestionAnswer<string>(0, QuestionEnum.homeStorageLocation, '', sl.storageLocationDetail.uniqueId)
        const typeOfCompound = new QuestionAnswer<string>(0, QuestionEnum.typeOfCompound, '', sl.storageLocationDetail.uniqueId)
        const typeOfCaravanPark = new QuestionAnswer<string>(0, QuestionEnum.typeOfCaravanPark, '', sl.storageLocationDetail.uniqueId)
        const typeOfFarm = new QuestionAnswer<string>(0, QuestionEnum.typeOfFarm, '', sl.storageLocationDetail.uniqueId)
        const otherLocation = new QuestionAnswer<string>(0, QuestionEnum.otherLocation, '', sl.storageLocationDetail.uniqueId)
        const hasSecurityGate = new QuestionAnswer<string>(0, QuestionEnum.hasSecurityGate, '', sl.securityGates ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const hasPerimiterFencing = new QuestionAnswer<string>(0, QuestionEnum.hasPerimiterFencing, '', sl.secureFencing ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const hasCCTV = new QuestionAnswer<string>(0, QuestionEnum.hasCCTV, '', sl.hasCCTV ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const hasWarden = new QuestionAnswer<string>(0, QuestionEnum.hasWarden, '', sl.fullTimeWarden ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const vehicleKeptAtPostCode = new QuestionAnswer<string>(0, QuestionEnum.vehicleKeptAtPostCode, '', sl.storageLocationPostcode)
        const whichCaSSOASite = new QuestionAnswer<string>(0, QuestionEnum.whichCaSSOASite, '', sl.cassoaSite === null ? null : sl.cassoaSite.uniqueId)
        const numberOfCaravans = new QuestionAnswer<string>(0, QuestionEnum.numberOfCaravans, '', sl.caravansOnSite === null || sl.caravansOnSite === 0 ? null : this.getNumberOfCarvansDLD(sl.caravansOnSite!))
        const monthsAtSite = new QuestionAnswer<string>(0, QuestionEnum.numberOfMonthsStored, '', sl.monthsAtStorageLocation === null ? null : this.getMonthsStoredDLD(sl.monthsAtStorageLocation!))
        const locationPage = new PageAnswers(`Storage Location ${i + 1}`, [storageLocation, homeStorageLocation, typeOfCompound, typeOfCaravanPark, typeOfFarm, otherLocation, hasSecurityGate, hasPerimiterFencing, hasCCTV, hasWarden, vehicleKeptAtPostCode, whichCaSSOASite, numberOfCaravans, monthsAtSite])
        sls.subPageAnswers.push(locationPage)
      }
    })

    this.mapAccountToAnswers(pageAnswers, account, pages)
    return pageAnswers
  }

  mapCarRiskToAnswers(risk: CarRisk, account: Account, pages: Page[]): Dictionary<PageAnswers> {
    const pageAnswers: Dictionary<PageAnswers> = { }

    this.mapAccountToAnswers(pageAnswers, account, pages)

    const vehicle = new Vehicle()
    vehicle.registration = risk.vehicle.registrationNumber
    vehicle.abiCode = risk.vehicle.car?.abiCode
    vehicle.make = risk.vehicle.car?.makeDescription
    vehicle.model = risk.vehicle.car?.modelDescription
    vehicle.dateFirstRegistered = risk.vehicle.car.dateFirstRegistered
    vehicle.value = risk.vehicle.value
    vehicle.purchaseDate = risk.vehicle.purchaseDate
    vehicle.yearOfManufacture = risk.vehicle.yearOfManufacture
    vehicle.bodyType = risk.vehicle.bodyType
    vehicle.fuelType = risk.vehicle.fuelType?.uniqueId
    vehicle.transmissionType = risk.vehicle.vehicleTransmission?.uniqueId
    vehicle.engineSize = risk.vehicle.cubicCapacity

    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleLookup, vehicle, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.title, risk.proposer.title.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.firstname, risk.proposer.firstName, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.lastName, risk.proposer.lastName, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.dateOfBirth, risk.proposer.dateOfBirth, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.address, risk.proposer.address, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.drivingLicence, risk.proposer.drivingLicence, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.licenceType, risk.proposer?.licenceType ? risk.proposer.licenceType.uniqueId : DLDCommonValuesEnum.CarLicenceType.FullUK, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleEstimatedMileage, risk.vehicle.annualMileage, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleBusinessMileage, risk.vehicle.businessMileage, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.registeredKeeper, risk.vehicle.registeredKeeper.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleOwner, risk.vehicle.registeredOwner.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleOvernightParkLocation, risk.vehicle.overnightParkLocation?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.employmentStatus, risk.proposer.employmentStatus?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.occupation, risk.proposer.occupation.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.businessCategory, risk.proposer.businessCategory?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.classOfUse, risk.classOfUse?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.coverType, risk.coverType?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.referralSource, risk.businessSource?.id, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.coverEffectiveDate, risk.effectiveDate, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.voluntaryExcess, risk.voluntaryExcess?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.noClaimsProtection, risk.noClaimsProtection ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.numberOfOtherVehiclesAvailable, risk.proposer.numberOfOtherVehiclesAvailable?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.conversionCompleted, risk.vehicle.conversionCompleted ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.modifiedEngineDriveTrain, risk.vehicle.modifiedEngineOrDrive ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.dvlaMotorhome, risk.vehicle.registeredDVLAMotorhome ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)

    this.createAnswerForQuestion(pageAnswers, QuestionEnum.hasClaims, risk.proposer.hasConvictions ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.liveInVehicleFullTime, risk.proposer.liveInVehicleFullTime ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.hadInsuranceRefused, risk.proposer.hadInsuranceRefused ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.mainUser, risk.proposer.drivingFrequency.uniqueId === DLDCommonValuesEnum.DrivingFrequency.Main ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.primaryTransport, risk.proposer.primaryTransport ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.beenRequestedExtraPrecautions, risk.proposer.beenRequestedExtraPrecautions ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)

    this.createAnswerForQuestion(pageAnswers, QuestionEnum.isVehicleRightHandDrive, risk.vehicle.leftHandDrive ? DLDCommonValuesEnum.Boolean.No : DLDCommonValuesEnum.Boolean.Yes, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.cubicCapacity, risk.vehicle.cubicCapacity, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.motorhomeType, risk.vehicle.motorhomeType, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.unknownMotorhomeType, risk.vehicle.unknownMotorhomeType, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleImported, risk.vehicle.imported ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.hasAdvancedDrivingLicence, risk.proposer.hasAdvancedDrivingLicence ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.advancedDrivingLicenceType, risk.proposer.advancedDrivingLicenceType?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.noClaimsBonus, this.getNoClaimsYearsDLD(risk.proposer.noClaimsBonus), pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.isClubMember, risk.proposer.isOwnersClubMember ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.clubName, risk.proposer.ownersClub?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.residentSinceBirth, risk.proposer.residentSinceBirth ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.residentSince, risk.proposer.residentSince, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.maritalStatus, risk.proposer.maritalStatus?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.hasMedicalConditions, risk.proposer.hasMedicalConditions ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.dvlaReportableMedicalConditions, risk.proposer.dvlaReportableMedicalConditions?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleSeats, risk.vehicle.noOfSeats, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleTransmission, risk.vehicle.vehicleTransmission?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.alarmType, risk.vehicle.alarmType?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.trackerType, risk.vehicle.trackerType?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.hasAccessToAnotherVehicle, risk.drivingOtherCarsOnOtherPolicy ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.numberOfHouseholdVehicles, risk.proposer.numberOfHouseholdVehicles, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.registeredKeeper, risk.vehicle.registeredKeeper.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleOwner, risk.vehicle.registeredOwner.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.presentValue, vehicle.value, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.fuelType, risk.vehicle.fuelType?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleAddress, risk.vehicle.address, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleKeptAtHome, risk.vehicle.keptAtHomeAddress ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)

    this.createAnswerForQuestion(pageAnswers, QuestionEnum.reasonForCover, risk.reasonForCover?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.coverDurationType, risk.coverTerm?.relatedUniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.relatedCoverDuration, DLDCommonValuesEnum.RelatedRadioListEnum.OtherValue, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.coverDuration, risk.coverTerm?.uniqueId, pages)

    this.mapClaims(pageAnswers, pages, risk.proposer.claims)

    const additionalDrivers = this.createAnswerForQuestion(pageAnswers, QuestionEnum.additionalDriver, risk.additionalDrivers.length, pages)!
    additionalDrivers.forEach((ada) => {
      for (let i = 0; i < risk.additionalDrivers.length; i += 1) {
        const ad = risk.additionalDrivers[i]
        const adTitle = new QuestionAnswer<string>(0, QuestionEnum.title, '', ad.title.uniqueId)
        const adFirstName = new QuestionAnswer<string>(0, QuestionEnum.firstname, '', ad.firstName)
        const adLastName = new QuestionAnswer<string>(0, QuestionEnum.lastName, '', ad.lastName)
        const adDateOfBirth = new QuestionAnswer<Date>(0, QuestionEnum.dateOfBirth, '', ad.dateOfBirth)
        const adAddress = new QuestionAnswer<Address>(0, QuestionEnum.address, '', ad?.address)
        const adDrivingLicence = new QuestionAnswer<string>(0, QuestionEnum.drivingLicence, '', ad.drivingLicence)
        const adLicenceType = new QuestionAnswer<string>(0, QuestionEnum.licenceType, '', ad?.licenceType ? ad.licenceType.uniqueId : DLDCommonValuesEnum.CarLicenceType.FullUK)
        const adRelationshipToPolicyholder = new QuestionAnswer<string>(0, QuestionEnum.relationshipToPolicyHolder, '', ad.relationshipToPolicyholder?.uniqueId)
        const adEmploymentStatus = new QuestionAnswer<string>(0, QuestionEnum.employmentStatus, '', ad.employmentStatus?.uniqueId)
        const adMaritalStatus = new QuestionAnswer<string>(0, QuestionEnum.maritalStatus, '', ad.maritalStatus?.uniqueId)
        const adOccupation = new QuestionAnswer<string>(0, QuestionEnum.occupation, '', ad.occupation.uniqueId)
        const adBusinessCategory = new QuestionAnswer<string>(0, QuestionEnum.businessCategory, '', ad.businessCategory?.uniqueId)
        const adResidentSinceBirth = new QuestionAnswer<string>(0, QuestionEnum.residentSinceBirth, '', ad.residentSinceBirth ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const adResidentSince = new QuestionAnswer<Date>(0, QuestionEnum.residentSince, '', ad.residentSince)
        const adClassOfUse = new QuestionAnswer<string>(0, QuestionEnum.classOfUse, '', ad.classOfUse.uniqueId)
        const adHasMedicalConditions = new QuestionAnswer<string>(0, QuestionEnum.hasMedicalConditions, '', ad.hasMedicalConditions ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const adDVLAReportableMedicalConditions = new QuestionAnswer<string>(0, QuestionEnum.dvlaReportableMedicalConditions, '', ad.dvlaReportableMedicalConditions?.uniqueId)
        const adPrn = new QuestionAnswer<number>(0, QuestionEnum.PRN, '', ad.prn, false)

        const adClaims = new QuestionAnswer<number>(0, QuestionEnum.claims, '', ad.claims.length)
        const claimsAnswers: Dictionary<PageAnswers> = {}
        const claims = this.mapClaims(claimsAnswers, pages, ad.claims)
        adClaims.subPageAnswers = claims.length > 0 ? (claims[0] as QuestionAnswer<number>).subPageAnswers : []

        const adPage = new PageAnswers(`Additional Driver ${i + 1}`, [adTitle, adFirstName, adLastName, adDateOfBirth, adAddress, adDrivingLicence, adLicenceType, adRelationshipToPolicyholder, adEmploymentStatus, adOccupation, adBusinessCategory, adResidentSinceBirth, adResidentSince, adClassOfUse, adMaritalStatus, adHasMedicalConditions, adDVLAReportableMedicalConditions, adClaims, adPrn])
        ada.subPageAnswers.push(adPage)
      }
    })

    return pageAnswers
  }

  mapGapRiskToAnswers(risk: GapRisk, account: Account, pages: Page[]): Dictionary<PageAnswers> {
    const pageAnswers: Dictionary<PageAnswers> = { }
    this.mapAccountToAnswers(pageAnswers, account, pages)

    const vehicle = new Vehicle()
    vehicle.registration = risk.vehicle.registrationNumber
    vehicle.abiCode = risk.vehicle.car?.abiCode
    vehicle.make = risk.vehicle.car?.makeDescription
    vehicle.model = risk.vehicle.car?.modelDescription
    vehicle.value = risk.vehicle.invoiceValue
    vehicle.purchaseDate = risk.vehicle.vehiclePurchaseDate
    vehicle.dateFirstRegistered = risk.vehicle.car.dateFirstRegistered
    vehicle.yearOfManufacture = risk.vehicle.modelYear
    vehicle.bodyType = risk.vehicle.model
    vehicle.engineSize = risk.vehicle.engineSize
    vehicle.fuelType = risk.vehicle.fuelType?.uniqueId
    vehicle.transmissionType = risk.vehicle.transmission?.uniqueId

    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleLookup, vehicle, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.title, risk.proposer.title.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.firstname, risk.proposer.firstName, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.lastName, risk.proposer.lastName, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.dateOfBirth, risk.proposer.dateOfBirth, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.address, risk.proposer.address, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.gapVehicleType, risk.vehicle.vehicleType?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.purchasePrice, risk.vehicle.invoiceValue, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.currentMileage, risk.vehicle.currentMileage, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehiclePurchaseDate, risk.vehicle.vehiclePurchaseDate, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.gapVehiclePurchaseType, risk.vehicle.vehiclePurchaseFrom?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.gapVehiclePaymentType, risk.vehicle.vehiclePaymentType?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.confirmCommercialType, risk.demands?.confirmCommercialType?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.confirmRegisteredKeeper, risk.demands?.confirmRegisteredKeeper?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.coverEffectiveDate, risk.effectiveDate, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.confirmFullyComp, risk.demands?.confirmFullyComp?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.noClaimsBonus, this.getNoClaimsYearsDLD(risk.proposer.noClaimDiscount!), pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.coverTerm, risk.coverTerm?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.additionalDrivers, risk.proposer.hasAdditionalApplicant ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleCollectionDate, risk.vehicle.vehicleCollectionDate, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.needCoverForAdditionalDriver, risk.proposer.needCoverForAdditionalDriver ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.includeCoverForVehicleStolen, risk.demands?.includeCoverForVehicleStolen ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.includeCoverForAddedExtra, risk.demands?.includeCoverForAddedExtra ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleDeliveryTaken, risk.vehicle.vehicleCollectionDateKnown?.uniqueId ? risk.vehicle.vehicleCollectionDateKnown.uniqueId : DLDCommonValuesEnum.Boolean.Yes, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.gapDamagedBeyondRepair, risk.demands.needOutstandingFinance ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.dateFirstRegistered, risk.vehicle.dateFirstRegistered, pages)
    return pageAnswers
  }

  private mapClaims(pageAnswers: Dictionary<PageAnswers>, pages: Page[], riskClaims: ClaimModel[]) {
    const claims = this.createAnswerForQuestion(pageAnswers, QuestionEnum.claims, riskClaims.length, pages)!
    claims.forEach((c) => {
      for (let i = 0; i < riskClaims.length; i += 1) {
        const claim = riskClaims[i]
        const claimDate = new QuestionAnswer<Date>(0, QuestionEnum.claimDate, '', claim.claimDate)
        const claimType = new QuestionAnswer<string>(0, QuestionEnum.claimType, '', claim.claimType.uniqueId)
        const claimStatus = new QuestionAnswer<string>(0, QuestionEnum.claimStatus, '', claim.claimStatus.uniqueId)
        const claimFault = new QuestionAnswer<string>(0, QuestionEnum.claimFault, '', claim.claimFault ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const claimPersonsInjured = new QuestionAnswer<string>(0, QuestionEnum.claimPersonsInjured, '', claim.claimPersonsInjured ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const claimCost = new QuestionAnswer<number>(0, QuestionEnum.claimCost, '', claim.claimCost)
        const ncbLost = new QuestionAnswer<string>(0, QuestionEnum.ncbLost, '', claim.ncbLost ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)

        const claimPage = new PageAnswers(`Claim ${i + 1}`, [claimDate, claimType, claimStatus, claimFault, claimPersonsInjured, claimCost, ncbLost])
        c.subPageAnswers.push(claimPage)
      }
    })
    return claims
  }

  getAccountRegisterRequest(questions: IQuestionAnswer[], affiliateId: number | null): AccountRegister | null {
    const title = questions.find(q => q.name === QuestionEnum.title) as QuestionAnswer<string>
    const firstName = questions.find(q => q.name === QuestionEnum.firstname) as QuestionAnswer<string>
    const lastName = questions.find(q => q.name === QuestionEnum.lastName) as QuestionAnswer<string>
    const email = questions.find(q => q.name === QuestionEnum.customerEmail || q.name === QuestionEnum.customerEmailConfirmation) as QuestionAnswer<string>
    const dob = questions.find(q => q.name === QuestionEnum.dateOfBirth) as QuestionAnswer<Date>
    const address = questions.find(q => q.name === QuestionEnum.address) as QuestionAnswer<Address>
    const phoneNumber = questions.find(q => q.name === QuestionEnum.mobilePhoneNumber || q.name === QuestionEnum.customerHomePhoneNumber) as QuestionAnswer<string>

    if (!title?.value || !firstName?.value || !lastName?.value || !email?.value)
      return null

    const request = new AccountRegister()
    request.title = title.value!
    request.firstName = firstName.value!
    request.lastName = lastName.value!
    request.dateOfBirth = dob.value!
    request.email = email.value!
    request.address = address.value!
    request.mobilePhone = phoneNumber.value!
    request.homePhone = phoneNumber.value!

    request.marketing = new Marketing()
    request.marketing.isEmailOptIn = false
    request.marketing.isTelephoneOptIn = false
    request.marketing.isSMSOptIn = false
    request.marketing.isPostOptIn = false

    request.affiliateId = affiliateId

    return request
  }

  updateAccountFromQuestions(account: Account, questions: IQuestionAnswer[]): Account {
    const title = questions.find(q => q.name === QuestionEnum.title) as QuestionAnswer<string>
    const firstName = questions.find(q => q.name === QuestionEnum.firstname) as QuestionAnswer<string>
    const lastName = questions.find(q => q.name === QuestionEnum.lastName) as QuestionAnswer<string>
    const email = questions.find(q => q.name === QuestionEnum.customerEmail) as QuestionAnswer<string>
    const customerEmail = questions.find(q => q.name === QuestionEnum.customerEmailConfirmation) as QuestionAnswer<string>
    const phoneNumber = questions.find(q => q.name === QuestionEnum.mobilePhoneNumber || q.name === QuestionEnum.customerHomePhoneNumber) as QuestionAnswer<string>
    const marketing = questions.find(q => q.name === QuestionEnum.keepingYouInformed) as QuestionAnswer<string[]>
    const address = questions.find(q => q.name === QuestionEnum.address) as QuestionAnswer<Address>
    const dateOfBirth = questions.find(q => q.name === QuestionEnum.dateOfBirth) as QuestionAnswer<Date>

    account.title = title?.value ?? account.title
    account.firstName = firstName?.value ?? account.firstName
    account.lastName = lastName?.value ?? account.lastName
    account.email = email?.value || customerEmail?.value || account.email
    account.homePhone = phoneNumber?.value ?? account.homePhone
    account.mobilePhone = phoneNumber?.value ?? account.mobilePhone
    account.telephone = phoneNumber?.value ?? account.telephone
    account.workPhone = phoneNumber?.value ?? account.workPhone
    account.dateOfBirth = dateOfBirth?.value ?? account.dateOfBirth

    if (marketing?.value) {
      account.marketing.isEmailOptIn = marketing.value?.indexOf(DLDCommonValuesEnum.MarketingOption.Email) !== -1
      account.marketing.isSMSOptIn = marketing.value?.indexOf(DLDCommonValuesEnum.MarketingOption.SMS) !== -1
      account.marketing.isPostOptIn = marketing.value?.indexOf(DLDCommonValuesEnum.MarketingOption.Post) !== -1
      account.marketing.isTelephoneOptIn = marketing.value?.indexOf(DLDCommonValuesEnum.MarketingOption.Telephone) !== -1
    }
    account.address = address?.value ?? account.address

    return account
  }
}
