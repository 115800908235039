import { JsonObject, JsonProperty } from 'json2typescript'
import PersonalDetails from './PersonalDetails'
import Address from './Address'
import { DefinedListDetail } from './DefinedList'

@JsonObject('PetProposerModel')
export default class PetProposer extends PersonalDetails {
  @JsonProperty('petCoverType', DefinedListDetail)
  petCoverType = new DefinedListDetail()

  @JsonProperty('petCoverSubType', DefinedListDetail)
  petCoverSubType = new DefinedListDetail()

  @JsonProperty('address', Address)
  address = new Address()

  @JsonProperty('ukResident', Boolean)
  ukResident: boolean | null = true

  @JsonProperty('petsKeptAtProposerAddress', Boolean)
  petsKeptAtProposerAddress = true

  @JsonProperty('liveInPremisesSellingAlcohol', Boolean)
  liveInPremisesSellingAlcohol = false

  @JsonProperty('anyLegalActionOrComplaints', Boolean)
  anyLegalActionOrComplaints = false

  @JsonProperty('usedForBreedingOrConnectedToBusiness', Boolean)
  usedForBreedingOrConnectedToBusiness = false
}
