import { JsonObject, JsonProperty } from 'json2typescript'
import DateConverter from '../../converters/DateConverter'
import { DefinedListDetail } from './DefinedList'

@JsonObject('TouringCaravanClaim')
export default class TouringCaravanClaim {
  @JsonProperty('claimType', DefinedListDetail)
  claimType: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('claimDate', DateConverter, true)
  claimDate: Date | null = null

  @JsonProperty('claimCost', Number, true)
  claimCost: number | null = null
}
