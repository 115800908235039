import _Vue from 'vue'

const bus = new _Vue()

export class EventBus {
  on(event: string, callback: (payload?: any) => void): void {
    bus.$on(event, callback)
  }

  emit(event: string, payload?: any): void {
    bus.$emit(event, payload)
  }

  off(event: string, payload?: any): void {
    bus.$off(event, payload)
  }
}

export default function EventBusPlugin(Vue: typeof _Vue): void {
  Vue.prototype.$events = new EventBus()
}
