import type _Vue from 'vue'
import type PaymentGatewayDetails from '@/services/values/PaymentGatewayDetails'
import IgnitePaymentGatewayModal from '@/components/payment/IgnitePaymentGatewayModal.vue'

export interface IPaymentGateway {
  showPrompt: (parent: Vue, details: PaymentGatewayDetails,) => Promise<{ cancelled: boolean, outcome: string | null, encryptedResponse: string | null }>
}

class PaymentGateway implements IPaymentGateway {
  private vue: typeof _Vue

  constructor(Vue: typeof _Vue) {
    this.vue = Vue
  }

  showPrompt(parent: Vue, details: PaymentGatewayDetails): Promise<{ cancelled: boolean, outcome: string | null, encryptedResponse: string | null }> {
    // eslint-disable-next-line no-console
    console.log('payment-gateway -- showPrompt')
    const PaymentGatewayModalComponent = this.vue.extend(IgnitePaymentGatewayModal)
    const component = new PaymentGatewayModalComponent({
      el: document.createElement('div'),
      propsData: {
        paymentGatewayDetails: details,
      },
    })

    parent.$el.appendChild(component.$el)

    return new Promise<{ cancelled: boolean, outcome: string | null, encryptedResponse: string | null }>((resolve) => {
      component.$off('complete')
      component.$off('cancel')

      function cancelled() {
        resolve({ cancelled: true, outcome: null, encryptedResponse: null })
        parent.$el.removeChild(component.$el)
        component.$destroy()
      }

      // required for back/forward button clicks
      // component currently is not registered in Vue
      // properly to receive normal destroy lifecycle events
      function popStateHandler() {
        // eslint-disable-next-line no-console
        console.log('payment-gateway -- Popstate')
        cancelled()
        window.removeEventListener('popstate', popStateHandler)
      }
      window.addEventListener('popstate', popStateHandler)

      component.$on('complete', (data: any) => {
        // eslint-disable-next-line no-console
        console.log('payment-gateway -- complete')
        resolve({ cancelled: false, outcome: data.outcome, encryptedResponse: data.encryptedResponse })
        parent.$el.removeChild(component.$el)
        component.$destroy()
      })
      component.$on('cancel', () => {
        // eslint-disable-next-line no-console
        console.log('payment-gateway -- Cancelled')
        cancelled()
      })
    })
  }
}

export default function PaymentGatewayPlugin(Vue: typeof _Vue): void {
  Vue.prototype.$paymentGateway = new PaymentGateway(Vue)
}
