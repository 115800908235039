import { JsonObject, JsonProperty } from 'json2typescript'
import Car from './Car'
import { AdditionalDriver, CarProposer } from './Driver'
import QuoteRequest from './QuoteRequest'
import DateConverter from '@/converters/DateConverter'

@JsonObject('CarQuoteRequest')
export default class CarQuoteRequest extends QuoteRequest {
  @JsonProperty('car', Car)
  car: Car = new Car()

  @JsonProperty('proposer', CarProposer)
  proposer: CarProposer = new CarProposer()

  @JsonProperty('coverType', String, true)
  coverType: string | null = null

  @JsonProperty('classOfUse', String)
  classOfUse = ''

  @JsonProperty('mileage', Number, true)
  mileage: number | null = null

  @JsonProperty('businessMileage', Number, true)
  businessMileage: number | null = null

  @JsonProperty('storage', String, true)
  storage: string | null = null

  @JsonProperty('policyDuration', String, true)
  policyDuration: string | null = null

  @JsonProperty('referredBy', String, true)
  referredBy: string | null = null

  @JsonProperty('isInsuredAnnually', String, true)
  isInsuredAnnually: string | null = null

  @JsonProperty('isImpounded', String, true)
  isImpounded: string | null = null

  @JsonProperty('voluntaryExcess', String, true)
  voluntaryExcess: string | null = null

  @JsonProperty('policyClass', String, true)
  policyClass: string | null = null

  @JsonProperty('currentInsurer', String, true)
  currentInsurer: string | null = null

  @JsonProperty('claimFreeYears', Number, true)
  claimFreeYears: number | null = null

  @JsonProperty('policyEndDate', DateConverter, true)
  policyEndDate: Date | null = null

  @JsonProperty('drivingOtherCarsOnOtherPolicy', String, true)
  drivingOtherCarsOnOtherPolicy: string | null = null

  @JsonProperty('registeredDVLAMotorhome', Boolean, true)
  registeredDVLAMotorhome: boolean | null = null

  @JsonProperty('conversionCompleted', Boolean, true)
  conversionCompleted: boolean | null = null

  @JsonProperty('modifiedEngineOrDrive', Boolean, true)
  modifiedEngineOrDrive: boolean | null = null

  @JsonProperty('noClaimsProtection', String, true)
  noClaimsProtection: string | null = null

  @JsonProperty('additionalDrivers', [AdditionalDriver])
  additionalDrivers: AdditionalDriver[] = []

  @JsonProperty('coverTerm', String, true)
  coverTerm: string | null = null

  @JsonProperty('registeredOutsideUKReason', String, true)
  registeredOutsideUKReason: string | null = null

  @JsonProperty('userSpecifiedNumberOfDrivers', String, true)
  userSpecifiedNumberOfDrivers: string | null = null

  @JsonProperty('reasonForCover', String, true)
  reasonForCover: string | null = null
}

@JsonObject('CarMTARequest')
export class CarMTAQuoteRequest extends CarQuoteRequest {
  @JsonProperty('reasonForChange', String)
  reasonForChange = ''
}
