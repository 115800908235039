import { JsonObject, JsonProperty } from 'json2typescript'
import DateConverter from '../../converters/DateConverter'
import { DefinedListDetail } from './DefinedList'
import Model from './Model'

@JsonObject('Claim')
export default class Claim extends Model {
  @JsonProperty('claimDate', DateConverter)
  claimDate: Date

  @JsonProperty('incidentDate', DateConverter)
  incidentDate: Date

  @JsonProperty('claimType', DefinedListDetail)
  claimType: DefinedListDetail

  @JsonProperty('claimStatus', DefinedListDetail)
  claimStatus: DefinedListDetail

  @JsonProperty('claimFault', Boolean)
  claimFault: boolean

  @JsonProperty('claimPersonsInjured', Boolean)
  claimPersonsInjured: boolean

  @JsonProperty('claimCost', Number)
  claimCost: number

  @JsonProperty('ncbLost', Boolean)
  ncbLost: boolean

  constructor(
    id = 0,
    claimDate = new Date(),
    incidentDate = new Date(),
    claimType = new DefinedListDetail(),
    claimStatus = new DefinedListDetail(),
    claimFault = false,
    claimPersonsInjured = false,
    claimCost = 0,
    ncbLost = false,
  ) {
    super(id)
    this.claimDate = claimDate
    this.incidentDate = incidentDate
    this.claimType = claimType
    this.claimStatus = claimStatus
    this.claimFault = claimFault
    this.claimPersonsInjured = claimPersonsInjured
    this.claimCost = claimCost
    this.ncbLost = ncbLost
  }
}
