import type Routes from '@/constants/Routes'

export default class Step {
  label: string

  icon: string

  routes: Routes[]

  pack: string | undefined

  constructor(label: string, icon: string, routes: Routes[], pack?: string) {
    this.label = label
    this.icon = icon
    this.routes = routes
    this.pack = pack
  }
}
