import { JsonObject, JsonProperty } from 'json2typescript'
import DateConverter from '@/converters/DateConverter'

@JsonObject('CancellationRequest')
export default class CancellationRequest {
  @JsonProperty('effectiveDate', DateConverter)
  effectiveDate: Date = new Date()

  @JsonProperty('currentMileage', Number, true)
  currentMileage: number | null = null
}
