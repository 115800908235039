import { JsonObject, JsonProperty } from 'json2typescript'
import DateConverter from '../../converters/DateConverter'
import PersonalDetails from './PersonalDetails'
import Address from './Address'

@JsonObject('GapProposerModel')
export default class GapProposer extends PersonalDetails {
  @JsonProperty('address', Address)
  address = new Address()

  @JsonProperty('coverTerm', Number, true)
  coverTerm: number | null = null

  @JsonProperty('emailAddress', String, true)
  emailAddress: string | null = null

  @JsonProperty('hasAdditionalApplicant', Boolean)
  hasAdditionalApplicant: boolean | null = null

  @JsonProperty('needCoverForAdditionalDriver', Boolean)
  needCoverForAdditionalDriver: boolean | null = null

  @JsonProperty('noClaimDiscount', Number)
  noClaimDiscount: number | null = null

  @JsonProperty('telephone', String, true)
  telephone: string | null = null

  @JsonProperty('companyName', String, true)
  companyName: string | null = null

  @JsonProperty('ukResidentFromBirth', Boolean)
  ukResidentFromBirth: boolean | null = null

  @JsonProperty('ukResidentFromDate', DateConverter)
  ukResidentFromDate: Date | null = null
}
