import { JsonObject, JsonProperty } from 'json2typescript'
import PetProposer from './PetProposer'
import Pet from './Pet'
import QuoteRequest from './QuoteRequest'

@JsonObject('PetQuoteRequest')
export default class PetQuoteRequest extends QuoteRequest {
  @JsonProperty('proposer', PetProposer)
  proposer: PetProposer = new PetProposer()

  @JsonProperty('pets', [Pet])
  pets: Pet[] = []

  @JsonProperty('coverLevelOrType', String)
  coverLevelOrType: string | null = ''
}

@JsonObject('PetMTAQuoteRequest')
export class PetMTAQuoteRequest extends PetQuoteRequest {
  @JsonProperty('reasonForChange', String)
  reasonForChange = ''
}
