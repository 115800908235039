export default class NotFoundError implements Error {
  name: string

  message: string

  stack?: string

  constructor() {
    this.name = 'NotFoundError'
    this.message = 'The requested resouce could not be found.'
  }
}
