import type ITransitionAction from '@/page-transitions/transition-actions/ITransitionAction'
import ContextLevel from '@/services/enums/ContextLevel'
import type IJourneyPage from '@/mixins/IJourneyPage'
import type ProductEnum from '@/services/enums/ProductEnum'
import { RatingStatus } from '@/services/values/RatingResponse'
import Routes from '@/constants/Routes'
import type { PageNavigationConfig } from '@/view-models/PageNavigationConfig'
import useAnswersStore from '@/stores/answers-store'
import useAggregatorStore from '@/stores/aggregator-store'
import BadRequestError from '@/services/error/BadRequestError'

export default class GetQuoteAction implements ITransitionAction {
  canExecute(currentContext: ContextLevel, nextContext: ContextLevel): boolean {
    return currentContext === ContextLevel.preQuote && nextContext === ContextLevel.quote
  }

  async execute(page: IJourneyPage): Promise<PageNavigationConfig | null> {
    page.$events.emit('set-loading-text', 'Getting you a price...')
    page.isLoading = true

    const answersStore = useAnswersStore()
    const aggregatorStore = useAggregatorStore()

    const currentAnswers = answersStore.answers
    const { product } = page.$route.params
    try {
      const ratingResponse = await page.$services.ratingEngineService.getQuote(currentAnswers, aggregatorStore.aggregatorQuoteReference, product as ProductEnum, page.$route.query)
      if (ratingResponse.status === RatingStatus.quoted) {
        page.isLoading = false
        return {
          params: {
            quoteReference: ratingResponse.quoteReference!,
          },
        }
      }
      page.$router.push({
        name: Routes.quoteDeclined,
        params: {
          quoteReference: ratingResponse.quoteReference ?? '',
        },
      })
      page.isLoading = false
    }
    catch (e) {
      if (e instanceof BadRequestError) {
        page.$buefy.toast.open({
          duration: 5000,
          message: (Object.values(e.modelState!)[0]) ?? e.message,
          type: 'is-danger',
          position: 'is-bottom',
        })
      }
      else {
        throw e
      }
    }
    return null
  }
}
