import { JsonObject, JsonProperty } from 'json2typescript'
import DateConverter from '@/converters/DateConverter'

@JsonObject('Pet')
export default class Pet {
  @JsonProperty('petType', String)
  petType = ''

  @JsonProperty('name', String)
  name = ''

  @JsonProperty('gender', String)
  gender = ''

  @JsonProperty('dateOfBirth', DateConverter)
  dateOfBirth: Date = new Date()

  @JsonProperty('petSubType', String)
  petSubType = ''

  @JsonProperty('breed', String)
  breed = ''

  @JsonProperty('size', String)
  size: string | null = null

  @JsonProperty('costOfPet', Number)
  costOfPet = 0

  @JsonProperty('hasBeenNeutered', Boolean)
  hasBeenNeutered = true

  @JsonProperty('hasBeenChipped', Boolean)
  hasBeenChipped: boolean | null = true

  @JsonProperty('hasUpToDateVaccinations', Boolean)
  hasUpToDateVaccinations = true

  @JsonProperty('proposerOwnsPet', Boolean)
  proposerOwnsPet = true

  @JsonProperty('hasAttacked', Boolean)
  hasAttacked = false

  @JsonProperty('everSeenVetForIllness', Boolean)
  everSeenVetForIllness = false

  @JsonProperty('isAwaitingReview', Boolean)
  isAwaitingReview: boolean | null = false

  @JsonProperty('coverPreExistingConditions', Boolean)
  coverPreExistingConditions = false

  @JsonProperty('veriskResponse', String)
  veriskResponse: string | null = null

  @JsonProperty('dominantBreed', String)
  dominantBreed: string | null = null

  @JsonProperty('dominantBreedSize', String)
  dominantBreedSize: string | null = null

  @JsonProperty('hasCausedLegalIncident', Boolean)
  hasCausedLegalIncident = false

  @JsonProperty('trackerUniqueId', String, true)
  trackerUniqueId: string | null = null

  @JsonProperty('prn', Number)
  prn: number | null = null

  @JsonProperty('isDeceased', Boolean)
  isDeceased: boolean | null = false

  @JsonProperty('dateRemoved', DateConverter)
  dateRemoved: Date | null = null
}
