import { JsonObject, JsonProperty } from 'json2typescript'
import DateConverter from '@/converters/DateConverter'

@JsonObject('Vehicle')
export default class Vehicle {
  @JsonProperty('registration', String)
  registration = ''

  @JsonProperty('vin', String)
  vin = ''

  @JsonProperty('abiCode', String)
  abiCode = ''

  @JsonProperty('fuelType', String)
  fuelType = ''

  @JsonProperty('make', String)
  make = ''

  @JsonProperty('model', String)
  model = ''

  @JsonProperty('engineSize', Number)
  engineSize = 0

  @JsonProperty('bodyType', String)
  bodyType = ''

  @JsonProperty('transmissionType', String)
  transmissionType = ''

  @JsonProperty('value', Number)
  value = 0

  @JsonProperty('noOfSeats', Number)
  noOfSeats: number | null = 0

  @JsonProperty('purchaseDate', DateConverter)
  purchaseDate: Date | null = new Date()

  @JsonProperty('yearOfManufacture', Number)
  yearOfManufacture: number | null = 0

  @JsonProperty('dateFirstRegistered', DateConverter)
  dateFirstRegistered: Date | null = new Date()
}
