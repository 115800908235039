<template>
  <div id="app" :data-tenant="tenantConfigName" :class="[quoteJourney ? 'quote-journey' : '', `${currentRoute}-page`]">
    <b-navbar :close-on-click="!quoteJourney" :mobile-burger="!quoteJourney" :active="account == null && !quoteJourney">
      <template slot="brand">
        <div class="agg-to-broker" v-if="(aggregatorClient || affiliate) && quoteJourney">
          <div class="agg-logo" v-if="aggregatorClient">
            <img alt="Aggregator Logo" :src="`data:image/png;base64,${aggregatorClient.img}`"
              v-if="aggregatorClient.img" />
            <span v-else><b>{{ aggregatorClient.name }}</b></span>
          </div>
          <div class="agg-logo" v-else-if="affiliate">
            <img alt="Affiliate Logo" :src="affiliate.logo" v-if="affiliate.logo" />
            <span v-else><b>{{ affiliate.name }}</b></span>
          </div>
          <div class="agg-arrow">
            <i class="fas fa-long-arrow-alt-right"></i>
          </div>
          <div class="brand-logo">
            <a :href="logoUrl">
              <img v-if="headerLogoPath" ref="brand-logo" alt="Logo" :src="headerLogoPath" />
            </a>
          </div>
        </div>
        <div class="level site-logo" v-else>
          <b-navbar-item :href="getLogoUrl()">          
            <img v-if="headerLogoPath" ref="brand-logo" alt="Logo" :src="headerLogoPath">
          </b-navbar-item>
        </div>
        <div class="navbar-contact" v-if="headerText">
          <div class="level">
            <IgniteMarkdown class="content">{{ headerText }}</IgniteMarkdown>
          </div>
        </div>
      </template>
      <template slot="end">
        <div class="navbar-item navbar-item-wrapper"
          v-if="!quoteJourney && portalEnabled && account">
          <span class="router-links">
            <router-link class="navbar-item" :to="dashboardRoute">
              Dashboard
            </router-link>
            <router-link class="navbar-item" :to="quotesRoute">
              Quotes
            </router-link>
            <router-link class="navbar-item" :to="policiesRoute">
              Policies
            </router-link>
            <router-link class="navbar-item" :to="claimsRoute">
              Claims
            </router-link>
            <template v-for="(a, i) in actions">
              <LinkConfigButton class="navbar-item" :config="a" :key="i" :isAnchor="true" />
            </template>
          </span>
          <div class="navbar-item navbar-item-wrapper">
            <div class="buttons">
              <b-dropdown hoverable>
                <b-button slot="trigger">
                  Account
                  <b-icon icon="menu-down"></b-icon>
                </b-button>
                <b-dropdown-item custom>
                  Logged in as <b>{{ `${account.firstName} ${account.lastName}` }}</b>
                </b-dropdown-item>
                <hr class="dropdown-divider">
                <b-dropdown-item has-link>
                  <router-link :to="editAccountRoute">
                    Edit
                  </router-link>
                </b-dropdown-item>
                <b-dropdown-item @click="logout">
                  Logout
                </b-dropdown-item>
              </b-dropdown>
              <b-button v-if="specialButton" type="is-primary" @click="specialButtonClick">
                {{ specialButton.name }}
              </b-button>
            </div>
          </div>
        </div>
        <div v-else-if="quoteJourney">
          <b-steps v-if="steps.length > 0" v-model="currentStep" animated :has-navigation="false" size="is-small">
            <b-step-item v-for="(s, i) in steps" :key="i" :label="s.label" :icon="s.icon" :icon-pack="s.pack"
              :clickable="false" />
          </b-steps>
        </div>
        <div v-else class="navbar-item navbar-item-wrapper">
          <div class="navbar-item navbar-item-wrapper">
            <div class="buttons">
              <b-button v-for="(product) in products" :key="product.UniqueId" tag="router-link"
                :to="getProductRoute(product)" :label="getNavLink(product)">
              </b-button>
            </div>
          </div>
        </div>
      </template>
    </b-navbar>

    <router-view class="app-content" />

    <TheFooter :footerLogoPath="footerLogoPath" :footer="getFooter()" :tenantName="getTenantFriendlyName()" :socialLinks="socialLinks"
      :footerLinks="getFooterLinks()" />
  </div>
</template>

<script lang="ts">
import { Vue, Watch, Component } from 'vue-property-decorator';

import Routes from '@/constants/Routes';
import { LinkConfig } from '@/configurations/IConfiguration';
import { RawLocation } from 'vue-router';
import Product from './services/models/Product';
import ProductEnum from './services/enums/ProductEnum';
import Step from './view-models/Step';
import DisplayMode from './constants/DisplayMode';
import { Dictionary } from './types';
import Account from './services/models/Account';
import AggregatorClient from './services/models/AggregatorClient';
import useAccountStore from './stores/account-store';
import useAggregatorStore from './stores/aggregator-store';
import TenantEnum from './services/enums/TenantEnum';
import WrapperUtils from './utils/WrapperUtils';
import { TenantConfigName } from './services/types/TenantConfigName';

const DEFAULT_LOGO_PATH = "/assets/img/common/logo-default.png"

interface AnalyticsWindow extends Window {
  dataLayer: Dictionary<string>[];
  injectedEnv: Dictionary<string>;
}

declare const window: AnalyticsWindow;

@Component({
  components: {
    IgniteMarkdown: () => import('@/components/controls/IgniteMarkdown.vue'),
    TheFooter: () => import('@/components-ignite/TheFooter.vue'),
    LinkConfigButton: () => import('@/components/LinkConfigButton.vue'),
  },
})
export default class App extends Vue {
  quoteJourney = false;

  currentStep = 0;

  steps: Step[] = [];

  specialButton: LinkConfig | null = this.$configurations.specialButton;

  buildNumber = import.meta.env.VUE_APP_BUILDNUMBER;

  products: Product[] = [];

  get headerLogoPath() : string | null{
    return this.getHeaderLogoPath();
  }

  get footerLogoPath() : string | null{
    return this.getFooterLogoPath();
  }

  get currentRoute(): string | null {
    return this.$route.name ?? null;
  }

  get dashboardRoute(): RawLocation {
    return {
      name: Routes.home,
    };
  }

  get quotesRoute(): RawLocation {
    return {
      name: Routes.quotes,
    };
  }

  get policiesRoute(): RawLocation {
    return {
      name: Routes.policies,
    };
  }

  get claimsRoute(): RawLocation {
    return {
      name: Routes.claims,
    };
  }

  get editAccountRoute(): RawLocation {
    return {
      name: Routes.account,
    };
  }

  get actions(): LinkConfig[] {
    return this.$configurations.accountHeaderActions;
  }

  getProductRoute(product: Product): RawLocation {
    return {
      name: Routes.productJourney,
      params: {
        product: product.uniqueId,
      },
    };
  }

  getLogoUrl(): string {
    return this.brandingProduct && this.$utils.useAlternateBranding(this.brandingProduct)
      ? this.$configurations.logoUrlForProduct(this.brandingProduct) ?? ''
      : this.$configurations.logoUrl;
  }

  getHeaderLogoPath(): string | null {
    return this.brandingProduct
      ? this.$configurations.headerLogoPathForProduct(this.brandingProduct) ?? this.$configurations.headerLogoPath
      : this.$configurations.headerLogoPath;
  }

  getFooterLogoPath(): string | null {
    return this.brandingProduct
      ? this.$configurations.footerLogoPathForProduct(this.brandingProduct) ?? this.$configurations.footerLogoPath
      : this.$configurations.footerLogoPath;
  }

  getFooterLinks(): LinkConfig[] | null {
    return this.brandingProduct && this.$utils.useAlternateBranding(this.brandingProduct)
      ? this.$configurations.footerLinksForProduct(this.brandingProduct) 
      : this.$configurations.footerLinks;
  }

  getFooter(): string {
    return this.brandingProduct && this.$utils.useAlternateBranding(this.brandingProduct)
      ? this.$configurations.footerTextForProduct(this.brandingProduct) ?? ''
      : this.$configurations.footer;
  }

  getTenantFriendlyName(): string {
    return this.$utils.getTenantFriendlyName(this.brandingProduct, this.$configurations);
  }

  getNavLink(product: Product): string {
    return this.$configurations.navLinkForProduct(product.uniqueId as ProductEnum) ?? `Insure a ${product.displayName}`;
  }
  
  get tenantName(): string {
    return this.$configurations.tenantFriendlyName;
  }

  get tenantConfigName(): TenantConfigName {
    return this.$configurations.tenantConfigName;
  }

  get buildNo(): string {
    return this.buildNumber;
  }

  get logoUrl(): string {
    return this.$configurations.logoUrl;
  }  

  get headerText(): string {
    return this.$configurations.headerText;
  }

  get footerLinks(): LinkConfig[] {
    return this.$configurations.footerLinks;
  }

  get socialLinks(): LinkConfig[] {
    return this.$configurations.socialLinks;
  }

  get portalEnabled(): boolean {
    return this.$configurations.features.portal;
  }

  get account(): Account | null {
    const accountStore = useAccountStore();
    if (accountStore.account) {
      this.$chat.setSession(accountStore.account);
      this.$analytics.identifyUser(accountStore.account, window);
    }
    return accountStore.account;
  }

  get aggregatorClient(): AggregatorClient | null {
    const aggregatorStore = useAggregatorStore();
    return aggregatorStore.client;
  }

  get affiliate(): { name: string, logo: URL | null } | null {
    const aggregatorStore = useAggregatorStore();
    const aff = aggregatorStore.affiliate;
    if (!aff) return null;
    return {
      name: aff.name,
      logo: aff.logoFile ? this.$services.documentService.generateImageURL(aff.logoFile) : null,
    };
  }

  private product: ProductEnum | null = null;
  private brandingProduct: ProductEnum | null = null;
  private wrapperUtils: WrapperUtils = new WrapperUtils();

  get currentProduct(): ProductEnum | null {
    return this.product;
  }

  set currentProduct(val: ProductEnum | null) {
    this.product = val;
    this.updateSteps();
  }

  @Watch('currentRoute', { deep: true })
  async routeChanged(): Promise<void> {
    document.title = this.$route.meta?.title ?? document.title;
    this.brandingProduct = this.$route.params.product as ProductEnum | null ?? this.$route.query.product as ProductEnum | null;
    const processTenant = `${window.injectedEnv.FRONTEND_TENANT}` as TenantEnum
    if (processTenant === TenantEnum.Wrapper)  {
      // Temporary hack for pages that are outside of the product journey but require alternate branding
      // These pages need to be ultimately moved into the product journeys at which point this code can be removed.
      if (this.$router.currentRoute.name === Routes.quoteDeclined || this.$router.currentRoute.name === Routes.quoteReferred) {
        this.brandingProduct = await this.wrapperUtils.getProductFromQuoteReference(this.$route.params.quoteReference, this.$services.riskService);
      };
    }

    this.$utils.handleBrandSwitching(this.brandingProduct);
    document.title = this.$utils.getPageTitle(document.title, this.$configurations, this.brandingProduct)

    this.quoteJourney = this.$route.meta?.displayMode === DisplayMode.journey || this.$route.matched.some(r => r.meta.displayMode === DisplayMode.journey);
    this.currentProduct = this.$route.params.product as ProductEnum | undefined ?? this.currentProduct;
    this.updateSteps();
  }

  async created(): Promise<void> {
    this.$utils.checkConsent(this.$configurations.cookiePolicyNotice!);
    this.routeChanged();
    const response = await this.$services.productService.getAll();
    this.products = response.results;
    // Google Optimize Patch
    const observer = new MutationObserver(() => window.dataLayer?.push({ event: 'optimize.activate' }));
    observer.observe(this.$el, { childList: true, subtree: true });
  }

  updateSteps(): void {
    if (this.currentRoute && this.currentProduct) {
      this.steps = this.$configurations.stepsForProduct(this.currentProduct, this.$route);
      const index = this.steps.findIndex(s => s.routes.indexOf(this.currentRoute as Routes) !== -1);
      this.currentStep = index !== -1 ? index : 0;
    }
  }

  specialButtonClick(): void {
    this.$configurations.specialButton!.click(this);
  }

  async logout(): Promise<void> {
    await this.$services.identityProvider.logout();
    const accountStore = useAccountStore();
    accountStore.clearAccount();
    window.location.href = '/';
  }
}
</script>
