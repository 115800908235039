export default class UnknownProductError implements Error {
  name: string

  message: string

  stack?: string

  constructor(product: string) {
    this.name = 'UnkownProductError'
    this.message = `The product (${product}) is not recognised.`
  }
}
