export default function FormatCardNumber(e: any, binding: any) {
  const newVal = e.computedValue.replace(/[^0-9]+/g, '')
  if (newVal.startsWith('34') || newVal.startsWith('37')) {
    const blockVal = [newVal.slice(0, 4), newVal.slice(4, 10), newVal.slice(10, 15)].filter(x => x.length > 0).join(' ')
    e.computedValue = blockVal
    binding.value.onCreditCardTypeChanged('amex')
    return
  }
  if (newVal.startsWith('4')) {
    const blockVal = [newVal.slice(0, 4), newVal.slice(4, 8), newVal.slice(8, 12), newVal.slice(12, 16)].filter(x => x.length > 0).join(' ')
    e.computedValue = blockVal
    binding.value.onCreditCardTypeChanged('visa')
    return
  }
  if ((Number.parseInt(newVal.slice(0, 4), 10) >= 2221 && Number.parseInt(newVal.slice(0, 4), 10) <= 2720) || (Number.parseInt(newVal.slice(0, 2), 10) >= 51 && Number.parseInt(newVal.slice(0, 2), 10) <= 55)) {
    const blockVal = [newVal.slice(0, 4), newVal.slice(4, 8), newVal.slice(8, 12), newVal.slice(12, 16)].filter(x => x.length > 0).join(' ')
    e.computedValue = blockVal
    binding.value.onCreditCardTypeChanged('mastercard')
    return
  }
  e.computedValue = newVal
  binding.value.onCreditCardTypeChanged('unknown')
}
