import type IRequestMapper from './interfaces/IRequestMapper'
import type { IQuestionAnswer } from './values/PageAnswers'
import type IValidationService from './interfaces/IValidationService'

export default class ValidationService implements IValidationService {
  requestMapper: IRequestMapper

  constructor(requestMapper: IRequestMapper) {
    this.requestMapper = requestMapper
  }

  async validate(answers: IQuestionAnswer[]): Promise<boolean> {
    return this.requestMapper.validate(answers)
  }
}
