import type { LinkConfig } from '@/configurations/IConfiguration'
import type PolicySummary from '@/services/values/PolicySummary'

export function actions(policy: PolicySummary): LinkConfig[] {
  return this.$configurations.actionsForPolicy(policy)
}

export function actionClick(action: LinkConfig): void {
  action.click(this)
}
