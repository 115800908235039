import moment from 'moment'
import type IQuestion from '../interfaces/IQuestion'
import type { UIFieldSetField } from '../models/UIField'
import UIFieldTypeEnum from '../enums/UIFieldTypeEnum'
import Question, { AdditionalPersonsQuestion, AdditionalVehiclesQuestion, AddressQuestion, ClaimsQuestion, ConvictionsQuestion, DateQuestion, DrivingLicenceQuestion, MarkdownQuestion, MedicalConditionsQuestion, ModalQuestion, ModificationQuestion, NumberQuestion, PetsQuestion, RelatedCustomSelectQuestion, RelatedRadioList, RelatedSelectQuestion, SelectOption, SelectQuestion, SpecifiedItemsQuestion, StorageLocationQuestion, TextConfirmQuestion, TextQuestion, VehicleLookupQuestion, VeriskQuestion } from '../../view-models/Question'
import QuestionEnum from '../enums/QuestionEnum'
import HTMLInputTypeEnum from '../enums/HTMLInputTypeEnum'
import InputComponentTypeEnum from '../enums/InputComponentTypeEnum'
import UnknownQuestionTypeError from '../error/UnknownQuestionTypeError'
import Page from '../../view-models/Page'
import type Account from '../models/Account'
import type { LicenseHeldYearsEnum, MonthsStoredAtLocationEnum, NCDYearsEnum, NumberOfCaravansOnSiteEnum } from '../enums/DLDCommonValuesEnum'
import DLDCommonValuesEnum from '../enums/DLDCommonValuesEnum'
import type ProductPage from '../models/ProductPage'
import PageAnswers, { QuestionAnswer } from '../values/PageAnswers'
import ContextLevel from '@/services/enums/ContextLevel'
import Section from '@/view-models/Section'
import type PrepopulatableValues from '@/services/models/PrepopulatableValues'
import type { Dictionary } from '@/types'
import Routes from '@/constants/Routes'

export default class BaseMapper {
  nbPageMappings: Dictionary<{ route: Routes, contextLevel: ContextLevel }> = {
    ModernCar_Proposer_CustomerFacing: { route: Routes.scheme, contextLevel: ContextLevel.preQuote },
    ModernCar_Premium: { route: Routes.premium, contextLevel: ContextLevel.quote },
    ModernCar_Payment: { route: Routes.payment, contextLevel: ContextLevel.policy },
    ModernCar_PaymentConfirmation: { route: Routes.paymentConfirm, contextLevel: ContextLevel.policy },
  }

  protected mapUIFieldSetFieldToQuestion(ufsf: UIFieldSetField): IQuestion {
    const label = ufsf.questionLabel ? ufsf.questionLabel : ufsf.uiField.questionLabel
    if (!ufsf.uiField.type?.name)
      throw new Error(`UIField(${ufsf.uiField.name}) has no type`)

    switch (ufsf.uiField.type.name.toLowerCase()) {
      case UIFieldTypeEnum.number.toLowerCase():
        return new NumberQuestion(
          ufsf.id,
          label,
          ufsf.uiField.name as QuestionEnum,
          ufsf.isMandatory,
          null,
          ufsf.customerHelpText ?? ufsf.uiField.customerHelpText,
          null,
          null,
          ufsf.uiField.config,
          ufsf.visible,
        )
      case UIFieldTypeEnum.text.toLowerCase():
        return new TextQuestion(
          ufsf.id,
          label,
          ufsf.uiField.name as QuestionEnum,
          HTMLInputTypeEnum.text,
          ufsf.isMandatory,
          undefined,
          null,
          ufsf.customerHelpText ?? ufsf.uiField.customerHelpText,
          ufsf.uiField.config,
          ufsf.uiField.regularExpression,
          ufsf.uiField.regularExpressionValidationError,
          ufsf.visible,
        )
      case UIFieldTypeEnum.tel.toLowerCase():
        return new TextQuestion(
          ufsf.id,
          label,
          ufsf.uiField.name as QuestionEnum,
          HTMLInputTypeEnum.tel,
          ufsf.isMandatory,
          undefined,
          null,
          ufsf.customerHelpText ?? ufsf.uiField.customerHelpText,
          ufsf.uiField.config,
          null,
          null,
          ufsf.visible,
        )
      case UIFieldTypeEnum.textarea.toLowerCase():
        return new TextQuestion(
          ufsf.id,
          label,
          ufsf.uiField.name as QuestionEnum,
          HTMLInputTypeEnum.textarea,
          ufsf.isMandatory,
          150,
          null,
          ufsf.customerHelpText ?? ufsf.uiField.customerHelpText,
          ufsf.uiField.config,
          null,
          null,
          ufsf.visible,
        )
      case UIFieldTypeEnum.email.toLowerCase():
        return new TextQuestion(
          ufsf.id,
          label,
          ufsf.uiField.name as QuestionEnum,
          HTMLInputTypeEnum.email,
          ufsf.isMandatory,
          80,
          null,
          ufsf.customerHelpText ?? ufsf.uiField.customerHelpText,
          ufsf.uiField.config,
          null,
          null,
          ufsf.visible,
        )
      case UIFieldTypeEnum.confirmEmail.toLowerCase():
        return new TextConfirmQuestion(
          ufsf.id,
          label,
          ufsf.uiField.name as QuestionEnum,
          InputComponentTypeEnum.textConfirm,
          HTMLInputTypeEnum.email,
          ufsf.isMandatory,
          80,
          null,
          ufsf.customerHelpText ?? ufsf.uiField.customerHelpText,
          ufsf.uiField.config,
          null,
          null,
          ufsf.visible,
        )
      case UIFieldTypeEnum.date.toLowerCase():
        return new DateQuestion(
          ufsf.id,
          label,
          ufsf.uiField.name as QuestionEnum,
          InputComponentTypeEnum.date,
          ufsf.isMandatory,
          false,
          false,
          null,
          ufsf.customerHelpText ?? ufsf.uiField.customerHelpText,
          null,
          null,
          null,
          !!ufsf.uiField.config.displayDateBtns,
          ufsf.visible,
          ufsf.uiField.config,
        )
      case UIFieldTypeEnum.dateSelect.toLowerCase():
        return new DateQuestion(
          ufsf.id,
          label,
          ufsf.uiField.name as QuestionEnum,
          InputComponentTypeEnum.dateSelect,
          ufsf.isMandatory,
          false,
          false,
          null,
          ufsf.customerHelpText ?? ufsf.uiField.customerHelpText,
          null,
          null,
          null,
          !!ufsf.uiField.config.displayDateBtns,
          ufsf.visible,
        )
      case UIFieldTypeEnum.datetime.toLowerCase():
        return new DateQuestion(
          ufsf.id,
          label,
          ufsf.uiField.name as QuestionEnum,
          InputComponentTypeEnum.date,
          ufsf.isMandatory,
          true,
          false,
          null,
          ufsf.customerHelpText ?? ufsf.uiField.customerHelpText,
          null,
          null,
          Number.parseInt(ufsf.uiField.config.incrementMinutes, 10),
          !!ufsf.uiField.config.displayDateBtns,
          ufsf.visible,
        )
      case UIFieldTypeEnum.checkbox.toLowerCase():
        return new Question<boolean>(
          ufsf.id,
          label,
          ufsf.uiField.name as QuestionEnum,
          InputComponentTypeEnum.checkbox,
          ufsf.isMandatory,
          null,
          ufsf.customerHelpText ?? ufsf.uiField.customerHelpText,
          null,
          ufsf.uiField.config,
          ufsf.visible,
        )
      case UIFieldTypeEnum.declarationCheckbox.toLowerCase():
        return new Question<boolean>(
          ufsf.id,
          label,
          ufsf.uiField.name as QuestionEnum,
          InputComponentTypeEnum.declarationCheckbox,
          ufsf.isMandatory,
          null,
          ufsf.customerHelpText ?? ufsf.uiField.customerHelpText,
          null,
          ufsf.uiField.config,
          ufsf.visible,
        )
      case UIFieldTypeEnum.businessSource.toLowerCase():
        return new SelectQuestion<number, number>(
          ufsf.id,
          label,
          ufsf.uiField.name as QuestionEnum,
          ufsf.uiField.config.displayAsBtns === 'true' ? InputComponentTypeEnum.radio : InputComponentTypeEnum.select,
          ufsf.isMandatory,
          ufsf.uiField.customListDetails!.map(
            bs => new SelectOption<number>(bs.name, bs.id, true),
          ),
          'Please select...',
          ufsf.customerHelpText ?? ufsf.uiField.customerHelpText,
          null,
          ufsf.uiField.config,
          ufsf.visible,
        )
      case UIFieldTypeEnum.relatedCustomSelectList.toLowerCase():
        return new RelatedCustomSelectQuestion(
          ufsf.id,
          label,
          ufsf.uiField.name as QuestionEnum,
          InputComponentTypeEnum.relatedCustomSelectList,
          ufsf.isMandatory,
          ufsf.uiField.customListDetails!.map(
            bs => new SelectOption<number>(bs.name, bs.id, true, bs.dependencyId!),
          ),
          ufsf.uiField.customListDetails!.map(
            bs => new SelectOption<number>(bs.name, bs.id, true, bs.dependencyId!),
          ),
          'Please select...',
          ufsf.customerHelpText ?? ufsf.uiField.customerHelpText,
          ufsf.customerUIDefault ? ufsf.customerUIDefault.definedListDetail.uniqueId : null,
          ufsf.uiField.config,
          ufsf.visible,
        )
      case UIFieldTypeEnum.select.toLowerCase():
        if (!ufsf.uiField.definedList)
          throw new Error(`UIField(${ufsf.uiField.name}) has no definedList`)

        return new SelectQuestion<string, string>(
          ufsf.id,
          label,
          ufsf.uiField.name as QuestionEnum,
          InputComponentTypeEnum.select,
          ufsf.isMandatory,
          ufsf.uiField.definedList!.definedListDetails.map(
            dld => new SelectOption<string>(dld.name, dld.uniqueId, dld.optionAsBtn),
          ),
          'Please select...',
          ufsf.customerHelpText ?? ufsf.uiField.customerHelpText,
          ufsf.customerUIDefault ? ufsf.customerUIDefault.definedListDetail.uniqueId : null,
          ufsf.uiField.config,
          ufsf.visible,
        )
      case UIFieldTypeEnum.relatedSelectList.toLowerCase():
        if (!ufsf.uiField.definedList)
          throw new Error(`UIField(${ufsf.uiField.name}) has no definedList`)

        return new RelatedSelectQuestion(
          ufsf.id,
          label,
          ufsf.uiField.name as QuestionEnum,
          InputComponentTypeEnum.relatedSelectList,
          ufsf.isMandatory,
          ufsf.uiField.definedList!.definedListDetails.map(
            dld => new SelectOption<string>(dld.name, dld.uniqueId, dld.optionAsBtn),
          ),
          ufsf.uiField.definedList!.definedListDetails,
          'Please select...',
          ufsf.customerHelpText ?? ufsf.uiField.customerHelpText,
          ufsf.customerUIDefault ? ufsf.customerUIDefault.definedListDetail.uniqueId : null,
          ufsf.uiField.config,
          ufsf.visible,
        )
      case UIFieldTypeEnum.autocomplete.toLowerCase():
        if (!ufsf.uiField.definedList)
          throw new Error(`UIField(${ufsf.uiField.name}) has no definedList`)

        return new SelectQuestion<string, string>(
          ufsf.id,
          label,
          ufsf.uiField.name as QuestionEnum,
          InputComponentTypeEnum.autocomplete,
          ufsf.isMandatory,
          ufsf.uiField.definedList!.definedListDetails.map(
            dld => new SelectOption<string>(dld.name, dld.uniqueId, dld.optionAsBtn),
          ),
          'Search...',
          ufsf.customerHelpText ?? ufsf.uiField.customerHelpText,
          ufsf.customerUIDefault ? ufsf.customerUIDefault.definedListDetail.uniqueId : null,
          ufsf.uiField.config,
          ufsf.visible,
        )
      case UIFieldTypeEnum.relatedAutoComplete.toLowerCase():
        if (!ufsf.uiField.definedList)
          throw new Error(`UIField(${ufsf.uiField.name}) has no definedList`)

        return new RelatedSelectQuestion(
          ufsf.id,
          label,
          ufsf.uiField.name as QuestionEnum,
          InputComponentTypeEnum.relatedAutoComplete,
          ufsf.isMandatory,
          ufsf.uiField.definedList!.definedListDetails.map(
            dld => new SelectOption<string>(dld.name, dld.uniqueId, dld.optionAsBtn),
          ),
          ufsf.uiField.definedList!.definedListDetails,
          'Please select...',
          ufsf.customerHelpText ?? ufsf.uiField.customerHelpText,
          ufsf.customerUIDefault ? ufsf.customerUIDefault.definedListDetail.uniqueId : null,
          ufsf.uiField.config,
          ufsf.visible,
        )
      case UIFieldTypeEnum.radio.toLowerCase():
        if (!ufsf.uiField.definedList)
          throw new Error(`UIField(${ufsf.uiField.name}) has no definedList`)

        return new SelectQuestion<string, string>(
          ufsf.id,
          label,
          ufsf.uiField.name as QuestionEnum,
          InputComponentTypeEnum.radio,
          ufsf.isMandatory,
          ufsf.uiField.definedList!.definedListDetails.map(
            dld => new SelectOption<string>(dld.name, dld.uniqueId, dld.optionAsBtn),
          ),
          'Please select...',
          ufsf.customerHelpText ?? ufsf.uiField.customerHelpText,
          ufsf.customerUIDefault ? ufsf.customerUIDefault.definedListDetail.uniqueId : null,
          ufsf.uiField.config,
          ufsf.visible,
        )
      case UIFieldTypeEnum.coInsurance.toLowerCase():
        return new SelectQuestion<string, number>(
          ufsf.id,
          label,
          ufsf.uiField.name as QuestionEnum,
          InputComponentTypeEnum.radio,
          ufsf.isMandatory,
          ufsf.uiField.coInsuranceRules!.map(
            cir => new SelectOption<number>(cir.percentage, cir.id, true),
          ),
          'Please select...',
          ufsf.customerHelpText ?? ufsf.uiField.customerHelpText,
          null,
          ufsf.uiField.config,
          ufsf.visible,
        )
      case UIFieldTypeEnum.multiSelect.toLowerCase():
        if (!ufsf.uiField.definedList)
          throw new Error(`UIField(${ufsf.uiField.name}) has no definedList`)

        return new SelectQuestion<string[], string>(
          ufsf.id,
          label,
          ufsf.uiField.name as QuestionEnum,
          InputComponentTypeEnum.multiSelect,
          ufsf.isMandatory,
          ufsf.uiField.definedList!.definedListDetails.map(
            dld => new SelectOption<string>(dld.name, dld.uniqueId, dld.optionAsBtn),
          ),
          'Please select...',
          ufsf.customerHelpText ?? ufsf.uiField.customerHelpText,
          null,
          ufsf.uiField.config,
          ufsf.visible,
        )
      case UIFieldTypeEnum.optInOptOut.toLowerCase():
        return new SelectQuestion<string[], string>(
          ufsf.id,
          label,
          ufsf.uiField.name as QuestionEnum,
          InputComponentTypeEnum.optInOptOut,
          ufsf.isMandatory,
          ufsf.uiField.definedList?.definedListDetails.map(
            dld => new SelectOption<string>(dld.name, dld.uniqueId, dld.optionAsBtn),
          ) ?? [],
          'Please select...',
          ufsf.customerHelpText ?? ufsf.uiField.customerHelpText,
          null,
          ufsf.uiField.config,
          ufsf.visible,
        )
      case UIFieldTypeEnum.ukDrivingLicence.toLowerCase():
        return new DrivingLicenceQuestion(
          ufsf.id,
          label,
          ufsf.uiField.name as QuestionEnum,
          ufsf.isMandatory,
          true,
          null,
          ufsf.customerHelpText ?? ufsf.uiField.customerHelpText,
        )
      case UIFieldTypeEnum.address.toLowerCase():
        return new AddressQuestion(
          ufsf.id,
          label,
          ufsf.uiField.name as QuestionEnum,
          ufsf.isMandatory,
          null,
          ufsf.customerHelpText ?? ufsf.uiField.customerHelpText,
        )
      case UIFieldTypeEnum.vehicleLookup.toLowerCase():
        return new VehicleLookupQuestion(
          ufsf.id,
          label,
          ufsf.uiField.name as QuestionEnum,
          ufsf.isMandatory,
          null,
          ufsf.customerHelpText ?? ufsf.uiField.customerHelpText,
          ufsf.uiField.config,
          ufsf.visible,
        )
      case UIFieldTypeEnum.additionalPerson.toLowerCase():
        return new AdditionalPersonsQuestion(
          ufsf.id,
          label,
          ufsf.uiField.name as QuestionEnum,
          ufsf.isMandatory,
          InputComponentTypeEnum.additionalPerson,
          [],
          ufsf.uiField.minSubPages,
          ufsf.uiField.maxSubPages,
          ufsf.customerHelpText ?? ufsf.uiField.customerHelpText,
          ufsf.uiField.productPageUniqueId,
          ufsf.uiField.config,
          ufsf.visible,
        )
      case UIFieldTypeEnum.additionalVehicle.toLowerCase():
        return new AdditionalVehiclesQuestion(
          ufsf.id,
          label,
          ufsf.uiField.name as QuestionEnum,
          ufsf.isMandatory,
          InputComponentTypeEnum.additionalVehicle,
          [],
          ufsf.uiField.minSubPages,
          ufsf.uiField.maxSubPages,
          ufsf.customerHelpText ?? ufsf.uiField.customerHelpText,
          ufsf.uiField.productPageUniqueId,
          ufsf.uiField.config,
        )
      case UIFieldTypeEnum.markdown.toLowerCase():
        return new MarkdownQuestion(
          ufsf.id,
          label,
          ufsf.uiField.name as QuestionEnum,
          ufsf.isMandatory,
          null,
          ufsf.customerHelpText ?? ufsf.uiField.customerHelpText,
          ufsf.uiField.markdown,
          ufsf.uiField.config,
          ufsf.visible,
        )
      case UIFieldTypeEnum.verisk.toLowerCase():
        return new VeriskQuestion(
          ufsf.id,
          label,
          ufsf.uiField.name as QuestionEnum,
          ufsf.isMandatory,
          null,
          ufsf.customerHelpText ?? ufsf.uiField.customerHelpText,
        )
      case UIFieldTypeEnum.storageLocationComponent.toLowerCase():
        return new StorageLocationQuestion(
          ufsf.id,
          label,
          ufsf.uiField.name as QuestionEnum,
          ufsf.isMandatory,
          InputComponentTypeEnum.storageLocations,
          [],
          ufsf.uiField.minSubPages,
          ufsf.uiField.maxSubPages,
          ufsf.customerHelpText ?? ufsf.uiField.customerHelpText,
          ufsf.uiField.productPageUniqueId,
        )
      case UIFieldTypeEnum.petComponent.toLowerCase():
        return new PetsQuestion(
          ufsf.id,
          label,
          ufsf.uiField.name as QuestionEnum,
          ufsf.isMandatory,
          InputComponentTypeEnum.pets,
          [],
          ufsf.uiField.minSubPages,
          ufsf.uiField.maxSubPages,
          ufsf.customerHelpText ?? ufsf.uiField.customerHelpText,
          ufsf.uiField.productPageUniqueId,
          ufsf.uiField.config,
        )
      case UIFieldTypeEnum.convictions.toLowerCase():
        return new ConvictionsQuestion(
          ufsf.id,
          label,
          ufsf.uiField.name as QuestionEnum,
          ufsf.isMandatory,
          InputComponentTypeEnum.convictions,
          [],
          ufsf.uiField.minSubPages,
          999,
          ufsf.customerHelpText ?? ufsf.uiField.customerHelpText,
          ufsf.uiField.productPageUniqueId,
          ufsf.uiField.config,
          ufsf.visible,
        )
      case UIFieldTypeEnum.modifications.toLowerCase():
        return new ModificationQuestion(
          ufsf.id,
          label,
          ufsf.uiField.name as QuestionEnum,
          ufsf.isMandatory,
          InputComponentTypeEnum.modifications,
          [],
          ufsf.uiField.minSubPages,
          999,
          ufsf.customerHelpText ?? ufsf.uiField.customerHelpText,
          ufsf.uiField.productPageUniqueId,
          ufsf.uiField.config,
          ufsf.visible,
        )
      case UIFieldTypeEnum.medicalconditions.toLowerCase():
        return new MedicalConditionsQuestion(
          ufsf.id,
          label,
          ufsf.uiField.name as QuestionEnum,
          ufsf.isMandatory,
          InputComponentTypeEnum.medicalconditions,
          [],
          ufsf.uiField.minSubPages,
          999,
          ufsf.customerHelpText ?? ufsf.uiField.customerHelpText,
          ufsf.uiField.productPageUniqueId,
          ufsf.uiField.config,
        )
      case UIFieldTypeEnum.claims.toLowerCase():
        return new ClaimsQuestion(
          ufsf.id,
          label,
          ufsf.uiField.name as QuestionEnum,
          ufsf.isMandatory,
          InputComponentTypeEnum.claims,
          [],
          ufsf.uiField.minSubPages,
          999,
          ufsf.customerHelpText ?? ufsf.uiField.customerHelpText,
          ufsf.uiField.productPageUniqueId,
          ufsf.uiField.config,
        )
      case UIFieldTypeEnum.modal.toLowerCase():
        return new ModalQuestion(
          ufsf.id,
          label,
          ufsf.uiField.name as QuestionEnum,
          InputComponentTypeEnum.modal,
          ufsf.isMandatory,
          ufsf.customerHelpText ?? ufsf.uiField.customerHelpText,
          null,
          ufsf.uiField.config,
          ufsf.visible,
        )
      case UIFieldTypeEnum.relatedRadioList.toLowerCase():
        return new RelatedRadioList(
          ufsf.id,
          label,
          ufsf.uiField.name as QuestionEnum,
          InputComponentTypeEnum.relatedRadioList,
          ufsf.isMandatory,
          ufsf.uiField.definedList!.definedListDetails.map(
            dld => new SelectOption<string>(dld.name, dld.uniqueId, dld.optionAsBtn),
          ),
          ufsf.uiField.definedList!.definedListDetails,
          'Please select...',
          ufsf.customerHelpText ?? ufsf.uiField.customerHelpText,
          ufsf.customerUIDefault ? ufsf.customerUIDefault.definedListDetail.uniqueId : null,
          ufsf.uiField.config,
          ufsf.visible,
        )
      case UIFieldTypeEnum.specifiedItems.toLowerCase():
        return new SpecifiedItemsQuestion(
          ufsf.id,
          label,
          ufsf.uiField.name as QuestionEnum,
          ufsf.isMandatory,
          InputComponentTypeEnum.specifiedItems,
          [],
          ufsf.uiField.minSubPages,
          ufsf.uiField.maxSubPages,
          ufsf.customerHelpText ?? ufsf.uiField.customerHelpText,
          ufsf.uiField.productPageUniqueId,
          ufsf.uiField.config,
          ufsf.visible,
        )
      default:
        throw new UnknownQuestionTypeError(ufsf.uiField.type.name)
    }
  }

  protected setupCommonDependencies(page: Page): void {
    const questions = page.sections.flatMap(s => s.questions)
    const dateOfBirth = questions.find(q => q.name === QuestionEnum.dateOfBirth) as DateQuestion
    if (dateOfBirth)
      dateOfBirth.maxDate = () => moment().add(-16, 'years').toDate()
  }

  mapPrepopulateValuesToQuestions(prepopulateValues: PrepopulatableValues, questions: IQuestion[]): void {
    const licenceNumber = questions.find(q => q.name === QuestionEnum.drivingLicence) as DrivingLicenceQuestion
    const employmentStatus = questions.find(q => q.name === QuestionEnum.employmentStatus) as SelectQuestion<string, string>
    const occupation = questions.find(q => q.name === QuestionEnum.occupation) as SelectQuestion<string, string>
    const businessCategory = questions.find(q => q.name === QuestionEnum.businessCategory) as SelectQuestion<string, string>

    if (licenceNumber && !licenceNumber.hasValue())
      licenceNumber.value = prepopulateValues.licenceNumber
    if (employmentStatus && !employmentStatus.hasValue())
      employmentStatus.value = prepopulateValues.employmentStatus
    if (occupation && !occupation.hasValue())
      occupation.value = prepopulateValues.occupation
    if (businessCategory && !businessCategory.hasValue())
      businessCategory.value = prepopulateValues.businessCategory
  }

  mapAccountToQuestions(account: Account, questions: IQuestion[]): void {
    const title = questions.find(q => q.name === QuestionEnum.title) as SelectQuestion<string, string>
    const firstName = questions.find(q => q.name === QuestionEnum.firstname) as Question<string>
    const surname = questions.find(q => q.name === QuestionEnum.lastName) as Question<string>
    const dob = questions.find(q => q.name === QuestionEnum.dateOfBirth) as DateQuestion
    const address = questions.find(q => q.name === QuestionEnum.address) as AddressQuestion
    const email = questions.find(q => q.name === QuestionEnum.customerEmail) as Question<string>
    const mobilePhone = questions.find(q => q.name === QuestionEnum.mobilePhoneNumber || q.name === QuestionEnum.customerHomePhoneNumber) as Question<string>
    const marketing = questions.find(q => q.name === QuestionEnum.keepingYouInformed || q.name === QuestionEnum.marketing) as SelectQuestion<string[], string>
    if (title && !title.hasValue())
      title.value = account.title
    if (dob && !dob.hasValue())
      dob.value = account.dateOfBirth
    if (firstName && !firstName.hasValue())
      firstName.value = account.firstName
    if (surname && !surname.hasValue())
      surname.value = account.lastName
    if (address && !address.hasValue())
      address.value = account.address
    if (email && !email.hasValue())
      email.value = account.email
    if (mobilePhone && !mobilePhone.hasValue())
      mobilePhone.value = account.mobilePhone
    if (marketing) {
      marketing.value = []
      if (account.marketing.isEmailOptIn)
        marketing.value.push(DLDCommonValuesEnum.MarketingOption.Email)

      if (account.marketing.isSMSOptIn)
        marketing.value.push(DLDCommonValuesEnum.MarketingOption.SMS)

      if (account.marketing.isPostOptIn)
        marketing.value.push(DLDCommonValuesEnum.MarketingOption.Post)

      if (account.marketing.isTelephoneOptIn)
        marketing.value.push(DLDCommonValuesEnum.MarketingOption.Telephone)

      if (!account.marketing.isEmailOptIn
        && !account.marketing.isSMSOptIn
        && !account.marketing.isPostOptIn
        && !account.marketing.isTelephoneOptIn
      )
        marketing.value.push(DLDCommonValuesEnum.MarketingOption.NoThanks)
    }
  }

  mapAccountToAnswers(pageAnswers: Dictionary<PageAnswers>, account: Account, pages: Page[]): void {
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.firstname, account.firstName, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.lastName, account.lastName, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.email, account.email, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.customerEmail, account.email, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.customerEmailConfirmation, account.email, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.mobilePhoneNumber, account.mobilePhone, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.customerHomePhoneNumber, account.homePhone, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.address, account.address, pages)
  }

  protected mapProductPageToPage(productPage: ProductPage): Page {
    const sections = productPage.uiFieldSets.map(ufs => new Section(
      ufs.name,
      ufs.displayHeader,
      ufs.uiFieldSetFields.map(ufsf => this.mapUIFieldSetFieldToQuestion(ufsf)),
    ))

    return new Page(
      productPage.uniqueId,
      productPage.name,
      productPage.title,
      productPage.subTitle,
      sections,
      productPage.configs,
      {},
    )
  }

  protected extractNullableValue<T, S, V>(value: T | null, nullSub: S, valueSub: V): S | V {
    if (value === null)
      return nullSub

    return valueSub
  }

  protected createAnswerForQuestion<T>(currentAnswers: Dictionary<PageAnswers>, questionName: QuestionEnum, value: T, pages: Page[]): QuestionAnswer<T>[] | null {
    const matchedPages = pages.map(p => ({
      id: p.id,
      title: p.title,
      matchedQuestions: p.sections.flatMap(s => s.questions).filter(q => q.name === questionName) as Question<T>[],
    })).filter(p => p.matchedQuestions)

    if (!matchedPages)
      return null

    const answers: QuestionAnswer<T>[] = []

    matchedPages.forEach((p) => {
      if (!currentAnswers[p.id])

        currentAnswers[p.id] = new PageAnswers(p.title)

      p.matchedQuestions.forEach((q) => {
        q.value = value
        let answer = currentAnswers[p.id].answers.find(a => a.id === q.id) as QuestionAnswer<T> | undefined
        if (answer) {
          answer.name = q.name
          answer.label = q.label
          answer.value = q.value
          answer.visible = q.isVisible()
          answer.display = q.display()
          answer.hideOnSummary = q.hideOnSummary()
        }
        else {
          answer = new QuestionAnswer(q.id, q.name, q.label, q.value, q.isVisible(), q.display(), [], null, q.hideOnSummary())
          currentAnswers[p.id].answers.push(answer)
        }

        answers.push(answer)
      })
    })

    return answers
  }

  protected getNoClaimsYears(ncdDLD: string): number {
    switch (ncdDLD) {
      case DLDCommonValuesEnum.NCDYears.Years0:
        return 0
      case DLDCommonValuesEnum.NCDYears.Years1:
        return 1
      case DLDCommonValuesEnum.NCDYears.Years2:
        return 2
      case DLDCommonValuesEnum.NCDYears.Years3:
        return 3
      case DLDCommonValuesEnum.NCDYears.Years4:
        return 4
      case DLDCommonValuesEnum.NCDYears.Years5:
        return 5
      case DLDCommonValuesEnum.NCDYears.Years6:
        return 6
      case DLDCommonValuesEnum.NCDYears.Years7:
        return 7
      case DLDCommonValuesEnum.NCDYears.Years8:
        return 8
      case DLDCommonValuesEnum.NCDYears.Years9:
        return 9
      case DLDCommonValuesEnum.NCDYears.Years10Plus:
        return 10
      default:
        throw new Error(`Unknown NCD Years DLD (${ncdDLD})`)
    }
  }

  protected getCoverTermFromValue(coverTermValue: number) {
    switch (coverTermValue) {
      case 1:
        return DLDCommonValuesEnum.CoverTerm.Years1
      case 2:
        return DLDCommonValuesEnum.CoverTerm.Years2
      case 3:
        return DLDCommonValuesEnum.CoverTerm.Years3
      case 4:
        return DLDCommonValuesEnum.CoverTerm.Years4
      default:
        throw new Error(`Unknown Cover Term value (${coverTermValue})`)
    }
  }

  protected getCoverTerm(coverTermDLD: string): number {
    switch (coverTermDLD) {
      case DLDCommonValuesEnum.CoverTerm.Years1:
        return 1
      case DLDCommonValuesEnum.CoverTerm.Years2:
        return 2
      case DLDCommonValuesEnum.CoverTerm.Years3:
        return 3
      case DLDCommonValuesEnum.CoverTerm.Years4:
        return 4
      default:
        throw new Error(`Unknown Cover Term DLD (${coverTermDLD})`)
    }
  }

  protected getLicenseYears(years: number): LicenseHeldYearsEnum {
    if (years < 1)
      return DLDCommonValuesEnum.LicenseHeldYears.ZeroYears

    if (years === 1)
      return DLDCommonValuesEnum.LicenseHeldYears.OneYear

    if (years === 2)
      return DLDCommonValuesEnum.LicenseHeldYears.TwoYear

    if (years === 3)
      return DLDCommonValuesEnum.LicenseHeldYears.ThreeYear

    if (years === 4)
      return DLDCommonValuesEnum.LicenseHeldYears.FourYear

    return DLDCommonValuesEnum.LicenseHeldYears.FivePlusYear
  }

  protected getMonthsStoredDLD(num: number): MonthsStoredAtLocationEnum {
    if (num < 1)
      return DLDCommonValuesEnum.MonthsStoredAtLocation.LessThan1

    if (num === 1)
      return DLDCommonValuesEnum.MonthsStoredAtLocation.LessThan2

    if (num === 2)
      return DLDCommonValuesEnum.MonthsStoredAtLocation.LessThan3

    if (num === 3)
      return DLDCommonValuesEnum.MonthsStoredAtLocation.LessThan4

    if (num === 4)
      return DLDCommonValuesEnum.MonthsStoredAtLocation.LessThan5

    if (num === 5)
      return DLDCommonValuesEnum.MonthsStoredAtLocation.LessThan6

    if (num === 6)
      return DLDCommonValuesEnum.MonthsStoredAtLocation.LessThan7

    if (num === 7)
      return DLDCommonValuesEnum.MonthsStoredAtLocation.LessThan8

    if (num === 8)
      return DLDCommonValuesEnum.MonthsStoredAtLocation.LessThan9

    return DLDCommonValuesEnum.MonthsStoredAtLocation.NineOrMore
  }

  protected getNumberOfCarvansDLD(num: number): NumberOfCaravansOnSiteEnum {
    if (num <= 10)
      return DLDCommonValuesEnum.NumberOfCaravansOnSite.Oneto10

    if (num <= 20)
      return DLDCommonValuesEnum.NumberOfCaravansOnSite.Elevento20

    if (num <= 30)
      return DLDCommonValuesEnum.NumberOfCaravansOnSite.TwentyOneto30

    if (num <= 40)
      return DLDCommonValuesEnum.NumberOfCaravansOnSite.ThirtyOneto40

    if (num <= 50)
      return DLDCommonValuesEnum.NumberOfCaravansOnSite.FortyOneto50

    if (num <= 60)
      return DLDCommonValuesEnum.NumberOfCaravansOnSite.fiftyOneto60

    if (num <= 70)
      return DLDCommonValuesEnum.NumberOfCaravansOnSite.SixtyOneto70

    if (num <= 80)
      return DLDCommonValuesEnum.NumberOfCaravansOnSite.SeventyOneto80

    if (num <= 90)
      return DLDCommonValuesEnum.NumberOfCaravansOnSite.EightyOneto90

    return DLDCommonValuesEnum.NumberOfCaravansOnSite.NinetyPlus
  }

  protected getNoClaimsYearsDLD(ncdDLD: number): NCDYearsEnum {
    if (ncdDLD < 1)
      return DLDCommonValuesEnum.NCDYears.Years0

    if (ncdDLD === 1)
      return DLDCommonValuesEnum.NCDYears.Years1

    if (ncdDLD === 2)
      return DLDCommonValuesEnum.NCDYears.Years2

    if (ncdDLD === 3)
      return DLDCommonValuesEnum.NCDYears.Years3

    if (ncdDLD === 4)
      return DLDCommonValuesEnum.NCDYears.Years4

    if (ncdDLD === 5)
      return DLDCommonValuesEnum.NCDYears.Years5

    if (ncdDLD === 6)
      return DLDCommonValuesEnum.NCDYears.Years6

    if (ncdDLD === 7)
      return DLDCommonValuesEnum.NCDYears.Years7

    if (ncdDLD === 8)
      return DLDCommonValuesEnum.NCDYears.Years8

    if (ncdDLD === 9)
      return DLDCommonValuesEnum.NCDYears.Years9

    return DLDCommonValuesEnum.NCDYears.Years10Plus
  }
}
