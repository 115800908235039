<template>
  <div>
      <div class="modal-card">
        <header class="modal-card-head" v-if="title">
          <p class="modal-card-title">{{ title }}</p>
        </header>
          <section class="modal-card-body confirmation-message">
            <templated-markdown :values="values">
              {{ message }}
            </templated-markdown>
            <b-field type="is-info" v-if="renewalOptOutModal">
              <b-input
                  type="textarea"
                  minlength="1"
                  maxlength="200"
                  placeholder="Please enter your reason here."
                  v-model="value"
                  v-on:input="updateReason">
              </b-input>
            </b-field>
          </section>
          <footer class="modal-card-foot">
            <template v-for="(a, i) in buttons">
              <LinkConfigButton
                v-if="!hideRenewalButtonForPolicyChangeAction(a)"
                :config="a"
                :key="i"
                @click="$emit('close')"
              />
            </template>
          </footer>
      </div>
    <b-loading :is-full-page="true" />
  </div>
</template>

<script lang="ts">
import {
  Component, Vue, Prop,
} from 'vue-property-decorator';
import LinkConfigButton from './LinkConfigButton.vue';
import { LinkConfig } from '@/configurations/IConfiguration';
import Policy from '@/services/models/Policy';
import AvailableActionsEnum from '@/services/enums/AvalableActionsEnum';
import moment from 'moment';

@Component({
  components: {
    TemplatedMarkdown: () => import('@/components/controls/TemplatedMarkdown.vue'),
    LinkConfigButton,
  },
})
export default class LinkConfigModal extends Vue {
  @Prop() title!: string;

  @Prop() message!: string;

  @Prop() buttons!: LinkConfig[];

  @Prop() isRenewalOptOutModal!: boolean;

  @Prop() policy!: Policy;

  value = '';

  get values() {
    return {
      renewalDate: moment(this.policy?.renewalDate).format('DD/MM/YYYY')
    };
  }

  get renewalOptOutModal() {
    return this.isRenewalOptOutModal
  }

  hideRenewalButtonForPolicyChangeAction(button: LinkConfig) {
    return this.title === 'Make Policy Change' && button.name === 'Renewal' && !this.policy.availableActions.find(a => a === AvailableActionsEnum.manageRenewal);
  }

  updateReason() {
    this.$events.emit('updateReason', this.value)
  }
}
</script>
