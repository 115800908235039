import { JsonObject, JsonProperty } from 'json2typescript'

@JsonObject('SchemeCoverDetailFeature')
export class SchemeCoverDetailFeature {
  @JsonProperty('feature', String)
  feature = ''

  @JsonProperty('description', String)
  description = ''
}

@JsonObject('SchemeCoverDetail')
export default class SchemeCoverDetail {
  @JsonProperty('descriptionMarkdown', String)
  descriptionMarkdown = ''

  @JsonProperty('features', [SchemeCoverDetailFeature])
  features: SchemeCoverDetailFeature[] = []

  @JsonProperty('level', Number)
  level = 0

  @JsonProperty('coverTypeDLDID', Number)
  coverTypeDLDID = 0
}
