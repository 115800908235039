import type { JsonCustomConvert } from 'json2typescript'
import { JsonConverter } from 'json2typescript'
import type PolicyStatusEnum from '@/services/enums/PolicyStatusEnum'

@JsonConverter
export default class PolicyStatusEnumConverter implements JsonCustomConvert<PolicyStatusEnum> {
  serialize(policyStatus: PolicyStatusEnum): string {
    return policyStatus.toString()
  }

  deserialize(policyStatus: string): PolicyStatusEnum {
    return policyStatus as PolicyStatusEnum
  }
}
