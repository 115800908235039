enum RenewalStatusEnum {
  notSet = 'Not Set',
  inProgress = 'In Progress',
  failedNoQuotes = 'Failed No Quotes',
  quotedPaymentInvalid = 'Quoted Payment Invalid',
  readyForRenewal = 'Ready For Renewal',
  lapseAtRenewal = 'Lapse At Renewal',
  renewed = 'Renewed',
  error = 'Error',
  awaitingApproval = 'Awaiting Approval',
  invitationSent = 'Invitation Sent',
  invitationSentPaymentRequired = 'Invitation Sent - Payment Required',
  lapsed = 'Lapsed',
  lapseAtRenewalUnwillingToQuote = 'Lapse At Renewal - Unwilling to Quote',
  awaitingClaimsReview = 'Awaiting Claims Review',
  rebrokePending = 'Rebroke Pending',
  rebroked = 'Rebroked',
  awaitingAdditionalReview = 'Awaiting Additional Review',
}

export default RenewalStatusEnum
