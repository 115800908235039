enum Routes {
  home = 'home',
  homeContentsOccupancyAndSecurity = 'home-contents',
  mtahomeContentsOccupancyAndSecurity = 'mta-home-contents',
  renewalhomeContentsOccupancyAndSecurity = 'renewal-home-contents',
  renewalhomeYourHome = 'renewal-home-your-home',
  policies = 'policies',
  policy = 'policy',
  policyChange = 'policy-change',
  cancellation = 'cancellation',
  cancelPendingMTA = 'cancel-mta',
  quotes = 'quotes',
  claims = 'claims',
  convictions = 'convictions',
  medicalconditions = 'medicalconditions',
  account = 'account',
  productJourney = 'product-journey',
  demands = 'demands',
  scheme = 'scheme',
  mtaScheme = 'mta-scheme',
  mtaUpgrade = 'mta-upgrade',
  quoteDeclined = 'quote-declined',
  quoteExpired = 'quote-expired',
  quote = 'quote',
  premium = 'premium',
  mtaPremium = 'mta-premium',
  declarations = 'declarations',
  preQuoteDeclarations = 'prequote-declarations',
  preQuoteAssumptions = 'prequote-assumptions',
  additionalProducts = 'additional-products',
  payment = 'payment',
  mtaPayment = 'mta-payment',
  paymentConfirm = 'payment-confirm',
  upgradePayment = 'upgrade-payment',
  upgrade = 'upgrade',
  renewalUpgradePayment = 'renewal-upgrade-payment',
  renewalUpgrade = 'renewal-upgrade',
  mtaConfirm = 'change-confirm',
  login = 'login',
  deepLink = 'deep-link',
  notFound = 'not-found',
  serverError = 'server-error',
  sessionExpired = 'session-expired',
  renewal = 'renewal',
  renewalPremium = 'renewal-premium',
  renewalDeclarations = 'renewal-declarations',
  renewalQuoteSummary = 'renewal-quote-summary',
  renewalPayment = 'renewal-payment',
  renewalPaymentConfirmation = 'renewal-payment-confirmation',
  renewalScheme = 'renewal-scheme',
  vehicleDetails = 'vehicle-details',
  proposerDetails = 'proposer-details',
  coverDetails = 'cover-details',
  petDetails = 'pet-details',
  aggregatorLanding = 'aggregator-landing',
  goCardlessRedirectComplete = 'go-cardless-redirect-complete',
  mtaVehicleDetails = 'mta-vehicle-details',
  mtaProposerDetails = 'mta-proposer-details',
  mtaCoverDetails = 'mta-cover-details',
  mtaPetDetails = 'mta-pet-details',
  renewalVehicleDetails = 'renewal-vehicle-details',
  renewalProposerDetails = 'renewal-proposer-details',
  renewalCoverDetails = 'renewal-cover-details',
  renewalPetDetails = 'renewal-pet-details',
  owningAndUsing = 'owning-and-using',
  mtaOwningAndUsing = 'mta-owning-and-using',
  assumptions = 'assumptions',
  proposerSection1 = 'proposer-details-section-1',
  proposerSection2 = 'proposer-details-section-2',
  proposerSection3 = 'proposer-details-section-3',
  proposerSection4 = 'proposer-details-section-4',
  proposerSection5 = 'proposer-details-section-5',
  vehicleSection1 = 'vehicle-details-section-1',
  vehicleSection2 = 'vehicle-details-section-2',
  vehicleSection3 = 'vehicle-details-section-3',
  driverDetails = 'driver-details',
  vehicleSummary = 'vehicle-summary',
  quoteReferred = 'quote-referred',
  renewalAssumptions = 'renewal-assumptions',
  renewalProposerSection1 = 'renewal-proposer-details-section-1',
  renewalProposerSection2 = 'renewal-proposer-details-section-2',
  renewalProposerSection3 = 'renewal-proposer-details-section-3',
  renewalProposerSection4 = 'renewal-proposer-details-section-4',
  renewalProposerSection5 = 'renewal-proposer-details-section-5',
  renewalDriverDetails = 'renewal-driver-details',
  mtaAssumptions = 'mta-assumptions',
  mtaDeclarations = 'mta-declarations',
  mtaPreQuoteAssumptions = 'mta-prequote-assumptions',
  mtaPreQuoteDeclarations = 'mta-prequote-declarations',
  mtaProposerSection1 = 'mta-proposer-details-section-1',
  mtaProposerSection2 = 'mta-proposer-details-section-2',
  mtaProposerSection3 = 'mta-proposer-details-section-3',
  mtaProposerSection4 = 'mta-proposer-details-section-4',
  mtaProposerSection5 = 'mta-proposer-details-section-5',
  mtaDriverDetails = 'mta-driver-details',
  mtaPaymentConfirmation = 'mta-payment-confirmation',
  vehicleToInsure = 'vehicle-to-insure',
  proposerLicenceInfo = 'proposer-licence-info',
  additionalDriverDetails = 'additional-driver-details',
  quoteSummary = 'quote-summary',
  mtaQuoteSummary = 'mta-quote-summary',
}

export default Routes
