import { JsonObject, JsonProperty } from 'json2typescript'
import Model from './Model'

@JsonObject('Affiliate')
export default class Affiliate extends Model {
  @JsonProperty('name', String)
  name: string

  @JsonProperty('returnLink', String)
  returnLink: string

  @JsonProperty('logoFile', String)
  logoFile: string

  constructor(
    id = 0,
    name = '',
    returnLink = '',
    logoFile = '',
  ) {
    super(id)
    this.name = name
    this.returnLink = returnLink
    this.logoFile = logoFile
  }
}
