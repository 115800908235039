import { JsonObject, JsonProperty } from 'json2typescript'
import DateConverter from '../../converters/DateConverter'

@JsonObject('StaticCaravanCoverRequest')
export default class StaticCaravanCoverRequest {
  @JsonProperty('effectiveDate', DateConverter, true)
  effectiveDate: Date | null = null

  @JsonProperty('effectiveDateBufferMinutes', String, true)
  effectiveDateBufferMinutes: string | null = null

  @JsonProperty('billingPeriodDLDID', String, true)
  billingPeriod: string | null = null

  @JsonProperty('voluntaryExcessDLDID', String, true)
  voluntaryExcess: string | null = null

  @JsonProperty('basisOfCoverDLDID', String, true)
  basisOfCover: string | null = null

  @JsonProperty('marketPrice', Number, true)
  marketPrice: number | null = null

  @JsonProperty('replacementValue', Number, true)
  replacementValue: number | null = null

  @JsonProperty('coverForAwnings', Boolean, true)
  coverForAwnings: boolean | null = null

  @JsonProperty('caravanAwningValue', Number, true)
  caravanAwningValue: number | null = null

  @JsonProperty('requireLeisureEquipmentInsurance', Boolean, true)
  requireLeisureEquipmentInsurance: boolean | null = null

  @JsonProperty('leisureEquipmentValue', Number, true)
  leisureEquipmentValue: number | null = null

  @JsonProperty('coverForContents', Boolean, true)
  coverForContents: boolean | null = null

  @JsonProperty('caravanContentsValue', Number, true)
  caravanContentsValue: number | null = null

  @JsonProperty('coverForPersonalEffects', Boolean, true)
  coverForPersonalEffects: boolean | null = null

  @JsonProperty('caravanPersonalEffectsValue', Number, true)
  caravanPersonalEffectsValue: number | null = null

  @JsonProperty('requireHighRiskItemsInsurance', Boolean, true)
  requireHighRiskItemsInsurance: boolean | null = null

  @JsonProperty('highRiskItemsValue', Number, true)
  highRiskItemsValue: number | null = null

  @JsonProperty('requirePublicLiabilityInsurance', Boolean, true)
  requirePublicLiabilityInsurance: boolean | null = null

  @JsonProperty('publicLiability', String, true)
  publicLiability: string | null = null

  @JsonProperty('europeanCoverRequired', Boolean, true)
  europeanCoverRequired: boolean | null = null

  @JsonProperty('hasHotTub', Boolean, true)
  hasHotTub: boolean | null = null

  @JsonProperty('caravanNoClaimsBonusDLDID', String, true)
  caravanNoClaimsBonus: string | null = null

  @JsonProperty('householdNoClaimsBonusDLDID', String, true)
  householdNoClaimsBonus: string | null = null

  @JsonProperty('hadPreviousInsurance', Boolean, true)
  hadPreviousInsurance: boolean | null = null

  @JsonProperty('previousInsurerListID', Number, true)
  previousInsurerListID: number | null = null

  @JsonProperty('previousPolicyNumber', String, true)
  previousPolicyNumber: string | null = null

  @JsonProperty('previousPolicyExpiration', DateConverter, true)
  previousPolicyExpiration: Date | null = null
}
