import type { JsonConvert } from 'json2typescript'
import type { AxiosInstance } from 'axios'
import type IDocumentService from './interfaces/IDocumentService'
import Document from './models/Document'
import { DocumentCollectionResponse } from './values/CollectionResponse'

export default class DocumentService implements IDocumentService {
  axios: AxiosInstance

  apiUrl: URL

  jsonConvert: JsonConvert

  constructor(axios: AxiosInstance, apiUrl: URL, jsonConvert: JsonConvert) {
    this.axios = axios
    this.apiUrl = apiUrl
    this.jsonConvert = jsonConvert
  }

  generateURL(id: string): URL {
    return new URL(`${this.apiUrl}/document/${id}`)
  }

  generateStaticURL(id: number): URL {
    return new URL(`${this.apiUrl}/document/static/${id}`)
  }

  generateImageURL(file: string) {
    return new URL(`${this.apiUrl.origin}/ImageHandler.ashx?file=${file}`)
  }

  async getPolicyDocument(policyNumber: string, id: number): Promise<Document> {
    const result = await this.axios.get(`${this.apiUrl}/document/policy/${encodeURIComponent(policyNumber)}/${id}`)
    return this.jsonConvert.deserializeObject(result.data, Document)
  }

  async getForPolicy(policyNumber: string, start?: number, end?: number): Promise<DocumentCollectionResponse> {
    const result = await this.axios.get(`${this.apiUrl}/document/policy/${encodeURIComponent(policyNumber)}`, { params: { start, end } })
    return this.jsonConvert.deserializeObject(result.data, DocumentCollectionResponse)
  }

  async getForCustomer(start?: number, end?: number): Promise<DocumentCollectionResponse> {
    const result = await this.axios.get(`${this.apiUrl}/document/customer`, { params: { start, end } })
    return this.jsonConvert.deserializeObject(result.data, DocumentCollectionResponse)
  }
}
