import type { JsonCustomConvert } from 'json2typescript'
import { JsonConverter } from 'json2typescript'
import moment from 'moment'

// any changes in here will impact all of the dates and times in this project and in Wintrix.
// please be careful when making any changes as you have to consider local time zones and differences between browsers.

@JsonConverter
export default class DateConverter implements JsonCustomConvert<Date | null> {
  serialize(date: Date | null): string | null {
    if (!date)
      return null
    const time = moment(date).format('HH:mm:ss').toString()
    const newDate = moment(date).format('yyyy-MM-DD').toString()
    return `${newDate} ${time}`
  }

  deserialize(date: string): Date | null {
    return date ? new Date(date.replace('Z', '')) : null
  }
}
