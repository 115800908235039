import type { Dictionary } from '@/types'

export default class AccountLocked implements Error {
  name: string

  message: string

  stack?: string

  modelState?: Dictionary<string>

  constructor(modelState?: Dictionary<string>) {
    this.name = 'AccountLocked'
    this.message = 'Account Locked.'
    this.modelState = modelState
  }
}
