import { JsonObject, JsonProperty } from 'json2typescript'
import { DefinedListDetail } from '@/services/models/DefinedList'
import Model from '@/services/models/Model'

@JsonObject('GapDemands')
export default class GapDemands extends Model {
  @JsonProperty('confirmAnyModifications', DefinedListDetail)
  confirmAnyModifications = new DefinedListDetail()

  @JsonProperty('needOutstandingFinance', Boolean)
  needOutstandingFinance: boolean | null = null

  @JsonProperty('confirmCommercialType', DefinedListDetail)
  confirmCommercialType = new DefinedListDetail()

  @JsonProperty('confirmCompetition', DefinedListDetail)
  confirmCompetition = new DefinedListDetail()

  @JsonProperty('confirmFullyComp', DefinedListDetail)
  confirmFullyComp = new DefinedListDetail()

  @JsonProperty('confirmGreyImport', DefinedListDetail)
  confirmGreyImport = new DefinedListDetail()

  @JsonProperty('confirmInvoicePrice', DefinedListDetail)
  confirmInvoicePrice = new DefinedListDetail()

  @JsonProperty('confirmModificationWork', DefinedListDetail)
  confirmModificationWork = new DefinedListDetail()

  @JsonProperty('confirmPayingForPolicyYourself', DefinedListDetail)
  confirmPayingForPolicyYourself = new DefinedListDetail()

  @JsonProperty('confirmPolicyholder', DefinedListDetail)
  confirmPolicyholder = new DefinedListDetail()

  @JsonProperty('confirmRegisteredKeeper', DefinedListDetail)
  confirmRegisteredKeeper = new DefinedListDetail()

  @JsonProperty('confirmUpgradeFully', DefinedListDetail)
  confirmUpgradeFully = new DefinedListDetail()

  @JsonProperty('confirmVehicle', DefinedListDetail)
  confirmVehicle = new DefinedListDetail()

  @JsonProperty('confirmVehicleUse', DefinedListDetail)
  confirmVehicleUse = new DefinedListDetail()

  @JsonProperty('includeCoverForAddedExtra', Boolean)
  includeCoverForAddedExtra: boolean | null = null

  @JsonProperty('includeCoverForVehicleStolen', Boolean)
  includeCoverForVehicleStolen: boolean | null = null

  @JsonProperty('purchaseVATRegistered', Boolean)
  purchaseVATRegistered: boolean | null = null

  @JsonProperty('registeredOwner', String)
  registeredOwner: string | null = null

  @JsonProperty('registeredKeeper', String)
  registeredKeeper: string | null = null
}
