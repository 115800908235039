import type { JsonConvert } from 'json2typescript'
import type { AxiosInstance } from 'axios'
import type IBenefitsService from './interfaces/IBenefitsService'
import BenefitsDetails from './models/BenefitsDetails'

export default class BenefitsService implements IBenefitsService {
  axios: AxiosInstance

  apiUrl: URL

  jsonConvert: JsonConvert

  constructor(axios: AxiosInstance, apiUrl: URL, jsonConvert: JsonConvert) {
    this.axios = axios
    this.apiUrl = apiUrl
    this.jsonConvert = jsonConvert
  }

  async getBenefits(): Promise<BenefitsDetails> {
    let result = null
    result = await this.axios.get(`${this.apiUrl}/benefits/propello-login-link`)
    return this.jsonConvert.deserializeObject(result.data, BenefitsDetails)
  }
}
