import type { JsonCustomConvert } from 'json2typescript'
import { JsonConverter } from 'json2typescript'
import type PaymentScheduleStatusEnum from '@/services/enums/PaymentScheduleStatusEnum'

@JsonConverter
export default class PaymentScheduleStatusEnumConerter implements JsonCustomConvert<PaymentScheduleStatusEnum> {
  serialize(product: PaymentScheduleStatusEnum): string {
    return product.toString()
  }

  deserialize(product: string): PaymentScheduleStatusEnum {
    return product as PaymentScheduleStatusEnum
  }
}
