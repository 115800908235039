enum TenantEnum {
  BarryGrainger = 'barrygrainger',
  Dash = 'dash',
  Hagerty = 'hagerty',
  Ignite = 'ignite',
  KeeyPartners = 'keeypartners',
  Quotax = 'quotax',
  Tractive = 'tractive',
  Wrapper = 'wrapper',
  Yoga = 'yoga',
  Zoomcover = 'zoomcover',
}

export default TenantEnum
