import { JsonObject, JsonProperty } from 'json2typescript'
import Model from './Model'

@JsonObject('Address')
export default class Address extends Model {
  @JsonProperty('display', String, true)
  display?: string

  @JsonProperty('houseNumber', String)
  houseNumber: string

  @JsonProperty('line1', String)
  line1: string

  @JsonProperty('line2', String)
  line2: string

  @JsonProperty('line3', String)
  line3: string

  @JsonProperty('city', String)
  city: string

  @JsonProperty('postCode', String)
  postCode: string

  constructor(
    id = 0,
    display = '',
    houseNumber = '',
    line1 = '',
    line2 = '',
    line3 = '',
    city = '',
    postCode = '',
  ) {
    super(id)
    this.display = display
    this.houseNumber = houseNumber
    this.line1 = line1
    this.line2 = line2
    this.line3 = line3
    this.city = city
    this.postCode = postCode
  }
}
