import { JsonObject, JsonProperty } from 'json2typescript'
import DateConverter from '@/converters/DateConverter'

@JsonObject('ConvictionValue')
export default class Conviction {
  @JsonProperty('convictionDate', DateConverter)
  convictionDate: Date = new Date()

  @JsonProperty('convictionType', String)
  convictionType = ''

  @JsonProperty('convictionPersonsInjured', Boolean)
  convictionPersonsInjured: boolean | null = null

  @JsonProperty('convictionDisqualificationMonths', Number)
  convictionDisqualificationMonths: number | null = null

  @JsonProperty('convictionFine', Number)
  convictionFine: number | null = null

  @JsonProperty('convictionPointsReceived', Number)
  convictionPointsReceived: number | null = null

  @JsonProperty('convictionIsPending', Boolean)
  convictionIsPending: boolean | null = null
}
