import { JsonObject, JsonProperty } from 'json2typescript'
import DateConverter from '../../converters/DateConverter'
import { DefinedListDetail } from './DefinedList'
import Model from './Model'

@JsonObject('Conviction')
export default class Conviction extends Model {
  @JsonProperty('convictionDate', DateConverter)
  convictionDate: Date

  @JsonProperty('convictionType', DefinedListDetail)
  convictionType: DefinedListDetail

  @JsonProperty('convictionPersonsInjured', Boolean)
  convictionPersonsInjured: boolean

  @JsonProperty('convictionDisqualificationMonths', Number)
  convictionDisqualificationMonths: number

  @JsonProperty('convictionFine', Number)
  convictionFine: number

  @JsonProperty('convictionPointsReceived', Number)
  convictionPointsReceived: number

  @JsonProperty('convictionIsPending', Boolean)
  convictionIsPending: boolean

  constructor(
    id = 0,
    convictionDate = new Date(),
    convictionType = new DefinedListDetail(),
    convictionPersonsInjured = false,
    convictionDisqualificationMonths = 0,
    convictionFine = 0,
    convictionPointsReceived = 0,
    convictionIsPending = false,
  ) {
    super(id)
    this.convictionDate = convictionDate
    this.convictionType = convictionType
    this.convictionPersonsInjured = convictionPersonsInjured
    this.convictionDisqualificationMonths = convictionDisqualificationMonths
    this.convictionFine = convictionFine
    this.convictionPointsReceived = convictionPointsReceived
    this.convictionIsPending = convictionIsPending
  }
}
