import moment from 'moment'
import Utils from './Utils'
import type QuoteDetails from '@/services/models/QuoteDetails'
import type SchemeQuoteResult from '@/services/models/SchemeQuoteResult'

export default class DashUtils extends Utils {
  getRenewalDate<T extends SchemeQuoteResult>(quote: QuoteDetails<T>): Date {
    return moment(quote.effectiveDate)
      .add(30, 'days')
      .hours(23)
      .minutes(59)
      .seconds(59)
      .toDate()
  }
}
