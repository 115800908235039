import moment from 'moment'
import Utils from './Utils'
import { CarQuoteDetails } from '@/services/models/CarQuoteDetails'
import type QuoteDetails from '@/services/models/QuoteDetails'
import type SchemeQuoteResult from '@/services/models/SchemeQuoteResult'

export default class ZoomcoverUtils extends Utils {
  getRenewalDate<T extends SchemeQuoteResult>(quote: QuoteDetails<T>): Date {
    if (!(quote instanceof CarQuoteDetails))
      throw new Error(`Zoomcover utils unable to use process type (${typeof quote})`)
    const durationString = (quote as CarQuoteDetails).coverType.uniqueId.split('.')[1]

    const components = durationString.split(/([0-9]+)/)

    return moment(quote.effectiveDate).add(Number.parseInt(components[1], 10), components[2] as moment.unitOfTime.Base).toDate()
  }

  coverType<T extends SchemeQuoteResult>(quote: QuoteDetails<T>, selectedSchemeQuoteResult: number): string {
    switch (quote.results.find(r => r.id === selectedSchemeQuoteResult)!.scheme.code) {
      case 'ZLBCI':
      case 'ZLOCI':
      case 'ZCSI':
      case 'ZTI':
      case 'ZBUOI':
        return 'Comprehensive'
      case 'ZIVI':
        return 'Third Party Only'
      case 'ZTUC':
        return 'Own Vehicle Damage Cover Only'
      case 'DUMMY':
        return 'Dummy Damage Only'
      default:
        break
    }

    return ''
  }
}
