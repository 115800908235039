import type { JsonCustomConvert } from 'json2typescript'
import { JsonConverter } from 'json2typescript'
import type AvalableActionsEnum from '@/services/enums/AvalableActionsEnum'

@JsonConverter
export default class AvalableActionsEnumArrayConverter implements JsonCustomConvert<AvalableActionsEnum[]> {
  serialize(avalableActionsEnum: AvalableActionsEnum[]): string {
    return avalableActionsEnum.toString()
  }

  deserialize(avalableActionsEnum: string[]): AvalableActionsEnum[] {
    return avalableActionsEnum as AvalableActionsEnum[]
  }
}
