<template>
  <b-modal class="paymentgateway-modal" :active="active" has-modal-card :can-cancel="false" :on-cancel="cancel">
    <div class="modal-card">
        <header class="modal-card-head align-items">
          <p class="modal-card-title">Payment</p>
          <button id="threed-cancel" type="button" class="m-0" @click="cancel">Cancel</button>
        </header>
        <section class="modal-card-body ">
          <Loading :active="loading" :loading-text="loadingText" />
          <iframe
            title="PaymentGateway"
            class="paymentgateway-iframe"
            :srcdoc="iframeSrcDoc">
          </iframe>
      </section>
    </div>
  </b-modal>
</template>

<script lang="ts">
/* eslint-disable no-useless-escape */
import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';
import PaymentGatewayDetails from '@/services/values/PaymentGatewayDetails';
import Loading from '@/components/Loading.vue';

@Component({
  components: {
    Loading
  },
})

export default class IgnitePaymentGatewayModal extends Vue {
  @Prop() private paymentGatewayDetails!: PaymentGatewayDetails;

  active = true;

  loading = false;

  loadingText= "Please wait... Do not refresh or close your browser.";

  iframeSrcDoc = ''

  mounted() {
    console.log('IgnitePaymentGatewayModal -- Mounted');
    window.removeEventListener('message', this.listener);
    window.addEventListener('message', this.listener);
    this.loading = true;
    this.iframeSrcDoc = this.paymentGatewayDetails.iframesrcdoc;
  }

  beforeDestroy() {
    console.log('IgnitePaymentGatewayModal -- Destroyed');
    window.removeEventListener('message', this.listener);
  }

  listener(event: MessageEvent) {
    if(event.data.application !== "PaymentGateway") {
      return;
    }

    console.log('IgnitePaymentGatewayModal -- Received event', event.data);

    if(event.data.outcome == "loaded"){
      this.loading = false
      return;
    }

    window.removeEventListener('message', this.listener);
    this.active = false;
    this.$emit('complete', event.data)
  }

  cancel() {
    console.log('IgnitePaymentGatewayModal -- Cancelled');
    window.removeEventListener('message', this.listener);
    this.active = false;
    this.$emit('cancel');
  }
}
</script>
